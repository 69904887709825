import { Component, OnInit ,Inject,HostListener ,ChangeDetectorRef} from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { FormControl, UntypedFormBuilder,FormGroup, Validators,ReactiveFormsModule  } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';
import { UrlConstant } from 'src/constants/url.constants';
import { ToasterService } from "src/app/shared/components/toast/toast.service";
import { PlatformConstant } from 'src/constants/platform.constants';
import { errorCodes } from 'src/constants/errorCodes';
import { BlockUI, NgBlockUI } from 'ng-block-ui';

@Component({
  selector: 'app-add-configuration',
  templateUrl: './add-configuration.component.html',
  styleUrls: ['./add-configuration.component.css']
})
export class AddConfigurationComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<AddConfigurationComponent>,  
    @Inject(MAT_DIALOG_DATA) public platformData : any,
    private apiService: ApiService,
    private toaster: ToasterService, 
    private formBuilder : UntypedFormBuilder,
    private cdr: ChangeDetectorRef ) { }
    
    @BlockUI() blockUI: NgBlockUI; 
    subscriptions: Subscription = new Subscription();
    errorMsg = "";
    configList:any = [];
    isPlatformReleased: boolean = false;
    platformReleasedInfo: string = PlatformConstant.PLATFORM_RELEASED;
  ngOnInit(): void {
    
    this.setDataList(this.platformData.params);
  }
  setDataList(data:any){
    
    this.isPlatformReleased = Number(data.released) == 1;
    this.blockUI.start('Loading...');
    const cpu:number = data.cpuSockets;
    const dimms:number = data.dimmSlots;
    
    this.configList = [];
    for(let i= 1;i<=cpu;i++)
      for(let j= 1;j<=dimms;j++){
        const row = {
          sequence: null,
          serverSeqNo: this.platformData.params.sequence,
          cpuCount: i,
          dimmCount: j,
          measured: null,
          inValid:"true",
          type : this.platformData.params.type,
          editor : null,
          role : null
        }
        this.configList.push(row);
      }
      

     const params={
      "cpuIds": this.platformData.params.cpus,
      "dimIds" : this.platformData.params.dimms,
      "serverSeqNo" :this.platformData.params.sequence,
      "statusOfDims" : "valid"
     }  
    const url = UrlConstant.ADD_CONFIG_DETAIL;
    this.subscriptions.add(
      this.apiService.postMethod(url,params).subscribe((data: any) => {
        
        if(data?.length > 0){
          data.forEach(element => {
            this.configList.forEach(row => {
              if(element.cpuCount == row.cpuCount && element.dimmCount == row.dimmCount){
                row.sequence = element.sequence;
                row.measured = element.measured?.toString();
                row.editor =  element.editor;
                row.inValid = element.inValid.toString();
              }
            });
          });
          
        }
        this.blockUI.stop();

      },error =>{  
        this.blockUI.stop();
        let errorMsg = error?.error ? error.error?.error?.errorString : "Oops something went wrong!!";
        this.toaster.show('error', errorMsg , error ? error?.message : '',5000);
      })
    );
  }
  edited = false;

  submit(action:any){
    
    let params =[];
    
    // this.blockUI.start('Loading...');
    this.edited = true;

    this.configList.forEach(row => {
      if(row.sequence || row.measured !== null){
        const param_row = 
          {
            "sequence" : row.sequence,
            "serverSeqNo" : row.serverSeqNo,
            "cpuCount" : row.cpuCount,
            "dimmCount" : row.dimmCount,
            "measured" : row.measured,
            "type" : row.type,
            "role" : "Admin"
          }
        params.push(param_row);
      }
    });

    
    

    if(params.length > 0){
      this.blockUI.start('Loading...');
      const url = UrlConstant.MANAGE_PLATFORM + UrlConstant.ADD_CONFIG;
      this.subscriptions.add(
        this.apiService.postMethod(url,params).subscribe((data: any) => {
          
          this.formChanged = false;

          if(data.modifiedCount == 0 && data?.error?.errorString == "error"){
            this.toaster.show('error', errorCodes.PLATFORM_CONFIG_FAILED , '')
          }
          else
            this.toaster.show('success', errorCodes.PLATFORM_CONFIG_UPDATED , '');

          if(action == 'closeModal'){
            this.dialogRef.close({
              data: {
                action : PlatformConstant.ACTION_REFRESH
                } 
              });
            }
            else{
              this.setDataList(this.platformData.params)
            }
          
            this.blockUI.stop();

          },error =>{  
          this.errorMsg= error?.error ? error.error?.error?.errorString : errorCodes.GENERIC_ERROR_MESSAGE;
          this.toaster.show('error', this.errorMsg , error ? error?.message : '');    
          this.blockUI.stop();
          this.formChanged = true;

      
        })
      );
    }
  }
  
  formChanged:boolean = false;
  optionChange(e,row){
    this.formChanged = true;

    
    if(e !== "true"){
      row.inValid = "false"
    }
    else{
      row.inValid = "true"
      row.measured = null;
    }
  }

  closeModal(){
    this.dialogRef.close({
      data: {
        action : this.edited ? PlatformConstant.ACTION_REFRESH : PlatformConstant.ACTION_CANCEL
        } 
      });
  }

}
