
  <div class="flex-center" *ngIf="label === 'DownLoad'">
    <a (click)="onClick($event, 'download')" [href]="getHref()">
      <span class="icon-download icon-size-20 icon-color"></span>
    </a>
  </div>
 
   <div class="flex-center" *ngIf="label === 'Delete'">
    <a (click)="openModal($event)" class="anchor-close" id="delete" title="Delete">
    <span class="icon-delete icon-size-20 icon-delete"></span>
    </a>
  </div>
