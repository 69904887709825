export class PlatformConstant {
    public static RACK = "RACK";
    public static BLADE = "BLADE";
    public static BLADE_CHASSIS = "CHASSIS";
    public static NODE = "CARTRIDGE";
    public static MODULAR_CHASSIS = "MSERIES";
    public static FABRIC_INTERCONNECTS = "FI";//"Fabric Interconnect";
    public static FABRIC_EXTENDERS = "NEXUS";//"Fabric Extender";

    public static PLATFORM = "platform";

    public static ACTION_CREATE = "CREATE";
    public static ACTION_CANCEL = "CANCEL";
    public static ACTION_REFRESH = "REFRESH";
    public static ACTION_DELETE = "Delete";
    public static ACTION_UPDATE = "Edit";
    public static ACTION_OK = "ok";

    public static ADD_CONFIG = "AddConfiguration";
    public static MANAGE_COMP_OPTIONS = "ManageComponentOptions";
    public static ADD_MEASUREMENT = "AddMeasurement";
    public static CPU_MEM_COMPATIBILITY = "CpuMemoryCompatibility";

    public static POWER_CAP = [
        {value: 'none', viewValue: 'None'},
        {value: 'max', viewValue: 'Max'},
        {value: 'redundant', viewValue: 'Redundant'}         
      ];
    public static ENABLED_DISABLED = [
      {value: 'Enabled', viewValue: 'Enabled'},
      {value: 'Disabled', viewValue: 'Disabled'},
    ];
    public static C_STATES = [
      {value: 'C1E', viewValue: 'C1E'},
      {value: 'C3', viewValue: 'C3'},
      {value: 'C6', viewValue: 'C6'},
    ]; 
    
    public static MEMORY_MODE = [
      {value: '1.5V', viewValue: '1.5V'},
      {value: '1.35V', viewValue: '1.35V'},
      {value: '1.2V', viewValue: '1.2V'},
      {value: '1.1V', viewValue: '1.1V'},
    ]; 

    public static RACK_FIELD_NAMES = []
    public static BLADE_SERVERS_FIELDS =[]
    public static BLADE_CHASSIS_FIELDS = []
    public static NODE_FIELDS = []
    public static MODULAR_CHASSIS_FIELDS = []
    public static FABRIC_FIELDS = []
    
    public static PLATFORMS_DROPDOWN = [
      {value: 'cpus', viewValue: 'Processor'},
      {value: 'dimms', viewValue: 'Memory'},
      {value: 'powerSupply', viewValue: 'Power Supply'},
      {value: 'ioes', viewValue: 'IO Cards'},
      {value: 'storages', viewValue: 'Storage'},
      {value: 'ioms', viewValue: 'IO Module'},
      {value: 'BLADE', viewValue: 'Blades'},
      {value: 'CARTRIDGE', viewValue: 'Node'},
      {value: 'expansions', viewValue: 'Expansion Cards'},
      {value: 'FanPolicy', viewValue: 'Fan Policy'},
    ]
    public static PLATFORM_RELEASED = 'The platform is already released, un-release the platform to save changes.';

}