<div *ngIf="!isSmallScreen" class="user-login big-screen">
  <span style="color: #00bceb;margin: 30px 0;" class="icon-cisco icon-size-48"></span>
  <div class="flex " id="title" style="margin: 0 auto 30px auto;width: fit-content;justify-content: center;">
    <h1>{{ 'UCS_POWER_CALCULATOR' | translate }} </h1>
  </div>
  <a (click)="login()" class="btn btn--primary btn--large">{{ 'LOGIN' | translate }} </a>
  <br>
  <a (click)="cancel()" style="margin: 30px 0;">{{ 'Continue as Guest' | translate }} </a>
</div>

<!-- ######################## If Screen Width is less than 770px ################################################### -->

<div *ngIf="isSmallScreen" class="user-login small-screen">
  <span style="color: #00bceb;margin: 15px 0;" class="icon-cisco icon-size-48 "></span>
  <div class="flex " id="title" style="margin: 0 auto 15px auto;width: fit-content; justify-content: center;">
    <h4>{{ 'UCS_POWER_CALCULATOR' | translate }} </h4>
  </div>
  <a (click)="login()" class="btn btn--primary">{{ 'LOGIN' | translate }} </a>
  <br>
  <a (click)="cancel()" style="margin: 15px 0;">{{ 'Continue as Guest' | translate }} </a>
</div>
