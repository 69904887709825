import { ChangeDetectorRef, Component, Inject, OnInit } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import {
  CngContentConfigType,
  CngModalService,
  CngModalSize,
} from "@cisco/cui-ng";
import { TranslateService } from "@ngx-translate/core";
import { BlockUI, NgBlockUI } from "ng-block-ui";
import { Subscription } from "rxjs";
import { AdminService } from "src/app/services/admin.service";
import { ApiService } from "src/app/services/api.service";
import { AdditionalAttributesComponent } from "src/app/shared/components/additional-attributes/additional-attributes.component";
import { ConfirmationBoxComponent } from "src/app/shared/components/confirmation-box/confirmation-box.component";
import { ToasterService } from "src/app/shared/components/toast/toast.service";
import { AppConstant } from "src/constants/app.constants";
import { ComponentConstant } from "src/constants/components.constants";
import { errorCodes } from "src/constants/errorCodes";
import { PlatformConstant } from "src/constants/platform.constants";
import { UrlConstant } from "src/constants/url.constants";

export interface DialogData {
  selecteddimm: string;
  noOfDimms: string;
  size: string;
  name: string;
  pid: string;
  mtbf: string;
  moreMemoryModulePerChannelIdlePower: string;
  moreMemoryModulePerChannelMaxPower: string;
  persistentModelIdlePower: string;
  persistentModelMaxPower: string;
  type: string;
  id: string;
  update: boolean;
  released: string;
  dimm: string;
  dimmFamName: string;
  numDimms: string;
  idleWatts: string;
  maxWatts: string;
  idleWatts2Dpc: string;
  maxWatts2Dpc: string;
}

interface Dimm {
  value: string;
  viewValue: string;
}

@Component({
  selector: "app-create-dimms",
  templateUrl: "./create-dimms.component.html",
  styleUrls: ["./create-dimms.component.css"],
})
export class CreateDimmsComponent implements OnInit {
  subscriptions: Subscription = new Subscription();

  constructor(
    public dialogRef: MatDialogRef<CreateDimmsComponent>,
    @Inject(MAT_DIALOG_DATA) public dimmsData: DialogData,
    private apiService: ApiService,
    private toaster: ToasterService,
    private modalService: CngModalService,
    private translate: TranslateService,
    private adminService: AdminService,
    public dialog: MatDialog,
    private formBuilder: UntypedFormBuilder,
    private cdr: ChangeDetectorRef
  ) {
    this.validations = this.formBuilder.group({
      dimm: new UntypedFormControl("", [
        Validators.required,
        Validators.pattern(AppConstant.VALID_INPUT_REGEX),
      ]),
      newDimmFamily: new UntypedFormControl("", []),
      noOfDimms: new UntypedFormControl("", [
        Validators.required,
        Validators.min(1),
        Validators.pattern("\\d+"),
      ]),
      size: new UntypedFormControl("", [
        Validators.required,
        Validators.min(1),
        Validators.pattern("\\d+"),
      ]),
      name: new UntypedFormControl("", [
        Validators.pattern(AppConstant.VALID_INPUT_REGEX),
        Validators.required,
      ]),
      mtbf: new UntypedFormControl(""),
      moreMemoryModulePerChannelIdlePower: new UntypedFormControl(""),
      moreMemoryModulePerChannelMaxPower: new UntypedFormControl(""),
      persistentModelIdlePower: new UntypedFormControl(""),
      persistentModelMaxPower: new UntypedFormControl(""),
      idleWatts: new UntypedFormControl("", [
        Validators.required,
        Validators.pattern(AppConstant.VALID_INPUT_REGEX),
      ]),
      maxWatts: new UntypedFormControl("", [
        Validators.required,
        Validators.pattern(AppConstant.VALID_INPUT_REGEX),
      ]),
      idleWatts2Dpc: new UntypedFormControl(""),
      maxWatts2Dpc: new UntypedFormControl(""),
    });
    this.validations.controls["dimm"].setValue(this.default, {
      onlySelf: true,
    });
  }
  default: string = "--select--";
  dimms: Dimm[] = [];
  validations: UntypedFormGroup;
  selecteddimm: any;
  lblMandatoryMsg = "";
  lblSuccessMsg = "";
  dialogConfig;
  errorMsg = "";
  showLoader: boolean = false;
  type;
  released;
  update: boolean = false;
  @BlockUI() blockUI: NgBlockUI;

  saveCloseFlag: boolean = true;
  onNoClick(): void {
    this.type = this.dimmsData.type;
    this.update = this.dimmsData.update;
    //this.dialogRef.close('cancel');
    this.saveCloseFlag = false;
    // this.dataToSave();
    this.saveDimmsFamily();
  }

  showPID = true;
  ngOnInit(): void {
    this.showPID = true;

    this.type = this.dimmsData.type;
    this.update = this.dimmsData.update;

    //
    if (this.dimmsData.update) {
      //for pid search
      this.selectedPid = this.dimmsData.pid;
      //addional fields
      this.additionalFields = this.dimmsData["additionalFields"]
        ? this.dimmsData["additionalFields"]
        : [];
    }
    this.getManageDimmsFamilyList();
    this.cdr.detectChanges();
  }

  getManageDimmsFamilyList() {
    this.blockUI.start("Loading...");
    let dimmsFamily = ComponentConstant.DIMM_FAMILIES;
    const url = UrlConstant.MANAGECOMPONENTS + "/?component=" + dimmsFamily;
    this.subscriptions.add(
      this.apiService.getMethod(url).subscribe((data: any) => {
        //
        let rows = [];
        for (let i = 0; i < data.componenetList.length; i++) {
          let dimFamilyNameObj = {
            value: data.componenetList[i].text,
            viewValue: data.componenetList[i].text,
          };
          rows.push(dimFamilyNameObj);
        }
        rows.push({ value: "new", viewValue: "--Add New Family--" });
        this.dimms = rows;
        if (this.dimmsData.update) {
          if (this.dimmsData.dimm) {
            this.selecteddimm = this.dimms.find(
              (row) => this.dimmsData.dimm == row.value
            );
            this.validations.controls["dimm"].patchValue(this.selecteddimm);
          }
        }
        this.showLoader = false;
        this.blockUI.stop();
      })
    );
  }

  showNewFamilyName: boolean = false;
  changeDropdown(event: any) {
    if (event.value.value == "new") {
      this.showNewFamilyName = true;
      this.validations.patchValue({ newDimmFamily: "" });
      this.validations.controls["newDimmFamily"].setValidators([
        Validators.required,
        Validators.pattern(AppConstant.VALID_INPUT_REGEX),
      ]);
      this.validations.controls["newDimmFamily"].enable();
    } else {
      this.showNewFamilyName = false;
      this.validations.controls["newDimmFamily"].clearValidators();
      this.validations.controls["newDimmFamily"].reset({
        value: "",
        disabled: true,
      });
    }
    this.validations.controls["newDimmFamily"].updateValueAndValidity();
  }

  saveDimmsFamily() {
    if (this.showNewFamilyName) {
      this.blockUI.start("Loading...");

      let reqData = {
        type: ComponentConstant.DIMM_FAMILIES,
        role: "Admin",
        text: this.validations.value.newDimmFamily,
        rank: 1,
        speed: 1,
        siliconSize: 1,
        released: 1,
        allowDuplicates: true,
        additionalFields: [],
      };
      const url = UrlConstant.MANAGECOMPONENTS;
      let params = [];
      params.push(reqData);
      this.subscriptions.add(
        this.apiService.postMethod(url, params).subscribe((data: any) => {
          this.blockUI.stop();

          this.onSave();
        })
      );
    } else {
      this.onSave();
    }
  }

  dataToSave() {
    if (this.validations.valid) {
      let dimmFamNameToSave = this.showNewFamilyName
        ? this.validations.value.newDimmFamily
        : this.validations.get("dimm").value.value;

      let data = {
        type: this.dimmsData.type,
        role: "Admin",
        dimmFamName: dimmFamNameToSave,
        numDimms: this.validations.value.noOfDimms,
        displayName: this.validations.value.name,
        size: this.validations.value.size,
        // "pid" : this.validations.value.pid,
        pid: this.selectedPid,

        mtbf: this.validations.value.mtbf,
        idleWatts: this.validations.value.idleWatts,
        maxWatts: this.validations.value.maxWatts,
        idleWatts2Dpc: this.validations.value.idleWatts2Dpc,
        maxWatts2Dpc: this.validations.value.maxWatts2Dpc, 
        moreMemoryModulePerChannelIdlePower:
          this.validations.value.moreMemoryModulePerChannelIdlePower,
        moreMemoryModulePerChannelMaxPower:
          this.validations.value.moreMemoryModulePerChannelMaxPower,
        persistentModelIdlePower:
          this.validations.value.persistentModelIdlePower,
        persistentModelMaxPower: this.validations.value.persistentModelMaxPower,
        released: "",
        additionalFields: this.setAdditionalFields(),
        allowDuplicates: false,
      };
      if (!this.dimmsData.update) {
        let result = this.createDimm(data);
      } else {
        data.released = this.dimmsData.released;
        data["id"] = this.dimmsData.id;
        let result = this.updateDimm(data);
      }
    } else {
    }
  }

  setAdditionalFields() {
    let array = [];
    if (this.additionalFields?.length > 0) {
      this.additionalFields.forEach((element) => {
        let row = {
          name: element.name,
          type: element.type,
          value: element.value,
        };
        array.push(row);
      });
    } else array = [];

    return array;
  }

  resetForm() {
    this.showPID = false;

    this.validations.reset();
    this.saveCloseFlag = true;
    this.additionalFields = [];
    if (this.showNewFamilyName) {
      this.getManageDimmsFamilyList();
      this.showNewFamilyName = false;
      this.validations.controls["newDimmFamily"].clearValidators();
      this.validations.controls["newDimmFamily"].reset({
        value: "",
        disabled: true,
      });
    }
    //search pid
    this.selectedPid = null;
    setTimeout(() => {
      this.showPID = true;
    }, 100);
  }
  onSave() {
    this.dataToSave();
  }

  createDimm(reqData: any) {
    const url = UrlConstant.MANAGECOMPONENTS;
    this.showLoader = true;
    this.blockUI.start("Loading...");
    let params = [];
    params.push(reqData);
    params = params.concat(this.applyToRecords);
    params = params.concat(this.updatedRecords);

    this.subscriptions.add(
      this.apiService.postMethod(url, params).subscribe(
        (data: any) => {
          this.showLoader = false;
          this.blockUI.stop();
          this.refreshData = true;

          this.lblMandatoryMsg = "";

          if (data.modifiedCount > 0) {
            this.toaster.show("success", errorCodes.NEW_RECORD, "", 5000);
            if (this.saveCloseFlag) {
              window.scroll(0, 0);
              this.dialogRef.close({
                data: {
                  action: PlatformConstant.ACTION_REFRESH,
                },
              });
          } else {
            this.resetForm();
          }        
          
        } else if (data.modifiedCount == 0 && data.error.errorString == "Duplicate Data Found") {
          reqData["allowDuplicates"] = true;
          this.openModal(reqData);
          this.subscriptions.add(
            this.apiService.postMethod(url, params).subscribe((data: any) => {
              console.log(data.modifiedCount);
              if (data.modifiedCount > 0) {
                this.toaster.show('success', errorCodes.NEW_RECORD, '',5000);
                // if (this.saveCloseFlag) {
                  window.scroll(0,0);
                  this.dialogRef.close({
                    data: {
                      action : PlatformConstant.ACTION_REFRESH
                      } 
                    });
                // } else {
                //   this.resetForm();
                // }                
              }
            }))          
        }
      }, error => {
        this.errorMsg = error?.error ? error.error?.error?.errorString : errorCodes.GENERIC_ERROR_MESSAGE;
        this.toaster.show('error', this.errorMsg, error ? error?.message : '',5000);
      })
    );
  }

  updateDimm(reqData: any) {
    const url = UrlConstant.MANAGECOMPONENTS + UrlConstant.UPDATE;
    this.showLoader = true;
    this.blockUI.start("Loading...");
    let params = [];
    params.push(reqData);
    params = params.concat(this.applyToRecords);
    params = params.concat(this.updatedRecords);

    this.subscriptions.add(
      this.apiService.postMethod(url, params).subscribe(
        (data: any) => {
          this.showLoader = false;
          this.blockUI.stop();
          this.refreshData = true;

          this.lblMandatoryMsg = "";

          if (
            data.modifiedCount > 0 &&
            data.successMessage == "Successfully updated"
          ) {
            this.toaster.show("success", errorCodes.UPDATE_RECORD, "", 5000);
            if (this.saveCloseFlag) {
              window.scroll(0, 0);
              this.dialogRef.close({
                data: {
                  action: PlatformConstant.ACTION_REFRESH,
                },
              });
            } else {
              //this.resetForm();
              this.saveCloseFlag = true;
              if (this.showNewFamilyName) {
                this.dimmsData.dimm = this.validations.value.newDimmFamily;
                this.getManageDimmsFamilyList();
                this.showNewFamilyName = false;
              }
            }
          } else if (
            data.modifiedCount == 0 &&
            data.error.errorString == "Duplicate Data Found"
          ) {
            this.blockUI.start("Loading...");
            reqData["allowDuplicates"] = true;
            this.subscriptions.add(
              this.apiService.postMethod(url, params).subscribe(
                (data: any) => {
                  this.blockUI.stop();
                  if (data.modifiedCount > 0) {
                    this.toaster.show(
                      "success",
                      errorCodes.UPDATE_RECORD,
                      "",
                      5000
                    );
                    if (this.saveCloseFlag) {
                      window.scroll(0, 0);
                      this.dialogRef.close({
                        data: {
                          action: PlatformConstant.ACTION_REFRESH,
                        },
                      });
                      this.openModal(reqData);
                    } else {
                      this.saveCloseFlag = true;
                    }
                  }
                },
                (error) => {
                  this.handleError(error);
                }
              )
            );
          }
        },
        (error) => {
          this.handleError(error);
        }
      )
    );
  }

  handleError(error) {
    this.errorMsg = error?.error
      ? error.error?.error?.errorString
      : errorCodes.GENERIC_ERROR_MESSAGE;
    this.toaster.show(
      "error",
      this.errorMsg,
      error ? error?.message : "",
      5000
    );
    this.showLoader = false;
    this.blockUI.stop();
  }

  refreshData: boolean = false;
  public onCancel = () => {
    this.dialogRef.close({
      data: {
        action: this.refreshData
          ? PlatformConstant.ACTION_REFRESH
          : PlatformConstant.ACTION_CANCEL,
      },
    });
  };

  ngOnDestroy() {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }

  showWattErr:boolean = false;
  showWatt2DpcErr:boolean = false;
  showChannelWattErr:boolean = false;
  showPersistentWattErr:boolean = false;
  IdleMaxWattsErr:boolean = false;
  IdleMaxWatts2DpcErr:boolean = false;
  IdleWattsErr:boolean = false;
  IdleWatts2DpcErr:boolean = false;
  mtbfErr:boolean = false;
  moreMemoryModulePerChannelIdlePowerErr : boolean = false;
  moreMemoryModulePerChannelMaxPowerErr : boolean = false;
  persistentModelIdlePowerErr : boolean = false;
  persistentModelMaxPowerErr : boolean = false;

  validatePower(e: any) {
    this.showWatt2DpcErr = false;
    this.showWattErr = false;
    this.showChannelWattErr = false;
    this.showPersistentWattErr = false;
    this.IdleMaxWattsErr = false;
    this.IdleMaxWatts2DpcErr = false;
    this.IdleWatts2DpcErr = false;
    this.IdleWattsErr = false;
    this.mtbfErr = false;
    this.moreMemoryModulePerChannelIdlePowerErr = false;
    this.moreMemoryModulePerChannelMaxPowerErr = false;
    this.persistentModelIdlePowerErr = false;
    this.persistentModelMaxPowerErr = false;

    if (
      this.validations.value.idleWatts &&
      this.validations.value.maxWatts &&
      !isNaN(this.validations.value.idleWatts) &&
      !isNaN(this.validations.value.maxWatts)
    ) {
      this.showWattErr =
        Number(this.validations.value.maxWatts) >=
        Number(this.validations.value.idleWatts)
          ? false
          : true;

      this.cdr.detectChanges();
    }
    if (
      this.validations.value.idleWatts &&
      (isNaN(this.validations.value.idleWatts) ||
        parseFloat(this.validations.value.idleWatts) <= 0)
    ) {
      //this.lblErrMessage = "Idle watts should be a Positive integer";
      this.IdleWattsErr = true;
      this.cdr.detectChanges();
    }
    if (
      this.validations.value.maxWatts &&
      (isNaN(this.validations.value.maxWatts) ||
        parseFloat(this.validations.value.maxWatts) <= 0)
    ) {
      //this.lblErrMessage = "Idle watts should be a Positive integer";
      this.IdleMaxWattsErr = true;
      this.cdr.detectChanges();
    }
    if(this.validations.value.idleWatts2Dpc && this.validations.value.maxWatts2Dpc 
      && !isNaN(this.validations.value.idleWatts2Dpc) && !isNaN(this.validations.value.maxWatts2Dpc)){
   this.showWatt2DpcErr = Number(this.validations.value.maxWatts2Dpc) >= Number(this.validations.value.idleWatts2Dpc) 
   ? false : true;
   console.log(Number(this.validations.value.maxWatts2Dpc) < Number(this.validations.value.idleWatts2Dpc));

   console.log(this.showWatt2DpcErr);
   this.cdr.detectChanges();
 }
 if (this.validations.value.idleWatts2Dpc && (isNaN(this.validations.value.idleWatts2Dpc) || parseFloat(this.validations.value.idleWatts2Dpc) <= 0)) {
   console.log('Idle watts is not a number');
   //this.lblErrMessage = "Idle watts should be a Positive integer";
   this.IdleWatts2DpcErr = true;
   this.cdr.detectChanges();
 }
 if (this.validations.value.maxWatts2Dpc && (isNaN(this.validations.value.maxWatts2Dpc) || parseFloat(this.validations.value.maxWatts2Dpc) <= 0)) {
   console.log('Max watts is not a number');
   //this.lblErrMessage = "Idle watts should be a Positive integer";
   this.IdleMaxWatts2DpcErr = true;
   this.cdr.detectChanges();
 }
    if (this.validations.value.mtbf && (isNaN(this.validations.value.mtbf))) {
      console.log('MTBF is not a number');
      //this.lblErrMessage = "Idle watts should be a Positive integer";
      this.mtbfErr = true;
      this.cdr.detectChanges();
    }

    if (
      this.validations.value.moreMemoryModulePerChannelIdlePower &&
      this.validations.value.moreMemoryModulePerChannelMaxPower &&
      !isNaN(this.validations.value.moreMemoryModulePerChannelIdlePower) &&
      !isNaN(this.validations.value.moreMemoryModulePerChannelMaxPower)
    ) {
      this.showChannelWattErr =
        Number(this.validations.value.moreMemoryModulePerChannelMaxPower) >=
        Number(this.validations.value.moreMemoryModulePerChannelIdlePower)
          ? false
          : true;

      this.cdr.detectChanges();
    }

    if (
      this.validations.value.moreMemoryModulePerChannelIdlePower &&
      isNaN(this.validations.value.moreMemoryModulePerChannelIdlePower)
    ) {
      //this.lblErrMessage = "Idle watts should be a Positive integer";
      this.moreMemoryModulePerChannelIdlePowerErr = true;
      this.cdr.detectChanges();
    }
    if (
      this.validations.value.moreMemoryModulePerChannelMaxPower &&
      isNaN(this.validations.value.moreMemoryModulePerChannelMaxPower)
    ) {
      //this.lblErrMessage = "Idle watts should be a Positive integer";
      this.moreMemoryModulePerChannelMaxPowerErr = true;
      this.cdr.detectChanges();
    }

    if (
      this.validations.value.persistentModelIdlePower &&
      this.validations.value.persistentModelMaxPower &&
      !isNaN(this.validations.value.persistentModelIdlePower) &&
      !isNaN(this.validations.value.persistentModelMaxPower)
    ) {
      this.showPersistentWattErr =
        Number(this.validations.value.persistentModelMaxPower) >=
        Number(this.validations.value.persistentModelIdlePower)
          ? false
          : true;
      this.cdr.detectChanges();
    }
    if (
      this.validations.value.persistentModelIdlePower &&
      isNaN(this.validations.value.persistentModelIdlePower)
    ) {
      //this.lblErrMessage = "Idle watts should be a Positive integer";
      this.persistentModelIdlePowerErr = true;
      this.cdr.detectChanges();
    }
    if (
      this.validations.value.persistentModelMaxPower &&
      isNaN(this.validations.value.persistentModelMaxPower)
    ) {
      //this.lblErrMessage = "Idle watts should be a Positive integer";
      this.persistentModelMaxPowerErr = true;
      this.cdr.detectChanges();
    }
  }

  public async openModal(data: any) {
    const result = await this.modalService
      .open({
        content: {
          type: CngContentConfigType.COMPONENT,
          content: ConfirmationBoxComponent,
          componentData: {
            someText: this.translate.instant("DUPLICATE_DATA_FOUND"),
            key: "delete",
          },
        },
        size: CngModalSize.SMALL,
      })
      .onDismiss.toPromise();

    if (result == "ok") this.getDuplicateData(data);
  }
  getDuplicateData(data: any) {
    this.subscriptions.add(this.adminService.setDimmData(data));
  }

  //addional fields
  additionalFields: any = [];
  applyToRecords: any = [];
  updatedRecords: any = [];
  isAdditionFieldsEdited: boolean = false;

  openAdditionalAtt_Modal() {
    const dialogRef = this.dialog.open(AdditionalAttributesComponent, {
      data: {
        params: this.dimmsData,
        additionalFields: this.additionalFields,
        p_type: ComponentConstant.COMPONENT,
        type: ComponentConstant.DIMMS,
        edited: this.isAdditionFieldsEdited,
      },
      disableClose: true,
      panelClass: "width-dialog-100",
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result.data.action !== PlatformConstant.ACTION_CANCEL) {
        this.additionalFields = result.data.addedFields;
        this.applyToRecords = result.data.additionalRecords;
        this.isAdditionFieldsEdited = result.data.edited;
        //
        this.updatedRecords = [];
        this.additionalFields.forEach((element) => {
          if (
            element.flag == "UpdateField" &&
            element.hasOwnProperty("selectedItems") &&
            element.selectedItems?.length > 0
          ) {
            element.selectedItems.forEach((element2) => {
              if (
                element2.rowId !== this.dimmsData.id &&
                element2.flag !== "NewField"
              ) {
                let ele = this.updatedRecords.filter((value) => {
                  return value.id == element2.rowId;
                });

                if (ele?.length == 0) {
                  this.updatedRecords.push({
                    type: ComponentConstant.DIMMS,
                    role: "Admin",
                    id: element2.rowId,
                    additionalFields: [
                      {
                        name: element.name,
                        type: element.type,
                        value: element.value,
                        flag: element.flag,
                        previousName: element.previousName,
                      },
                    ],
                  });
                }
                //
                else {
                  ele[0].additionalFields.push({
                    name: element.name,
                    type: element.type,
                    value: element.value,
                    flag: element.flag,
                    previousName: element.previousName,
                  });
                }
              }
              //
            });
          }
        });
      }
    });
  }
  // end-of-addional-fields

  //pid search
  selectedPid: any = null;
  selectedPID(e: any) {
    this.selectedPid = e;
  }
  config = {
    displayKey: "viewValue",
    search: true,
    height: "200px",
    placeholder: "Select Option",
    moreText: "more",
    noResultsFound: "No results found!",
    searchPlaceholder: "Search",
    searchOnKey: "viewValue",
    clearOnSelection: true,
    inputDirection: "ltr",
  };
}
