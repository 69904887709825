import { HttpClient } from '@angular/common/http';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ToasterService } from "src/app/shared/components/toast/toast.service";
import { errorCodes } from "src/constants/errorCodes";
import {
  CngContentConfigType,
  CngModalService,
  CngModalSize,
} from "@cisco/cui-ng";
import { ConfirmationBoxComponent } from "src/app/shared/components/confirmation-box/confirmation-box.component";
import { AdminService } from 'src/app/services/admin.service';

@Component({
  selector: 'app-edit-header',
  templateUrl: './edit-header.component.html',
  styleUrls: ['./edit-header.component.css'],
})
export class EditHeaderComponent implements OnInit {
  subscriptions: Subscription = new Subscription();
  showLoader = true;
  headList_bk: any[] = null;
  headList: any[] = null;
  @BlockUI() blockUI: NgBlockUI; 

  constructor(
    //public dialogRef: MatDialogRef<EditHeaderComponent>,
    private apiService: ApiService,
    private toaster: ToasterService, 
    private translateService: TranslateService,
    private httpClient: HttpClient,
    private modalService: CngModalService,
    private translate: TranslateService,    
    private adminService: AdminService,

  ) {
    
  }

  ngOnInit(): void {
    this.getHeaderList();
  }

  reset() {
    this.headList = [];
    this.headList_bk.forEach((val) =>
      this.headList.push(Object.assign({}, val))
    );
    this.isformValid();
  }

  getHeaderList(action?:string) {
    this.blockUI.start('Loading...');
    const url = 'getHeaderUrlsList';
    this.subscriptions.add(
      this.apiService.getMethod(url).subscribe((result: any[]) => {
        
        this.headList_bk = [];
        result.forEach((val) => this.headList_bk.push(Object.assign({"enableEdit":false,"isUrlvalid":true}, val)));
        this.headList = [];

        result.forEach((val) => this.headList.push(Object.assign({"enableEdit":false,"isUrlvalid":true}, val)));
        this.showLoader = false;
        this.blockUI.stop();

        if(action == 'refresh'){
          //update header
          
          this.adminService.setHeaderData(result);
        }

        
      }, (error) => {
        console.error(error);
        let errorMsg = error?.error ? error.error?.error?.errorString : "Oops something went wrong!!";
        this.toaster.show('error', errorMsg, error ? error?.message : '');
        this.blockUI.stop(); 
      })
    );
  }
  lblErrMsg;
  updateHeadList(action:any,header?:any) {
    
    this.showLoader = true;
    this.blockUI.start('Loading...');
    let userData = JSON.parse(localStorage.getItem('userData'));
    let flag = 0;    
    let params = [];

  if(action == 'update'){
      this.headList.forEach((head: any) => {        
        let data = {
          id: head.id,
          title: head.title,
          cssClass: head.cssClass,
          position: head.position,
          url: head.url,
          user: JSON.parse(localStorage.getItem('userData'))?.mail.split("@")[0]?JSON.parse(localStorage.getItem('userData'))?.mail.split("@")[0]:"Admin",//userData.mail,
          role: 'Admin',
          deleteFlag : head.deleteFlag,
          newHeaderUrl : head.id ? false : true
        };
      params.push(data);
      });
    }
    else{
      params = [{
        id: header.id,
        title: header.title,
        cssClass: header.cssClass,
        position: header.position,
        url: header.url,
        user: JSON.parse(localStorage.getItem('userData'))?.mail.split("@")[0]?JSON.parse(localStorage.getItem('userData'))?.mail.split("@")[0]:"Admin",//userData.mail,
        role: 'Admin',
        deleteFlag : true,
        newHeaderUrl : header.id ? false : true
      }
    ];
    }

        this.subscriptions.add(
          this.apiService
            .postMethod('admin/updateHeaderUrls', params)// 
            .subscribe((returnData) => {
              

                if(action == 'update')
                  this.toaster.show('success', errorCodes.HEADER_UPDATED_MSG, '', 5000);
                else
                  this.toaster.show('success', errorCodes.HEADER_DELETED_MSG, '', 5000);

                // setTimeout(()=>{
               
                //   window.location.reload();
                //   
                  
                // }, 3000);
                this.blockUI.stop();
                this.getHeaderList('refresh');

              },error =>{
              this.blockUI.stop();
              let errorMsg = error?.error ? error.error?.error?.errorString : "Oops something went wrong!!";
              this.toaster.show('error', errorMsg , error ? error?.message : '',5000);
            })
        );

      
      
    
  }

  ngOnDestroy() {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }

  enableEdit(data:any){
    
    data.enableEdit = true;
  }

  isSaveEnabled:boolean = true;
  isformValid(){
    this.isSaveEnabled = true;
    this.headList.forEach((head: any) => {
      if (head.title =="" || head.title.trim() == "" || head.cssClass.trim() == "" 
          || head.url.trim()  == "" || head.position == "" || head.isUrlvalid == false) {
            this.isSaveEnabled = false;
      }
    });  
  }

  validateURL(e:any,data:any){
    // 
    // data.isUrlvalid =  e.includes("http:") || e.includes("https:");
    // 
    let url;
  
  try {
    url = new URL(e);  
    data.isUrlvalid = url.protocol === "http:" || url.protocol === "https:";

  } catch (_) {
    data.isUrlvalid = false;
  }

  }
  deleteHeader(header:any,deleteIndex){
    
    this.openModal(deleteIndex);
  }

  public async openModal(deleteIndex) {
    const result = await this.modalService
      .open({
        content: {
          type: CngContentConfigType.COMPONENT,
          content: ConfirmationBoxComponent,
          componentData: {
            someText: this.translate.instant(
              "ARE_YOU_SURE_TO_DELETE_THIS_HEADER"
            ),
            key: "edit",
          },
        },
        size: CngModalSize.SMALL,
      })
      .onDismiss.toPromise();

    if (result == 'ok'){
      let data = this.headList.splice(deleteIndex,1);
      
      if(data[0].id){
        this.updateHeadList('delete',data[0]);
      }
      else{
        this.toaster.show('success', errorCodes.HEADER_DELETED_MSG, '', 5000);
      }
    }
  
      

  }

  addHeader(){
    
    const data = {
      id: null,
      title: "",
      cssClass: "",
      position: this.headList.length + 1,
      url: "",
      deleteFlag : false,
      "enableEdit":true,
      "isUrlvalid":false,
       newHeaderUrl : true
    };
    this.headList.push(data);
    this.isformValid()

  }
}
