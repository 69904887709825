import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {ApiService} from "./services/api.service";
import {Router} from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class DataServiceService {
  private messageSource = new BehaviorSubject('default message');
  currentMessage = this.messageSource.asObservable();

  private applicationStatus = new BehaviorSubject(null);
  currrentStatus = this.applicationStatus.asObservable();

  private dbSttatus = new BehaviorSubject(null);
  dbStaus = this.dbSttatus.asObservable();
  res:any;
  constructor(private apiService: ApiService,  private router: Router,) { }

  changeMessage(message: string) {
    this.messageSource.next(message)
  }

  setAPPlicationStatus(flag) {
    this.applicationStatus.next(flag);
  }

  setDBStatus(flag) {
    this.dbSttatus.next(flag);
  }


}
