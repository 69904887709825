<div class="flex flex-between" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle style="margin-left: -25px;margin-bottom: 0px;">
    <button mat-button (click)="closeModal()"  class="btn btn--link focus" >Back</button>
    <div style="    display: flex;
    flex-direction: row-reverse;">
     <div class="flex-center margin_auto_t_b" >
        <a class="anchor-close" id="close" title="Close" (click)="closeModal()">
        <span class="icon-close icon-size-24 icon-close"></span>
        </a>
    </div>
</div>
</div>
<div style="min-height: 300px;max-height: 80vh;overflow: auto;">
<div>
    <h4>Associated Platforms</h4>
</div>
<div>
    <div class="col-md-12 row" *ngIf="!isFanPolicy">
        <div class="col-1" >
            Name
        </div>
        <div class="col-md-8 col-lg-8">
            :&nbsp;{{displayData.name}}
        </div>
     </div>
     <div class="col-md-12 row">
        <div class="col-1" >
            PID
        </div>
        <div class="col-md-8 col-lg-8">
            :&nbsp;{{displayData.pid}}
        </div>
     </div>
     
</div>
<br>

    <div class="col-md-12">
        <div>
            <ag-grid-angular
                class="ag-theme-alpine"
                [rowData]="rowData"
                [gridOptions]="gridOptions"
                style="width: 100%; height: 450px;"
                (gridReady)="onGridReady($event)"
                (firstDataRendered)="headerHeightSetter($event)"
                (columnResized)="headerHeightSetter($event)"  
                [frameworkComponents]="frameworkComponents"
                >
            </ag-grid-angular>
        </div>
    </div>

</div> 
