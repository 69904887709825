import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProjectConfigurationComponent } from './project-configuration/project-configuration.component';
import { ProjectDetailsSummaryComponent } from './project-details-summary/project-details-summary.component';
import { ProjectDetailsComponent } from './project-details.component';
import { ProjectResultsComponent } from './project-results/project-results.component';
import { ProjectRoutingModule } from './project-routing.module';
import { ServerDetailsComponent } from './server-details/server-details.component'
import { AgGridModule } from 'ag-grid-angular';
import { SelectDropDownModule } from 'ngx-select-dropdown'
import { SharedModule } from 'src/app/shared/shared.module';
import { CardComponent } from './card/card.component';
import { SeriesComponent } from './series/series.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DynamicFormComponent } from './dynamic-form/dynamic-form/dynamic-form.component';
import { OrderByPipe } from './dynamic-form/order-by.pipe';
import { MatSliderModule } from '@angular/material/slider';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BlockUIModule } from "ng-block-ui";
import { NgSelectModule } from '@ng-select/ng-select';

@NgModule({
  declarations: [
    ProjectDetailsComponent,
    ProjectDetailsSummaryComponent,
    ProjectConfigurationComponent,
    ProjectResultsComponent,
    ServerDetailsComponent,
    CardComponent,
    SeriesComponent,
    DynamicFormComponent,
    OrderByPipe,

  ],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    ProjectRoutingModule, ReactiveFormsModule,
    AgGridModule.withComponents([]),
    MatSliderModule, BlockUIModule,
    SelectDropDownModule, NgSelectModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ProjectDetailsModule { }
