<div class="howToVid">
<div id="loading-dots" *ngIf="showLoader">
    <div class="loading-dots loading-dots--dark" aria-label="Loading, please wait...">
      <span></span>
      <span></span>
      <span></span>
    </div>
  </div>
  <div class="document-container"  *ngIf="!showLoader">
    <div class="row base-margin-bottom">
        <div class="col-md-10">
            <h5>How To Videos</h5>
        </div>
    </div>
    <div class="row">
        <div class="col-md-10 document-grid-container">
    
          <ag-grid-angular style="height: 520px; width:100%" [rowData]="rowData" [columnDefs]="columnDefs"
            gridId="documentGrid" [pagination]="true" [paginationPageSize]="paginationPageSize"
            [frameworkComponents]="frameworkComponents" (gridReady)="onGridReady($event)" class="ag-theme-alpine"
            >
          </ag-grid-angular>
        </div>
        <div class="col-md-2"></div>
      </div>
</div>
</div>