import { Component, OnInit, Inject, HostListener, ChangeDetectorRef } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormControl, UntypedFormBuilder, UntypedFormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';
import { UrlConstant } from 'src/constants/url.constants';
import { ToasterService } from "src/app/shared/components/toast/toast.service";
import { PlatformConstant } from 'src/constants/platform.constants';
import { errorCodes } from "src/constants/errorCodes";
import { CngContentConfigType, CngModalService, CngModalSize } from '@cisco/cui-ng';
import { ConfirmationBoxComponent } from 'src/app/shared/components/confirmation-box/confirmation-box.component';
import { TranslateService } from '@ngx-translate/core';
import { AdminService } from 'src/app/services/admin.service';
import { AdditionalAttributesComponent } from 'src/app/shared/components/additional-attributes/additional-attributes.component';
import { DomSanitizer } from '@angular/platform-browser';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { AppConstant } from 'src/constants/app.constants';

@Component({
  selector: 'app-create-modular-chassis',
  templateUrl: './create-modular-chassis.component.html',
  styleUrls: ['./create-modular-chassis.component.css']
})
export class CreateModularChassisComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<CreateModularChassisComponent>,
    @Inject(MAT_DIALOG_DATA) public storageData: any,
    private apiService: ApiService,
    private toaster: ToasterService,
    private formBuilder: UntypedFormBuilder,
    private modalService: CngModalService,
    private translate: TranslateService,
    private adminService: AdminService,
    private _sanitizer: DomSanitizer,
    private cdr: ChangeDetectorRef,
    public dialog: MatDialog,) { }

  @BlockUI() blockUI: NgBlockUI;
  subscriptions: Subscription = new Subscription();
  errorMsg: any;
  powerCapLevel = PlatformConstant.POWER_CAP;

  modularChassisForm = new UntypedFormGroup({
    vendor: new UntypedFormControl('', [Validators.required, Validators.pattern(AppConstant.VALID_INPUT_REGEX)]),
    name: new UntypedFormControl('', [Validators.required, Validators.pattern(AppConstant.VALID_INPUT_REGEX)]),
    gen: new UntypedFormControl('', []),
    numPsuSlots: new UntypedFormControl('', [Validators.required, Validators.pattern('\\d+'), Validators.min(1)]),
    nMin: new UntypedFormControl('', [Validators.pattern('\\d+'), Validators.min(0)]),
    implicitCap: new UntypedFormControl('', []),
    pciSlots: new UntypedFormControl('', [Validators.pattern('\\d+'), Validators.min(0)]),
    maxDisks: new UntypedFormControl('', [Validators.pattern('\\d+'), Validators.min(0)]),
    numBladeSlots: new UntypedFormControl('', [Validators.required, Validators.pattern('\\d+'), Validators.min(1)]),
    weight: new UntypedFormControl('', [Validators.min(0.0000000001), Validators.required, Validators.pattern(AppConstant.VALID_INPUT_REGEX)]),
    idleBasePower: new UntypedFormControl('', [Validators.required, Validators.min(0.0000000001), Validators.pattern(AppConstant.VALID_INPUT_REGEX)]),
    maxBasePower: new UntypedFormControl('', [Validators.required, Validators.min(0.0000000001), Validators.pattern(AppConstant.VALID_INPUT_REGEX)]),
    mtbf: new UntypedFormControl('', []),
    discont: new UntypedFormControl('', [Validators.required]),
    conversionFactor: new UntypedFormControl('', [Validators.min(0.0000000001), Validators.pattern('^\\d*\\.?\\d+$')]),
    series: new UntypedFormControl('', [Validators.required]),
    primaryClass: new UntypedFormControl('', []),
    legacyPlatform: new UntypedFormControl('', []),

  });

  platformName: string = null
  showPID = true;
  isEdit: Boolean = false;

  ngOnInit(): void {
    this.showPID = true;

    this.adminService.getPlatformName().subscribe((data) => {
      this.platformName = data;

    });


    if (this.storageData.action === PlatformConstant.ACTION_CREATE) {
      this.isEdit = false;

      this.modularChassisForm.patchValue({ "implicitCap": this.powerCapLevel[0] });
      this.modularChassisForm.patchValue({ "discont": "0" });
      this.url = null;
      this.modularChassisForm.patchValue({ "conversionFactor": 1.1 });
      this.modularChassisForm.patchValue({ "primaryClass": "Compute" });

    }
    else {
      this.isEdit = true;

      this.modularChassisForm.patchValue(this.storageData.params);
      //for pid search
      this.selectedPid = this.storageData.params?.basePid;

      //additional-fields
      this.additionalFields = this.storageData.params?.additionalFields ?
        this.storageData.params.additionalFields : [];

      //to show legacy platform flag on edit 
      if (this.storageData.params?.legacyPlatform !== true) {
        if ((this.storageData.params?.legacyPlatform == false ||
          this.storageData.params?.legacyPlatform == null)
          && this.storageData.params?.isNewConverted == true
          && this.storageData.params?.isNewCreated == null) {
          this.modularChassisForm.patchValue({ "legacyPlatform": "false" });
        } else {
          this.modularChassisForm.patchValue({ "legacyPlatform": "true" });
        }
      }


      if (this.storageData.params?.discont == 1)
        this.modularChassisForm.patchValue({ "discont": "1" });
      else
        this.modularChassisForm.patchValue({ "discont": "0" });

      if (this.storageData.params?.implicitCap) {
        let value = this.powerCapLevel.find(row => this.storageData.params?.implicitCap == row.value);
        this.modularChassisForm.controls['implicitCap'].patchValue(value);
      }

      this.imageId = this.storageData.params?.imageId;
      if (this.imageId)
        this.getImage();
      else
        //this.url = null; 
        this.url = this.storageData.params?.url;

    }
    //Set Series
    this.subscriptions.add(this.adminService.getSeriesAll().subscribe((data) => {

      let addedSerieses = [];
      data?.forEach(element => {
        addedSerieses.push({
          value: element.series,
          viewValue: element.seriesDisplayName
        })
      });
      this.seriesList = addedSerieses;

      if (this.storageData.action === PlatformConstant.ACTION_CREATE) {
        let selectedseries = this.seriesList.filter(f => { return f.value == "mseries" });
        if (selectedseries?.length > 0)
          this.modularChassisForm.patchValue({ "series": selectedseries[0] });
      }
      else {
        let selectedseries = this.seriesList.filter(f => { return f.value == this.storageData.params?.series })
        if (selectedseries?.length > 0)
          this.modularChassisForm.patchValue({ "series": selectedseries[0] });
      }

    }));
  }
  submitInProgress: boolean = false;
  refreshData: boolean = false;
  submit(action: any) {
    this.blockUI.start('Loading...');

    if (this.modularChassisForm.valid) {
      this.submitInProgress = true;
      let params: any = {};

      if (this.storageData.action === PlatformConstant.ACTION_CREATE) {
        params = {
          "role": "Admin",
          "type": PlatformConstant.MODULAR_CHASSIS,
          "vendor": this.modularChassisForm.value.vendor,
          "name": this.modularChassisForm.value.name,
          "gen": this.modularChassisForm.value.gen,
          // "basePid" :this.modularChassisForm.value.basePid,
          "basePid": this.selectedPid,

          "numPsuSlots": this.modularChassisForm.value.numPsuSlots,
          "numIomSlots": 0,
          "numBladeSlots": this.modularChassisForm.value.numBladeSlots,
          "weight": this.modularChassisForm.value.weight,
          "idleBasePower": this.modularChassisForm.value.idleBasePower,
          "maxBasePower": this.modularChassisForm.value.maxBasePower,
          "discont": this.modularChassisForm.value.discont,
          "url": "",
          "pciSlots": this.modularChassisForm.value.pciSlots,
          "maxDisks": this.modularChassisForm.value.maxDisks,

          "nMin": this.modularChassisForm.value.nMin,
          "implicitCap": this.modularChassisForm.value.implicitCap.value ? this.modularChassisForm.value.implicitCap.value : 'none',
          "released": 0,
          "mtbf": this.modularChassisForm.value.mtbf,
          "ioms": [],
          "powerSupplies": [],
          "bladeServers": [],
          "catridgeServers": [],
          "ioes": [],
          "storages": [],
          "inValid": true,
          "allowDuplicates": false,
          "additionalFields": this.setAdditionalFields(),
          "imageId": this.imageId,
          "additionalComponents": [],
          "conversionFactor": this.modularChassisForm.value.conversionFactor,
          "series": this.modularChassisForm.value.series.value,
          "primaryClass": this.modularChassisForm.value.primaryClass,

          "legacyPlatform": false,
          "isNewCreated": true,
          "isNewConverted": null

        }
      }
      else {
        params = {
          "role": "Admin",
          "type": this.storageData.params.type,//type
          "id": this.storageData.params.id,//id
          "vendor": this.modularChassisForm.value.vendor,
          "name": this.modularChassisForm.value.name,
          "gen": this.modularChassisForm.value.gen,
          // "basePid" :this.modularChassisForm.value.basePid,
          "basePid": this.selectedPid,

          "numPsuSlots": this.modularChassisForm.value.numPsuSlots,
          "numBladeSlots": this.modularChassisForm.value.numBladeSlots,
          "weight": this.modularChassisForm.value.weight,
          "idleBasePower": this.modularChassisForm.value.idleBasePower,
          "maxBasePower": this.modularChassisForm.value.maxBasePower,
          "discont": this.modularChassisForm.value.discont,
          // "url" : "",
          "url": this.storageData.params.url,
          "pciSlots": this.modularChassisForm.value.pciSlots,
          "maxDisks": this.modularChassisForm.value.maxDisks,

          "nMin": this.modularChassisForm.value.nMin,
          "implicitCap": this.modularChassisForm.value.implicitCap.value ? this.modularChassisForm.value.implicitCap.value : 'none',
          "mtbf": this.modularChassisForm.value.mtbf,
          "allowDuplicates": false,
          "additionalFields": this.setAdditionalFields(),
          "released": this.storageData.params.released,
          "imageId": this.imageId,
          "conversionFactor": this.modularChassisForm.value.conversionFactor,
          "series": this.modularChassisForm.value.series.value,
          "primaryClass": this.storageData.params?.primaryClass,

          "legacyPlatform": this.storageData.params?.legacyPlatform,
          "isNewCreated": this.storageData.params?.isNewCreated,
          "isNewConverted": this.storageData.params?.legacyPlatform !== false ?
            this.modularChassisForm.value.legacyPlatform == "true" ? false : true : null



        }
      }

      const url = UrlConstant.MANAGE_PLATFORM;
      let params2 = [];
      params2.push(params);
      params2 = params2.concat(this.applyToRecords);
      params2 = params2.concat(this.updatedRecords);
      this.subscriptions.add(
        this.apiService.postMethod(url, params2).subscribe((data: any) => {
          this.blockUI.stop();
          this.submitInProgress = false;
          this.refreshData = true;
          if (data.modifiedCount > 0) {

            this.storageData.action === PlatformConstant.ACTION_CREATE
              ? this.toaster.show('success', errorCodes.NEW_RECORD, '', 5000)
              : this.toaster.show('success', errorCodes.UPDATE_RECORD, '', 5000);

            if (action == 'closeModal') {
              this.dialogRef.close({
                data: {
                  action: PlatformConstant.ACTION_REFRESH
                }
              });
            }
            else {
              if (this.storageData.action === PlatformConstant.ACTION_CREATE) {
                this.resetForm();
              }
            }
          } else if (data.modifiedCount == 0 && data.error.errorString == "Duplicate Data Found") {
            params["allowDuplicates"] = true;

            this.blockUI.start("Loading...");
            this.subscriptions.add(
              this.apiService.postMethod(url, params2).subscribe((data: any) => {
                this.blockUI.stop();
                if (data.modifiedCount > 0) {
                  this.storageData.action === PlatformConstant.ACTION_CREATE
                    ? this.toaster.show('success', errorCodes.NEW_RECORD, '', 5000)
                    : this.toaster.show('success', errorCodes.UPDATE_RECORD, '', 5000);
                  if (action == 'closeModal') {
                    window.scroll(0, 0);
                    this.dialogRef.close({
                      data: {
                        action: PlatformConstant.ACTION_REFRESH
                      }
                    });
                    this.openModal(params2);
                  } else if (this.storageData.action === PlatformConstant.ACTION_CREATE) {
                    this.resetForm();
                  }
                }
              }, (error) => {
                this.handleError(error);
              }))
          }
        }, error => {
          this.handleError(error);
        })
      );

    }
  }
  handleError(error) {
    this.blockUI.stop();
    this.errorMsg = error?.error ? error.error?.error?.errorString : "Oops something went wrong!!";
    this.dialogRef.close({
      data: {
        action: PlatformConstant.ACTION_CANCEL
      }
    });
    this.toaster.show('error', this.errorMsg, error ? error?.message : '');
  }
  setAdditionalFields() {
    let array = [];
    if (this.additionalFields?.length > 0) {
      this.additionalFields.forEach(element => {
        let row = {
          "name": element.name,
          "type": element.type,
          "value": element.value
        }
        array.push(row);
      });
    }
    else
      array = [];
    return array;
  }

  resetForm() {
    this.showPID = false;
    let seriesValue = this.modularChassisForm.get('series').value;
    this.modularChassisForm.reset();
    this.modularChassisForm.patchValue({ "implicitCap": this.powerCapLevel[0] });
    this.modularChassisForm.patchValue({ "discont": "0" });
    this.modularChassisForm.patchValue({ "primaryClass": "Compute" });
    this.modularChassisForm.patchValue({ "series": seriesValue });
    this.additionalFields = [];

    this.url = null;
    this.imageId = null;
    this.fileList = null;
    this.imageChanged = false;

    this.storageData = {
      type: PlatformConstant.MODULAR_CHASSIS,
      action: PlatformConstant.ACTION_CREATE
    }
    //search pid
    this.selectedPid = null;
    setTimeout(() => {
      this.showPID = true;
    }, 100);

  }

  //pid search
  selectedPid: any = null;
  selectedPID(e: any) {

    this.selectedPid = e;
  }

  onCancel() {

    this.dialogRef.close({
      data: {
        action: this.refreshData ? PlatformConstant.ACTION_REFRESH : PlatformConstant.ACTION_CANCEL
      }
    });
  }

  showWattErr: boolean = false;
  IdleMaxWattsErr: boolean = false;
  IdleWattsErr: boolean = false;
  mtbfErr: boolean = false;
  weightErr: boolean = false;
  validateWatts(e: any) {

    this.showWattErr = false;
    this.IdleMaxWattsErr = false;
    this.IdleWattsErr = false;
    this.mtbfErr = false;
    this.weightErr = false;

    if (this.modularChassisForm.value.idleBasePower && this.modularChassisForm.value.maxBasePower
      && !isNaN(this.modularChassisForm.value.idleBasePower) && !isNaN(this.modularChassisForm.value.maxBasePower)) {
      this.showWattErr = Number(this.modularChassisForm.value.maxBasePower) >= Number(this.modularChassisForm.value.idleBasePower)
        ? false : true;



      this.cdr.detectChanges();

    }
    if (this.modularChassisForm.value.idleBasePower && (isNaN(this.modularChassisForm.value.idleBasePower) || parseFloat(this.modularChassisForm.value.idleBasePower) < 0)) {

      //this.lblErrMessage = "Idle watts should be a Positive integer";
      this.IdleWattsErr = true;
      this.cdr.detectChanges();
    }
    if (this.modularChassisForm.value.maxBasePower && (isNaN(this.modularChassisForm.value.maxBasePower) || parseFloat(this.modularChassisForm.value.maxBasePower) < 0)) {

      //this.lblErrMessage = "Idle watts should be a Positive integer";
      this.IdleMaxWattsErr = true;
      this.cdr.detectChanges();
    }
    if (this.modularChassisForm.value.mtbf && (isNaN(this.modularChassisForm.value.mtbf) || Number(this.modularChassisForm.value.mtbf) < 0)) {

      this.mtbfErr = true;
      this.cdr.detectChanges();
    }
    if (this.modularChassisForm.value.weight && (isNaN(this.modularChassisForm.value.weight))) {

      this.weightErr = true;
      this.cdr.detectChanges();
    }

  }

  public async openModal(data: any) {
    const result = await this.modalService
      .open({
        content: {
          type: CngContentConfigType.COMPONENT,
          content: ConfirmationBoxComponent,
          componentData: {
            someText: this.translate.instant(
              "DUPLICATE_DATA_FOUND"
            ),
            key: "delete",
          },
        },
        size: CngModalSize.SMALL,
      })
      .onDismiss.toPromise();

    if (result == 'ok')
      this.getDuplicateData(data);
  }
  getDuplicateData(data: any) {
    this.subscriptions.add(this.adminService.setPlatformData(data));

  }

  //additional-fields
  additionalFields: any = [];
  applyToRecords: any = [];
  updatedRecords: any = [];
  isAdditionFieldsEdited: boolean = false;
  openAdditionalAtt_Modal() {


    const dialogRef = this.dialog.open(AdditionalAttributesComponent, {
      data: {
        params: this.storageData,
        additionalFields: this.additionalFields,
        fields: PlatformConstant.MODULAR_CHASSIS_FIELDS,
        p_type: PlatformConstant.PLATFORM,
        type: PlatformConstant.MODULAR_CHASSIS,
        edited: this.isAdditionFieldsEdited
      },
      disableClose: true,
      panelClass: 'width-dialog-100',
    });

    dialogRef.afterClosed().subscribe(result => {


      if (result.data.action !== PlatformConstant.ACTION_CANCEL) {
        this.additionalFields = result.data.addedFields;
        this.applyToRecords = result.data.additionalRecords;
        this.isAdditionFieldsEdited = result.data.edited;
        //
        this.updatedRecords = [];
        this.additionalFields.forEach(element => {
          if (element.flag == "UpdateField" && element.hasOwnProperty("selectedItems")
            && element.selectedItems?.length > 0) {
            element.selectedItems.forEach(element2 => {

              if (element2.rowId !== this.storageData.id && element2.flag !== "NewField") {
                let ele = this.updatedRecords.filter(value => { return value.id == element2.rowId });

                if (ele?.length == 0) {

                  this.updatedRecords.push({
                    "type": PlatformConstant.MODULAR_CHASSIS,
                    "role": "Admin",
                    "id": element2.rowId,
                    "additionalFields": [{
                      name: element.name,
                      type: element.type,
                      value: element.value,
                      flag: element.flag,
                      previousName: element.previousName
                    }]
                  })
                }
                //
                else {

                  ele[0].additionalFields.push({
                    name: element.name,
                    type: element.type,
                    value: element.value,
                    flag: element.flag,
                    previousName: element.previousName
                  })
                }
              }
              //
            });

          }
        });
      }

    });

  }

  //for image
  url: any;
  fileList: any = null;
  imageChanged: boolean = false;

  onImportImage(params: any) {
    // const files = params.target.files;
    this.fileList = params.target.files;
    this.imageChanged = true;

    // if (this.fileList && this.fileList.length > 0) {
    //   const extension = this.fileList[0].name.match(/\.([^\.]+)$/)[1];
    //   
    //   
    // }
    if (this.fileList && this.fileList.length > 0) {
      let file: File = this.fileList.item(0);
      let reader: FileReader = new FileReader();
      //image
      let img = new Image();
      img.src = window.URL.createObjectURL(file);

      reader.readAsDataURL(file);
      reader.onload = (e) => {
        setTimeout(() => {
          const width = img.naturalWidth;
          const height = img.naturalHeight;
          window.URL.revokeObjectURL(img.src);


          this.url = reader.result;
        }, 1500);
      }

    }
  }
  //for image
  imageId = null;
  imageUpload(action: any) {

    if (this.imageChanged && this.fileList[0]) {
      this.blockUI.start('Loading...');
      this.imageId = null;
      let imageFormData = new FormData();
      imageFormData.append("image", this.fileList[0]);
      const url = UrlConstant.UPLOAD_IMAGES;
      this.subscriptions.add(this.apiService.postMethodOnlyAuth(url, imageFormData).subscribe((data: any) => {
        this.blockUI.stop();
        if (data.id) {
          this.imageId = data.id;
          this.submit(action);
        }

      },
        (error) => {

          this.errorMsg = error?.error ? error.error?.error?.errorString : errorCodes.GENERIC_ERROR_MESSAGE;
          this.toaster.show("error", this.errorMsg, error ? error?.message : "");
          window.scroll(0, 0);
          this.blockUI.stop();
        }));
    }
    else {
      this.submit(action);
    }
  }

  getImage() {
    this.blockUI.start('Loading...');
    this.subscriptions.add(
      this.apiService.getMethod('admin/managePlatforms/getImage/?imageId=' + this.imageId).subscribe((data: any) => {
        // this.url2= data;

        this.blockUI.stop();
        this.url = this._sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,'
          + data[0].image);
      }, (error) => {

        this.blockUI.stop();
        this.errorMsg = error?.error ? error.error?.error?.errorString : errorCodes.GENERIC_ERROR_MESSAGE;
        this.toaster.show("error", this.errorMsg, error ? error?.message : "");
        window.scroll(0, 0);
        // this.showLoader = false;
      }));
  }

  pciSoltsErr: boolean = false;
  hdSoltsErr: boolean = false;

  validateSlots(e: any, type) {


    if (type == "pci") {
      this.pciSoltsErr = false;

      if (this.storageData.action == "Edit" && this.storageData.params?.ioes?.length > 0
        && (e == 0 || e == "")) {
        this.pciSoltsErr = true;
      }
    }
    if (type == "hd") {
      this.hdSoltsErr = false;

      if (this.storageData.action == "Edit" && this.storageData.params?.storages?.length > 0
        && (e == 0 || e == "")) {
        this.hdSoltsErr = true;
      }
    }
  }

  seriesList = [];
  config = {
    displayKey: "viewValue",
    search: true,
    height: '150px',
    placeholder: 'Select Option',
    moreText: 'more',
    noResultsFound: 'No results found!',
    searchPlaceholder: 'Search',
    searchOnKey: 'viewValue',
    clearOnSelection: false,
    inputDirection: 'ltr'
  }

  legacyPlatformRadioChange(e: any) {
    if (this.storageData.params?.isNewConverted == true
      && this.modularChassisForm.value.legacyPlatform == "true"
      && this.storageData.params.fanPolicyPid) {
      this.toaster.show("error",
        errorCodes.ADMIN_FAN_WAR,
        errorCodes.ADMIN_FAN_WAR_RM_MSG,
        5000);
    }
  }
}
