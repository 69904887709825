import { Component, OnInit, Inject, HostListener, ChangeDetectorRef } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormControl, UntypedFormBuilder, UntypedFormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';
import { UrlConstant } from 'src/constants/url.constants';
import { ToasterService } from "src/app/shared/components/toast/toast.service";
import { PlatformConstant } from 'src/constants/platform.constants';
import { errorCodes } from "src/constants/errorCodes";
import { CngContentConfigType, CngModalService, CngModalSize } from '@cisco/cui-ng';
import { ConfirmationBoxComponent } from 'src/app/shared/components/confirmation-box/confirmation-box.component';
import { TranslateService } from '@ngx-translate/core';
import { AdminService } from 'src/app/services/admin.service';
import { AdditionalAttributesComponent } from 'src/app/shared/components/additional-attributes/additional-attributes.component';
import { DomSanitizer } from '@angular/platform-browser';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { AppConstant } from 'src/constants/app.constants';

@Component({
  selector: 'app-create-fabric-extenders',
  templateUrl: './create-fabric-extenders.component.html',
  styleUrls: ['./create-fabric-extenders.component.css']
})
export class CreateFabricExtendersComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<CreateFabricExtendersComponent>,
    @Inject(MAT_DIALOG_DATA) public storageData: any,
    private apiService: ApiService,
    private toaster: ToasterService,
    private formBuilder: UntypedFormBuilder,
    private modalService: CngModalService,
    private translate: TranslateService,
    private adminService: AdminService,
    public dialog: MatDialog,
    private _sanitizer: DomSanitizer,
    private cdr: ChangeDetectorRef) { }

  @BlockUI() blockUI: NgBlockUI;

  subscriptions: Subscription = new Subscription();
  errorMsg: any;
  fabricExtenderForm = new UntypedFormGroup({
    vendor: new UntypedFormControl('', [Validators.required, Validators.pattern(AppConstant.VALID_INPUT_REGEX)]),
    name: new UntypedFormControl('', [Validators.required, Validators.pattern(AppConstant.VALID_INPUT_REGEX)]),
    gen: new UntypedFormControl('', []),
    maxPsus: new UntypedFormControl('', [Validators.required, Validators.pattern('\\d+'), Validators.min(1)]),
    maxExpSlots: new UntypedFormControl('', [Validators.pattern('\\d+'), Validators.min(0)]),
    height: new UntypedFormControl('', [Validators.pattern('\\d+'), Validators.min(0)]),
    weight: new UntypedFormControl('', [Validators.min(0.0000000001), Validators.required, Validators.pattern(AppConstant.VALID_INPUT_REGEX)]),

    baseIdle: new UntypedFormControl('', [Validators.required, Validators.min(0.0000000001), Validators.required, Validators.pattern(AppConstant.VALID_INPUT_REGEX)]),
    baseMax: new UntypedFormControl('', [Validators.required, Validators.min(0.0000000001), Validators.required, Validators.pattern(AppConstant.VALID_INPUT_REGEX)]),

    maxPorts: new UntypedFormControl('', [Validators.required, Validators.pattern('\\d+'), Validators.min(1)]),
    idlePerPort: new UntypedFormControl('', [Validators.required, Validators.required, Validators.min(0.0000000001), Validators.pattern(AppConstant.VALID_INPUT_REGEX)]),
    maxPerPort: new UntypedFormControl('', [Validators.required, Validators.required, Validators.min(0.0000000001), Validators.pattern(AppConstant.VALID_INPUT_REGEX)]),
    mtbf: new UntypedFormControl('',),

    discont: new UntypedFormControl('', [Validators.required]),
    conversionFactor: new UntypedFormControl('', [Validators.min(0.0000000001), Validators.pattern('^\\d*\\.?\\d+$')]),
    series: new UntypedFormControl('', [Validators.required]),
    primaryClass: new UntypedFormControl('', []),
    legacyPlatform: new UntypedFormControl('', []),

  });

  showPID = true;
  platformName: string = null;
  isEdit: Boolean = false;

  ngOnInit(): void {
    this.showPID = true;

    this.adminService.getPlatformName().subscribe((data) => {
      this.platformName = data;

    });


    if (this.storageData.action === PlatformConstant.ACTION_CREATE) {
      this.isEdit = false;

      this.fabricExtenderForm.patchValue({ "discont": "0" });
      this.url = null;
      this.fabricExtenderForm.patchValue({ "conversionFactor": 1.1 });
      this.fabricExtenderForm.patchValue({ "primaryClass": "Networking" });

    }
    else {
      this.isEdit = true;


      this.fabricExtenderForm.patchValue(this.storageData.params);

      //for pid search
      this.selectedPid = this.storageData.params?.basePid;

      //additional-fields
      this.additionalFields = this.storageData.params?.additionalFields ?
        this.storageData.params.additionalFields : [];

      //to show legacy platform flag on edit 
      if (this.storageData.params?.legacyPlatform !== true) {
        if ((this.storageData.params?.legacyPlatform == false ||
          this.storageData.params?.legacyPlatform == null)
          && this.storageData.params?.isNewConverted == true
          && this.storageData.params?.isNewCreated == null) {
          this.fabricExtenderForm.patchValue({ "legacyPlatform": "false" });
        } else {
          this.fabricExtenderForm.patchValue({ "legacyPlatform": "true" });
        }
      }

      if (this.storageData.params?.discont == 1)
        this.fabricExtenderForm.patchValue({ "discont": "1" });
      else
        this.fabricExtenderForm.patchValue({ "discont": "0" });

      this.imageId = this.storageData.params?.imageId;
      if (this.imageId)
        this.getImage();
      else
        //this.url = null;
        this.url = this.storageData.params?.url;

    }
    //Set Series
    this.subscriptions.add(this.adminService.getSeriesAll().subscribe((data) => {

      let addedSerieses = [];
      data?.forEach(element => {
        addedSerieses.push({
          value: element.series,
          viewValue: element.seriesDisplayName
        })
      });
      this.seriesList = addedSerieses;

      if (this.storageData.action === PlatformConstant.ACTION_CREATE) {
        let selectedseries = this.seriesList.filter(f => { return f.value == "Fabric Extender" });
        if (selectedseries?.length > 0)
          this.fabricExtenderForm.patchValue({ "series": selectedseries[0] });
      }
      else {
        let selectedseries = this.seriesList.filter(f => { return f.value == this.storageData.params?.series })
        if (selectedseries?.length > 0)
          this.fabricExtenderForm.patchValue({ "series": selectedseries[0] });
      }

    }));
  }

  refreshData: boolean = false;
  submitInProgress: boolean = false;
  submit(action: any) {

    if (this.fabricExtenderForm.valid) {
      this.blockUI.start('Loading...');

      this.submitInProgress = true;
      let params = {};
      if (this.storageData.action === PlatformConstant.ACTION_CREATE) {
        params = {
          "role": "Admin",
          "type": PlatformConstant.FABRIC_EXTENDERS,
          "vendor": this.fabricExtenderForm.value.vendor,
          "name": this.fabricExtenderForm.value.name,
          "gen": this.fabricExtenderForm.value.gen,
          // "basePid" :this.fabricExtenderForm.value.basePid,
          "basePid": this.selectedPid,

          "weight": this.fabricExtenderForm.value.weight,
          "discont": this.fabricExtenderForm.value.discont,
          "url": "",
          "released": 0,

          "mtbf": this.fabricExtenderForm.value.mtbf,
          "powerSupplies": [

          ],
          "maxPsus": this.fabricExtenderForm.value.maxPsus,
          "height": this.fabricExtenderForm.value.height,
          "switchType": "NEXUS",
          "maxExpSlots": this.fabricExtenderForm.value.maxExpSlots,
          "maxPorts": this.fabricExtenderForm.value.maxPorts,
          "baseIdle": this.fabricExtenderForm.value.baseIdle,
          "baseMax": this.fabricExtenderForm.value.baseMax,
          "idlePerPort": this.fabricExtenderForm.value.idlePerPort,
          "maxPerPort": this.fabricExtenderForm.value.maxPerPort,
          "expansions": [],
          "inValid": true,
          "allowDuplicates": false,
          "additionalFields": this.setAdditionalFields(),
          "imageId": this.imageId,
          "additionalComponents": [],
          "conversionFactor": this.fabricExtenderForm.value.conversionFactor,
          "series": this.fabricExtenderForm.value.series.value,
          "primaryClass": this.fabricExtenderForm.value.primaryClass,

          "legacyPlatform": false,
          "isNewCreated": true,
          "isNewConverted": null




        }
      }
      else {
        params = {
          "role": "Admin",
          "type": this.storageData.params.type,//type
          "id": this.storageData.params.id,//id
          "vendor": this.fabricExtenderForm.value.vendor,
          "name": this.fabricExtenderForm.value.name,
          "gen": this.fabricExtenderForm.value.gen,
          // "basePid" :this.fabricExtenderForm.value.basePid,
          "basePid": this.selectedPid,

          "weight": this.fabricExtenderForm.value.weight,
          "discont": this.fabricExtenderForm.value.discont,
          //"url" : "",
          "url": this.storageData.params.url,
          "released": this.storageData.params.released,

          "mtbf": this.fabricExtenderForm.value.mtbf,
          "maxPsus": this.fabricExtenderForm.value.maxPsus,
          "height": this.fabricExtenderForm.value.height,
          "switchType": "NEXUS",
          "maxExpSlots": this.fabricExtenderForm.value.maxExpSlots,
          "maxPorts": this.fabricExtenderForm.value.maxPorts,
          "baseIdle": this.fabricExtenderForm.value.baseIdle,
          "baseMax": this.fabricExtenderForm.value.baseMax,
          "idlePerPort": this.fabricExtenderForm.value.idlePerPort,
          "maxPerPort": this.fabricExtenderForm.value.maxPerPort,
          "allowDuplicates": false,
          "additionalFields": this.setAdditionalFields(),
          "imageId": this.imageId,
          "conversionFactor": this.fabricExtenderForm.value.conversionFactor,
          "series": this.fabricExtenderForm.value.series.value,
          "primaryClass": this.fabricExtenderForm.value.primaryClass,

          "legacyPlatform": this.storageData.params?.legacyPlatform,
          "isNewCreated": this.storageData.params?.isNewCreated,
          "isNewConverted": this.storageData.params?.legacyPlatform !== false ?
            this.fabricExtenderForm.value.legacyPlatform == "true" ? false : true : null

        }
      }


      const url = UrlConstant.MANAGE_PLATFORM;
      let params2 = [];
      params2.push(params);
      params2 = params2.concat(this.applyToRecords);
      params2 = params2.concat(this.updatedRecords);
      this.subscriptions.add(
        this.apiService.postMethod(url, params2).subscribe((data: any) => {
          this.submitInProgress = false;
          this.refreshData = true;

          this.blockUI.stop();
          if (data.modifiedCount > 0) {

            this.storageData.action === PlatformConstant.ACTION_CREATE
              ? this.toaster.show('success', errorCodes.NEW_RECORD, '', 5000)
              : this.toaster.show('success', errorCodes.UPDATE_RECORD, '', 5000);

            if (action == 'closeModal') {
              this.dialogRef.close({
                data: {
                  action: PlatformConstant.ACTION_REFRESH
                }
              });
            }
            else {
              if (this.storageData.action === PlatformConstant.ACTION_CREATE) {
                this.resetForm();
              }
            }

          } else if (data.modifiedCount == 0 && data.error.errorString == "Duplicate Data Found") {
            params["allowDuplicates"] = true;
            this.blockUI.start("Loading...");
            this.subscriptions.add(
              this.apiService.postMethod(url, params2).subscribe((data: any) => {
                this.blockUI.stop();
                if (data.modifiedCount > 0) {
                  this.storageData.action === PlatformConstant.ACTION_CREATE
                    ? this.toaster.show('success', errorCodes.NEW_RECORD, '', 5000)
                    : this.toaster.show('success', errorCodes.UPDATE_RECORD, '', 5000);
                  if (action == 'closeModal') {
                    window.scroll(0, 0);
                    this.dialogRef.close({
                      data: {
                        action: PlatformConstant.ACTION_REFRESH
                      }
                    });
                    this.openModal(params2);
                  } else if (this.storageData.action === PlatformConstant.ACTION_CREATE) {
                    this.resetForm();
                  }

                }
              }, (error) => {
                this.handleError(error);
              }))
          }
        }, error => {
          this.handleError(error);
        })
      );

    }
  }
  handleError(error) {
    this.blockUI.stop();
    this.errorMsg = error?.error ? error.error?.error?.errorString : "Oops something went wrong!!";
    this.dialogRef.close({
      data: {
        action: PlatformConstant.ACTION_CANCEL
      }
    });
    this.toaster.show('error', this.errorMsg, error ? error?.message : '');
  }
  setAdditionalFields() {
    let array = [];
    if (this.additionalFields?.length > 0) {
      this.additionalFields.forEach(element => {
        let row = {
          "name": element.name,
          "type": element.type,
          "value": element.value
        }
        array.push(row);
      });
    }
    else
      array = [];
    return array;
  }
  resetForm() {
    this.showPID = false;
    let seriesValue = this.fabricExtenderForm.get('series').value;
    this.fabricExtenderForm.reset();
    this.fabricExtenderForm.patchValue({ "discont": "0" });
    this.fabricExtenderForm.patchValue({ "primaryClass": "Networking" });
    this.fabricExtenderForm.patchValue({ "series": seriesValue });
    this.additionalFields = [];

    this.url = null;
    this.imageId = null;
    this.fileList = null;
    this.imageChanged = false;

    this.storageData = {
      type: PlatformConstant.FABRIC_EXTENDERS,
      action: PlatformConstant.ACTION_CREATE
    }
    //search pid
    this.selectedPid = null;
    setTimeout(() => {
      this.showPID = true;
    }, 100);
  }


  //pid search
  selectedPid: any = null;
  selectedPID(e: any) {

    this.selectedPid = e;
  }

  onCancel() {

    this.dialogRef.close({
      data: {
        action: this.refreshData ? PlatformConstant.ACTION_REFRESH : PlatformConstant.ACTION_CANCEL
      }
    });
  }

  showWattErr: boolean = false;
  IdleMaxWattsErr: boolean = false;
  IdleWattsErr: boolean = false;
  mtbfErr: boolean = false;
  idlePortErr: boolean = false;
  maxPortErr: boolean = false;
  showPortErr: boolean = false;
  weightErr: boolean = false;

  validateWatts(e: any) {

    this.showWattErr = false;
    this.IdleMaxWattsErr = false;
    this.IdleWattsErr = false;
    this.mtbfErr = false;
    this.idlePortErr = false;
    this.maxPortErr = false;
    this.showPortErr = false;
    this.weightErr = false;


    if (this.fabricExtenderForm.value.baseIdle && this.fabricExtenderForm.value.baseMax
      && !isNaN(this.fabricExtenderForm.value.baseIdle) && !isNaN(this.fabricExtenderForm.value.baseMax)) {
      this.showWattErr = Number(this.fabricExtenderForm.value.baseMax) >= Number(this.fabricExtenderForm.value.baseIdle)
        ? false : true;



      this.cdr.detectChanges();

    }
    if (this.fabricExtenderForm.value.baseIdle && (isNaN(this.fabricExtenderForm.value.baseIdle) || parseFloat(this.fabricExtenderForm.value.baseIdle) < 0)) {

      //this.lblErrMessage = "Idle watts should be a Positive integer";
      this.IdleWattsErr = true;
      this.cdr.detectChanges();
    }
    if (this.fabricExtenderForm.value.baseMax && (isNaN(this.fabricExtenderForm.value.baseMax) || parseFloat(this.fabricExtenderForm.value.baseMax) < 0)) {

      //this.lblErrMessage = "Idle watts should be a Positive integer";
      this.IdleMaxWattsErr = true;
      this.cdr.detectChanges();
    }

    if (this.fabricExtenderForm.value.mtbf && (isNaN(this.fabricExtenderForm.value.mtbf) || Number(this.fabricExtenderForm.value.mtbf) < 0)) {

      this.mtbfErr = true;
      this.cdr.detectChanges();
    }

    if (this.fabricExtenderForm.value.idlePerPort && this.fabricExtenderForm.value.maxPerPort
      && !isNaN(this.fabricExtenderForm.value.idlePerPort) && !isNaN(this.fabricExtenderForm.value.maxPerPort)) {
      this.showPortErr = Number(this.fabricExtenderForm.value.maxPerPort) >= Number(this.fabricExtenderForm.value.idlePerPort)
        ? false : true;



      this.cdr.detectChanges();

    }
    if (this.fabricExtenderForm.value.idlePerPort && (isNaN(this.fabricExtenderForm.value.idlePerPort) || parseFloat(this.fabricExtenderForm.value.idlePerPort) < 0)) {

      //this.lblErrMessage = "Idle watts should be a Positive integer";
      this.idlePortErr = true;
      this.cdr.detectChanges();
    }
    if (this.fabricExtenderForm.value.maxPerPort && (isNaN(this.fabricExtenderForm.value.maxPerPort) || parseFloat(this.fabricExtenderForm.value.maxPerPort) < 0)) {

      //this.lblErrMessage = "Idle watts should be a Positive integer";
      this.maxPortErr = true;
      this.cdr.detectChanges();
    }

    if (this.fabricExtenderForm.value.weight && (isNaN(this.fabricExtenderForm.value.weight))) {

      this.weightErr = true;
      this.cdr.detectChanges();
    }

  }

  public async openModal(data: any) {
    const result = await this.modalService
      .open({
        content: {
          type: CngContentConfigType.COMPONENT,
          content: ConfirmationBoxComponent,
          componentData: {
            someText: this.translate.instant(
              "DUPLICATE_DATA_FOUND"
            ),
            key: "delete",
          },
        },
        size: CngModalSize.SMALL,
      })
      .onDismiss.toPromise();

    if (result == 'ok')
      this.getDuplicateData(data);
  }
  getDuplicateData(data: any) {
    this.subscriptions.add(this.adminService.setPlatformData(data));

  }

  //additional-fields
  additionalFields: any = [];
  applyToRecords: any = [];
  updatedRecords: any = [];
  isAdditionFieldsEdited: boolean = false;
  openAdditionalAtt_Modal() {


    const dialogRef = this.dialog.open(AdditionalAttributesComponent, {
      data: {
        params: this.storageData,
        additionalFields: this.additionalFields,
        fields: PlatformConstant.FABRIC_FIELDS,
        p_type: PlatformConstant.PLATFORM,
        type: PlatformConstant.FABRIC_EXTENDERS,
        edited: this.isAdditionFieldsEdited
      },
      disableClose: true,
      panelClass: 'width-dialog-100',
    });

    dialogRef.afterClosed().subscribe(result => {


      if (result.data.action !== PlatformConstant.ACTION_CANCEL) {
        this.additionalFields = result.data.addedFields;
        this.applyToRecords = result.data.additionalRecords;
        this.isAdditionFieldsEdited = result.data.edited;
        //
        this.updatedRecords = [];
        this.additionalFields.forEach(element => {
          if (element.flag == "UpdateField" && element.hasOwnProperty("selectedItems")
            && element.selectedItems?.length > 0) {
            element.selectedItems.forEach(element2 => {

              if (element2.rowId !== this.storageData.id && element2.flag !== "NewField") {
                let ele = this.updatedRecords.filter(value => { return value.id == element2.rowId });

                if (ele?.length == 0) {

                  this.updatedRecords.push({
                    "type": PlatformConstant.FABRIC_EXTENDERS,
                    "role": "Admin",
                    "id": element2.rowId,
                    "additionalFields": [{
                      name: element.name,
                      type: element.type,
                      value: element.value,
                      flag: element.flag,
                      previousName: element.previousName
                    }]
                  })
                }
                //
                else {

                  ele[0].additionalFields.push({
                    name: element.name,
                    type: element.type,
                    value: element.value,
                    flag: element.flag,
                    previousName: element.previousName
                  })
                }
              }
              //
            });

          }
        });
      }

    });

  }
  //for image
  url: any;
  fileList: any = null;
  imageChanged: boolean = false;

  onImportImage(params: any) {

    this.fileList = params.target.files;
    this.imageChanged = true;

    if (this.fileList && this.fileList.length > 0) {
      let file: File = this.fileList.item(0);
      let reader: FileReader = new FileReader();
      let img = new Image();
      img.src = window.URL.createObjectURL(file);

      reader.readAsDataURL(file);
      reader.onload = (e) => {
        setTimeout(() => {
          const width = img.naturalWidth;
          const height = img.naturalHeight;
          window.URL.revokeObjectURL(img.src);


          this.url = reader.result;
        }, 1500);
      }

    }
  }

  imageId = null;
  imageUpload(action: any) {

    if (this.imageChanged && this.fileList[0]) {
      this.blockUI.start('Loading...');
      this.imageId = null;
      let imageFormData = new FormData();
      imageFormData.append("image", this.fileList[0]);
      const url = UrlConstant.UPLOAD_IMAGES;
      this.subscriptions.add(this.apiService.postMethodOnlyAuth(url, imageFormData).subscribe((data: any) => {
        this.blockUI.stop();
        if (data.id) {
          this.imageId = data.id;
          this.submit(action);
        }

      },
        (error) => {

          this.errorMsg = error?.error ? error.error?.error?.errorString : errorCodes.GENERIC_ERROR_MESSAGE;
          this.toaster.show("error", this.errorMsg, error ? error?.message : "");
          window.scroll(0, 0);
          this.blockUI.stop();
        }));
    }
    else {
      this.submit(action);
    }
  }

  getImage() {
    this.blockUI.start('Loading...');
    this.subscriptions.add(
      this.apiService.getMethod('admin/managePlatforms/getImage/?imageId=' + this.imageId).subscribe((data: any) => {
        // this.url2= data;

        this.blockUI.stop();
        this.url = this._sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,'
          + data[0].image);

      }, (error) => {

        this.blockUI.stop();
        this.errorMsg = error?.error ? error.error?.error?.errorString : errorCodes.GENERIC_ERROR_MESSAGE;
        this.toaster.show("error", this.errorMsg, error ? error?.message : "");
        window.scroll(0, 0);
        // this.showLoader = false;
      }));
  }
  maxExpErr: boolean = false;
  validateMaxExpSlots(e: any) {
    this.maxExpErr = false;

    if (this.storageData.action == "Edit" && this.storageData.params?.expansions?.length > 0
      && (e == 0 || e == "")) {
      // this.toaster.show("error", "Expansion Cannot set to 0 because expansion cards assigned","",5000);
      this.maxExpErr = true;

    }

  }

  seriesList = [{ value: "bseriestest", viewValue: "bseriestest" }];
  config = {
    displayKey: "viewValue",
    search: true,
    height: '150px',
    placeholder: 'Select Option',
    moreText: 'more',
    noResultsFound: 'No results found!',
    searchPlaceholder: 'Search',
    searchOnKey: 'viewValue',
    clearOnSelection: false,
    inputDirection: 'ltr'
  }

  legacyPlatformRadioChange(e: any) {
    if (this.storageData.params?.isNewConverted == true
      && this.fabricExtenderForm.value.legacyPlatform == "true"
      && this.storageData.params.fanPolicyPid) {
      this.toaster.show("error",
        errorCodes.ADMIN_FAN_WAR,
        errorCodes.ADMIN_FAN_WAR_RM_MSG,
        5000);
    }
  }

}
