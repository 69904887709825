
    <div class="header" style="height: auto;">
        <div class="header-panels">
            <div class="header-panel" style="margin-left: 10px;">
                <a class="header__logo" href="http://www.cisco.com" target="_blank">
                    <span class="icon-cisco"></span>
                        </a>
                <div class="header__title">
                    <a class="header-text-div" (click)="onHeaderClick()">UCS {{ 'POWER' | translate }} {{ 'CALCULATOR' | translate }} </a>
                </div>
            </div>
            <div *ngIf="false" class="flex-live-supp"> 
                <div class="flex-center">
                    <span class="icon-group-chat icon-size-16"></span>
                </div>
                <a> Live Support</a>
            </div>
            <div *ngIf="showHeader" class="header-panel header-panel--right flex-wrap-768" style="margin-right: 10px;
            ">
                
               
                <ng-container *ngFor="let header of headList">
                <div class="flex-center">
                    <span [class]="header.cssClass + ' icon-size-14'"></span>
                </div>
                <a class="header-item" [href]="header.url" target="_blank">{{header.title |translate}}</a>
                </ng-container>

                <div *ngIf="userData" style="display: flex;">
                <div class="flex-center">
                    <span class="icon-contact icon-size-14" style="color: #03bbeb;"></span>
                </div>
                <a mat-button [matMenuTriggerFor]="menu" class="header-item" style="font-weight: bold;padding-top: 2px;color: #03bbeb;">{{userData.givenName}}</a>
                <mat-menu #menu="matMenu">
                    <a style="width: 100%;padding-top: 5px;padding-left: 38px;" class="header-item" (click)="logout()"  mat-menu-item>{{ 'Logout' | translate }}</a>
                  </mat-menu>
                </div>
                <a style="margin-left: 15px;" *ngIf="!userData" (click)="login()" class="btn btn--small"> {{ 'LOGIN' | translate }} </a>
            
            
            </div>
        </div>
    </div>

