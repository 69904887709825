<div class="flex-center" *ngIf="label === 'Edit'">
    <a (click)="onClick($event, 'navigate')" fragment="finalResults">
     {{params?.data?.projectName}}
    </a>
  </div>
  <div class="flex-center" *ngIf="label === 'Sizer'">
    <a (click)="onClick($event, 'sizer')" fragment="finalResults">
     {{params?.data?.projectName}}
    </a>
  </div>
  <div class="flex-center" *ngIf="label === 'DownLoad'">
    <a (click)="onClick($event, 'download')" id="download" title="Download">
      <span class="icon-download icon-size-20 icon-color"></span>
    </a>
  </div>
  <div class="flex-center" *ngIf="label === 'Clone'">
    <a (click)="openModal($event, 'clone')" id="cloneProject" title="Clone" class="anchor-clone"> 
    <span class="icon-clone icon-size-20 icon-color"></span>
    </a>
  </div>
  <!-- <div class="flex-center" *ngIf="label === 'Delete'">
    <a (click)="onClick($event)">
    <span class="icon-delete icon-size-20 icon-delete"></span>
    </a>
  </div> -->
   <div class="flex-center" *ngIf="label === 'Delete'">
    <a (click)="openModal($event,'delete')" class="anchor-close" id="delete" title="Delete">
    <span class="icon-delete icon-size-20 icon-delete"></span>
    </a>
  </div>
  