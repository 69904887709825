<mat-card-title> 
    <div mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle 
    style="margin-bottom: 8px;"><h4 style="float: left;font-size: large;font-weight: 400;"> {{ data.header }} </h4> 
      <div style="display:flex;
      flex-direction: row-reverse;">
      <div class="flex-center margin_auto_t_b" >
        <a class="anchor-close" id="close" title="Close" (click)="onCancel()">
        <span class="icon-close icon-size-24 icon-close"></span>
        </a>
    </div>
    </div>
    </div>
</mat-card-title>

<mat-dialog-content>
    <mat-form-field>
        <div class="form-group base-margin-bottom" style="max-width:800px; min-width:500px; min-height: 150px;">
            <div class="row base-margin-bottom">
                <div class="col-3">
                    Title
                </div>
                <div class="col-1"></div>
                <div class="col-8">
                    <ngx-select-dropdown
                    [multiple]="false"
                    [options]="headers"
                    [config]="{displayKey:'viewValue'}"
                    (change)="onSelectHeader($event)"
                    >
                    </ngx-select-dropdown>
                </div>
            </div>
            <div class="row base-margin-bottom">
                <div class="col-8">
                    <div *ngIf="isFileChosen">
                       {{ fileName }} 
                    </div>
                </div>
                <div class="col-4 div-upload">
                    <input #file type="file" name="uploadfile" id="uploadDocument" style="display:none;" (change)="onChooseFile($event)" />
                    <button *ngIf="header==''" class="btn btn--primary" [disabled]="true">Choose File</button>
                    <label *ngIf="header!==''" for="uploadDocument" class="btn btn--primary upload-document-btn" [disabled]="header==''">Choose File</label>
                </div>
            </div>
            
        </div>
    </mat-form-field>
</mat-dialog-content>
<!-- <form autocomplete="off">
    <mat-form-field>
       <div class="form-group base-margin-bottom" style="max-width: 800px; min-width: 500px;">
            <table class="table table-bordered">
                <tr>
                    <td class="mandatory">
                        <mat-label>Header</mat-label>
                        <mat-error *ngIf="header.trim()==''" style="color:red"></mat-error>
                    </td>
                    <td>
                        <div class="form-group">
                        <div class="form-group__text">
                            <ngx-select-dropdown
                            [multiple]="false"
                            [options]="headers"
                            [config]="{displayKey:'viewValue'}"
                            >
                            </ngx-select-dropdown>
                            </div>
                        </div>
                        
                    </td>
                </tr>
                <tr>
                    <td></td> <td></td>
                </tr>
                <tr>
                <td class="mandatory">
                    <mat-label>Upload File</mat-label>
                    <mat-error *ngIf="createComponentForm.get('errorMessage').hasError('required')" style="color:red"></mat-error> 
                </td>
                <td>
                    <div class="form-group">
                        <div class="form-group__text">
                            <textarea matInput  required></textarea>
                        </div>
                    </div>
                </td>
            </tr> 

            <tr>
                <td></td> <td></td>
            </tr>

            </table>
        </div>
    </mat-form-field>
</form> -->
 

<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close class="btn btn--small"(click)="onUploadDocument()" [disabled]="header==''||isFileChosen==false">Upload</button>
    <button mat-button class="btn btn--small btn--danger" cdkFocusInitial (click)="onCancel()">Cancel</button>
</mat-dialog-actions>