<div class="flex flex-between"  cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle 
style="margin-left: -25px;margin-bottom: 0px;">
    <button mat-button class="btn btn--link focus" style="margin: 0px 15px;" (click)="closeModal()" >Back</button>
    <div style="display: flex;flex-direction: row-reverse;">
        <div class="flex-center margin_auto_t_b">
            <a class="anchor-close" id="close" title="Close" (click)="closeModal()">
                <span class="icon-close icon-size-24 icon-close"></span>
            </a>
        </div>
    </div>
</div>
<div class="container-fluid" style="min-height: 60vh;max-height: 80vh;overflow: auto;">    
    <div>
        <h4>Add Configuration</h4>
    </div>
    <div>
        <div class="col-md-6 row" *ngIf="platformData?.params.vendor">
            <div class="col-2" >
                Vendor
            </div>
            <div class="col-md-8 col-lg-8">
                :&nbsp;{{platformData?.params.vendor}}
            </div>
         </div>
         <div class="col-md-6 row" *ngIf="platformData?.params.name">
            <div class="col-2" >
                Model
            </div>
            <div class="col-md-8 col-lg-8">
                :&nbsp;{{platformData?.params.name}}
            </div>
         </div>
         <div class="col-md-6 row" *ngIf="platformData?.params.gen">
            <div class="col-2" >
                Generation
            </div>
            <div class="col-md-8 col-lg-8">
                :&nbsp;{{platformData?.params.gen}}
            </div>
         </div>
    </div> 
        <br>
        <div class="row">
            <div class="col-md-12 base-margin-bottom">
                <p>Make sure that you know what you are doing if you modify the table below. Remember unmeasured and valid configs are interpolated based on bigger and smaller DIMM configs for the same number of CPUs. That means that the biggest and smallest dimm configs for any given CPU must be measured. If you don't the system will barf when interpolating values.</p>

                <p>No checking is done to make sure that the biggest and smallest DIMM configs are indeed marked as measured. So you can absolutely shoot yourself in the foot.</p>

                <p>Also, note that valid configs cannot be marked later as invalid from the web form. This is to avoid complexities where measurements already in the database are orphaned. Deleting valid configs must be done manually in the database</p>
            </div>
        </div> 
        <div class="row">
            <div class="col-md-10 base-margin-bottom">
        <div class="responsive-table">
            <table class="table table--lined" aria-label="Lined table example">
                <thead>
                    <tr>
                        <th style="width: 20%;">No. of CPUs </th>
                        <th style="width: 20%;">No. of Dimms</th>
                        <th>Options</th><th></th><th></th>
                    </tr>
                    <tr>
                        <th></th>
                        <th></th>
                        <th>Measured</th><th>Unmeasured</th><th>Invalid</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let row of configList; let i = index">
                        <td>{{row.cpuCount}}</td>
                        <td>{{row.dimmCount}}</td>
                        <td><label class="radio">
                            <input type="radio" value="1" [(ngModel)]="row.measured" (ngModelChange)="optionChange($event,row)" name="{{i}}">
                            <span class="radio__input"></span>
                        </label></td>
                        <td><label class="radio">
                            <input type="radio" value="0" [(ngModel)]="row.measured" (ngModelChange)="optionChange($event,row)" name="{{i}}">
                            <span class="radio__input"></span>
                        </label></td>
                        <td>
                            <label class="radio">
                            <input type="radio"  value="true" [(ngModel)]="row.inValid" (ngModelChange)="optionChange($event,row)" name="{{i}}">
                            <span class="radio__input"></span>
                        </label>
                    </td>
                        
                    </tr>
                    
                </tbody>
            </table>
        </div>   
        </div></div>
    </div>  
    <mat-dialog-actions>
        <button mat-button [disabled]="!formChanged" class="btn btn--small " (click)="submit('closeModal')" style="height: 24px;margin: 5px 20px 0 0;">Save & Close</button>
        <button mat-button [disabled]="!formChanged" (click)="submit('save')"  class="btn btn--small " style="height: 24px;margin: 5px 20px 0 0;">Save</button>
        <!-- <div *ngIf="isPlatformReleased">{{ platformReleasedInfo }} </div> -->
    </mat-dialog-actions>  