
<!--<div class="flex-center" >
    <a (click)="openModal($event)" class="anchor-close" id="edit" title="Edit">
    <span class="icon-edit icon-size-20 icon-edit"></span>
    </a>
</div> -->

<div class="flex-center" >
    <a (click)="openDialog($event)" class="anchor-close" id="edit" title="Edit">
    <span class="icon-edit icon-size-20 icon-edit"></span>
    </a>
</div>
<button (click)="btnClickedHandler($event)">Click me!</button>