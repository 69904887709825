import { Component, Input, OnInit } from "@angular/core";
import { Subscription } from "rxjs";
import { ApiService } from "src/app/services/api.service";
import { Location } from "@angular/common";
import { UrlConstant } from "src/constants/url.constants";
import { config } from "./../dynamic-form/configurationService"
import { Router } from "@angular/router";
import { ProjectService } from "src/app/services/project.service";

@Component({
  selector: "app-server-details",
  templateUrl: "./server-details.component.html",
  styleUrls: ["./server-details.component.css"],
})
export class ServerDetailsComponent implements OnInit {
  person: any;
  subscription: Subscription = new Subscription();
  productId: string;
  serverData: any;
  serverName: string;
  projectId: string;
  constructor(
    private _location: Location,
    private projectService: ProjectService,
    private router: Router,
  ) {
  }

  ngOnInit(): void {
    // this.projectId = localStorage.getItem(`currentProjectId`);    
    this.projectId = sessionStorage.getItem("myProjectId");
  }

  getServerName(newItem: string) {
    this.serverName = newItem;
  }
  navigateToProjectDetails(fragment?: string) {
    if (this.router.url.includes('admin/')) {
      if (this.router.url.includes('ucs-resizer')) {
        this.router.navigate(["/admin/ucs-resizer/projectDetails"], { fragment: fragment });
      } else this.router.navigate(["/admin/projectDetails"], { fragment: fragment });
    } else {
      if (this.router.url.includes('ucs-resizer')) {
        this.router.navigate(["/ucs-resizer/projectDetails"], { fragment: fragment });
      } else this.router.navigate(["/projectDetails"], { fragment: fragment });
    }
  }
  onTabClick(tabNumber) {
    this.subscription.add(this.projectService.setServerEditStatus(false));
    this.subscription.add(this.projectService.setIsAddedOrEdited(false));
    localStorage.setItem("setServerEditStatus", "false");
    sessionStorage.setItem("setServerEditStatus", "false");
    this.subscription.add(this.projectService.setCurrentChassis([null]));
    if (tabNumber == 1) {
      localStorage.setItem("setCurrentServer", JSON.stringify(null));
      sessionStorage.setItem("setCurrentServer", JSON.stringify(null));
      this.navigateToProjectDetails("Compute");

    }
    if (tabNumber == 2) {
      localStorage.setItem("setCurrentServer", JSON.stringify(null));
      sessionStorage.setItem("setCurrentServer", JSON.stringify(null));
      this.navigateToProjectDetails("Storage");

    }
    if (tabNumber == 3) {
      localStorage.setItem("setCurrentServer", JSON.stringify(null));
      sessionStorage.setItem("setCurrentServer", JSON.stringify(null));
      this.navigateToProjectDetails("Networking");
    }
  }
}