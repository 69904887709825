import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from "ag-grid-angular";
import {
  CngContentConfigType,
  CngModalService,
  CngModalSize,
} from "@cisco/cui-ng";
import { ConfirmationBoxComponent } from "../confirmation-box/confirmation-box.component";
import { TranslateService } from "@ngx-translate/core";
import * as CryptoJS from "crypto-js";

import { Router, RouterEvent } from "@angular/router";
import { AppConstant } from "src/constants/app.constants";
import { Subscription } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';
import { UrlConstant } from 'src/constants/url.constants';
import { HttpClient } from '@angular/common/http';
import { ComponentConstant } from 'src/constants/components.constants';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CreateNewcomponentComponent } from 'src/app/components/admin/admin-tab/create-newcomponent/create-newcomponent.component';
import { CreateStoragecomponentComponent } from 'src/app/components/admin/admin-tab/create-components/create-storagecomponent/create-storagecomponent.component';
import { CreatePowersupplyComponent } from 'src/app/components/admin/admin-tab/create-components/create-powersupply/create-powersupply.component';
import { CreateProcessorfamilyComponent } from 'src/app/components/admin/admin-tab/create-components/create-processorfamily/create-processorfamily.component';
import { CreateProcessorComponent } from 'src/app/components/admin/admin-tab/create-components/create-processor/create-processor.component';
import { CreateDimmsfamilyComponent } from 'src/app/components/admin/admin-tab/create-components/create-dimmsfamily/create-dimmsfamily.component';
import { CreateDimmsComponent } from 'src/app/components/admin/admin-tab/create-components/create-dimms/create-dimms.component';
import { CreateExpansioncardsComponent } from 'src/app/components/admin/admin-tab/create-components/create-expansioncards/create-expansioncards.component';
import { CreateVoltageComponent } from 'src/app/components/admin/admin-tab/create-components/create-voltage/create-voltage.component';
import { CreateBladeServersComponent } from 'src/app/components/admin/admin-tab/create-platforms/create-blade-servers/create-blade-servers.component';
import { ToasterService } from "src/app/shared/components/toast/toast.service";
import { errorCodes } from 'src/constants/errorCodes';
import { PlatformConstant } from 'src/constants/platform.constants';
import { CreateNewAdditionalComponent } from 'src/app/components/admin/admin-tab/create-new-additional/create-new-additional.component';
import { UpdateNameService } from 'src/app/services/update-name.service';
import { AssociatedPlatformsComponent } from "src/app/shared/components/associated-platforms/associated-platforms.component";
import { BlockUI, NgBlockUI } from 'ng-block-ui';

export interface DialogData {
  params: any;
  displayName: string;
  internalName: string;
  //nameMat: string;
}

@Component({
  selector: 'app-edit-render',
  templateUrl: './edit-render.component.html',
  styleUrls: ['./edit-render.component.css']
})
export class EditRenderComponent implements OnInit, ICellRendererAngularComp {
  params: any;
  label: string;
  //fileUrl: any;
  //fileName: string;
  subscriptions: Subscription = new Subscription();
  errorMsg: any;
  @BlockUI() blockUI: NgBlockUI;
  agInit(params): void {
    this.params = params;
    this.label = this.params.label || null;
  }

  constructor(
    private modalService: CngModalService,
    private translate: TranslateService,
    private router: Router,
    private apiService: ApiService,
    private httpClient: HttpClient,
    private toaster: ToasterService,
    public dialog: MatDialog,
    private updateNameService: UpdateNameService
  ) { }

  ngOnInit(): void {
  }
  refresh(params?: any): boolean {
    return true;
  }
  public async openModal($event, action: any) {

    const result = await this.modalService
      .open({
        content: {
          type: CngContentConfigType.COMPONENT,
          content: ConfirmationBoxComponent,
          componentData: {
            someText: this.translate.instant(
              "ARE_YOU_SURE_TO_DELETE_THIS_COMPONENT"
            ),
            key: "delete",
            event: $event,
          },
        },
        size: CngModalSize.SMALL,
      })
      .onDismiss.toPromise().catch((err) => {

      });

    if (result == 'ok')
      this.onClick($event, action);




  }
  onClick($event, action: string) {
    ;
    if (action == "navigate") {

      this.openDialog(this.params);

    }
    if (action == "configure") {

      this.openConfigureDialog(this.params);
    }

    if (action == "editCurvePoint") {

      this.editCurvePoint(this.params);
    }

    if (action == "deleteCurvePoint") {
      this.params.data.deleteFlag = true;

      this.editCurvePoint(this.params);
    }
    if (action == "editFanPolicy") {
      this.params.onClick(this.params);
    }
    if (action == "DeletePIDDetails") {
      this.params.data.deleteFlag = true;

      this.deletePIDDetails(this.params);
    }
    if (action == "deleteComponentData") {

      let type;
      if (this.params?.data?.type != undefined) {
        type = this.params?.data?.type;
      } else {
        type = ComponentConstant.POWERSUPPLY;
      }

      this.params.onClick(this.params);

      let result = this.deleteIoDevicesList(this.params?.data?.id, type, this.params?.data?.sequence);

    }

    if (action == "associatedPlatforms") {

      const dialogRef = this.dialog.open(AssociatedPlatformsComponent, {
        data: {
          params: this.params?.data,
          dynamicComponent: this.params?.dynamicComponent

        },
        panelClass: 'width-dialog-100'


      });
      dialogRef.afterClosed().subscribe(result => {

      })
    }

    if (action == "deleteAssociatedPlatform") {

      let url = UrlConstant.REMOVE_COMPONENT_OPTIONS;
      let reqestParams = {
        "platformSequenceList": [this.params.data.sequence],
        "platformComponentOptionType": this.platformComponentOptionType(),
        "componentSupportFlagList": [this.params.componentData?.params.sequence],
        "dynamicComponent": this.params.componentData.dynamicComponent,
      }


      this.blockUI.start('Loading...');
      this.subscriptions.add(
        this.apiService
          .postMethod(url, reqestParams).subscribe((data: any) => {


            this.blockUI.stop();
            if (data?.modifiedCount == 0 && data?.error?.errorString != "") {
              this.toaster.show('error', data?.error?.errorString, '', 5000);
            } else {
              this.toaster.show('success', errorCodes.DELETE_RECORD, '', 5000);
              this.params.api.updateRowData({ remove: [this.params.node.data] });
            }

          })
      )


    }

    if (this.params.onClick instanceof Function && action !== "navigate") {

      if (['delete', 'clone'].includes(action)) {
        let type;
        if (this.params?.data?.type != undefined) {
          type = this.params?.data?.type;
        } else {
          type = ComponentConstant.POWERSUPPLY;
        }

        this.params.onClick(this.params);


        this.deleteIoDevicesList(this.params?.data?.id, type, this.params?.data?.sequence);
      }

    }
  }

  deleteIoDevicesList(componentId: any, type: string, sequence: any) {

    let url = UrlConstant.MANAGECOMPONENTS + UrlConstant.DELETE;

    let dataToDelete = {
      "id": componentId,
      "type": type,
      "role": "Admin",
      "sequence": sequence,
      "dynamicComponent": this.params?.dynamicComponent
    };
    this.blockUI.start('Loading...');
    this.subscriptions.add(
      this.apiService
        .postMethod(url, dataToDelete).subscribe((data: any) => {


          this.blockUI.stop();
          if (data?.modifiedCount == 0 && data?.error?.errorString != "") {
            this.toaster.show('error', data?.error?.errorString, '', 5000);
          } else {
            this.toaster.show('success', errorCodes.DELETE_RECORD, '', 5000);
            this.params.api.updateRowData({ remove: [this.params.node.data] });
          }



        })
    )
  }

  platformComponentOptionType() {
    let type = null;
    const selectedType = this.params.componentData?.params.type;

    if (selectedType == ComponentConstant.POWERSUPPLY) {
      type = 'powerSupplies';
    }
    else if (selectedType == PlatformConstant.BLADE) {
      type = 'childPlatforms';

    }
    else if (selectedType == PlatformConstant.NODE) {
      type = 'childPlatforms';
    }
    else if (selectedType == ComponentConstant.EXPANSIONS) {
      type = 'expansions';
    }
    else {
      type = selectedType;
    }
    return type;
  }

  // for Edit
  txtDisplayName: String;

  onEditClick($event, action: string) {

    this.txtDisplayName = this.params?.data?.displayName;
    const params = {
      event: $event,
      rowData: this.params.node.data,
    };
  }

  // for Edit
  openDialog(params): void {
    ;
    ;
    let currentTypeData = this.updateNameService.typeDataArray.filter((typeD) => typeD.componentType == params?.data?.type)[0];


    // if (currentTypeData!==undefined && currentTypeData.additionalComponent == true) {
    if (params?.dynamicComponent == true) {
      const dialogRef = this.dialog.open(CreateNewAdditionalComponent, {
        data: {
          displayName: params?.data?.displayName,
          pid: params?.data?.pid,
          raid: params?.data?.raid,
          idleWatts: params?.data?.idleWatts,
          maxWatts: params?.data?.maxWatts,
          update: true,
          additionalFields: params?.data?.additionalFields,
          released: params?.data?.released,
          id: params?.data?.id,
          editor: params?.data?.editor,
          type: params?.data?.type,
          dynamicComponentAttributes: params?.dynamicComponentAttributes,
          dynamicComponentData: params?.data,
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result.data.action !== PlatformConstant.ACTION_CANCEL) {
          this.params.onClick(this.params);
        }
      })
    } else {
      switch (params?.data?.type) {

        case ComponentConstant.IOES: {

          const dialogRef = this.dialog.open(CreateNewcomponentComponent, {
            data: {
              displayName: params?.data?.displayName,
              internalName: params?.data?.internalName,
              pid: params?.data?.pid,
              ioType: params?.data?.ioType,
              raid: params?.data?.raid,
              idleWatts: params?.data?.idleWatts,
              maxWatts: params?.data?.maxWatts,
              mtbf: params?.data?.mtbf,
              id: params?.data?.id,
              type: params?.data?.type,
              released: params?.data?.released,
              update: true,
              additionalFields: params?.data?.additionalFields
            },
            disableClose: true,

          });
          dialogRef.afterClosed().subscribe(result => {
            if (result.data.action !== PlatformConstant.ACTION_CANCEL) {
              this.params.onClick(this.params);
            }
          })
          break;
        }
        case ComponentConstant.STORAGES: {
          const dialogRef = this.dialog.open(CreateStoragecomponentComponent, {
            data: {
              displayName: params?.data?.displayName,
              //internalName : params?.data?.internalName,
              pid: params?.data?.pid,
              mpn: params?.data?.mpn,
              storageType: params?.data?.storageType,
              storageZoneType: params?.data?.storageZoneType,
              capacity: params?.data?.capacity,
              idleWatts: params?.data?.idleWatts,
              maxWatts: params?.data?.maxWatts,
              mtbf: params?.data?.mtbf,
              id: params?.data?.id,
              type: params?.data?.type,
              released: params?.data?.released,
              additionalFields: params?.data?.additionalFields,
              update: true
            },
            panelClass: 'width-dialog-35',
            disableClose: true,
          });
          dialogRef.afterClosed().subscribe(result => {
            if (result.data.action !== PlatformConstant.ACTION_CANCEL) {
              this.params.onClick(this.params);
            }
          })
          break;

        }
        case ComponentConstant.CPU_FAMILIES: {
          const dialogRef = this.dialog.open(CreateProcessorfamilyComponent, {
            data: {
              displayName: params?.data?.text,
              mtbf: params?.data?.mtbf,
              id: params?.data?.id,
              type: params?.data?.type,
              additionalFields: params?.data?.additionalFields,
              update: true
            }

          });
          dialogRef.afterClosed().subscribe(result => {
            if (result.data.action !== PlatformConstant.ACTION_CANCEL) {
              this.params.onClick(this.params);
            }
          })
          break;
        }
        case ComponentConstant.CPUS: {

          const dialogRef = this.dialog.open(CreateProcessorComponent, {
            data: {
              displayName: params?.data?.displayName,
              name: params?.data?.name,
              pid: params?.data?.pid,
              cpuFamName: params?.data?.cpuFamName,
              //selectedProcessorFamily : params?.data?.selectedProcessorFamily,                
              tdp: params?.data?.tdp,
              idlePower: params?.data?.idleWatts,
              mtbf: params?.data?.mtbf,
              id: params?.data?.id,
              type: params?.data?.type,
              released: params?.data?.released,
              additionalFields: params?.data?.additionalFields,
              update: true
            }
          });
          dialogRef.afterClosed().subscribe(result => {
            if (result.data.action !== PlatformConstant.ACTION_CANCEL) {
              this.params.onClick(this.params);
            }
          })
          break;
        }
        case ComponentConstant.DIMM_FAMILIES: {
          const dialogRef = this.dialog.open(CreateDimmsfamilyComponent, {
            data: {
              name: params?.data?.text,
              rank: params?.data?.rank,
              speed: params?.data?.speed,
              siliconSize: params?.data?.siliconSize,
              id: params?.data?.id,
              type: params?.data?.type,
              released: params?.data?.released,
              additionalFields: params?.data?.additionalFields,
              update: true
            }

          });
          dialogRef.afterClosed().subscribe(result => {
            if (result.data.action !== PlatformConstant.ACTION_CANCEL) {
              this.params.onClick(this.params);
            }
          })
          break;
        }
        case ComponentConstant.DIMMS: {
          const dialogRef = this.dialog.open(CreateDimmsComponent, {
            data: {
              id: params?.data?.id,
              name: params?.data?.displayName,
              size: params?.data?.size,
              pid: params?.data?.pid,
              idleWatts: params?.data?.idleWatts,
              maxWatts: params?.data?.maxWatts,
              idleWatts2Dpc: params?.data?.idleWatts2Dpc,
              maxWatts2Dpc: params?.data?.maxWatts2Dpc,
              moreMemoryModulePerChannelIdlePower: params?.data?.moreMemoryModulePerChannelIdlePower,
              moreMemoryModulePerChannelMaxPower: params?.data?.moreMemoryModulePerChannelMaxPower,
              persistentModelIdlePower: params?.data?.persistentModelIdlePower,
              persistentModelMaxPower: params?.data?.persistentModelMaxPower,
              noOfDimms: params?.data?.numDimms,
              dimm: params?.data?.dimmFamName,
              mtbf: params?.data?.mtbf,
              type: params?.data?.type,
              released: params?.data?.released,
              additionalFields: params?.data?.additionalFields,
              update: true
            },
            disableClose: true,


          });
          dialogRef.afterClosed().subscribe(result => {
            if (result.data.action !== PlatformConstant.ACTION_CANCEL) {
              this.params.onClick(this.params);
            }
          })
          break;
        }
        case ComponentConstant.EXPANSIONS:
        case ComponentConstant.IOMS: {

          const dialogRef = this.dialog.open(CreateExpansioncardsComponent, {
            data: {
              displayName: params?.data?.displayName,
              pid: params?.data?.pid,
              idlePower: params?.data?.idleWatts,
              maxPower: params?.data?.maxWatts,
              mtbf: params?.data?.mtbf,
              id: params?.data?.id,
              type: params?.data?.type,
              released: params?.data?.released,
              additionalFields: params?.data?.additionalFields,
              update: true
            }
          });
          dialogRef.afterClosed().subscribe(result => {
            if (result.data.action !== PlatformConstant.ACTION_CANCEL) {
              this.params.onClick(this.params);
            }
          })
          break;
        }

        default: {

          const dialogRef = this.dialog.open(CreatePowersupplyComponent, {
            data: {
              displayName: params?.data?.powerSupply?.displayName,
              internalName: params?.data?.powerSupply?.internalDesc,
              pid: params?.data?.powerSupply?.pid,
              capacity: params?.data?.powerSupply?.capacity,
              mtbf: params?.data?.powerSupply?.mtbf,
              id: params?.data?.id,
              type: ComponentConstant.POWERSUPPLY,
              released: params?.data?.powerSupply?.released,
              powerSupplyInputs: params?.data?.powerSupplyInputs,
              additionalFields: params?.data?.powerSupply?.additionalFields,
              update: true
            }
          });
          dialogRef.afterClosed().subscribe(result => {
            if (result.data.action !== PlatformConstant.ACTION_CANCEL) {
              this.params.onClick(this.params);
            }
          })
          break;
        }
      }
    }
  }

  openConfigureDialog(params): void {

    localStorage.setItem('powerSupplyId', params?.data?.id);
    localStorage.setItem('powerSupplyName', params?.data?.powerSupply?.displayName);
    localStorage.setItem('powerSupplyPid', params?.data?.powerSupply?.pid);
    localStorage.setItem('powerSupplyCapacity', params?.data?.powerSupply?.capacity);
    localStorage.setItem("initialCurveInputs", params?.data?.powerSupplyInputs);
    localStorage.setItem("powerSuppySequence", params?.data?.sequence);

    const dialogRef = this.dialog.open(CreateVoltageComponent, {

      data: {
        displayName: params?.data?.powerSupply?.displayName,
        internalName: params?.data?.powerSupply?.internalDesc,
        pid: params?.data?.powerSupply?.pid,
        capacity: params?.data?.powerSupply?.capacity,
        mtbf: params?.data?.powerSupply?.mtbf,
        id: params?.data?.id,
        type: ComponentConstant.POWERSUPPLY,
        released: params?.data?.powerSupply?.released,
        editor: params?.data?.powerSupply?.editor,
        lastUpdated: params?.data?.powerSupply?.lastUpdated,
        //powerSupplyInputs :params?.data?.powerSupplyInputs,
        powerSupplyInputs: params?.data?.powerSupply?.voltagesCurve,
        //update: true
        sequence: params?.data?.sequence
      },
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {

      this.params.onClick(result);
    })
  }
  editCurvePoint(params): void {

    if (params?.data?.deleteFlag) {
      let url = UrlConstant.MANAGECOMPONENTS + UrlConstant.VOLTAGE_CURVE;
      let dataToDelete = {
        "type": ComponentConstant.POWERSUPPLY,
        "role": "Admin"
      };
      dataToDelete["curveFlag"] = "DELETE";
      dataToDelete["inputType"] = params?.data?.inputType;
      dataToDelete["id"] = localStorage.getItem('powerSupplyId');
      dataToDelete["inputVoltage"] = params?.data?.inputVoltage,
        dataToDelete["sequence"] = localStorage.getItem('powerSuppySequence');

      this.subscriptions.add(
        this.apiService
          .postMethod(url, [dataToDelete]).subscribe((data: any) => {
            ;

            //this.params.api.updateRowData({remove: [this.params.node.data]});  
            if (data?.successMessage == "Successfully updated") {
              this.toaster.show('success', errorCodes.DELETE_RECORD, '', 5000);
              this.params.api.updateRowData({ remove: [this.params.node.data] });
            }
            else if (data?.successMessage == null && data?.error?.errorString) {
              this.toaster.show('success', data?.error?.errorString, '', 5000);
              this.params.data.deleteFlag = false;
            }
            else {
              this.errorMsg = errorCodes.GENERIC_ERROR_MESSAGE;
              this.toaster.show('error', this.errorMsg, '', 5000);
              this.params.data.deleteFlag = false;
            }


          }, error => {
            this.errorMsg = error?.error ? error.error?.error?.errorString : errorCodes.GENERIC_ERROR_MESSAGE;
            this.toaster.show('error', this.errorMsg, error ? error?.message : '');
          })
      )
    }
    else {
      localStorage.setItem("initialInputVoltage", params?.data?.inputVoltage);
      localStorage.setItem("initialInputVoltageType", params?.data?.inputType);

      const dialogRef = this.dialog.open(CreateVoltageComponent, {
        data: {
          "powerSupplyInputs": this.params.powerSupplyData,
          "update": true,
          "id": params?.data?.id,
          "type": ComponentConstant.POWERSUPPLY,
          "a0PercentEff": params?.data?.a0percentEff,
          "a5PercentEff": params?.data?.a5percentEff,
          "a10PercentEff": params?.data?.a10percentEff,
          "a15PercentEff": params?.data?.a15percentEff,

          "a20PercentEff": params?.data?.a20percentEff,
          "a25PercentEff": params?.data?.a25percentEff,
          "a30PercentEff": params?.data?.a30percentEff,
          "a35PercentEff": params?.data?.a35percentEff,
          "a40PercentEff": params?.data?.a40percentEff,
          "a45PercentEff": params?.data?.a45percentEff,
          "a50PercentEff": params?.data?.a50percentEff,
          "a55PercentEff": params?.data?.a55percentEff,

          "a60PercentEff": params?.data?.a60percentEff,
          "a65PercentEff": params?.data?.a65percentEff,
          "a70PercentEff": params?.data?.a70percentEff,
          "a75PercentEff": params?.data?.a75percentEff,
          "a80PercentEff": params?.data?.a80percentEff,
          "a85PercentEff": params?.data?.a85percentEff,
          "a90PercentEff": params?.data?.a90percentEff,
          "a95PercentEff": params?.data?.a95percentEff,
          "a100PercentEff": params?.data?.a100percentEff,


          "a0PercentPf": params?.data?.a0percentPf,
          "a5PercentPf": params?.data?.a5percentPf,
          "a10PercentPf": params?.data?.a10percentPf,
          "a15PercentPf": params?.data?.a15percentPf,

          "a20PercentPf": params?.data?.a20percentPf,
          "a25PercentPf": params?.data?.a25percentPf,
          "a30PercentPf": params?.data?.a30percentPf,
          "a35PercentPf": params?.data?.a35percentPf,
          "a40PercentPf": params?.data?.a40percentPf,
          "a45PercentPf": params?.data?.a45percentPf,
          "a50PercentPf": params?.data?.a50percentPf,
          "a55PercentPf": params?.data?.a55percentPf,

          "a60PercentPf": params?.data?.a60percentPf,
          "a65PercentPf": params?.data?.a65percentPf,
          "a70PercentPf": params?.data?.a70percentPf,
          "a75PercentPf": params?.data?.a75percentPf,
          "a80PercentPf": params?.data?.a80percentPf,
          "a85PercentPf": params?.data?.a85percentPf,
          "a90PercentPf": params?.data?.a90percentPf,
          "a95PercentPf": params?.data?.a95percentPf,
          "a100PercentPf": params?.data?.a100percentPf,
          "voltage": params?.data?.inputVoltage,
          "inputType": params?.data?.inputType,
          "inputVoltage": params?.data?.inputVoltage,
        },
        disableClose: true
      })

      dialogRef.afterClosed().subscribe(result => {
        this.params.onClick(result);
      })
    }
  }
  getManageExpansionCardsList(type: string) {
    //let expansionCards = ComponentConstant.EXPANSIONS;
    const url = UrlConstant.MANAGECOMPONENTS + '/?component=' + type;
    this.subscriptions.add(
      this.apiService.getMethod(url).subscribe((data: any) => {

      })
    );
  }
  deletePIDDetails(params: any): void {
    const url = UrlConstant.DELETE_FAN_POLICY;
    const PID = params?.data.pid;
    const policy = params.data.policy
    const dataToDelete = [{
      "pid": PID,
      "policies": [policy]
    }]//{[sequence]: [policy]}

    this.subscriptions.add(this.apiService.postMethod(url, dataToDelete).subscribe((data: any) => {
      this.params.onClick(this.params);

      if (data?.modifiedCount == 0 && data?.error?.errorString != "") {
        this.toaster.show('error', data?.error?.errorString, '', 5000);
      } else {
        this.toaster.show('success', errorCodes.DELETE_RECORD, '', 5000);
        this.params.api.updateRowData({ remove: [this.params.node.data] });
      }

    }, error => {
      this.errorMsg = error?.error ? error.error?.error?.errorString : errorCodes.GENERIC_ERROR_MESSAGE;
      this.toaster.show('error', this.errorMsg, error ? error?.message : '');

    }));

  }
  ngOnDestroy() {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }

}
