import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CngModalService } from '@cisco/cui-ng';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { Subscription } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';
import { UpdateNameService } from 'src/app/services/update-name.service';
import { ToasterService } from 'src/app/shared/components/toast/toast.service';
import { AppConstant } from 'src/constants/app.constants';
import { UrlConstant } from 'src/constants/url.constants';

export interface DialogData {
  header: string;
}

@Component({
  selector: 'app-create-manage-comp',
  templateUrl: './create-manage-comp.component.html',
  styleUrls: ['./create-manage-comp.component.css']
})
export class CreateManageCompComponent implements OnInit {
  subscriptions: Subscription = new Subscription();
  createComponentForm: UntypedFormGroup;
  sequenceNum: number;
  lPanelDataURL: string = 'admin/manageComponentsLPanelData';
  @BlockUI() blockUI: NgBlockUI;
  constructor(
    public dialogRef: MatDialogRef<CreateManageCompComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private modalService: CngModalService,
    private formBuilder: UntypedFormBuilder,
    private updateNameService: UpdateNameService,
    private apiService: ApiService,
    private toast: ToasterService,
    private router: Router,
  ) {
    this.createComponentForm = this.formBuilder.group({
      displayName: new UntypedFormControl('', [Validators.required, Validators.pattern(AppConstant.VALID_INPUT_REGEX)]),
      errorMessage: new UntypedFormControl('', [Validators.required, Validators.pattern(AppConstant.VALID_INPUT_REGEX)]),
      expandable: new UntypedFormControl('', [Validators.required]),
      componentType: new UntypedFormControl('', [Validators.required, Validators.pattern(AppConstant.VALID_INPUT_REGEX)])
    });
  }

  dropdownList = [];
  selectedItems = [];
  dropdownSettings = {};
  staticList = [
    "displayName",
    "internalName",
    "pid",
  ];

  ngOnInit(): void {
    let height = 200;
    this.getDynamicComponentAttributes();

    this.dropdownSettings = {
      singleSelection: false,
      text: "Select Options",
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      enableSearchFilter: true,
      classes: "myclass custom-class",
      badgeShowLimit: 1,
      maxHeight: height > 0 ? height : 60,
      enableCheckAll: false,
      enableFilterSelectAll: false
    };

  }
  closeDialog() {
    this.dialogRef.close('Closed!');
  }
  onCancel() {
    this.dialogRef.close('CANCEL');
  }
  processName(name: string): string {
    return name.length > 0 ? name.trim().split(' ').join('').toLocaleLowerCase() : '';
  }
  onAddComponent() {
    this.sequenceNum = this.updateNameService.currentNames.length;
    let componentData = this.createComponentForm.value;
    let isExpandable = componentData.expandable == "Yes" ? true : false;
    let payload = {
      "componenetLPanel": {
        "additionalComponent": true,
        "collectionName": "componentConfig",
        "componentClass": "UcsComponent",
        "componentType": componentData.componentType.trim(),
        "createdBy": JSON.parse(localStorage.getItem('userData'))?.mail.split("@")[0] ? JSON.parse(localStorage.getItem('userData'))?.mail.split("@")[0] : "ucs_user",
        "createdDate": "2021-10-26T08:39:19.821Z",
        "cssClass": "",
        "displayName": componentData.displayName.trim(),
        "internalName": componentData.displayName.trim(),
        "position": (this.sequenceNum + 1).toString(),
        "updatedBy": JSON.parse(localStorage.getItem('userData'))?.mail.split("@")[0] ? JSON.parse(localStorage.getItem('userData'))?.mail.split("@")[0] : "Admin",
        "dynamicComponentAttributes": this.selectedAttributesList
      },
      "labelDto": {
        "controlLabels": [
          {
            "label": "Quantity",
            "type": "select",
            "controlType": "control",
            "visible": true,
            "required": false
          },
          {
            "label": "Configuration",
            "type": "select",
            "controlType": "control",
            "visible": true,
            "required": false
          }
        ],
        "name": this.processName(componentData.displayName),
        "parentLabel": {
          "controlType": "controlIndependent",
          "expandable": isExpandable,
          "group": this.processName(componentData.displayName),
          "label": componentData.displayName.trim(),
          "message": componentData.errorMessage.trim(),
          "required": false,
          "type": "select",
          "visible": true
        },
        "rowOneLabel": "",
        "rowZeroLabel": `Please select ${componentData.displayName.trim()} or type to search`,
        "seqNo": this.sequenceNum + 1
      }

    }
    this.blockUI.start('Loading');
    this.apiService.postMethod(this.lPanelDataURL, payload).subscribe((response) => {
      this.blockUI.stop();
      if (response.modifiedCount == 1) {
        this.updateNameService.addNewComponentNameAndType(this.createComponentForm.value.displayName, this.createComponentForm.value.componentType, this.createComponentForm.value.internalName);
        let isComponentDataUpdated: boolean = false;
        this.updateNameService.getCurrentData().subscribe(data => {
          if (!isComponentDataUpdated) {
            isComponentDataUpdated = true;
            let newComponent = payload.componenetLPanel;
            data.push(newComponent);
            this.updateNameService.componentData$.next(data);
            /** re-direct to component page after creation */
            this.router.navigate([`admin/admin-tab/additionalComponent${this.sequenceNum + 1 - 8}`]);
          }
        })
        this.dialogRef.close({ success: true });
      } else {
        this.toast.show("error", response.successMessage, '', 5000);
      }
    });

  }
  // To get the Dynamic Component Attributes list.

  getDynamicComponentAttributes() {
    const url = UrlConstant.DYNAMIC_COMPONENT_ATTRIBUTES + '?type=dynamicComponent';
    this.subscriptions.add(
      this.apiService.getMethod(url).subscribe((response) => {
        let componentAttributes = [];
        response.forEach((element, index) => {
          element.attribute.forEach((element, index) => {
            let attributeObj = {
              id: index + 1,
              name: element.name,
              key: element.key,
              "type": element.type,
              "required": element.required,
              "visible": element.visible
            }
            componentAttributes.push(attributeObj);
          })
        });
        this.dropdownList = componentAttributes;
        this.selectedItems = this.dropdownList.filter(d => this.staticList.includes(d.key));
        this.selectedAttributesList = this.dropdownList.filter(d => this.staticList.includes(d.key));
      })
    )
  }

  selectedAttributesList = [];
  onItemSelect(item: any) {
    this.selectedAttributesList.push(item);
  }

  OnItemDeSelect(item: any) {
    const index = this.selectedAttributesList.indexOf(item);
    // cannot deselect a required attribute
    if (this.staticList.includes(item.key)) {
      if (!this.selectedAttributesList.map(s => s.key).includes(item.key)) {
        this.selectedAttributesList.push(item);
      }
      return;
    }
    if (index > -1) {
      // only splice array when item is found
      this.selectedAttributesList.splice(index, 1); // 2nd parameter means remove one item only
    }
  }
  onSelectAll(items: any) {
  }
  onDeSelectAll(items: any) {

  }



}
