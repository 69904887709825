  <div class="flex-center" *ngIf="label === 'configure'">
    <a (click)="onClick($event, 'configure')"  id="configure" title="configure">
    <span class="icon-configurations icon-size-20 icon-configurations icon-color"></span>
    </a>
  </div> 

  <div class="flex-center" *ngIf="label === 'editCurvePoint'">
    <a (click)="onClick($event, 'editCurvePoint')" class="anchor-edit"  id="edit" title="Edit">
    <span class="icon-edit icon-size-20 icon-edit icon-color"></span>
    </a>
  </div> 

  <div class="flex-center" *ngIf="label === 'DeleteCurvePoint'">
    <a (click)="openModal($event, 'deleteCurvePoint')" class="anchor-close"  id="deleteCurvePoint" title="deleteCurvePoint">
    <span class="icon-delete icon-size-20 icon-delete"></span>
    </a>
  </div> 

  <div class="flex-center" *ngIf="label === 'DeleteComponentData'">
    <a (click)="onClick($event, 'deleteComponentData')" class="anchor-close"  id="deleteComponentData" title="deleteComponentData">
    <span class="icon-delete icon-size-20 icon-delete"></span>
    </a>
  </div> 

  <div class="flex-center" *ngIf="label === 'Edit'">
    <a (click)="onClick($event, 'navigate')" class="anchor-edit" id="edit" title="Edit">
    <span class="icon-edit icon-size-20 icon-edit icon-color "></span>
    </a>
</div>
<div class="flex-center" *ngIf="label === 'editFanPolicy'">
  <a (click)="onClick($event, 'editFanPolicy')" class="anchor-edit" id="edit" title="Edit">
  <span class="icon-edit icon-size-20 icon-edit icon-color "></span>
  </a>
</div>
<div class="flex-center" *ngIf="label === 'Delete'">
    <a (click)="openModal($event,'delete')" class="anchor-close" id="delete" title="Delete">
    <span class="icon-delete icon-size-20 icon-delete"></span>
    </a>
</div>
<div class="flex-center" *ngIf="label === 'DeletePIDDetails'">
  <a (click)="openModal($event,'DeletePIDDetails')" class="anchor-close" id="delete" title="Delete"  [ngClass]="(params?.data?.deleteFlag == false) ? 'disable-icon' : ''">
  <span class="icon-delete icon-size-20 icon-delete"></span>
  </a>
</div>

<div class="flex-center" *ngIf="label === 'associatedPlatforms'">
  <a (click)="onClick($event, 'associatedPlatforms')" id="edit" title="Edit">
  <span class="icon-cog icon-size-20 icon-color "></span>
  </a>
</div>
<div class="flex-center" *ngIf="label === 'deleteAssociatedPlatform'">
  <a (click)="openModal($event, 'deleteAssociatedPlatform')" id="deleteplatform" title="delete">
  <span class="icon-delete icon-size-20 icon-delete"></span>
  </a>
</div>
<!--<div class="flex-center" *ngIf="label === 'DeleteCurvePoint'">
  <a (click)="openModal($event)" class="anchor-close" id="deleteCurvePoint" title="deleteCurvePoint">
  <span class="icon-delete icon-size-20 icon-delete"></span>
  </a>
</div> -->