import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-create-iomodule',
  templateUrl: './create-iomodule.component.html',
  styleUrls: ['./create-iomodule.component.css']
})
export class CreateIomoduleComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
