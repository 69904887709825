<mat-card-title mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
  <div style="margin-bottom: 8px;">
    <h4 style="float: left;font-size: large;font-weight: 400;"> Processor </h4>
    <div style="display:flex;
    flex-direction: row-reverse;">
      <div class="flex-center margin_auto_t_b">
        <a class="anchor-close" id="close" title="Close" (click)="onCancel()">
          <span class="icon-close icon-size-24 icon-close"></span>
        </a>
      </div>
      <button mat-button class="btn btn--small " style="height: 24px;margin: 0px 20px 0 0;"
        (click)="openAdditionalAtt_Modal()">Additional Fields</button><span class="margin_auto_t_b"
        style="font-size: 65%;margin-top: -2px;margin-right:5px;"
        *ngIf="additionalFields.length>0">{{additionalFields.length}} Field(s) Added</span>
    </div>
  </div>
</mat-card-title>
<form [formGroup]="validations" autocomplete="off">
  <div mat-dialog-content>
    <mat-form-field>

      <div class="panel panel--compressed" style="max-width:800px;min-width: 500px;">
        <table class="table table-bordered">
          <tbody>
            <tr>
              <td class="mandatory" style="vertical-align: top;padding-top: 15px;">
                <mat-label>Family Name</mat-label>
                <mat-error *ngIf="validations.get('processorFamily').hasError('required')"
                  style="color:red"></mat-error>
              </td>
              <td>

                <div class="form-group storage">
                  <ngx-select-dropdown [multiple]="false"
                    [ngClass]=" validations.get('processorFamily').hasError('required') ? 'ngx-select-danger ngx-dropdown-button': 'ngx-select-normal ngx-dropdown-button'"
                    [(ngModel)]="selectedProcessorFamily" formControlName="processorFamily" [config]="config"
                    [options]="processorsFamily" (change)="changeDropdown($event)">
                  </ngx-select-dropdown>
                </div>
              </td>
              <td></td>
            </tr>

            <tr *ngIf="showNewFamilyName">
              <td class="mandatory">
                <mat-label>New Family Name</mat-label>
                <mat-error *ngIf="true" style="color:red"></mat-error>
              </td>
              <td>
                <div class="form-group">
                  <div class="form-group__text">
                    <input matInput
                      [ngClass]=" validations.get('newFamily').errors ? 'text-danger' :'text-normal' "
                      formControlName="newFamily" required style="width: 100%;">
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td class="mandatory">
                <mat-label>Display Name</mat-label>
                <mat-error *ngIf="validations.get('displayName').errors" style="color:red"></mat-error>
              </td>
              <td>
                <div class="form-group">
                  <div class="form-group__text">
                    <input matInput
                      [ngClass]=" validations.get('displayName').errors ? 'text-danger': 'text-normal'"
                      [(ngModel)]="processorData.displayName" formControlName="displayName" required>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td class="mandatory">
                <mat-label>Name</mat-label>
                <mat-error *ngIf="validations.get('name').errors" style="color:red"></mat-error>
              </td>
              <td>
                <div class="form-group">
                  <div class="form-group__text">
                    <input matInput
                      [ngClass]=" validations.get('name').errors ? 'text-danger': 'text-normal'"
                      [(ngModel)]="processorData.name" formControlName="name" required>
                  </div>
                </div>
              </td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td class="mandatory" style="vertical-align: top;padding-top: 15px;
              ">
                <mat-label>PID</mat-label>
              </td>
              <td>
                <app-search-box *ngIf="showPID" [type]="'component'" [pid]="selectedPid"
                  (pidSelectionEvent)="selectedPID($event)"></app-search-box>
              </td>
              <td></td>

            </tr>
            <tr>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td class="mandatory"> <mat-label>MTBF Hours</mat-label></td>
              <td>
                <div class="form-group">
                  <div class="form-group__text">
                    <input matInput (ngModelChange)="validatePower($event)" [ngClass]="validations.get('mtbf').hasError('pattern') ||
                                                 mtbfErr ? 'text-danger': 'text-normal'"
                      [(ngModel)]="processorData.mtbf" formControlName="mtbf">
                  </div>
                </div>
              </td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td>
                <mat-error *ngIf="mtbfErr" style=color:red>Only Non-Negative Integers are allowed </mat-error>
              </td>
            </tr>
            <tr>
              <td class="mandatory"> <mat-label>Idle Power(W)</mat-label></td>
              <td>
                <div class="form-group">
                  <div class="form-group__text">
                    <input matInput (ngModelChange)="validatePower($event)" [ngClass]="validations.get('idlePower').hasError('required') || 
                                                 validations.get('idlePower').hasError('pattern') ||
                                                 validations.get('idlePower').hasError('min') ||
                                                 showWattErr || 
                                                 IdleWattsErr ? 'text-danger': 'text-normal'"
                      [(ngModel)]="processorData.idlePower" formControlName="idlePower">
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td></td>
              <td>
                <div
                  *ngIf="validations.get('idlePower').hasError('pattern') || validations.get('idlePower').hasError('min')"
                  style=color:red>Only Positive Integers are allowed</div>
                <div *ngIf="showWattErr" style=color:red>Idle Power should be less than or equal to TDP value </div>
                <div *ngIf="IdleWattsErr" style=color:red>Only Positive Integers are allowed </div>
              </td>
            </tr>
            <tr>
              <td class="mandatory"> <mat-label>TDP value</mat-label></td>
              <td>
                <div class="form-group">
                  <div class="form-group__text">
                    <input matInput (ngModelChange)="validatePower($event)" [ngClass]="validations.get('tdp').hasError('required') ||
                                        validations.get('tdp').hasError('pattern') || validations.get('tdp').hasError('min') ||
                                        showWattErr || 
                                        IdleMaxWattsErr ? 'text-danger': 'text-normal'" [(ngModel)]="processorData.tdp"
                      formControlName="tdp">
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td></td>
              <td>
                <div *ngIf="validations.get('tdp').hasError('pattern') || validations.get('tdp').hasError('min') "
                  style=color:red>Only Positive Integers are allowed</div>
                <div *ngIf="showWattErr" style=color:red>TDP value should be greater than or equal to Idle Power</div>
                <div *ngIf="IdleMaxWattsErr" style=color:red>Only Positive Integers are allowed </div>

              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </mat-form-field>
  </div>

  <div mat-dialog-actions>
    <table class="table table-bordered">
      <tbody>
        <tr>
          <td>
            <mat-card-actions>
              <button mat-button (click)="saveProcessorFamily()" class="btn btn--small "
                style="height: 24px;margin: 5px 20px 0 0;"
                [disabled]="!validations.valid || showWattErr || IdleWattsErr || IdleMaxWattsErr || mtbfErr || !selectedPid">Save
                & Close</button>
            </mat-card-actions>
            <button mat-button (click)="onNoClick()" class="btn btn--small " style="height: 24px;margin: 5px 20px 0 0;"
              [disabled]="!validations.valid || showWattErr || IdleWattsErr || IdleMaxWattsErr || mtbfErr || !selectedPid">Save</button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</form>