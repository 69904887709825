import { Component, OnInit } from '@angular/core';
import { GridOptions } from 'ag-grid-community';
import { Subscription } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';
import { ToasterService } from 'src/app/shared/components/toast/toast.service';
import { errorCodes } from 'src/constants/errorCodes';
import { UrlConstant } from 'src/constants/url.constants';
import { DocumentIconRendererComponent } from './document-icon-renderer/document-icon-renderer.component';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as moment from 'moment';
import { saveAs } from 'file-saver';
import { FileSaver } from 'file-saver';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PlatformConstant } from 'src/constants/platform.constants';
//import * as $ from "jquery";

import { HttpParams } from '@angular/common/http';
import { environment } from "../../../../../environments/environment";
import { DocumentUploadComponent } from '../document-upload/document-upload.component';
@Component({
  selector: 'app-document',
  templateUrl: './document.component.html',
  styleUrls: ['./document.component.css']
})
export class DocumentComponent implements OnInit {
  showLoader: boolean;
  paginationPageSize = 10;
  frameworkComponents: any;
  subscription: Subscription = new Subscription();
  docDown = 0;
  columnDefs = [
    {
      field: "filename",
      headerName: "Document Name",
      sortable: true,
      filter: true,
      editable: true,
      maxWidth: 700,
      suppressSizeToFit: false,
      cellClass: ["document-name-color"],
      minWidth: 200,
    },
    {
      headerName: "Id",
      hide: true,
    },
    {
      field: "metadata.title",
      headerName: "Title",
      sortable: true,
      filter: true,
      minWidth: 200,
    },
    {
      field: "uploadDate",
      headerName: "Created On",
      maxWidth: 500,
      suppressSizeToFit: false,
      minWidth: 120,
      cellRenderer: (data) => {
        return data.value ? moment(data.value).format('MMM DD, YYYY') : null;
      }
    },
    {
      headerName: "",
      minWidth: 40,
      maxWidth: 50,
      cellRenderer: "buttonRenderer",
      cellRendererParams: {
        onClick: this.onDownLoadButtonClick.bind(this),
        label: "DownLoad",
      },
    },

    {
      headerName: "",
      minWidth: 40,
      maxWidth: 50,
      cellRenderer: "buttonRenderer",
      cellRendererParams: {
        onClick: this.onDeleteButtonClick.bind(this),
        label: "Delete",
      },
    },
  ];

  rowData = [];
  gridOptions: GridOptions = {}
  errorMsg: any;
  constructor(private apiService: ApiService,
    private httpClient: HttpClient,
    private toaster: ToasterService,
    public dialog: MatDialog
  ) {
    this.frameworkComponents = {
      buttonRenderer: DocumentIconRendererComponent,
    };
  }

  ngOnInit(): void {
    this.getDocumentList();
  }
  getDocumentList() {
    const url = UrlConstant.GET_DOCUMENT;
    this.showLoader = true;
    this.subscription.add(this.apiService.getMethod(url).subscribe((result: any) => {

      this.rowData = result;
      this.showLoader = false;
    },
      (error) => {
        this.errorMsg = error?.error ? error.error?.error?.errorString : errorCodes.GENERIC_ERROR_MESSAGE;
        this.toaster.show("error", this.errorMsg, error ? error?.message : "");
        window.scroll(0, 0);
        this.showLoader = false;
      }));
  }

  onUploadDocument(params: any) {
    const fileList = params.fileEvent.target.files;
    const title = params.title;

    if (fileList[0]) {
      this.showLoader = true;
      let formdata = new FormData();
      formdata.append("file", fileList[0]);
      formdata.append("title", title);
      const url = UrlConstant.UPLOAD_DOCUMENT;

      for (var key of formdata.entries()) {

      }
      this.subscription.add(this.apiService.postMethodOnlyAuth(url, formdata).subscribe((data: any) => {
        this.showLoader = false;
        this.toaster.show("success", errorCodes.DOCUMENT_UPLOAD_MESSAGE, "");
        this.getDocumentList();
      },
        (error) => {

          this.errorMsg = error?.error ? error.error?.error?.errorString : errorCodes.GENERIC_ERROR_MESSAGE;
          this.toaster.show("error", this.errorMsg, error ? error?.message : "");
          window.scroll(0, 0);
          this.showLoader = false;
        }));
    }
  }

  onGridReady(params) {
    this.gridOptions.api = params.api;
    this.gridOptions.columnApi = params.columnApi;
    this.gridOptions.api?.sizeColumnsToFit();
  }

  onDownLoadButtonClick(params: any) {

    this.docDown += 1;
    const url = UrlConstant.DOWNLOAD_DOCUMENT + '?objectId=' + params.data._id;

    var new_url = environment.apiUrl + url;

    // window.open(new_url);

  }

  onDeleteButtonClick(params: any) {

    const url = UrlConstant.DELETE_DOCUMENT + '?objectId=' + params.data._id;
    this.showLoader = true;
    this.subscription.add(
      this.apiService.deleteMethod(url, '').subscribe((data: any) => {
        this.showLoader = false;
        this.toaster.show("success", errorCodes.DOCUMENT_DELETE_MESSAGE, "");
        this.getDocumentList();
      },
        (error) => {
          this.errorMsg = error?.error ? error.error?.error?.errorString : errorCodes.GENERIC_ERROR_MESSAGE;
          this.toaster.show("error", this.errorMsg, error ? error?.message : "");
          window.scroll(0, 0);
          this.showLoader = false;
        }));
  }
  openDialog() {

    const dialogRef = this.dialog.open(DocumentUploadComponent, {
      data: {
        header: "Upload Document"
      }
    });

    dialogRef.afterClosed().subscribe(result => {

      try {
        if (result !== PlatformConstant.ACTION_CANCEL) {

          this.onUploadDocument(result);
        }

      } catch (error) {

      }
    });
  }
}
