import {
  Component,
  OnInit,
  Inject,
  HostListener,
  ChangeDetectorRef,
} from "@angular/core";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import {
  UntypedFormControl,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
  UntypedFormArray,
} from "@angular/forms";
import { Subscription } from "rxjs";
import { ComponentConstant } from "src/constants/components.constants";
import { ApiService } from "src/app/services/api.service";
import { UrlConstant } from "src/constants/url.constants";
import { ToasterService } from "src/app/shared/components/toast/toast.service";
import { errorCodes } from "src/constants/errorCodes";
import {
  CngContentConfigType,
  CngModalService,
  CngModalSize,
} from "@cisco/cui-ng";
import { ConfirmationBoxComponent } from "src/app/shared/components/confirmation-box/confirmation-box.component";
import { TranslateService } from "@ngx-translate/core";
import { AdminService } from "src/app/services/admin.service";
import { PlatformConstant } from "src/constants/platform.constants";
import { AdditionalAttributesComponent } from "src/app/shared/components/additional-attributes/additional-attributes.component";
import { BlockUI, NgBlockUI } from "ng-block-ui";
import * as _ from "lodash";
import { AppConstant } from "src/constants/app.constants";

export interface DialogData {
  displayName: string;
  internalName: string;
  componentDisplayName: string;
  pid: string;
  idleWatts: string;
  maxWatts: string;
  mtbf: string;
  type: string;
  id: string;
  update: boolean;
  released: string;
  raid: any;
  raid1: any;
  raid5: any;
  raid6: any;
  raid10: any;
  raid50: any;
  raid60: any;
  dynamicComponentAttributes: any[];
  dynamicComponentData: any;
}
interface ViewType {
  value: string;
  viewValue: string;
}
@Component({
  selector: "app-create-new-additional",
  templateUrl: "./create-new-additional.component.html",
  styleUrls: ["./create-new-additional.component.css"],
})
export class CreateNewAdditionalComponent implements OnInit {
  subscriptions: Subscription = new Subscription();
  dynamicComponentAttributes: any[] = [];
  dynamicComponentData: any;
  isDynamicFormCreated: boolean = false;
  constructor(
    public dialogRef: MatDialogRef<CreateNewAdditionalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private apiService: ApiService,
    private toaster: ToasterService,
    private formBuilder: UntypedFormBuilder,
    private modalService: CngModalService,
    private translate: TranslateService,
    private adminService: AdminService,
    public dialog: MatDialog,
    private cdr: ChangeDetectorRef
  ) {
    this.validations = this.formBuilder.group({
      dynamicAttributes: this.formBuilder.array([]),
      raid0: new UntypedFormControl(),
      raid1: new UntypedFormControl(),
      raid5: new UntypedFormControl(),
      raid6: new UntypedFormControl(),
      raid10: new UntypedFormControl(),
      raid50: new UntypedFormControl(),
      raid60: new UntypedFormControl(),
    });
  }

  @BlockUI() blockUI: NgBlockUI;
  selectedProcessorFamily: any;
  validations: UntypedFormGroup;
  dialogConfig;
  errorMsg = "";
  showLoader: boolean = false;
  type;
  released;
  update: boolean = false;
  isDisabled: boolean = true;
  idlePower: number = null;
  maxPower: number = null;
  saveCloseFlag: boolean = true;
  ioTypes: ViewType[] = [
    { value: "MEZ", viewValue: "MEZ" },
    { value: "PCI", viewValue: "PCI" },
    { value: "RAID", viewValue: "RAID" },
    { value: "PCI-RAID", viewValue: "PCI-RAID" },
  ];
  deviceTypes: ViewType[] = [
    { value: "SSD", viewValue: "SSD" },
    { value: "HDD", viewValue: "HDD" },
  ];
  processorFamily: ViewType[] = [];
  memoryFamily: ViewType[] = [];
  isProcessorFamilyFetched: boolean = false;
  isMemoryFamilyFetched: boolean = false;
  get dynamicAttributes() {
    return this.validations.controls["dynamicAttributes"] as UntypedFormArray;
  }
  onNoClick(): void {
    this.type = this.data.type;
    this.update = this.data.update;
    this.saveCloseFlag = false;
    this.dataToSave();
  }

  showPID = true;
  ngOnInit(): void {
    this.componentDisplayName = this.data.componentDisplayName;
    if (this.data.dynamicComponentAttributes && this.data.dynamicComponentAttributes.length > 0) {
      this.dynamicComponentAttributes = this.data.dynamicComponentAttributes;
      this.dynamicComponentData = this.data.dynamicComponentData;
      if (!this.isDynamicFormCreated) {
        this.isDynamicFormCreated = true;
        this.buildDynamicForm();
      }
    }

    this.adminService
      .getDynamicComponentAttributes()
      .subscribe((attributes) => {
        this.dynamicComponentAttributes = attributes;
        this.dynamicComponentData = this.data.dynamicComponentData;
        if (!this.isDynamicFormCreated) {
          this.isDynamicFormCreated = true;
          this.buildDynamicForm();
        }
      });
    this.showPID = true;
    this.getManageProcessorsFamilyList();
    this.getManageDimmsFamilyList();

    if (this.data.update) {
      this.selectedPid = this.data.pid;
      this.additionalFields = this.data["additionalFields"]
        ? this.data["additionalFields"]
        : [];
    }
  }
  setDynamicAttributes() {
    this.dynamicComponentAttributes.forEach((d) =>
      this.setAttributeValue(d.key)
    );
  }

  getProperties(attribute) {
    return {
      id: attribute.key,
      name: attribute.name,
      required: attribute.required,
      type: attribute.type,
      visible: attribute.visible,
    };
  }
  getSortedOrder(field?: string) {
    let fields = [
      "displayName",
      "internalName",
      "size",
      "pid",
      "processorFamilyName",
      "memoryFamilyName",
      "mtbf",
      "mpn",
      "tdp",
      "ioType",
      "raid",
      "deviceType",
      "storageZone",
      "capacity",
      "idlePower",
      "maxPower",
      "noOfMemories",
    ];
    return fields.indexOf(field) == -1
      ? Number.MAX_SAFE_INTEGER
      : fields.indexOf(field);
  }
  buildDynamicForm() {
    if (this.dynamicComponentAttributes) {
      this.dynamicComponentAttributes = this.dynamicComponentAttributes.sort(
        (a, b) => this.getSortedOrder(a.key) - this.getSortedOrder(b.key)
      );
      for (let attribute of this.dynamicComponentAttributes) {
        switch (attribute.key) {
          case "displayName":
          case "pid":
            const textForm = this.formBuilder.group({
              ...this.getProperties(attribute),
              value: [null, Validators.compose([
                Validators.required,
                Validators.pattern(AppConstant.VALID_INPUT_REGEX)
              ])],
            });
            this.dynamicAttributes.push(textForm);
            break;
          case "mpn":
            const mpnForm = this.formBuilder.group({
              ...this.getProperties(attribute),
              value: null,
            });
            this.dynamicAttributes.push(mpnForm);
            break;
          case "mtbf":
            const mtbfForm = this.formBuilder.group({
              ...this.getProperties(attribute),
              value: [
                null,
                Validators.compose([
                  Validators.pattern("^[0-9]*$"),
                  Validators.pattern(AppConstant.VALID_INPUT_REGEX),
                  Validators.min(0),
                ]),
              ],
            });
            this.dynamicAttributes.push(mtbfForm);
            break;
          case "maxPower":
          case "idlePower":
          case "tdp":
            const decimalForm = this.formBuilder.group({
              ...this.getProperties(attribute),
              value: [
                null,
                Validators.compose([
                  Validators.required,
                  Validators.pattern("^[0-9]+(.[0-9]{0,2})?$"),
                  Validators.min(0.1),
                ]),
              ],
            });
            this.dynamicAttributes.push(decimalForm);
            break;

          case "noOfMemories":
          case "size":
          case "capacity":
            const numericForm = this.formBuilder.group({
              ...this.getProperties(attribute),
              value: [
                null,
                Validators.compose([
                  Validators.required,
                  Validators.pattern("^[0-9]*$"),
                  Validators.min(0),
                ]),
              ],
            });
            this.dynamicAttributes.push(numericForm);
            break;

          case "ioType":
          case "deviceType":
          case "processorFamilyName":
          case "memoryFamilyName":
            const dropdownForm = this.formBuilder.group({
              ...this.getProperties(attribute),
              value: [null, Validators.required],
            });
            this.dynamicAttributes.push(dropdownForm);
            break;

          case "storageZone":
            const checkboxForm = this.formBuilder.group({
              ...this.getProperties(attribute),
              value: {
                FRONT: false,
                REAR: false,
                INTERNAL: false,
              },
            });
            this.dynamicAttributes.push(checkboxForm);
            break;
        }
      }
    }
  }
  getOptionsList(key: string) {
    if (key == "ioType") {
      return this.ioTypes;
    } else if (key == "deviceType") {
      return this.deviceTypes;
    } else if (key == "processorFamilyName") {
      return this.processorFamily;
    } else if (key == "memoryFamilyName") {
      return this.memoryFamily;
    }
    return [];
  }
  setRaid(type: number, attribute, $event) {
    switch (type) {
      case 0:
        this.validations.controls.raid0.patchValue($event.checked);
        break;
      case 1:
        this.validations.controls.raid1.patchValue($event.checked);
        break;
      case 5:
        this.validations.controls.raid5.patchValue($event.checked);
        break;
      case 6:
        this.validations.controls.raid6.patchValue($event.checked);
        break;
      case 10:
        this.validations.controls.raid10.patchValue($event.checked);
        break;
      case 50:
        this.validations.controls.raid50.patchValue($event.checked);
        break;
      case 60:
        this.validations.controls.raid60.patchValue($event.checked);
        break;
    }

    this.validateForm(attribute);
  }
  resetRaid() {
    this.validations.controls.raid0.patchValue(false);
    this.validations.controls.raid1.patchValue(false);
    this.validations.controls.raid5.patchValue(false);
    this.validations.controls.raid6.patchValue(false);
    this.validations.controls.raid10.patchValue(false);
    this.validations.controls.raid50.patchValue(false);
    this.validations.controls.raid60.patchValue(false);
  }
  getRaidData() {
    let res = "";
    for (let val of [0, 1, 5, 6, 10, 50, 60]) {
      let raidValue = this.validations.controls[`raid${val}`].value;
      if (raidValue) {
        if (val < 10) res += `0${val},`;
        else res += val.toString() + ",";
      }
    }
    return res.substring(0, res.length - 1);
  }
  setRaidData(raid: string) {
    if (raid) {
      let raidValues = raid.split(",").map((v) => Number(v));
      for (let val of raidValues) {
        this.validations.controls[`raid${val}`].patchValue(true);
      }
    }
  }
  getStorageZoneData(storageZone) {
    let res = "";
    for (let [k, v] of Object.entries(storageZone)) {
      if (v) res += k + ",";
    }
    return res.substring(0, res.length - 1);
  }
  getStorageZoneObj(storageZone: string) {
    let res = { FRONT: false, REAR: false, INTERNAL: false };
    if (storageZone) {
      let storageZones = storageZone.split(",");

      for (let zone of ["FRONT", "REAR", "INTERNAL"]) {
        if (storageZones.includes(zone)) {
          res[`${zone}`] = true;
          if (zone == "FRONT") this.frontChecked = true;
          else if (zone == "REAR") this.rearChecked = true;
          else if (zone == "INTERNAL") this.internalChecked = true;
        }
      }
    }
    this.checkZoneSelected();
    return res;
  }
  componentDisplayName: any = "";
  getAttributeValue(key: string) {
    let dynamicAttributes = this.validations.value.dynamicAttributes;
    let res = null;
    let found = dynamicAttributes.find((d) => d.id == key);
    if (found) {
      res = found.value;
      if (typeof res == "object") {
        if (key == "storageZone") {
          res = this.getStorageZoneData(res);
        } else {
          res = res ? res.value : null;
        }
      }
    }
    return res;
  }
  setAttributeValue(key: string) {
    let dynamicAttributes = this.validations.value.dynamicAttributes;
    let res = null;
    let found = dynamicAttributes.find((d) => d.id == key);
    let fieldValue = key;

    if (key == "idlePower") {
      fieldValue = "idleWatts";
      this.idlePower = this.dynamicComponentData[`${fieldValue}`];
    }
    if (key == "maxPower") {
      fieldValue = "maxWatts";
      this.maxPower = this.dynamicComponentData[`${fieldValue}`];
    }

    if (key == "memoryFamilyName") fieldValue = "dimmFamName";
    if (key == "processorFamilyName") fieldValue = "cpuFamName";
    if (key == "noOfMemories") fieldValue = "numDimms";
    if (key == "deviceType") fieldValue = "storageType";
    if (key == "storageZone") fieldValue = "storageZoneType";

    this.setRaidData(this.dynamicComponentData["raid"]);
    if (found) {
      let attributeValue = this.dynamicComponentData[`${fieldValue}`];
      switch (found.type) {
        case "text":
        case "integer":
          this.patchFormValue(found.id, attributeValue);
          break;
        case "dropdown":
          let value = this.getOptionsList(key).find(
            (f) => f.value == attributeValue
          );
          this.patchFormValue(found.id, value);
          break;
        case "checkbox":
          this.patchFormValue(found.id, this.getStorageZoneObj(attributeValue));
          break;
      }
    }
  }
  patchFormValue(key, value) {
    const controls = this.validations.get("dynamicAttributes")["controls"];
    for (const control of controls) {
      if (control.value.id == key) {
        control.controls.value.patchValue(value);
        break;
      }
    }
  }
  dataToSave() {
    if (this.validations.valid) {
      let value = this.validations.value;
      let data = {
        type: this.data.type,
        displayName: this.getAttributeValue("displayName"),
        internalName: _.camelCase(this.getAttributeValue("displayName")),
        idleWatts: this.getAttributeValue("idlePower"),
        maxWatts: this.getAttributeValue("maxPower"),
        pid: this.getAttributeValue("pid"),
        ioType: this.getAttributeValue("ioType"),
        mtbf: this.getAttributeValue("mtbf"),
        mpn: this.getAttributeValue("mpn"),
        storageType: this.getAttributeValue("deviceType"),
        storageZoneType: this.getAttributeValue("storageZone"),
        capacity: this.getAttributeValue("capacity"),
        tdp: this.getAttributeValue("tdp"),
        numDimms: this.getAttributeValue("noOfMemories"),
        size: this.getAttributeValue("size"),
        dimmFamName: this.getAttributeValue("memoryFamilyName"),
        cpuFamName: this.getAttributeValue("processorFamilyName"),
        raid: this.getRaidData(),
        released: "",
        additionalFields: this.setAdditionalFields(),
        allowDuplicates: false,
        dynamicComponent: true,
        role: "Admin",
      };
      if (!this.data.update) {
        let result = this.createIODevice(data);
      } else {
        data.released = this.data.released;
        data["id"] = this.data.id;
        let result = this.updateIODevice(data);
      }
    } else {
      console.error("Form invalid", this.validations.valid);
    }
  }
  validateForm(attribute) {
    if (attribute.value.id == "ioType") {
      if (!attribute.value.value?.value.includes("RAID")) {
        this.resetRaid();
      }
    } else if (
      attribute.value.id == "idlePower" ||
      attribute.value.id == "maxPower"
    ) {
      let data = {
        idlePower: this.idlePower,
        maxPower: this.maxPower,
      };
      if (attribute.value.id == "idlePower") {
        data.idlePower = attribute.value.value;
        this.idlePower = data.idlePower;
      } else {
        data.maxPower = attribute.value.value;
        this.maxPower = data.maxPower;
      }
      this.validateWatts(data);
    }
    this.patchFormValue(attribute.value.id, attribute.value.value);
  }

  frontChecked = false;
  rearChecked = false;
  internalChecked = false;
  zoneCkeckboxSelected = false;

  checkZoneSelected() {
    if (this.frontChecked || this.rearChecked || this.internalChecked) {
      this.zoneCkeckboxSelected = true;
    } else {
      this.zoneCkeckboxSelected = false;
    }
  }
  checkedHandler(e, type, attribute) {
    if (type == "FRONT") {
      this.frontChecked = e.target.checked;
    } else if (type == "REAR") {
      this.rearChecked = e.target.checked;
    } else if (type == "INTERNAL") {
      this.internalChecked = e.target.checked;
    }
    this.checkZoneSelected();
    this.setAttributeValues(attribute);
  }
  setAttributeValues(attribute) {
    attribute.value.value = {
      FRONT: this.frontChecked,
      REAR: this.rearChecked,
      INTERNAL: this.internalChecked,
    };
    this.validateForm(attribute);
  }
  setAdditionalFields() {
    let array = [];
    if (this.additionalFields?.length > 0) {
      this.additionalFields.forEach((element) => {
        let row = {
          name: element.name,
          type: element.type,
          value: element.value,
        };
        array.push(row);
      });
    } else array = [];

    return array;
  }

  onSave() {
    this.dataToSave();
  }

  createIODevice(reqData: any) {
    const url = UrlConstant.ADDITIONALCOMPONENTS;
    this.blockUI.start("Loading...");
    let params = [];
    params.push(reqData);
    params = params.concat(this.applyToRecords);
    params = params.concat(this.updatedRecords);

    this.subscriptions.add(
      this.apiService.postMethod(url, reqData).subscribe(
        (data: any) => {
          this.refreshData = true;
          if (data.success) {
            this.toaster.show("success", errorCodes.NEW_RECORD, "");
            if (this.saveCloseFlag) {
              window.scroll(0, 0);
              this.dialogRef.close({
                data: {
                  action: PlatformConstant.ACTION_REFRESH,
                },
              });
            } else {
              // this.resetForm();
            }
          }

          this.blockUI.stop();
        },
        (error) => {
          this.errorMsg = error?.error
            ? error.error?.error?.errorString
            : errorCodes.GENERIC_ERROR_MESSAGE;
          this.toaster.show(
            "error",
            this.errorMsg,
            error ? error?.message : ""
          );
          this.blockUI.stop();
        }
      )
    );
  }

  updateIODevice(reqData: any) {
    this.blockUI.start("Loading...");
    let params = [];
    params.push(reqData);
    params = params.concat(this.applyToRecords);
    params = params.concat(this.updatedRecords);

    const url = UrlConstant.MANAGECOMPONENTS + UrlConstant.UPDATE;
    this.subscriptions.add(
      this.apiService.postMethod(url, params).subscribe(
        (data: any) => {
          this.blockUI.stop();
          this.refreshData = true;
          if (data.successMessage == "Successfully updated") {
            this.toaster.show("success", errorCodes.UPDATE_RECORD, "");
            if (this.saveCloseFlag) {
              window.scroll(0, 0);
              this.dialogRef.close({
                data: {
                  action: PlatformConstant.ACTION_REFRESH,
                },
              });
            } else {
              this.saveCloseFlag = true;
            }
          } else if (
            data.modifiedCount == 0 &&
            data.error.errorString == "Duplicate Data Found"
          ) {
            reqData["allowDuplicates"] = true;
            this.openModal(reqData);

            this.subscriptions.add(
              this.apiService
                .postMethod(url, reqData)
                .subscribe((data: any) => {
                  if (data.success) {
                    this.toaster.show("success", errorCodes.UPDATE_RECORD, "");
                    window.scroll(0, 0);
                    this.dialogRef.close({
                      data: {
                        action: PlatformConstant.ACTION_REFRESH,
                      },
                    });
                  }
                })
            );
          }
        },
        (error) => {
          this.errorMsg = error?.error
            ? error.error?.error?.errorString
            : errorCodes.GENERIC_ERROR_MESSAGE;
          this.toaster.show(
            "error",
            this.errorMsg,
            error ? error?.message : ""
          );
          this.blockUI.stop();
        }
      )
    );
  }
  @HostListener("window:keyup.esc") onKeyUp() {
    let cn = confirm("you want to close ?");
    if (cn) {
      this.dialogRef.close();
    }
  }
  refreshData: boolean = false;
  public onCancel = () => {
    this.dialogRef.close({
      data: {
        action: this.refreshData
          ? PlatformConstant.ACTION_REFRESH
          : PlatformConstant.ACTION_CANCEL,
      },
    });
  };

  showWattErr: boolean = false;
  IdleMaxWattsErr: boolean = false;
  IdleWattsErr: boolean = false;
  mtbfErr: boolean = false;
  validateWatts(data: any) {
    this.showWattErr = false;
    this.IdleMaxWattsErr = false;
    this.IdleWattsErr = false;
    this.mtbfErr = false;
    let idlePower = data.idlePower;
    let maxPower = data.maxPower;
    if (idlePower && maxPower && !isNaN(idlePower) && !isNaN(maxPower)) {
      this.showWattErr = Number(maxPower) >= Number(idlePower) ? false : true;
      this.cdr.detectChanges();
    }

    if (idlePower && (isNaN(idlePower) || parseFloat(idlePower) < 0)) {
      this.IdleWattsErr = true;
      this.cdr.detectChanges();
    }
    if (maxPower && (isNaN(maxPower) || parseFloat(maxPower) < 0)) {
      this.IdleMaxWattsErr = true;
      this.cdr.detectChanges();
    }
  }

  public async openModal(data: any) {
    const result = await this.modalService
      .open({
        content: {
          type: CngContentConfigType.COMPONENT,
          content: ConfirmationBoxComponent,
          componentData: {
            someText: this.translate.instant("DUPLICATE_DATA_FOUND"),
            key: "delete",
          },
        },
        size: CngModalSize.SMALL,
      })
      .onDismiss.toPromise();

    if (result == "ok") {
      this.getDuplicateData(data);
    }
  }
  getDuplicateData(data: any) {
    this.subscriptions.add(this.adminService.setData(data));
  }

  resetForm() {
    this.showPID = false;

    this.validations.reset();
    this.saveCloseFlag = true;
    this.isDisabled = true;
    this.additionalFields = [];
    //search pid
    this.selectedPid = null;
    setTimeout(() => {
      this.showPID = true;
    }, 100);
  }

  //addional fields
  additionalFields: any = [];
  applyToRecords: any = [];
  updatedRecords: any = [];
  isAdditionFieldsEdited: boolean = false;

  openAdditionalAtt_Modal() {
    const dialogRef = this.dialog.open(AdditionalAttributesComponent, {
      data: {
        params: this.data,
        additionalFields: this.additionalFields,
        p_type: ComponentConstant.COMPONENT,
        type: ComponentConstant.SECURITY,
        edited: this.isAdditionFieldsEdited,
      },
      disableClose: true,
      panelClass: "width-dialog-100",
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result.data.action !== PlatformConstant.ACTION_CANCEL) {
        this.additionalFields = result.data.addedFields;
        this.applyToRecords = result.data.additionalRecords;
        this.isAdditionFieldsEdited = result.data.edited;
        //
        this.updatedRecords = [];
        this.additionalFields.forEach((element) => {
          if (
            element.flag == "UpdateField" &&
            element.hasOwnProperty("selectedItems") &&
            element.selectedItems?.length > 0
          ) {
            element.selectedItems.forEach((element2) => {
              if (
                element2.rowId !== this.data.id &&
                element2.flag !== "NewField"
              ) {
                let ele = this.updatedRecords.filter((value) => {
                  return value.id == element2.rowId;
                });

                if (ele?.length == 0) {
                  this.updatedRecords.push({
                    type: ComponentConstant.SECURITY,
                    role: "Admin",
                    id: element2.rowId,
                    additionalFields: [
                      {
                        name: element.name,
                        type: element.type,
                        value: element.value,
                        flag: element.flag,
                        previousName: element.previousName,
                      },
                    ],
                  });
                }
                //
                else {
                  ele[0].additionalFields.push({
                    name: element.name,
                    type: element.type,
                    value: element.value,
                    flag: element.flag,
                    previousName: element.previousName,
                  });
                }
              }
            });
          }
        });
      }
    });
  }
  selectedPid: any = null;
  selectedPID(e: any) {
    this.selectedPid = e;
  }
  getManageProcessorsFamilyList() {
    this.blockUI.start("Loading...");
    let processorFamily = ComponentConstant.CPU_FAMILIES;
    const url = UrlConstant.MANAGECOMPONENTS + "/?component=" + processorFamily;
    this.subscriptions.add(
      this.apiService.getMethod(url).subscribe(
        (data: any) => {
          let rows = [];
          for (let i = 0; i < data.componenetList.length; i++) {
            let processorFamilyNameObj = {
              value: data.componenetList[i].text,
              viewValue: data.componenetList[i].text,
            };
            rows.push(processorFamilyNameObj);
          }
          rows.push({ value: "new", viewValue: "--Add New Family--" });
          this.processorFamily = rows;
          this.isProcessorFamilyFetched = true;
          if (
            this.isProcessorFamilyFetched &&
            this.isMemoryFamilyFetched &&
            this.data.update
          ) {
            this.setDynamicAttributes();
          }
          this.blockUI.stop();
        },
        (error) => {
          this.blockUI.stop();
          this.errorMsg = error?.error
            ? error.error?.error?.errorString
            : errorCodes.GENERIC_ERROR_MESSAGE;
          this.toaster.show(
            "error",
            this.errorMsg,
            error ? error?.message : "",
            5000
          );
        }
      )
    );
  }
  getManageDimmsFamilyList() {
    this.blockUI.start("Loading...");
    let dimmsFamily = ComponentConstant.DIMM_FAMILIES;
    const url = UrlConstant.MANAGECOMPONENTS + "/?component=" + dimmsFamily;
    this.subscriptions.add(
      this.apiService.getMethod(url).subscribe((data: any) => {
        let rows = [];
        for (let i = 0; i < data.componenetList.length; i++) {
          let dimFamilyNameObj = {
            value: data.componenetList[i].text,
            viewValue: data.componenetList[i].text,
          };
          rows.push(dimFamilyNameObj);
        }
        rows.push({ value: "new", viewValue: "--Add New Family--" });
        this.memoryFamily = rows;

        this.isMemoryFamilyFetched = true;
        if (
          this.isProcessorFamilyFetched &&
          this.isMemoryFamilyFetched &&
          this.data.update
        ) {
          this.setDynamicAttributes();
        }
        this.showLoader = false;
        this.blockUI.stop();
      })
    );
  }
  ngOnDestroy() {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }
}
