import { Component, OnInit ,Inject,HostListener ,ChangeDetectorRef, ComponentFactoryResolver} from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { FormControl, UntypedFormBuilder,FormGroup, Validators,ReactiveFormsModule  } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';
import { UrlConstant } from 'src/constants/url.constants';
import { ToasterService } from "src/app/shared/components/toast/toast.service";
import { PlatformConstant } from 'src/constants/platform.constants';
import { errorCodes } from 'src/constants/errorCodes';
import { BlockUI, NgBlockUI } from 'ng-block-ui';

@Component({
  selector: 'app-cpu-memory-compatibility',
  templateUrl: './cpu-memory-compatibility.component.html',
  styleUrls: ['./cpu-memory-compatibility.component.css']
})
export class CpuMemoryCompatibilityComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<CpuMemoryCompatibilityComponent>,  
    @Inject(MAT_DIALOG_DATA) public platformData : any,
    private apiService: ApiService,
    private toaster: ToasterService, 
    private formBuilder : UntypedFormBuilder,
    private cdr: ChangeDetectorRef ) { }
    subscriptions: Subscription = new Subscription();

  ngOnInit(): void {
    
    this.isPlatformReleased = Number(this.platformData.params.released) == 1;
    this.getCPUs();
  }
  cpulist;
  selectedCpu;
  @BlockUI() blockUI: NgBlockUI; 
  isPlatformReleased: boolean = false;
  platformReleasedInfo: string = PlatformConstant.PLATFORM_RELEASED;
  getCPUs(){
this.cpulist = [];
const params = {"cpuIds": this.platformData.params.cpus ? this.platformData.params.cpus : [] };


    const url = UrlConstant.MANAGEMENTS_CPU;
    this.subscriptions.add(
      this.apiService.postMethod(url,params).subscribe((data: any) => {
        
        this.cpulist = data;
    }, (error) => {
      console.error(error);
      let errorMsg = error?.error ? error.error?.error?.errorString : "Oops something went wrong!!";
      this.toaster.show('error', errorMsg, error ? error?.message : '');
    })
    );
  }
  
  dimsIncompatable=[];
  selectedCpuSeqNo = null;
  changeType(event:any){
    
    if(event.value?.sequence){
      this.blockUI.start('Loading...');
    this.showLoader = 'show';
    
    this.selectedCpuSeqNo = event.value.sequence;
    const params = {
      "cpuIds": [event.value.sequence],
      "dimIds" : this.platformData.params.dimms ? this.platformData.params.dimms : [] ,
      "serverSeqNo" :this.platformData.params.sequence,
      "statusOfDims" : ""
  }
    const url = UrlConstant.GET_DIMS_INCOM;
    this.subscriptions.add(
      this.apiService.postMethod(url,params).subscribe((data: any) => {
        
        this.dimsIncompatable = data.allDims;
        this.dimsIncompatable.forEach(allDims=> {
          allDims["invalidCheck"] = false;
          data.invalidDims.forEach(invalidDims => {
            if(allDims.sequence == invalidDims.dimmSeqNo){
              allDims["invalidCheck"] = true;
            }
          });
        });

        
        this.showLoader = 'hide';
        this.blockUI.stop();
        this.formChanged = false;


      }, error =>{  
        let errorMsg= error?.error ? error.error?.error?.errorString : errorCodes.GENERIC_ERROR_MESSAGE;
        this.toaster.show('error', errorMsg , error ? error?.message : '',5000);
        this.blockUI.stop();
          
      })
    );
    }
    else{
      this.showLoader = null;
      this.dimsIncompatable = [];
    }
  }
  showLoader=null;

  checkedHandler(event,dims){

    this.formChanged = true;
    let checked = event.target.checked;
    dims.invalidCheck = checked;

  }
  edited:boolean = false;

submit(action:any){
  
  if(this.dimsIncompatable?.length>0){
  this.blockUI.start('Loading...');
  this.edited = true;
  let params = [];
  this.dimsIncompatable.forEach(element => {
    let dims = {
      "serverSeqNo":this.platformData.params.sequence,
      "cpuSeqNo": this.selectedCpuSeqNo,
      "dimmSeqNo": element.sequence,
      "invalid": element.invalidCheck
    }
    params.push(dims)
  });
  const url = UrlConstant.CPU_DIMM_INCOMP;
    this.subscriptions.add(
      this.apiService.postMethod(url,params).subscribe((data: any) => {
        
        this.blockUI.stop();
        this.formChanged = false;
        if (data.modifiedCount > 0) {         
          this.toaster.show('success', errorCodes.UPDATE_RECORD , '',5000); 
        }
       if(action == 'closeModal'){
        this.dialogRef.close({
          data: {
            action : PlatformConstant.ACTION_REFRESH
            } 
          });
      }
    },error =>{  
      this.blockUI.stop();
      let errorMsg = error?.error ? error.error?.error?.errorString : "Oops something went wrong!!";
      this.toaster.show('error', errorMsg , error ? error?.message : '',5000);
    })
    );

  
  }
}

close(){
  this.dialogRef.close({
    data: {
        action : this.edited ? PlatformConstant.ACTION_REFRESH : PlatformConstant.ACTION_CANCEL
      } 
    });
}

formChanged:boolean = false;
 

config = {
  displayKey:"displayName", //if objects array passed which key to be displayed defaults to description
  search:true, //true/false for the search functionlity defaults to false,
  height: '200px', //height of the list so that if there are more no of items it can show a scroll defaults to auto. With auto height scroll will never appear
  placeholder:'Select Processor' ,// text to be displayed when no item is selected defaults to Select,
  limitTo: 0 ,// number thats limits the no of options displayed in the UI (if zero, options will not be limited)
  moreText: 'more', // text to be displayed whenmore than one items are selected like Option 1 + 5 more
  noResultsFound: 'No results found!', // text to be displayed when no items are found while searching
  searchPlaceholder:'Search' ,// label thats displayed in search input,
  searchOnKey: 'displayName', // key on which search should be performed this will be selective search. if undefined this will be extensive search on all keys
  clearOnSelection: false, // clears search criteria when an option is selected if set to true, default is false
  inputDirection: 'ltr' // the direction of the search input can be rtl or ltr(default)
}

}
