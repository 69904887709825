<div class="modal__header">
	<div class="modal__title"></div>
	<p  >{{'SAVE_TEMPLATE_AS'| translate}}</p>
</div>
<div class="form-group">
<div class="modal__body form-group__text" >	
	<input id="input-state-default" class="input-template-name" style="width:300px" [(ngModel)]="templateName" type="text">
</div>
</div>
<div class="modal__footer">
	<button class="btn" (click)="onConfimation()" [ngClass]="(templateName !== '') ? 'active': 'disabled'">{{'OK' | translate}}</button>
	<button class="btn" (click)="closeModal()">{{'CANCEL' | translate}}</button>
	<!-- <button class="btn" (click)="closeAllModals()">Close All Modals</button> -->
</div>