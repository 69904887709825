<div id="loading-dots" *ngIf="showLoader1 || showLoader2 || showLoader3 || showLoader4">
  <div class="loading-dots loading-dots--dark" aria-label="Loading, please wait...">
    <span></span>
    <span></span>
    <span></span>
  </div>
</div>
<div class="container project-config-container">
  <!-- <div class="container" ngClass="(showloader) ? 'loader' : ''">
    <div class="row">
      <div class="col-md-12" *ngIf="showLoader1 || showLoader2 || showLoader3 || showLoader4">
        <div class="loader" aria-label="Loading, please wait...">
          <div class="wrapper">
            <div class="wheel"></div>
          </div>
        </div>
      </div>
    </div>
  </div> -->
  <div class="row" *ngIf="!showLoader1 && !showLoader2 && !showLoader3 && !showLoader4">
    <div class="col-md-12" >
      <!-- *ngIf="!showLoader" -->
      <ul id="hleft" class="tabs">
        <li id="Compute" class="tab" [ngClass]="selectedTabId == 1 ? 'active' : ''">
          <a tabindex="0" id="tab1" (click)="onTabClick(1)">{{'COMPUTE' | translate}}</a>
        </li>
        <li id="Storage" class="tab" [ngClass]="selectedTabId == 2 ? 'active' : ''" style="display:none">
          <a tabindex="0" id="tab2" (click)="onTabClick(2)">{{'STORAGE' | translate}}</a>
        </li>
        <li id="Networking" class="tab" [ngClass]="selectedTabId == 3 ? 'active' : ''">
          <a tabindex="0" (click)="onTabClick(3)">{{'NETWORK' | translate}}</a>
        </li>
        <li style="float:right; margin-top:15px; cursor:pointer;">         
          <span class="icon-refresh icon-size-18" style="color:#00bceb" title="Refresh Data" (click)="refreshData()"></span>
        </li>
        <li id="Search" style="float: right; vertical-align: middle;margin-top:8px;margin-right:15px;">
          <div class="row" style="display: flex; align-items:center;" *ngIf="!isObjectEmpty(searchSeries)">
            <div class="col-3"></div>
            <div class="col-8" style="padding-left:0px; margin-left:-5px;">
              <div class="form-group">
                <div class="form-group__text">
                <input 
                [(ngModel)]="searchQuery"
                style="width : 100%; height: 100%;flex:auto" 
                type="text"
                placeholder="{{ 'SEARCH' | translate }}.."
                (ngModelChange)="doSearch()">
                </div>
                </div>
            </div>
            <div class="col-1">
                <div 
                class="icon-search icon-size-18"
                [attr.disabled]="searchQuery.length < 1 ? true: null"
                ></div> 
            </div>
        </div>
        </li>
      </ul>
    </div>
   
    <div id="hleft-content" class="tab-content col-md-12">
      <div id="hleft-1-content" class="tab-pane" *ngIf="!showAdditional" [ngClass]="selectedTabId == 1 ? 'active' : ''">
       
        <app-series [secondaryClasses] = "secondaryClassesCpy"></app-series>
      </div>
      <div *ngIf="bladeList && selectedTabId ==1 && showAdditional">
        <a role="button" (click)="onCancel()" style="margin-top: 10px;">BACK</a>
        <!-- <app-series [secondaryClasses] = "bladeList"></app-series> -->
        <div class="row available-row">
          <div class="col-md-12" *ngIf="bladeList[0].seriesValues.length > 0">
          <span class="btn-group span-BtnAvailable">
            <button class="btn btn--ghost btn-available" [ngClass]="available ? 'selected' : ''"
              (click)="onAvailableClick()">
              {{'AVAILABLE' | translate}}
            </button>
            <button class="btn btn--ghost btn-discontinue" (click)="onDiscontinuedClick()"
              [ngClass]="available ? '' : 'selected'">
              {{'DISCONTINUED' | translate}}
            </button>
          </span></div>
        </div>
        <div class="row" style="border-bottom: 1px solid #adb5bd">

          <div class="col-md-12" *ngIf="currentTemplates?.templates?.length > 0 && doesTemplatesExistForAvailableStatus()">
            <p style="padding-left: 20px; margin-bottom: 0px;font-weight: 500;color:#1e4471;font-size: medium;">
              {{'TEMPLATES' | translate}}</p>
            <app-card [seriesValues]="currentTemplates.templates" [available]="available"
              [template]="true"></app-card>
          </div>
        </div>
        <div class="row">
            <div class="col-md-12">
            <app-card [seriesValues]="bladeListCpy[0].seriesValues" [available]="available" [isChild]="true"></app-card>
        </div>
      </div>
      </div>
      <div id="hleft-2-content" class="tab-pane" [ngClass]="selectedTabId == 2 ? 'active' : ''">
        <p>
          {{ 'TEMP_MESSAGE' | translate }} 
        </p>
      </div>
      <div id="hleft-3-content" class="tab-pane" [ngClass]="selectedTabId == 3 ? 'active' : ''">
               
      <app-series [secondaryClasses] = "networkingClassesCpy"></app-series>
    </div>
  </div>
</div>
</div>
