import { Component, OnInit } from "@angular/core";
import { ICellRendererAngularComp } from "ag-grid-angular";
import { DomSanitizer } from "@angular/platform-browser";

import {
  CngContentConfigType,
  CngModalService,
  CngModalSize,
} from "@cisco/cui-ng";
import { ConfirmationBoxComponent } from "../confirmation-box/confirmation-box.component";
import { TranslateService } from "@ngx-translate/core";
import * as CryptoJS from "crypto-js";
import { Subscription } from "rxjs";
import { Router } from "@angular/router";
import { AppConstant } from "src/constants/app.constants";

@Component({
  selector: "app-icon-renderer",
  templateUrl: "./icon-renderer.component.html",
  styleUrls: ["./icon-renderer.component.css"],
})
export class IconRendererComponent implements OnInit, ICellRendererAngularComp {
  params: any;
  label: string;
  fileUrl: any;
  fileName: string;
  subscriptions: Subscription = new Subscription();
  projectType: string = 'UCS.UserProjects';
  agInit(params): void {
    this.params = params;
    this.label = this.params.label || null;
  }

  preDownloadSetParamters() {
    this.projectType = this.router.url.includes('ucs-resizer') ? 'UCS.SizerProjects' : 'UCS.UserProjects';
    const key_sec = AppConstant.KEY_SEC;
    const data = JSON.parse(localStorage.getItem(`${this.projectType}-${this.params.data.Id}`));
    const jsonData = JSON.stringify(data);
    const encryptedData = CryptoJS.AES.encrypt(jsonData, key_sec).toString();
    const blob = new Blob([encryptedData], { type: "application/octet-stream", });
    //   this.fileUrl = this.sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(blob));
    //   this.fileName = `${this.params.data.projectName}.prj`;
    //SCAVA Fix
    var downloadLink = document.createElement("a");
    downloadLink.download = `${this.params.data.projectName}.prj`;
    downloadLink.href = window.URL.createObjectURL(blob);
    downloadLink.style.display = "none";
    document.body.appendChild(downloadLink);
    downloadLink.click();
  }

  constructor(
    private modalService: CngModalService,
    private translate: TranslateService,
    private sanitizer: DomSanitizer,
    private router: Router,
  ) { }

  ngOnInit(): void { }

  refresh(params?: any): boolean {
    return true;
  }

  public async openModal($event, action: string) {
    const result = await this.modalService
      .open({
        content: {
          type: CngContentConfigType.COMPONENT,
          content: ConfirmationBoxComponent,
          componentData: {
            someText: (action == 'delete') ? (this.translate.instant(
              "ARE_YOU_SURE_TO_DELETE_THIS_PROJECT")) : (this.translate.instant(
                "ARE_YOU_SURE_TO_CLONE_THIS_PROJECT"))
            ,
            key: (action == 'delete') ? "delete" : 'clone',
            event: $event,
          },
        },
        size: CngModalSize.SMALL,
      })
      .onDismiss.toPromise();

    if (result == 'ok' && action == 'delete')
      this.onClick($event, "delete");
    if (result == 'ok' && action == 'clone')
      this.onClick($event, "clone");

  }

  onClick($event, action: string) {
    if (action == "navigate") {

      if (this.router.url.includes('admin/')) {
        this.router.navigate(["/admin/finalResults"]);
      }
      else {
        this.router.navigate(["/finalResults"]);
      }
      localStorage.setItem("currentProjectId", this.params?.data?.Id);

      sessionStorage.setItem("myProjectId", this.params?.data?.Id);
    }
    if (this.params.onClick instanceof Function && action !== "navigate") {
      // put anything into params u want pass into parents component
      const params = {
        event: $event,
        rowData: this.params.node.data,
      };
      if (['delete', 'clone'].includes(action))
        this.params.onClick(this.params);
      else
        this.preDownloadSetParamters();
    }
    if (action == 'sizer') {
      if (this.router.url.includes('admin/')) {
        this.router.navigate(["/admin/ucs-resizer/finalResults"]);
      }
      else {
        this.router.navigate(["/ucs-resizer/finalResults"]);
      }
    }
  }

  ngOnDestroy() {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }
}
