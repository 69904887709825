import { Component, OnInit } from '@angular/core';
import { ToasterService } from 'src/app/shared/components/toast/toast.service';
import { ApiService } from 'src/app/services/api.service';
import { errorCodes } from 'src/constants/errorCodes';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-edit-error-messages',
  templateUrl: './edit-error-messages.component.html',
  styleUrls: ['./edit-error-messages.component.css']
})
export class EditErrorMessagesComponent implements OnInit {
  errorTypes: string[] = [];
  errorMessages: string[] = [];
  errorMessagesRef: string[] = [];
  isEdit: boolean[] = [];
  @BlockUI() blockUI: NgBlockUI;
  subscription: Subscription = new Subscription();
  
  constructor(
    private toast: ToasterService,
    private apiService: ApiService
    ) { }

  ngOnInit(): void {
    this.blockUI.start('Loading...')
    this.subscription.add(this.apiService.getMethod('admin/getErrorMessages').subscribe((data) => {
        
        for (let [key, value] of Object.entries(data)) {
          this.errorTypes.push(key.toString());
          this.errorMessages.push(value.toString());
        }
        this.errorMessagesRef = [...this.errorMessages];
        
        this.isEdit = new Array<boolean>(this.errorTypes.length).fill(false); 
        
        this.blockUI.stop();
      })
    );
    

  }
  onClickEdit($event, idx: number) {
    
    this.isEdit[idx] = !this.isEdit[idx];
    for (let i = 0; i < this.errorMessages.length; i++) {
      if (i!==idx) {
        // this.errorMessages[i] = this.errorMessagesRef[i];
        this.isEdit[i] = false;
      }
    } 

    
  }
  onChangeErrorMessage($event, idx: number) {
    
    if ($event.target.value.trim() === '') {
      // this.errorMessages[idx] = this.errorMessagesRef[idx];
      this.toast.show('error', errorCodes.ERROR_MSG_EMPTY, '', 5000); 
      return ;
    }
    let payload = {
      "errorType": this.errorTypes[idx],
      "errorMessage": this.errorMessages[idx],
      "lastUpdatedDate": new Date().toISOString().slice(0,10),
      "updatedBy": JSON.parse(localStorage.getItem('userData'))?.mail.split("@")[0]?JSON.parse(localStorage.getItem('userData'))?.mail.split("@")[0]:"Admin"
    }
    this.blockUI.start('Loading...');
    
    this.subscription.add(this.apiService.postMethod('admin/updateErrorMessage', payload).subscribe((response) => {
        
        this.toast.show('success', errorCodes.ERROR_MSG_UPDATED, '', 5000);
        this.blockUI.stop();
      })
    );
    this.isEdit[idx]=false;


    
    
  }

}
