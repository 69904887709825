import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { Router, RoutesRecognized } from "@angular/router";
import { ApiService } from "src/app/services/api.service";
import { Subscription } from "rxjs";
import { UrlConstant } from "../../../constants/url.constants";
import { TranslateService } from "@ngx-translate/core";
import { ToasterService } from "src/app/shared/components/toast/toast.service";
import { LicenseText } from "./license.interface";
import { UserLoginModalComponent } from "../user-login-modal/user-login-modal.component";
import { MatDialog } from "@angular/material/dialog";
import { errorCodes } from "src/constants/errorCodes";
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { filter, pairwise } from "rxjs/operators";

@Component({
  selector: "app-license",
  templateUrl: "./license.component.html",
  styleUrls: ["./license.component.css"],
})
export class LicenseComponent implements OnInit, OnDestroy {
  @BlockUI() blockUI: NgBlockUI;
  subscriptions: Subscription = new Subscription();
  licenseText = "";
  showLoader: boolean = false;
  errorMsg = '';
  userData: any;
  licenseTextResponse = [];
  routedOnStartUp: boolean = false;
  constructor(private router: Router,
    private apiService: ApiService,
    private translateService: TranslateService,
    private toaster: ToasterService,
    public dialog: MatDialog
  ) {

    this.userData = JSON.parse(localStorage.getItem('userData'));
    if (!this.userData) {
      dialog.open(UserLoginModalComponent, {
        disableClose: true,
        backdropClass: 'backdrop-dialog',
        panelClass: 'full-max-width-dialog',
      });
    }
    if (this.userData) {
      /** auto agree to license if logged-in */
      localStorage.setItem('UCS.EulaAcceptanceStatus', 'true');
      this.onAgreeToLicense();
    }
  }

  ngOnInit(): void {
    this.getLicenseText();
  }

  getLicenseText() {
    let currentLanguage = this.translateService.getBrowserLang();
    (this.translateService.store.langs.includes('currentLanguage')) ? currentLanguage : currentLanguage = 'en';

    const url = UrlConstant.LICENSETEXT + '/?language=' + currentLanguage;
    this.blockUI.start('Loading...');//this.showLoader = true;
    this.subscriptions.add(
      this.apiService.getMethod(url).subscribe((result: any) => {
        this.licenseText = result.licenseText;
        this.blockUI.stop();//this.showLoader = false;         
        //  this.toaster.show('success', 'Well done!', 'This is a success alert');
      }, error => {
        this.errorMsg = error?.error ? error.error?.error?.errorString : errorCodes.GENERIC_ERROR_MESSAGE;
        this.toaster.show('error', this.errorMsg, error ? error?.message : '');
        this.blockUI.stop();//this.showLoader = false;    
      })
    )
  }

  onAgreeToLicense() {
    this.router.navigate(["/projectList"]);
    localStorage.setItem('UCS.EulaAcceptanceStatus', 'true');
  }

  onDeclineLicense() {
    window.location.href = "http://www.cisco.com/";
    localStorage.setItem('UCS.EulaAcceptanceStatus', 'false');
  }

  ngOnDestroy() {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }
  text = 'The contents of this tool are based on public information as of July 31, 2013. The tool provides a power estimate. Actual customer loads may be significantly lower or higher than the estimate provided. Power usage and airflow will vary depending on system resource configuration and utilization application type, ambient temperature and other factors. If the customer is running High Performance Computing applications and intends to use Linpack as a test tool they may see higher consumption than this guidance. This tool is provided with the understanding that Cisco, its directors, officers, employees and agents are not engaged in rendering business, purchasing, legal, accounting, tax, or other professional advice and services. This tool must not be used as a substitute for consultation with professionals. While Cisco has attempted to provide reliable data and formulae in this tool, Cisco is not responsible for any error or omissions for the results obtained from this tool. The tool, and its output, is provided \"AS IS,\" with no warranty of merchantability, fitness for a particular purpose, completeness, accuracy or timeliness.\n IN NO EVENT WILL CISCO, ITS AGENTS OR EMPLOYEES BE LIABLE TO THE CUSTOMER OR ANYONE ELSE FOR ANY DECISION MADE OR ACTION TAKEN IN RELIANCE ON THE INFORMATION OR OUTPUT OF THIS TOOL, OR BE LIABLE FOR ANY LOST REVENUE, PROFIT, OR DATA, OR FOR SPECIAL, INDIRECT, CONSEQUENTIAL, INCIDENTAL, OR PUNITIVE DAMAGES HOWEVER CAUSED AND REGARDLESS OF THE THEORY OF LIABILITY ARISING OUT OF THE USE OF OR INABILITY TO USE TOOL EVEN IF CISCO HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.'
}
