import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  private messageSource$ = new BehaviorSubject('default message');
  currentMessage = this.messageSource$.asObservable();
  constructor() { }

  sendMessage(message: any) {
    this.messageSource$.next(message)
  }

  // set searchQuery
  private searchQuery$ = new BehaviorSubject(null);
  getSearchQuery() {
    return this.searchQuery$.asObservable();
  }
  setSearchQuery(currentData: any) {
    this.searchQuery$.next(currentData)
  } 
}
