import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { CNG_DATA, CngModalService } from '@cisco/cui-ng';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-input-project-name',
  templateUrl: './input-project-name.component.html',
  styleUrls: ['./input-project-name.component.css']
})
export class InputProjectNameComponent implements OnInit, OnDestroy {
  isModalShown = false;
  subscriptions: Subscription = new Subscription(); 
  projectName ='';
  doesMatchExist: boolean = false;

  constructor(@Inject(CNG_DATA) public data: any,
  private modalService: CngModalService) { }

  ngOnInit(): void {
    this.projectName = this.data.key?.projectName;
    this.matchNames();
  }
  matchNames() {
    let matchNames = this.data.key?.matchNames;
    let matchFound = false;
    for (let i = 0; i < matchNames.length; i++) {
      if (matchNames[i].trim() == this.projectName.trim()) {
        matchFound = true; break;
      }
    }
    if (matchFound) this.doesMatchExist = true; 
    else this.doesMatchExist = false;
  }
  public closeModal () {
		this.subscriptions.add(this.modalService.close('closed'));
  }
  
  onConfimation() {   
    this.subscriptions.add(this.modalService.close(this.projectName));
  }  

  ngOnDestroy(): void {
    if(this.subscriptions) {
      this.subscriptions.unsubscribe();
     }
  }
}
