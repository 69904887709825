import { Injectable } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { ApiService } from './api.service';
import { ToasterService } from '../shared/components/toast/toast.service';
import { errorCodes } from 'src/constants/errorCodes';

@Injectable({
  providedIn: 'root'
})
export class UpdateNameService {
  public updatedNames$ = new BehaviorSubject<{currentName: string, internalName: string}[]>([]);
  public typesData$ = new BehaviorSubject<any[]>([]);
  public componentData$ = new BehaviorSubject<any[]>([]);
  public currentNames: {currentName: string, internalName: string}[] = [];
  public typeDataArray: any[] = [];
  public manageComponents: any[] = [];
  public componentDataList: any[] = [];
  subscriptions: Subscription = new Subscription();
  manageComponentsLPanelDataUrl: string = 'admin/manageComponentsLPanelData'; 


  constructor(
    private apiService: ApiService,
    private toast: ToasterService
  ) { 
    this.subscriptions.add(this.apiService.getMethod(this.manageComponentsLPanelDataUrl).subscribe((response) => {
      response = response.sort(function(a,b) { return Number(a.position) - Number(b.position) });
      this.componentData$.next(response);
      response.map((manageComponent) => {
        this.componentDataList.push(manageComponent);
        if (manageComponent.displayName !== null) {
          this.currentNames.push({
            currentName: manageComponent.displayName,
            internalName: manageComponent.internalName,
          });
          this.typeDataArray.push({
            "componentType" : manageComponent.componentType,
            "additionalComponent": manageComponent.additionalComponent,
          });
          this.manageComponents.push(manageComponent);
        }
      });
      this.setNames();
    })
    );

  }
  setNames() {
    this.updatedNames$.next(this.currentNames);
    this.typesData$.next(this.typeDataArray);
    // this.componentData$.next(this.componentDataList);
  }
  getCurrentName() {
    return this.updatedNames$.asObservable();
  }
  getCurrentType() {
    return this.typesData$.asObservable();
  }
  getCurrentData() {
    return this.componentData$.asObservable();
  }
  
  addNewComponentNameAndType(newName: string, newType: string, internalName: string) {
    this.currentNames.push({
      currentName: newName,
      internalName: internalName
    });
    this.updatedNames$.next(this.currentNames);
    this.typeDataArray.push({
      "componentType": newType,
      "additionalComponent": true
    })
    this.typesData$.next(this.typeDataArray);
  }
  updateAllNames(newName: string, idx: number) : boolean {

    this.updatedNames$.subscribe((names) => this.currentNames = names);

    // put request here
    let updatedNameData;
    if (idx < this.manageComponents.length) {
      updatedNameData = this.manageComponents[idx];
     
      updatedNameData.displayName = newName;
      

      this.subscriptions.add(this.apiService.putMethod(this.manageComponentsLPanelDataUrl, updatedNameData)
      .subscribe((response) => {
        
        // response.modifiedCount = Math.round(Math.random());
        if (response.modifiedCount !== 0) {
          this.toast.show("success", errorCodes.COMPONENT_NAME_UPDATED, '', 5000);
          this.currentNames[idx].currentName = newName;
          this.updatedNames$.next(this.currentNames);
          return true;
        } else {
          this.toast.show("error", errorCodes.COMPONENT_PUTDB_ERROR, '', 5000);
          
          return false;
        }
      })
      );
    } else {
    
    return false;
    }
  }

  //set header data
  private platformPanelData$ = new BehaviorSubject([]);
  getplatformPanelData() {
    return this.platformPanelData$.asObservable();
  }
  setplatformPanelData(currentData: any) {
    this.platformPanelData$.next(currentData)
  }

}
