<div class="flex flex-between" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle
style="margin-left: -25px;margin-bottom: 0px;">
    <button mat-button (click)="onBackClick()"  class="btn btn--link focus" >Back</button>
        <div style="    display: flex;
        flex-direction: row-reverse;">
         <div class="flex-center margin_auto_t_b" >
            <a class="anchor-close" id="close" title="Close" (click)="onBackClick()">
            <span class="icon-close icon-size-24 icon-close"></span>
            </a>
        </div>
    </div>
</div>
<div style="min-height: 400px;max-height: 80vh;overflow: auto;">
<div>
    <h4>Add Measurement</h4>
</div>
<div>
    <div class="col-md-6 row" *ngIf="platformData?.params.vendor">
        <div class="col-2" >
            Vendor
        </div>
        <div class="col-md-8 col-lg-8">
            :&nbsp;{{platformData?.params.vendor}}
        </div>
     </div>
     <div class="col-md-6 row" *ngIf="platformData?.params.name">
        <div class="col-2" >
            Model
        </div>
        <div class="col-md-8 col-lg-8">
            :&nbsp;{{platformData?.params.name}}
        </div>
     </div>
     <div class="col-md-6 row" *ngIf="platformData?.params.gen">
        <div class="col-2" >
            Generation
        </div>
        <div class="col-md-8 col-lg-8">
            :&nbsp;{{platformData?.params.gen}}
        </div>
     </div>
</div> 
<br>
    <div class="row"> 
        <div class="col-md-6 base-margin-bottom">
            <div class="row">
                <div class="col-md-2 text-center-custom"><span>Processor</span></div>
                <div class="col-md-10">
        
                    <div class="form-group">

                        <ngx-select-dropdown 
                        (change)="changeType($event,'cpus')" 
                        [multiple]="false" [(ngModel)]="selectedCpu"
                         [config]="config" [options]="cpulist">
                        </ngx-select-dropdown>

                    </div>
                </div>
        
            </div>
        </div>
        <div class="col-md-6 base-margin-bottom">
            <div class="row">
                <div class="col-md-2 text-center-custom"><span>Dimms</span></div>
                <div class="col-md-10">
                    <div class="form-group">

                        <ngx-select-dropdown 
                        (change)="changeType($event,'dimms')" 
                        [multiple]="false" [(ngModel)]="selectedDimm"
                         [config]="config" [options]="dimmList">
                        </ngx-select-dropdown>
                    </div>
                </div>
        
            </div>
        </div>
    </div> 
    <!-- Clone Measurement Options - for processors/dimms/platforms  -->
    <div class="row" *ngIf="showCloneFields">
        <div class="col-md-6 base-margin-bottom">
            <div class="row">
                <div class="col-md-2 text-center-custom"><span>Clone Option</span></div>
                <div class="col-md-10">
        
                    <div class="form-group">

                        <ngx-select-dropdown 
                         (change)="selectCloneOption()"
                         [multiple]="false" [(ngModel)]="selectedCloneOption"
                         [config]="config" [options]="cloneOptions">
                        </ngx-select-dropdown>

                    </div>
                </div>
        
            </div>
        </div> 
    </div>

    <div class="row"  *ngIf="showCloneFields">
        <div *ngIf="selectedCloneOption=='Processors'" class="col-md-6 base-margin-bottom">
            <div class="row">
                <div class="col-md-2 text-center-custom"><span>Processor</span>
                </div>
                <div class="col-md-10">

                    <angular2-multiselect [data]="childCpuList" 
                            [(ngModel)]="childCpu" 
                            [settings]="dropdownSettings" 
                            (onSelect)="onItemSelect($event, 'cpu')" 
                            (onDeSelect)="OnItemDeSelect($event, 'cpu')"
                            (onSelectAll)="onSelectAll($event, 'cpu')"
                            (onDeSelectAll)="onDeSelectAll($event, 'cpu')">
                            <c-item>
                                <ng-template let-item="item">
                                <label style="color: #333;min-width: 150px;">{{item.displayName}}</label>
                                </ng-template>
                            </c-item>
                        </angular2-multiselect>

                </div>
            </div>
        </div>
        <div *ngIf="selectedCloneOption=='Dimms'" class="col-md-6 base-margin-bottom">
            <div class="row">
                <div class="col-md-2 text-center-custom"><span>Dimm</span>
                </div>
                <div class="col-md-10">

                    <angular2-multiselect [data]="dimmChildList" 
                            [(ngModel)]="selectedChildDimm" 
                            [settings]="dropdownSettings" 
                            (onSelect)="onItemSelect($event, 'dimms')" 
                            (onDeSelect)="OnItemDeSelect($event, 'dimms')"
                            (onSelectAll)="onSelectAll($event, 'dimms')"
                            (onDeSelectAll)="onDeSelectAll($event, 'dimms')">
                            <c-item>
                                <ng-template let-item="item">
                                <label style="color: #333;min-width: 150px;">{{item.displayName}}</label>
                                </ng-template>
                            </c-item>
                        </angular2-multiselect>

                </div>
            </div>
        </div>
        <div *ngIf="selectedCloneOption && cloneOptions.includes(selectedCloneOption)" class="col-md-6 base-margin-bottom">
            <div class="col-md-2 text-center-custom"><span>
                <button mat-button class="btn btn--small" 
            [disabled]="!enableClonebutton" (click)="openModalCloneConfirmation()" style="height: 24px;margin: 5px 20px 0 0;"
        >Clone</button>
            </span>

            
        </div>
    </div>   
    </div>             
    <!--  -->

    <div style="margin-top: 7px;margin-left: 20px;display: none;" *ngIf="showLoader == 'show'" class="loading-dots loading-dots--dark">
        <span></span>
        <span></span>
        <span></span>
      </div>
    <div class="col-md-12 row"  *ngIf="(showLoader == 'hide')">
    <div class="responsive-table" style="width: 100%;margin-left: 10px;">
        <table class="table table--lined" aria-label="Lined table example">
            <thead>
                <tr>
                    <th>No of CPUs </th>
                    <th>No of Dimms</th>
                    <th>Idle AC (W)</th>
                    <th>Max AC (W)</th>
                    <th>Measure</th>
                </tr>
            </thead>
            <tbody>
                <tbody>
                    <tr *ngFor="let row of ManagementDetailsList; let i = index">
                        <td>{{row.cpuCount}}</td>
                        <td>{{row.dimmCount}}</td>
                        <td><span *ngIf="row.measured != 0">{{row.idleWatts}}</span>
                            <span *ngIf="row.measured == 0">-</span>
                        </td>
                        <td><span *ngIf="row.measured != 0">{{row.maxWatts}}</span>
                            <span *ngIf="row.measured == 0">-</span>
                        </td>
                        <td><a *ngIf="row.measured == 1" (click)="openMeasurementDetails(row)">Measure</a>
                            <span *ngIf="row.measured == 0">Not Measured</span></td>
                    </tr>                
                
            </tbody>
        </table>
    </div>   
    </div>
</div> 
