<div class="modal__header">
	<div class="modal__title"></div>
</div>
<div class="modal__body">
	<p class="subtitle"  ></p>
	<p *ngIf="data?.someText" [innerText]="data?.someText"></p>
</div>
<div class="modal__footer">
	<button class="btn" (click)="onConfimation()">{{'OK' | translate}}</button>
	<button class="btn" (click)="closeModal()">{{'CANCEL' | translate}}</button>
	<!-- <button class="btn" (click)="closeAllModals()">Close All Modals</button> -->
</div>