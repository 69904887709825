import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { UrlConstant } from 'src/constants/url.constants';
import { LicenseText } from 'src/app/components/license/license.interface';
import { HttpClient } from '@angular/common/http';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ToasterService } from "src/app/shared/components/toast/toast.service";
import { errorCodes } from "src/constants/errorCodes";

@Component({
  selector: 'app-edit-license',
  templateUrl: './edit-license.component.html',
  styleUrls: ['./edit-license.component.css'],
})
export class EditLicenseComponent implements OnInit {
  subscriptions: Subscription = new Subscription();
  license1 = '';
  license1_bk = '';
  l1Update = false;
  showLoader = true;
  errorMsg = '';
  @BlockUI() blockUI: NgBlockUI; 

  constructor(
    private apiService: ApiService,
    private translateService: TranslateService,
    private httpClient: HttpClient,
    private toaster: ToasterService, 

  ) {
    this.getLicenseText();
  }

  ngOnInit(): void {}

  getLicenseText() {
    let currentLanguage = this.translateService.getBrowserLang();
    this.translateService.store.langs.includes('currentLanguage')
      ? currentLanguage
      : (currentLanguage = 'en');
      this.blockUI.start('Loading...');

    const url = UrlConstant.LICENSETEXT + '/?language=' + currentLanguage;
    this.subscriptions.add(
      this.apiService.getMethod(url).subscribe((result: any) => {
        this.license1_bk = result.licenseText;
        this.license1 = result.licenseText;
        this.showLoader = false;
        this.blockUI.stop();
      }, (error) => {
        console.error(error);
        this.errorMsg = error?.error ? error.error?.error?.errorString : "Oops something went wrong!!";
        this.toaster.show('error', this.errorMsg, error ? error?.message : '');
        this.blockUI.stop(); 
      })
    );
  }

  showErr:boolean = false;
  onLicense1Change(value: string) {
    this.showErr = false;
    value = value.trim();
    if (value != this.license1_bk) {
      this.l1Update = true;
    } else {
      this.l1Update = false;
    }
    if (value.trim()==""){
      this.showErr = true;
    }
  }
  lblErrMsg;
  updateL1() {
    this.showLoader = true;
    this.blockUI.start('Loading...');

    let userData = JSON.parse(localStorage.getItem('userData'));
    let currentLanguage = this.translateService.getBrowserLang();
    this.translateService.store.langs.includes('currentLanguage')
      ? currentLanguage
      : (currentLanguage = 'en');
    if (this.license1.trim() == "") {
      this.lblErrMsg = "Mandatory fields are missing";
      this.blockUI.stop();
      this.getLicenseText();
    }
    let data = {
      user: JSON.parse(localStorage.getItem('userData'))?.mail.split("@")[0]?JSON.parse(localStorage.getItem('userData'))?.mail.split("@")[0]:"Admin",//userData.mail,
      role: 'Admin',
      locale: currentLanguage,
      licenseText: this.license1.trim(),
    };
    this.subscriptions.add(
      this.apiService
        .postMethod('admin/updateLicenseText', data)
        .subscribe(() => {
          this.license1 = '';
          this.license1_bk = '';
          this.l1Update = false;
          this.blockUI.stop();
          this.toaster.show('success', errorCodes.LICENSE_UPDATED_MSG, '', 5000);
          this.getLicenseText();
        })
    );
  }

  ngOnDestroy() {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
    this.blockUI.stop();
  }
}
