import { Component, OnInit } from '@angular/core';
import { AdminService } from 'src/app/services/admin.service';
import { ApiService } from 'src/app/services/api.service';
import { GridOptions } from 'ag-grid-community';
import * as moment from 'moment';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { ShowFieldsComponent } from 'src/app/shared/components/show-fields/show-fields.component';
import { Subscription } from 'rxjs';
import { EditRenderGlobalSearchComponent } from 'src/app/shared/components/edit-render-global-search/edit-render-global-search.component';
import { PlatformConstant } from 'src/constants/platform.constants';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { AdminComponent } from '../../../../components/admin/admin.component';

@Component({
  selector: 'app-global-search-results',
  templateUrl: './global-search-results.component.html',
  styleUrls: ['./global-search-results.component.css'],
  providers:[AdminComponent],

})
export class GlobalSearchResultsComponent implements OnInit {

  searchQuery: string = '';
  searchApiUrl: string = 'admin/managePlatforms/byDisplayName?displayName=';
  queryResult: any;
  rowData: any[];
  errorMsg: any;
  frameworkComponents = {
    buttonEditRenderer : EditRenderGlobalSearchComponent             
  };

  @BlockUI() blockUI: NgBlockUI; 

  gridOptions : GridOptions = {
  columnDefs : [
    { headerName: 'Display Name' , field:'name',
    sortable: true,
    flex: 1,
    minWidth: 100,
    filter: true,
    resizable: true,
    cellRenderer: (data) => {
      return data.value ? data.value : data.data.displayName;
    },
    valueGetter: function(data) {

      return data.data?.name ? data.data?.name : data.data?.displayName;

},
    comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
      if (valueA.toUpperCase() == valueB.toUpperCase()){ 
        return 0;
      }
      else{
        return (valueA.toUpperCase() > valueB.toUpperCase()) ? 1 : -1;
      }
    }},
    { headerName: 'PID', field:'basePid',
    sortable: true,
    flex: 1,
    minWidth: 100,
    filter: true,
    resizable: true,
    comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
      if (valueA.toUpperCase() == valueB.toUpperCase()){ 
        return 0;
      }
      else{
        return (valueA.toUpperCase() > valueB.toUpperCase()) ? 1 : -1;
      }
    }},
    { headerName: 'Generation' , field:'gen',hide:true,
        comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
          if (valueA.toUpperCase() == valueB.toUpperCase()){ 
            return 0;
          }
          else{
            return (valueA.toUpperCase() > valueB.toUpperCase()) ? 1 : -1;
          }
        }
      },
    { 
      headerName: 'Type', 
      field:'type',
      sortable: true,
      flex: 1,
      minWidth: 100,
      filter: true,
      resizable: true,
  },
  { headerName: 'Series' , field:'series', hide:true,
      cellRenderer: (data) => {
        
        let series = this.seriesAll.filter(f=>f.series == data.value)
        return series?.length>0 ?series[0].seriesDisplayName : data.value; 
      }},
    { 
      headerName: 'Idle Power (W)', 
      field:'idleWatts',
      sortable: true,
      flex: 1,
      minWidth: 100,
      filter: true,
      resizable: true,hide:false,
      cellRenderer: (data) => {
        return data.value ? data.value : data.data.idlePower;
      },
      valueGetter: function(data) {
  
        return data.data?.idleWatts ? data.data?.idleWatts : data.data?.idlePower;
  
  },
    },
    { 
      headerName: 'Max Power (W)', 
      field:'maxWatts',
      sortable: true,
      flex: 1,
      minWidth: 100,
      filter: true,
      resizable: true,hide:false,
      cellRenderer: (data) => {
        return data.value ? data.value : data.data.maxPower;
      },
      valueGetter: function(data) {
  
        return data.data?.maxWatts ? data.data?.maxWatts : data.data?.maxPower;
  
  },
    },
    { headerName: 'CPU Slots' , field:'cpuSockets',hide:true},
    { headerName: 'Memory Slots' , field:'dimmSlots',hide:true},
    { headerName: 'Disk Slots' , field:'maxDisks',hide:true},
    { headerName: 'IO Slots' , field:'pciSlots',hide:true},
    { headerName: 'Chassis Slots' , field:'numChassisSlots',hide:true},

    { headerName: 'MTBF (Hours)' , field:'mtbf',hide:true},
    { headerName: 'EoS' , field:'discont',minWidth: 80,hide:true,
    maxWidth: 90,
      cellRenderer: (data) => {
        return data.value == "1" ? "True" : "False" ;
      }
    },

    { headerName: 'Weight(lbs)' , field:'weight',hide:true},
    { headerName: 'Power Cap' , field:'implicitCap',hide:true,
    cellRenderer: (data) => {
      return  data.value ? data.value.charAt(0).toUpperCase() + data.value.slice(1) : "";
    }},

    { headerName: 'PSU Slots' , field:'numPsuSlots',hide:true},
    { headerName: 'IOM Slots' , field:'numIomSlots',hide:true},
    { headerName: 'Blade Slots' , field:'numBladeSlots',hide:true},
    { headerName: 'Expansion Slots' , field:'maxExpSlots',hide:true},
    { headerName: 'Ports' , field:'maxPorts',hide:true},
    { headerName: 'Catridge Slots' , field:'numBladeSlots',hide:true},

    { 
      headerName: 'Updated By', 
      field:'editor',
      sortable: true,
      flex: 1,
      minWidth: 100,
      filter: true,
      resizable: true,
    },
    { 
      headerName: 'Last Updated' , 
      field:'lastUpdated',
      sortable: true,
      flex: 1,
      minWidth: 100,
      filter: true,
      resizable: true,
    cellRenderer: (data) => {
      //return data.value ? moment(data.value).format('MMM DD, YYYY') : null;
      return data.value ? moment(data.value).format('MMM DD, YYYY') : moment(data.data.created).format('MMM DD, YYYY');
    },
    valueGetter: function(data) {

      return data.data?.lastUpdated ? moment(data.data?.lastUpdated).format('MMM DD, YYYY') : moment(data.data?.created).format('MMM DD, YYYY');

},
    comparator:function dateComparator(date1, date2) {
      var date1Number = date1 && new Date(date1).getTime();
      var date2Number = date2 && new Date(date2).getTime();

      
      
    
      if (date1Number == null && date2Number == null) {
        return 0;
      }
    
      if (date1Number == null) {
        return -1;
      } else if (date2Number == null) {
        return 1;
      }
    
      return date1Number - date2Number;
    }
    },
    {
      headerName: "",
      minWidth: 60,
      maxWidth: 70,
      sortable: false,
        filter: false,
      cellRenderer: "buttonEditRenderer",
      cellRendererParams: {
        label: PlatformConstant.ACTION_UPDATE,
        onClick: this.onConfigButtonClick.bind(this),
      },
    },
    ],
    defaultColDef: {
      sortable: true,
      flex: 1,
      minWidth: 125,
      filter: true,
      resizable: true,
      editable: false,
      headerComponentParams: {
        template:
            '<div class="ag-cell-label-container" role="presentation">' +
            '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
            '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
            '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
            '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
            '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
            '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
            '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
            '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
            '  </div>' +
            '</div>',
    },
    }
  }

  showLoader: boolean;
  paginationPageSize = 10;
  // gridOptions: GridOptions = {}


  constructor(
    private adminService: AdminService,
    private apiService: ApiService,
    public dialog: MatDialog,
    private comp: AdminComponent
  ) { 
    this.showLoader = true;
    this.subscriptions.add(
    this.adminService.getSearchQuery().subscribe((data) => {
      this.searchQuery = data;
      
      if (this.searchQuery!==null) {
        // call api
        this.blockUI.start('Searching...');

        this.apiService.getMethod(this.searchApiUrl + this.searchQuery).subscribe((response) => {
          
          this.queryResult = response.ucsPlatform;
          this.rowData = this.queryResult;
          this.blockUI.stop();

          // let setFields = JSON.parse(localStorage.getItem("searchResultsGridFields"))
          // if(setFields?.show && setFields?.hide){
          //   this.gridColumnApi.setColumnsVisible(setFields.show, true);
          //   this.gridColumnApi.setColumnsVisible(setFields.hide, false);
          //   this.gridColumnApi.moveColumns(setFields.show, 0);
          // }
        })
      } else {
        // show empty results!
        
        this.rowData = [];
      }
      this.showLoader = false;
    })
    );

  }
  subscriptions: Subscription = new Subscription();
  seriesAll = [];

  ngOnInit(): void {
    this.subscriptions.add(this.adminService.getSeriesAll().subscribe((data) => {
      this.seriesAll = data;
      
      }));
  }

  onGridReady(params) {
    // this.gridOptions.api = params.api;
    // this.gridOptions.columnApi = params.columnApi;
    // this.gridOptions.api?.sizeColumnsToFit();
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi; 
    // let setFields = JSON.parse(localStorage.getItem("searchResultsGridFields"))
    // 
    // if(setFields?.show && setFields?.hide){
    //   this.gridColumnApi.setColumnsVisible(setFields.show, true);
    //   this.gridColumnApi.setColumnsVisible(setFields.hide, false);
    //   this.gridColumnApi.moveColumns(setFields.show, 0);

    // }
  }

  openShowFields(){
    
      //show/hide column
      
  
      
      var dd = this.gridColumnApi.getAllDisplayedColumns();
      dd.forEach(element => {
        
      });
      let allcols = this.gridColumnApi.getAllColumns();
      const dialogRef = this.dialog.open(ShowFieldsComponent, {      
        data: {
               fields : allcols,
              } ,
        disableClose: false,
      });   
  
      dialogRef.afterClosed().subscribe(result => {
         
        let show = [];
        let hide = [];
        result?.data?.fields.forEach(element => {
          if(element.visible == true){
            show.push(element.colId)
          }
          else{
            hide.push(element.colId)
          }
        });
        
        
  
        //save to localstorage
        const setFields = {show:show,hide:hide}
        localStorage.setItem("searchResultsGridFields", JSON.stringify(setFields));
  
        this.gridColumnApi.setColumnsVisible(show, true);
        this.gridColumnApi.setColumnsVisible(hide, false);
  
        this.gridColumnApi.moveColumns(show, 0);
        this.headerHeightSetter(null);
      });
    }

  gridApi: any;
  gridColumnApi:any;
  headerHeightSetter(event: any) {
    var padding = 20;
    var height = this.headerHeightGetter() + padding;
    this.gridApi.setHeaderHeight(height);
    this.gridApi.resetRowHeights();
  }

  headerHeightGetter() {
    var columnHeaderTexts = document.querySelectorAll('.ag-header-cell-text');
  
    var columnHeaderTextsArray = [];
  
    columnHeaderTexts.forEach(node => columnHeaderTextsArray.push(node));
  
    var clientHeights = columnHeaderTextsArray.map(
      headerText => headerText.clientHeight
    );
    var tallestHeaderTextHeight = Math.max(...clientHeights);
    return tallestHeaderTextHeight;
  }

  onConfigButtonClick(params:any){
    
    this.blockUI.start('Loading...');
    this.apiService.getMethod(this.searchApiUrl + this.searchQuery).subscribe((response) => {
      
      this.queryResult = response.ucsPlatform;
      this.rowData = this.queryResult;
      this.blockUI.stop();

    })  
  }



  ngOnDestroy() {
    
    this.adminService.setclearQuery("");

    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }
}
