<mat-card-title> 
    <div mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle 
    style="margin-bottom: 8px;"><h4 style="float: left;font-size: large;font-weight: 400;"> {{ data.header }} </h4> 
      <div style="display:flex;
      flex-direction: row-reverse;">
      <div class="flex-center margin_auto_t_b" >
        <a class="anchor-close" id="close" title="Close" (click)="onCancel()">
        <span class="icon-close icon-size-24 icon-close"></span>
        </a>
    </div>
    </div>
    </div>
</mat-card-title>

<form [formGroup]="createComponentForm" autocomplete="off">
        <mat-form-field>
           <div class="form-group base-margin-bottom" style="max-width: 800px; min-width: 500px;">
                <table class="table table-bordered">
                    <tr>
                        <td class="mandatory">
                            <mat-label>Display Name</mat-label>
                            <mat-error *ngIf="createComponentForm.get('displayName').errors" style="color:red"></mat-error>
                        </td>
                        <td>
                            <div class="form-group">
                                <div class="form-group__text">
                                    <input matInput [ngClass]="createComponentForm.get('displayName').errors ? 'text-danger' : 'text-normal'" formControlName="displayName" required>
                                </div>
                            </div>
                        </td>
                    </tr>

                    <tr>
                        <td></td> <td></td>
                    </tr>

                    <tr>
                        <td class="mandatory">
                            <mat-label>Error Message</mat-label>
                            <mat-error *ngIf="createComponentForm.get('errorMessage').errors" style="color:red"></mat-error>
                        </td>
                        <td>
                            <div class="form-group">
                                <div class="form-group__text">
                                    <textarea matInput [ngClass]="createComponentForm.get('errorMessage').errors ? 'text-danger' : 'text-normal'" formControlName="errorMessage" required></textarea>
                                </div>
                            </div>
                        </td>
                    </tr> 

                    <tr>
                        <td></td> <td></td>
                    </tr>

                    <tr>
                        <td class="mandatory">
                            <mat-label>Expandable</mat-label>
                            <mat-error *ngIf="createComponentForm.get('expandable').hasError('required')" style="color:red"></mat-error>
                        </td>
                        <td>
                            <div class="form-group form-group--inline">
                                <label class="radio" style="margin-right: 10px;">
                                <input type="radio" value='Yes' formControlName="expandable" >
                                <span class="radio__input" [ngClass]="createComponentForm.get('expandable').hasError('required') ? 'radio-danger' : 'no-danger'"></span>
                                <span class="radio__label">Yes</span>
                                </label>
                                <label class="radio">
                                <input type="radio" value='No' formControlName="expandable">
                                <span class="radio__input" [ngClass]="createComponentForm.get('expandable').hasError('required') ? 'radio-danger' : 'no-danger'"></span>
                                <span class="radio__label">No</span>
                                </label>
                            </div>
                        </td>
                    </tr> 

                    <tr>
                        <td></td> <td></td>
                    </tr>

                    <tr>
                        <td class="mandatory">
                            <mat-label>Component Type</mat-label>
                            <mat-error *ngIf="createComponentForm.get('componentType').errors" style="color:red"></mat-error>
                        </td>
                        <td>
                            <div class="form-group">
                                <div class="form-group__text">
                                    <input matInput [ngClass]="createComponentForm.get('componentType').errors ? 'text-danger' : 'text-normal'" formControlName="componentType" required>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td class="mandatory">
                            <mat-label>Select Attribute</mat-label>
                           <!-- <mat-error *ngIf="createComponentForm.get('componentType').hasError('required')" style="color:red"></mat-error>-->
                        </td>
                        <td>
                            <div class="form-group" style="width: 290px;"> 
                                <angular2-multiselect [data]="dropdownList" 
                                    [ngModelOptions]="{standalone: true}"
                                    [(ngModel)]="selectedItems" 
                                    [settings]="dropdownSettings" 
                                    (onSelect)="onItemSelect($event)" 
                                    (onDeSelect)="OnItemDeSelect($event)"
                                    [selectedItems]="selectedItems"
                                    (onSelectAll)="onSelectAll($event)"
                                    (onDeSelectAll)="onDeSelectAll($event)">
                                    <c-item>
                                        <ng-template let-item="item">
                                          <!-- <label style="color: #333;min-width: 150px;">{{item.name}}</label> -->
                                          <label [ngClass]="staticList.includes(item.key)?'default-select':''" [attr.disabled]="staticList.includes(item.key)?true:null" style="color: #333;min-width: 150px;">{{item.name}}</label>
                                        </ng-template>
                                      </c-item>
                                </angular2-multiselect>
 
                                              
                                
                              </div> 
                        </td>
                    </tr>

                </table>
            </div>
        </mat-form-field>
</form>
 
<mat-dialog-actions>
  <button mat-button class="btn btn--small" [disabled]="!createComponentForm.valid" (click)="onAddComponent()">Save</button>
  <button mat-button class="btn btn--small" cdkFocusInitial (click)="onCancel()">Cancel</button>
</mat-dialog-actions>
