import { Component, OnInit, Inject, HostListener, ChangeDetectorRef, ComponentFactoryResolver } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';
import { UrlConstant } from 'src/constants/url.constants';
import { GridOptions } from "ag-grid-community";
import { NewMeasurementComponent } from "../new-measurement/new-measurement.component";
import { PlatformConstant } from 'src/constants/platform.constants';
import * as moment from 'moment';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ToasterService } from "src/app/shared/components/toast/toast.service";
import { errorCodes } from "src/constants/errorCodes";
import { ComponentConstant } from 'src/constants/components.constants';


@Component({
  selector: 'app-measurement-details',
  templateUrl: './measurement-details.component.html',
  styleUrls: ['./measurement-details.component.css']
})
export class MeasurementDetailsComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<MeasurementDetailsComponent>,
    @Inject(MAT_DIALOG_DATA) public params: any,
    private apiService: ApiService,
    private cdr: ChangeDetectorRef,
    private toaster: ToasterService,
    public dialog: MatDialog,
  ) { }

  @BlockUI() blockUI: NgBlockUI;
  subscriptions: Subscription = new Subscription();
  showLoader: any = null;
  rowData: any;
  domLayout = 'autoHeight';
  gridOptions: GridOptions = {
    columnDefs: [
      { headerName: 'FW Revision', field: 'fwRevision' },
      { headerName: 'p State', field: 'pStates' },
      { headerName: 'Turbo', field: 'turbo' },
      { headerName: 'C State', field: 'cStates' },

      { headerName: 'Memory', field: 'memoryMode' },
      { headerName: 'HT', field: 'ht' },
      // { headerName: 'RAID' , field:'ioesName'},
      // { headerName: 'Voltage' , field:'inputVoltageName'},

      // { headerName: 'Power Supply' , field:'psusName'},
      // { headerName: 'PSU Count' , field:'numPsus'},
      { headerName: 'Disk', field: 'storageName' },
      { headerName: 'DiskCount', field: 'numDisks' },


      { headerName: 'Idle Power(W)', field: 'idleWatts' },
      { headerName: 'Max Power(W)', field: 'maxWatts' },
      { headerName: 'Notes', field: 'notes' },

      { headerName: 'Updated By', field: 'editor' },
      {
        headerName: 'Last Updated', field: 'lastUpdated',
        cellRenderer: (data) => {
          return data.value ? moment(data.value).format('MMM DD, YYYY') : moment(data.data.created).format('MMM DD, YYYY');
        }
      },

    ],
    defaultColDef: {
      sortable: true,
      flex: 1,
      minWidth: 120,
      filter: true,
      resizable: true,
      editable: false,
      headerComponentParams: {
        template:
          '<div class="ag-cell-label-container" role="presentation">' +
          '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
          '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
          '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
          '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
          '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
          '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
          '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
          '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
          '  </div>' +
          '</div>',
      },
    }
  }

  colDef_rack = [{ headerName: 'RAID', field: 'ioesName' },
  { headerName: 'Voltage', field: 'inputVoltageName' },

  { headerName: 'Power Supply', field: 'psusName' },
  { headerName: 'PSU Count', field: 'numPsus' },]
  platform_type;
  ngOnInit(): void {
    
    this.platform_type = this.params.platformData.type;
    if (this.platform_type == PlatformConstant.RACK) {
      this.gridOptions.columnDefs.splice(5, 0, this.colDef_rack[0], this.colDef_rack[1], this.colDef_rack[2], this.colDef_rack[3])
    }
    this.getMeasureData();
  }


  getMeasureData() {
    this.blockUI.start('Loading...');
    const params = {
      "cpuIds": [this.params?.selectedCpu.sequence],
      "dimIds": [this.params?.selectedDimm.sequence],
      "serverSeqNo": this.params?.selectedRow.sequence,
      "statusOfDims": "valid"
    }

    const url = UrlConstant.GET_MEASURE_DATA;
    this.subscriptions.add(
      this.apiService.postMethod(url, params).subscribe((data: any) => {
        
        // this.rowData = data;
        data.ServerCalcMeasurement.forEach(element => {
          if (element.inputVoltageSeq) {
            let voltage = data.raidDiskVol.filter((el) => {
              return element.inputVoltageSeq == el.sequence && el.type == "voltages";
            });
            element["inputVoltageName"] = voltage[0].name;
          }
          if (element.storageSeqNo) {
            let storage = data.raidDiskVol.filter((el) => {
              return element.storageSeqNo == el.sequence && el.type == "storages";
            });
            if (storage.length > 0) element["storageName"] = storage[0].displayName;
          }
          if (element.ioSeqNo) {
            let ioes = data.raidDiskVol.filter((el) => {
              return element.ioSeqNo == el.sequence && el.type == "ioes";
            });
            if (ioes.length > 0) element["ioesName"] = ioes[0].displayName;
          }
          if (element.psusSeqNo) {
            let psus = data.PowerSupplyVoltage.filter((el) => {
              return element.psusSeqNo == el.sequence;
            });
            if (psus.length > 0) element["psusName"] = psus[0].powerSupply.displayName;
          }
          if (element.noteSeqNo) {
            let note = data.NotesText.filter((el) => {
              return element.noteSeqNo == el.sequence;
            });
            element["notes"] = note[0]?.text;
          }
        });

        this.rowData = data.ServerCalcMeasurement;
        

        this.blockUI.stop();
      }, error => {
        this.blockUI.stop();
        let errorMsg = error?.error ? error.error?.error?.errorString : "Oops something went wrong!!";
        this.toaster.show('error', errorMsg, error ? error?.message : '', 5000);
      })
    );
  }

  openNewMeasurement() {
    
    

    if (
      ((this.platform_type == PlatformConstant.BLADE || this.platform_type == PlatformConstant.NODE) &&
        this.params?.platformData?.storages !== null && this.params?.platformData?.storages?.length > 0
      )
      ||
      ((this.platform_type != PlatformConstant.BLADE && this.platform_type != PlatformConstant.NODE)
        && this.params?.platformData?.storages !== null && this.params?.platformData?.storages?.length > 0
        && this.params?.platformData?.powerSupplies !== null && this.params?.platformData?.powerSupplies?.length > 0
      )

    ) {



      if ((this.platform_type != PlatformConstant.BLADE
        && this.platform_type != PlatformConstant.NODE)) {


        //check_voltages

        const url = UrlConstant.GET_COMP_DIS_NAMES + '/' + ComponentConstant.POWERSUPPLY;
        let params = {
          "type": ComponentConstant.POWERSUPPLY,
          "sequenceIds": this.params?.platformData?.powerSupplies
        }
        this.blockUI.start('checking...');

        this.subscriptions.add(
          this.apiService.postMethod(url, params).subscribe((data: any) => {
            this.blockUI.stop();

            let flag = false;
            for (let i = 0; i < data.length; i++) {
              if (data[i].powerSupplyInputs?.length > 0) {
                let curves = data[i].powerSupplyInputs?.filter(value => {
                  return (value.deleteFlag == false || value.deleteFlag == null)
                    && value.inputVoltage !== null
                });
                if (curves.length > 0) {
                  flag = true;
                  break;
                }
              }
            }

            if (flag) {
              this.openNewMeasurementModal();
            }
            else {
              this.toaster.show('error', errorCodes.OP_FAILED, errorCodes.VOLTAGES_NOT_ADDED, 5000);

            }
          }, error => {
            this.blockUI.stop();
            let errorMsg = error?.error ? error.error?.error?.errorString : "Oops something went wrong!!";
            this.toaster.show('error', errorMsg, error ? error?.message : '', 5000);
          }
          ));
        //
      }
      else {
        this.openNewMeasurementModal();

      }

    }
    else {
      if ((this.platform_type != PlatformConstant.BLADE
        && this.platform_type != PlatformConstant.NODE)) {

        let fields = [];
        if (this.params?.platformData?.storages == null ||
          this.params?.platformData?.storages?.length == 0) {
          fields.push("Storage");
        }
        if (this.params?.platformData?.powerSupplies == null &&
          this.params?.platformData?.powerSupplies?.length == 0
        ) {
          fields.push("Power Supply")
        }
        if (fields.length == 0) {
          this.openNewMeasurementModal();
        } else {
          let manFields = "";
          fields.forEach(f => {
            manFields = manFields + f + ", "
          })
          manFields = manFields.replace(/,\s*$/, "");
          this.toaster.show('error', errorCodes.OP_FAILED, errorCodes.CPU_DIMS_NOT_ADDED + manFields, 5000);
        }
      }
      else {
        this.toaster.show('error', errorCodes.OP_FAILED, errorCodes.CPU_DIMS_NOT_ADDED + "Storage", 5000);

      }

    }


  }
  openNewMeasurementModal() {
    const dialogRef = this.dialog.open(NewMeasurementComponent, {
      data: {
        params: this.params
      },
      disableClose: true,
      panelClass: 'width-dialog-100'
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result.data?.action == PlatformConstant.ACTION_REFRESH) {
        this.getMeasureData();
        this.dataRefreshed = true;
      }
    })
  }
  dataRefreshed: boolean = false;
  closeModal() {
    this.dialogRef.close({
      data: {
        action: this.dataRefreshed ? PlatformConstant.ACTION_REFRESH : PlatformConstant.ACTION_CANCEL
      }
    });
  }

  gridApi: any;
  gridColumnApi: any;
  headerHeightSetter(event: any) {
    var padding = 20;
    var height = this.headerHeightGetter() + padding;
    this.gridApi.setHeaderHeight(height);
    this.gridApi.resetRowHeights();
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    //params.api.sizeColumnsToFit();
  }
  headerHeightGetter() {
    var columnHeaderTexts = document.querySelectorAll('.ag-header-cell-text');

    var columnHeaderTextsArray = [];

    columnHeaderTexts.forEach(node => columnHeaderTextsArray.push(node));

    var clientHeights = columnHeaderTextsArray.map(
      headerText => headerText.clientHeight
    );
    var tallestHeaderTextHeight = Math.max(...clientHeights);
    return tallestHeaderTextHeight;
  }
}
