import { Component, OnInit } from '@angular/core';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { MatDialogRef } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { AuthClientService } from 'src/app/services/auth-client.service';
@Component({
  selector: 'app-user-login-modal',
  templateUrl: './user-login-modal.component.html',
  styleUrls: ['./user-login-modal.component.css'],
})
export class UserLoginModalComponent implements OnInit {
  loginUrl = '';
  userData: any;
  isSmallScreen = false;
  ngOnInit() {
    this.breakpointObserver
      .observe(['(max-width: 770px)'])
      .subscribe((state: BreakpointState) => {
        this.isSmallScreen = state.matches;
      });
    this.userData = JSON.parse(localStorage.getItem('userData'));
    if (this.userData) {
      /** auto agree to license if logged-in */
      localStorage.setItem('UCS.EulaAcceptanceStatus', 'true');
      this.navigateProjectsList();
    }
    this.authClientService.getCurrentUser().subscribe(userData => {
      if (userData && !this.userData) {
        this.userData = userData;
        localStorage.setItem('UCS.EulaAcceptanceStatus', 'true');
        this.cancel();
        this.navigateProjectsList();
      }
    });
  }

  constructor(
    public dialogRef: MatDialogRef<UserLoginModalComponent>,
    public breakpointObserver: BreakpointObserver,
    private router: Router,
    private authClientService: AuthClientService,
  ) {
    if (environment.production) {
      this.loginUrl =
        'https://www.cisco.com/c/login/index.html?referer=' +
        window.location.href +
        '?';
    } else {
      this.loginUrl =
        'https://www-stage.cisco.com/c/login/index.html?referer=' +
        window.location.href +
        '?';
    }
  }

  cancel() {
    this.dialogRef.close();
  }
  login() {
    this.authClientService.loginWithRedirect();
  }

  navigateProjectsList() {
    if (this.router.url.includes('admin/')) {
      if (this.router.url.includes('ucs-resizer')) {
        this.router.navigate(["/admin/ucs-resizer/projectList"]);
      } else this.router.navigate(["/admin/projectList"]);
    } else {
      if (this.router.url.includes('ucs-resizer')) {
        this.router.navigate(["/ucs-resizer/projectList"]);
      } else this.router.navigate(["/projectList"]);
    }
  }

}
