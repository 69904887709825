import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AdminService {

  constructor() { }

  private dataIODevice$ = new BehaviorSubject(null);
  getData() {
    return this.dataIODevice$.asObservable();
  }
  setData(currentData: any) {
    this.dataIODevice$.next(currentData)
  }

  private dataStorageDevice$ = new BehaviorSubject(null);
  getStorageData() {
    return this.dataStorageDevice$.asObservable();
  }
  setStorageData(currentData: any) {
    this.dataStorageDevice$.next(currentData)
  }

  private dataPowerSupply$ = new BehaviorSubject(null);
  getPowerSupplyData() {
    return this.dataPowerSupply$.asObservable();
  }
  setPowerSupplyData(currentData: any) {
    this.dataPowerSupply$.next(currentData)
  }

  private dataProcessorFamily$ = new BehaviorSubject(null);
  getProcessorFamilyData() {
    return this.dataProcessorFamily$.asObservable();
  }
  setProcessorFamilyData(currentData: any) {
    this.dataProcessorFamily$.next(currentData)
  }

  private dataProcessor$ = new BehaviorSubject(null);
  getProcessorData() {
    return this.dataProcessor$.asObservable();
  }
  setProcessorData(currentData: any) {
    this.dataProcessor$.next(currentData)
  }

  private dataDimmFamily$ = new BehaviorSubject(null);
  getDimmFamilyData() {
    return this.dataDimmFamily$.asObservable();
  }
  setDimmFamilyData(currentData: any) {
    this.dataDimmFamily$.next(currentData)
  }

  private dataDimm$ = new BehaviorSubject(null);
  getDimmData() {
    return this.dataDimm$.asObservable();
  }
  setDimmData(currentData: any) {
    this.dataDimm$.next(currentData)
  }

  private dataIOnExpansion$ = new BehaviorSubject(null);
  getIOnExpansionData() {
    return this.dataIOnExpansion$.asObservable();
  }
  setIOnExpansionData(currentData: any) {
    this.dataIOnExpansion$.next(currentData)
  }

  //platform
  private dataPlatform$ = new BehaviorSubject(null);
  getPlatformData() {
    return this.dataPlatform$.asObservable();
  }
  setPlatformData(currentData: any) {
    this.dataPlatform$.next(currentData)
  }

  //voltageList - By sjohny
  private dataVoltage$ = new BehaviorSubject(null);
  getVoltageList() {
    return this.dataVoltage$.asObservable();
  }
  setVoltageList(currentData: any) {
    this.dataVoltage$.next(currentData)
  }

  //set Grid data
  private gridData$ = new BehaviorSubject([]);
  getGridData() {
    return this.gridData$.asObservable();
  }
  setGridData(currentData: any) {
    this.gridData$.next(currentData)
  }

  //set header data
  private headerData$ = new BehaviorSubject([]);
  getHeaderData() {
    return this.headerData$.asObservable();
  }
  setHeaderData(currentData: any) {
    this.headerData$.next(currentData)
  }

  //set series data
  private seriesList$ = new BehaviorSubject(null);
  getSeriesAll() {
    return this.seriesList$.asObservable();
  }
  setSeriesAll(currentData: any) {
    this.seriesList$.next(currentData)
  }

  //set selcted platform name
  private platformName$ = new BehaviorSubject(null);
  getPlatformName() {
    return this.platformName$.asObservable();
  }
  setPlatformName(currentData: any) {
    this.platformName$.next(currentData)
  }

  // set searchQuery
  private searchQuery$ = new BehaviorSubject(null);
  getSearchQuery() {
    return this.searchQuery$.asObservable();
  }
  setSearchQuery(currentData: any) {
    this.searchQuery$.next(currentData)
  }


  private filteredComp$ = new BehaviorSubject(null);
  getFilteredComp() {
    return this.filteredComp$.asObservable();
  }
  setFilteredComp(currentData: any) {
    this.filteredComp$.next(currentData)
  }

  //for clear searchquery field
  private clearQuery$ = new BehaviorSubject(null);
  getclearQuery() {
    return this.clearQuery$.asObservable();
  }
  setclearQuery(currentData: any) {
    this.clearQuery$.next(currentData)
  }
  private dynamicComponentAttributes$ = new BehaviorSubject(null);
  getDynamicComponentAttributes() {
    return this.dynamicComponentAttributes$.asObservable();
  }
  setDynamicComponentAttributes(currentData: any) {
    this.dynamicComponentAttributes$.next(currentData);
  }
  private dynamicPlatformAttributes$ = new BehaviorSubject(null);
  getDynamicPlatformAttributes() {
    return this.dynamicPlatformAttributes$.asObservable();
  }
  setDynamicPlatformAttributes(currentData: any) {
    this.dynamicPlatformAttributes$.next(currentData);
  }
}
