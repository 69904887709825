<!-- <div class="app">
  <div class="container">
    <div class="row">
      <app-header></app-header>
    </div>

    <div class="row main-content">
      <div class="col-md-12" *ngIf="toastFlag">
        <app-toast-container></app-toast-container>
      </div>
      <div class="col-md-12">
      <router-outlet></router-outlet>
    </div>
    </div>
    <div class="row footer">
      <app-footer style="width: 100%;"></app-footer>
    </div>
  </div>
</div> -->
<block-ui>

<div class="app" >
      <app-header></app-header>

    <div class="main-content">
      <div class="col-md-12" *ngIf="toastFlag">
        <app-toast-container></app-toast-container>
      </div>
      <div class="col-md-12">
      <router-outlet></router-outlet>
    </div>
    </div>

      <app-footer></app-footer>

</div>
</block-ui>
