import { ChangeDetectorRef, OnDestroy } from "@angular/core";
import { AfterViewInit, Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { Subscription } from "rxjs";
import { ApiService } from "src/app/services/api.service";
import { ToasterService } from "src/app/shared/components/toast/toast.service";
import { UrlConstant } from "src/constants/url.constants";
import { Location } from "@angular/common";
import { ProjectService } from "src/app/services/project.service";
import { errorCodes } from 'src/constants/errorCodes'
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { SharedService } from "src/app/shared/shared.service";

@Component({
  selector: "app-project-configuration",
  templateUrl: "./project-configuration.component.html",
  styleUrls: ["./project-configuration.component.css"],
})
export class ProjectConfigurationComponent implements OnInit, AfterViewInit, OnDestroy {
  selectedTabId = 1;
  curDesc: string = '';
  prevSelectedTab = 1;
  showLoader1: boolean;
  showLoader2: boolean;
  showLoader3: boolean;
  showLoader4: boolean;
  subscriptions: Subscription = new Subscription();
  errorMsg: any;
  secondaryClasses: any = [];
  networkingClasses: any = [];
  secondaryClassesCpy: any = [];
  networkingClassesCpy: any = [];
  private fragment: string;
  basePid: any;
  bladeList: any = [];
  bladeListCpy: any = [];
  sequenceId: any;
  showAdditional: boolean = false;
  searchQuery: string = '';
  searchItems: any[] = [];
  searchResults: any[] = [];
  available: boolean = true;
  searchSeries: any = {};
  currentTemplates = { templates: [] };
  @BlockUI() blockUI: NgBlockUI;

  constructor(
    private apiService: ApiService,
    private translate: TranslateService,
    private toaster: ToasterService,
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    private _location: Location,
    private projectService: ProjectService,
    private router: Router,
    private sharedService: SharedService
  ) { }

  ngOnInit(): void {
    this.getComputeDetails();
    this.getNetworkdetails();
    this.route.fragment.subscribe(fragment => { this.fragment = fragment; });
    this.subscriptions.add(this.projectService.getChassisPid().subscribe(data => {
      this.projectService.setResetSeriesId(-1);
      this.projectService.setSeriesData({});
      if (data[0] && data.length > 0) {
        this.basePid = data[0];
        this.sequenceId = data[3];
        this.onTabClick(1);
        this.cdr.detectChanges();
        try {
          if (this.basePid && this.sequenceId && data[5] == 'blade' && data[2] && data[7]) {
            this.showAdditional = true;
            this.getBladeList(this.sequenceId, data[4], data[6]);
          }
          if (this.basePid && this.sequenceId && data[5] == 'node' && data[2] && data[7]) {
            this.showAdditional = true;
            this.getNodeList(this.sequenceId, data[4], data[6]);
          }
        } catch (error) {
          this.errorMsg = error?.error ? error.error?.error?.errorString : errorCodes.GENERIC_ERROR_MESSAGE;
          this.showAdditional = false;
        }
      } else {
        this.bladeList = null;
        this.showAdditional = false;
      }
    }));
    this.subscriptions.add(this.projectService.getSeriesData().subscribe((data) => {
      this.searchSeries = data;
      if (Object.keys(this.searchSeries).length !== 0) this.curDesc = this.searchSeries.seriesName;
    }));
    this.subscriptions.add(this.projectService.getSecondaryClasses().subscribe((data) => {
      if (data) {
        this.secondaryClasses = data;
        this.secondaryClassesCpy = JSON.parse(JSON.stringify(this.secondaryClasses));
        this.projectService.setSecondaryClasses(null);
      }
    }))
  }
  refreshData() {
    this.getComputeDetails();
    this.getNetworkdetails();
    this.projectService.setSeriesData({});
    this.projectService.setResetSeriesId(-1);
  }
  ngAfterViewInit(): void {
    try {
      document.querySelector('#' + this.fragment).scrollIntoView(true);
      if (this.fragment == 'Compute') {
        this.onTabClick(1);
        this.cdr.detectChanges();
      }
      if (this.fragment == 'Storage') {
        this.onTabClick(2);
        this.cdr.detectChanges();
      }
      if (this.fragment == 'Networking') {
        this.onTabClick(3);
        this.cdr.detectChanges();
      }
      if (this.fragment == 'blade') {
        this.onTabClick(1);
        this.cdr.detectChanges();
        try {
          if (this.basePid) {
            // this.getBladeList(this.basePid);
          }
        } catch (error) {
          this.errorMsg = error?.error ? error.error?.error?.errorString : errorCodes.GENERIC_ERROR_MESSAGE;
        }

      }
    } catch (e) { }
  }
  doSearch() {
    this.searchResults = [];
    this.searchItems = [];
    let series = 'Compute';
    if (!this.isObjectEmpty(this.searchSeries)) {
      let isFound = false;
      for (let i = 0; i < this.secondaryClasses.length; i++) {
        if (this.secondaryClasses[i].seriesName == this.searchSeries.seriesName) {
          this.searchItems = this.secondaryClasses[i].seriesValues;
          series = this.secondaryClasses[i].seriesName;
          isFound = true;
          break;
        }
      }
      if (!isFound) {
        for (let i = 0; i < this.networkingClasses.length; i++) {
          if (this.networkingClasses[i].seriesName == this.searchSeries.seriesName) {
            this.searchItems = this.networkingClasses[i].seriesValues;
            series = this.networkingClasses[i].seriesName;
            isFound = true;
            break;
          }
        }
      }
      if (!isFound && this.bladeList !== null) {
        for (let i = 0; i < this.bladeList.length; i++) {
          if (this.bladeList[i].seriesName == this.searchSeries.seriesName) {
            this.searchItems = this.bladeList[i].seriesValues;
            isFound = true;
            break;
          }
        }
      }
    }
    this.sharedService.setSearchQuery({
      query: this.searchQuery,
      series: series
    });
    for (let i = 0; i < this.searchItems.length; i++) {
      let curItem = this.searchItems[i];
      if (curItem?.name.toLowerCase().includes(this.searchQuery.trim().toLowerCase())) this.searchResults.push(curItem);
    }
    let found = false;
    for (let i = 0; i < this.secondaryClasses.length; i++) {
      if (this.secondaryClasses[i].seriesName == this.searchSeries.seriesName) {
        this.secondaryClassesCpy[i].seriesValues = this.searchResults;
        found = true;
        break;
      }
    }
    if (!found) {
      for (let i = 0; i < this.networkingClasses.length; i++) {
        if (this.networkingClasses[i].seriesName == this.searchSeries.seriesName) {
          this.networkingClassesCpy[i].seriesValues = this.searchResults;
          found = true;
          break;
        }
      }
    }
    if (!found && this.bladeList !== null) {
      for (let i = 0; i < this.bladeList.length; i++) {
        if (this.bladeList[i].seriesName == this.searchSeries.seriesName) {
          this.bladeListCpy[i].seriesValues = this.searchResults;
          found = true;
          break;
        }
      }
    }
  }
  goBack() {
    this.selectedTabId = this.prevSelectedTab;
  }
  getNodeList(basePid: any, voltage: number, slotsRemaining: number) {
    this.sharedService.setSearchQuery(null);
    let url = "";
    if (this.router.url.includes('admin/')) {
      url = UrlConstant.NODE_LIST_ADMIN + `?id=${basePid}`;
    }
    else {
      url = UrlConstant.NODE_LIST + `?id=${basePid}`;//'UCSX-9508';
    }

    this.showLoader1 = true;
    this.subscriptions.add(
      this.apiService.getMethod(url).subscribe((result) => {
        this.bladeList = result.secondaryClasses;
        this.bladeList.forEach((series) => {
          series.displayName = this.translate.instant(series.seriesName);
        });
        if (this.bladeList.length > 0) {
          this.bladeList[0].seriesValues.forEach((seriesValue) => {
            let found = seriesValue.attributes.find(a => a.label == 'numChassisSlots');
            let slotsConsumed = found ? found.value : null;
            seriesValue.isEnabled = slotsConsumed ? slotsRemaining >= slotsConsumed : true;
          })
        }
        this.bladeListCpy = JSON.parse(JSON.stringify(this.bladeList));
        this.currentTemplates = (localStorage[`template${this.bladeListCpy[0].type}`]) ? JSON.parse(localStorage.getItem(`template${this.bladeListCpy[0].type}`)) : { templates: [] };
        this.projectService.setSeriesData(this.bladeList[0]);
        this.showLoader1 = false;
      },
        (error) => {
          console.error(error);
          this.errorMsg = error?.error ? error.error?.error?.errorString : errorCodes.GENERIC_ERROR_MESSAGE;
          let message = error ? error?.message : "";
          message = message.includes("Http failure response") ? "" : message;
          this.toaster.show("error", this.errorMsg, error ? message : "");
          this.showLoader1 = false;
          this.showAdditional = false;

          this.subscriptions.add(this.projectService.setCurrentChassis([null]));
          this.navigateToProjectDetails();

        }))
  }
  navigateToProjectDetails(fragment?: string) {
    if (this.router.url.includes('admin/')) {
      if (this.router.url.includes('ucs-resizer')) {
        this.router.navigate(["/admin/ucs-resizer/projectDetails"], { fragment: fragment });
      } else this.router.navigate(["/admin/projectDetails"], { fragment: fragment });
    } else {
      if (this.router.url.includes('ucs-resizer')) {
        this.router.navigate(["/ucs-resizer/projectDetails"], { fragment: fragment });
      } else this.router.navigate(["/projectDetails"], { fragment: fragment });
    }
  }
  isObjectEmpty(o) {
    return Object.keys(o).length == 0;
  }
  getBladeList(basePid: any, voltage: number, slotsRemaining: number) {

    this.sharedService.setSearchQuery(null);
    // const url = UrlConstant.BLADE_LIST + `?id=${basePid}`;//'UCSB-5108-AC2';
    let url = "";
    if (this.router.url.includes('admin/')) {
      url = UrlConstant.BLADE_LIST_ADMIN + `?id=${basePid}`;
    }
    else {
      url = UrlConstant.BLADE_LIST + `?id=${basePid}`;//'UCSB-5108-AC2';
    }


    this.showLoader2 = true;
    this.subscriptions.add(
      this.apiService.getMethod(url).subscribe((result) => {
        this.bladeList = result.secondaryClasses;
        this.bladeList.forEach((series) => {
          series.displayName = this.translate.instant(series.seriesName);//series.seriesName
        });
        if (this.bladeList.length > 0) {
          this.bladeList[0].seriesValues.forEach((seriesValue) => {
            let found = seriesValue.attributes.find(a => a.label == 'numChassisSlots');
            let slotsConsumed = found ? found.value : null;
            seriesValue.isEnabled = slotsConsumed ? slotsRemaining >= slotsConsumed : true;
          })
        }
        this.bladeListCpy = JSON.parse(JSON.stringify(this.bladeList));
        this.currentTemplates = (localStorage[`template${this.bladeListCpy[0].type}`]) ? JSON.parse(localStorage.getItem(`template${this.bladeListCpy[0].type}`)) : { templates: [] };
        this.projectService.setSeriesData(this.bladeList[0]);
        this.showLoader2 = false;
      },
        (error) => {
          console.error(error);
          this.errorMsg = error?.error ? error.error?.error?.errorString : errorCodes.GENERIC_ERROR_MESSAGE;
          let message = error ? error?.message : "";
          message = message.includes("Http failure response") ? "" : message;
          this.toaster.show("error", this.errorMsg, error ? message : "");
          this.showLoader2 = false;
          this.showAdditional = false;
          this.subscriptions.add(this.projectService.setCurrentChassis([null]))
          this.navigateToProjectDetails();

        }))
  }

  getComputeDetails() {
    // const url = UrlConstant.COMPUTE;
    let url = "";
    if (this.router.url.includes('admin/')) {
      url = UrlConstant.COMPUTE_ADMIN;
    }
    else {
      url = UrlConstant.COMPUTE;
    }


    this.showLoader3 = true;

    this.subscriptions.add(
      this.apiService.getMethod(url).subscribe((result) => {
        this.secondaryClasses = result.secondaryClasses.sort((a, b) => (a.seriesName > b.seriesName) ? 1 : -1);
        this.secondaryClasses.forEach((series) => {
          series.displayName = this.translate.instant(series.seriesName);
        });
        this.secondaryClassesCpy = JSON.parse(JSON.stringify(this.secondaryClasses));
        this.showLoader3 = false;
      },
        (error) => {
          this.errorMsg = error?.error ? error.error?.error?.errorString : errorCodes.GENERIC_ERROR_MESSAGE;
          this.toaster.show("error", this.errorMsg, error ? error?.message : "");
          this.showLoader3 = false;
        }
      )
    );
  }

  getNetworkdetails() {

    // const url = UrlConstant.NETWORKING;
    let url = "";
    if (this.router.url.includes('admin/')) {
      url = UrlConstant.NETWORKING_ADMIN;
    }
    else {
      url = UrlConstant.NETWORKING;
    }

    this.showLoader4 = true;

    this.subscriptions.add(
      this.apiService.getMethod(url).subscribe(
        (result) => {
          this.networkingClasses = result.secondaryClasses.sort((a, b) => (a.seriesName > b.seriesName) ? 1 : -1);
          this.networkingClasses.forEach((series) => {
            series.displayName = this.translate.instant(series.seriesName);
          });
          this.networkingClassesCpy = JSON.parse(JSON.stringify(this.networkingClasses));
          this.showLoader4 = false;
        },
        (error) => {
          this.errorMsg = error?.error ? error.error?.error?.errorString : errorCodes.GENERIC_ERROR_MESSAGE;
          this.toaster.show("error", this.errorMsg, error ? error?.message : "");
          this.showLoader4 = false;
        }
      )
    );
  }
  onCancel() {
    this.subscriptions.add(this.projectService.setCurrentChassis([null]));
    this.projectService.setSeriesData({});
    this.projectService.setResetSeriesId(-1);
    if (this.showAdditional == true) this.showAdditional = false;
  }
  resetClassData() {
    this.secondaryClassesCpy = JSON.parse(JSON.stringify(this.secondaryClasses));
    this.networkingClassesCpy = JSON.parse(JSON.stringify(this.networkingClasses));
  }
  onTabClick(tabId: number) {
    if (tabId !== this.selectedTabId) {
      this.selectedTabId = tabId;
      this.prevSelectedTab = tabId;
      this.projectService.setSeriesData({});
      this.projectService.setResetSeriesId(-1);
      this.sharedService.setSearchQuery(null);
      this.resetClassData();
      this.fragment = '';
      const pathWithoutHash = this._location.path(false);
      this._location.replaceState(pathWithoutHash);
    }
  }
  onDiscontinuedClick() {
    this.available = false;
    this.refreshCurrentTemplates();
  }
  onAvailableClick() {
    this.available = true;
    this.refreshCurrentTemplates();
  }
  refreshCurrentTemplates() {
    this.currentTemplates = (localStorage[`template${this.bladeListCpy[0].type}`]) ? JSON.parse(localStorage.getItem(`template${this.bladeListCpy[0].type}`)) : { templates: [] };
  }
  doesTemplatesExistForAvailableStatus(): boolean {
    return this.currentTemplates.templates.filter((value) => {
      let avb = value.discont == 0 ? true : false;
      return avb == this.available;
    }).length > 0;
  }
  ngOnDestroy() {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }
}
