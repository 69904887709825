<form  [formGroup]="validations" autocomplete="off">
  <section fxLayout="row wrap" fxLayoutAlign="center center" >
    <mat-card fxFlex="500px" fxFlex.xs="100%">
      <div mat-dialog-content> 
        <mat-card-title style="font-size: large;" > Processor Family</mat-card-title>
          <mat-form-field>      
            <div class="panel panel--compressed panel--raised" style="max-width:800px;min-width: 500px;">
              <table class="table table-bordered">
                <tbody>
                  <tr>
                    <td class="mandatory"> 
                      <mat-label>Name</mat-label>
                      <mat-error  *ngIf="validations.get('displayName').hasError('required')" style="color:red"></mat-error>
                    </td>
                    <td> 
                      <div class="form-group" > 
                        <div class="form-group__text">
                          <input #input matInput [ngClass]=" validations.get('displayName').hasError('required') ? 'text-danger': 'text-normal'" [(ngModel)]="data.displayName"  cdkFocusInitial formControlName="displayName"  required>
                        </div>
                      </div>   
                    </td>              
                  </tr>          
                </tbody>
              </table>
            </div>
          </mat-form-field>
      </div>
        
      <div mat-dialog-actions>
          <table class="table table-bordered">
            <tbody>
              <tr>
                <td>
                  <mat-card-actions> 
                    <button mat-button  (click)="onSave()"   class="btn btn--small " 
                    style="height: 24px;margin: 5px 20px 0 0;"  [disabled]="!validations.valid">Submit</button>
                  </mat-card-actions>
                <!--  <mat-card-actions>
                    <button mat-button  [mat-dialog-close]="data" cdkFocusInitial class="btn btn--small " style="height: 24px;margin: 5px 20px 0 0;">Submit</button>
                  </mat-card-actions>         -->   
                  <button mat-button (click)="onNoClick()"  class="btn btn--small " style="height: 24px;margin: 5px 20px 0 0;">Cancel</button>
                </td>
              </tr>
            </tbody>
          </table>    
      </div>
        
    </mat-card>
  </section>
</form>
