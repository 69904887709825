import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ProjectService } from 'src/app/services/project.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  constructor(
    private projectService: ProjectService,
  ) { }
  subscriptions: Subscription = new Subscription();
  isUserLoggedIn: boolean = false;
  userData: any;
  ngOnInit(): void {
    this.userData = JSON.parse(localStorage.getItem('userData'));
    this.isUserLoggedIn = this.userData ? true : false;
  }
  onLicenseClick() {
    this.subscriptions.add(this.projectService.setCurrentChassis([null]));
    this.subscriptions.add(this.projectService.setProjectData(null));
    this.subscriptions.add(this.projectService.setPowerCost(''));
    this.subscriptions.add(this.projectService.setEmissionFactor(''));
    this.subscriptions.add(this.projectService.setCountry(''));
    this.subscriptions.add(this.projectService.setCurrentProjectName(null));
    this.subscriptions.add(this.projectService.setIsAddedOrEdited(false));
    this.projectService.setServerEditStatus(false);
    localStorage.setItem("setServerEditStatus", "false");
    localStorage.setItem("setCurrentServer", JSON.stringify(null));

    sessionStorage.setItem("setServerEditStatus", "false");
    sessionStorage.setItem("setCurrentServer", JSON.stringify(null));
  }

}
