import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { Toast } from '../toast.interface';
import { TranslateService } from "@ngx-translate/core";


@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.css']
})
export class ToastComponent implements OnInit {
  @Input() toast: Toast;
  @Input() i: number;

  @Output() remove = new EventEmitter<number>();
 
  
  
  constructor(private translate: TranslateService) { }
  
  ngOnInit(): void {
  }

  
}
