import { Component, ChangeDetectorRef,OnInit ,Input, Output, EventEmitter, Renderer2 ,ViewChild,ElementRef} from '@angular/core';
import { Subscription,Subject, } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';
import { UrlConstant } from 'src/constants/url.constants';
import { HttpClient, HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { distinctUntilChanged, debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-search-box',
  templateUrl: './search-box.component.html',
  styleUrls: ['./search-box.component.css']
})
export class SearchBoxComponent implements OnInit {

  constructor(
    private apiService: ApiService,
    private http: HttpClient,
    private renderer: Renderer2,
    private cdr: ChangeDetectorRef
    ) { 
      this.listenerFn = this.renderer.listen('window', 'click',(e:Event)=>{
       if(e.target !== this.toggleButton.nativeElement 
           && e.target!==this.menu?.nativeElement){
           this.show=false;
           this.searchResultsBox = false;
       }
       
   });
    }
    listenerFn: () => void;

    subscriptions: Subscription = new Subscription();
    @Output() pidSelectionEvent = new EventEmitter<string>();
    @ViewChild('menu') menu: ElementRef;
    @ViewChild('toggleButton') toggleButton: ElementRef;
    @Input() pid :string; 
    @Input() flag:boolean;
    @Input() type:string;


  ngOnInit(): void {
    
    
    this.selectedPid = this.pid?JSON.parse(JSON.stringify(this.pid)):null;
    this.preValueSelected = this.pid?JSON.parse(JSON.stringify(this.pid)):null;
    this.getClientOAuthToken();
  }
  searchResultsBox:boolean = false;
  show=false;
  loading = false;
  searchValue:any;
  pids:any = [];
  onSearchValueChange(value: string) {
    
    // value = value.trim();
    // if (value.length >= 3) {
    //   this.searchResultsBox = true;
    //   
    //   const url = UrlConstant.GET_PID + '/' + value +'/' ;
    //   this.loading = true;
    //   this.pids = [];
    // this.subscriptions.add(
    //   this.apiService.getPIDSuggestions(url,this.token.access_token)
    //   .pipe(
    //     debounceTime(1500), 
    //     distinctUntilChanged()
    //  )
    //   .subscribe((data: any) => {
    //     
    //     this.pids = JSON.parse(data.s)
    //     this.show=true;
    //     this.loading = false;

    //   }));
      

    // }
    // else{
    //   this.show=false;
    //   this.searchResultsBox = false;

    //   if(value.length==0){
    //     
    //     this.selectedPid = null;
    //     this.pidSelectionEvent.emit(this.selectedPid);

    //   }
    // }
    // this.cdr.detectChanges();  

  }

  onBlur(e){
    //??
    // setTimeout(()=>{     
    //       
    //   if(this.selectedPid == ""){   
    //          
    //    this.selectedPid = this.preValueSelected;}
    // }, 500);

    setTimeout(()=>{
      

    // this.selectedPid = this.preValueSelected;
    this.pidSelectionEvent.emit(this.selectedPid.trim());

    this.cdr.detectChanges();  
    }, 200);

  }

  onkeyup(e){
   
    setTimeout(()=>{
      
      this.pidSelectionEvent.emit(this.selectedPid?.trim());

    this.show=false;
    this.searchResultsBox = false;

    this.cdr.detectChanges();  
    }, 200);

  }

  selectedPid:any = null;
  preValueSelected:any =null;;
  selectedPID(e){
    
    this.selectedPid = e.PID;
    this.preValueSelected = this.selectedPid?JSON.parse(JSON.stringify(this.selectedPid)):null;
    this.show=false;
    this.pidSelectionEvent.emit(this.selectedPid);
  }
  token:any;
  clientCredOAuthURL = "https://cloudsso.cisco.com/as/token.oauth2?grant_type=client_credentials&client_id=ucs-ng&client_secret=ucs-ng"
  getClientOAuthToken() {
    this.apiService.postMethodForAuth(this.clientCredOAuthURL).subscribe((data: any) => {
      
      this.token = data;
    }) 
  }

  ngOnDestroy() {
    if (this.listenerFn) {
      this.listenerFn();
    }
    if(this.subscriptions){
      this.subscriptions.unsubscribe();
    }
  }
}
