<div class="flex flex-between"  cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle
 style="margin-left: -25px;margin-bottom: 0px;">
    <button mat-button (click)="close()"  class="btn btn--link focus" >Back</button>
    <div style="    display: flex;
    flex-direction: row-reverse;">
     <div class="flex-center margin_auto_t_b" >
        <a class="anchor-close" id="close" title="Close" (click)="close()">
        <span class="icon-close icon-size-24 icon-close"></span>
        </a>
    </div>
</div>
</div>

<div style="min-height: 400px;max-height: 80vh;overflow: auto;">
    
    <div>
        <h4>CPU/Memory Compatability</h4>
    </div>
    <div>
        <div class="col-md-6 row" *ngIf="platformData?.params.vendor">
            <div class="col-2" >
                Vendor
            </div>
            <div class="col-md-8 col-lg-8">
                :&nbsp;{{platformData?.params.vendor}}
            </div>
         </div>
         <div class="col-md-6 row" *ngIf="platformData?.params.name">
            <div class="col-2" >
                Model
            </div>
            <div class="col-md-8 col-lg-8">
                :&nbsp;{{platformData?.params.name}}
            </div>
         </div>
         <div class="col-md-6 row" *ngIf="platformData?.params.gen">
            <div class="col-2" >
                Generation
            </div>
            <div class="col-md-8 col-lg-8">
                :&nbsp;{{platformData?.params.gen}}
            </div>
         </div>
    </div>

      <br>
       <div class="row"><div class="col-md-1 text-center-custom"><span>Processor</span></div>
            <div class="col-md-4">

                <div class="form-group"> 
                    <ngx-select-dropdown 
                            (change)="changeType($event)" 
                            [multiple]="false" [(ngModel)]="selectedCpu"
                             [config]="config" [options]="cpulist">
                            </ngx-select-dropdown>
                  </div>
            </div>
            
        </div> 
        <div style="margin-top: 7px;margin-left: 20px;display: flex;" *ngIf="showLoader == 'show'" class="loading-dots loading-dots--dark">
            <span></span>
            <span></span>
            <span></span>
          </div>
        <div class="row"  *ngIf="(showLoader == 'hide')">
            <div class="col-md-12">
                <div class="responsive-table" style="padding: 5px;">
                                <table class="table table--lined" aria-label="Lined table example">
                                    <thead>
                                        <tr>
                                            <th style="width: 75%;">Name</th>
                                            <th>Invalid</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let dims of dimsIncompatable; let i = index">
                                            <td>{{dims.displayName}}</td>
                                            <td><div class="form-group">
                                                <label class="checkbox">
                                                    <input type="checkbox" [checked]="dims.invalidCheck" (click)="checkedHandler($event,dims)">
                                                    <span class="checkbox__input"></span>
                                                </label>
                                            </div></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            


            </div>
        </div>

    </div>  
    <mat-dialog-actions>
        <button mat-button [disabled]="!formChanged"   class="btn btn--small "  style="height: 24px;margin: 5px 20px 0 0;" (click)="submit('closeModal')">Save & Close</button>
        <button mat-button [disabled]="!formChanged" (click)="submit('save')"   class="btn btn--small " style="height: 24px;margin: 5px 20px 0 0;">Save</button>
        <!-- <div *ngIf="isPlatformReleased">{{platformReleasedInfo}}</div> -->
    </mat-dialog-actions>  