<div>
    <label style="color:red;">{{lblMandatoryMsg}}</label>
    <label style="color:green;">{{lblSuccessMsg}}</label>
  </div>
  <mat-card-title  mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle> 
    <div><h4 style="float: left;font-size: large;font-weight: 400;">Power Supply </h4> 
      <div style="display:flex;
      flex-direction: row-reverse;">
      <div class="flex-center margin_auto_t_b" >
        <a class="anchor-close" id="close" title="Close" (click)="onCancel()">
        <span class="icon-close icon-size-24 icon-close"></span>
        </a>
      </div>       

      <button mat-button class="btn btn--small " style="height: 24px;margin: 0px 20px 0 0;"
      (click)="openAdditionalAtt_Modal()">Additional Fields</button><span class="margin_auto_t_b" 
      style="font-size: 65%;margin-top: -2px;margin-right:5px;" *ngIf="additionalFields.length>0">{{additionalFields.length}} Field(s) Added</span></div>
    </div>
   </mat-card-title>
  <form  [formGroup]="validations" autocomplete="off">
    <div mat-dialog-content style="width: max-content;"> 
        <mat-form-field>
          
          <div class="panel panel--compressed" style="max-width:800px;min-width: 500px;">
            <table class="table table-bordered">
              <tbody>
                <tr>
                    <td class="mandatory"> <mat-label>Display Name</mat-label>
                      <mat-error  *ngIf="validations.get('displayName').errors" style="color:red"></mat-error></td>
                    <td>
                      <div class="form-group" > 
                        <div class="form-group__text">
                          <input matInput  [ngClass]=" validations.get('displayName').errors ? 'text-danger': 'text-normal'" [(ngModel)]="powerSupplyData.displayName" formControlName="displayName"  required  >
                        </div>
                      </div>  
                    </td>            
                </tr>
                <tr>
                  <td></td> <td></td>
                </tr>
                <tr>
                    <td class="mandatory"> <mat-label>Internal Description</mat-label>
                      <mat-error  *ngIf="validations.get('internalName').errors" style="color:red"></mat-error></td>
                    <td>
                      <div class="form-group" > 
                        <div class="form-group__text">
                          <input matInput [ngClass]=" validations.get('internalName').errors ? 'text-danger': 'text-normal'" [(ngModel)]="powerSupplyData.internalName" formControlName="internalName"  required  >
                        </div>
                      </div>
                    </td>            
                </tr>
                <tr>
                  <td></td> <td></td>
                </tr>
                <!-- pid search -->
                <tr>
                  <td class="mandatory"  style="vertical-align: top;padding-top: 15px;">
                    <mat-label >PID</mat-label>
                  </td>
                  <td>
                    <app-search-box *ngIf="showPID" [type]="'component'" [pid]="selectedPid" (pidSelectionEvent)="selectedPID($event)" ></app-search-box>
                    </td>
                  <td></td>           
                </tr>
                <tr>
                  <td></td> <td></td>
                </tr> 
                <tr>
                  <td class="mandatory"> <mat-label>Capacity (GB)</mat-label>
                    <mat-error  *ngIf="validations.get('capacity').errors" style="color:red"></mat-error></td>
                  <td>
                    <div class="form-group" > 
                      <div class="form-group__text">
                        <input matInput  maxlength="10" [ngClass]="validations.get('capacity').hasError('pattern') || validations.get('capacity').hasError('min')   || validations.get('capacity').hasError('required') ? 'text-danger': 'text-normal'" [(ngModel)]="powerSupplyData.capacity" formControlName="capacity"  required >
                      </div>  
                    </div>  
                  </td> 
                </tr> 
                <tr>
                  <td></td>
                  <td>
                    <mat-error *ngIf ="validations.get('capacity').hasError('pattern') || validations.get('capacity').hasError('min')" style = "color:red " >Only Positive Integers are allowed</mat-error>
                  </td>
                </tr>           
                <tr>
                  <td class="mandatory"> <mat-label>MTBF Hours</mat-label></td>
                  <td>
                    <div class="form-group" > 
                      <div class="form-group__text">
                       <input matInput (ngModelChange)="validateWatts($event)" 
                              [ngClass]="validations.get('mtbf').hasError('pattern') ||
                                          mtbfErr? 'text-danger': 'text-normal'" [(ngModel)]="powerSupplyData.mtbf" formControlName="mtbf">
                      </div>  
                    </div>  
                  </td> 
                </tr>  
                <tr>
                    <td></td>
                    <td>
                      <mat-error *ngIf ="validations.get('mtbf').hasError('pattern')" style = "color:red " >Only Positive Integers are allowed</mat-error>
                      <mat-error *ngIf="mtbfErr" style=color:red>Only Non-Negative Integers are allowed </mat-error>
                    </td>
                  </tr>            
              </tbody>
            </table>
          </div>
        </mat-form-field>
      </div>
      
      <div mat-dialog-actions>
        <table class="table table-bordered">
          <tbody>
            <tr>
              <td>
  
                <mat-card-actions> 
                  <button mat-button  (click)="onSave()"   class="btn btn--small " 
                  style="height: 24px;margin: 5px 20px 0 0;" 
                  [disabled]="!validations.valid || mtbfErr || !selectedPid">Save & Close</button>
                </mat-card-actions>               
                <button mat-button (click)="onNoClick()" 
                cdkFocusInitial  class="btn btn--small "
                style="height: 24px;margin: 5px 20px 0 0;"
                [disabled]="!validations.valid || mtbfErr || !selectedPid">Save</button>
              </td>
            </tr>
          </tbody>
        </table>       
      </div>
  </form> 
    