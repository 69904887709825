import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from "ag-grid-angular";

import { Router } from "@angular/router";
import { AppConstant } from "src/constants/app.constants";
import { Subscription } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';
import { UrlConstant } from 'src/constants/url.constants';
import { HttpClient } from '@angular/common/http';
import { ComponentConstant } from 'src/constants/components.constants';

@Component({
  selector: 'app-checkbox-render',
  templateUrl: './checkbox-render.component.html',
  styleUrls: ['./checkbox-render.component.css']
})
export class CheckboxRenderComponent implements OnInit,ICellRendererAngularComp {
  params : any;
  label: string;  
  errorMsg: any;
 
  subscriptions: Subscription = new Subscription();
  constructor(
    private router: Router,
    private apiService: ApiService,
    private httpClient: HttpClient) { }
  agInit(params): void {
    this.params = params;
    this.label = this.params.label || null;    
  }

  ngOnInit(): void {
  }
  refresh(params?: any): boolean {
    return true;
  }

  checkedHandler(event) {
    let checked = event.target.checked;
    let colId = this.params.column.colId;
    
    if (checked) {
      this.params.data.released = 1;
    } else {
      this.params.data.released = "";
    }
    if (this.params?.data?.raid == null){
      this.params.data.raid = "";
    }
    ;
    ;
   if(this.params?.data?.type == ComponentConstant.IOES) {
    let ioesData = {
      "id": this.params?.data?.id,  
      "type" : ComponentConstant.IOES,
      "role" : "Admin",    
      "internalName" : this.params?.data?.internalName,
      "displayName" : this.params?.data?.displayName,
      "pid" : this.params?.data?.pid,
      "ioType" : this.params?.data?.ioType,
      "idleWatts" : this.params?.data?.idleWatts,
      "maxWatts" : this.params?.data?.maxWatts,
      "released" : this.params?.data?.released,
      "mtbf" : this.params?.data?.mtbf,
      "raid" : this.params?.data?.raid , 
      "allowDuplicates": true,
      "additionalFields" : this.params?.data?.additionalFields?.length > 0 ? this.params?.data?.additionalFields : []
    };
     this.updateIoDevicesReleaseState(ioesData); 
   } else if (this.params?.data?.type == ComponentConstant.STORAGES) {
    let storageDeviceData = {
      "id": this.params?.data?.id,  
      "type" : this.params?.data?.type,
      "role" : "Admin",    
      "displayName" : this.params?.data?.displayName,
      "pid" : this.params?.data?.pid,
      "storageType" : this.params?.data?.storageType,
      "idleWatts" : this.params?.data?.idleWatts,
      "maxWatts" : this.params?.data?.maxWatts,
      "released" : this.params?.data?.released,
      "mtbf" : this.params?.data?.mtbf,
      "capacity" : this.params?.data?.capacity,
      "mpn" : this.params?.data?.mpn,
      "allowDuplicates": true,
      "additionalFields" : this.params?.data?.additionalFields?.length > 0 ? this.params?.data?.additionalFields : [],
      "storageZoneType" : this.params?.data?.storageZoneType,
    };
     this.updateIoDevicesReleaseState(storageDeviceData); 
   } else if (this.params?.data?.type == ComponentConstant.CPUS) {
    let processorData = {
      "id": this.params?.data?.id,  
      "type" : this.params?.data?.type,
      "role" : "Admin",   
      "displayName" : this.params?.data?.displayName,
      "name": this.params?.data?.name,
      "pid" : this.params?.data?.pid,
      "cpuFamName" : this.params?.data?.cpuFamName,     
      "mtbf" : this.params?.data?.mtbf,
      "tdp" : this.params?.data?.tdp,
      "idleWatts": this.params?.data?.idleWatts,
      "released" : this.params?.data?.released,
      "allowDuplicates": true,
      "additionalFields" : this.params?.data?.additionalFields?.length > 0 ? this.params?.data?.additionalFields : [],
    };
    this.updateIoDevicesReleaseState(processorData); 
   } else if (this.params?.data?.type == ComponentConstant.DIMMS) {
    let dimmsData = {
      "id": this.params?.data?.id,  
      "type" : this.params?.data?.type,
      "role" : "Admin",   
      "displayName" : this.params?.data?.displayName,
      "size": this.params?.data?.size,
      "pid" : this.params?.data?.pid,
      "dimmFamName" : this.params?.data?.dimmFamName,     
      "mtbf" : this.params?.data?.mtbf,
      "numDimms" : this.params?.data?.numDimms,
      "released" : this.params?.data?.released,
      "allowDuplicates": true,
      "additionalFields" : this.params?.data?.additionalFields?.length > 0 ? this.params?.data?.additionalFields : [],
    
        "idleWatts" : this.params?.data?.idleWatts,
        "maxWatts" :this.params?.data?.maxWatts,     
        "moreMemoryModulePerChannelIdlePower" :this.params?.data?.moreMemoryModulePerChannelIdlePower,
        "moreMemoryModulePerChannelMaxPower" : this.params?.data?.moreMemoryModulePerChannelMaxPower,
        "persistentModelIdlePower" : this.params?.data?.persistentModelIdlePower,
        "persistentModelMaxPower" : this.params?.data?.persistentModelMaxPower,   
    }
     this.updateIoDevicesReleaseState(dimmsData);
   }else if (this.params?.data?.type == ComponentConstant.EXPANSIONS) {
    let expansionCardData = {
      "id": this.params?.data?.id,  
      "type" : this.params?.data?.type,
      "role" : "Admin",    
      "displayName" : this.params?.data?.displayName,
      "pid" : this.params?.data?.pid,
      "idleWatts" : this.params?.data?.idleWatts,
      "maxWatts" : this.params?.data?.maxWatts,
      "released" : this.params?.data?.released,
      "mtbf" : this.params?.data?.mtbf,
      "allowDuplicates": true,
      "additionalFields" : this.params?.data?.additionalFields?.length > 0 ? this.params?.data?.additionalFields : [],
    }
     this.updateIoDevicesReleaseState(expansionCardData);
   }else if (this.params?.data?.type == ComponentConstant.IOMS) {
      let ioModuleData = {
        "id": this.params?.data?.id,  
        "type" : this.params?.data?.type,
        "role" : "Admin",    
        "displayName" : this.params?.data?.displayName,
        "pid" : this.params?.data?.pid,
        "idleWatts" : this.params?.data?.idleWatts,
        "maxWatts" : this.params?.data?.maxWatts,
        "released" : this.params?.data?.released,
        "mtbf" : this.params?.data?.mtbf,
        "allowDuplicates": true,
        "additionalFields" : this.params?.data?.additionalFields?.length > 0 ? this.params?.data?.additionalFields : [],
      }
      this.updateIoDevicesReleaseState(ioModuleData);
   } else if (this.params?.data?.type == ComponentConstant.POWERSUPPLY){
      
    let powerSuppliesData = {
      "id": this.params?.data?.id,  
      "type" : ComponentConstant.POWERSUPPLY,
      "role" : "Admin",    
      "internalName" : this.params?.data?.powerSupply?.internalDesc,
      "displayName" : this.params?.data?.powerSupply?.displayName,
      "pid" : this.params?.data?.powerSupply?.pid,
      "capacity" : this.params?.data?.powerSupply?.capacity,
      "released" : this.params?.data?.released,
      "mtbf" : this.params?.data?.powerSupply?.mtbf,   
      "allowDuplicates": true,
      "additionalFields" : this.params?.data?.powerSupply?.additionalFields?.length > 0 ? this.params?.data?.additionalFields : [],   
    }  
    this.updateIoDevicesReleaseState(powerSuppliesData);
  } else {
    
    let dynamicComponentsData = {
      "id" : this.params.data?.id,
      "type" : this.params?.data.type,
      "role" : "Admin",    
      "displayName" : this.params?.data?.displayName,
      "pid" : this.params?.data?.pid,
      "idleWatts" : this.params?.data?.idleWatts,
      "maxWatts" : this.params?.data?.maxWatts,
      "released" : this.params?.data?.released,   
      "allowDuplicates": true,
      "additionalFields" : this.params?.data?.additionalFields?.length > 0 ? this.params?.data?.additionalFields : [],
    }
    this.updateIoDevicesReleaseState(dynamicComponentsData);
  }
   
   /*for (let [key, value] of Object.entries(this.params?.data)) {
    
  } */

    //    
    //this.params.node.setDataValue(colId, checked);  
    this.params.node.setDataValue(colId, this.params.data.released);
}

  updateIoDevicesReleaseState(data:any){
  //let ioes = ComponentConstant.IOES;
  
  let params =[];
  params.push(data);
  const url = UrlConstant.MANAGECOMPONENTS + UrlConstant.UPDATE; 
   ;
  this.subscriptions.add(
    this.apiService
      .postMethod(url,params).subscribe(() => {
        
      })
  )
  
}
deleteSelected(event) {
  this.params.onClick(this.params, event.target.checked);
}
}
