import { Pipe, PipeTransform } from "@angular/core";
import { AdminService } from "src/app/services/admin.service";

@Pipe({
  name: "filterComponent",
})
export class FilterComponentPipe implements PipeTransform {
  constructor(private adminService: AdminService) {}
  filterByField(attr: any, field: string, value: string) {
    return attr && attr[`${field}`] ? attr[`${field}`]?.toLowerCase().includes(value.toLocaleLowerCase()) : false; 
  }
  filterFn(attr: any, value: string): boolean {
    let displayNameExists, internalNameExists, nameExists, pidExists; 
    displayNameExists = this.filterByField(attr, 'displayName', value);
    internalNameExists = this.filterByField(attr, 'internalName', value);
    nameExists = this.filterByField(attr, 'name', value);
    pidExists = this.filterByField(attr, 'pid', value);
    return displayNameExists || nameExists || pidExists || internalNameExists;
  }
  searchFilter(elements: any[], value: string) {
    return elements.filter(f => {
      return this.filterFn(f, value);
    });
  }
  transform(componentList: any[], searchValue: string, type: string): unknown {
    
    searchValue = searchValue.trim();
    let searchFeature = [];
    if (searchValue === "") {
      searchFeature = componentList;
    } else {
      if (type == "accordion") {
        componentList.forEach((ele) => {
          let ele2 = this.searchFilter(ele.data, searchValue);
          if (ele2.length > 0) {
            let f = JSON.parse(JSON.stringify(ele));
            f.data = ele2;
            searchFeature.push(f);
          }
        });
      } else if (type == "pslist") {
        componentList.forEach((ele) => {
          if (this.filterFn(ele.powerSupply, searchValue)) {
            searchFeature.push(ele);
          }
        });
      } else if (type == "ioeslist") {
        componentList.forEach((ele) => {
          if (this.filterFn(ele, searchValue)) {
            searchFeature.push(ele);
          }
        });
      } else if (type == "bladelist") {
        componentList.forEach((ele) => {
          if (this.filterFn(ele, searchValue)) {
            searchFeature.push(ele);
          }
        });
      } else if (type == "fanlist") {
        componentList.forEach((ele) => {
          if (this.filterFn(ele, searchValue)) {
            searchFeature.push(ele);
          }
        });
      } else if (type == "additionalComponentsList") {
        componentList.forEach((ele) => {
          if (this.filterFn(ele, searchValue)) {
            searchFeature.push(ele);
          }
        });
      } else {
        searchFeature = componentList;
      }
    }
    this.adminService.setFilteredComp(searchFeature);

    return searchFeature;
  }
}
