import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';
import { UrlConstant } from 'src/constants/url.constants';
import { HttpClient } from '@angular/common/http';
import { GridOptions } from "ag-grid-community";
import { ComponentConstant } from 'src/constants/components.constants';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { EditRenderComponent} from 'src/app/shared/components/edit-render/edit-render.component';
import { CreateProcessorfamilyComponent } from 'src/app/components/admin/admin-tab/create-components/create-processorfamily/create-processorfamily.component'
import { CheckboxRenderComponent } from 'src/app/shared/components/checkbox-render/checkbox-render.component';
import { DeleteRenderComponent } from 'src/app/shared/components/delete-render/delete-render.component';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ToasterService } from 'src/app/shared/components/toast/toast.service';
import { errorCodes } from 'src/constants/errorCodes';
import { AdminService } from 'src/app/services/admin.service';

export interface DialogData {
  displayName : string; 
  test : string;
  
}

@Component({
  selector: 'app-manage-processor-family',
  templateUrl: './manage-processor-family.component.html',
  styleUrls: ['./manage-processor-family.component.css']
})
export class ManageProcessorFamilyComponent implements OnInit {
  displayName : string;
  test : string;
  @BlockUI() blockUI: NgBlockUI;

  subscriptions: Subscription = new Subscription();  
  errorMsg = '';
  columnDefs;
  frameworkComponents;
  defaultColDef;
  rowData: [];
  gridApi: any;
  gridColumnApi: any;
  gridOptions: GridOptions = {};
  flagBackButton = false;

  constructor(
    private apiService: ApiService,
    private httpClient: HttpClient,
    private toaster: ToasterService,
    private adminService: AdminService,
    public dialog: MatDialog){ 
      this.getManageProcessorFamilyList();
      
      this.columnDefs = [
        { headerName: 'Name' , field:'text',editable: false},        
        { headerName: 'Updated By', field:'editor' ,editable: false},
        { headerName: 'Last Updated' , field:'lastUpdated',editable: false,
        cellRenderer: (data) => {
          //return data.value ? moment(data.value).format('MMM DD, YYYY') : null;
          return data.value ? moment(data.value).format('MMM DD, YYYY') : moment(data.data.created).format('MMM DD, YYYY');
        }
        }, 
        {
          headerName: "",
          minWidth: 40,
          maxWidth: 50,
          sortable: false,
          filter: false,
          cellRenderer: "buttonEditRenderer",
          cellRendererParams: {
            label: "Edit",
            onClick: this.onEditButtonClick.bind(this),
          },
         
        },                   
        {
          headerName: "",
          minWidth: 40,
          maxWidth: 50,
          sortable: false,
          filter: false,
          cellRenderer: "buttonEditRenderer",
          cellRendererParams: {
            onClick: this.onDeleteButtonClick.bind(this),
            label: "Delete",
          },
         
        },
        
        { headerName: 'RAID', field:'raid',hide:true},
        { headerName: 'id', field:'id',hide:true},
        { headerName: 'sequence', field:'sequence',hide:true},
        { headerName: 'type', field:'type',hide:true},
        { headerName: 'name', field:'name',hide:true},
        { headerName: 'index' , field:'index',hide:true},
        { headerName: 'voltage', field:'voltage',hide:true},
        { headerName: 'voltageType', field:'voltageType',hide:true},
        { headerName: 'ts', field:'ts',hide:true},
        { headerName: 'created', field:'created',hide:true},
        { headerName: 'error' , field:'error',hide:true},
        { headerName: 'successMessage' , field:'successMessage',hide:true}
    ];
      this.defaultColDef = {
      editable : false,
      sortable: true,
      flex: 1,
      minWidth: 100,
      filter: true,
      resizable: true,
    };

    this.frameworkComponents = {
      buttonEditRenderer : EditRenderComponent,
      checkboxRenderer : CheckboxRenderComponent,
      buttonDeleterender : DeleteRenderComponent
      
      };
  }
  paginationPageSize = 10; 
  
  ngOnInit(): void {
    this.adminService.getProcessorFamilyData().subscribe(data => {
      if(data) {
      this.flagBackButton = true;
      this.getDuplicateRecords(data);
      }
    }) ;   
   }

  getDuplicateRecords(data: any) {    
   const filter = {
    text: {
          filterType: 'text',
          type: 'equals',
          filter: data.text
      },     
  }
    this.gridApi.setFilterModel(filter)
  }

  getManageProcessorFamilyList(){
    let cpuFamily = ComponentConstant.CPU_FAMILIES;
    this.blockUI.start('Loading...');
    const url = UrlConstant.MANAGECOMPONENTS + '/?component=' + cpuFamily ;
    this.subscriptions.add(
      this.apiService.getMethod(url).subscribe((data: any) => {
        this.rowData = data;
        this.blockUI.stop();
      }, error =>{  
        this.errorMsg= error?.error ? error.error?.error?.errorString : errorCodes.GENERIC_ERROR_MESSAGE;
        this.toaster.show('error', this.errorMsg , error ? error?.message : '');         
      }));
  }

  onEditButtonClick(params: any) {
    this.getManageProcessorFamilyList();    
  }

  onDeleteButtonClick(params: any) {
    
    this.getManageProcessorFamilyList();
    this.gridOptions.api?.updateRowData({ remove: [params.node.data.Id] });     
  }  
  openDialog(): void {
    const dialogRef = this.dialog.open(CreateProcessorfamilyComponent, {      
      data: {
             type : ComponentConstant.CPU_FAMILIES
            }            
    });   

    dialogRef.afterClosed().subscribe(result => {
      try{
        if (result !== 'cancel') {
          this.getManageProcessorFamilyList();
        }         
      } catch (error) {
        
      }         
    });
  }  
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;   
  }
  resetGrid() {
    this.gridApi.setFilterModel(null);
    this.flagBackButton = false;
  }
  ngOnDestroy() {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }
  
}

