<form [formGroup]="chassisServerForm" style="overflow: auto;max-height: 90vh;" autocomplete="off">
    <div class="container-fluid">

        <div style="margin-bottom: 0px;" mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
            <h4 style="float: left;">{{platformName}}</h4>
            <div style="    display: flex;
            flex-direction: row-reverse;">
                <div class="flex-center margin_auto_t_b">
                    <a class="anchor-close" id="close" title="Close" (click)="onCancel()">
                        <span class="icon-close icon-size-24 icon-close"></span>
                    </a>
                </div>
                <button mat-button type="button" class="btn btn--small " style="height: 24px;margin: 5px 20px 0 0;"
                    (click)="openAdditionalAtt_Modal()">Additional Fields</button><span class="margin_auto_t_b"
                    style="font-size: 65%;padding: 5px;" *ngIf="additionalFields?.length>0">{{additionalFields.length}}
                    Field(s) Added</span>
            </div>
        </div>

        <div class="section">
            <div class="col-md-12 col-lg-12">
                <div class="row">

                    <div class="col-md-6 col-lg-6 row">
                        <div class="col-3">
                            Vendor
                        </div>
                        <div class="col-md-6 col-lg-6">
                            <div class="form-group">
                                <div class="form-group__text">
                                    <input matInput formControlName="vendor"
                                        [ngClass]="chassisServerForm.get('vendor').errors ? 'text-danger': 'text-normal'">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-6 row">
                        <div class="col-3">
                            Name
                        </div>
                        <div class="col-md-6 col-lg-6">
                            <div class="form-group">
                                <div class="form-group__text">
                                    <input matInput formControlName="name"
                                        [ngClass]="chassisServerForm.get('name').errors ? 'text-danger': 'text-normal'">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <div class="section">
            <div class="col-md-12 col-lg-12">

                <div class="row">

                    <div class="col-md-6 col-lg-6 row">
                        <div class="col-3">
                            Generation
                        </div>
                        <div class="col-md-6 col-lg-6">
                            <div class="form-group">
                                <div class="form-group__text">
                                    <input matInput formControlName="gen" [ngClass]="'text-normal'">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-6 row">
                        <div class="col-3">
                            PID
                        </div>
                        <div class="col-md-6 col-lg-6">
                            <app-search-box *ngIf="showPID" [type]="'platform'" [pid]="selectedPid"
                                (pidSelectionEvent)="selectedPID($event)"></app-search-box>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="section">
            <div class="col-md-12 col-lg-12">
                <div class="row">

                    <div class="col-md-6 col-lg-6 row">
                        <div class="col-3">
                            Number of PSU Slots
                        </div>
                        <div class="col-md-6 col-lg-6">
                            <div class="form-group">
                                <div class="form-group__text">
                                    <input matInput formControlName="numPsuSlots" maxlength="10"
                                        [ngClass]="chassisServerForm.get('numPsuSlots').hasError('required') || chassisServerForm.get('numPsuSlots').hasError('pattern') || chassisServerForm.get('numPsuSlots').hasError('min') ? 'text-danger': 'text-normal'">
                                </div>
                                <span
                                    *ngIf="chassisServerForm.get('numPsuSlots').hasError('pattern') || chassisServerForm.get('numPsuSlots').hasError('min')"
                                    style=color:red>Only Positive Integers are allowed</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-6 row">
                        <div class="col-3">
                            nMin
                        </div>
                        <div class="col-md-3 col-lg-3">
                            <div class="form-group">
                                <div class="form-group__text">
                                    <input matInput formControlName="nMin" maxlength="10"
                                        [ngClass]="chassisServerForm.get('nMin').hasError('pattern') ? 'text-danger': 'text-normal'">
                                </div>
                                <span *ngIf="chassisServerForm.get('nMin').hasError('pattern')" style=color:red>Only
                                    Positive Integers are allowed</span>
                            </div>
                        </div>
                        <div class="col-md-3 col-lg-3">
                            Power Cap Level
                        </div>
                        <div class="col-md-3 col-lg-3">
                            <div class="form-group">
                                <ngx-select-dropdown [multiple]="false"
                                    [ngClass]="chassisServerForm.get('implicitCap').hasError('required') ? 'ngx-select-danger ngx-dropdown-button': 'ngx-select-normal ngx-dropdown-button'"
                                    formControlName="implicitCap" [config]="{search:false,displayKey:'viewValue'}"
                                    [options]="powerCapLevel">
                                </ngx-select-dropdown>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <div class="section">
            <div class="col-md-12 col-lg-12">

                <div class="row">

                    <div class="col-md-6 col-lg-6 row">
                        <div class="col-3">
                            IOM Slots
                        </div>
                        <div class="col-md-6 col-lg-6">
                            <div class="form-group">
                                <div class="form-group__text disabled">
                                    <input matInput formControlName="numIomSlots" readonly="true">
                                </div>

                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-6 row">
                        <div class="col-3">
                            Conversion Factor
                        </div>
                        <div class="col-md-6 col-lg-6">
                            <div class="form-group">
                                <div class="form-group__text">
                                    <input matInput formControlName="conversionFactor" maxlength="10"
                                        [ngClass]="chassisServerForm.get('conversionFactor').hasError('max') || chassisServerForm.get('conversionFactor').hasError('required') || chassisServerForm.get('conversionFactor').hasError('pattern') || chassisServerForm.get('conversionFactor').hasError('min') ? 'text-danger': 'text-normal'">
                                </div>
                                <span
                                    *ngIf="chassisServerForm.get('conversionFactor').hasError('min') || chassisServerForm.get('conversionFactor').hasError('pattern') || chassisServerForm.get('conversionFactor').hasError('max')"
                                    style=color:red>Only Positive Numders are allowed </span>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="section">
            <div class="col-md-12 col-lg-12">

                <div class="row">

                    <div class="col-md-6 col-lg-6 row">
                        <div class="col-3">
                            Blade Slots
                        </div>
                        <div class="col-md-6 col-lg-6">
                            <div class="form-group">
                                <div class="form-group__text">
                                    <input matInput formControlName="numBladeSlots" maxlength="10"
                                        [ngClass]="chassisServerForm.get('numBladeSlots').hasError('required') || chassisServerForm.get('numBladeSlots').hasError('pattern') || chassisServerForm.get('numBladeSlots').hasError('min') ? 'text-danger': 'text-normal'">
                                </div>
                                <span
                                    *ngIf="chassisServerForm.get('numBladeSlots').hasError('pattern') || chassisServerForm.get('numBladeSlots').hasError('min')"
                                    style=color:red>Only Positive Integers are allowed</span>

                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-6 row">
                        <div class="col-3">
                            Series
                        </div>
                        <div class="col-md-6 col-lg-6">
                            <div class="form-group select-ngx" [attr.disabled]="true">
                                <ngx-select-dropdown disabled="true" [multiple]="false"
                                    [ngClass]=" chassisServerForm.get('series').hasError('required') ? 
                                                      'ngx-select-danger ngx-dropdown-button': 'ngx-select-normal ngx-dropdown-button'" formControlName="series"
                                    [config]="config" [options]="seriesList">
                                </ngx-select-dropdown>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="section">
            <div class="col-md-12 col-lg-12">

                <div class="row">

                    <div class="col-md-6 col-lg-6 row">
                        <div class="col-3">
                            Weight (lbs)
                        </div>
                        <div class="col-md-6 col-lg-6">
                            <div class="form-group">
                                <div class="form-group__text">
                                    <input matInput formControlName="weight" (ngModelChange)="validateWatts($event)"
                                        [ngClass]="weightErr || chassisServerForm.get('weight').hasError('pattern')|| chassisServerForm.get('weight').hasError('required') || chassisServerForm.get('weight').hasError('min') ? 'text-danger': 'text-normal'">
                                </div>
                                <span
                                    *ngIf="chassisServerForm.get('weight').hasError('pattern')|| chassisServerForm.get('weight').hasError('min')"
                                    style=color:red>Only Positive Integers are allowed </span>
                                <span *ngIf="weightErr" style=color:red>Only Positive Numbers are allowed</span>

                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-6 row" *ngIf="isEdit && storageData.params?.legacyPlatform !== false
                               && storageData.params?.isNewCreated == null">
                        <div class="col-3">
                            Legacy Platform

                        </div>
                        <div class="col-md-6 col-lg-6">
                            <div class="form-group form-group--inline">
                                <label class="radio">
                                    <input type="radio" value="true" (change)="legacyPlatformRadioChange($event)"
                                        name="legacyPlatform" formControlName="legacyPlatform">
                                    <span class="radio__input"></span>
                                    <span class="radio__label">Yes</span>
                                </label>
                            </div>
                            <div class="form-group form-group--inline">
                                <label class="radio">
                                    <input type="radio" value="false" (change)="legacyPlatformRadioChange($event)"
                                        name="legacyPlatform" formControlName="legacyPlatform">
                                    <span class="radio__input"></span>
                                    <span class="radio__label">No</span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-6 col-lg-6">

                <div class="section">
                    <div class="col-md-12 col-lg-12">

                        <div class="row">

                            <div class="col-3">
                                Idle Power (W)
                            </div>
                            <div class="col-md-6 col-lg-6 padding-set-input">
                                <div class="form-group">
                                    <div class="form-group__text">
                                        <input matInput formControlName="idleBasePower"
                                            (ngModelChange)="validateWatts($event)" [ngClass]=" showWattErr 
                                                                || chassisServerForm.get('idleBasePower').hasError('required') 
                                                                || chassisServerForm.get('idleBasePower').hasError('pattern')
                                                                || chassisServerForm.get('idleBasePower').hasError('min')  
                                                                || IdleWattsErr ? 'text-danger': 'text-normal'">
                                    </div>
                                    <span
                                        *ngIf="IdleWattsErr || chassisServerForm.get('idleBasePower').hasError('pattern') || chassisServerForm.get('idleBasePower').hasError('min')"
                                        style=color:red>Only Positive Integers are allowed </span>
                                </div>
                                <mat-error *ngIf="showWattErr" style=color:red>Idle Power should be less than or equal
                                    to Max Power </mat-error>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="section">
                    <div class="col-md-12 col-lg-12">
                        <div class="row">

                            <div class="col-3">
                                Max Power (W)
                            </div>
                            <div class="col-md-6 col-lg-6 padding-set-input">
                                <div class="form-group">
                                    <div class="form-group__text">
                                        <input matInput formControlName="maxBasePower"
                                            (ngModelChange)="validateWatts($event)" [ngClass]=" showWattErr 
                                                            || chassisServerForm.get('maxBasePower').hasError('required') 
                                                            || chassisServerForm.get('maxBasePower').hasError('pattern')
                                                            || chassisServerForm.get('maxBasePower').hasError('min') 
                                                            || IdleMaxWattsErr? 'text-danger': 'text-normal'">
                                    </div>
                                    <span
                                        *ngIf="IdleMaxWattsErr || chassisServerForm.get('maxBasePower').hasError('pattern') || chassisServerForm.get('maxBasePower').hasError('pattern')"
                                        style=color:red>Only Positive Integers are allowed </span>
                                </div>
                                <mat-error *ngIf="showWattErr" style=color:red>Max Power should be greater than or equal
                                    to Idle Power</mat-error>
                            </div>

                        </div>

                    </div>
                </div>
                <div class="section">
                    <div class="col-md-12 col-lg-12">

                        <div class="row">

                            <div class="col-3">
                                MTBF Hours
                            </div>
                            <div class="col-md-6 col-lg-6 padding-set-input">
                                <div class="form-group">
                                    <div class="form-group__text">
                                        <input matInput formControlName="mtbf" (ngModelChange)="validateWatts($event)"
                                            [ngClass]="chassisServerForm.get('mtbf').hasError('pattern') || mtbfErr? 'text-danger': 'text-normal'">
                                    </div>
                                    <span *ngIf="chassisServerForm.get('mtbf').hasError('pattern')" style=color:red>Only
                                        Positive Integers are allowed </span>
                                    <span *ngIf="mtbfErr" style=color:red>Only Non-Negative Integers are allowed</span>
                                </div>
                            </div>
                           
                        </div>
                    </div>
                </div>

                <div class="section">
                    <div class="col-md-12 col-lg-12">

                        <div class="row">

                            <div class="col-3">
                                End of Sale
                            </div>
                            <div class="col-md-6 col-lg-6">
                                <div class="form-group form-group--inline">
                                    <label class="radio">
                                        <input type="radio" value="1" name="discont" formControlName="discont">
                                        <span class="radio__input"></span>
                                        <span class="radio__label">Yes</span>
                                    </label>
                                </div>
                                <div class="form-group form-group--inline">
                                    <label class="radio">
                                        <input type="radio" value="0" name="discont" formControlName="discont">
                                        <span class="radio__input"></span>
                                        <span class="radio__label">No</span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
            <div class="col-md-6 col-lg-6" style="
      margin-left: -22px;
  ">

                <div class="row padding_row">
                    <div class="col-md-12 col-lg-12 row">
                        <div class="col-md-3 col-lg-3 ">
                            Image Look up
                        </div>
                        <div class="col-md-6 col-lg-6">
                            <div class="form-group">
                                <input type="file" name="uploadfile" id="importImage" accept="image/*"
                                    style="display:none;" (change)="onImportImage($event)" />
                                <label for="importImage" class="btn btn--secondary import-project-btn">Browse
                                    Image</label>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="row padding_row">
                    <div class="col-md-12 col-lg-12 row" style="height: 170px;">

                        <img [src]="url" style="width: 275px;height: auto;margin: auto;"> <br />

                    </div>
                </div>

            </div>
        </div>





        <button mat-button type="button" (click)="imageUpload('closeModal')"
            [disabled]="submitInProgress  || !chassisServerForm.valid  || showWattErr || IdleWattsErr || IdleMaxWattsErr || mtbfErr  || !selectedPid"
            class="btn btn--small " style="height: 24px;margin: 5px 20px 0 0;">Save & Close</button>
        <button mat-button type="button" (click)="imageUpload('save')"
            [disabled]="submitInProgress  || !chassisServerForm.valid  || showWattErr || IdleWattsErr || IdleMaxWattsErr || mtbfErr || !selectedPid"
            class="btn btn--small " style="height: 24px;margin: 5px 20px 0 0;">Save</button>

    </div>
</form>