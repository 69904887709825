import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CngContentConfigType, CngModalService, CngModalSize } from "@cisco/cui-ng";
import { TranslateService } from "@ngx-translate/core";
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { Subscription } from 'rxjs';
import { CreateAdditionalPlatform1Component } from "src/app/components/admin/admin-tab/create-platforms/create-additional-platforms/create-additional-platform1/create-additional-platform1.component";
import { CreateBladeChassisComponent } from "src/app/components/admin/admin-tab/create-platforms/create-blade-chassis/create-blade-chassis.component";
import { CreateBladeServersComponent } from "src/app/components/admin/admin-tab/create-platforms/create-blade-servers/create-blade-servers.component";
import { CreateFabricExtendersComponent } from "src/app/components/admin/admin-tab/create-platforms/create-fabric-extenders/create-fabric-extenders.component";
import { CreateFabricInterconnectsComponent } from "src/app/components/admin/admin-tab/create-platforms/create-fabric-interconnects/create-fabric-interconnects.component";
import { CreateModularChassisComponent } from "src/app/components/admin/admin-tab/create-platforms/create-modular-chassis/create-modular-chassis.component";
import { CreateNodesComponent } from "src/app/components/admin/admin-tab/create-platforms/create-nodes/create-nodes.component";
import { CreateRackServersComponent } from "src/app/components/admin/admin-tab/create-platforms/create-rack-servers/create-rack-servers.component";
import { AddConfigurationComponent } from 'src/app/components/admin/admin-tab/manage-platform-configs/add-configuration/add-configuration.component';
import { AddMeasurementComponent } from 'src/app/components/admin/admin-tab/manage-platform-configs/add-measurement/add-measurement.component';
import { CpuMemoryCompatibilityComponent } from 'src/app/components/admin/admin-tab/manage-platform-configs/cpu-memory-compatibility/cpu-memory-compatibility.component';
import { ManageComponentComponent } from 'src/app/components/admin/admin-tab/manage-platform-configs/manage-component/manage-component.component';
import { ApiService } from 'src/app/services/api.service';
import { ToasterService } from "src/app/shared/components/toast/toast.service";
import { errorCodes } from "src/constants/errorCodes";
import { PlatformConstant } from 'src/constants/platform.constants';
import { UrlConstant } from 'src/constants/url.constants';
import { ConfirmationBoxComponent } from "../confirmation-box/confirmation-box.component";

@Component({
  selector: 'app-edit-render-manage-platforms',
  templateUrl: './edit-render-manage-platforms.component.html',
  styleUrls: ['./edit-render-manage-platforms.component.css']
})
export class EditRenderManagePlatformsComponent implements OnInit {
  params;
  label
  subscriptions: Subscription = new Subscription();
  @BlockUI() blockUI: NgBlockUI;

  constructor(
    public dialog: MatDialog,
    private modalService: CngModalService,
    private translate: TranslateService,  
    private apiService: ApiService,
    private toaster: ToasterService, 
    ) { }

  ngOnInit(): void {
  }
  agInit(params): void {
    this.params = params;
    this.label = this.params.label || null;
  }
  public async openModal($event) {
    const result = await this.modalService
      .open({
        content: {
          type: CngContentConfigType.COMPONENT,
          content: ConfirmationBoxComponent,
          componentData: {
            someText: this.translate.instant(
              "ARE_YOU_SURE_TO_DELETE_THIS_PLATFORM"
            ),
            key: "delete",
            event: $event,
          },
        },
        size: CngModalSize.SMALL,
      })
      .onDismiss.toPromise();

    if (result == PlatformConstant.ACTION_OK){
      this.onClick($event, PlatformConstant.ACTION_DELETE);
    }
  }

  onClick($event:any, action: string) {

    if (action == PlatformConstant.ADD_MEASUREMENT) {
      //for compute node 0
      if( this.params?.data?.type == PlatformConstant.NODE  && this.params?.data?.computeNode == 0){
        this.toaster.show('error', errorCodes.NODE_SET_0, '',5000);             
        return false;
      }

      if(this.params?.data?.cpus !== null && this.params?.data?.dimms !== null  
        && this.params?.data?.dimms?.length > 0 && this.params?.data?.cpus.length > 0 ){

      const dialogRef = this.dialog.open(AddMeasurementComponent, {
        data: {
          params : this.params.data
         },
        disableClose: true,
        panelClass: 'width-dialog-100'
      });
      dialogRef.afterClosed().subscribe(result => {
        if(result.data?.action == PlatformConstant.ACTION_REFRESH){
          this.params.onClick(this.params);   
        }
      })
    }
    else{
      this.checkMandatoryFields();           
    }

    }
    else if (action == PlatformConstant.MANAGE_COMP_OPTIONS) {
      const dialogRef = this.dialog.open(ManageComponentComponent, {
        data: {
          params : this.params.data,
          additionalPlatform : this.params?.additionalPlatform
         },
        disableClose: true,
        panelClass: 'width-dialog-100'
      });
      dialogRef.afterClosed().subscribe(result => {
        if(result.data?.action == PlatformConstant.ACTION_REFRESH){
          this.params.onClick(this.params);   
        }                
      })
    }
    else if (action == PlatformConstant.ADD_CONFIG) {

      //for compute node 0
      if( this.params?.data?.type == PlatformConstant.NODE  && this.params?.data?.computeNode == 0){
        this.toaster.show('error', errorCodes.NODE_SET_0, '',5000);             
        return false;
      }

      if(this.params?.data?.cpus !== null && this.params?.data?.dimms !== null  
        && this.params?.data?.dimms?.length > 0 && this.params?.data?.cpus.length > 0 ){

      

      const dialogRef = this.dialog.open(AddConfigurationComponent, {
        data: {
          params : this.params.data
         },
        disableClose: true,
        panelClass: 'width-dialog-100'
      });
      dialogRef.afterClosed().subscribe(result => {
        if(result.data?.action == PlatformConstant.ACTION_REFRESH){
          this.params.onClick(this.params);   
        }                
      })
    }
    else{
      this.checkMandatoryFields();           
    }

    }
    else if (action == PlatformConstant.CPU_MEM_COMPATIBILITY) {

      if(this.params?.data?.cpus !== null && this.params?.data?.dimms !== null  
        && this.params?.data?.dimms?.length > 0 && this.params?.data?.cpus.length > 0 ){

      const dialogRef = this.dialog.open(CpuMemoryCompatibilityComponent, {
        data: {
          params : this.params.data
         },
        disableClose: true,
        panelClass: 'width-dialog-100'
      });
      dialogRef.afterClosed().subscribe(result => {
        if(result.data?.action == PlatformConstant.ACTION_REFRESH){
          this.params.onClick(this.params);   
        }      
      })

    }
    else{
      this.checkMandatoryFields();           
    }

    }
    else if (action === PlatformConstant.ACTION_DELETE) {
      this.deletePlatform(this.params?.data?.id, this.params?.data?.type);             
    }
    else if (action === PlatformConstant.ACTION_UPDATE) {
      this.openEditDialog(this.params.data);
    }
    else{
      
    }
  }

  checkMandatoryFields(){
    let fields = [];

      if(this.params?.data?.cpus == null || this.params?.data?.cpus?.length == 0 ){
        fields.push("Processor");
      }
      if(this.params?.data?.dimms == null || this.params?.data?.dimms?.length == 0 ){
        fields.push("Memory");
      }

      let manFields ="";
      fields.forEach(f=>{
        manFields = manFields + f +", "
      })
      manFields = manFields.replace(/,\s*$/, "");
      this.toaster.show('error', errorCodes.OP_FAILED , errorCodes.CPU_DIMS_NOT_ADDED +manFields,5000);   

  }

  deletePlatform(platformId:any,type:string){     
    const url = UrlConstant.MANAGE_PLATFORM + UrlConstant.DELETE ;
    let params = {
      "id": platformId,  
      "type" : type,
      "role" : "Admin"
    };    
    this.blockUI.start('Loading...');
    this.subscriptions.add(
      this.apiService
        .postMethod(url,params).subscribe((data: any) => { 
          this.blockUI.stop();     
          this.params.api.applyTransaction({remove: [this.params.node.data]}); 
          this.toaster.show('success', errorCodes.PLATFORM_DELETE_MESSAGE, '',5000);             
        })
    )   
  }

  openEditDialog(params:any){
    let component:any = null;
    let type:any = null;

    switch (params?.type) {
      case PlatformConstant.RACK : {
        component = CreateRackServersComponent;
        break;
      }
      case PlatformConstant.BLADE : {
        component = CreateBladeServersComponent;
        break;
      }
      case PlatformConstant.BLADE_CHASSIS : {
        component = CreateBladeChassisComponent;
        break;
      }
      case PlatformConstant.NODE : {
        component = CreateNodesComponent;
        break;
      }
      case PlatformConstant.MODULAR_CHASSIS : {
        component = CreateModularChassisComponent;
        break;
      }
      case PlatformConstant.FABRIC_INTERCONNECTS : {
        component = CreateFabricInterconnectsComponent;
        break;
      }
      case PlatformConstant.FABRIC_EXTENDERS : {
        component = CreateFabricExtendersComponent;
        break;
      }
      default : {
        if(this.params.additionalPlatform){
          component = CreateAdditionalPlatform1Component;
        }
        else{
          
        }
      }
    }
    if(component){
      const dialogRef = this.dialog.open(component, {      
        data: {
               action : PlatformConstant.ACTION_UPDATE,
               params : params,
              },
        disableClose: true,
        panelClass: this.params.additionalPlatform ? '' : 'width-dialog-100',           
      });
      dialogRef.afterClosed().subscribe(result => {
        if(result.data?.action == PlatformConstant.ACTION_REFRESH){
          this.params.onClick(this.params);   
        }
      });
    }
  }

}
