<form [formGroup]="fabricInterconnetForm" style="overflow: auto;max-height: 90vh;" autocomplete="off">

    <div class="container-fluid">
        <div style="margin-bottom: 0px;" mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
            <h4 style="float: left;">{{platformName}}</h4>
            <div style="    display: flex;
        flex-direction: row-reverse;">
                <div class="flex-center margin_auto_t_b">
                    <a class="anchor-close" id="close" title="Close" (click)="onCancel()">
                        <span class="icon-close icon-size-24 icon-close"></span>
                    </a>
                </div>
                <button mat-button type="button" class="btn btn--small " style="height: 24px;margin: 5px 20px 0 0;"
                    (click)="openAdditionalAtt_Modal()">Additional Fields</button><span class="margin_auto_t_b"
                    style="font-size: 65%;padding: 5px;" *ngIf="additionalFields?.length>0">{{additionalFields.length}}
                    Field(s) Added</span>
            </div>
        </div>
        <div class="section">
            <div class="col-md-12 col-lg-12">
                <div class="row">

                    <div class="col-md-6 col-lg-6 row">
                        <div class="col-3">
                            Vendor
                        </div>
                        <div class="col-md-6 col-lg-6">
                            <div class="form-group">
                                <div class="form-group__text">
                                    <input matInput formControlName="vendor"
                                        [ngClass]="fabricInterconnetForm.get('vendor').errors ? 'text-danger': 'text-normal'">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-6 row">
                        <div class="col-3">
                            Name
                        </div>
                        <div class="col-md-6 col-lg-6">
                            <div class="form-group">
                                <div class="form-group__text">
                                    <input matInput formControlName="name"
                                        [ngClass]="fabricInterconnetForm.get('name').errors ? 'text-danger': 'text-normal'">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <div class="section">
            <div class="col-md-12 col-lg-12">

                <div class="row">

                    <div class="col-md-6 col-lg-6 row">
                        <div class="col-3">
                            Generation
                        </div>
                        <div class="col-md-6 col-lg-6">
                            <div class="form-group">
                                <div class="form-group__text">
                                    <input matInput formControlName="gen" [ngClass]="'text-normal'">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-6 row">
                        <div class="col-3">
                            PID
                        </div>
                        <div class="col-md-6 col-lg-6">
                            <app-search-box *ngIf="showPID" [type]="'platform'" [pid]="selectedPid"
                                (pidSelectionEvent)="selectedPID($event)"></app-search-box>

                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="section">
            <div class="col-md-12 col-lg-12">
                <div class="row">

                    <div class="col-md-6 col-lg-6 row">
                        <div class="col-3">
                            Number of PSU Slots
                        </div>
                        <div class="col-md-6 col-lg-6">
                            <div class="form-group">
                                <div class="form-group__text">
                                    <input matInput formControlName="maxPsus" maxlength="10"
                                        [ngClass]="fabricInterconnetForm.get('maxPsus').hasError('required') || fabricInterconnetForm.get('maxPsus').hasError('pattern') || fabricInterconnetForm.get('maxPsus').hasError('min') ? 'text-danger': 'text-normal'">
                                </div>
                                <span
                                    *ngIf="fabricInterconnetForm.get('maxPsus').hasError('pattern') || fabricInterconnetForm.get('maxPsus').hasError('min')"
                                    style=color:red>Only Positive Integers are allowed</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-6 row">
                        <div class="col-3">
                            Expansion Slots
                        </div>
                        <div class="col-md-6 col-lg-6">
                            <div class="form-group">
                                <div class="form-group__text">
                                    <input matInput formControlName="maxExpSlots" maxlength="10"
                                        (ngModelChange)="validateMaxExpSlots($event)"
                                        [ngClass]="maxExpErr || fabricInterconnetForm.get('maxExpSlots').hasError('required') || fabricInterconnetForm.get('maxExpSlots').hasError('pattern') ? 'text-danger': 'text-normal'">
                                </div>
                                <span *ngIf="fabricInterconnetForm.get('maxExpSlots').hasError('pattern')"
                                    style=color:red>Only Positive Integers are allowed</span>
                                <mat-error *ngIf="maxExpErr" style=color:red>Expansion Slots cannot be set to 0 because
                                    Expansion Cards are already assigned</mat-error>

                            </div>
                        </div>
                    </div>


                </div>
            </div>

        </div>
        <div class="section">
            <div class="col-md-12 col-lg-12">

                <div class="row">

                    <div class="col-md-6 col-lg-6 row">
                        <div class="col-3">
                            Idle Power (W)
                        </div>
                        <div class="col-md-6 col-lg-6">
                            <div class="form-group">
                                <div class="form-group__text">
                                    <input matInput formControlName="baseIdle" (ngModelChange)="validateWatts($event)"
                                        [ngClass]="showWattErr 
                                                       || fabricInterconnetForm.get('baseIdle').hasError('required') 
                                                       || fabricInterconnetForm.get('baseIdle').hasError('pattern') 
                                                       || fabricInterconnetForm.get('baseIdle').hasError('min') 
                                                       || IdleWattsErr ? 'text-danger': 'text-normal'">
                                </div>
                                <span
                                    *ngIf="IdleWattsErr || fabricInterconnetForm.get('baseIdle').hasError('pattern') || fabricInterconnetForm.get('baseIdle').hasError('min') "
                                    style=color:red>Only Positive Integers are allowed</span>
                                <mat-error *ngIf="showWattErr" style=color:red>Idle Power should be less than or equal
                                    to Max Power </mat-error>

                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-6 row">
                        <div class="col-3">
                            Max Power (W)
                        </div>
                        <div class="col-md-6 col-lg-6">

                            <div class="form-group">
                                <div class="form-group__text">
                                    <input matInput formControlName="baseMax" (ngModelChange)="validateWatts($event)"
                                        [ngClass]="showWattErr 
                                                   || fabricInterconnetForm.get('baseMax').hasError('required') 
                                                   || fabricInterconnetForm.get('baseMax').hasError('pattern') 
                                                   || fabricInterconnetForm.get('baseMax').hasError('min') 
                                                   || IdleMaxWattsErr? 'text-danger': 'text-normal'">
                                </div>
                                <span
                                    *ngIf="IdleMaxWattsErr || fabricInterconnetForm.get('baseMax').hasError('pattern') || fabricInterconnetForm.get('baseMax').hasError('min') "
                                    style=color:red>Only Positive Integers are allowed</span>
                                <mat-error *ngIf="showWattErr" style=color:red>Max Power should be greater than or equal
                                    to Idle Power</mat-error>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="section">
            <div class="col-md-12 col-lg-12">
                <div class="row">

                    <div class="col-md-6 col-lg-6 row">
                        <div class="col-3">
                            Weight (lbs)
                        </div>
                        <div class="col-md-6 col-lg-6">
                            <div class="form-group">
                                <div class="form-group__text">
                                    <input matInput formControlName="weight" (ngModelChange)="validateWatts($event)"
                                        [ngClass]="weightErr || fabricInterconnetForm.get('weight').hasError('required') || fabricInterconnetForm.get('weight').hasError('pattern') || fabricInterconnetForm.get('weight').hasError('min') ? 'text-danger': 'text-normal'">
                                </div>
                                <span
                                    *ngIf="fabricInterconnetForm.get('weight').hasError('min') || fabricInterconnetForm.get('weight').hasError('pattern')"
                                    style=color:red>Only Positive Integers are allowed </span>
                                <span *ngIf="weightErr" style=color:red>Only Positive Numbers are allowed</span>

                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-6 row">
                        <div class="col-3">
                            Height (Rack Units)
                        </div>
                        <div class="col-md-6 col-lg-6">
                            <div class="form-group">
                                <div class="form-group__text">
                                    <input matInput formControlName="height" maxlength="10"
                                        [ngClass]="fabricInterconnetForm.get('height').hasError('pattern') ? 'text-danger': 'text-normal'">
                                </div>
                                <span *ngIf="fabricInterconnetForm.get('height').hasError('pattern')"
                                    style=color:red>Only Positive Integers are allowed </span>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <div class="section">
            <div class="col-md-12 col-lg-12">
                <div class="row">

                    <div class="col-md-6 col-lg-6 row">
                        <div class="col-3">
                            Ports
                        </div>
                        <div class="col-md-6 col-lg-6">
                            <div class="form-group">
                                <div class="form-group__text">
                                    <input matInput formControlName="maxPorts" maxlength="40"
                                        [ngClass]="fabricInterconnetForm.get('maxPorts').hasError('min') || fabricInterconnetForm.get('maxPorts').hasError('required') || fabricInterconnetForm.get('maxPorts').hasError('pattern') ? 'text-danger': 'text-normal'">
                                </div>
                                <span
                                    *ngIf="fabricInterconnetForm.get('maxPorts').hasError('pattern') ||  fabricInterconnetForm.get('maxPorts').hasError('min') "
                                    style=color:red>Only Positive Integers are allowed</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-6 row">
                        <div class="col-3">
                            Conversion Factor
                        </div>
                        <div class="col-md-6 col-lg-6">
                            <div class="form-group">
                                <div class="form-group__text">
                                    <input matInput formControlName="conversionFactor" maxlength="10"
                                        [ngClass]="fabricInterconnetForm.get('conversionFactor').hasError('max') || fabricInterconnetForm.get('conversionFactor').hasError('required') || fabricInterconnetForm.get('conversionFactor').hasError('pattern') || fabricInterconnetForm.get('conversionFactor').hasError('min') ? 'text-danger': 'text-normal'">
                                </div>
                                <span
                                    *ngIf="fabricInterconnetForm.get('conversionFactor').hasError('min') || fabricInterconnetForm.get('conversionFactor').hasError('pattern') || fabricInterconnetForm.get('conversionFactor').hasError('max')"
                                    style=color:red>Only Positive Numders are allowed </span>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="section">
            <div class="col-md-12 col-lg-12">
                <div class="row">

                    <div class="col-md-6 col-lg-6 row">
                        <div class="col-3">
                            Idle Per Port (W)

                        </div>
                        <div class="col-md-6 col-lg-6">
                            <div class="form-group">
                                <div class="form-group__text">
                                    <input matInput formControlName="idlePerPort" maxlength="40"
                                        (ngModelChange)="validateWatts($event)" [ngClass]="fabricInterconnetForm.get('idlePerPort').hasError('min') 
                                                           || fabricInterconnetForm.get('idlePerPort').hasError('required') 
                                                           || fabricInterconnetForm.get('idlePerPort').hasError('pattern')
                                                           || showPortErr
                                                           || idlePortErr
                                                            ? 'text-danger': 'text-normal'">
                                </div>
                                <span
                                    *ngIf="idlePortErr || fabricInterconnetForm.get('idlePerPort').hasError('pattern') || fabricInterconnetForm.get('idlePerPort').hasError('min')"
                                    style=color:red>Only Positive Integers are allowed</span>
                                <mat-error *ngIf="showPortErr" style=color:red>Idle Port should be less than or equal to
                                    Max Port </mat-error>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-6 row">
                        <div class="col-3">
                            Series
                        </div>
                        <div class="col-md-6 col-lg-6">
                            <div class="form-group select-ngx" [attr.disabled]="true">
                                <ngx-select-dropdown disabled="true" [multiple]="false"
                                    [ngClass]=" fabricInterconnetForm.get('series').hasError('required') ? 
                                                  'ngx-select-danger ngx-dropdown-button': 'ngx-select-normal ngx-dropdown-button'" formControlName="series"
                                    [config]="config" [options]="seriesList">
                                </ngx-select-dropdown>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="section">
            <div class="col-md-12 col-lg-12">
                <div class="row">

                    <div class="col-md-6 col-lg-6 row">
                        <div class="col-3">
                            Max Per Port (W)

                        </div>
                        <div class="col-md-6 col-lg-6">
                            <div class="form-group">
                                <div class="form-group__text">
                                    <input matInput formControlName="maxPerPort" maxlength="40"
                                        (ngModelChange)="validateWatts($event)" [ngClass]="fabricInterconnetForm.get('maxPerPort').hasError('min') 
                                                               || fabricInterconnetForm.get('maxPerPort').hasError('required') 
                                                               || fabricInterconnetForm.get('maxPerPort').hasError('pattern') 
                                                               || showPortErr
                                                               || maxPortErr? 'text-danger': 'text-normal'">
                                </div>
                                <span
                                    *ngIf="maxPortErr || fabricInterconnetForm.get('maxPerPort').hasError('min') || fabricInterconnetForm.get('maxPerPort').hasError('pattern')"
                                    style=color:red>Only Positive Integers are allowed</span>
                                <mat-error *ngIf="showPortErr" style=color:red>Max Port should be greater than or equal
                                    to idle Port </mat-error>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-6 row" *ngIf="isEdit && storageData.params?.legacyPlatform !== false
                               && storageData.params?.isNewCreated == null">
                        <div class="col-3">
                            Legacy Platform
                        </div>
                        <div class="col-md-6 col-lg-6">
                            <div class="col-md-10 col-lg-10">
                                <div class="form-group form-group--inline">
                                    <label class="radio">
                                        <input type="radio" (change)="legacyPlatformRadioChange($event)" value="true"
                                            name="legacyPlatform" formControlName="legacyPlatform">
                                        <span class="radio__input"></span>
                                        <span class="radio__label">Yes</span>
                                    </label>
                                </div>
                                <div class="form-group form-group--inline">
                                    <label class="radio">
                                        <input type="radio" (change)="legacyPlatformRadioChange($event)" value="false"
                                            name="legacyPlatform" formControlName="legacyPlatform">
                                        <span class="radio__input"></span>
                                        <span class="radio__label">No</span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <div class="row">

            <div class="col-md-6 col-lg-6">



                <div class="section" style="margin-top: -15px;">
                    <div class="col-md-12 col-lg-12">

                        <div class="row">

                            <div class="col-3">
                                MTBF Hours
                            </div>
                            <div class="col-md-6 col-lg-6 padding-set-input">
                                <div class="form-group">
                                    <div class="form-group__text">
                                        <input matInput formControlName="mtbf" (ngModelChange)="validateWatts($event)"
                                            [ngClass]="fabricInterconnetForm.get('mtbf').hasError('pattern') || mtbfErr ? 'text-danger': 'text-normal'">
                                    </div>
                                    <span *ngIf="fabricInterconnetForm.get('mtbf').hasError('pattern')"
                                        style=color:red>Only Positive Integers are allowed </span>
                                    <span *ngIf="mtbfErr" style=color:red>Only Non-Negative Integers are allowed</span>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <div class="section">
                    <div class="col-md-12 col-lg-12">

                        <div class="row">

                            <div class="col-3">
                                End of Sale
                            </div>
                            <div class="col-md-6 col-lg-6">
                                <div class="form-group form-group--inline">
                                    <label class="radio">
                                        <input type="radio" value="1" name="discont" formControlName="discont">
                                        <span class="radio__input"></span>
                                        <span class="radio__label">Yes</span>
                                    </label>
                                </div>
                                <div class="form-group form-group--inline">
                                    <label class="radio">
                                        <input type="radio" value="0" name="discont" formControlName="discont">
                                        <span class="radio__input"></span>
                                        <span class="radio__label">No</span>
                                    </label>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-lg-6" style="
      margin-left: -22px;
  ">

                <div class="row padding_row">
                    <div class="col-md-12 col-lg-12 row">
                        <div class="col-md-3 col-lg-3 ">
                            Image Look up
                        </div>
                        <div class="col-md-6 col-lg-6">
                            <div class="form-group">
                                <input type="file" name="uploadfile" id="importImage" accept="image/*"
                                    style="display:none;" (change)="onImportImage($event)" />
                                <label for="importImage" class="btn btn--secondary import-project-btn">Browse
                                    Image</label>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="row padding_row">
                    <div class="col-md-12 col-lg-12 row" style="height: 170px;">

                        <img [src]="url" style="width: 275px;height: auto;margin: auto;"> <br />

                    </div>
                </div>

            </div>
        </div>

        <button mat-button type="button" (click)="imageUpload('closeModal')" [disabled]="maxExpErr || showWattErr || submitInProgress || !fabricInterconnetForm.valid
            || IdleWattsErr || IdleMaxWattsErr || mtbfErr
            || idlePortErr || maxPortErr || showPortErr || !selectedPid" class="btn btn--small "
            style="height: 24px;margin: 5px 20px 0 0;">Save & close</button>

        <button mat-button type="button" class="btn btn--small " style="height: 24px;margin: 5px 20px 0 0;" [disabled]="maxExpErr || showWattErr || submitInProgress || !fabricInterconnetForm.valid
            || IdleWattsErr || IdleMaxWattsErr || mtbfErr
            || idlePortErr || maxPortErr || showPortErr || !selectedPid" (click)="imageUpload('save')">Save</button>

    </div>
</form>