import { Component, Input, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { Subscription } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';
import { ProjectService } from 'src/app/services/project.service';
import { ToasterService } from 'src/app/shared/components/toast/toast.service';
import { SharedService } from 'src/app/shared/shared.service';
import { errorCodes } from 'src/constants/errorCodes';

@Component({
  selector: 'app-series',
  templateUrl: './series.component.html',
  styleUrls: ['./series.component.css']
})
export class SeriesComponent implements OnInit, OnDestroy {
  subscription: Subscription = new Subscription();
  @Input() secondaryClasses = [];
  secondaryClassesUnique = [];
  selectedSeriesId = -1;
  available = true;
  fabricExTemplates = { templates: [] };
  fabricInTemplates = { templates: [] };
  cseriesTemplates = { templates: [] };
  bladeServerTemplates = { templates: [] };
  cartridgeServerTemplates = { templates: [] };
  currentTemplates = { templates: [] };
  seriesMap: any[] = [];
  @BlockUI() blockUI: NgBlockUI;

  constructor(
    private projectService: ProjectService,
    private sharedService: SharedService,
    private cd: ChangeDetectorRef,
    private apiService: ApiService,
    private toaster: ToasterService,
  ) { }

  ngOnInit(): void {
    this.subscription.add(this.projectService.getTemplateFlag().subscribe(data => {
      if (data) {
        this.loadTemplates();
      }
    }))
    this.loadTemplates();
    this.secondaryClassesUnique = this.secondaryClasses.filter((e, i) => this.secondaryClasses.findIndex(a => a.seriesName.trim() === e.seriesName.trim()) === i);
    this.subscription.add(this.projectService.getResetSeriesId().subscribe(data => {
      this.selectedSeriesId = data;
      if (this.secondaryClassesUnique[this.selectedSeriesId]) this.currentTemplates =  (localStorage[`template${this.secondaryClassesUnique[this.selectedSeriesId].type}`]) ? JSON.parse(localStorage.getItem(`template${this.secondaryClassesUnique[this.selectedSeriesId].type}`)) : { templates: []};
    }));
    this.apiService.getMethod(`admin/getseries`).subscribe(response => {
      this.seriesMap = response;
    }, (error) => {
      console.error(error);
      let errorMsg = error?.error ? error.error?.error?.errorString : "Oops something went wrong!!";
      this.toaster.show('error', errorMsg, error ? error?.message : '');
      this.blockUI.stop(); 
    });
    this.sharedService.getSearchQuery().subscribe((searchQuery) => {
      if (this.selectedSeriesId !== -1) this.filterSearch(searchQuery);
    });
  }
  filterSearch(searchQuery) {
    if (searchQuery !== null && this.secondaryClasses[this.selectedSeriesId]) {
      if (this.secondaryClassesUnique[this.selectedSeriesId].seriesName == searchQuery.series) {
        if (searchQuery.query == '') this.secondaryClassesUnique[this.selectedSeriesId].seriesValues = this.secondaryClasses[this.selectedSeriesId].seriesValues;
        else this.secondaryClassesUnique[this.selectedSeriesId].seriesValues = this.secondaryClasses[this.selectedSeriesId].seriesValues.filter(s => s.name.toLocaleLowerCase().includes(searchQuery.query.toLocaleLowerCase()));
      }
    } else {
      this.secondaryClassesUnique = this.secondaryClasses.filter((e, i) => this.secondaryClasses.findIndex(a => a.seriesName.trim() === e.seriesName.trim()) === i); 
    }
  }
  loadTemplates() {
    this.fabricInTemplates = (localStorage['templateFI']) ? JSON.parse(localStorage.getItem('templateFI')) : this.fabricInTemplates;
    this.cseriesTemplates = (localStorage['templateRACK']) ? JSON.parse(localStorage.getItem('templateRACK')) : this.cseriesTemplates;
    this.bladeServerTemplates = (localStorage['templateBLADE']) ? JSON.parse(localStorage.getItem('templateBLADE')) : this.bladeServerTemplates;
    this.cartridgeServerTemplates = (localStorage['templateCARTRIDGE']) ? JSON.parse(localStorage.getItem('templateCARTRIDGE')) : this.cartridgeServerTemplates;
    this.fabricExTemplates = (localStorage['templateNEXUS']) ? JSON.parse(localStorage.getItem('templateNEXUS')) : this.fabricExTemplates; // NEXUS is type for fabric Extender   
    this.cd.detectChanges();
  }

  onSeriesSelect(index: number) {
    if (this.selectedSeriesId == index) {
      this.selectedSeriesId = -1;
      this.projectService.setSeriesData({});
    } else {
      this.selectedSeriesId = index;
      this.projectService.setSeriesData(this.secondaryClassesUnique[this.selectedSeriesId]);
      this.currentTemplates =  this.secondaryClassesUnique[this.selectedSeriesId] && (localStorage[`template${this.secondaryClassesUnique[this.selectedSeriesId].type}`]) ? JSON.parse(localStorage.getItem(`template${this.secondaryClassesUnique[this.selectedSeriesId].type}`)) : { templates: [] };
      this.projectService.setResetSeriesId(this.selectedSeriesId);
    }
    this.sharedService.setSearchQuery(null);
  }
  refreshSeriesData(idx: number) {
    let currentClass = this.secondaryClassesUnique[idx];
    let seriesName = currentClass.seriesName;
    let [series, primaryClass] = this.getSeriesAndPrimaryClass(seriesName);
    this.currentTemplates =  this.secondaryClassesUnique[this.selectedSeriesId] && (localStorage[`template${currentClass.type}`]) ? JSON.parse(localStorage.getItem(`template${currentClass.type}`)) : { templates: [] };
    let url = `refreshApi/${primaryClass}/${series}`;
    this.blockUI.start('Loading...');
    this.apiService.getMethod(url).subscribe(response => {
      let secondaryClassesLatest = response.secondaryClasses;
      secondaryClassesLatest = secondaryClassesLatest.filter((e, i) => secondaryClassesLatest.findIndex(a => a.seriesName === e.seriesName) === i);
      secondaryClassesLatest.forEach(s => s.displayName = s.seriesName);
      this.secondaryClassesUnique[idx] = secondaryClassesLatest[0];
      this.secondaryClasses[idx] = secondaryClassesLatest[0];
      this.projectService.setSecondaryClasses(this.secondaryClassesUnique);
      this.blockUI.stop();
    }, (error) => {
      console.error(error);
      let errorMsg = error?.error ? error.error?.error?.errorString : errorCodes.GENERIC_ERROR_MESSAGE;
      this.toaster.show('error', errorMsg, error ? error?.message : '');
      this.blockUI.stop(); 
    })
  }
  getSeriesAndPrimaryClass(seriesName: string) {
    let currentSeries = this.seriesMap.find(s => s.seriesDisplayName == seriesName);
    return [currentSeries.series, currentSeries.primaryClass];
  }

  onDiscontinuedClick() {
    this.available = false;
    this.refreshCurrentTemplates();
  }

  onAvailableClick() {
    this.available = true;
    this.refreshCurrentTemplates();
  }
  refreshCurrentTemplates() {
    if (this.secondaryClassesUnique[this.selectedSeriesId]) this.currentTemplates =  (localStorage[`template${this.secondaryClassesUnique[this.selectedSeriesId].type}`]) ? JSON.parse(localStorage.getItem(`template${this.secondaryClassesUnique[this.selectedSeriesId].type}`)) : { templates: []};
  }
  doesTemplatesExistForAvailableStatus() : boolean {
    return this.currentTemplates.templates.filter((value) => {      
      let avb = value.discont == 0 ? true : false;
      return avb == this.available;
    }).length > 0;
  }
  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

}
