import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AdminService } from 'src/app/services/admin.service';


@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})
export class AdminComponent implements OnInit {

  searchQuery: string = '';
  constructor(
    private router: Router,
    private adminService: AdminService
    ) { }

  ngOnInit(): void {
    localStorage.setItem('UCS.EulaAcceptanceStatus', 'true');
    this.adminService.getclearQuery().subscribe(data=>{
      this.searchQuery = "";
    })
  }
  searchForItem() {
    
    // route to the search results page
    this.router.navigate(['admin/admin-tab/', 'searchResults']);
    // save to a service later, after integration
    this.adminService.setSearchQuery(this.searchQuery);
  }

}
