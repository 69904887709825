<div class="edit">
    <div class="flex flex-between base-margin-bottom" style="max-width: 920px;">
        <h4>Edit Series Names</h4>
    </div>

    <div class="row base-margin-bottom" *ngFor="let series of seriesNames; let i = index;">
        
        <div class="col-1" style="margin-left: 30px; margin-top: 15px;">
            
            <div class="row base-margin-row" style="font-size: larger;">
               {{ i + 1 }}. 
            </div>
        </div>
        <div class="col-4" style="display: flex; margin-left: -50px;">
            <div class="form-group base-margin-row" style="flex:auto;">
                <div class="form-group__text" style="flex:auto;">
                    <input 
                    [ngClass]="seriesDisplayNames[i].trim() == '' || seriesDisplayNames[i]==null ? 'text-danger': (isEdit[i]==true ? 'text-normal' : 'text-disabled')" 
                    [(ngModel)]="seriesDisplayNames[i]" 
                    type="text" rows="3" style="flex:auto"
                    [disabled]="isEdit.length == 0 ? true : isEdit[i]==false"
                    (change)="onChangeSeriesDisplayName($event, i)"
                    style="margin-top: 10px; font-size: larger;"
                    >
                </div>
            </div>
        </div>
        <div class="col-3" style="display: flex;">
            <div class="form-group base-margin-row" style="flex:auto;">
                <div class="form-group__text" style="flex:auto;">
                    <input 
                    [(ngModel)]="seriesNames[i]" 
                    type="text" rows="3" style="flex:auto"
                    [disabled]="true"
                    style="margin-top: 10px; font-size: larger;"
                    >
                </div>
            </div>
        </div>
        <div class="col-1 middle">
            <div (click)="onClickEdit($event, i)"
            class="icon-edit icon-size-20 man-comp-icon" style="margin: 0px 5px;color:#00bceb">
        </div> 
        </div> 
    </div>

</div>