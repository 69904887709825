import { Component, OnDestroy, OnInit, ElementRef, HostListener } from '@angular/core';
import { Subscription } from 'rxjs';
import { AdminService } from 'src/app/services/admin.service';
import { ApiService } from 'src/app/services/api.service';
import { ToasterService } from 'src/app/shared/components/toast/toast.service';
import { errorCodes } from 'src/constants/errorCodes';
import { UrlConstant } from 'src/constants/url.constants';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UpdateNameService } from 'src/app/services/update-name.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { PlatformConstant } from 'src/constants/platform.constants';
import { CreateNewPlatformComponent } from 'src/app/components/admin/admin-tab/create-platforms/create-new-platform/create-new-platform.component';
import { CngModalService, CngContentConfigType, CngModalSize, } from "@cisco/cui-ng";
import { AddManageCompComponent } from 'src/app/shared/components/add-manage-comp/add-manage-comp.component';
import { TranslateService } from '@ngx-translate/core';
import { SharedService } from 'src/app/shared/shared.service';
import { CreateManageCompComponent } from './create-manage-comp/create-manage-comp.component';


@Component({
  selector: 'app-admin-tab',
  templateUrl: './admin-tab.component.html',
  styleUrls: ['./admin-tab.component.css']
})
export class AdminTabComponent implements OnInit, OnDestroy {
  subscriptions: Subscription = new Subscription();
  manageComponentsLPanelDataUrl: string = 'admin/manageComponentsLPanelData';
  manageComponentsPanelData: any[] = [];
  isEdit: boolean[] = [];
  currentDisplayName: string = '';

  managePlatformPanelData: any[] = [];
  isEditPlatform: boolean[] = [];
  displayNameRef: string[] = [];
  displayNameRef_platform: string[] = [];

  @BlockUI() blockUI: NgBlockUI;

  constructor(
    private adminService: AdminService,
    private apiService: ApiService,
    private toaster: ToasterService,
    public updateNameService: UpdateNameService,
    private translate: TranslateService,
    private modalService: CngModalService,
    private sharedService: SharedService,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.getPlatformPlanelDatas();
    this.getAllSeries();
    this.subscriptions.add(
      this.apiService.getMethod(this.manageComponentsLPanelDataUrl).subscribe((response) => {
        // 
        response = response.sort(function (a, b) { return Number(a.position) - Number(b.position) });

        for (let i = 0; i < response.length; i++) {
          let manageComponent = response[i];
          let routeLink = "";
          let position = Number(manageComponent.position);
          if (manageComponent.internalName == null) continue;
          if (manageComponent.additionalComponent == false) {
            let routeSplit = manageComponent.internalName.split(' ');
            for (let i = 0; i < routeSplit.length; i++) {
              routeLink += routeSplit[i].charAt(0) + routeSplit[i].substr(1).toLowerCase();
            };
            routeLink = routeLink.charAt(0).toLowerCase() + routeLink.substr(1);
            this.manageComponentsPanelData.push({
              "displayName": manageComponent.displayName,
              "routeLink": routeLink,
              "internalName": manageComponent.internalName,
            });

          } else {
            this.manageComponentsPanelData.push({
              "displayName": manageComponent.displayName,
              "routeLink": "additionalComponent" + (position - 8).toString(),
              "internalName": manageComponent.internalName,
            });
          }
          this.displayNameRef.push(manageComponent.displayName);
        }

        this.isEdit.fill(false, this.manageComponentsPanelData.length);
      })
    );
  }
  getAllSeries() {

    const url_series = UrlConstant.GET_ALL_SERIES;
    this.subscriptions.add(
      this.apiService.getMethod(url_series).subscribe((data) => {

        this.adminService.setSeriesAll(data);
      }))
  }
  getPlatformPlanelDatas() {

    const url_platform = UrlConstant.MANAGE_PLATFORMS_PANEL_DATA;
    this.subscriptions.add(
      this.apiService.getMethod(url_platform).subscribe((response) => {


        this.updateNameService.setplatformPanelData(response);//add to obervsble

        this.platformRawData = response;
        let paneldata = [];
        response.map((managePlatform) => {
          let routeLink;
          if (managePlatform.additionalPlatform) {
            routeLink = "manageAdditionalPlatform" + (managePlatform.position - 7)
          }
          else {
            routeLink = "manage" + managePlatform.internalName.replace(/\s/g, '');
          }
          paneldata.push({
            "displayName": managePlatform.displayName,
            "routeLink": routeLink,
          });
          this.displayNameRef_platform.push(managePlatform.displayName);
          this.isEditPlatform.fill(false, 0, paneldata.length);
        });
        this.managePlatformPanelData = paneldata;


      }, error => {
        let errorMsg = error?.error ? error.error?.error?.errorString : errorCodes.GENERIC_ERROR_MESSAGE;
        this.toaster.show('error', errorMsg, error ? error?.message : '');
      }));
  }

  platformRawData: any = []
  onClick() {
    this.subscriptions.add(this.adminService.setStorageData(null));
    this.subscriptions.add(this.adminService.setData(null));
    this.subscriptions.add(this.adminService.setPowerSupplyData(null));
    this.subscriptions.add(this.adminService.setProcessorFamilyData(null));
    this.subscriptions.add(this.adminService.setProcessorData(null));
    this.subscriptions.add(this.adminService.setDimmFamilyData(null));
    this.subscriptions.add(this.adminService.setDimmData(null));
    this.subscriptions.add(this.adminService.setIOnExpansionData(null));
    this.subscriptions.add(this.adminService.setPlatformData(null));

  }

  onBlurDisplayName($event, idx: number) {

    if ($event.target.value == '') {
      this.toaster.show("error", errorCodes.COMPONENT_DISPLAYNAME_EMPTY, '', 5000);
    } else {
      this.isEdit[idx] = false;
    }
  }
  async onChangeDisplayName($event, idx: number) {
    let changedName = $event.target.value;

    if (changedName != '') this.isEdit[idx] = false;
    if (changedName.length > 30) {
      this.manageComponentsPanelData[idx].displayName = this.displayNameRef[idx];
      this.toaster.show("error", errorCodes.COMPONENT_DISPLAYNAME_TOOLONG_DEV, '', 5000);
    } else {
      // update the other components as well through subscription
      this.blockUI.start('Loading...');
      let res;
      res = await this.updateNameService.updateAllNames(changedName, idx)

      if (typeof res === "boolean") {
        this.updateNameService.getCurrentName().subscribe((data) => {
          this.displayNameRef = data.map(d => d.currentName);
          for (let i = 0; i < data.length; i++) {
            this.manageComponentsPanelData[i].displayName = data[i];
          }
        })
      }
      this.blockUI.stop()


    }
    // this.updateNameService.updateAllNames(changedName, idx); 

  }

  onClickEdit($event, idx: number) {

    // if was already on edit .. then if not empty and clicked okay , update, edit = false
    for (let i = 0; i < this.isEdit.length; i++) {
      if (i !== idx) this.isEdit[i] = false;
    }
    if (this.isEdit[idx] == true) {
      if (this.manageComponentsPanelData[idx].displayName === "") {
        this.isEdit[idx] = true;
      } else {
        this.isEdit[idx] = false;
      }
    } else {
      this.isEdit[idx] = true;
    }
  }
  onClickAddComponent($event) {

    // this.openModal();
    this.openDialog();
  }

  public async openModal() {
    const result = await this.modalService
      .open({
        content: {
          type: CngContentConfigType.COMPONENT,
          content: AddManageCompComponent,
          componentData: {
            titleMessage: this.translate.instant("Add New Component"),
            message: this.translate.instant("Enter Component Name"),
            key: 'add',
          },
        },
        size: CngModalSize.SMALL,
      })
      .onDismiss.toPromise();
    this.addComponent();
  }

  openDialog() {
    const dialogRef = this.dialog.open(CreateManageCompComponent, {
      data: {
        header: "Create Additional Component"
      }
    });

    dialogRef.afterClosed().subscribe(result => {


      try {
        if (result !== PlatformConstant.ACTION_CANCEL) {

          this.addComponent();
        }

      } catch (error) {

      }
    });
  }

  addComponent() {

    let currentNames = this.updateNameService.currentNames;
    let currentIdx = currentNames.length;
    this.manageComponentsPanelData.push({
      "displayName": currentNames[currentIdx - 1],
      "routeLink": "additionalComponent" + (currentIdx - 8).toString()
    });
  }

  onClickEditPlatform($event, id: number) {

    // if was already on edit .. then if not empty and clicked okay , update, edit = false
    for (let i = 0; i < this.isEditPlatform.length; i++) {
      if (i !== id) this.isEditPlatform[i] = false;
    }
    if (this.isEditPlatform[id] == true) {
      if (this.managePlatformPanelData[id].displayName === "") {
        this.isEditPlatform[id] = true;
      } else {
        this.isEditPlatform[id] = false;
      }
    } else {
      this.isEditPlatform[id] = true;
    }
  }

  onChangePlatformDisplayName($event, id: number) {

    let changedName = $event.target.value;
    if ($event.target.value != '') this.isEditPlatform[id] = false;
    if (changedName != '') this.isEditPlatform[id] = false;

    if (changedName.length > 30) {
      this.managePlatformPanelData[id].displayName = this.displayNameRef_platform[id];
      this.toaster.show("error", errorCodes.COMPONENT_DISPLAYNAME_TOOLONG_DEV, '', 5000);
    } else {
      this.displayNameRef[id] = changedName;

      // update the other components as well through subscription
      this.blockUI.start('Loading new name!');
      const url_platform = UrlConstant.MANAGE_PLATFORMS_PANEL_DATA;
      let updatedNameData = this.platformRawData[id];
      updatedNameData.displayName = changedName;

      this.subscriptions.add(this.apiService.putMethod(url_platform, updatedNameData)
        .subscribe((response) => {

          this.toaster.show("success", errorCodes.PLATFORM_NAME_UPDATED, '', 5000);
          this.blockUI.stop();
          if (response.modifiedCount > 0) {
            this.getPlatformPlanelDatas();
          }
        }, error => {
          this.blockUI.stop();
          let errorMsg = error?.error ? error.error?.error?.errorString : errorCodes.GENERIC_ERROR_MESSAGE;
          this.toaster.show('error', errorMsg, error ? error?.message : '');
        })
      );
    }

  }
  onBlurPlatformDisplayName($event, id: number) {

    if ($event.target.value == '') {
      this.toaster.show("error", errorCodes.PLATFORM_DISPLAYNAME_EMPTY, '', 5000);
    }
    else {
      this.isEditPlatform[id] = false;
      this.onChangePlatformDisplayName($event, id);
    }
  }

  ngOnDestroy() {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }

  openModal_platform() {
    const dialogRef = this.dialog.open(CreateNewPlatformComponent, {
      data: {
        index: this.platformRawData.length,
        platform_data: this.platformRawData
      },
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe(result => {

      if (result.data?.action == PlatformConstant.ACTION_REFRESH) {
        this.getPlatformPlanelDatas();
        this.getAllSeries();
      }
    });
  }

}
