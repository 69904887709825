import { Component, Inject, OnInit, QueryList, TemplateRef, ViewChildren } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { BlockUI, NgBlockUI } from "ng-block-ui";
import { Subscription } from "rxjs";
import { ApiService } from "src/app/services/api.service";
import { ToasterService } from "src/app/shared/components/toast/toast.service";
import { errorCodes } from "src/constants/errorCodes";
import { PlatformConstant } from "src/constants/platform.constants";
import { UrlConstant } from "src/constants/url.constants";
import { UpdateNameService } from "src/app/services/update-name.service";
import { AdminService } from "src/app/services/admin.service";
import { Router } from "@angular/router";
import { AppConstant } from "src/constants/app.constants";

@Component({
  selector: "app-create-new-platform",
  templateUrl: "./create-new-platform.component.html",
  styleUrls: ["./create-new-platform.component.css"],
})
export class CreateNewPlatformComponent implements OnInit {
  constructor(
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public storageData: any,
    public dialogRef: MatDialogRef<CreateNewPlatformComponent>,
    private formBuilder: UntypedFormBuilder,
    private apiService: ApiService,
    private toaster: ToasterService,
    private updateNameService: UpdateNameService,
    private adminService: AdminService,
    private router: Router,
  ) { }

  subscriptions: Subscription = new Subscription();
  newPlatformForm: UntypedFormGroup;
  @BlockUI() blockUI: NgBlockUI;
  onCancel() {
    this.dialogRef.close({
      data: {
        action: PlatformConstant.ACTION_CANCEL,
      },
    });
  }
  dropdownList = [];
  selectedItems = [];
  // required attributes for additional platform
  staticList = [
    "vendor",
    "name",
    "discont",
    "basePid",
    "dimmSlots",
    "cpuSockets",
    "idlePower",
    "maxPower",
    "maxPsus",
    "height",
    "weight",
  ];
  selectedItems2 = [];
  dropdownSettings = {};
  seriesList = [];
  selectedSeries: any;
  ngOnInit() {
    this.getDynamicPlatformAttributes();

    this.subscriptions.add(
      this.adminService.getSeriesAll().subscribe((data) => {
        let addedSerieses = [];
        data?.forEach((element) => {
          addedSerieses.push({
            value: element.series,
            viewValue: element.seriesDisplayName,
          });
        });
        addedSerieses.push({ value: "new", viewValue: "--Add New Series--" });
        this.seriesList = addedSerieses;
      })
    );

    this.dropdownList = [
      { id: 1, itemName: "platformType", validation: false },
      { id: 2, itemName: "platformName", validation: false },
      { id: 3, itemName: "displayName", validation: true },
      { id: 4, itemName: "PID", validation: true },
      { id: 5, itemName: "idlePower", validation: true },
      { id: 6, itemName: "MaxPower", validation: true },
      { id: 7, itemName: "processorSupport", validation: true },
      { id: 8, itemName: "sharedResource", validation: false },
      { id: 9, itemName: "node", validation: true },
    ];
    this.selectedItems2 = [];
    this.dropdownSettings = {
      singleSelection: false,
      text: "Select",
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      enableSearchFilter: true,
      classes: "myclass custom-class",
      badgeShowLimit: 1,
      maxHeight: 150,
      position: "bottom",
      autoPosition: false,
      enableCheckAll: false,
      enableFilterSelectAll: false,
    };
    this.newPlatformForm = this.formBuilder.group({
      primaryClass: new UntypedFormControl("", [Validators.required]),
      platformName: new UntypedFormControl("", [Validators.required, Validators.pattern(AppConstant.VALID_INPUT_REGEX)]),
      visibleAttribute: new UntypedFormControl([]),
      validationAttribute: new UntypedFormControl("", []),
      series: new UntypedFormControl(""),
      newSeries: new UntypedFormControl("", [Validators.required, Validators.pattern(AppConstant.VALID_INPUT_REGEX)]),
      isFanPolicyReq: new UntypedFormControl("", [Validators.required]),
      /** check whether additional platform has child [required] */
      // isChild: new FormControl("", [Validators.required]),
    });
    this.newPlatformForm.get("validationAttribute").patchValue([]);
    this.newPlatformForm.patchValue({ primaryClass: "Compute" });
    this.newPlatformForm.patchValue({ isFanPolicyReq: "false" });
    this.newPlatformForm.patchValue({ isChild: "false" });
  }

  submitPlatform() {
    if (this.newPlatformForm.valid) {
      let visible_attr = [];
      let valid_attr = [];
      this.newPlatformForm.value.visibleAttribute.forEach((element) => {
        visible_attr.push(element.itemName);
      });
      this.dropdownList.forEach((element) => {
        if (element.validation) valid_attr.push(element.itemName);
      });
      this.showNewSeriesName = true;
      const payload = {
        managePlatforms: {
          primaryClass: this.newPlatformForm.value.primaryClass,
          platformType: "AdditionalPlatform" + (this.storageData.index + 1 - 7),
          displayName: this.newPlatformForm.value.platformName,
          position: this.storageData.index + 1,
          displayType: "AdditionalPlatform" + (this.storageData.index + 1 - 7),

          additionalPlatform: true,
          level: "Parent",
          Parent: null,
          collectionName: "platformConfig",
          componentClass: "UcsPlatform",

          isFanPolicyReq: this.newPlatformForm.value.isFanPolicyReq,

          internalName: this.newPlatformForm.value.platformName,
          series: this.showNewSeriesName
            ? this.newPlatformForm.value.newSeries
            : this.newPlatformForm.value.series.value,
          seriesDisplayName: this.showNewSeriesName
            ? this.newPlatformForm.value.newSeries
            : this.newPlatformForm.value.series.viewValue,
          dynamicPlatformAttributes: this.selectedItems,
        },
        visibleAttrs: visible_attr,
        validationAttrs: valid_attr,
        type: "AdditionalPlatform" + (this.storageData.index + 1 - 7),
        hasChildren: false,
      };

      const url = "admin/managePlatformsLPanelData";

      this.blockUI.start("Loading...");
      this.subscriptions.add(
        this.apiService.postMethod(url, payload).subscribe(
          (response) => {
            this.toaster.show("success", errorCodes.NEW_PLATFORM, "", 5000);
            this.blockUI.stop();
            this.dialogRef.close({
              data: {
                action:
                  response.modifiedCount > 0
                    ? PlatformConstant.ACTION_REFRESH
                    : PlatformConstant.ACTION_CANCEL,
              },
            });
            let isPlatformDataUpdated: boolean = false;
            this.updateNameService.getplatformPanelData().subscribe(data => {
              if (!isPlatformDataUpdated) {
                isPlatformDataUpdated = true;
                let newPlatform = payload.managePlatforms;
                data.push(newPlatform);
                this.updateNameService.setplatformPanelData(data);
                /** re-direct to platform page after creation */
                this.router.navigate([`admin/admin-tab/manageAdditionalPlatform${this.storageData.index + 1 - 7}`]);
              }
            })
          },
          (error) => {
            this.blockUI.stop();
            let errorMsg = error?.error
              ? error.error?.error?.errorString
              : errorCodes.GENERIC_ERROR_MESSAGE;
            this.toaster.show("error", errorMsg, error ? error?.message : "");
          }
        )
      );
    }
  }

  onItemSelect(item: any) { }
  OnItemDeSelect(item: any) { }
  onSelectAll(items: any) { }
  onDeSelectAll(items: any) { }

  duplicatName: boolean = false;
  duplicatType: boolean = false;

  checkforDuplicateNames() {
    this.duplicatName = false;
    this.duplicatType = false;

    var name = this.storageData.platform_data.filter((x) => {
      return (
        x.displayName.toLowerCase() ==
        this.newPlatformForm.value.platformName.toLowerCase() ||
        x.internalName.toLowerCase() ==
        this.newPlatformForm.value.platformName.toLowerCase()
      );
    });

    if (name?.length > 0) {
      this.duplicatName = true;
    }
  }
  showNewSeriesName = false;
  changeDropdown(event: any) {
    if (event.value.value == "new") {
      this.showNewSeriesName = true;

      this.newPlatformForm.patchValue({ newSeries: "" });
      this.newPlatformForm.controls["newSeries"].setValidators([
        Validators.required,
      ]);
      this.newPlatformForm.controls["newSeries"].enable();
    } else {
      this.showNewSeriesName = false;
      this.newPlatformForm.controls["newSeries"].clearValidators();
      this.newPlatformForm.controls["newSeries"].reset({
        value: "",
        disabled: true,
      });
    }
    this.newPlatformForm.controls["newSeries"].updateValueAndValidity();
  }
  config = {
    displayKey: "viewValue",
    search: true,
    height: "150px",
    placeholder: "Select Series",
    moreText: "more",
    noResultsFound: "No results found!",
    searchPlaceholder: "Search",
    searchOnKey: "viewValue",
    clearOnSelection: false,
    inputDirection: "ltr",
  };

  ngOnDestroy() {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }

  getDynamicPlatformAttributes() {
    const url =
      UrlConstant.DYNAMIC_COMPONENT_ATTRIBUTES + "?type=dynamicPlatform";
    this.subscriptions.add(
      this.apiService.getMethod(url).subscribe((response) => {
        let platformAttributes = [];
        response.forEach((element, index) => {
          element.attribute.forEach((element, index) => {
            let attributeObj = {
              id: index + 1,
              name: element.name,
              key: element.key,
              type: element.type,
              required: element.required,
              visible: element.visible,
            };
            platformAttributes.push(attributeObj);
          });
        });
        this.dropdownList = platformAttributes;
        this.selectedItems = this.dropdownList.filter(d => this.staticList.includes(d.key));
        // this.selectedItems.forEach(s => s.disabled = true);
      })
    );
  }

  selectedAttributesList = [];
  onAttributeSelect(item: any) {
    this.selectedAttributesList.push(item);
  }

  OnAttributeDeSelect(item: any) {
    const index = this.selectedAttributesList.indexOf(item);
    // cannot deselect a required attribute
    if (this.staticList.includes(item.key)) {
      if (!this.selectedItems.map(s => s.key).includes(item.key)) {
        this.selectedItems.push(item);
      }
      return;
    }
    if (index > -1) {
      // only splice array when item is found
      this.selectedAttributesList.splice(index, 1); // 2nd parameter means remove one item only
    }
  }
  checkSeriesUnique() {
    let value = this.newPlatformForm.get('newSeries').value.toLocaleLowerCase().trim();
    if (this.seriesList.map(s => s.viewValue.toLocaleLowerCase().trim()).includes(value)) return false;
    if (this.seriesList.map(s => s.value.toLocaleLowerCase().trim()).includes(value)) return false;
    return true;
  }
}
