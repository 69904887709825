<div class="mat-dialog-content" style="min-height: 250px;">
  <div class="flex flex-between" style="float: right;">
    <div>
      <div class="flex-center margin_auto_t_b">
        <a class="anchor-close" id="close" title="Close" (click)="close()">
          <span class="icon-close icon-size-24 icon-close"></span>
        </a>
      </div>
    </div>
  </div>
  <div class="container-fluid">
    <h4>Additional Fields</h4>
    <div class="section">
      <div class="col-md-12 col-lg-12 row">

        <div class="col-md-11 col-lg-11">
          <span *ngIf="additional_fields?.length == 0">No Additional Fields</span>

          <div class="row padding_row" *ngFor="let field of additional_fields,let i = index" style="    margin: 5px 0 5px 0;
                ">
            <div class="col-md-4 col-lg-4">
              <div class="form-group" style="width: 100%;margin: 0 10px;">
                <div class="form-group__text">
                  <label for="select-state-default">Apply To</label>
                  <angular2-multiselect [data]="dropdownList" [(ngModel)]="field.selectedItems"
                    [settings]="dropdownSettings" (onSelect)="onItemSelect($event,field)"
                    (onDeSelect)="OnItemDeSelect($event,field)" (onSelectAll)="onSelectAll($event,field)"
                    (onDeSelectAll)="onDeSelectAll($event,field)">
                    <c-item>
                      <ng-template let-item="item">
                        <label style="color: #333;min-width: 150px;">{{item.itemName}}</label>
                      </ng-template>
                    </c-item>
                  </angular2-multiselect>
                </div>
              </div>
            </div>
            <div class="col-md-3 col-lg-3">

              <div class="form-group" style="width: 100%;">
                <div class="form-group__text">
                  <label for="select-state-default">Name</label>
                  <input matInput autocomplete="off" [(ngModel)]="field.name" (ngModelChange)="validate();"
                    [ngClass]="field.name ==null || field.name?.trim() =='' || duplicateNames.includes(field.name) || existingNames.includes(field.name)  ? 'text-danger': 'text-normal'">
                </div>
              </div>
              <div *ngIf="duplicateNames.includes(field.name)" style="color:red">Duplicate Field Name</div>
              <div *ngIf="existingNames.includes(field.name)" style="color:red">Field Name Already Exists</div>
            </div>
            <div class="col-md-2 col-lg-2">

              <div class="form-group" style="width: 100%;" *ngIf="(additional_fields.length<=2 && i<2) ||
                          (additional_fields.length>2 && i< additional_fields.length - 2)">
                <div class="form-group__text">
                  <label for="select-state-default">Type</label>
                  <ng-select appearance="outline" dropdownPosition="bottom" [(ngModel)]="field.type"
                    [searchable]="false" class="select-small" (change)="typeChanged(field)"
                    [ngClass]="field.type ==null || field.type==''? 'select-danger': 'select-normal'">
                    <ng-option *ngFor="let state of type" [value]="state.value">
                      {{state.viewValue}}
                    </ng-option>
                  </ng-select>
                </div>
              </div>

              <div class="form-group" style="width: 100%;"
                *ngIf="additional_fields.length>2 && i>=additional_fields.length - 2">
                <div class="form-group__text">
                  <label for="select-state-default">Type</label>
                  <ng-select appearance="outline" dropdownPosition="top" [(ngModel)]="field.type" [searchable]="false"
                    class="select-small select-normal" (change)="typeChanged(field)"
                    [ngClass]="field.type ==null || field.type==''? 'select-danger': 'select-normal'">
                    <ng-option *ngFor="let state of type" [value]="state.value">
                      {{state.viewValue}}
                    </ng-option>
                  </ng-select>
                </div>
              </div>
            </div>

            <div class="col-md-2 col-lg-2">

              <div class="form-group" style="width: 100%;" *ngIf="field.type == 'text'">
                <div class="form-group__text">
                  <label for="select-state-default">Value</label>

                  <input type="text" autocomplete="off" [(ngModel)]="field.value" (ngModelChange)="validate();"
                    [ngClass]="field.value ==null || field.value?.trim() ==''  ? 'text-danger': 'text-normal'">
                </div>
              </div>
              <div class="form-group" *ngIf="field.type == 'number'">
                <div class="form-group__text">
                  <label for="select-state-default">Value</label>
                  <input type="number" autocomplete="off" [(ngModel)]="field.value" (ngModelChange)="validate();"
                    min="0"
                    [ngClass]="field.value == null || field.value < 0 || field.value == 0 ? 'text-danger': 'text-normal'">
                </div>
                <div *ngIf="field.value < 0 || field.value == 0" style="color:red">Only Positive Integers are allowed
                </div>

              </div>
              <div class="form-group" style="width: 100%;" *ngIf="field.type == 'boolean' 
                   && ((additional_fields.length<=2 && i<2) ||
            (additional_fields.length>2 && i< additional_fields.length - 2))">
                <div class="form-group__text">
                  <label for="select-state-default">Value</label>
                  <ng-select appearance="outline" dropdownPosition="bottom" [(ngModel)]="field.value"
                    [searchable]="false" class="select-small" (change)="validate();"
                    [ngClass]="field.value == null || field.value ==''  ? 'select-danger': 'select-normal'">
                    <ng-option *ngFor="let state of booleanValues" [value]="state.value">
                      {{state.viewValue}}
                    </ng-option>
                  </ng-select>
                </div>
              </div>
              <div class="form-group" style="width: 100%;" *ngIf="field.type == 'boolean' 
                   && (additional_fields.length>2 && i>=additional_fields.length - 2)">
                <div class="form-group__text" style="width: 100%;">
                  <label for="select-state-default">Value</label>
                  <ng-select appearance="outline" dropdownPosition="top" [(ngModel)]="field.value" [searchable]="false"
                    class="select-small" (change)="validate();"
                    [ngClass]="field.value == null || field.value == ''  ? 'select-danger': 'select-normal'">
                    <ng-option *ngFor="let state of booleanValues" [value]="state.value">
                      {{state.viewValue}}
                    </ng-option>
                  </ng-select>
                </div>
              </div>
            </div>
            <div class="col-md-1 col-lg-1" style="padding-top: 28px;">
              <div class="flex-center margin_auto_t_b">
                <a class="anchor-close" id="delete" title="Delete" (click)="delete_row(i)">
                  <span class="icon-delete icon-size-20 icon-delete"></span>
                </a>
              </div>

            </div>
          </div>

        </div>
        <div class="col-md-1 col-lg-1" style="margin-top: 25px;">
          <div class="flex-center margin_auto_t_b">
            <a class="anchor-close" id="delete" title="close" (click)="insert_row()">
              <span class="icon-add icon-size-20 icon-expandable"></span>
            </a>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>
<mat-dialog-actions>
  <button mat-button type="submit" class="btn btn--small " [disabled]="!validForm || duplicate || IsExists"
    style="height: 24px;margin: 5px 20px 0 0;" (click)="onclikOK()">OK</button>
  <button mat-button class="btn btn--small " (click)="close()"
    style="height: 24px;margin: 5px 20px 0 0;">Cancel</button>
</mat-dialog-actions>