<div class="flex flex-between" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle style="margin-left: -25px;margin-bottom: 0px;">
    <button mat-button (click)="closeModal()"  class="btn btn--link focus" >Back</button>
    <div style="    display: flex;
    flex-direction: row-reverse;">
     <div class="flex-center margin_auto_t_b" >
        <a class="anchor-close" id="close" title="Close" (click)="closeModal()">
        <span class="icon-close icon-size-24 icon-close"></span>
        </a>
    </div>
</div>
</div>
<div style="min-height: 400px;max-height: 80vh;overflow: auto;">
<div>
    <h4>Measurements</h4>
</div>
<div class="row col-md-12">
<div class="col-md-4 ">
    <div class="col-md-12 row" *ngIf="params?.platformData.vendor">
        <div class="col-4" >
            Vendor
        </div>
        <div class="col-md-8 col-lg-8">
            :&nbsp;{{params?.platformData.vendor}}
        </div>
     </div>
     <div class="col-md-12 row" *ngIf="params?.platformData.name">
        <div class="col-4" >
            Model
        </div>
        <div class="col-md-8 col-lg-8">
            :&nbsp;{{params?.platformData.name}}
        </div>
     </div>
     <div class="col-md-12 row" *ngIf="params?.platformData.gen">
        <div class="col-4" >
            Generation
        </div>
        <div class="col-md-8 col-lg-8">
            :&nbsp;{{params?.platformData.gen}}
        </div>
     </div>
</div> 
<div class="col-md-4">
    <div class="col-md-12 row">
        <div class="col-4" >
            CPU
        </div>
        <div class="col-md-8 col-lg-8">
            :&nbsp;{{params?.selectedCpu.displayName}}
        </div>
     </div>
     <div class="col-md-12 row">
        <div class="col-4" >
            No of CPUs
        </div>
        <div class="col-md-8 col-lg-8">
            :&nbsp;{{params?.selectedRow.cpuCount}}
        </div>
     </div>
</div>
<div class="col-md-4">
    <div class="col-md-12 row">
        <div class="col-4" >
            Dimm
        </div>
        <div class="col-md-8 col-lg-8">
            :&nbsp;{{params?.selectedDimm.displayName}}
        </div>
     </div>
     <div class="col-md-12 row">
        <div class="col-4" >
            No of Dimms
        </div>
        <div class="col-md-8 col-lg-8">
            :&nbsp;{{params?.selectedRow.dimmCount}}
        </div>
     </div>
</div>
</div>
<br>
<div class="flex" style="padding: 0 10px;flex-direction: row-reverse;">
    <button mat-raised-button  class="btn btn--small " (click)="openNewMeasurement()" style="height: 24px;margin: 0 5px 5px 0;">New Measurement</button>         
</div>
<div style="margin-top: 7px;margin-left: 20px;display: flex;" *ngIf="showLoader == 'show'" class="loading-dots loading-dots--dark">
        <span></span>
        <span></span>
        <span></span>
      </div>
    <div class="col-md-12">
        <div>
            <ag-grid-angular
                class="ag-theme-alpine"
                [rowData]="rowData"
                [gridOptions]="gridOptions"
                [domLayout]="domLayout"
                (gridReady)="onGridReady($event)"
        (firstDataRendered)="headerHeightSetter($event)"
        (columnResized)="headerHeightSetter($event)"  
                >
            </ag-grid-angular>
        </div>
    </div>













</div> 
<!-- <mat-dialog-actions>
    <button mat-button   class="btn btn--small " style="height: 24px;margin: 5px 20px 0 0;">Submit</button>
    <button mat-button [mat-dialog-close]="true"  class="btn btn--small " style="height: 24px;margin: 5px 20px 0 0;">Cancel</button>
</mat-dialog-actions>    -->