import { Injectable } from '@angular/core';
 import { Toast } from './toast.interface';
 import { ToastType } from './toast.type';
import { Observable, BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ToasterService {
  subject: BehaviorSubject<Toast>;
  toast$: Observable<Toast>;

  constructor() {
    this.subject = new BehaviorSubject<Toast>(null);
    this.toast$ = this.subject.asObservable()
      .pipe(filter(toast => toast !== null));
  }

  show(type: ToastType, title?: string, body?: string, delay?: number, markedForDelete?: boolean) {
    this.subject.next({ type, title, body, delay, markedForDelete });
  }

  remove(title?: string){
    this.subject.next({ type: null, title: title , body: null, delay: null, markedForDelete: true});
  }
}
