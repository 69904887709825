export class ComponentConstant {
    public static IOES = "ioes";
	public static VOLTAGES = "voltages";
	public static POWERSUPPLY  = "powerSupply";
	public static STORAGES = "storages";
	public static IOMS = "ioms";
	public static DIMM_FAMILIES = "dimmFamilies";
	public static CPU_FAMILIES = "cpuFamilies";
	public static DIMMS = "dimms";
	public static CPUS = "cpus";
	public static EXPANSIONS = "expansions";
	public static ADMIN = "Admin";
	public static DELETE_FLAG = true;
	public static FAN_POLICY = "FanPolicy"
	public static COMPONENT = "component";
	public static SECURITY = "security";

}