import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { GridOptions } from "ag-grid-community";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ApiService } from 'src/app/services/api.service';
import { UrlConstant } from 'src/constants/url.constants';
import { ComponentConstant } from 'src/constants/components.constants';
import { EditRenderComponent } from 'src/app/shared/components/edit-render/edit-render.component';
import { CheckboxRenderComponent } from 'src/app/shared/components/checkbox-render/checkbox-render.component';
import { CreatePowersupplyComponent } from '../create-components/create-powersupply/create-powersupply.component';
import { ToasterService } from 'src/app/shared/components/toast/toast.service';
import { errorCodes } from 'src/constants/errorCodes';
import { AdminService } from 'src/app/services/admin.service';
import { PlatformConstant } from 'src/constants/platform.constants';
import { ShowFieldsComponent } from 'src/app/shared/components/show-fields/show-fields.component';
import { Router } from '@angular/router';
import { UpdateNameService } from 'src/app/services/update-name.service';

export interface DialogData {

  type: string;
}

@Component({
  selector: 'app-manage-power-supplies',
  templateUrl: './manage-power-supplies.component.html',
  styleUrls: ['./manage-power-supplies.component.css']
})
export class ManagePowerSuppliesComponent implements OnInit {
  gridColumnApi: any;
  subscriptions: Subscription = new Subscription();
  gridApi: any;
  @BlockUI() blockUI: NgBlockUI;
  errorMsg = '';
  columnDefs;
  frameworkComponents;
  defaultColDef;
  rowData: [];
  gridOptions: GridOptions = {};
  powerSupply: string;
  flagBackButton = false;
  componentIdx: number;
  componentName: string;
  componentDisplayName: string = "";

  constructor(
    private apiService: ApiService,
    private httpClient: HttpClient,
    private toaster: ToasterService,
    private adminService: AdminService,
    public dialog: MatDialog,
    private router: Router,
    private updateNameService: UpdateNameService
  ) {

    this.updateNameService.getCurrentName().subscribe((data) => {
      if (data.length > 0) {
        this.componentName = data.filter(compName => compName.internalName.trim() == 'Manage Power Supplies')[0].currentName;
        let compNameSplit = this.componentName.trim().replace("Manage", "").replace("manage", "");
        this.componentDisplayName = compNameSplit;
      }
    });

    this.powerSupply = "base";
    this.getManagePowerSupplyList();

    this.columnDefs = [
      {
        headerName: 'Display Name', field: 'powerSupply.displayName',
        comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
          if (valueA.toUpperCase() == valueB.toUpperCase()) {
            return 0;
          }
          else {
            return (valueA.toUpperCase() > valueB.toUpperCase()) ? 1 : -1;
          }
        }
      },
      {
        headerName: 'Internal Description', field: 'powerSupply.internalDesc',
        comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
          if (valueA.toUpperCase() == valueB.toUpperCase()) {
            return 0;
          }
          else {
            return (valueA.toUpperCase() > valueB.toUpperCase()) ? 1 : -1;
          }
        }
      },
      {
        headerName: 'PID', field: 'powerSupply.pid',
        comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
          if (valueA.toUpperCase() == valueB.toUpperCase()) {
            return 0;
          }
          else {
            return (valueA.toUpperCase() > valueB.toUpperCase()) ? 1 : -1;
          }
        }
      },
      { headerName: 'Capacity (GB)', field: 'powerSupply.capacity' },
      { headerName: 'Voltage', field: 'powerSupply.voltages' },
      { headerName: 'MTBF (Hours)', field: 'powerSupply.mtbf' },
      { headerName: 'Released State', field: 'powerSupply.released', cellRenderer: "checkboxRenderer" },
      { headerName: 'Updated By', field: 'powerSupply.editor' },
      {
        headerName: 'Last Updated', field: 'powerSupply.lastUpdated',
        cellRenderer: (data) => {
          return data.value ? moment(data.value).format('MMM DD, YYYY') : data.data?.powerSupply?.created ? moment(data.data?.powerSupply?.created).format('MMM DD, YYYY') : "";
        }, valueGetter: function (data) {

          return data.data?.powerSupply?.lastUpdated ? moment(data.data?.powerSupply?.lastUpdated).format('MMM DD, YYYY') : data.data?.powerSupply?.created ? moment(data.data?.powerSupply?.created).format('MMM DD, YYYY') : "";

        },
        comparator: function dateComparator(date1, date2) {
          var date1Number = date1 && new Date(date1).getTime();
          var date2Number = date2 && new Date(date2).getTime();




          if (date1Number == null && date2Number == null) {
            return 0;
          }

          if (date1Number == null) {
            return -1;
          } else if (date2Number == null) {
            return 1;
          }

          return date1Number - date2Number;
        }

      },
      {
        headerName: "Configure",
        cellRenderer: "buttonEditRenderer",
        cellRendererParams: {
          onClick: this.onEditButtonClick.bind(this),
          label: "configure",
        },
        filter: false,

      },

      {
        headerName: "Associated Platforms",
        minWidth: 90,
        maxWidth: 100,
        sortable: false,
        filter: false,
        cellRenderer: "buttonEditRenderer",
        cellRendererParams: {
          label: "associatedPlatforms",
          dynamicComponent: false
        },

      },

      {
        headerName: "",
        minWidth: 50,
        maxWidth: 60,
        sortable: false,
        filter: false,
        cellRenderer: "buttonEditRenderer",
        cellRendererParams: {
          onClick: this.onEditButtonClick.bind(this),
          label: "Edit",
          dynamicComponent: false
        },

      },
      {
        headerName: "",
        minWidth: 50,
        maxWidth: 60,
        sortable: false,
        filter: false,
        cellRenderer: "buttonEditRenderer",
        cellRendererParams: {
          onClick: this.onDeleteButtonClick.bind(this),
          label: "Delete",
          dynamicComponent: false
        },

      },

      // { headerName: 'RAID', field:'raid',hide:true},
      // { headerName: 'id', field:'id',hide:true},
      // { headerName: 'sequence', field:'sequence',hide:true},
      // { headerName: 'type', field:'type',hide:true},
      // { headerName: 'name', field:'name',hide:true},
      // { headerName: 'index' , field:'index',hide:true},
      // { headerName: 'voltage', field:'voltage',hide:true},
      // { headerName: 'voltageType', field:'voltageType',hide:true},
      // { headerName: 'ts', field:'ts',hide:true},
      // { headerName: 'created', field:'created',hide:true},
      // { headerName: 'error' , field:'error',hide:true},
      // { headerName: 'successMessage' , field:'successMessage',hide:true}
    ];
    this.defaultColDef = {
      editable: false,
      sortable: true,
      flex: 1,
      minWidth: 100,
      filter: true,
      resizable: true,
      headerComponentParams: {
        template:
          '<div class="ag-cell-label-container" role="presentation">' +
          '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
          '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
          '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
          '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
          '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
          '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
          '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
          '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
          '  </div>' +
          '</div>',
      },
      filterParams: { newRowsAction: 'keep' },
    };
    this.frameworkComponents = {
      buttonEditRenderer: EditRenderComponent,
      checkboxRenderer: CheckboxRenderComponent
    };
  }
  paginationPageSize = 10;

  ngOnInit(): void {
    this.adminService.getPowerSupplyData().subscribe(data => {
      if (data) {
        this.flagBackButton = true;
        this.getDuplicateRecords(data);
      }
    });
  }

  getDuplicateRecords(data: any) {
    const filter = {
      'powerSupply.displayName': {
        filterType: 'text',
        type: 'equals',
        filter: data.displayName
      }, 'powerSupply.pid': {
        filterType: 'text',
        type: 'equals',
        filter: data.pid
      }
    }
    this.gridApi.setFilterModel(filter)
  }
  // showGrid:boolean = false;
  getManagePowerSupplyList() {
    let powerSupplies = UrlConstant.POWER_SUPPLY;
    this.blockUI.start('Loading...');
    // this.showGrid = false;
    const url = UrlConstant.MANAGECOMPONENTS + powerSupplies;
    this.subscriptions.add(
      this.apiService.getMethod(url).subscribe((data: any) => {
        let powerSupplyVoltages = "";
        let powerSupplyCurveArray = [];
        //TA54089
        const url = UrlConstant.MANAGECOMPONENTS + '/?component=' + ComponentConstant.VOLTAGES;
        this.subscriptions.add(
          this.apiService.getMethod(url).subscribe((voltages: any) => {
            this.adminService.setVoltageList(voltages.componenetList);

            for (let i = 0; i < data.powerSupplyVoltage.length; i++) {
              for (let j = 0; j < data.powerSupplyVoltage[i].powerSupplyInputs.length; j++) {

                if (!data.powerSupplyVoltage[i].powerSupplyInputs[j].deleteFlag && data.powerSupplyVoltage[i].powerSupplyInputs[j].inputVoltage != null) {
                  const ind = voltages.componenetList.findIndex(v => v.sequence == data.powerSupplyVoltage[i].powerSupplyInputs[j].inputVoltage);
                  powerSupplyVoltages = powerSupplyVoltages + voltages.componenetList[ind].name + ", ";
                  powerSupplyCurveArray.push(data.powerSupplyVoltage[i].powerSupplyInputs[j]);
                }
              }

              powerSupplyVoltages = powerSupplyVoltages?.replace(/,\s*$/, "");

              data.powerSupplyVoltage[i].powerSupply["voltages"] = powerSupplyVoltages;
              data.powerSupplyVoltage[i].powerSupply["voltagesCurve"] = powerSupplyCurveArray;
              data.powerSupplyVoltage[i].type = ComponentConstant.POWERSUPPLY;
              powerSupplyVoltages = "";
              powerSupplyCurveArray = [];
            }
            this.rowData = data.powerSupplyVoltage;
            this.adminService.setGridData(data.powerSupplyVoltage);
            //to add addtional fields in grid
            if (data.addlabels) {
              let index = this.columnDefs.length - 3;
              data.addlabels.forEach(element => {
                let headerIndex = this.columnDefs.findIndex(f => f.headerName == element);
                if (headerIndex == -1)
                  this.columnDefs.splice(index, 0,
                    {
                      headerName: element,
                      colId: element,
                      hide: true,
                      field: element,
                      cellRenderer: (data) => {

                        let value = null;
                        data.data.powerSupply?.additionalFields?.forEach(element1 => {
                          if (element1.name == data.colDef.headerName)
                            value = element1.value;
                        });
                        return value;
                      },
                      valueGetter: function (data) {
                        let value = null;
                        data.data.powerSupply?.additionalFields?.forEach(element1 => {
                          if (element1.name == data.colDef.headerName)
                            value = element1.value;
                        });
                        return value;
                      }
                    })
              });
            }
            // this.showGrid = true;
            this.gridApi.setColumnDefs(this.columnDefs);

            //set coumns fron localstorage
            let setFields = JSON.parse(localStorage.getItem(ComponentConstant.POWERSUPPLY + "GridFields"))

            if (setFields?.show && setFields?.hide) {
              this.gridColumnApi.setColumnsVisible(setFields.show, true);
              this.gridColumnApi.setColumnsVisible(setFields.hide, false);
              this.gridColumnApi.moveColumns(setFields.show, 0);

            }
            this.blockUI.stop();

          }, (error) => {
            console.error(error);
            this.errorMsg = error?.error ? error.error?.error?.errorString : "Oops something went wrong!!";
            this.toaster.show('error', this.errorMsg, error ? error?.message : '');
            this.blockUI.stop();
          }));

      }, error => {
        this.errorMsg = error?.error ? error.error?.error?.errorString : errorCodes.GENERIC_ERROR_MESSAGE;
        this.toaster.show('error', this.errorMsg, error ? error?.message : '');
        this.blockUI.stop();

      }));
  }

  onDeleteButtonClick(params: any) {

    this.gridOptions.api?.updateRowData({ remove: [params.node.data.Id] });
    //this.getManagePowerSupplyList();   
  }

  onEditButtonClick(params: any) {

    this.getManagePowerSupplyList();
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    //set coumns fron localstorage
    let setFields = JSON.parse(localStorage.getItem(ComponentConstant.POWERSUPPLY + "GridFields"))

    if (setFields?.show && setFields?.hide) {
      this.gridColumnApi.setColumnsVisible(setFields.show, true);
      this.gridColumnApi.setColumnsVisible(setFields.hide, false);
      this.gridColumnApi.moveColumns(setFields.show, 0);

    }
  }
  openDialog(): void {
    const dialogRef = this.dialog.open(CreatePowersupplyComponent, {
      data: {
        type: ComponentConstant.POWERSUPPLY
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      try {
        if (result.data.action !== PlatformConstant.ACTION_CANCEL) {
          this.getManagePowerSupplyList();
         setTimeout(() => this.gridApi.paginationGoToLastPage(), 100);
        }
      } catch (error) {

      }
    });
  }

  headerHeightSetter(event: any) {
    var padding = 20;
    var height = this.headerHeightGetter() + padding;
    this.gridApi.setHeaderHeight(height);
    this.gridApi.resetRowHeights();
  }

  headerHeightGetter() {
    var columnHeaderTexts = document.querySelectorAll('.ag-header-cell-text');

    var columnHeaderTextsArray = [];

    columnHeaderTexts.forEach(node => columnHeaderTextsArray.push(node));

    var clientHeights = columnHeaderTextsArray.map(
      headerText => headerText.clientHeight
    );
    var tallestHeaderTextHeight = Math.max(...clientHeights);
    return tallestHeaderTextHeight;
  }
  resetGrid() {
    this.gridApi.setFilterModel(null);
    this.flagBackButton = false;
  }

  openShowFields() {

    //show/hide column



    var dd = this.gridColumnApi.getAllDisplayedColumns();
    dd.forEach(element => {

    });
    //this.gridColumnApi.setColumnsVisible(["basePid", "cpuSockets"], false);
    // const group = this.gridColumnApi.columnController.columnDefs;
    // this.gridOptions.columnDefs.forEach(child => this.gridColumnApi.setColumnVisible(child.field, false));

    let allcols = this.gridColumnApi.getAllColumns();
    const dialogRef = this.dialog.open(ShowFieldsComponent, {
      data: {
        fields: allcols,
      },
      disableClose: false,
      // panelClass: 'width-dialog-100',           
    });

    dialogRef.afterClosed().subscribe(result => {

      // let show = result.data.fields.filter(v => { return v.visible == true });
      // let hide = result.data.fields.filter(v => { return v.visible == false });
      let show = [];
      let hide = [];
      result.data.fields.forEach(element => {
        if (element.visible == true) {
          show.push(element.colId)
        }
        else {
          hide.push(element.colId)
        }
      });


      //save to localstorage
      const setFields = { show: show, hide: hide }
      localStorage.setItem(ComponentConstant.POWERSUPPLY + "GridFields", JSON.stringify(setFields));

      this.gridColumnApi.setColumnsVisible(show, true);
      this.gridColumnApi.setColumnsVisible(hide, false);
      this.gridColumnApi.moveColumns(setFields.show, 0);

      this.headerHeightSetter(null);
    });
  }



  ngOnDestroy() {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }

}

