import { Component, OnInit } from '@angular/core';
import { GridOptions } from "ag-grid-community";
import { Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { CheckboxRenderManagePlatformsComponent } from 'src/app/shared/components/checkbox-render-manage-platforms/checkbox-render-manage-platforms.component';
import { EditRenderManagePlatformsComponent } from 'src/app/shared/components/edit-render-manage-platforms/edit-render-manage-platforms.component';
import { PlatformConstant } from 'src/constants/platform.constants';
import { ApiService } from 'src/app/services/api.service';
import { UrlConstant } from 'src/constants/url.constants';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import * as moment from 'moment';
import { AdminService } from 'src/app/services/admin.service';
import { ShowFieldsComponent } from 'src/app/shared/components/show-fields/show-fields.component';
import { UpdateNameService } from 'src/app/services/update-name.service';
import { CreateAdditionalPlatform1Component } from "src/app/components/admin/admin-tab/create-platforms/create-additional-platforms/create-additional-platform1/create-additional-platform1.component";
import { ToasterService } from 'src/app/shared/components/toast/toast.service';

@Component({
  selector: 'app-manage-additional-platform2',
  templateUrl: './manage-additional-platform2.component.html',
  styleUrls: ['./manage-additional-platform2.component.css']
})
export class ManageAdditionalPlatform2Component implements OnInit {

  constructor(public dialog: MatDialog,
    private adminService: AdminService,
    private updateNameService: UpdateNameService,
    private apiService: ApiService,
    private toaster: ToasterService,
    ) { }

  subscriptions: Subscription = new Subscription();

  errorMsg: any = '';
  rowData: any = [];
  paginationPageSize = 10;
  @BlockUI() blockUI: NgBlockUI;
  platformName: any = "";
  flagBackButton = false;
  currentPlatform: any;
  dynamicPlatformAttributes: any[] = [];
  isSetGridFields: boolean = false;
  frameworkComponents = {
    checkboxRenderer: CheckboxRenderManagePlatformsComponent,
    buttonEditRenderer: EditRenderManagePlatformsComponent
  };

  gridOptions: GridOptions = {
    columnDefs: [
      {
        headerName: 'Released State', field: 'released',
        cellRenderer: "checkboxRenderer",
        cellRendererParams: {
          additionalPlatform: true,
        },
        minWidth: 110,
        maxWidth: 120,
      },
      { headerName: 'Updated By', field: 'editor' },
      {
        headerName: 'Last Updated', field: 'lastUpdated', cellRenderer: (data) => {
          return data.value ? moment(data.value).format('MMM DD, YYYY') : moment(data.data.created).format('MMM DD, YYYY');
        },
        valueGetter: function (data) {

          return data.data?.lastUpdated ? moment(data.data?.lastUpdated).format('MMM DD, YYYY') : moment(data.data?.created).format('MMM DD, YYYY');

        },
        comparator: function dateComparator(date1, date2) {
          var date1Number = date1 && new Date(date1).getTime();
          var date2Number = date2 && new Date(date2).getTime();

          if (date1Number == null && date2Number == null) {
            return 0;
          }
          if (date1Number == null) {
            return -1;
          } else if (date2Number == null) {
            return 1;
          }
          return date1Number - date2Number;
        }
      },
      /*{
        headerName: 'Add Measurement', cellRenderer: "buttonEditRenderer",
        sortable: false,
        filter: false,
        cellRendererParams: {
          label: PlatformConstant.ADD_MEASUREMENT,
          onClick: this.onConfigButtonClick.bind(this),
        }
      },*/
      {
        headerName: 'Manage Component Options',
        cellRenderer: "buttonEditRenderer",
        cellRendererParams: {
          label: PlatformConstant.MANAGE_COMP_OPTIONS,
          additionalPlatform: true,
          onClick: this.onConfigButtonClick.bind(this),
        }
      },
      {
        headerName: "Add Configuration",
        cellRenderer: "buttonEditRenderer",
        sortable: false,
        filter: false,
        cellRendererParams: {
          label: PlatformConstant.ADD_CONFIG,
          onClick: this.onConfigButtonClick.bind(this),
        }
      },
      {
        headerName: 'CPU/Memory Compatability', cellRenderer: "buttonEditRenderer",
        sortable: false,
        filter: false,
        cellRendererParams: {
          label: PlatformConstant.CPU_MEM_COMPATIBILITY,
          onClick: this.onConfigButtonClick.bind(this),
        }
      },
      {
        headerName: "",
        minWidth: 60,
        maxWidth: 70,
        cellRenderer: "buttonEditRenderer",
        cellRendererParams: {
          label: PlatformConstant.ACTION_UPDATE,
          additionalPlatform: true,
          onClick: this.onConfigButtonClick.bind(this),
        },
      },
      {
        headerName: "",
        minWidth: 60,
        maxWidth: 70,
        cellRenderer: "buttonEditRenderer",
        cellRendererParams: {
          label: PlatformConstant.ACTION_DELETE,
          additionalPlatform: true,
        },
      },
    ],
    defaultColDef: {
      sortable: true,
      flex: 1,
      minWidth: 125,
      filter: true,
      resizable: true,
      editable: false,
      suppressMovable: true,
      headerComponentParams: {
        template:
          '<div class="ag-cell-label-container" role="presentation">' +
          '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
          '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
          '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
          '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
          '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
          '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
          '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
          '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
          '  </div>' +
          '</div>',
      },
      filterParams: { newRowsAction: 'keep'},
    }
  }
  platformDetails: any;
  ngOnInit(): void {
    this.updateNameService.getplatformPanelData().subscribe((data) => {

      if (data.length > 0) {
        let platform = data.filter(f => { return f["position"] == "9" });
        this.currentPlatform = platform[0];
        if (this.currentPlatform) this.setGridFields();
        this.platformDetails = platform[0]//additional 1

        let compNameSplit = platform[0].displayName.trim().split(" ");
        let compName = '';
        for (let i = 0; i < compNameSplit.length; i++) {
          if (!(compNameSplit[i].trim().toLowerCase() == 'manage'))
            compName += compNameSplit[i] + " ";
        }
        this.platformName = compName;
        this.adminService.setPlatformName(this.platformName);
        if (this.platformDetails?.platformType) {
          this.getPlatformList();
        }
      }
    });

    this.subscriptions.add(

      this.adminService.getPlatformData().subscribe(data => {
        if (data) {
          this.flagBackButton = true;
          this.getDuplicateRecords(data);
        }
      })
    )
    window.scroll(0, 0);
    this.subscriptions.add(this.adminService.getSeriesAll().subscribe((data) => {
      this.seriesAll = data;
    }));
  }
  seriesAll = [];

  getDuplicateRecords(data: any) {
    const filter = {
      name: {
        filterType: 'text',
        type: 'equals',
        filter: data.displayName
      }, basePid: {
        filterType: 'text',
        type: 'equals',
        filter: data.basePid
      }
    }
    this.gridApi.setFilterModel(filter)
  }

  getPlatformList() {
    this.blockUI.start('Loading...');
    // this.showGrid = false;
    const type = this.platformDetails.platformType;
    const url = UrlConstant.MANAGE_PLATFORM + '/?platformType=' + type;
    this.subscriptions.add(
      this.apiService.getMethod(url).subscribe((data: any) => {
        if (data){
          data.ucsPlatform.forEach(element =>{
            element.discont = element.discont == "1" ? "Yes":"No";                   
          })
        }
        this.adminService.setGridData(data.ucsPlatform);
        this.rowData = data.ucsPlatform.filter(d => d.deleteFlag !== true);
        //to add addtional fields in grid
        if (data.addlabels) {
          let index = this.gridOptions.columnDefs.length - 3;
          data.addlabels.forEach(element => {
            let headerIndex = this.gridOptions.columnDefs.findIndex(f => f.headerName == element);
            if (headerIndex == -1)
              this.gridOptions.columnDefs.splice(index, 0, {
                headerName: element, colId: element, hide: true, field: element,
                cellRenderer: (data) => {
                  let value = null;
                  data.data.additionalFields?.forEach(element1 => {
                    if (element1.name == data.colDef.headerName)
                      value = element1.value;
                  });
                  return value;
                },
                valueGetter: function (data) {
                  let value = null;
                  data.data.additionalFields?.forEach(element1 => {
                    if (element1.name == data.colDef.headerName)
                      value = element1.value;
                  });
                  return value;
                }
              })
          });
        }
        //this.showGrid = true;
        this.gridApi.setColumnDefs(this.gridOptions.columnDefs);

        let setFields = JSON.parse(localStorage.getItem(this.platformDetails.platformType + "GridFields"))
        if (setFields?.show && setFields?.hide) {
          this.gridColumnApi.setColumnsVisible(setFields.show, true);
          this.gridColumnApi.setColumnsVisible(setFields.hide, false);
          this.gridColumnApi.moveColumns(setFields.show, 0);

        }
        this.blockUI.stop();

      }, (error) => {
        console.error(error);
        this.errorMsg = error?.error ? error.error?.error?.errorString : "Oops something went wrong!!";
        this.toaster.show('error', this.errorMsg, error ? error?.message : '');
        this.blockUI.stop(); 
      })
    );

  }

  openDialog(): void {
    const dialogRef = this.dialog.open(CreateAdditionalPlatform1Component, {
      data: {
        type: this.platformDetails.platformType,
        all_data: this.platformDetails,
        action: PlatformConstant.ACTION_CREATE
      },
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result.data?.action == PlatformConstant.ACTION_REFRESH)
        this.getPlatformList();
     setTimeout(() => this.gridApi.paginationGoToLastPage(), 100);
    });
  }
  openShowFields() {
    let allcols = this.gridColumnApi.getAllColumns();
    const dialogRef = this.dialog.open(ShowFieldsComponent, {
      data: {
        fields: allcols,
      },
      disableClose: false,
    });

    dialogRef.afterClosed().subscribe(result => {
      let show = [];
      let hide = [];
      result.data.fields.forEach(element => {
        if (element.visible == true) {
          show.push(element.colId)
        }
        else {
          hide.push(element.colId)
        }
      });

      //save to localstorage
      const setFields = { show: show, hide: hide }
      localStorage.setItem(this.platformDetails.platformType + "GridFields", JSON.stringify(setFields));

      this.gridColumnApi.setColumnsVisible(show, true);
      this.gridColumnApi.setColumnsVisible(hide, false);
      this.gridColumnApi.moveColumns(setFields.show, 0);

      this.headerHeightSetter(null);
    });
  }
  resetGrid() {
    this.gridApi.setFilterModel(null);
    this.flagBackButton = false;
  }
  onConfigButtonClick(params: any) {
    this.getPlatformList();
  }

  gridApi: any;
  gridColumnApi: any;
  headerHeightSetter(event: any) {
    var padding = 20;
    var height = this.headerHeightGetter() + padding;
    this.gridApi.setHeaderHeight(height);
    this.gridApi.resetRowHeights();
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    //set coumns fron localstorage
    let setFields = JSON.parse(localStorage.getItem(this.platformDetails.platformType + "GridFields"))
    if (setFields?.show && setFields?.hide) {
      this.gridColumnApi.setColumnsVisible(setFields.show, true);
      this.gridColumnApi.setColumnsVisible(setFields.hide, false);
      this.gridColumnApi.moveColumns(setFields.show, 0);

    }
    if (this.currentPlatform) this.setGridFields();

  }
  getSortedOrder(field?: string) {
    let fields = [
      "vendor",
      "name",
      "gen",
      "basePid",
      "dimmSlots",
      "cpuSockets",
      "pciSlots",
      "maxDisks",
      "idlePower",
      "maxPower",
      "maxPsus",
      "nMin",
      "implicitCap",
      "height",
      "conversionFactor",
      "maxBootPower",
      "mtbf",
      "mezzSlots",
      "chassisSlots",
      "bladeSlots",
      "numberOfIOSlots",
      "discont",
    ];
    return fields.indexOf(field) == -1
      ? Number.MAX_SAFE_INTEGER
      : fields.indexOf(field);
  }
  setGridFields() {
    if (!this.isSetGridFields && this.gridApi) {
      let dynamicPlatformAttributes =
        this.currentPlatform.dynamicPlatformAttributes;
      this.adminService.setDynamicPlatformAttributes(
        dynamicPlatformAttributes
      );
      if (dynamicPlatformAttributes && dynamicPlatformAttributes.length > 0) {
        this.dynamicPlatformAttributes = dynamicPlatformAttributes;
        /**
         * the grid column api contains the pre-defined columns
         * releasedState, updatedBy, lastUpdated, associatedPlatforms, edit, delete
         * add the dynamic component attributes as additional columns
         */
        let currentColDefs = this.gridOptions.columnDefs;
        for (let attribute of dynamicPlatformAttributes) {
          let fieldValue = attribute.key;
          if (attribute.key == "maxPsus") fieldValue = "maxPsuSlots";
          let field = {
            headerName: attribute.name,
            field: fieldValue,
          };
          currentColDefs.unshift(field);
        }
        /** 
         * sort columns based on field parameter  
        */
        currentColDefs = currentColDefs.sort(
          (a, b) => this.getSortedOrder(a['field']) - this.getSortedOrder(b['field'])
        );
        this.gridApi.setColumnDefs(currentColDefs);
        this.gridColumnApi.moveColumns(
          currentColDefs.map((c: any) => c.field),
          0
        );
        this.isSetGridFields = true;
      }
    }

  }
  headerHeightGetter() {
    var columnHeaderTexts = document.querySelectorAll('.ag-header-cell-text');

    var columnHeaderTextsArray = [];

    columnHeaderTexts.forEach(node => columnHeaderTextsArray.push(node));

    var clientHeights = columnHeaderTextsArray.map(
      headerText => headerText.clientHeight
    );
    var tallestHeaderTextHeight = Math.max(...clientHeights);
    return tallestHeaderTextHeight;
  }
}
