import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { CngContentConfigType, CngModalService, CngModalSize } from '@cisco/cui-ng';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';
import { ConfirmationBoxComponent } from 'src/app/shared/components/confirmation-box/confirmation-box.component';
import { UrlConstant } from 'src/constants/url.constants';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-document-icon-renderer',
  templateUrl: './document-icon-renderer.component.html',
  styleUrls: ['./document-icon-renderer.component.css']
})
export class DocumentIconRendererComponent implements OnInit {
  params: any;
  label: string;
  fileUrl: any;
  fileName: string; subscription: Subscription = new Subscription();

  constructor(private modalService: CngModalService,
    private translate: TranslateService,
    private apiService: ApiService, private http: HttpClient) { }
  agInit(params): void {
    this.params = params;
    this.label = this.params.label || null;
  }

  ngOnInit(): void {
  }
  getHref() {
    const url = UrlConstant.DOWNLOAD_DOCUMENT + '?objectId=' + this.params.data._id;
    return environment.apiUrl + url;
  }
  onClick(event, action: string) {
    let id = this.params.data.id;
    action == 'delete' ? this.delete(id) : this.download(id);
  }

  download(id: any) {
    // const url = UrlConstant.DOWNLOAD_DOCUMENT +'?objectId='+id ; 
    // this.showLoader = true; 
    //  let httpOptions = {
    //     headers: new HttpHeaders({      
    //       Authorization: "Basic " + btoa("ucs_user:ucs_password"),
    //     }),
    //   };
    // const url =`http://localhost:8080/v1/ucs/document/download?objectId=${id}`;
    //   window.open(url);
    // this.http
    //   .get( 'http://localhost:8080/v1/ucs/document/download?objectId=610a65c3f9b95658ff82b03d', httpOptions)  
    // this.subscription.add(
    //   this.apiService.getMethod(url).subscribe((data: any) => {
    //     // this.showLoader = false;  
    //     
    //   }));
    if (this.params.onClick instanceof Function) {
      // put anything into params u want pass into parents component
      const params = {
        event: event,
        rowData: this.params.node.data,
      };
      this.params.onClick(this.params);
    }
  }
  delete(id: any) {
    if (this.params.onClick instanceof Function) {
      // put anything into params u want pass into parents component
      const params = {
        event: event,
        rowData: this.params.node.data,
      };
      this.params.onClick(this.params);
    }
  }

  public async openModal($event) {
    const result = await this.modalService
      .open({
        content: {
          type: CngContentConfigType.COMPONENT,
          content: ConfirmationBoxComponent,
          componentData: {
            someText: this.translate.instant(
              "ARE_YOU_SURE_TO_DELETE_THIS_DOCUMENT"
            ),
            key: "delete",
            event: $event,
          },
        },
        size: CngModalSize.SMALL,
      })
      .onDismiss.toPromise();

    if (result == 'ok')
      this.onClick($event, "delete");
  }
}
