import { CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA, NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AgGridModule } from "ag-grid-angular";
//import { BrowserModule } from "@angular/platform-browser";
import { FormsModule, ReactiveFormsModule,  } from "@angular/forms";
import { IconRendererComponent } from "./components/icon-renderer/icon-renderer.component";
import { ConfirmationBoxComponent } from "./components/confirmation-box/confirmation-box.component";
import { CngModalModule, CngToastModule } from "@cisco/cui-ng";
import { HttpClient } from "@angular/common/http";
import {
  TranslateModule,
  TranslateLoader,
  TranslateService,
} from "@ngx-translate/core";

import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { ToastComponent } from "./components/toast/toast/toast.component";
import { ToastContainerComponent } from "./components/toast/toast-container/toast-container.component";
import { RouterModule } from "@angular/router";

import { InputProjectNameComponent } from './components/input-project-name/input-project-name.component';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { SaveTemplateComponent } from './components/save-template/save-template.component';
import { DeleteRenderComponent } from './components/delete-render/delete-render.component';
import { EditRenderComponent } from './components/edit-render/edit-render.component';
import { CheckboxRenderComponent } from './components/checkbox-render/checkbox-render.component';
import { EditRenderManagePlatformsComponent } from './components/edit-render-manage-platforms/edit-render-manage-platforms.component';
import { CheckboxRenderManagePlatformsComponent } from './components/checkbox-render-manage-platforms/checkbox-render-manage-platforms.component';
import { AdditionalAttributesComponent } from './components/additional-attributes/additional-attributes.component';
import { SearchBoxComponent } from './components/search-box/search-box.component';
import { SelectDropDownModule } from 'ngx-select-dropdown'
import { MatDialogModule} from '@angular/material/dialog';
import { NgSelectModule } from '@ng-select/ng-select';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { ShowFieldsComponent } from './components/show-fields/show-fields.component';
import { AddManageCompComponent } from './components/add-manage-comp/add-manage-comp.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { AssociatedPlatformsComponent } from './components/associated-platforms/associated-platforms.component';
import { EditRenderGlobalSearchComponent } from './components/edit-render-global-search/edit-render-global-search.component';


export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

@NgModule({
  declarations: [
    IconRendererComponent,
    ConfirmationBoxComponent,
    ToastComponent,

    ToastContainerComponent,
  
    InputProjectNameComponent,
  
    SaveTemplateComponent,
    DeleteRenderComponent,
  
    EditRenderComponent,
  
    CheckboxRenderComponent,
  
    EditRenderManagePlatformsComponent,
  
    CheckboxRenderManagePlatformsComponent,
  
    AdditionalAttributesComponent,
  
    SearchBoxComponent,
  
    ShowFieldsComponent,
    
    AddManageCompComponent,
    
    AssociatedPlatformsComponent,
    
    EditRenderGlobalSearchComponent,
    
  
 
  ],
  imports: [
    CommonModule,
   //// BrowserModule,
    FormsModule,NgSelectModule,
    ReactiveFormsModule,
    AgGridModule.withComponents([
      IconRendererComponent,
      DeleteRenderComponent,
      EditRenderComponent,
      CheckboxRenderComponent
      
     // BrowserAnimationsModule
    ]),
    CngModalModule,
    SelectDropDownModule,
    MatDialogModule,AngularMultiSelectModule,
    CngToastModule,
    RouterModule,
    TranslateModule,
    DragDropModule
    // .forRoot({
    //   loader: {
    //     provide: TranslateLoader,
    //     useFactory: HttpLoaderFactory,
    //     deps: [HttpClient],
    //   },
    // }),
  ],
  providers: [TranslateService],
  exports: [
    IconRendererComponent,
    TranslateModule,
    ToastContainerComponent,  
    TranslateModule,
    DeleteRenderComponent,
    EditRenderComponent,
    CheckboxRenderComponent,
    SearchBoxComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class SharedModule {}
