<div  mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle
 class="flex flex-between" style="margin-left: -25px;margin-bottom: 0px;">
    <button mat-button (click)="close()"  class="btn btn--link focus" >Back</button>
    <div style="    display: flex;
        flex-direction: row-reverse;">
         <div class="flex-center margin_auto_t_b" >
            <a class="anchor-close" id="close" title="Close" (click)="close()">
            <span class="icon-close icon-size-24 icon-close"></span>
            </a>
        </div>
    </div>
</div>
<div style="min-height: 400px;max-height: 80vh;overflow: auto;">
    <div>
        <h4 #manageComponent>Manage Component</h4>
    </div>
    <div *ngIf="!this.platformData.additionalPlatform">
        <div class="col-md-6 row" *ngIf="platformData?.params.vendor">
            <div class="col-2" >
                Vendor
            </div>
            <div class="col-md-8 col-lg-8">
                :&nbsp;{{platformData?.params.vendor}}
            </div>
         </div>
         <div class="col-md-6 row" *ngIf="platformData?.params.name">
            <div class="col-2" >
                Model
            </div>
            <div class="col-md-8 col-lg-8">
                :&nbsp;{{platformData?.params.name}}
            </div>
         </div>
         <div class="col-md-6 row" *ngIf="platformData?.params.gen">
            <div class="col-2" >
                Generation
            </div>
            <div class="col-md-8 col-lg-8">
                :&nbsp;{{platformData?.params.gen}}
            </div>
         </div>
    </div> 

    <div *ngIf="this.platformData.additionalPlatform">
        <div class="col-md-6 row" *ngIf="platformData?.params.displayName; else showName">
            <div class="col-3" >
                Display Name
            </div>
            <div class="col-md-8 col-lg-8">
                :&nbsp;{{platformData?.params.displayName}}
            </div>
         </div>
         <ng-template #showName>
            <div class="col-md-6 row" *ngIf="platformData?.params.name">
                <div class="col-3" >
                    Name
                </div>
                <div class="col-md-8 col-lg-8">
                    :&nbsp;{{platformData?.params.name}}
                </div>
             </div>
         </ng-template>

    </div>     
        <br>
        <div class="row">
            <div class="col-md-4 base-margin-bottom">
                
                <div class="form-group">
                    <div class="form-group__text">
                        <label for="select-state-default">Select Platform</label>
                        <ngx-select-dropdown 
                            [multiple]="false"  
                            [(ngModel)]="selectedType"
                            [config]="config" [options]="platformDropdown" (change)="changeType($event)" >
                        </ngx-select-dropdown>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
            </div>
            <div class="col-md-4" style="margin: auto;" *ngIf="(showLoader == 'hide')">
                <div style="width:250px;float: right;margin-right: 5px;">
                    <div class="form-group input--icon max-width">
                        <div class="form-group__text">
                            <input type="text"  placeholder="Search" title="Search by Name" (ngModelChange)="onSearchStringChange($event)" [(ngModel)]="searchText"
                                 style="background-color: var(--cui-background-alt);
                                 border: none;">
                            <button type="button" class="link">
                                <span class="icon-search"></span>
                            </button>
                        </div>
                    </div>
                </div>   
            </div>
            
        </div> 
        <div>
            <div style="margin-top: 7px;margin-left: 20px;display: none;" *ngIf="showLoader == 'show'" class="loading-dots loading-dots--dark">
                <span></span>
                <span></span>
                <span></span>
              </div>
            <div class="accordian-row" *ngIf="!(showLoader == 'show')">
            <div *ngIf="selectedType?.value == 'cpus'">   
              <ul class="accordion accordian-series">
                <li *ngFor="let series of cpulist | filterComponent:searchText:'accordion'; let i = index" [ngClass]="selectedSeriesId.includes(i) ? 'active' : ''">
                  <div>
                    <a class="accordion__title accor-title" (click)="onSeriesSelect(i)" id="series-{{i}}">
                      <span class="accordion__toggle icon-chevron-down" style="margin-top: -7px;"></span>
                      <h5>{{series.name}}</h5>
                    </a>                 
                  </div>
                  <div class="accordion__content">
                    <div class="row" style="border-bottom: 1px solid #adb5bd">
                        <div class="col-md-12" >                      
                            <div class="responsive-table">
                                <table class="table table--lined" aria-label="Lined table example">
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Display Name</th>
                                            <th>PID</th>
                                            <th>Supported
                                                <label class="checkbox">
                                                    <input type="checkbox" [(ngModel)]="series.checkAll" (click)="checkAll($event,series.data,'accordion')">
                                                    <span class="checkbox__input"></span>
                                                </label>&nbsp;All
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let r of series.data; let i = index">
                                            <td style="max-width: 400px;">{{r.name}}</td>
                                            <td style="max-width: 400px;">{{r.displayName}}</td>
                                            <td>{{r.pid}}</td>
                                            <td><div class="form-group">
                                                <label class="checkbox">
                                                    <input type="checkbox" [checked]="r.supportCheck" (click)="checkedHandler($event,r);showCheckALL('cpus')">
                                                    <span class="checkbox__input"></span>
                                                </label>
                                            </div></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>                    
                    </div>
                  </div>
                </li>              
              </ul>
            </div>
              <div *ngIf="selectedType?.value == 'dimms'">
              <ul class="accordion accordian-series">
                <li *ngFor="let series of dimmList | filterComponent:searchText:'accordion'; let i = index" [ngClass]="selectedSeriesId.includes(i) ? 'active' : ''">
                  <div>
                    <a class="accordion__title accor-title" (click)="onSeriesSelect(i)" id="series-{{i}}">
                      <span class="accordion__toggle icon-chevron-down" style="margin-top: -7px;"></span>
                      <h5>{{series.name}}</h5>
                    </a>                 
                  </div>
                  <div class="accordion__content">
                    <div class="row" style="border-bottom: 1px solid #adb5bd">
                        <div class="col-md-12" >                      
                            <div class="responsive-table">
                                <table class="table table--lined" aria-label="Lined table example">
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Size(GB)</th>
                                            <th>PID</th>
                                            <th>Supported
                                                <label class="checkbox">
                                                    <input type="checkbox" [(ngModel)]="series.checkAll"  (click)="checkAll($event,series.data,'accordion')">
                                                    <span class="checkbox__input"></span>
                                                </label>&nbsp;All
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let r of series.data; let i = index">
                                            <td style="max-width: 500px;">{{r.displayName}}</td>
                                            <td>{{r.size}}</td>
                                            <td>{{r.pid}}</td>
                                            <td><div class="form-group">
                                                <label class="checkbox">
                                                    <input type="checkbox" [checked]="r.supportCheck" (click)="checkedHandler($event,r);showCheckALL('dimms');">
                                                    <span class="checkbox__input"></span>
                                                </label>
                                            </div></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>                    
                    </div>
                  </div>
                </li>              
              </ul>
            </div>
              <div *ngIf="selectedType?.value == 'powerSupply'">
                <div class="row" style="border-bottom: 1px solid #adb5bd">
                    <div class="col-md-12" >                      
                        <div class="responsive-table">
                            <table class="table table--lined" aria-label="Lined table example">
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Capacity (GB)</th>
                                        <th>PID</th>
                                        <th>Supported
                                            <label class="checkbox">
                                                <input type="checkbox" [(ngModel)]="checkboxSelectAll" (click)="checkAll($event,PSList)">
                                                <span class="checkbox__input"></span>
                                            </label>&nbsp;All
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let r of PSList | filterComponent:searchText:'pslist'; let i = index">
                                        <td style="max-width: 500px;">{{r.powerSupply.displayName}}</td>
                                        <td>{{r.powerSupply.capacity}}</td>
                                        <td style="max-width: 400px;">{{r.powerSupply.pid}}</td>
                                        <td> <div class="form-group">
                                            <label class="checkbox">
                                                <input type="checkbox" [checked]="r.supportCheck" (click)="checkedHandler($event,r);showCheckALL('powerSupply');">
                                                <span class="checkbox__input"></span>
                                            </label>
                                        </div></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>                    
                </div>
              </div>
              <div *ngIf="selectedType?.value == 'ioes'">
                <div class="row" style="border-bottom: 1px solid #adb5bd">
                    <div class="col-md-12" >                      
                        <div class="responsive-table">
                            <table class="table table--lined" aria-label="Lined table example">
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Display Name</th>
                                        <th>PID</th>
                                        <th>Type</th>
                                        <th>Supported
                                            <label class="checkbox">
                                                <input type="checkbox" [(ngModel)]="checkboxSelectAll" (click)="checkAll($event,IOCardsList.ioes)">
                                                <span class="checkbox__input"></span>
                                            </label>&nbsp;All
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let r of IOCardsList.ioes | filterComponent:searchText:'ioeslist'; let i = index">
                                        <td style="max-width: 400px;">{{r.internalName}}</td>
                                        <td style="max-width: 500px;">{{r.displayName}}</td>
                                        <td>{{r.pid}}</td>
                                        <td>{{r.ioType}}</td>
                                        <td> <div class="form-group">
                                            <label class="checkbox">
                                                <input type="checkbox" [checked]="r.supportCheck" (click)="checkedHandler($event,r);showCheckALL('ioes');">
                                                <span class="checkbox__input"></span>
                                            </label>
                                        </div></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>                    
                </div>
              </div>
              <div *ngIf="selectedType?.value == 'storages'">
                <div class="row" style="border-bottom: 1px solid #adb5bd">
                    <div class="col-md-12" >                      
                        <div class="responsive-table">
                            <table class="table table--lined" aria-label="Lined table example">
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>PID</th>
                                        <th>Type</th>
                                        <th>Supported
                                            <label class="checkbox">
                                                <input type="checkbox" [(ngModel)]="checkboxSelectAll" (click)="checkAll($event,storagesList)">
                                                <span class="checkbox__input"></span>
                                            </label>&nbsp;All
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let r of storagesList| filterComponent:searchText:'ioeslist'; let i = index">
                                        <td style="max-width: 500px;">{{r.displayName}}</td>
                                        <td style="max-width: 400px;">{{r.pid}}</td>
                                        <td>{{r.storageType}}</td><td> <div class="form-group">
                                            <label class="checkbox">
                                                <input type="checkbox" [checked]="r.supportCheck" (click)="checkedHandler($event,r);showCheckALL('storages');">
                                                <span class="checkbox__input"></span>
                                            </label>
                                        </div></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>                    
                </div>
              </div>
              <div *ngIf="selectedType?.value == 'ioms'">
                <div class="row" style="border-bottom: 1px solid #adb5bd">
                    <div class="col-md-12" >                      
                        <div class="responsive-table">
                            <table class="table table--lined" aria-label="Lined table example">
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>PID</th>
                                        <th>Supported
                                            <label class="checkbox">
                                                <input type="checkbox" [(ngModel)]="checkboxSelectAll" (click)="checkAll($event,iomsList.ioms)">
                                                <span class="checkbox__input"></span>
                                            </label>&nbsp;All
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let r of iomsList.ioms| filterComponent:searchText:'ioeslist'; let i = index">
                                        <td style="max-width: 500px;">{{r.displayName}}</td>
                                        <td style="max-width: 400px;">{{r.pid}}</td>
                                        <td> <div class="form-group">
                                            <label class="checkbox">
                                                <input type="checkbox" [checked]="r.supportCheck" (click)="checkedHandler($event,r);showCheckALL('ioms');">
                                                <span class="checkbox__input"></span>
                                            </label>
                                        </div></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>                    
                </div>
              </div>
              <div *ngIf="selectedType?.value == 'BLADE'">
                <div class="row" style="border-bottom: 1px solid #adb5bd">
                    <div class="col-md-12" >                      
                        <div class="responsive-table">
                            <table class="table table--lined" aria-label="Lined table example">
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Generation</th>
                                        <th>PID</th>
                                        <th>Chassis Slots</th>    
                                        <th>Supported
                                            <label class="checkbox">
                                                <input type="checkbox" [(ngModel)]="checkboxSelectAll"  (click)="checkAll($event,bladeList)">
                                                <span class="checkbox__input"></span>
                                            </label>&nbsp;All
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let r of bladeList| filterComponent:searchText:'bladelist'; let i = index">
                                        <td style="max-width: 400px;">{{r.name}}</td>
                                        <td style="max-width: 300px;">{{r.gen}}</td>
                                        <td  style="max-width: 250px;">{{r.basePid}}</td>
                                        <td>{{r.numChassisSlots}}</td>
                                        <td> <div class="form-group">
                                            <label class="checkbox">
                                                <input type="checkbox" [checked]="r.supportCheck" (click)="checkedHandler($event,r);showCheckALL('BLADE');">
                                                <span class="checkbox__input"></span>
                                            </label>
                                        </div></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>                    
                </div>
              </div>
              <div *ngIf="selectedType?.value == 'CARTRIDGE'">
                <div class="row" style="border-bottom: 1px solid #adb5bd">
                    <div class="col-md-12" >                      
                        <div class="responsive-table">
                            <table class="table table--lined" aria-label="Lined table example">
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Generation</th>
                                        <th>PID</th>
                                        <th>Chassis Slots</th>    
                                        <th>Supported
                                            <label class="checkbox">
                                                <input type="checkbox" [(ngModel)]="checkboxSelectAll" (click)="checkAll($event,nodeList)">
                                                <span class="checkbox__input"></span>
                                            </label>&nbsp;All
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let r of nodeList | filterComponent:searchText:'bladelist'; let i = index">
                                        <td style="max-width: 400px;">{{r.name}}</td>
                                        <td style="max-width: 300px;">{{r.gen}}</td>
                                        <td style="max-width: 250px;">{{r.basePid}}</td>
                                        <td>{{r.numChassisSlots}}</td>
                                        <td> <div class="form-group">
                                            <label class="checkbox">
                                                <input type="checkbox" [checked]="r.supportCheck" (click)="checkedHandler($event,r);showCheckALL('CARTRIDGE');">
                                                <span class="checkbox__input"></span>
                                            </label>
                                        </div></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>                    
                </div>
              </div>
              <div *ngIf="selectedType?.value == 'expansions'">
                <div class="row" style="border-bottom: 1px solid #adb5bd">
                    <div class="col-md-12" >                      
                        <div class="responsive-table">
                            <table class="table table--lined" aria-label="Lined table example">
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>PID</th>
                                        <th>Type</th>
                                        <th>Supported
                                            <label class="checkbox">
                                                <input type="checkbox" [(ngModel)]="checkboxSelectAll" (click)="checkAll($event,expansionList.expansions)">
                                                <span class="checkbox__input"></span>
                                            </label>&nbsp;All
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let r of expansionList.expansions| filterComponent:searchText:'ioeslist'; let i = index">
                                        <td  style="max-width: 500px;">{{r.displayName}}</td>
                                        <td style="max-width: 400px;">{{r.pid}}</td>
                                        <td>{{r.ioType}}</td>
                                        <td> <div class="form-group">
                                            <label class="checkbox">
                                                <input type="checkbox" [checked]="r.supportCheck" (click)="checkedHandler($event,r);showCheckALL('expansions');">
                                                <span class="checkbox__input"></span>
                                            </label>
                                        </div></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>                    
                </div>
              </div>

              <!-- fan policy -->
              <div *ngIf="selectedType?.value == 'FanPolicy'">
                <div class="row" style="border-bottom: 1px solid #adb5bd">
                    <div class="col-md-8" >                      
                        <div class="responsive-table">
                            <table class="table table--lined" aria-label="Lined table example">
                                <thead>
                                    <tr>
                                        <!-- <th>Name</th> -->
                                        <th>PID</th>
                                        <th>Supported</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let r of fanPolicyList| filterComponent:searchText:'fanlist'; let i = index">
                                        <!-- <td  style="max-width: 500px;">{{r.displayName}}</td> -->
                                        <td style="width: 400px;">{{r}}</td>
                                        <td> 
                                                <div class="form-group form-group--inline">
                                                    <label class="radio">
                                                        <input type="radio" [value]="r" name="fanPolicy" [(ngModel)]="selectedFanPolicy" 
                                                        (change)="handleChange($event)" >
                                                        <span class="radio__input"></span>
                                                        <span class="radio__label"></span>
                                                    </label>
                                                    <!-- <label class="checkbox">
                                                        <input type="checkbox" [checked]="selectedFanPolicy == r" 
                                                            (click)="checkedHandlerFan($event,r);">
                                                        <span class="checkbox__input"></span>
                                                    </label> -->
                                                </div>
                                       </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>                    
                </div>
              </div>

              <!-- Additional components -->
              <div *ngIf="selectedType?.additionalComponent">
                <div class="row" *ngIf="additionalComponentsList.length > 0; else componentsListEmpty">
                    <div class="col-md-8" >                      
                        <div class="responsive-table">
                            <table class="table table--lined" aria-label="Lined table example">
                                <thead>
                                    <tr>
                                        <!-- <th>Name</th> -->
                                        <th>Display Name</th>
                                        <th>PID</th>
                                        <th>Supported
                                            <label class="checkbox">
                                                <input type="checkbox" [(ngModel)]="checkboxSelectAll" (click)="checkAll($event,additionalComponentsList)">
                                                <span class="checkbox__input"></span>
                                            </label>&nbsp;All
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let r of additionalComponentsList | filterComponent:searchText:'additionalComponentsList'; let i = index">
                                        <td  style="max-width: 500px;">{{r.displayName}}</td>
                                        <td style="max-width: 400px;">{{r.pid}}</td>
                                        <td> <div class="form-group">
                                            <label class="checkbox">
                                                <input type="checkbox" [checked]="r.supportCheck" (click)="checkedHandler($event,r);showCheckALL('ADPLATFORM');">
                                                <span class="checkbox__input"></span>
                                            </label>
                                        </div></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>                    
                </div>
                <ng-template #componentsListEmpty>
                    Selected additional component does not have any Released Item
                </ng-template>
              </div>



            </div>

        </div>
    </div>    
    <mat-dialog-actions>
        <button mat-button   class="btn btn--small " [disabled]="disableSave || !formChanged" style="height: 24px;margin: 5px 20px 0 0;" (click)="submit('closeModal')">Save & Close</button>
        <button mat-button (click)="submit('save')"  [disabled]="disableSave || !formChanged" class="btn btn--small " style="height: 24px;margin: 5px 20px 0 0;">Save</button>
        <span title="scroll to top" *ngIf="!(selectedType.length == 0)" class="icon-chevron-left-double" style="margin-right: 30px;transform: rotate(90deg);color:#1680aa"(click)="manageComponentScrollUp()"></span>
        <!-- <div *ngIf="isPlatformReleased"> {{ platformReleasedInfo }} </div> -->
    </mat-dialog-actions>
