
 <footer class="footer">
    <div class="flex-container">
      <div class="flex-item-left">
        <a class="footer_logo" href="http://www.cisco.com" target="_blank">
          <span class="icon-cisco icon-size-24"></span>
              </a>
      <div class="footer-image-container">       
                <img class="footer-intel-image" src="../../../../assets/images/intel-w46.png"/>
      </div>       
                <div class="footer-logo-xenon-text">
                    <div >Cisco UCS {{'WITH_INTEL' | translate}}®</div>
                    <div class="footer-xeon">Xeon® {{'PROCESSORS' | translate}}</div>
                </div>
               
      </div>
      <div class="flex-item-center ">
          <div class="footer__links">
          <ul class="list list--inline">
              <li (click)="onLicenseClick()" *ngIf="!isUserLoggedIn" class="li__footer link_border_right"><a class="footer__link" [routerLink]="['license']" target="_self">{{'LICENSE_AGREEMENT' | translate}}</a></li>
              <li class="li__footer"><a class="footer__link" [routerLink]="['help']" target="_self">{{'HELP' | translate}}</a></li>
              <li class="link_border li__footer"><a class="footer__link" href="mailto:ucspc@cisco.com?" title="Contact">{{'CONTACT' | translate}} &nbsp;<span class="icon-contact icon-size-16"></span></a></li>
              <li class="link_border li__footer"><a class="footer__link" href="mailto:ucspc@cisco.com?subject=Feedback" title="Feedback">{{'FEEDBACK' | translate}} &nbsp;<span class="icon-feedback icon-size-16"></span></a></li> 
              <li >UCS Power Calculator {{'VERSION' | translate}} V2.2.3-9122a813-20201009 © 2020 Cisco Systems, Inc. All rights reserved.</li>
            </ul>   
            <div class="footer_subtext"><span>Intel, the Intel {{'LOGO' | translate}}, Xeon, {{'AND' | translate}} Xeon Inside {{'ARE_TRADEMARKS_OR_REGISTERED_TRADEMARKS_OF' | translate}} Intel Corporation {{'IN_THE_US_OR_OTHER_COUNTRIES' | translate}}.</span></div>
        </div>

      </div>
      <div class="flex-item-right">
        <a class="footer_logo" href="http://www.cisco.com" target="_blank">
            <span class="icon-cisco icon-size-24"></span>
                </a>
      </div>
    </div>
</footer>
