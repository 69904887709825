import { Component, OnInit, Inject } from '@angular/core';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';
import { UrlConstant } from 'src/constants/url.constants';
import { HttpClient } from '@angular/common/http';
import { ComponentConstant } from 'src/constants/components.constants';
import { DeleteRenderComponent } from 'src/app/shared/components/delete-render/delete-render.component';
import { EditRenderComponent } from 'src/app/shared/components/edit-render/edit-render.component';
import { CheckboxRenderComponent } from 'src/app/shared/components/checkbox-render/checkbox-render.component';
import { GridOptions } from "ag-grid-community";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CreateNewcomponentComponent } from 'src/app/components/admin/admin-tab/create-newcomponent/create-newcomponent.component';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { AdminService } from 'src/app/services/admin.service';
import { PlatformConstant } from 'src/constants/platform.constants';
import { ShowFieldsComponent } from 'src/app/shared/components/show-fields/show-fields.component';

import { UpdateNameService } from 'src/app/services/update-name.service';
import { Router } from '@angular/router';
import { ToasterService } from 'src/app/shared/components/toast/toast.service';


export interface DialogData {
  displayName: string;
  internalName: string;
  //nameMat: string;
}

@Component({
  selector: 'app-manage-io-devices',
  templateUrl: './manage-io-devices.component.html',
  styleUrls: ['./manage-io-devices.component.css']
})
export class ManageIoDevicesComponent implements OnInit {
  displayName: string;
  internalName: string;
  gridApi: any;
  gridColumnApi: any;
  componentIdx: number;
  componentName: string;
  componentDisplayName: string = "";
  //nameMat: string;

  subscriptions: Subscription = new Subscription();
  @BlockUI() blockUI: NgBlockUI;
  showLoader = true;
  errorMsg = '';
  columnDefs;
  frameworkComponents;
  defaultColDef;
  rowData;
  gridOptions: GridOptions = {};
  deleteConfirm;
  flagBackButton = false;

  constructor(
    private apiService: ApiService,
    private httpClient: HttpClient,
    private adminService: AdminService,
    public dialog: MatDialog,
    private updateNameService: UpdateNameService,
    private router: Router,
    private toaster: ToasterService,
  ) {

    // this.componentIdx = this.router.getCurrentNavigation().extras.state.idx;



    this.updateNameService.getCurrentName().subscribe((data) => {
      if (data.length > 0) {

        this.componentName = data.filter(compName => compName.internalName.trim() == 'Manage IO Devices')[0].currentName;
        let compNameSplit = this.componentName.trim().replace("Manage", "").replace("manage", "");
        // let compName = '';
        // for (let i = 1; i < compNameSplit.length; i++) compName += compNameSplit[i] + " ";
        this.componentDisplayName = compNameSplit;
      }
    });
    // this.componentName = this.updateNameService.currentNames[this.componentIdx];



    this.getManageIoDevicesList();
    this.columnDefs = [
      {
        headerName: 'Display Name', field: 'displayName',
        comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
          if (valueA.toUpperCase() == valueB.toUpperCase()) {
            return 0;
          }
          else {
            return (valueA.toUpperCase() > valueB.toUpperCase()) ? 1 : -1;
          }
        }
      },
      {
        headerName: 'Internal Name', field: 'internalName',
        comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
          if (valueA.toUpperCase() == valueB.toUpperCase()) {
            return 0;
          }
          else {
            return (valueA.toUpperCase() > valueB.toUpperCase()) ? 1 : -1;
          }
        }
      },
      {
        headerName: 'PID', field: 'pid',
        comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
          if (valueA.toUpperCase() == valueB.toUpperCase()) {
            return 0;
          }
          else {
            return (valueA.toUpperCase() > valueB.toUpperCase()) ? 1 : -1;
          }
        }
      },
      { headerName: 'Type', field: 'ioType' },
      { headerName: 'RAID', field: 'raid' },
      { headerName: 'Idle Power (W)', field: 'idleWatts' },
      { headerName: 'Max Power (W)', field: 'maxWatts' },
      { headerName: 'MTBF (Hours) ', field: 'mtbf' },
      { headerName: 'Released State', field: 'released', cellRenderer: "checkboxRenderer" },
      { headerName: 'Updated By', field: 'editor' },
      {
        headerName: 'Last Updated', field: 'lastUpdated',
        cellRenderer: (data) => {
          //return data.value ? moment(data.value).format('MMM DD, YYYY') : null;
          return data.value ? moment(data.value).format('MMM DD, YYYY') : moment(data.data.created).format('MMM DD, YYYY');
        },
        valueGetter: function (data) {

          return data.data?.lastUpdated ? moment(data.data?.lastUpdated).format('MMM DD, YYYY') : moment(data.data?.created).format('MMM DD, YYYY');

        },
        comparator: function dateComparator(date1, date2) {
          var date1Number = date1 && new Date(date1).getTime();
          var date2Number = date2 && new Date(date2).getTime();




          if (date1Number == null && date2Number == null) {
            return 0;
          }

          if (date1Number == null) {
            return -1;
          } else if (date2Number == null) {
            return 1;
          }

          return date1Number - date2Number;
        }
      },
      {
        headerName: "Associated Platforms",
        minWidth: 100,
        maxWidth: 110,
        sortable: false,
        filter: false,
        cellRenderer: "buttonEditRenderer",
        cellRendererParams: {
          label: "associatedPlatforms",
          dynamicComponent: false
        },

      },
      {
        headerName: "",
        minWidth: 70,
        maxWidth: 80,
        sortable: false,
        filter: false,
        cellRenderer: "buttonEditRenderer",
        cellRendererParams: {
          onClick: this.onEditButtonClicked.bind(this),
          label: "Edit",
          dynamicComponent: false
        },

      },
      {
        headerName: "",
        minWidth: 70,
        maxWidth: 80,
        sortable: false,
        filter: false,
        cellRenderer: "buttonEditRenderer",
        cellRendererParams: {
          onClick: this.onDeleteButtonClick.bind(this),
          label: "Delete",
          dynamicComponent: false
        },

      },

      // { headerName: 'id', field:'id',hide:true},
      // { headerName: 'sequence', field:'sequence',hide:true},
      // { headerName: 'type', field:'type',hide:true},
      // { headerName: 'name', field:'name',hide:true},
      // { headerName: 'index' , field:'index',hide:true},
      // { headerName: 'voltage', field:'voltage',hide:true},
      // { headerName: 'voltageType', field:'voltageType',hide:true},
      // { headerName: 'ts', field:'ts',hide:true},
      // { headerName: 'created', field:'created',hide:true},
      // { headerName: 'error' , field:'error',hide:true},
      // { headerName: 'successMessage' , field:'successMessage',hide:true}
    ];
    this.defaultColDef = {
      editable: false,
      sortable: true,
      flex: 1,
      minWidth: 110,
      filter: true,
      resizable: true,
      headerComponentParams: {
        template:
          '<div class="ag-cell-label-container" role="presentation">' +
          '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
          '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
          '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
          '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
          '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
          '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
          '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
          '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
          '  </div>' +
          '</div>',
      },
      filterParams: { newRowsAction: 'keep' },
    };
    this.frameworkComponents = {
      btnCellRenderer: DeleteRenderComponent,
      buttonEditRenderer: EditRenderComponent,
      checkboxRenderer: CheckboxRenderComponent
    };
  }
  paginationPageSize = 10;
  value: String;
  name: String;

  ngOnInit(): void {
    this.adminService.getData().subscribe(data => {
      if (data) {
        this.flagBackButton = true;
        this.getDuplicateRecords(data);
      }
    });
  }

  getDuplicateRecords(data: any) {
    const filter = {
      displayName: {
        filterType: 'text',
        type: 'equals',
        filter: data.displayName
      }, pid: {
        filterType: 'text',
        type: 'equals',
        filter: data.pid
      }
    }
    this.gridApi.setFilterModel(filter)
  }

  // showGrid:boolean = true;
  getManageIoDevicesList() {
    let ioes = ComponentConstant.IOES;
    this.blockUI.start('Loading...');
    // this.showGrid = false;
    const url = UrlConstant.MANAGECOMPONENTS + '/?component=' + ioes;
    this.subscriptions.add(
      this.apiService.getMethod(url).subscribe((data: any) => {
        this.rowData = data.componenetList;
        this.adminService.setGridData(data.componenetList);
        //to add addtional fields in grid
        if (data.addlabels) {
          let index = this.columnDefs.length - 2;
          data.addlabels.forEach(element => {
            let headerIndex = this.columnDefs.findIndex(f => f.headerName == element);
            if (headerIndex == -1)
              this.columnDefs.splice(index, 0, {
                headerName: element, colId: element, hide: true, field: element,
                cellRenderer: (data) => {

                  let value = null;
                  data.data.additionalFields?.forEach(element1 => {
                    if (element1.name == data.colDef.headerName)
                      value = element1.value;
                  });
                  return value;
                },
                valueGetter: function (data) {
                  let value = null;
                  data.data.additionalFields?.forEach(element1 => {
                    if (element1.name == data.colDef.headerName)
                      value = element1.value;
                  });
                  return value;
                }
              })
          });
        }
        // this.showGrid = true;
        this.gridApi.setColumnDefs(this.columnDefs);
        let setFields = JSON.parse(localStorage.getItem(ComponentConstant.IOES + "GridFields"))
        if (setFields?.show && setFields?.hide) {
          this.gridColumnApi.setColumnsVisible(setFields.show, true);
          this.gridColumnApi.setColumnsVisible(setFields.hide, false);
          this.gridColumnApi.moveColumns(setFields.show, 0);
        }
        this.blockUI.stop();
        //this.showLoader = false;
      }, (error) => {
        console.error(error);
        this.errorMsg = error?.error ? error.error?.error?.errorString : "Oops something went wrong!!";
        this.toaster.show('error', this.errorMsg, error ? error?.message : '');
        this.blockUI.stop();
      })
    );
  }

  onDeleteButtonClick(params: any) {


    this.gridOptions.api?.updateRowData({ remove: [params.node.data.Id] });
  }
  onEditButtonClicked(params: any) {
    this.getManageIoDevicesList();
  }
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    let setFields = JSON.parse(localStorage.getItem(ComponentConstant.IOES + "GridFields"))
    if (setFields?.show && setFields?.hide) {
      this.gridColumnApi.setColumnsVisible(setFields.show, true);
      this.gridColumnApi.setColumnsVisible(setFields.hide, false);
      this.gridColumnApi.moveColumns(setFields.show, 0);

    }
  }
  openDialog(): void {
    const dialogRef = this.dialog.open(CreateNewcomponentComponent, {
      data: {
        DisplayName: this.displayName,
        InternalName: this.internalName,
        type: ComponentConstant.IOES
      },
      disableClose: true,

    });

    dialogRef.afterClosed().subscribe(result => {

      try {
        if (result.data.action !== PlatformConstant.ACTION_CANCEL) {
          this.getManageIoDevicesList();
          setTimeout(() => this.gridApi.paginationGoToLastPage(), 100);
        }
      } catch (error) {

      }
    });
  }

  createIoDevicesList(data: any) {
    let ioes = ComponentConstant.IOES;
    const url = UrlConstant.MANAGECOMPONENTS;

    this.subscriptions.add(
      this.apiService.postMethod(url, data).subscribe((data: any) => {
        this.showLoader = false;
      })
    );
  }
  headerHeightSetter(event: any) {
    var padding = 20;
    var height = this.headerHeightGetter() + padding;
    this.gridApi.setHeaderHeight(height);
    this.gridApi.resetRowHeights();
  }

  headerHeightGetter() {
    var columnHeaderTexts = document.querySelectorAll('.ag-header-cell-text');

    var columnHeaderTextsArray = [];

    columnHeaderTexts.forEach(node => columnHeaderTextsArray.push(node));

    var clientHeights = columnHeaderTextsArray.map(
      headerText => headerText.clientHeight
    );
    var tallestHeaderTextHeight = Math.max(...clientHeights);
    return tallestHeaderTextHeight;
  }
  resetGrid() {
    this.gridApi.setFilterModel(null);
    this.flagBackButton = false;
  }

  openShowFields() {

    //show/hide column



    var dd = this.gridColumnApi.getAllDisplayedColumns();
    dd.forEach(element => {

    });
    //this.gridColumnApi.setColumnsVisible(["basePid", "cpuSockets"], false);
    // const group = this.gridColumnApi.columnController.columnDefs;
    // this.gridOptions.columnDefs.forEach(child => this.gridColumnApi.setColumnVisible(child.field, false));

    let allcols = this.gridColumnApi.getAllColumns();
    const dialogRef = this.dialog.open(ShowFieldsComponent, {
      data: {
        fields: allcols,
      },
      disableClose: false,
      // panelClass: 'width-dialog-100',           
    });

    dialogRef.afterClosed().subscribe(result => {

      // let show = result.data.fields.filter(v => { return v.visible == true });
      // let hide = result.data.fields.filter(v => { return v.visible == false });
      let show = [];
      let hide = [];
      result.data.fields.forEach(element => {
        if (element.visible == true) {
          show.push(element.colId)
        }
        else {
          hide.push(element.colId)
        }
      });


      //save to localstorage
      const setFields = { show: show, hide: hide }
      localStorage.setItem(ComponentConstant.IOES + "GridFields", JSON.stringify(setFields));

      this.gridColumnApi.setColumnsVisible(show, true);
      this.gridColumnApi.setColumnsVisible(hide, false);
      this.gridColumnApi.moveColumns(setFields.show, 0);

      this.headerHeightSetter(null);
    });
  }

  ngOnDestroy() {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }

}
