import { Injectable } from "@angular/core";
import { OktaAuth } from "@okta/okta-auth-js";
import { BehaviorSubject } from "rxjs";
import { environment } from "src/environments/environment";
const SCOPES = ["openid", "profile", "email"];

@Injectable({
  providedIn: "root",
})
export class AuthClientService {
  authClient: OktaAuth;
  currentUser = new BehaviorSubject<any>(null);
  config = {
    issuer: environment.issuer,
    clientId: environment.clientId,
    redirectUri: environment.redirectUri,
  };
  constructor() {
    this.authClient = new OktaAuth(this.config);
  }
  startAuthService() {
    this.main();
  }
  getAuthClient() {
    return this.authClient;
  }
  setCurrentUser(userData: any) {
    this.currentUser.next(userData);
  }
  async main() {
    let currentUser = this.currentUser;
    this.authClient.authStateManager.subscribe(function (authState) {
      if (!authState.isAuthenticated) {
        return;
      } else {
        let userInfo = authState.accessToken;
        const userData = {
          givenName: userInfo.claims.first_name
            ? userInfo.claims.first_name
            : userInfo.claims.full_name,
          mail: userInfo.claims.email_address,
        };
        localStorage.setItem("userData", JSON.stringify(userData));
        currentUser.next(userData);
        return;
      }
    });

    if (this.authClient.token.isLoginRedirect()) {
      const { tokens } = await this.authClient.token.parseFromUrl();
      this.authClient.tokenManager.setTokens(tokens);
    }

    this.authClient.start();
  }

  loginWithRedirect() {
    const tokenParams = { scopes: SCOPES };
    this.authClient.token.getWithRedirect(tokenParams);
  }
  async logOut() {
    this.authClient.signOut({
      postLogoutRedirectUri: environment.redirectUri,
    });
  }
  async logOutUserFromSession() {
    this.authClient.tokenManager.clear();
  }
  getCurrentUser() {
    return this.currentUser.asObservable();
  }
}
