<div class="toast toast--loose toast-width row" style="z-index: 9999;">
    <div class="toast__icon col-md-1"
        [ngClass]="(toast.type === 'success') ? 'text-success icon-check-outline' : (toast.type === 'error') ? 'text-danger icon-error-outline' : 'text-info icon-warning-outline'">
    </div>
    <div class="toast__body col-md-10">
        <div class="toast__title">{{toast.title | translate}}</div>
        <div class="toast__message" style="white-space: pre-line;max-height: 200px;overflow: auto;" [innerHTML]="toast.body | translate"></div>
    </div>
    <div class="col-md-1">
        <a (click)="remove.emit(i)" class="toast__close icon-close"></a>
    </div>
</div>