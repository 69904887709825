<form  [formGroup]="validations" autocomplete="off">
  <div mat-dialog-content> 
    <mat-form-field>
      <mat-card-title style="font-size: large;"> Dimms Family</mat-card-title>
      <div class="panel panel--compressed" style="max-width:800px;min-width: 500px;">
        <table class="table table-bordered">
          <tbody>
            <tr>              
              <td class="mandatory"> 
                <mat-label>Name</mat-label>
                <mat-error  *ngIf="validations.get('name').hasError('required')" style="color:red"></mat-error>
              </td>
              <td>
                <div class="form-group" > 
                  <div class="form-group__text">
                <input matInput  [ngClass]=" validations.get('name').hasError('required') ? 'text-danger': 'text-normal'" [(ngModel)]="dimmsFamilyData.name" formControlName="name"  required  >
              </div></div>
              </td> 
              <!--<td><input matInput [(ngModel)]="dimmsFamilyData.name"></td> -->
              
                       
            </tr> 
            <tr>
              <td></td>
              <td></td>
            </tr> 
            <tr>
              <td class="mandatory"> 
                <mat-label>Rank </mat-label>
                <mat-error  *ngIf="validations.get('rank').hasError('required')" style="color:red"></mat-error>
              </td>
              <td>
                <div class="form-group" > 
                  <div class="form-group__text">
                <input matInput maxlength="10" [ngClass]=" validations.get('rank').hasError('required') || validations.get('rank').hasError('pattern') ? 'text-danger': 'text-normal'" [(ngModel)]="dimmsFamilyData.rank" formControlName="rank"  required >
                  </div></div>
              </td>  
            </tr>
            <tr>
              <td></td>
              <td>
                <mat-error *ngIf ="validations.get('rank').hasError('pattern')" style = "color:red " >Only Positive Integers are allowed</mat-error>
              </td>
            </tr>
            <tr>
              <td class="mandatory">
                 <mat-label>Speed (MHz)</mat-label>
                 <mat-error  *ngIf="validations.get('speed').hasError('required')" style="color:red"></mat-error>
              </td>
              <td>
                <div class="form-group" > 
                  <div class="form-group__text">
                <input matInput maxlength="10" [ngClass]=" validations.get('speed').hasError('required') || validations.get('speed').hasError('pattern')? 'text-danger': 'text-normal'" [(ngModel)]="dimmsFamilyData.speed" formControlName="speed"  required >
                  </div></div>
              </td>
              <td></td>              
            </tr> 
            <tr>
              <td></td>
              <td>
                <mat-error *ngIf ="validations.get('speed').hasError('pattern')" style = "color:red " >Only Positive Integers are allowed</mat-error>
              </td>
            </tr>
            <tr>
              <td class="mandatory"> 
                <mat-label>Silicon Size</mat-label>
                <mat-error  *ngIf="validations.get('siliconSize').hasError('required')" style="color:red"></mat-error>
              </td>
              <td>
                <div class="form-group" > 
                  <div class="form-group__text">
                <input matInput maxlength="10" [ngClass]=" validations.get('siliconSize').hasError('required') || validations.get('siliconSize').hasError('pattern')? 'text-danger': 'text-normal'" [(ngModel)]="dimmsFamilyData.siliconSize" formControlName="siliconSize"  required >
                  </div></div>
              </td>
            </tr>  
            <tr>
              <td></td>
              <td>
                <mat-error *ngIf ="validations.get('siliconSize').hasError('pattern')" style = "color:red " >Only Positive Integers are allowed</mat-error>
              </td>
            </tr> 
          </tbody>
        </table>
      </div>
    </mat-form-field>
  </div>
  
  <div mat-dialog-actions>
    <table class="table table-bordered">
      <tbody>
        <tr>
          <td>
            <mat-card-actions> 
              <button mat-button  (click)="onSave()"   class="btn btn--small " 
              style="height: 24px;margin: 5px 20px 0 0;"  [disabled]="!validations.valid">Submit</button>
            </mat-card-actions>
           <!-- <mat-card-actions>
              <button mat-button  [mat-dialog-close]="dimmsFamilyData" cdkFocusInitial class="btn btn--small " style="height: 24px;margin: 5px 20px 0 0;">Submit</button>
            </mat-card-actions>-->
            
            <button mat-button (click)="onNoClick()"  class="btn btn--small " style="height: 24px;margin: 5px 20px 0 0;">Cancel</button>
          </td>
        </tr>
      </tbody>
    </table>   
    
    
  </div>
</form>    