import { Component, OnInit } from '@angular/core';
import { GridOptions } from 'ag-grid-community';
import { Subscription } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';
import { ToasterService } from 'src/app/shared/components/toast/toast.service';
import { errorCodes } from 'src/constants/errorCodes';
import { UrlConstant } from 'src/constants/url.constants';
import { DocumentIconRendererComponent } from 'src/app/components/admin/admin-tab/document/document-icon-renderer/document-icon-renderer.component';
import * as moment from 'moment';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PlatformConstant } from 'src/constants/platform.constants';
import { environment } from "../../../../../environments/environment";

@Component({
  selector: 'app-release-notes',
  templateUrl: './release-notes.component.html',
  styleUrls: ['./release-notes.component.css']
})
export class ReleaseNotesComponent implements OnInit {
  showLoader: boolean;
  paginationPageSize = 10;
  frameworkComponents: any;
  subscription: Subscription = new Subscription();
  docDown = 0;
  columnDefs = [
    {
      field: "filename",
      headerName: "Document Name",
      sortable: true,
      filter: true,
      editable: true,
      maxWidth: 700,
      suppressSizeToFit: false,
      cellClass: ["document-name-color"],
      minWidth: 200,
    },
    {
      headerName: "Id",
      hide: true,
    },
    {
      field: "metadata.title",
      headerName: "Title",
      sortable: true,
      filter: true,
      minWidth: 200,
    },
    {
      field: "uploadDate",
      headerName: "Created On",
      maxWidth: 500,
      suppressSizeToFit: false,
      minWidth: 120,
      cellRenderer: (data) => {
        return data.value ? moment(data.value).format('MMM DD, YYYY') : null;
      }
    },
    {
      headerName: "",
      minWidth: 80,
      maxWidth: 100,
      cellRenderer: "buttonRenderer",
      cellRendererParams: {
        onClick: this.onDownLoadButtonClick.bind(this),
        label: "DownLoad",
      },
    },

  ];

  rowData = [];
  gridOptions: GridOptions = {}
  errorMsg: any;
  constructor(private apiService: ApiService,
    private toaster: ToasterService,
    public dialog: MatDialog
  ) {
    this.frameworkComponents = {
      buttonRenderer: DocumentIconRendererComponent,
    };
  }

  ngOnInit(): void {
    this.getDocumentList();
  }
  getDocumentList() {
    const url = UrlConstant.GET_DOCUMENT;
    this.showLoader = true;
    this.subscription.add(this.apiService.getMethod(url).subscribe((result: any) => {

      this.rowData = result.filter((res) => res.metadata.title === 'Release Notes');
      this.showLoader = false;
    },
      (error) => {
        this.errorMsg = error?.error ? error.error?.error?.errorString : errorCodes.GENERIC_ERROR_MESSAGE;
        this.toaster.show("error", this.errorMsg, error ? error?.message : "");
        window.scroll(0, 0);
        this.showLoader = false;
      }));
  }


  onGridReady(params) {
    this.gridOptions.api = params.api;
    this.gridOptions.columnApi = params.columnApi;
    this.gridOptions.api?.sizeColumnsToFit();
  }

  onDownLoadButtonClick(params: any) {

    this.docDown += 1;
    const url = UrlConstant.DOWNLOAD_DOCUMENT + '?objectId=' + params.data._id;

    var new_url = environment.apiUrl + url;
    // window.open(new_url);

  }
}
