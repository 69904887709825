<mat-card-title>
  <div mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle style="margin-bottom: 8px;">
    <h4 style="float: left;font-size: large;font-weight: 400;"> Storage Device</h4>
    <div style="display:flex;
    flex-direction: row-reverse;">
      <div class="flex-center margin_auto_t_b">
        <a class="anchor-close" id="close" title="Close" (click)="onCancel()">
          <span class="icon-close icon-size-24 icon-close"></span>
        </a>
      </div>
      <button mat-button class="btn btn--small " style="height: 24px;margin: 0px 20px 0 0;"
        (click)="openAdditionalAtt_Modal()">Additional Fields</button><span class="margin_auto_t_b"
        style="font-size: 65%;margin-top: -2px;margin-right:5px;"
        *ngIf="additionalFields.length>0">{{additionalFields.length}} Field(s) Added</span>
    </div>
  </div>
</mat-card-title>
<form [formGroup]="validations" autocomplete="off">
  <div mat-dialog-content>
    <mat-form-field>

      <div class="panel panel--compressed" style="max-width:800px;min-width: 500px;">
        <table class="table table-bordered">
          <tbody>
            <tr>
              <td class="mandatory">
                <mat-label>Display Name</mat-label>
                <mat-error *ngIf="validations.get('displayName').errors" style="color:red"></mat-error>

              </td>
              <td>
                <div class="form-group">
                  <div class="form-group__text">
                    <input matInput [ngClass]=" validations.get('displayName').errors ? 'text-danger': 'text-normal'"
                      [(ngModel)]="storageData.displayName" formControlName="displayName" required>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td class="mandatory" style="vertical-align: top;padding-top: 15px;
              ">
                <mat-label>PID</mat-label>
              </td>
              <td>
                <app-search-box *ngIf="showPID" [type]="'component'" [pid]="selectedPid"
                  (pidSelectionEvent)="selectedPID($event)"></app-search-box>
              </td>
              <td></td>
            </tr>

            <tr>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td class="mandatory">
                <mat-label>MPN</mat-label>
              </td>
              <td>
                <div class="form-group">
                  <div class="form-group__text">
                    <input matInput [(ngModel)]="storageData.mpn" [ngClass]="'text-normal'" formControlName="mpn">
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td class="mandatory" style="vertical-align: top;padding-top: 15px;">
                <mat-label>Device Type</mat-label>
                <mat-error *ngIf="validations.get('storageDeviceType').hasError('required')"
                  style="color:red"></mat-error>
              </td>
              <td>
                <div class="form-group storage">
                  <ngx-select-dropdown [multiple]="false"
                    [ngClass]=" validations.get('storageDeviceType').hasError('required') ? 'ngx-select-danger ngx-dropdown-button': 'ngx-select-normal ngx-dropdown-button'"
                    [(ngModel)]="selectedStorageDeviceType" formControlName="storageDeviceType"
                    [config]="{search:false,displayKey:'viewValue'}" [options]="storageDeviceTypes">
                  </ngx-select-dropdown>
                </div>
              </td>
            </tr>
            <tr>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td class="mandatory">
                <mat-label>Storage Zone</mat-label>
              </td>
              <td>
                <label class="checkbox" style="margin: 0px 5px 0px 5px;">
                  <input class="text-danger" type="checkbox" (click)="checkedHandler($event,'FRONT')"
                    [checked]="frontChecked" value="FRONT" [ngClass]=" !zoneCkeckboxSelected ? 'text-danger': 'hover'"
                    name="front">
                  <span class="checkbox__input" style="margin-right: 5px;"
                    [ngClass]=" !zoneCkeckboxSelected ? 'text-danger': ''">
                  </span>FRONT
                </label>
                <label class="text-danger" class="checkbox" style="margin: 0px 5px 0px 5px;">
                  <input type="checkbox" (click)="checkedHandler($event, 'REAR')" [checked]="rearChecked" value="REAR"
                    [ngClass]=" !zoneCkeckboxSelected ? 'text-danger': 'hover'" name="rear">
                  <span class="checkbox__input" style="margin-right: 5px;"
                    [ngClass]=" !zoneCkeckboxSelected ? 'text-danger': ''">
                  </span>REAR
                </label>
                <label class="checkbox" style="margin: 0px 5px 0px 5px;">
                  <input type="checkbox" (click)="checkedHandler($event, 'INTERNAL')" [checked]="internalChecked"
                    value="INTERNAL" [ngClass]=" !zoneCkeckboxSelected ? 'text-danger': 'hover'" name="internal">
                  <span class="checkbox__input" [ngClass]=" !zoneCkeckboxSelected ? 'text-danger': ''"
                    style="margin-right: 5px;"></span>INTERNAL
                </label>
              </td>
            </tr>
            <tr>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td class="mandatory">

                <mat-label>Idle Power(W)</mat-label>
                <mat-error *ngIf="validations.get('idleWatts').errors" style="color:red"></mat-error>

              </td>

              <td>
                <div class="form-group">
                  <div class="form-group__text">
                    <input matInput (ngModelChange)="validateWatts($event)" [ngClass]="validations.get('idleWatts').hasError('pattern') ||
                                 validations.get('idleWatts').hasError('min') || 
                                 validations.get('idleWatts').hasError('required') ||
                                 validations.get('idleWatts').hasError('pattern') ||
                                 showWattErr ||
                                 IdleWattsErr ? 'text-danger': 'text-normal'" [(ngModel)]="storageData.idleWatts"
                      formControlName="idleWatts" required>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td></td>
              <td>

                <mat-error *ngIf="(validations.get('idleWatts').hasError('pattern') || validations.get('idleWatts').hasError('min')) && !IdleWattsErr" style=color:red>Only Positive
                  Integers are allowed</mat-error>
                <mat-error *ngIf="showWattErr" style=color:red>Idle Power should be less than or equal to Max Power
                </mat-error>
                <mat-error *ngIf="IdleWattsErr" style=color:red>Only Positive Integers are allowed </mat-error>

              </td>
            </tr>
            <tr>
              <td class="mandatory">
                <mat-label>Max Power(W)</mat-label>
                <mat-error *ngIf="validations.get('maxWatts').errors" style="color:red"></mat-error>
              </td>
              <td>
                <div class="form-group">
                  <div class="form-group__text">
                    <input (ngModelChange)="validateWatts($event)" matInput [ngClass]="validations.get('maxWatts').hasError('pattern') || 
                                  validations.get('maxWatts').hasError('min')   || 
                                  validations.get('maxWatts').hasError('required') ||
                                  validations.get('maxWatts').hasError('pattern') ||
                                  showWattErr ||
                                  IdleMaxWattsErr ? 'text-danger': 'text-normal'" [(ngModel)]="storageData.maxWatts"
                      formControlName="maxWatts" required>
                  </div>
                </div>
              </td>
            </tr>
            <td></td>
            <td>
              <mat-error *ngIf="(validations.get('maxWatts').hasError('pattern') || validations.get('maxWatts').hasError('min')) && !IdleMaxWattsErr" style=color:red>Only Positive Integers
                are allowed</mat-error>
              <mat-error *ngIf="showWattErr" style=color:red>Max Power should be greater than or equal to Idle
                Power</mat-error>
              <mat-error *ngIf="IdleMaxWattsErr" style=color:red>Only Positive Integers are allowed </mat-error>
            </td>

            <tr>
              <td class="mandatory"> <mat-label>MTBF Hours</mat-label></td>
              <td>
                <div class="form-group">
                  <div class="form-group__text">
                    <input matInput (ngModelChange)="validateWatts($event)" [ngClass]="validations.get('mtbf').hasError('pattern') || mtbfErr ? 'text-danger': 'text-normal'" [(ngModel)]="storageData.mtbf"
                      formControlName="mtbf">
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td></td>
              <td>
                <mat-error *ngIf="validations.get('mtbf').hasError('pattern')" style=color:red>Only Positive Integers
                  are allowed</mat-error>
                <mat-error *ngIf="mtbfErr" style=color:red>Only Non-Negative Integers are allowed </mat-error>
              </td>
            </tr>
            <tr>
              <td class="mandatory">
                <mat-label>Capacity (GB)</mat-label>
                <mat-error *ngIf="validations.get('capacity').errors" style="color:red"></mat-error>
              </td>
              <td>
                <div class="form-group">
                  <div class="form-group__text">
                    <input matInput maxlength="10" (ngModelChange)="validateWatts($event)" [ngClass]="validations.get('capacity').hasError('pattern') || 
                           validations.get('capacity').hasError('min')   || 
                           validations.get('capacity').hasError('required') ||
                           validations.get('capacity').hasError('pattern') ||
                           capacityErr  ? 'text-danger': 'text-normal'" [(ngModel)]="storageData.capacity"
                      formControlName="capacity" required>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td></td>
              <td>
                <mat-error *ngIf="capacityErr" style=color:red>Only Positive Integers are allowed </mat-error>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </mat-form-field>
  </div>

  <div mat-dialog-actions>
    <table class="table table-bordered">
      <tbody>
        <tr>
          <td>
            <mat-card-actions>
              <button mat-button (click)="onSave()" class="btn btn--small " style="height: 24px;margin: 5px 20px 0 0;"
                [disabled]="!validations.valid || showWattErr || IdleWattsErr || IdleMaxWattsErr || mtbfErr || capacityErr || !zoneCkeckboxSelected || !selectedPid">Save
                & Close</button>
            </mat-card-actions>

            <button mat-button (click)="onNoClick()" class="btn btn--small " style="height: 24px;margin: 5px 20px 0 0;"
              [disabled]="!validations.valid || showWattErr || IdleWattsErr || IdleMaxWattsErr || mtbfErr || capacityErr || !zoneCkeckboxSelected  || !selectedPid">Save</button>
          </td>
        </tr>
      </tbody>
    </table>

  </div>
</form>