import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { projectTemplate } from './../../../constants/configuration';
import { IconRendererComponent } from "../../shared/components/icon-renderer/icon-renderer.component";
import { v4 as uuidv4 } from "uuid";
import { TranslateService } from "@ngx-translate/core";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import {
  CngContentConfigType,
  CngModalService,
  CngModalSize,
} from "@cisco/cui-ng";
import { ConfirmationBoxComponent } from "../../shared/components/confirmation-box/confirmation-box.component";
import { SharedService } from "src/app/shared/shared.service";
import { Subscription } from "rxjs";
import * as CryptoJS from "crypto-js";
import { InputProjectNameComponent } from "src/app/shared/components/input-project-name/input-project-name.component";
import { ToasterService } from "src/app/shared/components/toast/toast.service";
import { AppConstant } from "src/constants/app.constants";
import { GridOptions } from "ag-grid-community";
import { errorCodes } from "src/constants/errorCodes";
import { UcsSizerService } from "src/app/services/ucs-sizer.service";
@Component({
  selector: "app-project-list",
  templateUrl: "./project-list.component.html",
  styleUrls: ["./project-list.component.css"],
})
export class ProjectListComponent implements OnInit, OnDestroy {
  subscription: Subscription = new Subscription();
  fileUrl: any;
  fileName: string;
  gridApi: any;
  gridColumnApi: any;
  gridParmas: any;
  @ViewChild('importProject') importProjectFile;
  //gridOptions: GridOptions = <GridOptions>{};
  api: any;
  frameworkComponents: any;
  ucsProjectCounter = 0;
  projectData: any;
  projectArray = [];
  projectListNames: string[] = [];
  paginationPageSize = 10;
  message: string;
  originalText: any;
  locale: string = 'en';
  localeString: any = {
    "en": "en-US",
    "ja": "ja-JP",
    "de": "de-DE",
    "fr": "fr-FR",
    "ko": "ko-KR"
  }
  columnDefs = [
    {
      field: "projectName",
      headerName: this.translate.instant('PROJECT_NAME'),
      sortable: true,
      filter: true,
      editable: true,
      maxWidth: 500,
      suppressSizeToFit: false,
      cellClass: ["project-name-color"],
      minWidth: 200,
      cellRenderer: "buttonRenderer",
      cellRendererParams: {
        label: "Edit",
      },
      headerValueGetter: this.localizeHeader.bind(this)
    },
    {
      headerName: "Id",
      hide: true,
    },
    {
      field: "created",
      headerName: this.translate.instant('CREATED_ON'),
      maxWidth: 400,
      suppressSizeToFit: false,
      minWidth: 120,
      headerValueGetter: this.localizeHeader.bind(this),
      cellRenderer: (date) => {
        this.locale = this.translate.getBrowserLang();
        return date.value ? (new Date(date.value)).toLocaleDateString(this.localeString[this.locale], { year: 'numeric', month: 'short', day: 'numeric' }) : '';
      }
    },
    {
      field: "lastUpdated",
      headerName: this.translate.instant('LAST_UPDATED'),
      maxWidth: 400,
      suppressSizeToFit: false,
      minWidth: 120,
      headerValueGetter: this.localizeHeader.bind(this),
      cellRenderer: (date) => {
        this.locale = this.translate.getBrowserLang();
        return date.value ? (new Date(date.value)).toLocaleDateString(this.localeString[this.locale], { year: 'numeric', month: 'short', day: 'numeric' }) : '';
      }
    },
    {
      headerName: "",
      minWidth: 40,
      maxWidth: 50,
      cellRenderer: "buttonRenderer",
      cellRendererParams: {
        onClick: this.onDownLoadButtonClick.bind(this),
        label: "DownLoad",
        fileUrl: "",
      },
    },
    {
      headerName: "",
      minWidth: 40,
      maxWidth: 50,
      cellRenderer: "buttonRenderer",
      cellRendererParams: {
        onClick: this.onCloneButtonClick.bind(this),
        label: "Clone",
      },
    },
    {
      headerName: "",
      minWidth: 40,
      maxWidth: 50,
      cellRenderer: "buttonRenderer",
      cellRendererParams: {
        onClick: this.onDeleteButtonClick.bind(this),
        label: "Delete",
      },
    },
  ];

  rowData = [];
  gridOptions: GridOptions = {}
  isResizerList: boolean = false;
  UCS_PRJ: string = "UCS.UserProjects";
  SIZER_PRJ: string = "UCS.SizerProjects";
  constructor(
    private translate: TranslateService,
    private router: Router,
    private modalService: CngModalService,
    private sharedService: SharedService,
    private toaster: ToasterService,
    private sizerService: UcsSizerService,
  ) {
    this.frameworkComponents = {
      buttonRenderer: IconRendererComponent,
    };
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if (event.url == '/projectList') {
          this.updateProjectData();
        }
      }
    });
    if (this.router.url.includes('ucs-resizer/projectList')) {
      this.sizerService.fetchProjects();
      this.isResizerList = true;
      this.ucsProjectCounter = 0;
      this.columnDefs.find(c => c.field == 'projectName').cellRendererParams = {
        label: "Sizer"
      };
    }
  }

  ngOnInit(): void {
    this.subscription.add(
      this.sharedService.currentMessage.subscribe((message: any) => {
        this.message = message.key;

        if (this.message == "newProject") {
          this.onCreateNewPrject();
          this.subscription.add(this.sharedService.sendMessage(""));
        }
      })
    );
    this.updateProjectData();
  }

  updateProjectData() {
    if (localStorage.getItem(`${this.isResizerList ? this.SIZER_PRJ : this.UCS_PRJ}`) !== null) {
      // update component properties from LocalStorage
      this.rowData = [];
      this.projectArray = [];
      this.projectListNames = [];
      this.projectArray = localStorage.getItem(`${this.isResizerList ? this.SIZER_PRJ : this.UCS_PRJ}`).split(",").filter(v => { return v; });
      this.projectArray.forEach((element) => {
        const data = JSON.parse(localStorage.getItem(`${this.isResizerList ? this.SIZER_PRJ : this.UCS_PRJ}-${element}`));
        this.projectListNames.push(data?.projectName);
        const createdDate = new Date(data?.createdDate).toString().slice(4, 15);
        const updateDate = new Date(data?.updatedDate).toString().slice(4, 15);
        this.rowData.push({
          projectName: data?.projectName,
          Id: data?.projectId,
          created: createdDate.slice(0, 6) + "," + createdDate.slice(6, 15),
          lastUpdated: updateDate.slice(0, 6) + "," + updateDate.slice(6, 15),
        });
      });
    }
  }

  onGridReady(params) {
    this.gridOptions.api = params.api;
    this.gridOptions.columnApi = params.columnApi;
    this.gridOptions.api?.sizeColumnsToFit();

    let curLanguage = this.translate.getBrowserLang();
    this.locale = curLanguage;
    if (curLanguage !== 'en') {
      this.translate.use(curLanguage);
      this.gridOptions.api.refreshHeader();
    }
  }

  onCloneButtonClick(params: any) {
    const tempData = JSON.parse(localStorage.getItem(`${this.isResizerList ? this.SIZER_PRJ : this.UCS_PRJ}-${params.data.Id}`));
    const guid = uuidv4();
    this.updateProjectArray();
    this.createNewProjectData(tempData, guid, "", new Date().toISOString(), new Date().toISOString());
    //update local storage   
    let unit = this.projectData?.measurementUnit;

    this.updateLocalStorage(this.ucsProjectCounter, this.projectData, this.projectArray, guid, unit);
    this.gridOptions.api?.updateRowData({ add: [this.projectData] });
    this.updateProjectData();
  }

  onDeleteButtonClick(params: any) {
    localStorage.removeItem(`${this.isResizerList ? this.SIZER_PRJ : this.UCS_PRJ}-${params.data.Id}`);
    const index = this.projectArray.indexOf(params.data.Id);
    if (index !== -1) {
      this.projectArray.splice(index, 1);
      this.projectListNames.splice(index, 1);
      this.rowData.splice(index, 1);
    }
    localStorage.setItem(`${this.isResizerList ? this.SIZER_PRJ : this.UCS_PRJ}`, this.projectArray.toString());
    this.gridOptions.api?.updateRowData({ remove: [params.node.data.Id] });
    this.updateProjectData();
  }

  onDownLoadButtonClick(params: any) { }


  onCreateNewPrject() {
    // 
    //
    const guid = uuidv4();
    this.updateProjectArray();
    this.createNewProjectData(projectTemplate, guid, "", new Date().toISOString(), new Date().toISOString()
    );
    //update local storage

    this.updateLocalStorage(this.ucsProjectCounter, this.projectData, this.projectArray, guid, "Imperial");
    this.updateSessionStorage(guid);

    /* istanbul ignore function */
    this.gridOptions.api?.updateRowData({ add: [this.projectData] });
    this.updateProjectData();
    this.navigateToProjectDetails();

    localStorage.setItem("currentProjectId", guid);
  }
  navigateToProjectDetails(fragment?: string) {
    if (this.router.url.includes('admin/')) {
      if (this.router.url.includes('ucs-resizer')) {
        this.router.navigate(["/admin/ucs-resizer/projectDetails"], { fragment: fragment });
      } else this.router.navigate(["/admin/projectDetails"], { fragment: fragment });
    } else {
      if (this.router.url.includes('ucs-resizer')) {
        this.router.navigate(["/ucs-resizer/projectDetails"], { fragment: fragment });
      } else this.router.navigate(["/projectDetails"], { fragment: fragment });
    }
  }
  onImportPrject(params) {
    const fileList = params.target.files;
    if (fileList[0]) {
      const reader = new FileReader();
      const data = reader.readAsDataURL(fileList[0]);
    }
    const files = fileList;
    if (files && files.length > 0) {
      const extension = fileList[0].name.match(/\.([^\.]+)$/)[1];
      if (extension !== 'prj') {
        this.toaster.show('error', errorCodes.WRONG_FILE_FORMAT, errorCodes.FILE_FORMAT_MESSAGE, 5000);
        return;
      }
    }
    if (files && files.length > 0) {
      let file: File = files.item(0);
      let reader: FileReader = new FileReader();
      reader.readAsBinaryString(file);
      reader.onload = (e) => {
        let data: string = reader.result as string;
        const key_sect = AppConstant.KEY_SEC;
        let bytes = CryptoJS.AES.decrypt(data, key_sect);
        this.originalText = bytes.toString(CryptoJS.enc.Utf8);

        const tempData = JSON.parse(this.originalText);
        const guid = uuidv4();
        this.openImportProjectModal(tempData, guid, tempData.projectName, tempData.createdDate, tempData.updatedDate);
      };
    }
    this.importProjectFile.nativeElement.value = '';
  }
  public async openImportProjectModal(
    inputProjectData: any,
    inputGuid: string,
    inputProjectName: string,
    inputCreateDate: any,
    inputUpdateDate: any
  ) {
    const result = await this.modalService
      .open({
        content: {
          type: CngContentConfigType.COMPONENT,
          content: InputProjectNameComponent,
          componentData: {
            key: {
              projectName: inputProjectName,
              matchNames: this.projectListNames
            }
          },
        },
        size: CngModalSize.SMALL,
      })
      .onDismiss.toPromise();
    if (result !== "closed") {
      this.updateProjectCounter(result.toString());
      this.updateProjectArray();
      this.createNewProjectData(inputProjectData, inputGuid, result.toString(), inputCreateDate, inputUpdateDate);
      /// update local storage      
      let unit = this.projectData?.measurementUnit;
      this.updateLocalStorage(this.ucsProjectCounter, this.projectData, this.projectArray, inputGuid, unit);
      this.gridOptions.api?.updateRowData({ add: [this.projectData] });
      this.updateProjectData();
    }
  }
  /**
   * Update current project count in case a project is imported
   * and the imported project count > current project count 
   * @param inputProjectName 
   * This does not disallow user from editing the project name
   */
  updateProjectCounter(inputProjectName: string) {
    let regex: RegExp = new RegExp(/Ucs-Power-project_[0-9]+/, 'g');
    if (inputProjectName.match(regex)) {
      let importedProjectCount = Number(inputProjectName.substring(18));
      if (!isNaN(importedProjectCount) && importedProjectCount > 0) {
        if (Number(localStorage.getItem("UCS.ProjectNameCounter")) < importedProjectCount) {
          this.ucsProjectCounter = importedProjectCount - 1;
          localStorage.setItem("UCS.ProjectNameCounter", this.ucsProjectCounter.toString());
        }
      }
    }
  }
  updateProjectArray() {
    let userProjects = localStorage.getItem(`${this.isResizerList ? this.SIZER_PRJ : this.UCS_PRJ}`);
    if (userProjects && userProjects.length > 0) this.projectArray = userProjects.split(",").filter(v => { return v; });
  }
  onCellValueChanged(params: any) {
    //update project properties
    this.projectData = JSON.parse(
      localStorage.getItem(`${this.isResizerList ? this.SIZER_PRJ : this.UCS_PRJ}-${params.data.Id}`)
    );
    if (params.data.projectName == '') {
      this.toaster.show('error', errorCodes.PROJECTNAME_EMPTY, errorCodes.PROJECTNAME_MESSAGE, 5000);
      this.projectData.projectName = params.oldValue;
      localStorage.setItem(`${this.isResizerList ? this.SIZER_PRJ : this.UCS_PRJ}-${params.data.Id}`, JSON.stringify(this.projectData));
      this.updateProjectData();
    } else {
      this.projectData.projectName = params.newValue;
      //update local storage     
      localStorage.setItem(`${this.isResizerList ? this.SIZER_PRJ : this.UCS_PRJ}-${params.data.Id}`, JSON.stringify(this.projectData));
    }
  }

  public async openModal() {
    const result = await this.modalService
      .open({
        content: {
          type: CngContentConfigType.COMPONENT,
          content: ConfirmationBoxComponent,
          componentData: {
            someText: this.translate.instant(
              "ARE_YOU_SURE_TO_CREATE_A_NEW_PROJECT"
            ),
            key: "newProject",
          },
        },
        size: CngModalSize.SMALL,
      })
      .onDismiss.toPromise();
  }

  updateLocalStorage(counter: number, inputData: any, inputArray: any, guid: string, unit = 'Imperial') {
    localStorage.setItem(`${this.isResizerList ? this.SIZER_PRJ : this.UCS_PRJ}`, inputArray.toString());
    localStorage.setItem("UCS.ProjectNameCounter", counter.toString());
    localStorage.setItem(`${this.isResizerList ? this.SIZER_PRJ : this.UCS_PRJ}-${guid}`, JSON.stringify(inputData));
    localStorage.setItem("UCS.GlobalMeasurmentUnit", unit);
    localStorage.setItem("latestProjectId", guid.toString());
  }

  updateSessionStorage(guid) {
    sessionStorage.setItem("myProjectId", guid);
  }

  createNewProjectData(inputProjectData: any, inputGuid: string, inputProjectName: string, inputCreateDate: any,
    inputUpdateDate: any) {
    this.ucsProjectCounter = Number(localStorage.getItem("UCS.ProjectNameCounter")) + 1;
    this.projectData = inputProjectData;
    this.projectArray.push(inputGuid);
    this.projectData.projectId = inputGuid;
    this.projectData.projectName = inputProjectName ? inputProjectName
      : `Ucs-${this.translate.instant("POWER")}-${this.translate.instant("PROJECT")}_${this.ucsProjectCounter}`;
    this.projectData.createdDate = inputCreateDate;
    this.projectData.updatedDate = inputUpdateDate;
  }
  public localizeHeader(parameters): string {
    let headerIdentifier = parameters.colDef.headerName;
    let translateHeader = headerIdentifier.toUpperCase().split(' ').join('_');

    return this.translate.instant(translateHeader);
  }
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
