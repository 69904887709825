import { Injectable } from '@angular/core';
import { map, catchError } from 'rxjs/operators';
import {
    CanActivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    UrlTree,
    CanActivateChild,
    CanDeactivate, Router
} from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { DataServiceService } from "./data-service.service";
import { MaintenanceScreenComponent } from "./maintenance-screen/maintenance-screen.component";
import { ApiService } from "./services/api.service";
import { BlockUI, NgBlockUI } from 'ng-block-ui';

@Injectable({
    providedIn: 'root'
})
export class ApplicationStatusGuard implements CanActivate {
    constructor(private data: DataServiceService, private apiService: ApiService, private router: Router) {

    }

    message: any;
    subscription: Subscription;
    @BlockUI() blockUI: NgBlockUI;
    canActivate(): Observable<boolean> | Promise<boolean> | boolean {
        if (!this.message) {
            return new Promise(res => {
                this.blockUI.start("Loading...");
                this.apiService.getMethod("test").subscribe(
                    (data) => {
                        this.blockUI.stop();
                        this.message = true;
                        res(true);
                    },
                    (error) => {
                        this.blockUI.stop();
                        this.data.setAPPlicationStatus(true);
                        this.message = false;
                        this.router.navigate(['maintenance']);
                        res(false);
                    }
                );
            });
        }
        else { return true }
    }

    checkDB(): Promise<boolean> {
        const url = '/actuator/health';
        return new Promise(res => {
            this.blockUI.start("Loading...");
            this.apiService.getMethodActuatorHealth(url).subscribe(
                (data) => {
                    this.message = true;
                    this.blockUI.stop();
                    res(true);
                },
                (error) => {
                    this.data.setDBStatus(true);
                    this.message = false;
                    this.router.navigate(['maintenance']);
                    res(false);
                    this.blockUI.stop();
                }
            );
        });
    }
}
