import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Toast } from '../toast.interface';
import { ToasterService } from '../toast.service';

@Component({
  selector: 'app-toast-container',
  templateUrl: './toast-container.component.html',
  styleUrls: ['./toast-container.component.css']
})
export class ToastContainerComponent implements OnInit, OnDestroy { 
  
  toasts: Toast[] = [];
  subscriptions: Subscription = new Subscription();  
  
  constructor(public toaster: ToasterService) {}  

  ngOnInit() {
    this.subscriptions.add(this.toaster.toast$
      .subscribe(toast => {
        if(toast.markedForDelete){
          let idx=this.toasts.findIndex(v=> v.title == toast.title)
          if(idx >=0)
          this.toasts.splice(idx, 1);
        }else{
          this.toasts = [toast, ...this.toasts];
          setTimeout(() => {
            this.toasts = this.toasts.filter(t => t.title !== toast.title);
          }, toast.delay || 5000);
        }
       
      }));
  }

  remove(index: number) {
    this.toasts = this.toasts.filter((v, i) => i !== index);
    //this.toasts.splice(index, 1);
  }

  ngOnDestroy() {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
    
  }
}
