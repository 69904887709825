import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from "ag-grid-angular";
import {
  CngContentConfigType,
  CngModalService,
  CngModalSize,
} from "@cisco/cui-ng";
import { ConfirmationBoxComponent } from "../confirmation-box/confirmation-box.component";
import { TranslateService } from "@ngx-translate/core";
import * as CryptoJS from "crypto-js";

import { Router } from "@angular/router";
import { AppConstant } from "src/constants/app.constants";
import { Subscription } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';
import { UrlConstant } from 'src/constants/url.constants';
import { HttpClient } from '@angular/common/http';
import { ComponentConstant } from 'src/constants/components.constants';


import { MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { CreateProcessorfamilyComponent } from 'src/app/components/admin/admin-tab/create-components/create-processorfamily/create-processorfamily.component'
import { ToasterService } from "src/app/shared/components/toast/toast.service";
import { errorCodes } from "src/constants/errorCodes";

@Component({
  selector: 'app-delete-render',
  templateUrl:'./delete-render.component.html',
  styleUrls: ['./delete-render.component.css']
})
export class DeleteRenderComponent implements OnInit , ICellRendererAngularComp {
  private params: any;

  agInit(params: any): void {
    this.params = params;       
  }  
  constructor(private modalService: CngModalService,
    private translate: TranslateService,    
    private router: Router,
    private apiService: ApiService,
    private httpClient: HttpClient,
    private toaster: ToasterService, 
    public dialog: MatDialog) { }

    subscriptions: Subscription = new Subscription();

  ngOnInit(): void {
  }
  
  refresh(params?: any): boolean {
    return true;
  }

  public async openModal($event) {
    const result = await this.modalService
      .open({
        content: {
          type: CngContentConfigType.COMPONENT,
          content: ConfirmationBoxComponent,
          componentData: {
            someText: this.translate.instant(
              "ARE_YOU_SURE_TO_UPDATE_THIS_COMPONENT"
            ),
            key: "edit",
            event: $event,
          },
        },
        size: CngModalSize.SMALL,
      })
      .onDismiss.toPromise();

    if (result == 'ok')
      this.onClick($event, "delete");
      
      
      

  }

  onClick($event, action: string) {    
        
        this.params.onClick(this.params);
        let data = {
          "id" : this.params?.data?.id, 
          "editor" : this.params?.data?.editor,
          "type" : this.params?.data?.type,
          "role" : "Admin",
          "text" : this.params?.data?.text
        };
        for (let [key, value] of Object.entries(data)) {
          
        }
        const url = UrlConstant.MANAGECOMPONENTS + UrlConstant.UPDATE ;        
        this.subscriptions.add(
          this.apiService
            .postMethod(url,data).subscribe(() => {  
              this.toaster.show('success', errorCodes.PLATFORM_DELETE_MESSAGE, '',5000);             
            })
        )        
  }

  openDialog($event): void {
    this.params.clicked(this.params.value);
    
    const dialogRef = this.dialog.open(CreateProcessorfamilyComponent, {      
      data: {
             "displayName" : "test"
            }            
    });   

    dialogRef.afterClosed().subscribe(result => {
     
         
    
          
    });
  }

  btnClickedHandler($event) {
    this.params.clicked(this.params.value);
  }

  
  

  

}
