<div class="edit-license">
  <div class="flex flex-between">
    <h4>Edit License</h4>
    <button (click)="license1 = license1_bk;l1Update = false;" class="btn btn--small btn--ghost" style="height: 24px;margin: 5px 20px 0 0;">Reset</button>
  </div>
  <div>
    <label  style="color: red;"> {{lblErrMsg}}</label>
  </div>
  <div id="loading-dots" *ngIf="showLoader">
    <div class="loading-dots loading-dots--dark" aria-label="Loading, please wait...">
      <span></span>
      <span></span>
      <span></span>
    </div>
  </div>
    <div style="padding: 0 10px;">
      <!-- <div style="margin-top: 7px;margin-left: 20px;display: flex;" *ngIf="showLoader" class="loading-dots loading-dots--dark">
        <span></span>
        <span></span>
        <span></span>
      </div> -->
      <div *ngIf="!showLoader">
    <div class="form-group" style="width: 100%;">
        <div class="form-group__text">
          <textarea
          [(ngModel)]="license1" (ngModelChange)="onLicense1Change($event)"
          id="textarea" class="textarea" rows="20"></textarea>
        </div>
      </div>
      <div style="display: flex;padding: 10px;">
        
        <button (click)="updateL1()" class="btn btn--primary"[disabled]="showErr" [ngClass]="{'disabled' : !l1Update}">Update</button>
      </div>
    </div>
  </div>
</div>