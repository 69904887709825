<!-- <input 
    type="checkbox" 
    (click)="checkedHandler($event)"
    [checked]="params.value"
/> -->

<div class="col-lg-2 col-md-4 col-6">
      
    <label class="checkbox">
        <input type="checkbox" class="hover" (click)="checkedHandler($event)"
        [checked]="params.value">
        <span class="checkbox__input"></span>
    </label>
</div>
