import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface DialogData {
  header: string;
}

@Component({
  selector: 'app-raise-case',
  templateUrl: './raise-case.component.html',
  styleUrls: ['./raise-case.component.css']
})
export class RaiseCaseComponent implements OnInit {

  shortDescription: string = '';
  description: string = '';
  comments: string = '';

  constructor(
    public dialogRef: MatDialogRef<RaiseCaseComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) { }

  ngOnInit(): void {
  }
  onRaiseCase() {
    
    this.dialogRef.close({
      "u_business_service": "ESP - ITSM Data Management",
      "caller_id":JSON.parse(localStorage.getItem('userData'))?.mail.split("@")[0]?JSON.parse(localStorage.getItem('userData'))?.mail.split("@")[0]:"Admin",
      "category":"Access",
      "subcategory":"Authentication",
      "impact":"3",
      "urgency":"3",
      "short_description": this.shortDescription,
      "description": this.description,
      "contact_type":"Phone", 
      "comments": this.comments, 
      "assignment_group":"GSE-ITSM Data Support"
    });
  }
  onCancel() {
    this.dialogRef.close('CANCEL');
  }

}
