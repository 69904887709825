<div class="container server-details-container">
    <div class="row row-chassis-name ">
        <div class="div-chassis-name col-md-6">
            <h5>{{serverName}}</h5>
        </div>
        <div class="col-md-6">
            <ul id="hright" class="tabs tabs--right">
                <li id="Compute" >
                    <a tabindex="0" (click)="onTabClick(1)">{{'COMPUTE' | translate}}</a>
                </li>
                <li id="Storage" class="tab" style="display: none;">
                    <a tabindex="0" (click)="onTabClick(2)">{{'STORAGE' | translate}}</a>
                </li>
                <li id="Networking" class="tab">
                    <a tabindex="0" (click)="onTabClick(3)">{{'NETWORK' | translate}}</a>
                </li>
                
            </ul>
        </div>
    </div>
    <app-dynamic-form (serverName)="getServerName($event)"></app-dynamic-form>
</div>