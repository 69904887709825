<form [formGroup]="bladeServerForm" style="overflow: auto;max-height: 90vh;" autocomplete="off">
    <div class="container-fluid">

        <div style="margin-bottom: 0px;" mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
            <h4 style="float: left;">{{platformName}} </h4>
            <div style="    display: flex;
            flex-direction: row-reverse;">
                <div class="flex-center margin_auto_t_b">
                    <a class="anchor-close" id="close" title="Close" (click)="onCancel()">
                        <span class="icon-close icon-size-24 icon-close"></span>
                    </a>
                </div>
                <button mat-button type="button" class="btn btn--small " style="height: 24px;margin: 5px 20px 0 0;"
                    (click)="openAdditionalAtt_Modal()">Additional Fields</button><span class="margin_auto_t_b"
                    style="font-size: 65%;padding: 5px;" *ngIf="additionalFields?.length>0">{{additionalFields.length}}
                    Field(s) Added</span>
            </div>
        </div>

        <div class="section">
            <div class="col-md-12 col-lg-12">
                <div class="row">

                    <div class="col-md-6 col-lg-6 row">
                        <div class="col-3">
                            Vendor
                        </div>
                        <div class="col-md-6 col-lg-6">
                            <div class="form-group">
                                <div class="form-group__text">
                                    <input matInput formControlName="vendor"
                                        [ngClass]="bladeServerForm.get('vendor').errors ? 'text-danger': 'text-normal'">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-6 row">
                        <div class="col-3">
                            Name
                        </div>
                        <div class="col-md-6 col-lg-6">
                            <div class="form-group">
                                <div class="form-group__text">
                                    <input matInput formControlName="name"
                                        [ngClass]="bladeServerForm.get('name').errors ? 'text-danger': 'text-normal'">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <div class="section">
            <div class="col-md-12 col-lg-12">

                <div class="row">

                    <div class="col-md-6 col-lg-6 row">
                        <div class="col-3">
                            Generation
                        </div>
                        <div class="col-md-6 col-lg-6">
                            <div class="form-group">
                                <div class="form-group__text">
                                    <input matInput formControlName="gen" [ngClass]="'text-normal'">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-6 row">
                        <div class="col-3">
                            PID
                        </div>
                        <div class="col-md-6 col-lg-6">
                            <app-search-box *ngIf="showPID" [type]="'platform'" [pid]="selectedPid"
                                (pidSelectionEvent)="selectedPID($event)"></app-search-box>

                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="section">
            <div class="col-md-12 col-lg-12">
                <div class="row">

                    <div class="col-md-6 col-lg-6 row">
                        <div class="col-3">
                            Max Dimms
                        </div>
                        <div class="col-md-6 col-lg-6">
                            <div class="form-group">
                                <div class="form-group__text">
                                    <input matInput formControlName="dimmSlots" maxlength="10"
                                        [ngClass]="bladeServerForm.get('dimmSlots').hasError('required') || 
                                        bladeServerForm.get('dimmSlots').hasError('min') 
                                                  ||bladeServerForm.get('dimmSlots').hasError('pattern')  ? 'text-danger': 'text-normal'">
                                </div>
                                <span
                                    *ngIf="bladeServerForm.get('dimmSlots').hasError('min') || bladeServerForm.get('dimmSlots').hasError('pattern')"
                                    style=color:red>Only Positive Integers are allowed</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-6 row">
                        <div class="col-3">
                            Max CPUs
                        </div>
                        <div class="col-md-6 col-lg-6">
                            <div class="form-group">
                                <div class="form-group__text">
                                    <input matInput formControlName="cpuSockets" maxlength="10"
                                        [ngClass]="bladeServerForm.get('cpuSockets').hasError('required') ||
                                        bladeServerForm.get('cpuSockets').hasError('pattern') || bladeServerForm.get('cpuSockets').hasError('min')? 'text-danger': 'text-normal'">
                                </div>
                                <span
                                    *ngIf="bladeServerForm.get('cpuSockets').hasError('pattern') || bladeServerForm.get('cpuSockets').hasError('min')"
                                    style=color:red>Only Positive Integers are allowed</span>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <div class="section">
            <div class="col-md-12 col-lg-12">

                <div class="row">

                    <div class="col-md-6 col-lg-6 row">
                        <div class="col-3">
                            Number Of PCI Slots
                        </div>
                        <div class="col-md-6 col-lg-6">
                            <div class="form-group">
                                <div class="form-group__text">
                                    <input matInput formControlName="pciSlots" maxlength="10"
                                        (ngModelChange)="validateSlots($event,'pci')"
                                        [ngClass]="pciSoltsErr || bladeServerForm.get('pciSlots').hasError('pattern') || bladeServerForm.get('pciSlots').hasError('min')  ? 'text-danger': 'text-normal'">
                                </div>
                                <span
                                    *ngIf="bladeServerForm.get('pciSlots').hasError('pattern') || bladeServerForm.get('pciSlots').hasError('min') "
                                    style=color:red>Only Positive Integers are allowed</span>
                                <mat-error *ngIf="pciSoltsErr" style=color:red>PCi Slots cannot be set to 0 because IO
                                    Cards are already assigned</mat-error>

                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-6 row">
                        <div class="col-3">
                            Number of Hard Disks
                        </div>
                        <div class="col-md-6 col-lg-6">
                            <div class="form-group">
                                <div class="form-group__text">
                                    <input matInput formControlName="maxDisks" maxlength="10"
                                        (ngModelChange)="validateSlots($event,'hd')"
                                        [ngClass]="hdSoltsErr || bladeServerForm.get('maxDisks').hasError('required') || bladeServerForm.get('maxDisks').hasError('pattern') || bladeServerForm.get('maxDisks').hasError('min') ? 'text-danger': 'text-normal'">
                                </div>
                                <span
                                    *ngIf="bladeServerForm.get('maxDisks').hasError('pattern') || bladeServerForm.get('maxDisks').hasError('min')"
                                    style=color:red>Only Positive Integers are allowed</span>
                                <mat-error *ngIf="hdSoltsErr" style=color:red>Hard Drives cannot be set to 0 because
                                    Storages are already assigned</mat-error>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- idle/max -->
        <div class="section">
            <div class="col-md-12 col-lg-12">

                <div class="row">

                    <div class="col-md-6 col-lg-6 row">
                        <div class="col-3">
                            Idle Power
                        </div>
                        <div class="col-md-6 col-lg-6">
                            <div class="form-group">
                                <div class="form-group__text">
                                    <input matInput formControlName="idlePower" (ngModelChange)="validateWatts($event)"
                                        [ngClass]="IdleWattsErr || showWattErr || bladeServerForm.get('idlePower').hasError('required') || bladeServerForm.get('idlePower').hasError('pattern') || bladeServerForm.get('idlePower').hasError('min') ? 'text-danger': 'text-normal'">
                                </div>
                                <span
                                    *ngIf="IdleWattsErr || bladeServerForm.get('idlePower').hasError('min') ||  bladeServerForm.get('idlePower').hasError('pattern')"
                                    style=color:red>Only Positive Integers are allowed </span>
                                <mat-error *ngIf="showWattErr" style=color:red>Idle Power should be less than or equal
                                    to Max Power </mat-error>

                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-6 row">
                        <div class="col-3">
                            Max Power
                        </div>
                        <div class="col-md-6 col-lg-6">
                            <div class="form-group">
                                <div class="form-group__text">
                                    <input matInput formControlName="maxPower" (ngModelChange)="validateWatts($event)"
                                        [ngClass]="IdleMaxWattsErr || showWattErr || bladeServerForm.get('maxPower').hasError('min') || bladeServerForm.get('maxPower').hasError('required') || bladeServerForm.get('maxPower').hasError('pattern') ? 'text-danger': 'text-normal'">
                                </div>
                                <span
                                    *ngIf="IdleMaxWattsErr || bladeServerForm.get('maxPower').hasError('min')|| bladeServerForm.get('maxPower').hasError('pattern')"
                                    style=color:red>Only Positive Integers are allowed </span>
                                <mat-error *ngIf="showWattErr" style=color:red>Idle Power should be less than or equal
                                    to Max Power </mat-error>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="section">
            <div class="col-md-12 col-lg-12">

                <div class="row">

                    <div class="col-md-6 col-lg-6 row">
                        <div class="col-3">
                            Chassis Slots
                        </div>
                        <div class="col-md-6 col-lg-6">
                            <div class="form-group">
                                <div class="form-group__text">
                                    <input matInput formControlName="numChassisSlots" maxlength="10"
                                        [ngClass]="bladeServerForm.get('numChassisSlots').hasError('required')|| bladeServerForm.get('numChassisSlots').hasError('pattern') || bladeServerForm.get('numChassisSlots').hasError('min')? 'text-danger': 'text-normal'">
                                </div>
                                <span
                                    *ngIf="bladeServerForm.get('numChassisSlots').hasError('pattern') || bladeServerForm.get('numChassisSlots').hasError('min')"
                                    style=color:red>Only Positive Integers are allowed</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-6 row">
                        <div class="col-3">
                            Conversion Factor
                        </div>
                        <div class="col-md-6 col-lg-6">
                            <div class="form-group">
                                <div class="form-group__text">
                                    <input matInput formControlName="conversionFactor" maxlength="10"
                                        [ngClass]="bladeServerForm.get('conversionFactor').hasError('max') || bladeServerForm.get('conversionFactor').hasError('required') || bladeServerForm.get('conversionFactor').hasError('pattern') || bladeServerForm.get('conversionFactor').hasError('min') ? 'text-danger': 'text-normal'">
                                </div>
                                <span
                                    *ngIf="bladeServerForm.get('conversionFactor').hasError('min') || bladeServerForm.get('conversionFactor').hasError('pattern') || bladeServerForm.get('conversionFactor').hasError('max')"
                                    style=color:red>Only Positive Numders are allowed </span>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="section">
            <div class="col-md-12 col-lg-12">

                <div class="row">

                    <div class="col-md-6 col-lg-6 row">
                        <div class="col-3">
                            Weight(lbs)
                        </div>
                        <div class="col-md-6 col-lg-6">
                            <div class="form-group">
                                <div class="form-group__text">
                                    <input matInput formControlName="weight" (ngModelChange)="validateWatts($event)"
                                        [ngClass]="weightErr || bladeServerForm.get('weight').hasError('required') || bladeServerForm.get('weight').hasError('pattern') || bladeServerForm.get('weight').hasError('min') ? 'text-danger': 'text-normal'">
                                </div>
                                <span
                                    *ngIf="bladeServerForm.get('weight').hasError('pattern') || bladeServerForm.get('weight').hasError('min')"
                                    style=color:red>Only Positive Integers are allowed </span>
                                <span *ngIf="weightErr" style=color:red>Only Positive Numbers are allowed</span>

                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-6 row">
                        <div class="col-3">
                            Series </div>
                        <div class="col-md-6 col-lg-6">
                            <div class="form-group select-ngx" [attr.disabled]="true">
                                <ngx-select-dropdown disabled="true" [multiple]="false"
                                    [ngClass]=" bladeServerForm.get('series').hasError('required') ? 
                                                      'ngx-select-danger ngx-dropdown-button': 'ngx-select-normal ngx-dropdown-button'" formControlName="series"
                                    [config]="config" [options]="seriesList">
                                </ngx-select-dropdown>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <div class="section" style="margin-bottom: -15px;">
            <div class="col-md-12 col-lg-12">

                <div class="row">

                    <div class="col-md-6 col-lg-6 row">
                        <div class="col-3">
                            Boot Power
                        </div>
                        <div class="col-md-6 col-lg-6">
                            <div class="form-group">
                                <div class="form-group__text">
                                    <input matInput formControlName="maxBootPower" maxlength="10"
                                        [ngClass]="bladeServerForm.get('maxBootPower').hasError('required') || bladeServerForm.get('maxBootPower').hasError('pattern') ? 'text-danger': 'text-normal'">
                                </div>
                                <span *ngIf="bladeServerForm.get('maxBootPower').hasError('pattern')"
                                    style=color:red>Only Positive Integers are allowed </span>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-6 row" *ngIf="isEdit && storageData.params?.legacyPlatform !== false
                                && storageData.params?.isNewCreated == null">
                        <div class="col-3">
                            Legacy Platform
                        </div>
                        <div class="col-md-6 col-lg-6">
                            <div class="form-group form-group--inline">
                                <label class="radio">
                                    <input type="radio" value="true" (change)="legacyPlatformRadioChange($event)"
                                        name="legacyPlatform" formControlName="legacyPlatform">
                                    <span class="radio__input"></span>
                                    <span class="radio__label">Yes</span>
                                </label>
                            </div>
                            <div class="form-group form-group--inline">
                                <label class="radio">
                                    <input type="radio" value="false" (change)="legacyPlatformRadioChange($event)"
                                        name="legacyPlatform" formControlName="legacyPlatform">
                                    <span class="radio__input"></span>
                                    <span class="radio__label">No</span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-6 col-lg-6">


                <div class="section">
                    <div class="col-md-12 col-lg-12">

                        <div class="row">

                            <!-- <div class="col-md-6 col-lg-6 row" > -->
                            <div class="col-3">
                                MTBF Hours
                            </div>
                            <div class="col-md-6 col-lg-6 cutom-padding-l7-r16">
                                <div class="form-group">
                                    <div class="form-group__text">
                                        <input matInput formControlName="mtbf" (ngModelChange)="validateWatts($event)"
                                            [ngClass]="bladeServerForm.get('mtbf').hasError('pattern') || mtbfErr? 'text-danger': 'text-normal'">
                                    </div>
                                    <span *ngIf="bladeServerForm.get('mtbf').hasError('pattern')" style=color:red>Only
                                        Positive Integers are allowed </span>
                                    <span *ngIf="mtbfErr" style=color:red>Only Non-Negative Integers are allowed</span>
                                </div>
                            </div>
                            <!-- </div> -->

                        </div>
                    </div>
                </div>
                <div class="section">
                    <div class="col-md-12 col-lg-12">

                        <div class="row">

                            <!-- <div class="col-md-6 col-lg-6 row" > -->
                            <div class="col-3">
                                Dedicated RAID Slot
                            </div>
                            <div class="col-md-6 col-lg-6 cutom-padding-l7-r16">
                                <div class="form-group">
                                    <div class="form-group__text">
                                        <input matInput formControlName="mezzSlots"
                                            [ngClass]="bladeServerForm.get('mezzSlots').hasError('required') || bladeServerForm.get('mezzSlots').hasError('pattern') ? 'text-danger': 'text-normal'">
                                    </div>
                                    <span *ngIf="bladeServerForm.get('mezzSlots').hasError('pattern')"
                                        style=color:red>Only Positive Integers are allowed</span>
                                </div>
                            </div>
                            <!-- </div> -->

                        </div>
                    </div>
                </div>
                <div class="section">
                    <div class="col-md-12 col-lg-12">

                        <div class="row">

                            <!-- <div class="col-md-6 col-lg-6 row" > -->
                            <div class="col-3">
                                End of Sale
                            </div>
                            <div class="col-md-6 col-lg-6 cutom-padding-l7-r16">
                                <div class="form-group form-group--inline">
                                    <label class="radio">
                                        <input type="radio" value="1" name="discont" formControlName="discont">
                                        <span class="radio__input"></span>
                                        <span class="radio__label">Yes</span>
                                    </label>
                                </div>
                                <div class="form-group form-group--inline">
                                    <label class="radio">
                                        <input type="radio" value="0" name="discont" formControlName="discont">
                                        <span class="radio__input"></span>
                                        <span class="radio__label">No</span>
                                    </label>
                                </div>
                            </div>
                            <!-- </div> -->

                        </div>
                    </div>
                </div>

            </div>
            <div class="col-md-6 col-lg-6" style="
      margin-left: -22px;
  ">

                <div class="row padding_row">
                    <div class="col-md-12 col-lg-12 row">
                        <div class="col-md-3 col-lg-3 ">
                            Image Look up
                        </div>
                        <div class="col-md-6 col-lg-6">
                            <div class="form-group">
                                <input type="file" name="uploadfile" id="importImage" accept="image/*"
                                    style="display:none;" (change)="onImportImage($event)" />
                                <label for="importImage" class="btn btn--secondary import-project-btn">Browse
                                    Image</label>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="row padding_row">
                    <div class="col-md-12 col-lg-12 row" style="height: 170px;">

                        <img [src]="url" style="width: 275px;height: auto;margin: auto;"> <br />

                    </div>
                </div>

            </div>
        </div>


        <button mat-button type="button" (click)="imageUpload('closeModal')"
            [disabled]="pciSoltsErr || hdSoltsErr || !submitInProgress && !bladeServerForm.valid || mtbfErr  || showWattErr || IdleWattsErr || IdleMaxWattsErr || !selectedPid"
            class="btn btn--small " style="height: 24px;margin: 5px 20px 0 0;">Save & Close</button>
        <button mat-button type="button" class="btn btn--small "
            [disabled]="pciSoltsErr || hdSoltsErr || !submitInProgress && !bladeServerForm.valid || mtbfErr  || showWattErr || IdleWattsErr || IdleMaxWattsErr || !selectedPid"
            style="height: 24px;margin: 5px 20px 0 0;" (click)="imageUpload('save')">Save</button>
    </div>
</form>