<div class="globalSearch">
    <div id="loading-dots" *ngIf="showLoader">
        <div class="loading-dots loading-dots--dark" aria-label="Loading, please wait...">
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
      <div class="document-container"  *ngIf="!showLoader">
        <!-- <div class="row base-margin-bottom">
            <div class="col-md-10">
                <h5>Search Results</h5>
            </div>
        </div> -->
        <div class="flex flex-between" style="padding: 0 10px;">
          <h4>Search Results
              <!-- <button class="btn btn--ghost" (click)="openShowFields()" 
                style="padding: 0 10px;font-size: small;margin-left: 5px;"> 
                <span class="icon-list-menu" style="margin-right: 5px;">
                </span>Show Fields
              </button> -->
          </h4>
      </div>
        <div class="row">
            <div class="col-md-12 document-grid-container">
        
              <ag-grid-angular style="height: 520px; width:100%" [rowData]="rowData" [gridOptions]="gridOptions"

                gridId="documentGrid" [pagination]="true" [paginationPageSize]="paginationPageSize" (firstDataRendered)="headerHeightSetter($event)"
                (columnResized)="headerHeightSetter($event)" 
                [enableCellTextSelection]="true"    
                [frameworkComponents]="frameworkComponents" (gridReady)="onGridReady($event)" class="ag-theme-alpine"
                >
              </ag-grid-angular>
            </div>
          </div>
    </div>
</div>