<mat-card-title> 
  <div mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle 
  style="margin-bottom: 8px;"><h4 style="float: left;font-size: large;font-weight: 400;">IO Device </h4> 
    <div style="display:flex;
    flex-direction: row-reverse;">
    <div class="flex-center margin_auto_t_b" >
      <a class="anchor-close" id="close" title="Close" (click)="onCancel()">
      <span class="icon-close icon-size-24 icon-close"></span>
      </a>
  </div>
    <button mat-button class="btn btn--small " style="height: 24px;margin: 0px 20px 0 0;"
    (click)="openAdditionalAtt_Modal()">Additional Fields</button><span class="margin_auto_t_b" 
    style="font-size: 65%;margin-top: -2px;margin-right:5px;" *ngIf="additionalFields.length>0">{{additionalFields.length}} Field(s) Added</span></div>
  </div>
</mat-card-title>

<form  [formGroup]="validations" autocomplete="off">
  <div> 
    <mat-form-field>    
      
      <div class="form-group base-margin-bottom" style="max-width:800px;min-width: 500px;">
        <table class="table table-bordered">
          <tbody>
            <tr>
              <td class="mandatory">
                 <mat-label>Display Name</mat-label>
                 <mat-error  *ngIf="validations.get('displayName').errors" style="color:red"></mat-error>
              </td>
              <td>
                 <div class="form-group" > 
                    <div class="form-group__text">
                       <input matInput  [ngClass]=" validations.get('displayName').errors ? 'text-danger': 'text-normal'" [(ngModel)]="data.displayName"  formControlName="displayName" required> 
                    </div>  
                 </div>
                </td>
              <td></td>
                          
            </tr>
            <tr>
              <td></td> <td></td>
            </tr>
            <tr>
              <td class="mandatory"> 
                <mat-label>Internal Name</mat-label>
                <mat-error  *ngIf="validations.get('internalName').errors" style="color:red"></mat-error>
              </td>
              <td>
                <div class="form-group" > 
                  <div class="form-group__text">
                    <input matInput [ngClass]=" validations.get('internalName').errors ? 'text-danger': 'text-normal'" [(ngModel)]="data.internalName" formControlName="internalName" required>
                  </div>  
                </div> 
              </td>
            </tr>
            <tr>
              <td></td> <td></td>
            </tr>
            <tr>
              <td class="mandatory"  style="vertical-align: top;padding-top: 15px;
              ">
                 <mat-label >PID</mat-label>
              </td>
              <td>
                <app-search-box *ngIf="showPID" [type]="'component'" [pid]="selectedPid" (pidSelectionEvent)="selectedPID($event)" ></app-search-box>
                </td>
              <td></td>
                          
            </tr>

            <tr>
              <td></td> <td></td>
            </tr>
            <tr>
              <td class="mandatory" style="vertical-align: top;padding-top: 15px;"> 
                <mat-label>IO Type</mat-label>
                <mat-error  *ngIf="validations.get('ioType').hasError('required')" style="color:red"></mat-error>
              </td>
              <td>

                 <div class="form-group storage"> 
                  <ngx-select-dropdown 
                          [multiple]="false" [ngClass]=" validations.get('ioType').hasError('required') ? 'ngx-select-danger ngx-dropdown-button': 'ngx-select-normal ngx-dropdown-button'" 
                          [(ngModel)]="selectedIoType" (change)="selectIOType($event)" formControlName="ioType"
                           [config]="{search:false,displayKey:'viewValue'}" [options]="ioTypes">
                          </ngx-select-dropdown>
                </div>

              </td>
            </tr>
            <tr>
              <td></td> <td></td>
            </tr>
            <tr>
              <td class="mandatory"> 
                <mat-label>Idle Power(W)</mat-label>
                <mat-error  *ngIf="validations.get('idleWatts').hasError('required') || showWattErr || IdleWattsErr || validations.get('idleWatts').hasError('pattern') || 
                                   validations.get('idleWatts').hasError('min') " style="color:red"></mat-error>
              </td>
              <td>
                <div class="form-group" > 
                  <div class="form-group__text">                  
                   <input matInput  (ngModelChange)="validateWatts($event)" 
                   [ngClass]="validations.get('idleWatts').hasError('pattern') || 
                              validations.get('idleWatts').hasError('min')   || 
                              validations.get('idleWatts').hasError('required') || showWattErr || IdleWattsErr? 'text-danger': 'text-normal'" [(ngModel)]="data.idleWatts" formControlName="idleWatts" required>
                  </div>
                </div>  
              </td>               
            </tr>
            <tr>
              <td></td> 
              <td>
                <mat-error *ngIf ="(validations.get('idleWatts').hasError('pattern') || validations.get('idleWatts').hasError('min') || validations.get('idleWatts').hasError('pattern') || validations.get('idleWatts').hasError('min')   || validations.get('idleWatts').hasError('max')) && !IdleWattsErr" style = color:red>Only Positive Integers are allowed</mat-error>
                <mat-error *ngIf ="showWattErr" style = color:red>Idle Power should be less than or equal to Max Power </mat-error>
                <mat-error *ngIf ="IdleWattsErr" style = color:red>Only Positive Integers are allowed </mat-error>
              </td>
            </tr> 
            <tr>
              <td class="mandatory"> 
                <mat-label>Max Power(W)</mat-label>
                <mat-error  *ngIf="validations.get('maxWatts').hasError('required')" style="color:red"></mat-error>
              </td>
              <td>
                <div class="form-group" > 
                  <div class="form-group__text">   
                   <input matInput (ngModelChange)="validateWatts($event)" 
                   [ngClass]="validations.get('maxWatts').hasError('pattern') || 
                              validations.get('maxWatts').hasError('min')   || 
                              validations.get('maxWatts').hasError('required') || 
                              showWattErr || IdleMaxWattsErr ? 'text-danger': 'text-normal'"[(ngModel)]="data.maxWatts" formControlName="maxWatts" required>
                  </div>
                </div>  
              </td>
            </tr>
            <tr>
              <td></td> 
              <td>
                  <mat-error *ngIf ="validations.get('maxWatts').hasError('pattern') && !IdleMaxWattsErr" style = color:red>Only Positive Integers are allowed</mat-error>
                  <mat-error *ngIf ="showWattErr" style = color:red>Max Power should be greater than or equal to Idle Power</mat-error>
                  <mat-error *ngIf ="IdleMaxWattsErr" style = color:red>Only Positive Integers are allowed </mat-error>
              </td>
            </tr> 
            <tr>
              <td class="mandatory"> <mat-label>MTBF Hours</mat-label></td>
              <td>
                <div class="form-group" > 
                  <div class="form-group__text">
                    <input matInput (ngModelChange)="validateWatts($event)" 
                    [ngClass]="validations.get('mtbf').hasError('pattern') || mtbfErr? 'text-danger': 'text-normal'" [(ngModel)]="data.mtbf" formControlName="mtbf">
                  </div>
                  </div>
                  </td>     
            </tr>
            <tr>
              <td></td> 
              <td>
                <mat-error *ngIf ="validations.get('mtbf').hasError('pattern')" style = color:red>Only Positive Integers are allowed</mat-error>
                <mat-error *ngIf="mtbfErr" style=color:red>Only Non-Negative Integers are allowed</mat-error>
              </td>
            </tr> 
            <tr>
              <td *ngIf="!isDisabled">
                 <mat-label>Supported Raid</mat-label>
              </td>
              <td *ngIf="!isDisabled"> <mat-checkbox [(ng-model)]="data.raid"  (change)="setCheckBox($event.checked)" [disabled]="isDisabled" formControlName="raid" style="padding-right: 10px;" > RAID 0 </mat-checkbox>
               <mat-checkbox [(ng-model)]="data.raid1" (change)="setCheckBox1($event.checked)" [disabled]="isDisabled" formControlName="raid1" style="padding-right: 10px;"> RAID 1 </mat-checkbox>
               <mat-checkbox [(ng-model)]="data.raid5" (change)="setCheckBox5($event.checked)" [disabled]="isDisabled" formControlName="raid5" style="padding-right: 10px;"> RAID 5 </mat-checkbox>
               <mat-checkbox [(ng-model)]="data.raid6"  (change)="setCheckBox6($event.checked)" [disabled]="isDisabled" formControlName="raid6" style="padding-right: 10px;"> RAID 6 </mat-checkbox>     </td>         
            </tr>
            <tr>
              <td></td>
              <td *ngIf="!isDisabled"> 
               <mat-checkbox [(ng-model)]="data.raid10" (change)="setCheckBox10($event.checked)" [disabled]="isDisabled" formControlName="raid10" style="padding-right: 10px;"> RAID 10 </mat-checkbox>
               <mat-checkbox [(ng-model)]="data.raid50" (change)="setCheckBox50($event.checked)" [disabled]="isDisabled" formControlName="raid50" style="padding-right: 10px;"> RAID 50 </mat-checkbox>
              <mat-checkbox [(ng-model)]="data.raid60" (change)="setCheckBox60($event.checked)" [disabled]="isDisabled" formControlName="raid60" style="padding-right: 10px;"> RAID 60 </mat-checkbox></td>
            </tr>
            <!-- <tr>
              <td></td>
             
            </tr> -->
          </tbody>
        </table>
      </div>
    </mat-form-field>
  </div>

  <div mat-dialog-actions>
    <table class="table table-bordered">
      <tbody>
        <tr>
          <td>
            <mat-card-actions> 
              <button mat-button  (click)="onSave()"   class="btn btn--small " 
              style="height: 24px;margin: 5px 20px 0 0;"  
              [disabled]="!validations.valid || showWattErr || IdleWattsErr || IdleMaxWattsErr || mtbfErr || !selectedPid">Save & Close</button>
            </mat-card-actions>
            <button mat-button (click)="onNoClick()"  class="btn btn--small " 
            style="height: 24px;margin: 5px 20px 0 0;"
            [disabled]="!validations.valid || showWattErr || IdleWattsErr || IdleMaxWattsErr || mtbfErr || !selectedPid">Save</button>
          </td>
        </tr>
      </tbody>
    </table>   
  </div>
</form>
