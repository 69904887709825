<div class="container project-list-container">
  <div class="row project-container base-margin-top">
    <div class="col-xs-3 col-md-4 col-lg-6 div-my-projects">
      <h5>{{ 'MY_PROJECTS' | translate }}</h5>
    </div>
    <div class="col-xs-9 col-md-8 col-lg-6" style="display: flex; justify-content: flex-end;">
      <button class="btn btn--primary new-project-btn" (click)="openModal()">{{'NEW_PROJECT' | translate}}</button>
    <!-- </div> -->
    <!-- <div class="col-lg-1 col-md-1 div-empty"></div> -->
    <!-- <div class="col-xs-5 col-md-4 col-lg-2 div-import-project"> -->
      <input type="file" name="uploadfile" #importProject id="importProject" style="display:none;" accept=".prj"
        (change)="onImportPrject($event)" />
      <label for="importProject" class="btn btn--secondary import-project-btn">{{'IMPORT_PROJECT' | translate}}</label>

      <!-- <button class="btn btn--secondary import-project-btn" (click)="onImportPrject()">{{"IMPORT_PROJECT" | translate}}</button> -->
    </div>
    <div class="col-lg-1 col-md-2 div-empty">&nbsp;</div>
  </div>
  <div class="row">
    <div class="col-md-10 project-grid-container pjlistGrid">

      <ag-grid-angular style="height: 530px; width:100%" [rowData]="rowData" [columnDefs]="columnDefs"
        gridId="projectListGrid" [pagination]="true" [paginationPageSize]="paginationPageSize"
        [frameworkComponents]="frameworkComponents" (gridReady)="onGridReady($event)" class="ag-theme-alpine"
        (cellValueChanged)="onCellValueChanged($event)">
      </ag-grid-angular>
    </div>
    <div class="col-md-2"></div>
  </div>
</div>