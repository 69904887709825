import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { GridOptions } from "ag-grid-community";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ApiService } from 'src/app/services/api.service';
import { UrlConstant } from 'src/constants/url.constants';
import { ComponentConstant } from 'src/constants/components.constants';
import { EditRenderComponent } from 'src/app/shared/components/edit-render/edit-render.component';
import { CheckboxRenderComponent } from 'src/app/shared/components/checkbox-render/checkbox-render.component';
import { CreateDimmsComponent } from '../create-components/create-dimms/create-dimms.component';
import { ToasterService } from 'src/app/shared/components/toast/toast.service';
import { errorCodes } from 'src/constants/errorCodes';
import { AdminService } from 'src/app/services/admin.service';
import { PlatformConstant } from 'src/constants/platform.constants';
import { ShowFieldsComponent } from 'src/app/shared/components/show-fields/show-fields.component';
import { Router } from '@angular/router';
import { UpdateNameService } from 'src/app/services/update-name.service';

export interface DialogData {
  displayName: string;
  internalName: string;

}

@Component({
  selector: 'app-manage-dimms',
  templateUrl: './manage-dimms.component.html',
  styleUrls: ['./manage-dimms.component.css']
})
export class ManageDimmsComponent implements OnInit {
  gridApi: any;
  gridColumnApi: any;
  subscriptions: Subscription = new Subscription();
  @BlockUI() blockUI: NgBlockUI;
  errorMsg = '';
  columnDefs;
  frameworkComponents;
  componentIdx: number;
  componentName: string;
  componentDisplayName: string = "";
  //defaultColDef;
  rowData: [];
  gridOptions: GridOptions = {};
  flagBackButton = false;
  constructor(
    private apiService: ApiService,
    private httpClient: HttpClient,
    private toaster: ToasterService,
    private adminService: AdminService,
    public dialog: MatDialog,
    private router: Router,
    private updateNameService: UpdateNameService
    ) {
    this.updateNameService.getCurrentName().subscribe((data) => {
      if (data.length > 0) {
        this.componentName = data.filter(compName => compName.internalName.trim() == 'Manage Memories')[0].currentName;
        let compNameSplit = this.componentName.trim().replace("Manage", "").replace("manage", "");
        this.componentDisplayName = compNameSplit;
      }
    });
    
    this.frameworkComponents = {
      buttonEditRenderer: EditRenderComponent,
      checkboxRenderer: CheckboxRenderComponent
    };

    this.columnDefs = [
      { headerName: 'Display Name', field: 'displayName', 
      comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
        if (valueA.toUpperCase() == valueB.toUpperCase()){ 
          return 0;
        }
        else{
          return (valueA.toUpperCase() > valueB.toUpperCase()) ? 1 : -1;
        }
      }},
      { headerName: 'Size (GB)', field: 'size', },
      { headerName: 'PID', field: 'pid', 
      comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
        if (valueA.toUpperCase() == valueB.toUpperCase()){ 
          return 0;
        }
        else{
          return (valueA.toUpperCase() > valueB.toUpperCase()) ? 1 : -1;
        }
      }},
      { headerName: 'Idle Power 1DPC(W)', field: 'idleWatts' },
      { headerName: 'Max Power 1DPC(W)', field: 'maxWatts' },
      { headerName: 'Idle Power 2DPC(W)', field: 'idleWatts2Dpc' },
      { headerName: 'Max Power 2DPC(W)', field: 'maxWatts2Dpc' },
      { headerName: 'No Of Memories', field: 'numDimms' },
      { headerName: 'Family Name', field: 'dimmFamName' ,
      comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
        if (valueA.toUpperCase() == valueB.toUpperCase()){ 
          return 0;
        }
        else{
          return (valueA.toUpperCase() > valueB.toUpperCase()) ? 1 : -1;
        }
      }},
      { headerName: 'MTBF (Hours) ', field: 'mtbf' },
      { headerName: 'Released State', field: 'released', cellRenderer: "checkboxRenderer" },
      { headerName: 'Updated By', field: 'editor' },
      {
        headerName: 'Last Updated', field: 'lastUpdated',
        cellRenderer: (data) => {
          return data.value ? moment(data.value).format('MMM DD, YYYY') : moment(data.data.created).format('MMM DD, YYYY');
        },
        valueGetter: function(data) {

          return data.data?.lastUpdated ? moment(data.data?.lastUpdated).format('MMM DD, YYYY') : moment(data.data?.created).format('MMM DD, YYYY');

    },
        comparator:function dateComparator(date1, date2) {
          var date1Number = date1 && new Date(date1).getTime();
          var date2Number = date2 && new Date(date2).getTime();

          
          
        
          if (date1Number == null && date2Number == null) {
            return 0;
          }
        
          if (date1Number == null) {
            return -1;
          } else if (date2Number == null) {
            return 1;
          }
        
          return date1Number - date2Number;
        }
      },
      {
        headerName: "Associated Platforms",
        minWidth: 100,
        maxWidth: 110,
        sortable: false,
        filter: false,
        cellRenderer: "buttonEditRenderer",
        cellRendererParams: {
          label: "associatedPlatforms",
          dynamicComponent : false
        },
  
      },
      {
        headerName: "",
        minWidth: 60,
        maxWidth: 70,
        sortable: false,
        filter: false,
        cellRenderer: "buttonEditRenderer",
        cellRendererParams: {
          onClick: this.onEditButtonClick.bind(this),
          label: "Edit",
          dynamicComponent : false
        },
  
      },
      {
        headerName: "",
        minWidth: 60,
        maxWidth: 70,
        sortable: false,
        filter: false,
        cellRenderer: "buttonEditRenderer",
        cellRendererParams: {
          onClick: this.onDeleteButtonClick.bind(this),
          label: "Delete",
          dynamicComponent : false
        },
  
      },
    ];
  }
  paginationPageSize = 10;


  defaultColDef = {
    editable: false,
    sortable: true,
    flex: 1,
    minWidth: 120,
    filter: true,
    resizable: true,
    headerComponentParams: {
      template:
        '<div class="ag-cell-label-container" role="presentation">' +
        '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
        '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
        '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
        '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
        '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
        '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
        '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
        '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
        '  </div>' +
        '</div>',
    },
    filterParams: { newRowsAction: 'keep'},
  };

  ngOnInit(): void {
    this.adminService.getDimmData().subscribe(data => {
      if (data) {
        this.flagBackButton = true;
        this.getDuplicateRecords(data);
      }
    });
    this.getManageDimmsList();
  }

  getDuplicateRecords(data: any) {
    
    const filter = {
      displayName: {
        filterType: 'text',
        type: 'equals',
        filter: data.displayName
      }, pid: {
        filterType: 'text',
        type: 'equals',
        filter: data.pid
      }
    }
    this.gridApi.setFilterModel(filter)
  }

  // showGrid:boolean = false;
  getManageDimmsList() {
    let dimms = ComponentConstant.DIMMS;
    const url = UrlConstant.MANAGECOMPONENTS + '/?component=' + dimms;
    this.blockUI.start('Loading...');
    // this.showGrid = false;
    this.subscriptions.add(
      this.apiService.getMethod(url).subscribe((data: any) => {
        this.adminService.setGridData(data.componenetList);
        this.rowData = data.componenetList;
        //to add addtional fields in grid
        if(data.addlabels){
          let index = this.columnDefs.length-2;
          data.addlabels.forEach(element => {
            let headerIndex =  this.columnDefs.findIndex(f=>f.headerName == element);
            if(headerIndex == -1)
            this.columnDefs.splice(index,0,{ headerName: element ,colId:element,hide:true,field:element,
                cellRenderer: (data) => {
                  
                  let value = null;
                  data.data.additionalFields?.forEach(element1 => {
                    if(element1.name == data.colDef.headerName)
                      value = element1.value;
                  });
                  return value;
                },
                valueGetter: function(data) {
                  let value = null;
                  data.data.additionalFields?.forEach(element1 => {
                    if(element1.name == data.colDef.headerName)
                      value = element1.value;
                  });
                  return value;
            }})
          });
        }
        // this.showGrid = true;
        this.gridApi.setColumnDefs(this.columnDefs);
        //set coumns fron localstorage
    let setFields = JSON.parse(localStorage.getItem(ComponentConstant.DIMMS+"GridFields"))
    if(setFields?.show && setFields?.hide){
      this.gridColumnApi.setColumnsVisible(setFields.show, true);
      this.gridColumnApi.setColumnsVisible(setFields.hide, false);
      this.gridColumnApi.moveColumns(setFields.show, 0);

    }
        this.blockUI.stop();
      }, error => {
        this.errorMsg = error?.error ? error.error?.error?.errorString : errorCodes.GENERIC_ERROR_MESSAGE;
        this.toaster.show('error', this.errorMsg, error ? error?.message : '');
        this.blockUI.stop();
      }));
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(CreateDimmsComponent, {
      data: {
        type: ComponentConstant.DIMMS
      },
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((result) => {
      try {
        if (result.data.action !== PlatformConstant.ACTION_CANCEL) {
          
          this.getManageDimmsList();
         setTimeout(() => this.gridApi.paginationGoToLastPage(), 100);
        }
      } catch (error) {
        

      }
    });
  }

  createDimm(data: any) {
    const url = UrlConstant.MANAGECOMPONENTS;
    this.subscriptions.add(
      this.apiService.postMethod(url, data).subscribe((data: any) => {
        for (let [key, value] of Object.entries(data)) {
          
          if (key == "modifiedCount" && value > 0) {
            
            this.getManageDimmsList();
            break;
          }
        }
      })
    );
  }

  onEditButtonClick(params: any) {
    this.getManageDimmsList();
  }

  onDeleteButtonClick(params: any) {
    
    this.gridOptions.api?.updateRowData({ remove: [params.node.data.Id] });
  }

  headerHeightSetter(event: any) {
    var padding = 20;
    var height = this.headerHeightGetter() + padding;
    this.gridApi.setHeaderHeight(height);
    this.gridApi.resetRowHeights();
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    //params.api.sizeColumnsToFit();  
    //set coumns fron localstorage
    let setFields = JSON.parse(localStorage.getItem(ComponentConstant.DIMMS+"GridFields"))
    
    if(setFields?.show && setFields?.hide){
      this.gridColumnApi.setColumnsVisible(setFields.show, true);
      this.gridColumnApi.setColumnsVisible(setFields.hide, false);
      this.gridColumnApi.moveColumns(setFields.show, 0);

    }
  }
  headerHeightGetter() {
    var columnHeaderTexts = document.querySelectorAll('.ag-header-cell-text');

    var columnHeaderTextsArray = [];

    columnHeaderTexts.forEach(node => columnHeaderTextsArray.push(node));

    var clientHeights = columnHeaderTextsArray.map(
      headerText => headerText.clientHeight
    );
    var tallestHeaderTextHeight = Math.max(...clientHeights);
    return tallestHeaderTextHeight;
  }

  resetGrid() {
    this.gridApi.setFilterModel(null);
    this.flagBackButton = false;
  }

  openShowFields(){
    
      //show/hide column
      
  
      
      var dd = this.gridColumnApi.getAllDisplayedColumns();
      dd.forEach(element => {
        
      });
      //this.gridColumnApi.setColumnsVisible(["basePid", "cpuSockets"], false);
      // const group = this.gridColumnApi.columnController.columnDefs;
      // this.gridOptions.columnDefs.forEach(child => this.gridColumnApi.setColumnVisible(child.field, false));
      
      let allcols = this.gridColumnApi.getAllColumns();
      const dialogRef = this.dialog.open(ShowFieldsComponent, {      
        data: {
               fields : allcols,
              } ,
        disableClose: false,
        // panelClass: 'width-dialog-100',           
      });   
  
      dialogRef.afterClosed().subscribe(result => {
         
        // let show = result.data.fields.filter(v => { return v.visible == true });
        // let hide = result.data.fields.filter(v => { return v.visible == false });
        let show = [];
        let hide = [];
        result.data.fields.forEach(element => {
          if(element.visible == true){
            show.push(element.colId)
          }
          else{
            hide.push(element.colId)
          }
        });
        
        
        
//save to localstorage
const setFields = {show:show,hide:hide}
localStorage.setItem(ComponentConstant.DIMMS+"GridFields", JSON.stringify(setFields));
        this.gridColumnApi.setColumnsVisible(show, true);
        this.gridColumnApi.setColumnsVisible(hide, false);
        this.gridColumnApi.moveColumns(setFields.show, 0);

        this.headerHeightSetter(null);
      });
    }
    
  ngOnDestroy() {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }
}


