import { Component, OnInit, Inject, HostListener } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormControl, UntypedFormBuilder, UntypedFormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { Subscription } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ComponentConstant } from 'src/constants/components.constants';
import { ApiService } from 'src/app/services/api.service';
import { UrlConstant } from 'src/constants/url.constants';
import { ToasterService } from "src/app/shared/components/toast/toast.service";
import { errorCodes } from 'src/constants/errorCodes';
import { CngContentConfigType, CngModalService, CngModalSize } from '@cisco/cui-ng';
import { ConfirmationBoxComponent } from 'src/app/shared/components/confirmation-box/confirmation-box.component';
import { TranslateService } from '@ngx-translate/core';
import { AdminService } from 'src/app/services/admin.service';

export interface DialogData {
  name: string;
  rank: string;
  siliconSize: string;
  speed: string;
  mtbf: string;
  type: string;
  id: string;
  update: boolean;
  released: string;

}

@Component({
  selector: 'app-create-dimmsfamily',
  templateUrl: './create-dimmsfamily.component.html',
  styleUrls: ['./create-dimmsfamily.component.css']
})
export class CreateDimmsfamilyComponent implements OnInit {
  subscriptions: Subscription = new Subscription();
  constructor(public dialogRef: MatDialogRef<CreateDimmsfamilyComponent>,
    @Inject(MAT_DIALOG_DATA) public dimmsFamilyData: DialogData,
    private apiService: ApiService,
    private modalService: CngModalService,
    private translate: TranslateService,
    private adminService: AdminService,
    private toaster: ToasterService,
    private formBuilder: UntypedFormBuilder,) {
    this.validations = this.formBuilder.group({
      name: new UntypedFormControl('', [Validators.required,]),
      rank: new UntypedFormControl('', [
        Validators.required,
        Validators.pattern('\\d+')]),
      siliconSize: new UntypedFormControl('', [
        Validators.required,
        Validators.pattern('\\d+')]),
      speed: new UntypedFormControl('', [
        Validators.required,
        Validators.min(1),
        Validators.pattern('\\d+')]),
      mtbf: new UntypedFormControl('', [Validators.pattern("^\\d*\\.?\\d+$")])
    })
  }
  validations: UntypedFormGroup;
  id;
  type;
  released;
  update: boolean = false;
  dialogConfig;
  errorMsg = '';

  onNoClick(): void {
    this.type = this.dimmsFamilyData.type;
    //this.update = this.dimmsFamilyData.update;
    this.dialogRef.close('cancel');
  }

  ngOnInit(): void {
    this.type = this.dimmsFamilyData.type;
    // this.update = this.dimmsFamilyData.update;
  }

  onSave() {
    if (this.validations.valid) {
      
      //let type = this.type = this.dimmsFamilyData.type;//ComponentConstant.DIMM_FAMILIES;        
      let data = {
        "type": this.type,
        "role": "Admin",
        "text": this.validations.value.name,
        "rank": this.validations.value.rank,
        "speed": this.validations.value.speed,
        "siliconSize": this.validations.value.siliconSize,
        "released": "",
        "allowDuplicates": false
      };
      if (!this.dimmsFamilyData.update) {
        
        let result = this.createDimmsFamily(data);
      } else {
        data.released = this.released;
        data["id"] = this.dimmsFamilyData.id;
        let result = this.updateDimmsFamily(data);
      }

    } else {
      
    }
  }

  createDimmsFamily(reqData: any) {
    const url = UrlConstant.MANAGECOMPONENTS;
    this.subscriptions.add(
      this.apiService.postMethod(url, reqData).subscribe((data: any) => {
        
        //this.dimmsFamilyData = data;
        if (data.modifiedCount > 0) {
          // var cn = confirm('New DimmFamily has been created.');
          // if(cn){
          this.toaster.show('success', errorCodes.NEW_RECORD, '');
          window.scroll(0,0);
          this.dialogRef.close();
          // }
        } else if (data.modifiedCount == 0 && data.error.errorString == "Duplicate Data Found") {
          // var cn = confirm("Duplicate data found. Do you want to save ? Click OK");
          // if (cn){
          //this.toaster.show('warning', errorCodes.DUPLICATE_RECORD , '');
          reqData["allowDuplicates"] = true;
          this.openModal(reqData);
          this.subscriptions.add(
            this.apiService.postMethod(url, reqData).subscribe((data: any) => {
              
              if (data.modifiedCount > 0) {
                // var cn = confirm('New DimmFamily has been created.');
                // if(cn){
                this.toaster.show('success', errorCodes.NEW_RECORD, '');
                window.scroll(0,0);
                this.dialogRef.close();
                //}
              }
            }))
          // }else {
          //   this.dialogRef.close();
          // }
        }
      }, error => {
        this.errorMsg = error?.error ? error.error?.error?.errorString : errorCodes.GENERIC_ERROR_MESSAGE;
        this.toaster.show('error', this.errorMsg, error ? error?.message : '');
      })
    );
  }

  updateDimmsFamily(reqData: any) {
    const url = UrlConstant.MANAGECOMPONENTS + UrlConstant.UPDATE;
    this.subscriptions.add(
      this.apiService.postMethod(url, reqData).subscribe((data: any) => {
        
        this.dimmsFamilyData = data;
        if (data.modifiedCount > 0 && data.successMessage == "Successfully updated") {
          // var cn = confirm('Details have been updated.');
          // if(cn){
          this.toaster.show('success', errorCodes.UPDATE_RECORD, '');
          window.scroll(0,0);
          this.dialogRef.close();
          //}
        } else if (data.modifiedCount == 0 && data.error.errorString == "Duplicate Data Found") {
          // var cn = confirm("Duplicate data found. Do you want to Update ? Click Ok");
          // if (cn){
         // this.toaster.show('warning', errorCodes.DUPLICATE_RECORD, '');
          reqData["allowDuplicates"] = true;
          this.openModal(reqData);
          this.subscriptions.add(
            this.apiService.postMethod(url, reqData).subscribe((data: any) => {
              
              if (data.modifiedCount > 0) {
                // var cn = confirm('Details have been updated.');
                // if(cn){
                this.toaster.show('success', errorCodes.UPDATE_RECORD, '');
                window.scroll(0,0);
                this.dialogRef.close();
                // }
              }
            }))
          // } else {
          //   

          // }
        }
      }, error => {
        this.errorMsg = error?.error ? error.error?.error?.errorString : errorCodes.GENERIC_ERROR_MESSAGE;
        this.toaster.show('error', this.errorMsg, error ? error?.message : '');
      })
    );

  }
  ngOnDestroy() {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }
  public onCancel = () => {
    
  }

  public async openModal(data: any) {
    const result = await this.modalService
      .open({
        content: {
          type: CngContentConfigType.COMPONENT,
          content: ConfirmationBoxComponent,
          componentData: {
            someText: this.translate.instant(
              "DUPLICATE_DATA_FOUND"
            ),
            key: "delete",
          },
        },
        size: CngModalSize.SMALL,
      })
      .onDismiss.toPromise();

    if (result == 'ok')
      this.getDuplicateData(data);
  }
  getDuplicateData(data: any) {
    this.subscriptions.add(this.adminService.setDimmFamilyData(data));
  }
}
