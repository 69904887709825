<block-ui>
<div class="edit-license">
    <div class="flex flex-between" style="padding: 0 10px;">
        <h4>{{platformName}}<a *ngIf="flagBackButton" (click)="resetGrid()" style="font-size: medium; font-weight: 600; padding-left: 10px;">Back</a>
            <button class="btn btn--ghost" (click)="openShowFields()" style="    padding: 0 10px;    font-size: small;
            margin-left: 5px;
            "> <span class="icon-list-menu" style="    margin-right: 5px;
                "></span>Show Fields</button>
                <a style="vertical-align: middle;margin-left: 15px;  color: #00bceb" 
                data-icon="icon-info-outline" data-balloon="Mandatory Components required for Release are  Memory and Processor.

                " data-balloon-pos="up" data-balloon-length="xlarge">
                    <span class="icon-info-outline icon-size-24"></span>  
            </a>
        </h4>
        <button mat-raised-button (click)="openDialog()"  class="btn btn--small " style="height: 24px;margin: 5px 20px 0 0;">New {{platformName}}</button>         
    </div>
    <div style="padding: 0 10px;">
        <ag-grid-angular
            style="width: 100%; height: 550px;"
            class="ag-theme-alpine"
            [rowData]="rowData"
            [gridOptions]="gridOptions"
            [pagination]="true"
            [paginationPageSize]="paginationPageSize"         
            [frameworkComponents]="frameworkComponents"
            (gridReady)="onGridReady($event)"
            (firstDataRendered)="headerHeightSetter($event)"
            [enableCellTextSelection]="true"
            (columnResized)="headerHeightSetter($event)" >
        </ag-grid-angular>
    </div>
</div>
</block-ui>



