import { Component, OnInit ,Inject,HostListener ,ChangeDetectorRef} from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { FormControl, UntypedFormBuilder,FormGroup, Validators,ReactiveFormsModule  } from '@angular/forms';
import { Subscription } from 'rxjs';
import { PlatformConstant } from 'src/constants/platform.constants';
import { ComponentConstant } from 'src/constants/components.constants';
import { AdminService } from 'src/app/services/admin.service';


@Component({
  selector: 'app-show-fields',
  templateUrl: './show-fields.component.html',
  styleUrls: ['./show-fields.component.css']
})
export class ShowFieldsComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<ShowFieldsComponent>,  
    @Inject(MAT_DIALOG_DATA) public fieldsData : any,
    private formBuilder : UntypedFormBuilder,
    private cdr: ChangeDetectorRef,
    public dialog: MatDialog,
    private adminService: AdminService,

  ) { }
   
  fields = [];
  ngOnInit(): void {
    
    this.fieldsData.fields.forEach(element => {
      if(element.colDef.field || element.colDef.colId){
      let field = {
        name: element.colDef.headerName,
        colId: element.colId,
        visible: element.visible
      }
      this.fields.push(field);
    }
    });
this.checkALLBox();

}

  onCancel(){
    this.dialogRef.close({});
  }
  apply(){
    
    this.dialogRef.close({
      data: {
        fields : this.fields
      }
    });
  }
  checkboxSelectAll:boolean = false;
  checkAll(e) {
    
    
    let checked = e.target.checked;
    this.fields.forEach(element => {
      element.visible = checked;
    });
  }
  checkALLBox(){
    this.checkboxSelectAll = this.fields.length == this.fields.filter(v => { return v.visible == true }).length ?
    true : false 
  }

}
