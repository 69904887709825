import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, NgModule ,NO_ERRORS_SCHEMA} from '@angular/core';

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { HeaderComponent } from "./components/layout/header/header.component";
import { FooterComponent } from "./components/layout/footer/footer.component";
import { LicenseComponent } from "./components/license/license.component";
import { HelpComponent } from "./components/license/help/help.component";
// localization module import
import {
  TranslateModule,
  TranslateLoader,
  TranslateService,
} from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from "@angular/common/http";
//import {CookieService } from 'ngx-cookie-service';


import { ProjectListComponent } from "./components/project-list/project-list.component";
import { SharedModule } from "./shared/shared.module";
import { AgGridModule } from "ag-grid-angular";
import { AuthorizationGuard } from "./services/authorization.guard";
import { RouterModule } from "@angular/router";
import { CngAccordionModule } from "@cisco/cui-ng";
import { ProjectDetailsModule } from "./components/project-details/project-details.module";
import { FinalResultsComponent } from "./components/project-details/final-results/final-results.component";
import { UserLoginModalComponent } from './components/user-login-modal/user-login-modal.component';
import { MatDialogModule } from '@angular/material/dialog';
import {MatCheckboxModule} from '@angular/material/checkbox'; 
import {MatMenuModule} from '@angular/material/menu';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { StartupService } from './services/startup.service';
import { ReactiveFormsModule } from '@angular/forms';
import { AdminComponent } from './components/admin/admin.component';
import { AdminTabComponent } from './components/admin/admin-tab/admin-tab.component';
import { EditLicenseComponent } from './components/admin/admin-tab/edit-license/edit-license.component';
import { FormsModule } from '@angular/forms';
import { EditHeaderComponent } from './components/admin/admin-tab/edit-header/edit-header.component';
import { ManageIoDevicesComponent } from './components/admin/admin-tab/manage-io-devices/manage-io-devices.component';
import { ManageStorageDevicesComponent } from './components/admin/admin-tab/manage-storage-devices/manage-storage-devices.component';
import { ManagePowerSuppliesComponent } from './components/admin/admin-tab/manage-power-supplies/manage-power-supplies.component';
import { ManageProcessorFamilyComponent } from './components/admin/admin-tab/manage-processor-family/manage-processor-family.component';
import { ManageProcessorsComponent } from './components/admin/admin-tab/manage-processors/manage-processors.component';
import { ManageDimmsFamilyComponent } from './components/admin/admin-tab/manage-dimms-family/manage-dimms-family.component';
import { ManageDimmsComponent } from './components/admin/admin-tab/manage-dimms/manage-dimms.component';
import { ManageExpansionCardsComponent } from './components/admin/admin-tab/manage-expansion-cards/manage-expansion-cards.component';
import { ManageIoModulesComponent } from './components/admin/admin-tab/manage-io-modules/manage-io-modules.component';
import { CreateNewcomponentComponent } from './components/admin/admin-tab/create-newcomponent/create-newcomponent.component';
import { CreateStoragecomponentComponent } from './components/admin/admin-tab/create-components/create-storagecomponent/create-storagecomponent.component';
import { CreateProcessorfamilyComponent } from './components/admin/admin-tab/create-components/create-processorfamily/create-processorfamily.component';
import { CreateProcessorComponent } from './components/admin/admin-tab/create-components/create-processor/create-processor.component';
import { CreateDimmsComponent } from './components/admin/admin-tab/create-components/create-dimms/create-dimms.component';
import { CreateDimmsfamilyComponent } from './components/admin/admin-tab/create-components/create-dimmsfamily/create-dimmsfamily.component';
import { CreateExpansioncardsComponent } from './components/admin/admin-tab/create-components/create-expansioncards/create-expansioncards.component';
import { CreateIomoduleComponent } from './components/admin/admin-tab/create-components/create-iomodule/create-iomodule.component';
import { CreatePowersupplyComponent } from './components/admin/admin-tab/create-components/create-powersupply/create-powersupply.component';
import { CreateVoltageComponent } from './components/admin/admin-tab/create-components/create-voltage/create-voltage.component';
import { ManageRackServersComponent } from './components/admin/admin-tab/manage-platforms/manage-rack-servers/manage-rack-servers.component';
import { CreateRackServersComponent } from './components/admin/admin-tab/create-platforms/create-rack-servers/create-rack-servers.component';
import { AddMeasurementComponent } from './components/admin/admin-tab/manage-platform-configs/add-measurement/add-measurement.component';
import { ManageComponentComponent } from './components/admin/admin-tab/manage-platform-configs/manage-component/manage-component.component';
import { AddConfigurationComponent } from './components/admin/admin-tab/manage-platform-configs/add-configuration/add-configuration.component';
import { CpuMemoryCompatibilityComponent } from './components/admin/admin-tab/manage-platform-configs/cpu-memory-compatibility/cpu-memory-compatibility.component';
import { ManageBladeServersComponent } from './components/admin/admin-tab/manage-platforms/manage-blade-servers/manage-blade-servers.component';
import { ManageBladeChassisComponent } from './components/admin/admin-tab/manage-platforms/manage-blade-chassis/manage-blade-chassis.component';
import { ManageNodesComponent } from './components/admin/admin-tab/manage-platforms/manage-nodes/manage-nodes.component';
import { ManageModularChassisComponent } from './components/admin/admin-tab/manage-platforms/manage-modular-chassis/manage-modular-chassis.component';
import { ManageFabricInterconnectsComponent } from './components/admin/admin-tab/manage-platforms/manage-fabric-interconnects/manage-fabric-interconnects.component';
import { ManageFabricExtendersComponent } from './components/admin/admin-tab/manage-platforms/manage-fabric-extenders/manage-fabric-extenders.component';
import { CreateBladeServersComponent } from './components/admin/admin-tab/create-platforms/create-blade-servers/create-blade-servers.component';
import { CreateBladeChassisComponent } from './components/admin/admin-tab/create-platforms/create-blade-chassis/create-blade-chassis.component';
import { CreateNodesComponent } from './components/admin/admin-tab/create-platforms/create-nodes/create-nodes.component';
import { CreateModularChassisComponent } from './components/admin/admin-tab/create-platforms/create-modular-chassis/create-modular-chassis.component';
import { CreateFabricInterconnectsComponent } from './components/admin/admin-tab/create-platforms/create-fabric-interconnects/create-fabric-interconnects.component';
import { CreateFabricExtendersComponent } from './components/admin/admin-tab/create-platforms/create-fabric-extenders/create-fabric-extenders.component';
import { DocumentComponent } from './components/admin/admin-tab/document/document.component';
import { DocumentIconRendererComponent } from './components/admin/admin-tab/document/document-icon-renderer/document-icon-renderer.component';
import { ConfigsQcReportsComponent } from './components/admin/admin-tab/configs-qc-reports/configs-qc-reports.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { BlockUIModule } from 'ng-block-ui';
import { MeasurementDetailsComponent } from './components/admin/admin-tab/manage-platform-configs/add-measurement/measurement-details/measurement-details.component';
import { NewMeasurementComponent } from './components/admin/admin-tab/manage-platform-configs/add-measurement/new-measurement/new-measurement.component';
import { SelectDropDownModule } from 'ngx-select-dropdown';
import { ManageFanPolicyComponent } from './components/admin/admin-tab/manage-fan-policy/manage-fan-policy.component';
import { CreateFanpolicyComponent } from './components/admin/admin-tab/create-components/create-fanpolicy/create-fanpolicy.component';

import { UpdateNameService } from './services/update-name.service';

import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { ManageNewOneComponent } from './components/admin/admin-tab/manage-new-one/manage-new-one.component';
import { MaintenanceScreenComponent } from './maintenance-screen/maintenance-screen.component'
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CreateNewPlatformComponent } from './components/admin/admin-tab/create-platforms/create-new-platform/create-new-platform.component';
import { ManageAdditionalPlatform1Component } from './components/admin/admin-tab/manage-additional-platforms/manage-additional-platform1/manage-additional-platform1.component';
import { ManageAdditionalPlatform2Component } from './components/admin/admin-tab/manage-additional-platforms/manage-additional-platform2/manage-additional-platform2.component';
import { CreateAdditionalPlatform1Component } from './components/admin/admin-tab/create-platforms/create-additional-platforms/create-additional-platform1/create-additional-platform1.component';
import { ManageAdditionalPlatform3Component } from './components/admin/admin-tab/manage-additional-platforms/manage-additional-platform3/manage-additional-platform3.component';
import { ManageAdditionalPlatform4Component } from './components/admin/admin-tab/manage-additional-platforms/manage-additional-platform4/manage-additional-platform4.component';
import { ManageAdditionalPlatform5Component } from './components/admin/admin-tab/manage-additional-platforms/manage-additional-platform5/manage-additional-platform5.component';
import { CreateManageCompComponent } from './components/admin/admin-tab/create-manage-comp/create-manage-comp.component';
import { ManageNewTwoComponent } from './components/admin/admin-tab/manage-new-two/manage-new-two.component';
import { ManageNewThreeComponent } from './components/admin/admin-tab/manage-new-three/manage-new-three.component';
import { ManageNewFourComponent } from './components/admin/admin-tab/manage-new-four/manage-new-four.component';
import { ManageNewFiveComponent } from './components/admin/admin-tab/manage-new-five/manage-new-five.component';
import { CreateNewAdditionalComponent } from './components/admin/admin-tab/create-new-additional/create-new-additional.component';
import { DocumentUploadComponent } from './components/admin/admin-tab/document-upload/document-upload.component';
import { HowToVideosComponent } from './components/license/help/how-to-videos/how-to-videos.component';
import { ReleaseNotesComponent } from './components/license/help/release-notes/release-notes.component';
import { HelpDocsComponent } from './components/license/help/help-docs/help-docs.component';
import { RaiseCaseComponent } from './components/admin/admin-tab/configs-qc-reports/raise-case/raise-case.component';
import { EditErrorMessagesComponent } from './components/admin/admin-tab/edit-error-messages/edit-error-messages.component';
import { EditSeriesComponent } from './components/admin/admin-tab/edit-series/edit-series.component';
import { ManageNewSixComponent } from './components/admin/admin-tab/manage-new-six/manage-new-six.component';
import { GlobalSearchResultsComponent } from './components/admin/admin-tab/global-search-results/global-search-results.component';
import { FilterComponentPipe } from "./shared/pipe/filter-component.pipe";
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { RequestInterceptor } from './request.interceptor';
//loader module
export  function  HttpLoaderFactory(http:  HttpClient) {
  return  new  TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export function initConfiguration(startupService: StartupService) {
  return () => startupService.load();
}

export function appInitializerFactory(translate: TranslateService) {
  return () => {
    translate.setDefaultLang('en');
    return translate.use('en').toPromise();
  };
}

@NgModule({
    declarations: [
        AppComponent,
        HeaderComponent,
        FooterComponent,
        LicenseComponent,
        HelpComponent,
        ProjectListComponent,
        FinalResultsComponent,
        UserLoginModalComponent,
        AdminComponent,
        AdminTabComponent,
        EditLicenseComponent,
        EditHeaderComponent,
        ManageIoDevicesComponent,
        ManageStorageDevicesComponent,
        ManagePowerSuppliesComponent,
        ManageProcessorFamilyComponent,
        ManageProcessorsComponent,
        ManageDimmsFamilyComponent,
        ManageDimmsComponent,
        ManageExpansionCardsComponent,
        ManageIoModulesComponent,
        CreateNewcomponentComponent,
        CreateStoragecomponentComponent,
        CreateProcessorfamilyComponent,
        CreateProcessorComponent,
        CreateDimmsComponent,
        CreateDimmsfamilyComponent,
        CreateExpansioncardsComponent,
        CreateIomoduleComponent,
        CreatePowersupplyComponent,
        CreateVoltageComponent,
        ManageRackServersComponent,
        CreateRackServersComponent,
        AddMeasurementComponent,
        ManageComponentComponent,
        AddConfigurationComponent,
        CpuMemoryCompatibilityComponent,
        ManageBladeServersComponent,
        ManageBladeChassisComponent,
        ManageNodesComponent,
        ManageModularChassisComponent,
        ManageFabricInterconnectsComponent,
        ManageFabricExtendersComponent,
        CreateBladeServersComponent,
        CreateBladeChassisComponent,
        CreateNodesComponent,
        CreateModularChassisComponent,
        CreateFabricInterconnectsComponent,
        CreateFabricExtendersComponent,
        DocumentComponent,
        DocumentIconRendererComponent,
        ConfigsQcReportsComponent,
        MeasurementDetailsComponent,
        NewMeasurementComponent,
        ManageFanPolicyComponent,
        CreateFanpolicyComponent,
        ManageNewOneComponent,
        CreateFanpolicyComponent,
        CreateNewPlatformComponent, ManageAdditionalPlatform1Component, ManageAdditionalPlatform2Component, CreateAdditionalPlatform1Component, ManageAdditionalPlatform3Component, ManageAdditionalPlatform4Component, ManageAdditionalPlatform5Component,
        MaintenanceScreenComponent, CreateManageCompComponent, ManageNewTwoComponent, ManageNewThreeComponent, ManageNewFourComponent, ManageNewFiveComponent, CreateNewAdditionalComponent, DocumentUploadComponent, HowToVideosComponent, ReleaseNotesComponent, HelpDocsComponent, RaiseCaseComponent, EditErrorMessagesComponent, EditSeriesComponent, ManageNewSixComponent, GlobalSearchResultsComponent,
        FilterComponentPipe,
        PageNotFoundComponent,
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        SharedModule,
        ProjectDetailsModule,
        AgGridModule,
        RouterModule,
        CngAccordionModule,
        MatDialogModule,
        MatCheckboxModule,
        MatMenuModule,
        BrowserAnimationsModule,
        ReactiveFormsModule,
        FormsModule, NgSelectModule, AngularMultiSelectModule,
        BlockUIModule.forRoot(),
        SelectDropDownModule,
        DragDropModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        })
    ],
    exports: [],
    providers: [AuthorizationGuard,
        {
            provide: APP_INITIALIZER,
            useFactory: appInitializerFactory,
            deps: [TranslateService],
            multi: true
        },
        StartupService,
        {
            provide: APP_INITIALIZER,
            useFactory: initConfiguration,
            multi: true,
            deps: [StartupService],
        },
        UpdateNameService,
        {
            provide: HTTP_INTERCEPTORS, useClass: RequestInterceptor, multi: true
        },
    ],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA]
})
export class AppModule {}
