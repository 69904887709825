let apiUrl = "";
let type = "";
let production = false;
let powerUrl = "";
let env = "";
let issuer = "";
let clientId = "";
let redirectUri = window.location.origin + "/license";
switch (window.location.hostname) {
  case "localhost":
    console.log("********** case local window.location.hostname " + window.location.hostname);
    type = "local";
    production = false;
    apiUrl = "http://localhost:8080/v1/ucs/";
    // apiUrl = "https://ucsws-dev.cisco.com/v1/ucs/";
    powerUrl = "http://localhost:8080/public/";
    env = "http://localhost:8080";
    issuer = 'https://int-id.cisco.com/oauth2/default';
    clientId = 'ucs-ng-pc-stage';
    break;

  case "ucs-dev.cisco.com":
    console.log("************* case ucs-dev.cisco.com");
    type = "dev";
    production = false;
    apiUrl = "https://ucsws-dev.cisco.com/v1/ucs/";
    powerUrl = "https://ucsws-dev.cisco.com/public/";
    env = "https://ucsws-dev.cisco.com";
    issuer = 'https://int-id.cisco.com/oauth2/default';
    clientId = 'ucs-ng-pc-stage';
    break;

  case "ucs-stg.cisco.com":
    console.log("****************** ucs-stg.cisco.com");
    type = "stg";
    production = false;
    apiUrl = "https://ucsws-stage.cisco.com/v1/ucs/";
    powerUrl = "https://ucsws-stage.cisco.com/public/";
    env = "https://ucsws-stage.cisco.com";
    issuer = 'https://int-id.cisco.com/oauth2/default';
    clientId = 'ucs-ng-pc-stage';
    break;

  case "ucsng.cisco.com":
    console.log("*****************  ucsng.cisco.com");
    type = "prod";
    production = true;
    apiUrl = "https://ucsngws.cisco.com/v1/ucs/";
    powerUrl = "https://ucsngws.cisco.com/public/";
    env = "https://ucsngws.cisco.com";
    issuer = 'https://id.cisco.com/oauth2/default';
    clientId = 'ucs-ng-pc-prd';
    break;

  case "ucspowercalculator.cisco.com":
    console.log("************* ucspowercalculator.cisco.com");
    type = "prod";
    production = true;
    apiUrl = "https://ucsngws.cisco.com/v1/ucs/";
    powerUrl = "https://ucsngws.cisco.com/public/";
    env = "https://ucsngws.cisco.com";
    issuer = 'https://id.cisco.com/oauth2/default';
    clientId = 'ucs-ng-pc-prd';
    break;

  default:
    console.log("************** default");
    type = "prod";
    production = true;
    apiUrl = "https://ucsngws.cisco.com/v1/ucs/";
    powerUrl = "https://ucsngws.cisco.com/public/";
    env = "https://ucsngws.cisco.com";
    issuer = 'https://id.cisco.com/oauth2/default';
    clientId = 'ucs-ng-pc-prd';
}

export const environment = {
  production,
  apiUrl,
  type,
  powerUrl,
  env,
  issuer,
  clientId,
  redirectUri,
};
