import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import {
	CngContentConfigType,
	CngModalService,
	CNG_DATA,
} from '@cisco/cui-ng';
import { Subscription } from 'rxjs';
import { SharedService } from '../../shared.service';

@Component({
  selector: 'app-confirmation-box',
  templateUrl: './confirmation-box.component.html',
  styleUrls: ['./confirmation-box.component.css']
})
export class ConfirmationBoxComponent implements OnInit, OnDestroy {
  message:string;
  subscriptions: Subscription = new Subscription(); 
  confirmationMessage: string = "OK"
  constructor(@Inject(CNG_DATA) public data: any,
  private modalService: CngModalService,
  private sharedService: SharedService) { }
 
  isModalShown = false;
  ngOnInit(): void {   
  }

  public closeModal () {
		this.subscriptions.add(this.modalService.close('closed'));
	}

	public openNewModal () {
	this.modalService.open({
			content: {
				type: CngContentConfigType.COMPONENT,
				content: ConfirmationBoxComponent,
			},
		});
	}
  
  onConfimation() { 
    
    if(this.data.key !== 'delete'){
      this.subscriptions.add(this.sharedService.sendMessage(this.data));
    }   
    
    this.subscriptions.add(this.modalService.close('ok'));
  }  

  ngOnDestroy(): void {
    if(this.subscriptions) {
      this.subscriptions.unsubscribe();
     }
  }
}
