import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';
import { ProjectService } from 'src/app/services/project.service';
import { environment } from 'src/environments/environment';
import { AdminService } from 'src/app/services/admin.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { Router } from "@angular/router";
import { DataServiceService } from "../../../data-service.service";
import { TranslateService } from '@ngx-translate/core';
import { ToasterService } from 'src/app/shared/components/toast/toast.service';
import { errorCodes } from 'src/constants/errorCodes';
import { AuthClientService } from 'src/app/services/auth-client.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit {
  subscriptions: Subscription = new Subscription();
  userData: any = null;
  loginUrl = '';
  logoutUrl = '';
  showHeader = false;
  headList = [];
  @BlockUI() blockUI: NgBlockUI;
  res: any;
  errorMessage: any;
  dbStatus: any;

  constructor(private apiService: ApiService,
    private dataService: DataServiceService,
    private projectService: ProjectService,
    private router: Router,
    private adminService: AdminService,
    private translate: TranslateService,
    public toaster: ToasterService,
    private authClientService: AuthClientService,
  ) {

    {
      this.userData = JSON.parse(localStorage.getItem('userData'));
      if (environment.production) {
        this.loginUrl =
          'https://www.cisco.com/c/login/index.html?referer=' +
          window.location.href +
          '?';

        this.logoutUrl =
          'https://www.cisco.com/autho/logout.html?ReturnUrl=' + 'https://' + window.location.hostname + '/'
          // window.location.href +
          + '?';
      } else {
        this.loginUrl =
          'https://www-stage.cisco.com/c/login/index.html?referer=' +
          window.location.href +
          '?';

        this.logoutUrl =
          'https://www-stage.cisco.com/autho/logout.html?ReturnUrl=' + 'https://' + window.location.hostname + '/'
          // window.location.href +
          + '?';
      }
      this.blockUI.start("Loading...");
      this.subscriptions.add(this.adminService.getHeaderData().subscribe((data: any) => {
        this.headList = [];
        this.blockUI.stop();
        if (data?.length > 0) {
          data.forEach((val) => this.headList.push(Object.assign({}, val)));
          this.showHeader = true;
        }
      }, (error) => {
        console.error(error);
        this.blockUI.stop();
      }));
      this.getHeaderList();
    }
  }

  getHeaderList() {
    const url = 'getHeaderUrlsList';
    this.blockUI.start("Loading...");
    this.subscriptions.add(
      this.apiService.getMethod(url).subscribe((result: any[]) => {
        setTimeout(() => {
          this.blockUI.stop();
          this.adminService.setHeaderData(result);
        }, 1000);
      }, (error) => {
        console.error(error);
        this.blockUI.stop();
      })
    );
  }

  ngOnInit(): void {
    this.getHeaderList();
    this.authClientService.getCurrentUser().subscribe(response => {
      if (response) {
        if (response.givenName == 'guest') {
          this.userData = null;
        } else {
          this.userData = response;
        }
      }
    })
  }

  login() {
    this.authClientService.loginWithRedirect();
  }

  logout() {
    // this.authClientService.logOut();
    this.authClientService.logOutUserFromSession();
    this.authClientService.setCurrentUser({ givenName: 'guest', mail: '' });
    localStorage.removeItem('userData');
    this.router.navigate(['license']);
  }


  onHeaderClick() {
    this.subscriptions.add(this.projectService.setCurrentChassis([null]));
    this.subscriptions.add(this.projectService.setProjectData(null));
    this.subscriptions.add(this.projectService.setPowerCost(''));
    this.subscriptions.add(this.projectService.setEmissionFactor(''));
    this.subscriptions.add(this.projectService.setCountry(''));
    this.subscriptions.add(this.projectService.setCurrentProjectName(null));
    this.subscriptions.add(this.projectService.setIsAddedOrEdited(false));
    this.projectService.setServerEditStatus(false);
    localStorage.setItem("setServerEditStatus", "false");
    localStorage.setItem("setCurrentServer", JSON.stringify(null));

    sessionStorage.setItem("setServerEditStatus", "false");
    sessionStorage.setItem("setCurrentServer", JSON.stringify(null));

    // remove any existing redundancy warnings 
    this.toaster.remove(errorCodes.NON_REDUNDANT);
    this.toaster.remove(errorCodes.INSUFFICIENT_REDUNDANCY);
    this.toaster.remove(errorCodes.POWER_CAPPED);
    this.toaster.remove(errorCodes.EXTENDED_POWER_REQUIRED);

    if (this.router.url.includes('admin/')) {
      // if (this.router.url.includes('ucs-resizer')) {
      //   this.router.navigate(["/admin/ucs-resizer/projectList"]);
      // } else 
      this.router.navigate(["/admin/projectList"]);
    } else {
      // if (this.router.url.includes('ucs-resizer')) {
      //   this.router.navigate(["/ucs-resizer/projectList"]);
      // } else 
      this.router.navigate(["/projectList"]);
    }
  }

  ngOnDestroy() {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }
}
