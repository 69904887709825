<div class="admin-tab">

    <div style="display: flex;">
        <div style="width: 300px; border-right:1px solid lightgray">

            <ul id="vleft" class="tabs tabs--vertical">
                <li style="font-size: 15px;font-weight: 600;padding: 0 0 10px 0;">
                    Manage Others
                </li>
            </ul>


            <div class="row">
                <div class="form-group__text" class="col-2">

                    <div class="icon-edit icon-size-15 man-comp-icon" routerLink="license"
                        style="margin: 0px 5px;color:#00bceb">
                    </div>
                </div>

                <div class="col-10">
                    <ul id="vleft" class="tabs tabs--vertical">
                        <li id="vleft-1" class="tab" routerLink="license" routerLinkActive="active">
                            <a tabindex="0" style="padding: 5px;font-size: 13px;"> License </a>
                        </li>
                    </ul>
                </div>

            </div>
            <div class="row">
                <div class="form-group__text" class="col-2">

                    <div class="icon-edit icon-size-15 man-comp-icon" routerLink="header"
                        style="margin: 0px 5px;color:#00bceb">
                    </div>
                </div>

                <div class="col-10">
                    <ul id="vleft" class="tabs tabs--vertical">
                        <li id="vleft-1" class="tab" routerLink="header" routerLinkActive="active">
                            <a tabindex="0" style="padding: 5px;font-size: 13px;"> Header </a>
                        </li>
                    </ul>
                </div>

            </div>

            <div class="row">
                <div class="form-group__text" class="col-2">

                    <div class="icon-edit icon-size-15 man-comp-icon" routerLink="series"
                        style="margin: 0px 5px;color:#00bceb">
                    </div>
                </div>

                <div class="col-10">
                    <ul id="vleft" class="tabs tabs--vertical">
                        <li id="vleft-1" class="tab" routerLink="series" routerLinkActive="active">
                            <a tabindex="0" style="padding: 5px;font-size: 13px;"> Series </a>
                        </li>
                    </ul>
                </div>

            </div>

            <ul id="vleft" class="tabs tabs--vertical">

                <li style="font-size: 15px;font-weight: 600;padding: 0 0 10px 0;">
                    Manage Components
                    <div (click)="onClickAddComponent($event)" *ngIf="updateNameService.currentNames.length<=13;"
                        class="icon-add icon-size-18 icon-expandable man-comp-icon-add csco-style"></div>
                    <div disabled *ngIf="updateNameService.currentNames.length>13;"
                        class="icon-add icon-size-18 icon-expandable man-comp-icon-add csco-style-inactive"></div>
                </li>
            </ul>
            <!-- Logic for component data from api -->
            <div class="row" *ngFor="let panelData of manageComponentsPanelData; let i = index">
                <div class="form-group__text" class="col-2">
                    <div (click)="onClickEdit($event, i)"
                        *ngIf="!panelData.routeLink.includes('additionalComponent'); else disableEdit"
                        class="icon-edit icon-size-15 man-comp-icon" style="margin: 0px 5px;color:#00bceb">
                    </div>
                    <ng-template #disableEdit>
                        <div title="Cannot Edit Additional Component Names">
                            <span disabled class="icon-edit icon-size-15 man-comp-icon csco-style-inactive"
                                style="margin: 0px 5px;"></span>
                        </div>
                    </ng-template>
                </div>

                <div class="form-group__text" class="col-10" *ngIf="isEdit[i]">
                    <div><input id="displayName" type="text" [(ngModel)]="panelData.displayName"
                            [ngClass]="(panelData.displayName != '') ?  'select-normal' : 'select-danger'"
                            *ngIf="isEdit[i]" (blur)="onBlurDisplayName($event, i)"
                            (change)="onChangeDisplayName($event, i)">
                    </div>
                </div>
                <div class="col-10" *ngIf="!isEdit[i]">
                    <ul id="vleft" class="tabs tabs--vertical">
                        <li id="vleft-1" class="tab" [routerLink]="[panelData.routeLink]" [state]="{idx: i}"
                            routerLinkActive="active">
                            <a *ngIf="!isEdit[i]" tabindex="0" style="padding: 5px;font-size: 13px;"
                                (click)="onClick()"> {{ panelData.displayName }} </a>
                        </li>
                    </ul>
                </div>

            </div>



            <ul id="vleft" class="tabs tabs--vertical">
                <li style="font-size: 15px;font-weight: 600;padding: 0 0 10px 0;display: inline-flex;width: 100%;">
                    Manage Platforms
                    <div *ngIf="managePlatformPanelData.length < 12 " class="anchor-add" style="     color: #00bceb;
                    padding: 0 0 0 35%;
                    " id="add" title="add" (click)="openModal_platform()">
                        <span class="icon-add icon-size-18 icon-expandable csco-style"></span>
                    </div>
                    <div disabled *ngIf="managePlatformPanelData.length >= 12 " class="anchor-add" style="padding: 0 0 0 35%;
                    " id="add" title="add" (click)="openModal_platform()">
                        <span disabled class="icon-add icon-size-18 icon-expandable csco-style-inactive"></span>
                    </div>
                </li>
            </ul>
            <!-- Add logic for platform data from api here -->
            <div class="row" *ngFor="let panelData of managePlatformPanelData; let i = index">
                <div class="form-group__text" class="col-2">

                    <div (click)="onClickEditPlatform($event, i)"
                        *ngIf="!panelData.routeLink.includes('AdditionalPlatform'); else disableEdit"
                        class="icon-edit icon-size-15 man-comp-icon" style="margin: 0px 5px;color:#00bceb">
                    </div>
                    <ng-template #disableEdit>
                        <div title="Cannot Edit Additional Platform Names">
                            <span disabled class="icon-edit icon-size-15 man-comp-icon csco-style-inactive"
                                style="margin: 0px 5px;"></span>
                        </div>
                    </ng-template>
                </div>
                <!-- <div class="col-1"></div> -->
                <div class="form-group__text" class="col-10" *ngIf="isEditPlatform[i]">
                    <div><input id="displayName" type="text" autocomplete="off" [(ngModel)]="panelData.displayName"
                            [ngClass]="(panelData.displayName != '') ?  'select-normal' : 'select-danger'"
                            *ngIf="isEditPlatform[i]" (blur)="onBlurPlatformDisplayName($event, i)">
                    </div>
                </div>
                <div class="col-10" *ngIf="!isEditPlatform[i]">
                    <ul id="vleft" class="tabs tabs--vertical">
                        <li id="vleft-1" class="tab" routerLink="{{panelData.routeLink}}" [state]="{idx: i}"
                            routerLinkActive="active">
                            <a *ngIf="!isEditPlatform[i]" tabindex="0" style="padding: 5px;font-size: 13px;"
                                (click)="onClick()"> {{ panelData.displayName }} </a>
                        </li>
                    </ul>
                </div>

            </div>
        </div>
        <div style="width: 100%;">
            <router-outlet></router-outlet>
        </div>
    </div>

</div>