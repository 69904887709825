<div>
  <div class="row " [ngClass]="(resultchassisLength > 9) ? 'card-container-height': 'card-container'">

    <div class="card col-md-12" *ngFor="let item of resultChassis">
      <div class="card card-inner">
        <div class="flex" style="flex-direction: row;">
          <div class="col-md-11">
            <p class="card-heading">{{item?.name}}</p>
          </div>
          <div class="col-md-1" *ngIf="template"><a (click)="onDeleteTemplate(item.templateId, item.type)"><span
                class="icon-exit icon-size-12"></span></a></div>
        </div>


        <div class="card-img-div">
          <img [src]="item.image ? getImage(item.image) : item.url ? item?.url : defaultImage" alt={{item?.name}}
            class="card-img">
        </div>

        <div *ngFor="let element of item.attributes">
          <div class="col-md-12" *ngIf="element?.visible">
            <div class="row card-row-content">
              <div class="col-md-10 card-property-labels">

                <img [src]="element?.url ? element?.url : defaultIcon" class="icon-img" />
                {{element?.displayName | translate }}
              </div>

              <div class="col-md-2">{{element?.value}}
              </div>
            </div>
          </div>
        </div>

      </div>
      <div class="card-btn-div"
        [title]="item?.isEnabled === false ? 'Cannot add server as it exceeds the available slots in current configuration' : ''">
        <button [ngClass]="item?.isEnabled === false ? 'item-disabled': ''"
          class="btn btn--primary active btn--justified card-btn"
          (click)="onConfigure(item.basePid, item.id, item.voltage, template, item)">{{'CONFIGURE_AND_ADD' |
          translate}}</button>
      </div>
    </div>
    <div *ngIf="resultChassis.length == 0" class="no-data-div">
      <p>{{'NO_DATA' | translate}}</p>
    </div>
  </div>

</div>