import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HttpEventType
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { tap, catchError, finalize } from 'rxjs/operators';
import { BlockUI, NgBlockUI } from 'ng-block-ui';

@Injectable()
export class RequestInterceptor implements HttpInterceptor {
  @BlockUI() blockUI: NgBlockUI;
  constructor() {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    let lastResponse: HttpEvent<any>;
    let error: HttpErrorResponse;
    return next.handle(request)
    .pipe(
      tap((response: HttpEvent<any>) => {
        lastResponse = response;
      }),
      catchError((err: any) => {
        error = err;
        return throwError(err);        
      }),
      finalize(() => {
        if (lastResponse.type === HttpEventType.Sent && !error) {
          this.blockUI.stop();
        }
      })
    );
  }
}
