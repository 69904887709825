<mat-card-title mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
  <div style="margin-bottom: 8px;">
    <h4 style="float: left;font-size: large;font-weight: 400;"> Memory </h4>
    <div style="display:flex;
    flex-direction: row-reverse;">
      <div class="flex-center margin_auto_t_b">
        <a class="anchor-close" id="close" title="Close" (click)="onCancel()">
          <span class="icon-close icon-size-24 icon-close"></span>
        </a>
      </div>

      <button mat-button class="btn btn--small " style="height: 24px;margin: 0px 20px 0 0;"
        (click)="openAdditionalAtt_Modal()">Additional Fields</button><span class="margin_auto_t_b"
        style="font-size: 65%;margin-top: -2px;margin-right:5px;"
        *ngIf="additionalFields.length>0">{{additionalFields.length}} Field(s) Added</span>
    </div>
  </div>
</mat-card-title>
<form [formGroup]="validations" autocomplete="off">

  <div mat-dialog-content>
    <mat-form-field>

      <div class="panel panel--compressed" style="max-width:800px;min-width: 500px;">
        <table class="table table-bordered">
          <tbody>
            <tr>
              <td class="mandatory" style="vertical-align: top;padding-top: 15px;">
                <mat-label>Family</mat-label>
                <mat-error *ngIf="validations.get('dimm').hasError('required')" style="color:red"></mat-error>
              </td>
              <td>
                <div class="form-group storage" style="width: 290px;">
                  <ngx-select-dropdown [multiple]="false"
                    [ngClass]=" validations.get('dimm').hasError('required') ? 'ngx-select-danger ngx-dropdown-button': 'ngx-select-normal ngx-dropdown-button'"
                    [(ngModel)]="selecteddimm" formControlName="dimm" (change)="changeDropdown($event)"
                    [config]="config" [options]="dimms">
                  </ngx-select-dropdown>
                </div>
              </td>
            </tr>
            <tr *ngIf="showNewFamilyName">
              <td class="mandatory">
                <mat-label>New Family Name</mat-label>
                <mat-error *ngIf="true" style="color:red"></mat-error>
              </td>
              <td>
                <div class="form-group">
                  <div class="form-group__text">
                    <input matInput [ngClass]=" validations.get('newDimmFamily').errors ? 'text-danger' :'text-normal' "
                      formControlName="newDimmFamily" required style="width: 100%;">
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td class="mandatory">
                <mat-label>No of Memories</mat-label>
                <mat-error *ngIf="validations.get('noOfDimms').hasError('required')" style="color:red"></mat-error>
              </td>
              <td>
                <div class="form-group">
                  <div class="form-group__text">
                    <input matInput
                      [ngClass]="validations.get('noOfDimms').errors ? 'text-danger': 'text-normal'"
                      [(ngModel)]="dimmsData.noOfDimms" formControlName="noOfDimms" maxlength="9" required
                      style="width: 100%;">
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td></td>
              <td>
                <mat-error *ngIf="validations.get('noOfDimms').hasError('pattern') || validations.get('noOfDimms').hasError('min')" style=color:red>Only Positive
                  Integers are allowed</mat-error>
              </td>
            </tr>
            <tr>
              <td class="mandatory">
                <mat-label>Size(GB)</mat-label>
                <mat-error *ngIf="validations.get('size').hasError('required')" style="color:red"></mat-error>
              </td>
              <td>
                <div class="form-group">
                  <div class="form-group__text">
                    <input matInput [ngClass]="validations.get('size').errors ? 'text-danger': 'text-normal'"
                      [(ngModel)]="dimmsData.size" maxlength="10" formControlName="size" required style="width: 100%;">
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td></td>
              <td>
                <mat-error *ngIf="validations.get('size').hasError('pattern') || validations.get('size').hasError('min')" style=color:red>Only Positive Integers
                  are allowed</mat-error>
              </td>
            </tr>
            <tr>
              <td class="mandatory">
                <mat-label>Name</mat-label>
                <mat-error *ngIf="validations.get('size').errors" style="color:red"></mat-error>
              </td>
              <td>
                <div class="form-group">
                  <div class="form-group__text">
                    <input matInput [ngClass]="validations.get('name').errors ? 'text-danger': 'text-normal'"
                      [(ngModel)]="dimmsData.name" formControlName="name" required style="width: 100%;">
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td></td>
              <td></td>
            </tr>
            <!-- pid search -->
            <tr>
              <td class="mandatory" style="vertical-align: top;padding-top: 15px;
              ">
                <mat-label>PID</mat-label>
              </td>
              <td>
                <app-search-box *ngIf="showPID" [type]="'component'" [pid]="selectedPid"
                  (pidSelectionEvent)="selectedPID($event)"></app-search-box>
              </td>
              <td></td>

            </tr>
            <tr>
              <td></td>
              <td>

              </td>
            </tr>
            <tr>
              <td class="mandatory"> <mat-label>Idle Power 1DPC (W)</mat-label></td>
              <td>
                <div class="form-group">
                  <div class="form-group__text">
                    <input matInput (ngModelChange)="validatePower($event)" [ngClass]="validations.get('idleWatts').hasError('required') || 
                                               validations.get('idleWatts').hasError('pattern') ||
                                               validations.get('idleWatts').hasError('min') ||
                                               showWattErr || 
                                               IdleWattsErr ? 'text-danger': 'text-normal'"
                      [(ngModel)]="dimmsData.idleWatts" formControlName="idleWatts">
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td></td>
              <td>
                <div
                  *ngIf="validations.get('idleWatts').hasError('pattern') || validations.get('idleWatts').hasError('min')"
                  style=color:red>Only Positive Integers are allowed</div>
                <div *ngIf="showWattErr" style=color:red>Idle Power should be less than or equal to Max Power </div>
                <div *ngIf="IdleWattsErr" style=color:red>Only Positive Integers are allowed </div>
              </td>
            </tr>
            <tr>
              <td class="mandatory"> <mat-label>Max Power 1DPC (W)</mat-label></td>
              <td>
                <div class="form-group">
                  <div class="form-group__text">
                    <input matInput (ngModelChange)="validatePower($event)" [ngClass]="validations.get('maxWatts').hasError('required') ||
                                      validations.get('maxWatts').hasError('pattern') ||
                                      validations.get('maxWatts').hasError('min') ||
                                      showWattErr || 
                                      IdleMaxWattsErr ? 'text-danger': 'text-normal'" [(ngModel)]="dimmsData.maxWatts"
                      formControlName="maxWatts">
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td></td>
              <td>
                <div
                  *ngIf="validations.get('maxWatts').hasError('pattern') || validations.get('maxWatts').hasError('min') "
                  style=color:red>Only Positive Integers are allowed</div>
                <div *ngIf="showWattErr" style=color:red>Max Power should be greater than or equal to Idle Power</div>
                <div *ngIf="IdleMaxWattsErr" style=color:red>Only Positive Integers are allowed </div>
              </td>
            </tr> 
            <tr>
              <td class="mandatory"> <mat-label>Idle Power 2DPC (W)</mat-label></td>
              <td>
                <div class="form-group" > 
                  <div class="form-group__text">
                    <input matInput (ngModelChange)="validatePower($event)" 
                                    [ngClass]="validations.get('idleWatts2Dpc').hasError('pattern') ||
                                               validations.get('idleWatts2Dpc').hasError('min') ||
                                               showWatt2DpcErr || 
                                               IdleWatts2DpcErr ? 'text-danger': 'text-normal'" 
                                    [(ngModel)]="dimmsData.idleWatts2Dpc" formControlName="idleWatts2Dpc">
                  </div>
                </div>
              </td> 
            </tr>  
            <tr>
              <td></td> 
              <td>
                <div *ngIf ="validations.get('idleWatts2Dpc').hasError('pattern') || validations.get('idleWatts2Dpc').hasError('min')" style = color:red>Only Positive Integers are allowed</div>
                <div *ngIf ="showWatt2DpcErr" style = color:red>Idle Power 2DPC should be less than or equal to Max Power 2DPC </div>
                <div *ngIf ="IdleWatts2DpcErr" style = color:red>Only Positive Integers are allowed </div>
              </td>
            </tr> 
            <tr>
              <td class="mandatory"> <mat-label>Max Power 2DPC (W)</mat-label></td>
              <td>
                <div class="form-group" > 
                  <div class="form-group__text">
                    <input matInput (ngModelChange)="validatePower($event)" 
                           [ngClass]="validations.get('maxWatts2Dpc').hasError('pattern') ||
                                      validations.get('maxWatts2Dpc').hasError('min') ||
                                      showWatt2DpcErr || 
                                      IdleMaxWatts2DpcErr ? 'text-danger': 'text-normal'" 
                           [(ngModel)]="dimmsData.maxWatts2Dpc" formControlName="maxWatts2Dpc">
                  </div>
                </div>
              </td> 
            </tr>
            <tr>
              <td></td> 
              <td>
                <div *ngIf ="validations.get('maxWatts2Dpc').hasError('pattern') || validations.get('maxWatts2Dpc').hasError('min') " style = color:red>Only Positive Integers are allowed</div>
                <div *ngIf ="showWatt2DpcErr" style = color:red>Max Power 2DPC should be greater than or equal to Idle Power 2DPC</div>
                <div *ngIf ="IdleMaxWatts2DpcErr" style = color:red>Only Positive Integers are allowed </div>

              </td>
            </tr>
            <tr>
              <td class="mandatory"> <mat-label>MTBF Hours</mat-label></td>
              <td>
                <div class="form-group">
                  <div class="form-group__text">
                    <input matInput (ngModelChange)="validatePower($event)"
                      [ngClass]="validations.get('mtbf').hasError('pattern') || mtbfErr ? 'text-danger': 'text-normal'"
                      [(ngModel)]="dimmsData.mtbf" formControlName="mtbf" style="width: 100%;">
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td></td>
              <td>
                <mat-error *ngIf="mtbfErr" style=color:red>Only Non-Negative Integers are allowed </mat-error>
              </td>
            </tr>
            <tr>
              <td class="mandatory"> <mat-label>Channel Idle Power(W)</mat-label></td>
              <td>
                <div class="form-group">
                  <div class="form-group__text">
                    <input matInput (ngModelChange)="validatePower($event)" [ngClass]="validations.get('moreMemoryModulePerChannelIdlePower').hasError('pattern') || 
                                showChannelWattErr ||
                                moreMemoryModulePerChannelIdlePowerErr
                                 ? 'text-danger': 'text-normal'"
                      [(ngModel)]="dimmsData.moreMemoryModulePerChannelIdlePower"
                      formControlName="moreMemoryModulePerChannelIdlePower" style="width: 100%;">
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td></td>
              <td>
                <!--<mat-error *ngIf ="validations.get('mtbf').hasError('pattern')" style = color:red>Only Positive Integers are allowed</mat-error>-->
                <mat-error *ngIf="moreMemoryModulePerChannelIdlePowerErr" style=color:red>Only Positive Integers are
                  allowed </mat-error>
                <mat-error *ngIf="validations.get('moreMemoryModulePerChannelIdlePower').hasError('pattern')"
                  style=color:red>Only Positive Integers are allowed</mat-error>
                <mat-error *ngIf="showChannelWattErr" style=color:red>Idle Power should be less than or equal to Max
                  Power </mat-error>

              </td>
            </tr>
            <tr>
              <td class="mandatory"> <mat-label>Channel Max Power(W)</mat-label></td>
              <td>
                <div class="form-group">
                  <div class="form-group__text">
                    <input matInput (ngModelChange)="validatePower($event)" [ngClass]="validations.get('moreMemoryModulePerChannelMaxPower').hasError('pattern') 
                                           || showChannelWattErr 
                                           || moreMemoryModulePerChannelMaxPowerErr
                                            ? 'text-danger': 'text-normal'"
                      [(ngModel)]="dimmsData.moreMemoryModulePerChannelMaxPower"
                      formControlName="moreMemoryModulePerChannelMaxPower" style="width: 100%;">
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td></td>
              <td>
                <!--<mat-error *ngIf ="validations.get('mtbf').hasError('pattern')" style = color:red>Only Positive Integers are allowed</mat-error>-->
                <mat-error *ngIf="moreMemoryModulePerChannelMaxPowerErr" style=color:red>Only Positive Integers are
                  allowed </mat-error>
                <mat-error *ngIf="validations.get('moreMemoryModulePerChannelMaxPower').hasError('pattern')"
                  style=color:red>Only Positive Integers are allowed</mat-error>
                <mat-error *ngIf="showChannelWattErr" style=color:red>Max Power should be greater than or equal to Idle
                  Power </mat-error>

              </td>
            </tr>

            <tr>
              <td class="mandatory"> <mat-label>Persistent Idle Power(W)</mat-label></td>
              <td>
                <div class="form-group">
                  <div class="form-group__text">
                    <input matInput (ngModelChange)="validatePower($event)" [ngClass]="validations.get('persistentModelIdlePower').hasError('pattern') 
                                         || showPersistentWattErr
                                         || persistentModelIdlePowerErr ? 'text-danger': 'text-normal'"
                      [(ngModel)]="dimmsData.persistentModelIdlePower" formControlName="persistentModelIdlePower"
                      style="width: 100%;">
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td></td>
              <td>
                <mat-error *ngIf="persistentModelIdlePowerErr" style=color:red>Only Positive Integers are allowed
                </mat-error>
                <mat-error *ngIf="validations.get('persistentModelIdlePower').hasError('pattern')" style=color:red>Only
                  Positive Integers are allowed</mat-error>
                <mat-error *ngIf="showPersistentWattErr" style=color:red>Idle Power should be less than or equal to Max
                  Power </mat-error>

              </td>
            </tr>


            <tr>
              <td class="mandatory"> <mat-label>Persistent Max Power(W)</mat-label></td>
              <td>
                <div class="form-group">
                  <div class="form-group__text">
                    <input matInput (ngModelChange)="validatePower($event)" [ngClass]="validations.get('persistentModelMaxPower').hasError('pattern') 
                                           || showPersistentWattErr
                                           || persistentModelMaxPowerErr ? 'text-danger': 'text-normal'"
                      [(ngModel)]="dimmsData.persistentModelMaxPower" formControlName="persistentModelMaxPower"
                      style="width: 100%;">
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td></td>
              <td>
                <mat-error *ngIf="persistentModelMaxPowerErr" style=color:red>Only Positive Integers are allowed
                </mat-error>
                <mat-error *ngIf="validations.get('persistentModelMaxPower').hasError('pattern')" style=color:red>Only
                  Positive Integers are allowed</mat-error>
                <mat-error *ngIf="showPersistentWattErr" style=color:red>Max Power should be greater than or equal to
                  Idle Power </mat-error>

              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </mat-form-field>
  </div>

  <div mat-dialog-actions>
    <table class="table table-bordered">
      <tbody>
        <tr>
          <td>
            <mat-card-actions>
              <button mat-button (click)="saveDimmsFamily()" class="btn btn--small "
                style="height: 24px;margin: 5px 20px 0 0;" [disabled]="!validations.valid || showWattErr || 
                                                                      IdleWattsErr || IdleMaxWattsErr ||
                                                                      showWatt2DpcErr || 
                                                                      IdleWatts2DpcErr || IdleMaxWatts2DpcErr ||
                                                                       mtbfErr || 
                                                                       moreMemoryModulePerChannelIdlePowerErr ||
                                                                       moreMemoryModulePerChannelMaxPowerErr ||
                                                                       persistentModelIdlePowerErr ||
                                                                       persistentModelMaxPowerErr ||
                                                                       showChannelWattErr ||
                                                                       showPersistentWattErr || !selectedPid">Save &
                Close</button>
            </mat-card-actions>
            <button mat-button (click)="onNoClick()" class="btn btn--small " style="height: 24px;margin: 5px 20px 0 0;"
              [disabled]="!validations.valid || showWattErr || 
                       IdleWattsErr || IdleMaxWattsErr ||
                       showWatt2DpcErr || 
                       IdleWatts2DpcErr || IdleMaxWatts2DpcErr ||
                       mtbfErr || 
                       moreMemoryModulePerChannelIdlePowerErr ||
                       moreMemoryModulePerChannelMaxPowerErr ||
                       persistentModelIdlePowerErr ||
                       persistentModelMaxPowerErr ||
                       showChannelWattErr ||
                       showPersistentWattErr || !selectedPid">Save</button>
          </td>
        </tr>
      </tbody>
    </table>


  </div>
</form>