import { Component, OnInit, Inject, HostListener } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GridOptions, initialiseAgGridWithWebComponents } from "ag-grid-community";
import { UntypedFormControl, UntypedFormBuilder, UntypedFormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { Subscription } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ComponentConstant } from 'src/constants/components.constants';
import { ToasterService } from "src/app/shared/components/toast/toast.service";
import { ApiService } from 'src/app/services/api.service';
import { UrlConstant } from 'src/constants/url.constants';
import { EditRenderComponent } from 'src/app/shared/components/edit-render/edit-render.component';
//import { CreatePowersupplyComponent} from 'src/app/components/admin/admin-tab/create-components/create-powersupply/create-powersupply.component';
//import { CookieService } from 'ngx-cookie-service';
import * as _ from "lodash";
import { errorCodes } from 'src/constants/errorCodes';
//import { parse } from 'path';
import { BlockUI, NgBlockUI } from 'ng-block-ui';

import { AdminService } from 'src/app/services/admin.service';




export interface DialogData {

  displayName: string;
  internalName: string;
  pid: string
  capacity: string;
  mtbf: string;
  type: string;
  id: string;
  update: boolean;
  released: string;
  powerSupplyInputs: any;
  voltage: string;
  inputVoltage: number;
  inputType: string;
  a0PercentEff: any;
  a5PercentEff: any;
  a10PercentEff: any;
  a15PercentEff: any;
  a20PercentEff: any;
  a25PercentEff: any;
  a30PercentEff: any;
  a35PercentEff: any;
  a40PercentEff: any;
  a45PercentEff: any;
  a50PercentEff: any;
  a55PercentEff: any;

  a60PercentEff: any;
  a65PercentEff: any;
  a70PercentEff: any;
  a75PercentEff: any;
  a80PercentEff: any;
  a85PercentEff: any;
  a90PercentEff: any;
  a95PercentEff: any;
  a100PercentEff: any;

  a0PercentPf: any;
  a5PercentPf: any;
  a10PercentPf: any;
  a15PercentPf: any;
  a20PercentPf: any;
  a25PercentPf: any;
  a30PercentPf: any;
  a35PercentPf: any;
  a40PercentPf: any;
  a45PercentPf: any;
  a50PercentPf: any;
  a55PercentPf: any;

  a60PercentPf: any;
  a65PercentPf: any;
  a70PercentPf: any;
  a75PercentPf: any;
  a80PercentPf: any;
  a85PercentPf: any;
  a90PercentPf: any;
  a95PercentPf: any;
  a100PercentPf: any;
  selectedVoltage: any;

}

interface Voltage {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-create-voltage',
  templateUrl: './create-voltage.component.html',
  styleUrls: ['./create-voltage.component.css']
})

export class CreateVoltageComponent implements OnInit {

  subscriptions: Subscription = new Subscription();
  columnDefs;
  frameworkComponents;
  defaultColDef;
  rowData: [];
  gridOptions: GridOptions = {};
  id;
  powerSupplyName;
  powerSupplyPid;
  powerSupplyCapacity;
  form: string;
  validations: UntypedFormGroup;
  selectedVoltage: any = [];
  selectedVoltageViewValue: any;
  voltages: Voltage[] = [];
  zeroPercentEff;
  object;
  @BlockUI() blockUI: NgBlockUI;

  curvePointdata_cellStyle: any = { "white-space": "break-spaces", "line-height": "1.5" };

  constructor(
    private apiService: ApiService,
    public dialog: MatDialog,
    public toaster: ToasterService,
    private adminService: AdminService,

    public dialogRef: MatDialogRef<CreateVoltageComponent>,
    @Inject(MAT_DIALOG_DATA) public powerSupplyData: DialogData,
    private formBuilder: UntypedFormBuilder
  ) { //private cookieService : CookieService



    this.form = "curvePointdata";
    var today = new Date();
    //displaying
    this.powerSupplyName = this.powerSupplyData.displayName;
    this.powerSupplyPid = this.powerSupplyData.pid;
    this.powerSupplyCapacity = this.powerSupplyData.capacity;

    this.id = this.powerSupplyData.id;
    localStorage.setItem('voltageCurveForm', this.form);

    this.validations = this.formBuilder.group({
      voltage: new UntypedFormControl('', [Validators.required]),
      a0PercentEff: new UntypedFormControl('', [Validators.pattern("^\\d*\\.?\\d+$"), Validators.required, Validators.min(0.00000000001), Validators.max(1.0)]),
      a5PercentEff: new UntypedFormControl('', [Validators.pattern("^\\d*\\.?\\d+$"), Validators.required, Validators.min(0.00000000001), Validators.max(1.0)]),
      a10PercentEff: new UntypedFormControl('', [Validators.pattern("^\\d*\\.?\\d+$"), Validators.required, Validators.min(0.00000000001), Validators.max(1.0)]),

      a15PercentEff: new UntypedFormControl('', [Validators.pattern("^\\d*\\.?\\d+$"), Validators.required, Validators.min(0.00000000001), Validators.max(1.0)]),
      a20PercentEff: new UntypedFormControl('', [Validators.pattern("^\\d*\\.?\\d+$"), Validators.required, Validators.min(0.00000000001), Validators.max(1.0)]),
      a25PercentEff: new UntypedFormControl('', [Validators.pattern("^\\d*\\.?\\d+$"), Validators.required, Validators.min(0.00000000001), Validators.max(1.0)]),
      a30PercentEff: new UntypedFormControl('', [Validators.pattern("^\\d*\\.?\\d+$"), Validators.required, Validators.min(0.00000000001), Validators.max(1.0)]),
      a35PercentEff: new UntypedFormControl('', [Validators.pattern("^\\d*\\.?\\d+$"), Validators.required, Validators.min(0.00000000001), Validators.max(1.0)]),
      a40PercentEff: new UntypedFormControl('', [Validators.pattern("^\\d*\\.?\\d+$"), Validators.required, Validators.min(0.00000000001), Validators.max(1.0)]),
      a45PercentEff: new UntypedFormControl('', [Validators.pattern("^\\d*\\.?\\d+$"), Validators.required, Validators.min(0.00000000001), Validators.max(1.0)]),
      a50PercentEff: new UntypedFormControl('', [Validators.pattern("^\\d*\\.?\\d+$"), Validators.required, Validators.min(0.00000000001), Validators.max(1.0)]),
      a55PercentEff: new UntypedFormControl('', [Validators.pattern("^\\d*\\.?\\d+$"), Validators.required, Validators.min(0.00000000001), Validators.max(1.0)]),

      a60PercentEff: new UntypedFormControl('', [Validators.pattern("^\\d*\\.?\\d+$"), Validators.required, Validators.min(0.00000000001), Validators.max(1.0)]),
      a65PercentEff: new UntypedFormControl('', [Validators.pattern("^\\d*\\.?\\d+$"), Validators.required, Validators.min(0.00000000001), Validators.max(1.0)]),
      a70PercentEff: new UntypedFormControl('', [Validators.pattern("^\\d*\\.?\\d+$"), Validators.required, Validators.min(0.00000000001), Validators.max(1.0)]),
      a75PercentEff: new UntypedFormControl('', [Validators.pattern("^\\d*\\.?\\d+$"), Validators.required, Validators.min(0.00000000001), Validators.max(1.0)]),
      a80PercentEff: new UntypedFormControl('', [Validators.pattern("^\\d*\\.?\\d+$"), Validators.required, Validators.min(0.00000000001), Validators.max(1.0)]),
      a85PercentEff: new UntypedFormControl('', [Validators.pattern("^\\d*\\.?\\d+$"), Validators.required, Validators.min(0.00000000001), Validators.max(1.0)]),
      a90PercentEff: new UntypedFormControl('', [Validators.pattern("^\\d*\\.?\\d+$"), Validators.required, Validators.min(0.00000000001), Validators.max(1.0)]),
      a95PercentEff: new UntypedFormControl('', [Validators.pattern("^\\d*\\.?\\d+$"), Validators.required, Validators.min(0.00000000001), Validators.max(1.0)]),
      a100PercentEff: new UntypedFormControl('', [Validators.pattern("^\\d*\\.?\\d+$"), Validators.required, Validators.min(0.00000000001), Validators.max(1.0)]),

      a0PercentPf: new UntypedFormControl('', [Validators.required,
      Validators.pattern("^\\d*\\.?\\d+$"), Validators.required, Validators.min(0.00000000001), Validators.max(1.0)]),
      a5PercentPf: new UntypedFormControl('', [Validators.pattern("^\\d*\\.?\\d+$"), Validators.required, Validators.min(0.00000000001), Validators.max(1.0)]),
      a10PercentPf: new UntypedFormControl('', [Validators.pattern("^\\d*\\.?\\d+$"), Validators.required, Validators.min(0.00000000001), Validators.max(1.0)]),

      a15PercentPf: new UntypedFormControl('', [Validators.pattern("^\\d*\\.?\\d+$"), Validators.required, Validators.min(0.00000000001), Validators.max(1.0)]),
      a20PercentPf: new UntypedFormControl('', [Validators.pattern("^\\d*\\.?\\d+$"), Validators.required, Validators.min(0.00000000001), Validators.max(1.0)]),
      a25PercentPf: new UntypedFormControl('', [Validators.pattern("^\\d*\\.?\\d+$"), Validators.required, Validators.min(0.00000000001), Validators.max(1.0)]),
      a30PercentPf: new UntypedFormControl('', [Validators.pattern("^\\d*\\.?\\d+$"), Validators.required, Validators.min(0.00000000001), Validators.max(1.0)]),
      a35PercentPf: new UntypedFormControl('', [Validators.pattern("^\\d*\\.?\\d+$"), Validators.required, Validators.min(0.00000000001), Validators.max(1.0)]),
      a40PercentPf: new UntypedFormControl('', [Validators.pattern("^\\d*\\.?\\d+$"), Validators.required, Validators.min(0.00000000001), Validators.max(1.0)]),
      a45PercentPf: new UntypedFormControl('', [Validators.pattern("^\\d*\\.?\\d+$"), Validators.required, Validators.min(0.00000000001), Validators.max(1.0)]),
      a50PercentPf: new UntypedFormControl('', [Validators.pattern("^\\d*\\.?\\d+$"), Validators.required, Validators.min(0.00000000001), Validators.max(1.0)]),
      a55PercentPf: new UntypedFormControl('', [Validators.pattern("^\\d*\\.?\\d+$"), Validators.required, Validators.min(0.00000000001), Validators.max(1.0)]),

      a60PercentPf: new UntypedFormControl('', [Validators.pattern("^\\d*\\.?\\d+$"), Validators.required, Validators.min(0.00000000001), Validators.max(1.0)]),
      a65PercentPf: new UntypedFormControl('', [Validators.pattern("^\\d*\\.?\\d+$"), Validators.required, Validators.min(0.00000000001), Validators.max(1.0)]),
      a70PercentPf: new UntypedFormControl('', [Validators.pattern("^\\d*\\.?\\d+$"), Validators.required, Validators.min(0.00000000001), Validators.max(1.0)]),
      a75PercentPf: new UntypedFormControl('', [Validators.pattern("^\\d*\\.?\\d+$"), Validators.required, Validators.min(0.00000000001), Validators.max(1.0)]),
      a80PercentPf: new UntypedFormControl('', [Validators.pattern("^\\d*\\.?\\d+$"), Validators.required, Validators.min(0.00000000001), Validators.max(1.0)]),
      a85PercentPf: new UntypedFormControl('', [Validators.pattern("^\\d*\\.?\\d+$"), Validators.required, Validators.min(0.00000000001), Validators.max(1.0)]),
      a90PercentPf: new UntypedFormControl('', [Validators.pattern("^\\d*\\.?\\d+$"), Validators.required, Validators.min(0.00000000001), Validators.max(1.0)]),
      a95PercentPf: new UntypedFormControl('', [Validators.pattern("^\\d*\\.?\\d+$"), Validators.required, Validators.min(0.00000000001), Validators.max(1.0)]),
      a100PercentPf: new UntypedFormControl('', [Validators.pattern("^\\d*\\.?\\d+$"), Validators.required, Validators.min(0.00000000001), Validators.max(1.0)]),
    })
    if (this.powerSupplyData.powerSupplyInputs.length == 1 && this.powerSupplyData.powerSupplyInputs[0].inputType == null) {
      this.form = "newCurvePoint";
    } else if (this.powerSupplyData.powerSupplyInputs.length > 0) {
      // this.rowData = this.powerSupplyData.powerSupplyInputs;                   
    }
    else {
      this.form = "newCurvePoint";
    }

    this.columnDefs = [
      {
        headerName: 'Voltage', field: 'inputType', cellStyle: { "font-weight": "600" },
        minWidth: 100,
      },
      {
        headerName: '', field: '',
        cellRenderer: (params) => {
          return " EFF" + "\nPF";
        },
        cellStyle: {
          "white-space": "break-spaces",
          "line-height": "1.5",
          "text-align": "center",
          "font-weight": "600"
        },
        autoHeight: true,
        wrapText: true,
        minWidth: 80,
        suppressMenu: true
      },
      {
        headerName: '0%', field: 'a0percentEff',
        cellRenderer: (params) => {
          return params.value + "\n" + params.data.a0percentPf;
        },
        cellStyle: this.curvePointdata_cellStyle,
        autoHeight: true,
        wrapText: true
      },
      {
        headerName: '5%', field: 'a5percentEff',
        cellRenderer: (params) => {
          return params.value + "\n" + params.data.a5percentPf;
        },
        cellStyle: this.curvePointdata_cellStyle,
        autoHeight: true
      },
      {
        headerName: '10%', field: 'a10percentEff',
        cellRenderer: (params) => {
          return params.value + "\n" + params.data.a10percentPf;
        },
        cellStyle: this.curvePointdata_cellStyle,
        autoHeight: true
      },
      {
        headerName: '15%', field: 'a15percentEff',
        cellRenderer: (params) => {
          return params.value + "\n" + params.data.a15percentPf;
        },
        cellStyle: this.curvePointdata_cellStyle,
        autoHeight: true
      },
      {
        headerName: '20%', field: 'a20percentEff',
        cellRenderer: (params) => {
          return params.value + "\n" + params.data.a20percentPf;
        },
        cellStyle: this.curvePointdata_cellStyle,
        autoHeight: true
      },
      {
        headerName: '25%', field: 'a25percentEff',
        cellRenderer: (params) => {
          return params.value + "\n" + params.data.a25percentPf;
        },
        cellStyle: this.curvePointdata_cellStyle,
        autoHeight: true
      },
      {
        headerName: '30%', field: 'a30percentEff',
        cellRenderer: (params) => {
          return params.value + "\n" + params.data.a30percentPf;
        },
        cellStyle: this.curvePointdata_cellStyle,
        autoHeight: true
      },
      {
        headerName: '35%', field: 'a35percentEff',
        cellRenderer: (params) => {
          return params.value + "\n" + params.data.a35percentPf;
        },
        cellStyle: this.curvePointdata_cellStyle,
        autoHeight: true
      },
      {
        headerName: '40%', field: 'a40percentEff',
        cellRenderer: (params) => {
          return params.value + "\n" + params.data.a40percentPf;
        },
        cellStyle: this.curvePointdata_cellStyle,
        autoHeight: true
      },
      {
        headerName: '45%', field: 'a45percentEff',
        cellRenderer: (params) => {
          return params.value + "\n" + params.data.a45percentPf;
        },
        cellStyle: this.curvePointdata_cellStyle,
        autoHeight: true
      },
      {
        headerName: '50%', field: 'a50percentEff',
        cellRenderer: (params) => {
          return params.value + "\n" + params.data.a50percentPf;
        },
        cellStyle: this.curvePointdata_cellStyle,
        autoHeight: true
      },
      {
        headerName: '55%', field: 'a55percentEff',
        cellRenderer: (params) => {
          return params.value + "\n" + params.data.a55percentPf;
        },
        cellStyle: this.curvePointdata_cellStyle,
        autoHeight: true
      },
      {
        headerName: '60%', field: 'a60percentEff',
        cellRenderer: (params) => {
          return params.value + "\n" + params.data.a60percentPf;
        },
        cellStyle: this.curvePointdata_cellStyle,
        autoHeight: true
      },
      {
        headerName: '65%', field: 'a65percentEff',
        cellRenderer: (params) => {
          return params.value + "\n" + params.data.a65percentPf;
        },
        cellStyle: this.curvePointdata_cellStyle,
        autoHeight: true
      },
      {
        headerName: '70%', field: 'a70percentEff',
        cellRenderer: (params) => {
          return params.value + "\n" + params.data.a70percentPf;
        },
        cellStyle: this.curvePointdata_cellStyle,
        autoHeight: true
      },
      {
        headerName: '75%', field: 'a75percentEff',
        cellRenderer: (params) => {
          return params.value + "\n" + params.data.a75percentPf;
        },
        cellStyle: this.curvePointdata_cellStyle,
        autoHeight: true
      },
      {
        headerName: '80%', field: 'a80percentEff',
        cellRenderer: (params) => {
          return params.value + "\n" + params.data.a80percentPf;
        },
        cellStyle: this.curvePointdata_cellStyle,
        autoHeight: true
      },
      {
        headerName: '85%', field: 'a85percentEff',
        cellRenderer: (params) => {
          return params.value + "\n" + params.data.a85percentPf;
        },
        cellStyle: this.curvePointdata_cellStyle,
        autoHeight: true
      },
      {
        headerName: '90%', field: 'a90percentEff',
        cellRenderer: (params) => {
          return params.value + "\n" + params.data.a90percentPf;
        },
        cellStyle: this.curvePointdata_cellStyle,
        autoHeight: true
      },
      {
        headerName: '95%', field: 'a95percentEff',
        cellRenderer: (params) => {
          return params.value + "\n" + params.data.a95percentPf;
        },
        cellStyle: this.curvePointdata_cellStyle,
        autoHeight: true
      },
      {
        headerName: '100%', field: 'a100percentEff',
        cellRenderer: (params) => {
          return params.value + "\n" + params.data.a100percentPf;
        },
        cellStyle: this.curvePointdata_cellStyle,
        autoHeight: true
      },
      {
        headerName: "",
        minWidth: 40,
        maxWidth: 50,
        cellRenderer: "buttonEditRenderer",
        cellRendererParams: {
          onClick: this.onEditButtonClick.bind(this),
          label: "editCurvePoint",
          powerSupplyData: this.powerSupplyData.powerSupplyInputs
        },
      },
      {
        headerName: "",
        minWidth: 40,
        maxWidth: 50,
        cellRenderer: "buttonEditRenderer",
        cellRendererParams: {
          onClick: this.onDeleteButtonClick.bind(this),
          label: "DeleteCurvePoint",
        },
      },
    ];
    this.defaultColDef = {
      editable: false,
      sortable: true,
      flex: 1,
      minWidth: 90,
      filter: true,
      resizable: true,
    };

    // this.gridOptions = {
    //   // is always present, so return true
    //   isExternalFilterPresent: function() { 
    //     return true; 
    //   },

    //   // return true if flag=true
    //   doesExternalFilterPass: function(rowNode) { 
    //     
    //     return rowNode.data.deleteFlag; 
    //   }
    // };

    this.frameworkComponents = {
      buttonEditRenderer: EditRenderComponent,
    };
  }
  paginationPageSize = 10;

  isExternalFilterPresent() {
    return true;
  }

  doesExternalFilterPass(rowNode) {

    if (rowNode.data.deleteFlag === true) {
      return false;
    }
    else {
      return true;
    }
  }

  //on select Voltage DDL
  selectVoltage(item: any) {

    let isVolttypeCompt = true;
    let type = this.selectedVoltage[0]?.viewValue.includes("VDC") ? "VDC" : "VAC";


    this.selectedVoltage.forEach(element => {
      if (!element.viewValue.includes(type)) {
        isVolttypeCompt = false;
      }
    });

    if (!item.viewValue.includes(type)) {
      this.toaster.show('error', 'Incompatible Type.', '', 2000);
      let index = this.selectedVoltage.findIndex(x => item.value === x.value);
      this.selectedVoltage.splice(index, 1);
    }

    if (this.selectedVoltage?.length == 1) {
      if (this.selectedVoltage[0].viewValue.includes("VDC")) {
        this.setDCVoltagePF1();
      }
      else {
        if (this.powerSupplyData.update) {
          this.setACVoltagePFEnable();
        } else {
          this.setACVoltagePF();
        }
      }
    }

    // for (let i=0; i<this.voltages.length; i++) {
    //     if (this.voltages[i]['value'] == this.selectedVoltage?.value.toString()){
    //     this.selectedVoltageViewValue = this.voltages[i]['viewValue'];
    //     if(this.selectedVoltageViewValue.includes("VDC")){            
    //       this.setDCVoltagePF1();                    

    //     }else {
    //       if (this.powerSupplyData.update) {
    //         this.setACVoltagePFEnable(); 
    //       } else {
    //         this.setACVoltagePF(); 
    //       }

    //     }          
    //     break;
    //   }        
    // }
  }

  voltageList: any[];
  ngOnInit(): void {


    this.getPowerSupplyById();
    // this.getVoltageList();


    this.subscriptions.add(this.adminService.getVoltageList().subscribe((data: any) => {
      this.voltageList = data;
    })
    );

    //update voltage curve
    if (this.powerSupplyData.update) {
      this.form = "newCurvePoint";

      //this.getVoltageList();   

      //this.selectedVoltage = this.powerSupplyData.voltage;//like 9/8 not label

      //for VDC
      if (localStorage.getItem('initialInputVoltageType')?.includes("VDC")) {
        this.setDCVoltagePF1();
      }
      //for edit window
      this.powerSupplyName = localStorage.getItem('powerSupplyName');
      this.powerSupplyPid = localStorage.getItem('powerSupplyPid');
      this.powerSupplyCapacity = localStorage.getItem('powerSupplyCapacity');//this.cookieService.get('powerSupplyName');
    }

  }

  setACVoltagePF() {
    this.validations.controls['a0PercentPf'].reset({ value: "", disabled: false });
    this.validations.controls['a5PercentPf'].reset({ value: "", disabled: false });
    this.validations.controls['a10PercentPf'].reset({ value: "", disabled: false });
    this.validations.controls['a15PercentPf'].reset({ value: "", disabled: false });
    this.validations.controls['a20PercentPf'].reset({ value: "", disabled: false });
    this.validations.controls['a25PercentPf'].reset({ value: "", disabled: false });
    this.validations.controls['a30PercentPf'].reset({ value: "", disabled: false });
    this.validations.controls['a35PercentPf'].reset({ value: "", disabled: false });
    this.validations.controls['a40PercentPf'].reset({ value: "", disabled: false });
    this.validations.controls['a45PercentPf'].reset({ value: "", disabled: false });
    this.validations.controls['a50PercentPf'].reset({ value: "", disabled: false });
    this.validations.controls['a55PercentPf'].reset({ value: "", disabled: false });
    this.validations.controls['a60PercentPf'].reset({ value: "", disabled: false });
    this.validations.controls['a65PercentPf'].reset({ value: "", disabled: false });
    this.validations.controls['a70PercentPf'].reset({ value: "", disabled: false });
    this.validations.controls['a75PercentPf'].reset({ value: "", disabled: false });
    this.validations.controls['a80PercentPf'].reset({ value: "", disabled: false });
    this.validations.controls['a85PercentPf'].reset({ value: "", disabled: false });
    this.validations.controls['a90PercentPf'].reset({ value: "", disabled: false });
    this.validations.controls['a95PercentPf'].reset({ value: "", disabled: false });
    this.validations.controls['a100PercentPf'].reset({ value: "", disabled: false });
  }
  setDCVoltagePF1() {
    this.validations.controls['a0PercentPf'].reset({ value: 1, disabled: true });
    this.validations.controls['a5PercentPf'].reset({ value: 1, disabled: true });
    this.validations.controls['a10PercentPf'].reset({ value: 1, disabled: true });
    this.validations.controls['a15PercentPf'].reset({ value: 1, disabled: true });
    this.validations.controls['a20PercentPf'].reset({ value: 1, disabled: true });
    this.validations.controls['a25PercentPf'].reset({ value: 1, disabled: true });
    this.validations.controls['a30PercentPf'].reset({ value: 1, disabled: true });
    this.validations.controls['a35PercentPf'].reset({ value: 1, disabled: true });
    this.validations.controls['a40PercentPf'].reset({ value: 1, disabled: true });
    this.validations.controls['a45PercentPf'].reset({ value: 1, disabled: true });
    this.validations.controls['a50PercentPf'].reset({ value: 1, disabled: true });
    this.validations.controls['a55PercentPf'].reset({ value: 1, disabled: true });
    this.validations.controls['a60PercentPf'].reset({ value: 1, disabled: true });
    this.validations.controls['a65PercentPf'].reset({ value: 1, disabled: true });
    this.validations.controls['a70PercentPf'].reset({ value: 1, disabled: true });
    this.validations.controls['a75PercentPf'].reset({ value: 1, disabled: true });
    this.validations.controls['a80PercentPf'].reset({ value: 1, disabled: true });
    this.validations.controls['a85PercentPf'].reset({ value: 1, disabled: true });
    this.validations.controls['a90PercentPf'].reset({ value: 1, disabled: true });
    this.validations.controls['a95PercentPf'].reset({ value: 1, disabled: true });
    this.validations.controls['a100PercentPf'].reset({ value: 1, disabled: true });

  }
  setACVoltagePFEnable() {
    this.validations.controls['a0PercentPf'].reset({ value: 1, disabled: false });
    this.validations.controls['a5PercentPf'].reset({ value: 1, disabled: false });
    this.validations.controls['a10PercentPf'].reset({ value: 1, disabled: false });
    this.validations.controls['a15PercentPf'].reset({ value: 1, disabled: false });
    this.validations.controls['a20PercentPf'].reset({ value: 1, disabled: false });
    this.validations.controls['a25PercentPf'].reset({ value: 1, disabled: false });
    this.validations.controls['a30PercentPf'].reset({ value: 1, disabled: false });
    this.validations.controls['a35PercentPf'].reset({ value: 1, disabled: false });
    this.validations.controls['a40PercentPf'].reset({ value: 1, disabled: false });
    this.validations.controls['a45PercentPf'].reset({ value: 1, disabled: false });
    this.validations.controls['a50PercentPf'].reset({ value: 1, disabled: false });
    this.validations.controls['a55PercentPf'].reset({ value: 1, disabled: false });
    this.validations.controls['a60PercentPf'].reset({ value: 1, disabled: false });
    this.validations.controls['a65PercentPf'].reset({ value: 1, disabled: false });
    this.validations.controls['a70PercentPf'].reset({ value: 1, disabled: false });
    this.validations.controls['a75PercentPf'].reset({ value: 1, disabled: false });
    this.validations.controls['a80PercentPf'].reset({ value: 1, disabled: false });
    this.validations.controls['a85PercentPf'].reset({ value: 1, disabled: false });
    this.validations.controls['a90PercentPf'].reset({ value: 1, disabled: false });
    this.validations.controls['a95PercentPf'].reset({ value: 1, disabled: false });
    this.validations.controls['a100PercentPf'].reset({ value: 1, disabled: false });

  }
  powerSupplyVoltages: any;
  getVoltageList() {
    this.blockUI.start('Loading...');


    this.powerSupplyVoltages = [];
    const urlGetPowersupplyVoltages = UrlConstant.MANAGECOMPONENTS + '/powerSupplyVoltage/?id=' + localStorage.getItem('powerSupplyId');

    this.subscriptions.add(
      this.apiService.getMethod(urlGetPowersupplyVoltages).subscribe((powerSupplyVoltagesData: any) => {


        for (let i = 0; i < powerSupplyVoltagesData[0].powerSupplyInputs.length; i++) {

          if (powerSupplyVoltagesData[0].powerSupplyInputs[i].inputType != null &&
            powerSupplyVoltagesData[0].powerSupplyInputs[i].deleteFlag == false) {

            var powerSupplyVoltagesDataObj = {
              'inputType': powerSupplyVoltagesData[0].powerSupplyInputs[i].inputType,
              'index': powerSupplyVoltagesData[0].powerSupplyInputs[i].inputVoltage
            }
            this.powerSupplyVoltages.push(powerSupplyVoltagesDataObj);
          }
        }



        this.voltages = [];
        let voltages = ComponentConstant.VOLTAGES;
        const url = UrlConstant.MANAGECOMPONENTS + '/?component=' + voltages;
        this.subscriptions.add(
          this.adminService.getVoltageList().subscribe((data: any) => {


            var Obj3 = _.differenceWith(data, this.powerSupplyData.powerSupplyInputs, function (o1, o2) {
              return o1['sequence'] === o2['inputVoltage'] && o2['deleteFlag'] !== true
            });


            let voltageDDL = []

            //for update
            if (this.powerSupplyData.update) {
              for (let i = 0; i < data.length; i++) {
                if (this.powerSupplyData.inputVoltage == data[i].sequence) {
                  let updateVoltageObj = {
                    value: data[i].sequence,
                    id: data[i].sequence,
                    viewValue: data[i].name
                  }
                  //push only if voltage not in Obj3
                  let voldata = Obj3.filter(v => { return v.sequence == data[i].sequence });

                  if (voldata.length == 0)
                    voltageDDL.push(updateVoltageObj);
                }
              }
            }




            for (let i = 0; i < Obj3.length; i++) {
              let voltageObj = {
                value: Obj3[i].index,
                viewValue: Obj3[i].name,
                id: Obj3[i].index,

              }
              voltageDDL.push(voltageObj);
            }

            this.voltages = voltageDDL;

            if (this.powerSupplyData.update) {
              let ddl = this.voltages.find(row => this.powerSupplyData.voltage == row.value);
              this.selectedVoltage.push(ddl);
              //this.powerSupplyData.voltage;

              this.validations.controls['voltage'].patchValue(this.selectedVoltage);

            }


            this.blockUI.stop();

          }, (error) => {
            console.error(error);
            let errorMsg = error?.error ? error.error?.error?.errorString : "Oops something went wrong!!";
            this.toaster.show('error', errorMsg, error ? error?.message : '');
            this.blockUI.stop();
          })
        );

      })
    )
  }
  onSave() {

    let params = [];
    this.selectedVoltage.forEach(element => {


      let data = {
        "type": this.powerSupplyData.type,
        "role": "Admin",
        "id": localStorage.getItem('powerSupplyId'),

        "a0percentEff": this.validations.value.a0PercentEff,
        "a5percentEff": this.validations.value.a5PercentEff,
        "a10percentEff": this.validations.value.a10PercentEff,
        "a15percentEff": this.validations.value.a15PercentEff,

        "a20percentEff": this.validations.value.a20PercentEff,
        "a25percentEff": this.validations.value.a25PercentEff,
        "a30percentEff": this.validations.value.a30PercentEff,
        "a35percentEff": this.validations.value.a35PercentEff,
        "a40percentEff": this.validations.value.a40PercentEff,
        "a45percentEff": this.validations.value.a45PercentEff,
        "a50percentEff": this.validations.value.a50PercentEff,
        "a55percentEff": this.validations.value.a55PercentEff,

        "a60percentEff": this.validations.value.a60PercentEff,
        "a65percentEff": this.validations.value.a65PercentEff,
        "a70percentEff": this.validations.value.a70PercentEff,
        "a75percentEff": this.validations.value.a75PercentEff,
        "a80percentEff": this.validations.value.a80PercentEff,
        "a85percentEff": this.validations.value.a85PercentEff,
        "a90percentEff": this.validations.value.a90PercentEff,
        "a95percentEff": this.validations.value.a95PercentEff,
        "a100percentEff": this.validations.value.a100PercentEff,


        "a0percentPf": this.validations.value.a0PercentPf,
        "a5percentPf": this.validations.value.a5PercentPf,
        "a10percentPf": this.validations.value.a10PercentPf,
        "a15percentPf": this.validations.value.a15PercentPf,

        "a20percentPf": this.validations.value.a20PercentPf,
        "a25percentPf": this.validations.value.a25PercentPf,
        "a30percentPf": this.validations.value.a30PercentPf,
        "a35percentPf": this.validations.value.a35PercentPf,
        "a40percentPf": this.validations.value.a40PercentPf,
        "a45percentPf": this.validations.value.a45PercentPf,
        "a50percentPf": this.validations.value.a50PercentPf,
        "a55percentPf": this.validations.value.a55PercentPf,

        "a60percentPf": this.validations.value.a60PercentPf,
        "a65percentPf": this.validations.value.a65PercentPf,
        "a70percentPf": this.validations.value.a70PercentPf,
        "a75percentPf": this.validations.value.a75PercentPf,
        "a80percentPf": this.validations.value.a80PercentPf,
        "a85percentPf": this.validations.value.a85PercentPf,
        "a90percentPf": this.validations.value.a90PercentPf,
        "a95percentPf": this.validations.value.a95PercentPf,
        "a100percentPf": this.validations.value.a100PercentPf,
        "inputVoltage": parseInt(element.value),
        "inputType": element.viewValue,
        "released": "",
        "allowDuplicates": false,
        "deleteFlag": false,
        "curveFlag": !this.powerSupplyData.update ? "NEWCURVE" : "UPDATE",
        "sequence": localStorage.getItem('powerSuppySequence')

      };
      params.push(data);
    });


    //if (this.form == "newCurvePoint"){
    if (!this.powerSupplyData.update) {
      // data["curveFlag"] = "NEWCURVE";
      let result = this.createNewCurve(params);
    } else {
      let voltupdate = false;
      params.forEach(ele => {
        ele.released = this.powerSupplyData.released;
        ele["id"] = localStorage.getItem('powerSupplyId');

        if (ele.inputVoltage.toString() == localStorage.getItem('initialInputVoltage')) {

          voltupdate = true;
          ele.curveFlag = "UPDATE";
          ele["initialVoltageValue"] = localStorage.getItem('initialInputVoltage');
          ele["initialVoltageType"] = localStorage.getItem('initialInputVoltageType');
        }
        else {
          ele.curveFlag = "NEWCURVE";
        }
      });

      if (!voltupdate) {
        params[0]["initialVoltageValue"] = localStorage.getItem('initialInputVoltage');
        params[0]["initialVoltageType"] = localStorage.getItem('initialInputVoltageType');
        params[0].curveFlag = "UPDATE"
      }

      // data.released = this.powerSupplyData.released;        
      // data["id"] =  localStorage.getItem('powerSupplyId');
      //localStorage.getItem('initialInputVoltage') , localStorage.getItem('initialInputVoltageType')
      // data["initialVoltageValue"] = localStorage.getItem('initialInputVoltage');
      // data["initialVoltageType"] = localStorage.getItem('initialInputVoltageType');

      // data["curveFlag"] = "UPDATE";
      //   for (let i=0; i<this.voltages.length; i++) {
      //     if (this.voltages[i]['value'] == this.selectedVoltage?.value.toString()){
      //      this.selectedVoltageViewValue = this.voltages[i]['viewValue'];
      //      break;
      //    }               
      //  }
      //  data["inputType"] = this.selectedVoltageViewValue;
      //let result = this.updateCurve(data);


      let result = this.createNewCurve(params);
    }
  }
  onNoClick() {

    if (this.powerSupplyData.update) {
      this.dialogRef.close('cancel');
    } else {
      this.form = "curvePointdata";
    }
  }

  onBackClick() {
    this.dialogRef.close('cancel');
  }
  getPowerSupplyVoltageDetails() {
    let powerSupplies = UrlConstant.POWER_SUPPLY;
    let id = '60a3dc17b4739e2cd989e4d9';
    const url = UrlConstant.MANAGECOMPONENTS + '/powerSupplyVoltage?id=' + id;
    this.subscriptions.add(
      this.apiService.getMethod(url).subscribe((data: any) => {
        this.rowData = data;
      })
    );
  }

  submitInProgress: boolean = false;
  createNewCurve(newCurveData) {

    // let params = [];
    // params.push(newCurveData);
    const url = UrlConstant.MANAGECOMPONENTS + UrlConstant.VOLTAGE_CURVE;
    this.submitInProgress = true;
    this.blockUI.start('Loading...');

    this.subscriptions.add(
      this.apiService.postMethod(url, newCurveData).subscribe((data: any) => {

        this.toaster.show('success', errorCodes.DETAILS_ADDED, '', 5000);
        this.submitInProgress = false;
        this.blockUI.stop();


        if (data.successMessage == "Successfully updated") {
          //reset forms
          this.validations.reset();
          this.setACVoltagePF();
          // this.selectedVoltage = "select";

          // var cn = confirm('Details have been added.');
          // if(cn){
          //                    

          if (newCurveData?.curveFlag == "NEWCURVE") {
            this.form = "curvePointdata";
            this.getPowerSupplyById();
          }
          else {
            this.dialogRef.close();
          }
          // }
        }

      }, error => {
        this.blockUI.stop();
        this.submitInProgress = false;
        let errorMsg = error?.error ? error.error?.error?.errorString : "Oops something went wrong!!";
        this.toaster.show('error', errorMsg, error ? error?.message : '');
      })
    );

  }
  updateCurve(updateCurveData) {


  }
  onEditButtonClick(params: any) {

    if (params !== 'cancel')
      this.getPowerSupplyById();
  }

  getPowerSupplyById() {
    const powersupplyId = localStorage.getItem('powerSupplyId');

    const url = UrlConstant.MANAGECOMPONENTS + '/powerSupply/' + powersupplyId;
    this.subscriptions.add(
      this.apiService.getMethod(url).subscribe((data: any) => {

        // To remove the null curve point
        let powerSupplyVoltages = "";
        let powerSupplyCurveArray = [];

        for (let i = 0; i < data.length; i++) {
          for (let j = 0; j < data[i].powerSupplyInputs.length; j++) {

            if (!data[i].powerSupplyInputs[j].deleteFlag && data[i].powerSupplyInputs[j].inputVoltage != null) {
              powerSupplyVoltages = powerSupplyVoltages + data[i].powerSupplyInputs[j].inputType + ",";
              const ind = this.voltageList.findIndex(v => v.sequence == data[i].powerSupplyInputs[j].inputVoltage);
              data[i].powerSupplyInputs[j].inputType = this.voltageList[ind].name
              powerSupplyCurveArray.push(data[i].powerSupplyInputs[j]);
            }
          }
          data[i].powerSupply["voltages"] = powerSupplyVoltages;
          data[i].powerSupply["voltagesCurve"] = powerSupplyCurveArray;
          powerSupplyVoltages = "";
          powerSupplyCurveArray = [];
        }



        this.rowData = data[0].powerSupply["voltagesCurve"];

        // Code ends
        //this.rowData = data[0].powerSupplyInputs; 

        //????
        this.powerSupplyData.powerSupplyInputs = data[0].powerSupplyInputs;

        this.getVoltageList();

      })
    );
  }

  onDeleteButtonClick(params: any) {

    //this.gridOptions.api?.updateRowData({ remove: [params.node.data.Id] });     
  }

  openDialog(): void {
    this.form = "newCurvePoint";
    this.getVoltageList();
  }

  config = {
    displayKey: "viewValue",
    search: false,
    height: '200px',
    placeholder: 'Select Option',
    moreText: 'more',
    noResultsFound: 'No results found!',
    searchPlaceholder: 'Search',
    searchOnKey: 'viewValue',
    clearOnSelection: false,
    inputDirection: 'ltr'
  }
  dropdownSettings = {
    singleSelection: false,
    text: "Select Option",
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    enableSearchFilter: true,
    classes: "myclass custom-class",
    badgeShowLimit: 1,
    maxHeight: 200,
    enableCheckAll: false,
    enableFilterSelectAll: false,
    labelKey: "viewValue"
  };
  OnItemDeSelect(item: any) {

  }

  onItemSelect(item: any) {

  }
  ngOnDestroy() {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }
}
