<div class="modal__header">
	<div class="modal__title"></div>
	<p  >{{'PROJECT_NAME'| translate}}</p>
</div>

<div class="modal__body form-group__text" >	
	<input 
	id="input-state-default" 
	class="input-project-name" 
	(input)="matchNames()" 
	[(ngModel)]="projectName" 
	type="text"
	[title]="projectName == '' ? 'Project Name Empty!' : doesMatchExist ? 'Project with same name exists! &#10;Please change name' : ''"
	>
</div>
<div class="modal__footer">
	<button class="btn" (click)="onConfimation()" [ngClass]="(projectName !== '' && doesMatchExist == false) ? 'active': 'disabled'">{{'OK' | translate}}</button>
	<button class="btn" (click)="closeModal()">{{'CANCEL' | translate}}</button>
</div>