
<div class="help">
<div class="row base-margin-bottom">
    <div class="col-md-12 col-lg-12 col-xl-12">
        <div class="panel">
            <p class="just">Delivering performance, versatility and density without compromise, the Cisco UCS range addresses the broadest set of workloads, from IT and web infrastructure to distributed database. <br/>
            The UCS series blade server chassis is a crucial building block of the Cisco unified computing system, delivering a scalable and flexible architecture for current and future data center needs, while helping reduce cost of ownership.
            </p>
    </div>
    </div>
</div>
<div class="row base-margin-bottom">
    <div class="col-md-5 col-lg-5 col-xl-5 base-margin-bottom">
        <div class="panel ul">
           <h5 class="help-title"><b>How to Videos</b></h5>
        </div>
    </div>
    <div class="col-2"></div>
    <div class="col-md-5 col-lg-5 col-xl-5 base-margin-bottom">
        <div class="panel ul">
            <h5 class="help-title"><b>Release Notes</b></h5>
        </div>
    </div>
</div>
<div class="row base-margin-bottom">
    <div class="col-md-5 col-lg-5 col-xl-5 base-margin-bottom">
        <div class="panel">
           <p class="just">Coming Soon...</p>
           <!-- <p class="just">Need help with configuring a server? The <a routerLink="howToVideos">how to videos</a> section includes a series of videos on help with configuring any server included in the UCS power calculator tool.</p> -->
        </div>
    </div>
    <div class="col-2"></div>
    <div class="col-md-5 col-lg-5 col-xl-5 base-margin-bottom">
        <div class="panel">
            <p class="just">Visit the <a routerLink="releaseNotes">release notes</a> section to view the release information of the tool.</p>
        </div>
    </div>
</div>
<div class="row base-margin-bottom">
    <div class="col-md-12 col-lg-12 col-xl-12 base-margin-bottom">
        <div class="panel ul">
           <h5 class="help-title"><b>TME Quote</b></h5>
        </div>
    </div>
</div>
<div class="row base-margin-bottom">
    <div class="col-md-12 col-lg-12 col-xl-12 base-margin-bottom">
        <div class="panel">
            <p class="just">
                Server power consumption is a function of hardware configuration, BIOS settings and workload. 
                There is no industry standard governing power calculators. 
                Therefore, power calculators are unreliable when used to compare power utilization across products from different hardware vendors. 
                I recommend caution when making comparisons between vendor tools. 
                Especially when some vendors choose to use their power calculator tool as a marketing instrument rather than an engineering tool to safely size electrical circuits.

            </p>
            <p class="tme"> — Jeffrey Metcalf, Technical Marketing Engineer - Cisco UCS</p>
        </div>
    </div> 
</div>
</div>