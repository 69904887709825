<div mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    <div style="margin-bottom: 8px;"><h4 style="float: left;font-size: large;font-weight: 500;">
        Show Columns</h4> 
      <div style="display:flex;
      flex-direction: row-reverse;">
      <div class="flex-center margin_auto_t_b" >
        <a class="anchor-close" id="close" title="Close" (click)="onCancel()">
        <span class="icon-close icon-size-24 icon-close"></span>
        </a>
      </div>    
        </div>
    </div>
</div>
<mat-dialog-content style="min-width: 350px;"> 
    <div class="responsive-table">
        <table class="table table--lined" aria-label="Lined table example">
            <thead>
                <tr>
                    <th>
                        <label class="checkbox">
                            <input type="checkbox"[(ngModel)]="checkboxSelectAll" (click)="checkAll($event)" />
                            <span class="checkbox__input"></span>
                        </label>
                    </th>
                    <th>Header Name</th>
                    
                </tr>
            </thead>
            <tbody>
                <ng-container *ngFor="let r of fields  ; let i = index">
                <tr >
                    <td>
                        <label class="checkbox">
                            <input type="checkbox" [checked]="r.visible" [(ngModel)]="r.visible" (click)="this.checkALLBox();" />
                            <span class="checkbox__input"></span>
                        </label>
                    </td>
                    <td style="max-width: 300px;">{{r.name}}</td>
                    
                </tr>
            </ng-container>
            </tbody>
        </table>
    </div>
</mat-dialog-content>    
<mat-dialog-actions>
    <button mat-button  (click)="apply()" [disabled] = "false" class="btn btn--small " style="height: 24px;margin: 5px 20px 0 0;">Apply</button>

</mat-dialog-actions>
