<!--<input 
      type="checkbox"  class="hover"
      (click)="checkedHandler($event)"
      [checked]="params.value"
/> -->

<div class="col-lg-2 col-md-4 col-6">
      
      <label class="checkbox" *ngIf="label !=='deleteSelected'">
          <input type="checkbox" class="hover" (click)="checkedHandler($event)"
          [checked]="params.value">
          <span class="checkbox__input"></span>
      </label>
  </div>

  <div class="col-lg-2 col-md-4 col-6" *ngIf="label =='deleteSelected'" >
      
    <label class="checkbox">
        <input type="checkbox" class="hover" (click)="deleteSelected($event)" [disabled]="params?.data?.deleteFlag == false"
        [checked]="params.value"  [ngClass]="(params?.data?.deleteFlag == false) ? 'disable-icon' : ''">
        <span class="checkbox__input"></span>
    </label>
</div>