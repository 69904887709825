import { Component, OnInit, Inject, HostListener, ChangeDetectorRef } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormControl, UntypedFormBuilder, UntypedFormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { Subscription } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ComponentConstant } from 'src/constants/components.constants';
import { ApiService } from 'src/app/services/api.service';
import { UrlConstant } from 'src/constants/url.constants';
import { ToasterService } from "src/app/shared/components/toast/toast.service";
import { errorCodes } from 'src/constants/errorCodes';
import { CngContentConfigType, CngModalService, CngModalSize } from '@cisco/cui-ng';
import { ConfirmationBoxComponent } from 'src/app/shared/components/confirmation-box/confirmation-box.component';
import { TranslateService } from '@ngx-translate/core';
import { AdminService } from 'src/app/services/admin.service';
import { AdditionalAttributesComponent } from 'src/app/shared/components/additional-attributes/additional-attributes.component';
import { PlatformConstant } from 'src/constants/platform.constants';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { AppConstant } from 'src/constants/app.constants';

export interface DialogData {

  displayName: string;
  pid: string;
  mpn: string;
  idleWatts: string;
  maxWatts: string;
  mtbf: string;
  capacity: string;
  selectedStorageDeviceType: string;
  storageDeviceType: string;
  storageZoneType: string;
  storageDeviceNameToSave: string;
  type: string;
  id: string;
  update: boolean;
  released: string;
  storageType: string;

}
interface StorageDeviceType {
  value: string;
  viewValue: string;
}
interface StorageZoneType {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-create-storagecomponent',
  templateUrl: './create-storagecomponent.component.html',
  styleUrls: ['./create-storagecomponent.component.css']
})
export class CreateStoragecomponentComponent implements OnInit {
  subscriptions: Subscription = new Subscription();
  constructor(public dialogRef: MatDialogRef<CreateStoragecomponentComponent>,
    @Inject(MAT_DIALOG_DATA) public storageData: DialogData,
    private apiService: ApiService,
    private toaster: ToasterService,
    private formBuilder: UntypedFormBuilder,
    private modalService: CngModalService,
    private translate: TranslateService,
    private adminService: AdminService,
    public dialog: MatDialog,
    private cdr: ChangeDetectorRef) {
    this.validations = this.formBuilder.group({
      displayName: new UntypedFormControl('', [
        Validators.required, Validators.pattern(AppConstant.VALID_INPUT_REGEX)]),

      mpn: new UntypedFormControl(),
      storageDeviceType: new UntypedFormControl('', [
        Validators.required]),
      storageZoneType: new UntypedFormControl('', []),
      idleWatts: new UntypedFormControl('', [
        Validators.required,
        Validators.pattern(AppConstant.VALID_INPUT_REGEX),
        Validators.required, Validators.min(0.1),
      ]),
      maxWatts: new UntypedFormControl('', [
        Validators.required,
        Validators.pattern(AppConstant.VALID_INPUT_REGEX),
        Validators.required, Validators.min(0.1)
      ]),
      mtbf: new UntypedFormControl('',
        //[Validators.pattern("^\\d*\\.?\\d+$")]
      ),
      capacity: new UntypedFormControl('', [
        Validators.required,
        Validators.pattern('\\d+'),
        Validators.pattern(AppConstant.VALID_INPUT_REGEX),
        Validators.required, Validators.min(1)])
    })
    //this.validations.controls['storageDeviceType'].setValue(this.default, {onlySelf: true});

  }



  @BlockUI() blockUI: NgBlockUI;

  default: string = '--select--';
  validations: UntypedFormGroup;
  selectedStorageDeviceType: any;
  selectedStorageZoneType: any;
  lblMandatoryMsg = "";
  lblSuccessMsg = "";
  dialogConfig;
  errorMsg = '';
  lblErrMessage;
  lblMaxWattErrMessage;

  type;
  released;
  update: boolean = false;

  storageDeviceTypes: StorageDeviceType[] = [
    { value: 'SSD', viewValue: 'SSD' },
    { value: 'HDD', viewValue: 'HDD' }
  ];

  storageZoneTypes: StorageZoneType[] = [
    { value: 'FRONT', viewValue: 'FRONT' },
    { value: 'REAR', viewValue: 'REAR' },
    { value: 'INTERNAL', viewValue: 'INTERNAL' },
  ];
  saveCloseFlag: boolean = true;
  onNoClick(): void {
    this.type = this.storageData.type;
    this.update = this.storageData.update;
    //this.dialogRef.close('cancel');
    this.saveCloseFlag = false;
    this.dataToSave();
  }
  //Number.prototype.noExponents= function(){
  noExponents() {
    var n = 4.545756867888889E39;
    var data = String(this).split(/[eE]/);
    if (data.length == 1) return data[0];

    var z = '', sign = n < 0 ? '-' : '',
      str = data[0].replace('.', ''),
      mag = Number(data[1]) + 1;

    if (mag < 0) {
      z = sign + '0.';
      while (mag++) z += '0';
      return z + str.replace(/^\-/, '');
    }
    mag -= str.length;
    while (mag--) z += '0';
    //return str + z;

  }

  showPID = true;
  ngOnInit(): void {
    this.showPID = true;
    this.lblErrMessage = "";
    this.lblMaxWattErrMessage = "";
    if (this.storageData.update) {
      // this.selectedStorageDeviceType = this.storageData.storageType;  
      this.selectedStorageDeviceType = this.storageDeviceTypes.find(row => this.storageData.storageType == row.value);

      //for pid search
      this.selectedPid = this.storageData.pid;

      //addional fields
      this.additionalFields = this.storageData["additionalFields"] ? this.storageData["additionalFields"] : [];



      this.selectedStorageZoneType = this.storageData.storageZoneType;
      var splitted = this.selectedStorageZoneType.split(",");
      for (let i = 0; i < splitted.length; i++) {

        switch (splitted[i]) {
          case "FRONT": {
            this.frontChecked = true;
            break;
          }
          case "REAR": {
            this.rearChecked = true;
            break;
          }
          case "INTERNAL": {
            this.internalChecked = true;
            break;
          }
        }
      }
      this.checkZoneSelected();

    }

    this.cdr.detectChanges();

    this.dialogRef.disableClose = true;

  }
  refreshData: boolean = false;
  public onCancel = () => {

    this.dialogRef.close({
      data: {
        action: this.refreshData ? PlatformConstant.ACTION_REFRESH : PlatformConstant.ACTION_CANCEL
      }
    });
  }

  dataToSave() {

    this.lblErrMessage = "";
    this.lblMaxWattErrMessage = "";
    if (this.validations.valid && this.lblErrMessage == "" && this.lblMaxWattErrMessage == "") {
      let storageDeviceNameToSave = this.validations.get('storageDeviceType').value;
      this.lblErrMessage = "";
      this.lblMaxWattErrMessage = "";
      let data = {
        "type": this.storageData.type,
        "role": "Admin",
        "displayName": this.validations.value.displayName,
        "mpn": this.validations.value.mpn,
        // "pid" : this.validations.value.pid,
        "pid": this.selectedPid,

        "storageType": this.validations.get('storageDeviceType').value.value,
        "storageZoneType": this.setStorageZoneType(),
        "idleWatts": this.validations.value.idleWatts,
        "maxWatts": this.validations.value.maxWatts,
        "capacity": this.validations.value.capacity,
        "mtbf": this.validations.value.mtbf,
        "released": "",
        "additionalFields": this.setAdditionalFields(),
        "allowDuplicates": false
      };
      if (!this.storageData.update) {

        let result = this.createStorageDevice(data);
      } else {
        data.released = this.storageData.released;
        data["id"] = this.storageData.id;
        let result = this.updateStorageDevice(data);
      }

    } else {

    }
  }
  setAdditionalFields() {
    let array = [];
    if (this.additionalFields?.length > 0) {
      this.additionalFields.forEach(element => {
        let row = {
          "name": element.name,
          "type": element.type,
          "value": element.value
        }
        array.push(row);
      });
    }
    else
      array = [];

    return array;
  }

  resetForm() {
    this.showPID = false;

    this.validations.reset();
    this.saveCloseFlag = true;
    this.additionalFields = [];
    this.frontChecked = false;
    this.rearChecked = false;
    this.internalChecked = false;
    this.checkZoneSelected();
    //search pid
    this.selectedPid = null;
    this.showPID = true;
  }

  onSave() {
    this.dataToSave();
  }

  frontChecked = false;
  rearChecked = false;
  internalChecked = false;
  zoneCkeckboxSelected = false;
  checkZoneSelected() {
    if (this.frontChecked || this.rearChecked || this.internalChecked) {
      this.zoneCkeckboxSelected = true;
    }
    else {
      this.zoneCkeckboxSelected = false;
    }
  }
  checkedHandler(e, type) {

    if (type == 'FRONT') { this.frontChecked = e.target.checked; }
    else if (type == 'REAR') { this.rearChecked = e.target.checked; }
    else if (type == 'INTERNAL') { this.internalChecked = e.target.checked; }

    this.checkZoneSelected();
  }
  setStorageZoneType() {
    let zones = '';
    if (this.frontChecked) {
      zones = zones + 'FRONT' + ',';
    }
    if (this.rearChecked) {
      zones = zones + 'REAR' + ',';
    }
    if (this.internalChecked) {
      zones = zones + 'INTERNAL';
    }

    if (zones && zones.charAt(zones.length - 1) == ',') {

      zones = zones.substring(0, zones.length - 1);
    }

    return zones;
  }

  createStorageDevice(reqData: any) {

    this.blockUI.start('Loading...');
    let params = [];
    params.push(reqData);
    params = params.concat(this.applyToRecords);
    params = params.concat(this.updatedRecords);


    const url = UrlConstant.MANAGECOMPONENTS;
    this.subscriptions.add(
      this.apiService.postMethod(url, params).subscribe((data: any) => {
        this.blockUI.stop();
        this.refreshData = true;

        this.lblMandatoryMsg = "";

        if (data.modifiedCount > 0) {
          this.toaster.show('success', errorCodes.NEW_RECORD, '', 5000);
          if (this.saveCloseFlag) {
            window.scroll(0, 0);
            this.dialogRef.close({
              data: {
                action: PlatformConstant.ACTION_REFRESH
              }
            });
          } else {
            this.resetForm();
          }

        } else if (data.modifiedCount == 0 && data.error.errorString == "Duplicate Data Found") {
          reqData["allowDuplicates"] = true;
          this.blockUI.start("Loading...");
          this.subscriptions.add(
            this.apiService.postMethod(url, params).subscribe((data: any) => {
              this.blockUI.stop();
              if (data.modifiedCount > 0) {
                this.toaster.show('success', errorCodes.NEW_RECORD, '', 5000);
                if (this.saveCloseFlag) {
                  window.scroll(0, 0);
                  this.dialogRef.close({
                    data: {
                      action: PlatformConstant.ACTION_REFRESH
                    }
                  });
                  this.openModal(reqData);
                } else {
                  this.resetForm();
                }
              }
            }, (error) => {
              this.handleError(error);
            }))
        }
      }, error => {
        this.handleError(error);
      })
    );
  }

  updateStorageDevice(reqData: any) {
    this.blockUI.start('Loading...');

    let params = [];
    params.push(reqData);
    params = params.concat(this.applyToRecords);
    params = params.concat(this.updatedRecords);


    const url = UrlConstant.MANAGECOMPONENTS + UrlConstant.UPDATE;
    this.subscriptions.add(
      this.apiService.postMethod(url, params).subscribe((data: any) => {
        this.blockUI.stop();
        this.refreshData = true;

        this.lblMandatoryMsg = "";

        if (data.modifiedCount > 0 && data.successMessage == "Successfully updated") {

          this.toaster.show('success', errorCodes.UPDATE_RECORD, '', 5000);
          if (this.saveCloseFlag) {
            window.scroll(0, 0);
            this.dialogRef.close({
              data: {
                action: PlatformConstant.ACTION_REFRESH
              }
            });
          } else {
            this.saveCloseFlag = true;
          }
        } else if (data.modifiedCount == 0 && data.error.errorString == "Duplicate Data Found") {

          reqData["allowDuplicates"] = true;
          this.blockUI.start("Loading...");
          this.subscriptions.add(
            this.apiService.postMethod(url, params).subscribe((data: any) => {
              this.blockUI.stop();
              if (data.modifiedCount > 0) {
                this.toaster.show('success', errorCodes.UPDATE_RECORD, '', 5000);
                if (this.saveCloseFlag) {
                  window.scroll(0, 0);
                  this.dialogRef.close({
                    data: {
                      action: PlatformConstant.ACTION_REFRESH
                    }
                  });
                  this.openModal(reqData);
                } else {
                  this.saveCloseFlag = true;
                }
              }
            }, (error) => {
              this.handleError(error);
            }))
        }
      }, error => {
        this.handleError(error);
      })
    );

  }
  handleError(error) {
    this.errorMsg = error?.error ? error.error?.error?.errorString : errorCodes.GENERIC_ERROR_MESSAGE;
    this.toaster.show('error', this.errorMsg, error ? error?.message : '', 5000);
    this.blockUI.stop();
  }
  showWattErr: boolean = false;
  IdleMaxWattsErr: boolean = false;
  IdleWattsErr: boolean = false;
  mtbfErr: boolean = false;
  capacityErr: boolean = false;
  validateWatts(e: any) {
    this.showWattErr = false;
    this.IdleMaxWattsErr = false;
    this.IdleWattsErr = false;
    this.mtbfErr = false;
    this.capacityErr = false;
    if (this.validations.value.idleWatts && this.validations.value.maxWatts
      && !isNaN(this.validations.value.idleWatts) && !isNaN(this.validations.value.maxWatts)) {
      this.showWattErr = Number(this.validations.value.maxWatts) >= Number(this.validations.value.idleWatts)
        ? false : true;



      this.cdr.detectChanges();

    }
    if (this.validations.value.idleWatts && (isNaN(this.validations.value.idleWatts) || parseFloat(this.validations.value.idleWatts) <= 0)) {
      this.IdleWattsErr = true;
      this.cdr.detectChanges();
    }
    if (this.validations.value.maxWatts && (isNaN(this.validations.value.maxWatts) || parseFloat(this.validations.value.maxWatts) <= 0)) {
      this.IdleMaxWattsErr = true;
      this.cdr.detectChanges();
    }
    if (this.validations.value.mtbf && (isNaN(this.validations.value.mtbf) || Number(this.validations.value.mtbf) < 0)) {
      this.mtbfErr = true;
      this.cdr.detectChanges();
    }
    if (this.validations.value.capacity && (isNaN(this.validations.value.capacity))) {
      this.capacityErr = true;
      this.cdr.detectChanges();
    }
  }

  public async openModal(data: any) {
    const result = await this.modalService
      .open({
        content: {
          type: CngContentConfigType.COMPONENT,
          content: ConfirmationBoxComponent,
          componentData: {
            someText: this.translate.instant(
              "DUPLICATE_DATA_FOUND"
            ),
            key: "delete",
          },
        },
        size: CngModalSize.SMALL,
      })
      .onDismiss.toPromise();

    if (result == 'ok')
      this.getDuplicateData(data);
  }
  getDuplicateData(data: any) {
    this.subscriptions.add(this.adminService.setStorageData(data));
  }

  //addional fields
  additionalFields: any = [];
  applyToRecords: any = [];
  updatedRecords: any = [];
  openAdditionalAtt_Modal() {


    const dialogRef = this.dialog.open(AdditionalAttributesComponent, {
      data: {
        params: this.storageData,
        additionalFields: this.additionalFields,
        p_type: ComponentConstant.COMPONENT,
        type: ComponentConstant.STORAGES,
        edited: this.isAdditionFieldsEdited
      },
      disableClose: true,
      panelClass: 'width-dialog-100',
    });

    dialogRef.afterClosed().subscribe(result => {


      if (result.data.action !== PlatformConstant.ACTION_CANCEL) {
        this.additionalFields = result.data.addedFields;
        this.applyToRecords = result.data.additionalRecords;
        this.isAdditionFieldsEdited = result.data.edited;
        //
        this.updatedRecords = [];
        this.additionalFields.forEach(element => {
          if (element.flag == "UpdateField" && element.hasOwnProperty("selectedItems")
            && element.selectedItems?.length > 0) {
            element.selectedItems.forEach(element2 => {

              if (element2.rowId !== this.storageData.id && element2.flag !== "NewField") {
                let ele = this.updatedRecords.filter(value => { return value.id == element2.rowId });

                if (ele?.length == 0) {

                  this.updatedRecords.push({
                    "type": ComponentConstant.STORAGES,
                    "role": "Admin",
                    "id": element2.rowId,
                    "additionalFields": [{
                      name: element.name,
                      type: element.type,
                      value: element.value,
                      flag: element.flag,
                      previousName: element.previousName
                    }]
                  })
                }
                //
                else {

                  ele[0].additionalFields.push({
                    name: element.name,
                    type: element.type,
                    value: element.value,
                    flag: element.flag,
                    previousName: element.previousName
                  })
                }
              }
              //
            });

          }
        });
      }

    });
  }
  // end-of-addional-fields
  isAdditionFieldsEdited: boolean = false;

  //search pid
  selectedPid: any = null;
  selectedPID(e: any) {

    this.selectedPid = e;
  }

}
