<div class="series-container">
  <div class="row available-row">
    <div class="col-md-12" *ngIf="secondaryClasses.length > 0">
      <span class="btn-group span-BtnAvailable">
        <button class="btn btn--ghost btn-available" [ngClass]="available ? 'selected' : ''"
          (click)="onAvailableClick()">
          {{'AVAILABLE' | translate}}
        </button>
        <button class="btn btn--ghost btn-discontinue" (click)="onDiscontinuedClick()"
          [ngClass]="available ? '' : 'selected'">
          {{'DISCONTINUED' | translate}}
        </button>
      </span>
    </div>
  </div>
  <div class="row">
    <div class="accordian-row">
      <ul class="accordion accordian-series">
        <li *ngFor="let series of secondaryClassesUnique; let i = index"
          [ngClass]="selectedSeriesId == i ? 'active' : ''">
          <div>
            <a class="accordion__title accor-title" (click)="onSeriesSelect(i)" id="series-{{i}}">
              <span class="accordion__toggle icon-chevron-down" style="margin-top: -7px;"></span>
              <h5>{{series?.displayName | translate }} </h5>
              <span (click)="refreshSeriesData(i);$event.stopPropagation()" class="icon-refresh icon-size-15"
                title="Refresh Data"></span>
            </a>
          </div>
          <div class="accordion__content">
            <div class="row" style="border-bottom: 1px solid #adb5bd">

              <div class="col-md-12" *ngIf="currentTemplates?.templates?.length > 0 && doesTemplatesExistForAvailableStatus()">
                <p style="padding-left: 20px; margin-bottom: 0px;font-weight: 500;color:#1e4471;font-size: medium;">
                  {{'TEMPLATES' | translate}}</p>
                <app-card [seriesValues]="currentTemplates.templates" [available]="available"
                  [template]="true"></app-card>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <app-card [seriesValues]="series.seriesValues" [available]="available"></app-card>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
  <div *ngIf="secondaryClasses.length == 0">{{'NO_SERVERS' | translate}}

  </div>
</div>