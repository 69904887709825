<div>
<a class="modal__close" (click)="closeModal()"><span class="icon-close"></span></a>
<div class="modal__header">
	<div class="modal__title">
        {{ data?.titleMessage | translate }}
    </div>
</div>
<div class="modal__body">
	<p class="subtitle"  ></p>
	<!-- <p *ngIf="data?.someText" [innerText]="data?.someText"></p> -->
    <div class="row">
        <div class="col-5">
            <h6> {{ data?.message | translate }} </h6>
        </div>
        <!-- <div class="col-1"></div> -->
        <div class="col-7" style="display: flex;flex-direction:row">
            <input id="newCompName" style="flex:1" [(ngModel)]="newCompName" type="text" 
            (change)="onChangeNewCompName($event)" (keyup)="onKeyPress($event)"
            [ngClass]="isAddEnabled?'select-normal':'select-danger'"
            >
        </div>
    </div>
</div>
<div class="modal__footer">
	<button class="btn" (click)="onAddComponent()" [disabled]="!isAddEnabled">{{'Add' | translate}}</button>
	<button class="btn" (click)="closeModal()">{{'CANCEL' | translate}}</button>
</div>
</div>