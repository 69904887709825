import { Component, OnInit ,Inject,HostListener ,ChangeDetectorRef, ComponentFactoryResolver} from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';
import { UrlConstant } from 'src/constants/url.constants';
import { GridOptions } from "ag-grid-community";
import { PlatformConstant } from 'src/constants/platform.constants';
import * as moment from 'moment';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ToasterService } from "src/app/shared/components/toast/toast.service";
import { errorCodes } from "src/constants/errorCodes";
import { ComponentConstant } from 'src/constants/components.constants';
import { EditRenderComponent } from 'src/app/shared/components/edit-render/edit-render.component';
import { CheckboxRenderComponent } from 'src/app/shared/components/checkbox-render/checkbox-render.component';

@Component({
  selector: 'app-associated-platforms',
  templateUrl: './associated-platforms.component.html',
  styleUrls: ['./associated-platforms.component.css']
})
export class AssociatedPlatformsComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<AssociatedPlatformsComponent>,  
    @Inject(MAT_DIALOG_DATA) public params : any,
    private apiService: ApiService,
    private cdr: ChangeDetectorRef,
    private toaster: ToasterService, 
    public dialog: MatDialog,
    ) { }
  
  displayData:any={
    name : null,
    pid :null
  }  
  isFanPolicy: boolean = false;
  ngOnInit(): void {
    
    if(this.params.params.type == "powerSupply"){
     this.displayData.name = this.params?.params?.powerSupply.displayName;
     this.displayData.pid = this.params?.params?.powerSupply.pid;

    } else if (this.params.params.policy && this.params.params.pid) {
      /** fan policy */
      this.isFanPolicy = true;
      this.displayData.pid = this.params?.params?.pid;
    } else {
      this.displayData.name = this.params?.params?.displayName;
      this.displayData.pid = this.params?.params?.pid;
    }

    this.getAssociatedPlatformsList();
  }
  @BlockUI() blockUI: NgBlockUI; 
  subscriptions: Subscription = new Subscription();
  dataRefreshed:boolean = false;
  showLoader:any = "show";
  rowData:any = [];
  // domLayout= 'autoHeight';
  frameworkComponents = {
    buttonEditRenderer: EditRenderComponent,
    checkboxRenderer: CheckboxRenderComponent

  };

  gridOptions : GridOptions = {
    columnDefs: [
      { headerName: 'Name' , field:'name',
      cellRenderer: (data) => {
        return data.value ? data.value : data.data.displayName;
      }},
      { headerName: 'PID' , field:'basePid'},
      { headerName: 'Type' , field:'type'},
      {
        headerName: "",
        minWidth: 50,
        maxWidth: 60,
        sortable: false,
        filter: false,
        cellRenderer: "buttonEditRenderer",
        cellRendererParams: {
          onClick: this.onPlatformsButtonClick.bind(this),
          label: "deleteAssociatedPlatform",
          componentData : this.params
        },
  
      },

    ],
    defaultColDef: {
      sortable: true,
      flex: 1,
      minWidth: 120,
      filter: true,
      resizable: true,
      editable: false,
      headerComponentParams: {
        template:
            '<div class="ag-cell-label-container" role="presentation">' +
            '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
            '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
            '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
            '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
            '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
            '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
            '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
            '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
            '  </div>' +
            '</div>',
    },
    }
  }
  closeModal(){
    this.dialogRef.close({
      data: {
        action : this.dataRefreshed?PlatformConstant.ACTION_REFRESH:PlatformConstant.ACTION_CANCEL
        } 
    });
  }
  gridApi: any;
  gridColumnApi:any;
  headerHeightSetter(event: any) {
    var padding = 20;
    var height = this.headerHeightGetter() + padding;
    this.gridApi.setHeaderHeight(height);
    this.gridApi.resetRowHeights();
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi; 
    //params.api.sizeColumnsToFit();
  }
  headerHeightGetter() {
    var columnHeaderTexts = document.querySelectorAll('.ag-header-cell-text');
  
    var columnHeaderTextsArray = [];
  
    columnHeaderTexts.forEach(node => columnHeaderTextsArray.push(node));
  
    var clientHeights = columnHeaderTextsArray.map(
      headerText => headerText.clientHeight
    );
    var tallestHeaderTextHeight = Math.max(...clientHeights);
    return tallestHeaderTextHeight;
  }

  getAssociatedPlatformsList(){
  // 
  this.blockUI.start('Loading...');
  const params = {
    sequence: this.params?.params?.sequence,
    type: this.params.params.type == "powerSupply" ? "powerSupplies" : this.params?.params?.type,
    dynamicComponent :this.params?.dynamicComponent,
    pid: null,
  }  
  if (this.isFanPolicy) {
    params.type = "fanPolicy",
    params.pid = this.params.params.pid,
    params.sequence = 0;
  }                                                   

  const url = UrlConstant.GET_ASSOCIATED_PLATFORMS+"/?sequence="+ params.sequence +
               "&type="+params.type+"&pid="+params.pid+"&dynamicComponent="+params.dynamicComponent;
  
    this.subscriptions.add(this.apiService.getMethod(url).subscribe((data: any) => {
      this.blockUI.stop();
      
      this.rowData = data;

    },error =>{  
      this.blockUI.stop();
      let errorMsg = error?.error ? error.error?.error?.errorString : "Oops something went wrong!!";
      this.toaster.show('error', errorMsg , error ? error?.message : '',5000);
    }));
  // 


  }

  onPlatformsButtonClick(params: any) {
    this.getAssociatedPlatformsList();
  }
}
