import { ChangeDetectorRef, Component, Inject, OnInit } from "@angular/core";
import { UntypedFormBuilder } from "@angular/forms";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import {
  CngContentConfigType,
  CngModalService,
  CngModalSize,
} from "@cisco/cui-ng";
import { TranslateService } from "@ngx-translate/core";
import { BlockUI, NgBlockUI } from "ng-block-ui";
import { Subscription } from "rxjs";
import { ApiService } from "src/app/services/api.service";
import { ConfirmationBoxComponent } from "src/app/shared/components/confirmation-box/confirmation-box.component";
import { ToasterService } from "src/app/shared/components/toast/toast.service";
import { PlatformConstant } from "src/constants/platform.constants";
import { UrlConstant } from "src/constants/url.constants";
import { MeasurementDetailsComponent } from "./measurement-details/measurement-details.component";

@Component({
  selector: "app-add-measurement",
  templateUrl: "./add-measurement.component.html",
  styleUrls: ["./add-measurement.component.css"],
})
export class AddMeasurementComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<AddMeasurementComponent>,
    @Inject(MAT_DIALOG_DATA) public platformData: any,
    private apiService: ApiService,
    private toaster: ToasterService,
    private formBuilder: UntypedFormBuilder,
    private cdr: ChangeDetectorRef,
    public dialog: MatDialog,
    private translate: TranslateService,
    private modalService: CngModalService
  ) {}
  subscriptions: Subscription = new Subscription();
  @BlockUI() blockUI: NgBlockUI;

  ngOnInit(): void {
    
    this.getCPUs();
    this.getIncompatableDimms();
  }

  cpulist = [];
  selectedCpu: any = null;
  selectedDimm: any = null;

  /** clone options */
  cloneOptions: string[] = ["Processors", "Dimms"];
  selectedCloneOption: any = null;
  showCloneOption: boolean = false;

  dimmChildList: any[] = [];
  selectedChildDimm: any = null;

  getCPUs() {
    this.blockUI.start("Loading...");
    this.cpulist = [];
    const params = {
      cpuIds: this.platformData.params.cpus
        ? this.platformData.params.cpus
        : [],
    };
    const url = UrlConstant.MANAGEMENTS_CPU;
    this.subscriptions.add(
      this.apiService.postMethod(url, params).subscribe(
        (data: any) => {
          
          this.cpulist = data;
          this.blockUI.stop();
        },
        (error) => {
          this.blockUI.stop();
          let errorMsg = error?.error
            ? error.error?.error?.errorString
            : "Oops something went wrong!!";
          this.toaster.show("error", errorMsg, "", 5000);
        }
      )
    );
  }

  dimmList = [];
  getDimms(cpu) {
    
    this.blockUI.start("Loading...");
    this.dimmList = [];
    const params = {
      cpuIds: [cpu.value.sequence],
      dimIds: this.platformData.params.dimms
        ? this.platformData.params.dimms
        : [],
      serverSeqNo: this.platformData.params.sequence,
      statusOfDims: "valid",
    };

    const url = UrlConstant.MANAGEMENTS_DIMMS;
    this.subscriptions.add(
      this.apiService.postMethod(url, params).subscribe(
        (data: any) => {
          
          this.dimmList = data;
          if (this.dimmList?.length == 0) {
            this.toaster.show("error", "No valid Dimms available", "", 5000);
          }
          this.blockUI.stop();
        },
        (error) => {
          this.blockUI.stop();
          let errorMsg = error?.error
            ? error.error?.error?.errorString
            : "Oops something went wrong!!";
          this.toaster.show(
            "error",
            errorMsg,
            error ? error?.message : "",
            5000
          );
        }
      )
    );
  }

  incompatDimmsList: any = null;
  getIncompatableDimms() {
    const url =
      UrlConstant.GET_PLATFORM_DIMMS_INCOMPAT +
      "/?serverSeqNo=" +
      this.platformData.params.sequence;
    
    this.subscriptions.add(
      this.apiService.getMethod(url).subscribe(
        (data: any) => {
          
          this.incompatDimmsList = data["InvalidDimms"];
          
        },
        (error) => {
          let errorMsg = error?.error
            ? error.error?.error?.errorString
            : "Oops something went wrong!!";
          this.toaster.show(
            "error",
            errorMsg,
            error ? error?.message : "",
            5000
          );
        }
      )
    );
  }

  ManagementDetailsList: any = [];
  showLoader = null;

  getManagementDetails() {
    
    this.showLoader = "show";
    this.blockUI.start("Loading...");

    const params = {
      cpuIds: [this.selectedCpu.sequence],
      dimIds: [this.selectedDimm.sequence],
      serverSeqNo: this.platformData.params.sequence,
      statusOfDims: "valid",
    };

    const url = UrlConstant.MANAGEMENTS_DETAILS;
    this.subscriptions.add(
      this.apiService.postMethod(url, params).subscribe(
        (data: any) => {
          
          this.ManagementDetailsList = data;
          this.showLoader = "hide";
          this.setCloneFields();
          this.blockUI.stop();
        },
        (error) => {
          this.blockUI.stop();
          let errorMsg = error?.error
            ? error.error?.error?.errorString
            : "Oops something went wrong!!";
          this.toaster.show(
            "error",
            errorMsg,
            error ? error?.message : "",
            5000
          );
        }
      )
    );
  }

  changeType(event: any, type: any) {
    
    this.showLoader = null;
    this.childCpu = null;
    this.childCpuList = [];
    this.showCloneFields = false;

    this.dimmChildList = [];
    this.selectedChildDimm = null;

    if (type == "cpus") {
      if (event.value?.sequence) {
        this.getDimms(event);
      }
      this.dimmList = [];
      this.selectedDimm = null;
    } else if (type == "dimms") {
      if (event.value?.sequence) {
        this.getManagementDetails();
        this.dimmChildList = this.dimmList.filter(
          (dimm) => dimm.sequence !== this.selectedDimm.sequence
        );
      }
    }
  }
  selectCloneOption() {
    this.childCpu = null;
    this.selectedChildDimm = null; 
    this.enableClonebutton = false;
  }
  openMeasurementDetails(selectedRow: any) {
    
    
    
    const dialogRef = this.dialog.open(MeasurementDetailsComponent, {
      data: {
        platformData: this.platformData.params,
        selectedRow: selectedRow,
        selectedDimm: this.selectedDimm,
        selectedCpu: this.selectedCpu,
      },
      disableClose: false,
      panelClass: "width-dialog-100",
    });
    dialogRef.afterClosed().subscribe((result) => {
      
      if (result.data.action == PlatformConstant.ACTION_REFRESH) {
        this.getManagementDetails();
      }
    });
  }

  onBackClick() {
    this.dialogRef.close({
      data: {
        action: PlatformConstant.ACTION_CANCEL,
      },
    });
  }

  onCancel() {}

  childCpu: any = null;
  childCpuList: any = [];
  showCloneFields: boolean = false;
  enableClonebutton: boolean = false;
  changechildCpu(event: any) {
    
    if (this.childCpu?.sequence) this.enableClonebutton = true;
  }

  setCloneFields() {
    this.enableClonebutton = false;


    let selectedDimmInCompatableCpus = [];
    this.incompatDimmsList?.forEach((element) => {
      if (element.invalidDimms.includes(this.selectedDimm.sequence)) {
        selectedDimmInCompatableCpus.push(element.cpuSeqNo);
      }
    });


    this.childCpuList = this.cpulist.filter((f) => {
      return (
        f.cpuFamName == this.selectedCpu.cpuFamName &&
        f.sequence !== this.selectedCpu.sequence &&
        !selectedDimmInCompatableCpus.includes(f.sequence)
      );
    });
    
    //check for measurement
    let measureAvailable = false;
    if (this.ManagementDetailsList?.length > 0) {
      for (let i = 0; i < this.ManagementDetailsList.length; i++) {
        
        if (
          this.ManagementDetailsList[i].measured == 1 &&
          this.ManagementDetailsList[i].idleWatts !== null &&
          this.ManagementDetailsList[i].maxWatts !== null
        ) {
          measureAvailable = true;
          
          break;
        }
      }
    }
    
    if (this.childCpuList.length > 0 && measureAvailable) {
      this.showCloneFields = true;
    }
  }

  public async openModalCloneConfirmation() {
    const result = await this.modalService
      .open({
        content: {
          type: CngContentConfigType.COMPONENT,
          content: ConfirmationBoxComponent,
          componentData: {
            someText: this.translate.instant("ARE_YOU_SURE_TO_PERFORM_CLONE"),
            key: "edit",
          },
        },
        size: CngModalSize.SMALL,
      })
      .onDismiss.toPromise();

    if (result == "ok") {
      this.cloneMeasurement();
    }

    
  }

  cloneMeasurement() {
    this.blockUI.start("Loading...");
    let serverConfigSeqList = [];
    this.ManagementDetailsList.forEach((element) => {
      serverConfigSeqList.push(element.sequence);
    });
    let childcpusList = [];
    if(this.childCpu && this.childCpu.length > 0) this.childCpu.forEach((element) => {
      childcpusList.push(element.sequence);
    });
    let childDimmsList = [];
    if(this.selectedChildDimm && this.selectedChildDimm.length > 0) this.selectedChildDimm.forEach((element) => {
      childDimmsList.push(element.sequence);
    })

    /** 
     * updating params - add child dimms 
     * for cloning cpus : childDimms = dimm
     * for cloning dimms : childCpus = parentCpu
    */
    const params = {
      parentCpu: this.selectedCpu.sequence,
      childCpus: childcpusList.length > 0 ? childcpusList : [this.selectedCpu.sequence],
      childDimms: childDimmsList.length > 0 ? childDimmsList : [this.selectedDimm.sequence],
      dimm: this.selectedDimm.sequence,
      serverConfigSeqList: serverConfigSeqList,
      serverSeqNo: this.platformData.params.sequence,
      type: this.platformData.params.type,
    };
    
    const url = UrlConstant.MEASUREMENT_CLONE;
    this.subscriptions.add(
      this.apiService.postMethod(url, params).subscribe(
        (data: any) => {
          

          let fil = [];
          data.forEach((element) => {
            let splitmsg = element.trim().split(" ");
            
            let filt = this.ManagementDetailsList.filter((f) => {
              return splitmsg[0] == f.sequence;
            });
            
            let ch =
              "cpu: " + filt[0]?.cpuCount + " dimm: " + filt[0]?.dimmCount;
            let g = {
              cpu: filt[0]?.cpuCount,
              dimm: filt[0]?.dimmCount,
              msg: element.replace(splitmsg[0], ch),
            };
            fil.push(g);
          });
          fil.sort(function (a, b) {
            if (a["cpu"] === b["cpu"]) {
              return a["dimm"] - b["dimm"];
            }
            return a["cpu"] > b["cpu"] ? 1 : -1;
          });

          this.blockUI.stop();
          let msg1 =
            "<b>Donor:</b> " +
            this.selectedCpu.displayName +
            ", Dimm: " +
            this.selectedDimm.displayName +
            ", <b>Target(s):</b> ";
          if(this.childCpu && this.childCpu.length > 0) {
            this.childCpu.forEach((element) => {
              msg1 = msg1.concat(element.displayName + ",");
            });
          }
          if(this.selectedChildDimm && this.selectedChildDimm.length > 0) {
            this.selectedChildDimm.forEach(element => {
              msg1 = msg1.concat(element.displayName + ",");
            })
          }

          fil.forEach((e) => {
            msg1 = msg1.concat("\n" + e.msg);
          });

          
          this.toaster.show("success", "", msg1, 30000);

          this.childCpu = null;
          this.selectedChildDimm = null;
          this.enableClonebutton = false;
        },
        (error) => {
          this.blockUI.stop();
          let errorMsg = error?.error
            ? error.error?.error?.errorString
            : "Oops something went wrong!!";
          this.toaster.show(
            "error",
            errorMsg,
            error ? error?.message : "",
            5000
          );
          this.enableClonebutton = true;
        }
      )
    );
  }

  config = {
    displayKey: "displayName", //if objects array passed which key to be displayed defaults to description
    search: true, //true/false for the search functionlity defaults to false,
    height: "200px", //height of the list so that if there are more no of items it can show a scroll defaults to auto. With auto height scroll will never appear
    placeholder: "Select Option", // text to be displayed when no item is selected defaults to Select,
    limitTo: 0, // number thats limits the no of options displayed in the UI (if zero, options will not be limited)
    moreText: "more", // text to be displayed whenmore than one items are selected like Option 1 + 5 more
    noResultsFound: "No results found!", // text to be displayed when no items are found while searching
    searchPlaceholder: "Search", // label thats displayed in search input,
    searchOnKey: "displayName", // key on which search should be performed this will be selective search. if undefined this will be extensive search on all keys
    clearOnSelection: true, // clears search criteria when an option is selected if set to true, default is false
    inputDirection: "ltr", // the direction of the search input can be rtl or ltr(default)
  };

  // ddl
  onItemSelect(item: any, type: string) {
    
    
    if (
      (type == "cpu" && this.childCpu.length > 0) ||
      (type == "dimms" && this.selectedChildDimm.length > 0)
    )
      this.enableClonebutton = true;
    else this.enableClonebutton = false;
  }
  OnItemDeSelect(item: any, type: string) {
    
    
    if (
      (type == "cpu" && this.childCpu.length > 0) ||
      (type == "dimms" && this.selectedChildDimm.length > 0)
    )
      this.enableClonebutton = true;
    else this.enableClonebutton = false;
  }
  onSelectAll(items: any, type: string) {
    
  }
  onDeSelectAll(items: any, type: string) {
    
  }
  dropdownSettings = {
    singleSelection: false,
    text: "Select",
    selectAllText: "Select All",
    unSelectAllText: "UnSelect All",
    enableSearchFilter: true,
    classes: "myclass custom-class",
    badgeShowLimit: 1,
    maxHeight: 150,
    position: "bottom",
    autoPosition: false,
    enableCheckAll: false,
    enableFilterSelectAll: false,
    labelKey: "displayName",
  };
}
