
import { Injectable } from '@angular/core';
import { CanActivate,  Router } from '@angular/router';

@Injectable({
  providedIn: "root",
})
export class AuthorizationGuard implements CanActivate {
    constructor(private router : Router){}
  canActivate()
  {
   
    if (localStorage.getItem("UCS.EulaAcceptanceStatus") == "true") {
        return true;
    } else {
        this.router.navigate(["/"]);
        return false;
    }
        
  }

}
