<div class="flex-center" *ngIf="label === 'AddConfiguration'">
  <a
    (click)="onClick($event, 'AddConfiguration')"
    id="configure"
    title="Add Configuration"
  >
    <span class="app-icon app-icon-add-config"></span>
  </a>
</div>
<div class="flex-center" *ngIf="label === 'ManageComponentOptions'">
  <a
    (click)="onClick($event, 'ManageComponentOptions')"
    id="manageComp"
    title="Manage Component Options"
  >
    <span class="app-icon app-icon-manage"></span>
  </a>
</div>
<div
  class="flex-center"
  *ngIf="
    label === 'AddMeasurement' &&
    !(
      (label === 'AddMeasurement' && params?.data?.legacyPlatform == false) ||
      (label === 'AddMeasurement' &&
        params?.data?.legacyPlatform != false &&
        params?.data?.isNewConverted == true)
    )
  "
>
  <a
    (click)="onClick($event, 'AddMeasurement')"
    id="addMeasurement"
    title="Add Measurement"
  >
    <span class="app-icon app-icon-add-measurements"></span>
  </a>
</div>

<!-- additional platform -->
<div
  class="flex-center"
  *ngIf="label === 'AddMeasurement' && params?.data?.type.includes('AdditionalPlatform')"
>
  <a
    (click)="onClick($event, 'AddMeasurement')"
    id="addMeasurement"
    title="Add Measurement"
  >
    <span class="app-icon app-icon-add-measurements"></span>
  </a>
</div>

<div
  class="flex-center"
  *ngIf="((label === 'AddMeasurement' && params?.data?.legacyPlatform == false) ||
    (label === 'AddMeasurement' && params?.data?.legacyPlatform != false && params?.data?.isNewConverted == true)) &&
    !params?.data?.type.includes('AdditionalPlatform') 
    "
>
  <a id="addMeasurement" title="Add Measurement">
    <span class="app-icon app-icon-add-measurements-disabled"></span>
  </a>
</div>
<div class="flex-center" *ngIf="label === 'CpuMemoryCompatibility'">
  <a
    (click)="onClick($event, 'CpuMemoryCompatibility')"
    id="memCompatibility"
    title="Cpu/Memory Compatibility"
  >
    <span class="app-icon app-icon-manage-component"></span>
  </a>
</div>

<div class="flex-center" *ngIf="label === 'Edit'" style="text-align: center">
  <a
    (click)="onClick($event, 'Edit')"
    class="anchor-edit"
    id="edit"
    title="Edit"
  >
    <span class="icon-edit icon-size-20 icon-edit icon-color"></span>
  </a>
</div>

<div class="flex-center" *ngIf="label === 'Delete'" style="text-align: center">
  <a
    (click)="openModal($event)"
    class="anchor-close"
    id="delete"
    title="Delete"
  >
    <span class="icon-delete icon-size-20 icon-delete"></span>
  </a>
</div>
