import { Component, OnInit } from '@angular/core';
import { RaiseCaseComponent } from './raise-case/raise-case.component';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PlatformConstant } from 'src/constants/platform.constants';
import { ApiService } from 'src/app/services/api.service';
import { Subscription } from 'rxjs';
import { ToasterService } from 'src/app/shared/components/toast/toast.service';
import { NgBlockUI, BlockUI } from 'ng-block-ui';

@Component({
  selector: 'app-configs-qc-reports',
  templateUrl: './configs-qc-reports.component.html',
  styleUrls: ['./configs-qc-reports.component.css']
})
export class ConfigsQcReportsComponent implements OnInit {
  subscription: Subscription = new Subscription();
  @BlockUI() blockUI: NgBlockUI;

  constructor(
    public dialog: MatDialog,
    private apiService: ApiService,
    private toast: ToasterService
  ) { }

  ngOnInit(): void {
  }

  openDialog() {
    
    const dialogRef = this.dialog.open(RaiseCaseComponent, {      
      data: {
              header: "Raise Case"
            }            
    });   

    dialogRef.afterClosed().subscribe(result => {

      try{
        if (result !== PlatformConstant.ACTION_CANCEL) {
           
           this.onRaiseCase(result);
        }
        
      } catch (error) {
        
      }        
    });
  }
  onRaiseCase(caseData) {
    
    this.blockUI.start('Loading...')
    this.subscription.add(this.apiService.postMethodAdmin('/admin/configqc/createCase', caseData).subscribe((response) => {
      
      let incidentId = Object.keys(response)[0];
      
      this.toast.show("success", `Incident ${incidentId} was created successfully!`, '', 5000);
      this.blockUI.stop();
    }));
    
  }

}
