import { Component, OnInit, Inject, HostListener, ChangeDetectorRef, ViewChild, ElementRef } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'; import { Subscription } from 'rxjs';

import { ComponentConstant } from 'src/constants/components.constants';
import { ApiService } from 'src/app/services/api.service';
import { UrlConstant } from 'src/constants/url.constants';
import { ToasterService } from "src/app/shared/components/toast/toast.service";
import { errorCodes } from 'src/constants/errorCodes';
import { PlatformConstant } from 'src/constants/platform.constants';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { AdminService } from 'src/app/services/admin.service';
import { UpdateNameService } from 'src/app/services/update-name.service';
@Component({
  selector: 'app-manage-component',
  templateUrl: './manage-component.component.html',
  styleUrls: ['./manage-component.component.css']
})
export class ManageComponentComponent implements OnInit {

  constructor(public dialog: MatDialog,
    private apiService: ApiService,
    private toaster: ToasterService,
    public dialogRef: MatDialogRef<ManageComponentComponent>,
    @Inject(MAT_DIALOG_DATA) public platformData: any,
    private adminService: AdminService,
    private updateNameService: UpdateNameService,
  ) { }

  @BlockUI() blockUI: NgBlockUI;
  subscriptions: Subscription = new Subscription();
  PlatformType: any = null;

  platformTypes_ALL: any = PlatformConstant.PLATFORMS_DROPDOWN;
  platformDropdown: any = [];

  searchText = '';
  isFanPolicyReq: boolean = false;
  isLegacy: boolean = true;
  isQuantityAdded: boolean = false;
  isPlatformReleased: boolean = false;
  platformReleasedInfo: string = PlatformConstant.PLATFORM_RELEASED;
  @ViewChild('manageComponent', { read: ElementRef, static: false }) manageComponent: ElementRef;
  ngOnInit(): void {
    this.isPlatformReleased = Number(this.platformData.params.released) == 1;
    this.PlatformType = this.platformData.params.type;
    this.supportedCPUs = this.platformData.params.cpus ? this.platformData.params.cpus : [];
    this.supportedDimms = this.platformData.params.dimms ? this.platformData.params.dimms : [];
    this.supportedPowerSupply = this.platformData.params.powerSupplies ? this.platformData.params.powerSupplies : [];
    this.supportedStorages = this.platformData.params.storages ? this.platformData.params.storages : [];
    this.supportedIOCards = this.platformData.params.ioes ? this.platformData.params.ioes : [];

    this.supportedIOMS = this.platformData.params.ioms ? this.platformData.params.ioms : [];
    //changed to childPlatforms
    this.supportedBlades = this.platformData.params.childPlatforms ? this.platformData.params.childPlatforms : [];
    this.supportedNodes = this.platformData.params.childPlatforms ? this.platformData.params.childPlatforms : [];
    // this.supportedBlades = this.platformData.params.bladeServers? this.platformData.params.bladeServers:[];
    // this.supportedNodes = this.platformData.params.catridgeServers? this.platformData.params.catridgeServers:[];

    this.supportedExpansioncards = this.platformData.params.expansions ? this.platformData.params.expansions : [];
    this.selectedFanPolicy = this.platformData.params.fanPolicyPid ? this.platformData.params.fanPolicyPid : "";
    this.supportedAdditionalComponents = this.platformData.params.additionalComponents;


    // 
    this.updateNameService.getplatformPanelData().subscribe((data) => {

      if (data.length > 0) {
        let platform = data.filter(f => { return f["platformType"] == this.platformData.params.type })

        this.isFanPolicyReq = platform[0]?.isFanPolicyReq;
      }


      if (this.platformData.params.legacyPlatform == null && (this.platformData.params.isNewConverted == false || this.platformData.params.isNewConverted == null)) {
        this.isLegacy = true;
        this.setDropdownValues(this.isLegacy);
      } else {
        this.isLegacy = false;
        this.setDropdownValues(this.isLegacy);
      }
    });
  }

  setDropdownValues(isLegacy) {
    //set DDL
    let ddl = [];
    if (this.PlatformType == PlatformConstant.RACK) {
      this.blockUI.start("Loading...");
      ddl = this.platformTypes_ALL.slice(0, 5);
      ddl.sort((a, b) => {
        let fa = a.viewValue.toLowerCase(),
          fb = b.viewValue.toLowerCase();
        if (fa > fb) return 1;
        if (fa < fb) return -1;
        return 0;
      });
      if (this.checkforFan())
        ddl.push(this.platformTypes_ALL[9]);
      // this.platformDropdown = ddl;
      if (!isLegacy)
        this.getAdditionalComponents(ddl);
      else {
        this.platformDropdown = ddl;


      }

      this.blockUI.stop();

    }
    else if (this.PlatformType == PlatformConstant.BLADE ||
      this.PlatformType == PlatformConstant.NODE) {
      //this.blockUI.start("Loading...");
      ddl.push(this.platformTypes_ALL[0]);
      ddl.push(this.platformTypes_ALL[1]);
      ddl.push(this.platformTypes_ALL[3]);
      ddl.push(this.platformTypes_ALL[4]);
      if (this.checkforFan())
        ddl.push(this.platformTypes_ALL[9]);
      if (!isLegacy)
        this.getAdditionalComponents(ddl);
      else
        this.platformDropdown = ddl;
      //this.blockUI.stop();  
    }
    else if (this.PlatformType == PlatformConstant.BLADE_CHASSIS) {
      ddl.push(this.platformTypes_ALL[2]);
      ddl.push(this.platformTypes_ALL[5]);
      ddl.push(this.platformTypes_ALL[6]);
      if (this.checkforFan())
        ddl.push(this.platformTypes_ALL[9]);
      if (!isLegacy)
        this.getAdditionalComponents(ddl);
      else
        this.platformDropdown = ddl;
    }
    else if (this.PlatformType == PlatformConstant.MODULAR_CHASSIS) {
      ddl.push(this.platformTypes_ALL[2]);
      ddl.push(this.platformTypes_ALL[3]);
      ddl.push(this.platformTypes_ALL[4]);
      ddl.push(this.platformTypes_ALL[7]);
      if (this.checkforFan())
        ddl.push(this.platformTypes_ALL[9]);
      if (!isLegacy)
        this.getAdditionalComponents(ddl);
      else
        this.platformDropdown = ddl;
    }
    else if (this.PlatformType == PlatformConstant.FABRIC_INTERCONNECTS ||
      this.PlatformType == PlatformConstant.FABRIC_EXTENDERS) {
      ddl.push(this.platformTypes_ALL[2]);
      ddl.push(this.platformTypes_ALL[8]);
      if (this.checkforFan())
        ddl.push(this.platformTypes_ALL[9]);
      if (!isLegacy)
        this.getAdditionalComponents(ddl);
      else
        this.platformDropdown = ddl;
    }
    else if (this.platformData.additionalPlatform) {

      ddl = this.platformTypes_ALL.slice(0, 5);

      if (this.checkforFan())
        ddl.push(this.platformTypes_ALL[9]);
      // this.platformDropdown = ddl;

      this.getAdditionalComponents(ddl);
    }
  }

  checkforFan(): boolean {
    let value: boolean = false;
    if ((this.isFanPolicyReq == true && this.platformData.params.isNewConverted == true)
      || (this.isFanPolicyReq == true && this.platformData.params.isNewCreated == true)) {
      value = true
    }

    return value;
  }

  getAdditionalComponents(ddl: any) {
    this.blockUI.start();
    this.subscriptions.add(
      this.apiService.getMethod('admin/manageComponentsLPanelData').subscribe((response) => {
        this.blockUI.stop();

        response.map((manageComponent) => {
          if (manageComponent.additionalComponent) {
            let compNameSplit = manageComponent.displayName.trim().split(" ");
            let compName = '';
            for (let i = 0; i < compNameSplit.length; i++) {
              if (!(compNameSplit[i].trim().toLowerCase() == 'manage'))
                compName += compNameSplit[i] + " ";
            }

            let row = {
              value: manageComponent.componentType,
              viewValue: compName,
              additionalComponent: true
            };

            ddl.push(row);
            /* ddl.sort ( (a,b) => {
               let fa = a.viewValue.toLowerCase(), 
                   fb = b.viewValue.toLowerCase();
               if (fa > fb) return 1;
               if (fa < fb) return -1;
               return 0;
             }); */
          }
        })
        this.platformDropdown = ddl;


      }, (error) => {
        console.error(error);
        let errorMsg = error?.error ? error.error?.error?.errorString : "Oops something went wrong!!";
        this.toaster.show('error', errorMsg, error ? error?.message : '');
        this.blockUI.stop();
      })

    );

  }

  close() {

    this.dialogRef.close({
      data: {
        action: this.edited ? PlatformConstant.ACTION_REFRESH : PlatformConstant.ACTION_CANCEL
      }
    });
  }

  supportedCPUs = [];
  supportedDimms = [];
  supportedPowerSupply = [];
  supportedStorages = [];
  supportedIOCards = [];

  supportedIOMS = [];
  supportedBlades = [];
  supportedNodes = [];
  supportedExpansioncards = [];
  supportedAdditionalComponents = [];
  cpulist = [];
  showLoader = null;
  selectedSeriesId = [];
  onSeriesSelect(index: number) {

    let ind = this.selectedSeriesId.indexOf(index);


    if (ind >= 0) { this.selectedSeriesId.splice(ind, 1); }
    else this.selectedSeriesId.push(index);


  }
  getPlatfomList() {
    this.cpulist = [];
    this.showLoader = 'show';
    this.blockUI.start('Loading...');

    let cpus = ComponentConstant.CPUS;
    const url = UrlConstant.MANAGE_COMP_OPTIONS + '/?componentType=' + cpus;
    this.subscriptions.add(
      this.apiService.getMethod(url).subscribe((data: any) => {

        if (data) {
          for (const key of Object.keys(data)) {
            let rdata = data[key];
            for (let i = 0; i < rdata.length; i++) {
              rdata[i]["supportCheck"] = this.supportedCPUs.includes(rdata[i].sequence);
            }
            rdata = rdata.filter(r => r.released == 1);
            let k = { name: key, data: rdata, checkAll: false };
            this.cpulist.push(k);
            this.showCheckALL(ComponentConstant.CPUS);
          }
          this.cpulist.sort((a, b) => {
            let fa = a.name.toLowerCase(),
              fb = b.name.toLowerCase();
            if (fa > fb) return 1;
            if (fa < fb) return -1;
            return 0;
          });
          this.cpulist.forEach(cpu => {
            cpu.data = this.sortAlphaNumGen(cpu.data);
          });
        }
        this.showLoader = 'hide';
        this.blockUI.stop();
      }, (error) => {
        console.error(error);
        let errorMsg = error?.error ? error.error?.error?.errorString : "Oops something went wrong!!";
        this.toaster.show('error', errorMsg, error ? error?.message : '');
        this.blockUI.stop();
      })
    );
  }
  dimmList: any = [];
  getMemoryList() {
    this.showLoader = 'show';
    this.blockUI.start('Loading...');

    this.dimmList = [];
    let dimms = ComponentConstant.DIMMS;
    const url = UrlConstant.MANAGE_COMP_OPTIONS + '/?componentType=' + dimms;
    this.subscriptions.add(
      this.apiService.getMethod(url).subscribe((data: any) => {

        if (data) {
          for (const key of Object.keys(data)) {
            let rdata = data[key];
            for (let i = 0; i < rdata.length; i++) {
              rdata[i]["supportCheck"] = this.supportedDimms.includes(rdata[i].sequence);
            }
            rdata = rdata.filter(r => r.released == 1);
            let k = { name: key, data: rdata, checkAll: false };
            this.dimmList.push(k);
            this.showCheckALL(ComponentConstant.DIMMS);
          }
          this.dimmList.sort((a, b) => {
            let fa = a.name.toLowerCase(),
              fb = b.name.toLowerCase();
            if (fa > fb) return 1;
            if (fa < fb) return -1;
            return 0;
          });
          this.dimmList.forEach(dimm => {
            dimm.data = this.sortAlphaNumGen(dimm.data);
          });
        }
        this.showLoader = "hide";
        this.blockUI.stop();

      }, (error) => {
        console.error(error);
        let errorMsg = error?.error ? error.error?.error?.errorString : "Oops something went wrong!!";
        this.toaster.show('error', errorMsg, error ? error?.message : '');
        this.blockUI.stop();
      })
    );
  }
  PSList: any = [];
  checkboxSelectAll: boolean = false;
  sortAlphaNumPS(list: any[]) {
    list = list.sort((a, b) => a.powerSupply.displayName.toLowerCase().localeCompare(b.powerSupply.displayName.toLowerCase(), undefined, { numeric: true, sensitivity: 'base' }));
    return list;
  }
  sortAlphaNumGen(list: any) {
    list = list.sort((a, b) => a.displayName.toLowerCase().localeCompare(b.displayName.toLowerCase(), undefined, { numeric: true, sensitivity: 'base' }));
    return list;
  }
  getPSList() {
    this.showLoader = 'show';
    this.blockUI.start('Loading...');

    this.PSList = [];
    const url = UrlConstant.MANAGE_COMP_DATA + ComponentConstant.POWERSUPPLY;
    this.subscriptions.add(
      this.apiService.getMethod(url).subscribe((data: any) => {

        if (data.powerSupplyVoltage) {
          for (let i = 0; i < data.powerSupplyVoltage.length; i++) {
            data.powerSupplyVoltage[i]["supportCheck"] = this.supportedPowerSupply.includes(data.powerSupplyVoltage[i].sequence);
          }
          data.powerSupplyVoltage = data.powerSupplyVoltage.filter(p => p.powerSupply.released == 1);
          this.PSList = data.powerSupplyVoltage;
          this.PSList = this.sortAlphaNumPS(this.PSList);
          this.showCheckALL(ComponentConstant.POWERSUPPLY);
        }
        this.showLoader = 'hide';
        this.blockUI.stop();

      }, (error) => {
        console.error(error);
        let errorMsg = error?.error ? error.error?.error?.errorString : "Oops something went wrong!!";
        this.toaster.show('error', errorMsg, error ? error?.message : '');
        this.blockUI.stop();
      })
    );
  }
  IOCardsList: any = []
  getIOCardsList() {
    if (this.platformData.params.pciSlots > 0) {

      this.showLoader = 'show';
      this.blockUI.start('Loading...');
      this.IOCardsList = [];
      const url = UrlConstant.MANAGE_COMP_OPTIONS + "?componentType=" + ComponentConstant.IOES;
      this.subscriptions.add(
        this.apiService.getMethod(url).subscribe((data: any) => {

          if (data) {
            for (let i = 0; i < data.ioes.length; i++) {
              data.ioes[i]["supportCheck"] = this.supportedIOCards.includes(data.ioes[i].sequence);
            }
            data.ioes = data.ioes.filter(c => c.released == 1);
            this.IOCardsList = data;
            this.IOCardsList.ioes = this.sortAlphaNumGen(this.IOCardsList.ioes);
            this.showCheckALL(ComponentConstant.IOES);

          }
          this.showLoader = 'hide';
          this.blockUI.stop();

        }, (error) => {
          console.error(error);
          let errorMsg = error?.error ? error.error?.error?.errorString : "Oops something went wrong!!";
          this.toaster.show('error', errorMsg, error ? error?.message : '');
          this.blockUI.stop();
        })
      );
    } else {
      this.toaster.show('error', errorCodes.MAX_IOES_ERR_MSG, '', 5000);
      this.disableSave = false;
    }
  }
  storagesList: any = [];
  getStoragesList() {
    if (this.platformData.params.maxDisks > 0) {

      this.showLoader = 'show';
      this.blockUI.start('Loading...');
      this.storagesList = [];
      const url = UrlConstant.MANAGE_COMP_DATA + "?component=" + ComponentConstant.STORAGES;
      this.subscriptions.add(
        this.apiService.getMethod(url).subscribe((data: any) => {
          if (data.componenetList) {
            for (let i = 0; i < data.componenetList.length; i++) {
              data.componenetList[i]["supportCheck"] = this.supportedStorages.includes(data.componenetList[i].sequence);
            }
            data.componenetList = data.componenetList.filter(c => c.released == 1);
            this.storagesList = data.componenetList;
            this.storagesList = this.storagesList.sort((a, b) => a.capacity - b.capacity);
            this.showCheckALL(ComponentConstant.STORAGES);

          }
          this.showLoader = 'hide';
          this.blockUI.stop();
        }, (error) => {
          console.error(error);
          let errorMsg = error?.error ? error.error?.error?.errorString : "Oops something went wrong!!";
          this.toaster.show('error', errorMsg, error ? error?.message : '');
          this.blockUI.stop();
        })
      );
    }
    else {
      this.toaster.show('error', errorCodes.MAX_STORAGE_ERR_MSG, '', 5000);
      this.disableSave = false;
    }
  }

  iomsList: any = [];
  getIOModuleList() {
    this.showLoader = 'show';
    this.blockUI.start('Loading...');
    this.iomsList = [];
    const url = UrlConstant.MANAGE_COMP_OPTIONS + "?componentType=" + ComponentConstant.IOMS;
    this.subscriptions.add(
      this.apiService.getMethod(url).subscribe((data: any) => {


        if (data) {
          for (let i = 0; i < data.ioms.length; i++) {
            data.ioms[i]["supportCheck"] = this.supportedIOMS.includes(data.ioms[i].sequence);
          }
          data.ioms = data.ioms.filter(iom => iom.released == 1);
          this.iomsList = data;

          this.showCheckALL(ComponentConstant.IOMS);
        }
        this.showLoader = 'hide';
        this.blockUI.stop();

      }, (error) => {
        console.error(error);
        let errorMsg = error?.error ? error.error?.error?.errorString : "Oops something went wrong!!";
        this.toaster.show('error', errorMsg, error ? error?.message : '');
        this.blockUI.stop();
      })
    );
  }

  bladeList = [];
  getBladeList() {
    this.showLoader = 'show';
    this.blockUI.start('Loading...');

    this.bladeList = [];
    const url = UrlConstant.GET_ALL_BLADED_NODES + "?componentType=" + PlatformConstant.BLADE;
    this.subscriptions.add(
      this.apiService.getMethod(url).subscribe((data: any) => {


        if (data) {
          for (let i = 0; i < data.length; i++) {
            data[i]["supportCheck"] = this.supportedBlades.includes(data[i].sequence);
          }
          data = data.filter(d => d.released == 1);
          this.bladeList = data;
          this.showCheckALL(PlatformConstant.BLADE);

        }
        this.showLoader = 'hide';
        this.blockUI.stop();
      }, (error) => {
        console.error(error);
        let errorMsg = error?.error ? error.error?.error?.errorString : "Oops something went wrong!!";
        this.toaster.show('error', errorMsg, error ? error?.message : '');
        this.blockUI.stop();
      })
    );

  }

  nodeList = [];
  getNodeList() {
    this.showLoader = 'show';
    this.blockUI.start('Loading...');
    this.nodeList = [];
    const url = UrlConstant.GET_ALL_BLADED_NODES + "?componentType=" + PlatformConstant.NODE;
    this.subscriptions.add(
      this.apiService.getMethod(url).subscribe((data: any) => {


        if (data) {
          for (let i = 0; i < data.length; i++) {
            data[i]["supportCheck"] = this.supportedNodes.includes(data[i].sequence);
          }
          data = data.filter(d => d.released == 1);
          this.nodeList = data;
          this.showCheckALL(PlatformConstant.NODE);

        }
        this.showLoader = 'hide';
        this.blockUI.stop();
      }, (error) => {
        console.error(error);
        let errorMsg = error?.error ? error.error?.error?.errorString : "Oops something went wrong!!";
        this.toaster.show('error', errorMsg, error ? error?.message : '');
        this.blockUI.stop();
      })
    );
  }

  expansionList: any = [];
  getExpansionList() {

    if (this.platformData.params.maxExpSlots > 0) {
      this.showLoader = 'show';
      this.blockUI.start('Loading...');
      this.expansionList = [];
      const url = UrlConstant.MANAGE_COMP_OPTIONS + "?componentType=" + ComponentConstant.EXPANSIONS;
      this.subscriptions.add(
        this.apiService.getMethod(url).subscribe((data: any) => {


          if (data) {
            for (let i = 0; i < data.expansions.length; i++) {
              data.expansions[i]["supportCheck"] = this.supportedExpansioncards.includes(data.expansions[i].sequence);
            }
            data.expansions = data.expansions.filter(e => e.released == 1);
            this.expansionList = data;

            this.showCheckALL(ComponentConstant.EXPANSIONS);

          }
          this.showLoader = 'hide';
          this.blockUI.stop();
        }, (error) => {
          console.error(error);
          let errorMsg = error?.error ? error.error?.error?.errorString : "Oops something went wrong!!";
          this.toaster.show('error', errorMsg, error ? error?.message : '');
          this.blockUI.stop();
        })
      );
    }
    else {
      this.toaster.show('error', errorCodes.MAX_EXPANSION_ERR_MSG, '', 5000);
      this.disableSave = false;
    }
  }

  fanPolicyList = [];
  selectedFanPolicy: any;
  getFanpolicy() {

    this.showLoader = 'show';
    this.blockUI.start('Loading...');
    this.fanPolicyList = [];
    const url = UrlConstant.GET_FAN_TABLE;
    this.subscriptions.add(
      this.apiService.getMethod(url).subscribe((data: any) => {

        this.fanPolicyList = data;
        this.selectedFanPolicy = this.platformData.params.fanPolicyPid ? this.platformData.params.fanPolicyPid : "";
        this.showLoader = 'hide';
        this.blockUI.stop();
      }, error => {
        let errorMsg = error?.error ? error.error?.error?.errorString : errorCodes.GENERIC_ERROR_MESSAGE;
        this.toaster.show('error', errorMsg, error ? error?.message : '');
        this.blockUI.stop();
      })
    );
  }

  additionalComponentsList: any = [];
  getAdditionalComponentsList(type: any, quantityName: string) {
    this.showLoader = 'show';
    this.blockUI.start('Loading...');
    this.additionalComponentsList = [];
    const url = UrlConstant.MANAGE_COMP_DATA + "?component=" + type;
    this.subscriptions.add(
      this.apiService.getMethod(url).subscribe((data: any) => {


        let selctedComps = [];
        if (data) {
          if (this.supportedAdditionalComponents.length > 0)
            selctedComps = this.supportedAdditionalComponents?.filter(f => {
              //return f.name == type;
              return f.componentType == type;
            });

          if (selctedComps.length > 0) {
            for (let i = 0; i < data.componenetList.length; i++) {
              data.componenetList[i]["supportCheck"] = selctedComps[0]?.value.includes(data.componenetList[i].sequence);
            }
          }
          data.componenetList = data.componenetList.filter(c => c.released == 1);
          this.additionalComponentsList = data.componenetList;
          this.checkQuantityAdded(quantityName);
          this.showCheckALL("ADPLATFORM");

        }
        this.showLoader = 'hide';
        this.blockUI.stop();

      }, (error) => {
        console.error(error);
        let errorMsg = error?.error ? error.error?.error?.errorString : "Oops something went wrong!!";
        this.toaster.show('error', errorMsg, error ? error?.message : '');
        this.blockUI.stop();
      })
    );
  }
  checkQuantityAdded(quantityName: string) {
    this.isQuantityAdded = false;
    this.additionalComponentsList.forEach(c => {
      if (c.additionalFields.filter(f => f.name == quantityName).length > 0) {
        this.isQuantityAdded = true; return;
      }
    })
    if (!this.isQuantityAdded) {
      let toasterMessage = quantityName + ' is not added. Please add ' + quantityName + ' in the additional fields else will be considered as 1. '
      this.toaster.show('warning', toasterMessage, '', 60000);
    }
  }
  disableSave: boolean = true;
  selectedType: any = [];
  changeType(e: any) {
    this.resetArrays();
    let value = e.value?.value;
    let quantityName = e.value && e.value.viewValue ? e.value.viewValue.trimEnd() + '_Quantity' : '';

    if (e.value?.additionalComponent) {
      this.getAdditionalComponentsList(value, quantityName);
    }
    else {

      if (value == ComponentConstant.CPUS) {
        this.getPlatfomList();
      }
      else if (value == ComponentConstant.DIMMS) {
        this.getMemoryList();
      }
      else if (value == ComponentConstant.POWERSUPPLY) {
        this.getPSList();
      }
      else if (value == ComponentConstant.IOES) {
        this.getIOCardsList();
      }
      else if (value == ComponentConstant.STORAGES) {
        this.getStoragesList();
      }
      else if (value == ComponentConstant.IOMS) {
        this.getIOModuleList();
      }
      else if (value == PlatformConstant.BLADE) {
        this.getBladeList();
      }
      else if (value == PlatformConstant.NODE) {
        this.getNodeList();
      }
      else if (value == ComponentConstant.EXPANSIONS) {
        this.getExpansionList();
      }
      else if (value == ComponentConstant.FAN_POLICY) {
        this.getFanpolicy();
      }
    }
    this.selectedSeriesId = [];
  }

  edited: boolean = false;
  formChanged: boolean = false;

  platformComponentOptionType() {
    let type = null;
    if (this.selectedType.value == ComponentConstant.POWERSUPPLY) {
      type = 'powerSupplies';
    }
    else if (this.selectedType.value == PlatformConstant.BLADE) {
      // type = 'bladeServers';
      type = 'childPlatforms';

    }
    else if (this.selectedType.value == PlatformConstant.NODE) {
      // type = 'catridgeServers';
      type = 'childPlatforms';

    }
    else if (this.selectedType.value == ComponentConstant.EXPANSIONS) {
      type = 'expansions';
    }
    else {


      type = this.selectedType.value;

    }
    return type;
  }

  setAdditionalComponents() {
    if (this.selectedType.additionalComponent == true) {
      let ids = [];


      if (this.additionalComponentsList) {
        this.additionalComponentsList.forEach(row => {
          if (row.supportCheck == true) {
            ids.push(row.sequence);
          }
        });
      }
      const param = [{
        //"name": this.selectedType.value,        
        //"name": this.selectedType.viewValue.trim().split(" ").toString(),
        "name": this.selectedType && this.selectedType.viewValue ? this.selectedType.viewValue.trim() : this.selectedType.value,
        "value": ids,
        "componentType": this.selectedType.value
      }]
      return param;
    }
    else {
      return [];
    }
  }

  saveFan(action: any) {

    this.blockUI.start('Loading...');
    if (this.selectedFanPolicy !== null && this.selectedFanPolicy !== "") {
      this.edited = true;

      let reqestParams = {
        "sequence": this.platformData.params.sequence,
        "pid": this.selectedFanPolicy,
        "isNewConverted": this.platformData.params?.legacyPlatform !== false ?
          true : null
      }
      const url = UrlConstant.SAVE_FAN_TABLE;
      this.subscriptions.add(
        this.apiService.postMethod(url, reqestParams).subscribe((data: any) => {

          this.toaster.show('success', errorCodes.PLATFORM_COMPONENTS_UPDATED, '', 5000);
          this.platformData.params["fanPolicyPid"] = this.selectedFanPolicy;
          this.blockUI.stop();
          this.formChanged = false;

          if (action == 'closeModal') {
            this.dialogRef.close({
              data: {
                action: PlatformConstant.ACTION_REFRESH
              }
            });
          }
        }, error => {
          let errorMsg = error?.error ? error.error?.error?.errorString : errorCodes.GENERIC_ERROR_MESSAGE;
          this.toaster.show('error', errorMsg, error ? error?.message : '');
          this.blockUI.stop();
        }))
    }
  }

  submit(action: any) {
    this.edited = true;




    if (this.selectedType.value == ComponentConstant.FAN_POLICY) {
      this.saveFan(action);
    }
    else {

      let reqestParams = {
        "id": this.platformData.params.id,
        "platformComponentOptionType": this.platformComponentOptionType(),
        "componentSupportFlagList": this.componentSupportFlagList(this.selectedType.value),

        "dynamicComponent": this.selectedType.additionalComponent == true ? true : false,
        "additionalComponents": this.setAdditionalComponents()
      }





      if (
        (this.PlatformType == "RACK" && this.platformData.params.released == 1 && reqestParams.platformComponentOptionType == ComponentConstant.CPUS &&
          reqestParams.componentSupportFlagList?.length == 0) ||
        (this.PlatformType == "RACK" && this.platformData.params.released == 1 && reqestParams.platformComponentOptionType == ComponentConstant.DIMMS &&
          reqestParams.componentSupportFlagList?.length == 0) ||
        (this.PlatformType == "RACK" && this.platformData.params.released == 1 && reqestParams.platformComponentOptionType == "powerSupplies" &&
          reqestParams.componentSupportFlagList?.length == 0) ||

        (this.PlatformType == "BLADE" && this.platformData.params.released == 1 && reqestParams.platformComponentOptionType == ComponentConstant.CPUS &&
          reqestParams.componentSupportFlagList?.length == 0) ||
        (this.PlatformType == "BLADE" && this.platformData.params.released == 1 && reqestParams.platformComponentOptionType == ComponentConstant.DIMMS &&
          reqestParams.componentSupportFlagList?.length == 0) ||

        (this.PlatformType == "CARTRIDGE" && this.platformData.params.computeNode == 1 && this.platformData.params.released == 1 && reqestParams.platformComponentOptionType == ComponentConstant.CPUS &&
          reqestParams.componentSupportFlagList?.length == 0) ||
        (this.PlatformType == "CARTRIDGE  " && this.platformData.params.computeNode == 1 && this.platformData.params.released == 1 && reqestParams.platformComponentOptionType == ComponentConstant.DIMMS &&
          reqestParams.componentSupportFlagList?.length == 0) ||

        ((this.PlatformType == "MSERIES" || this.PlatformType == "FI" || this.PlatformType == "NEXUS") && this.platformData.params.released == 1 && reqestParams.platformComponentOptionType == "powerSupplies" &&
          reqestParams.componentSupportFlagList?.length == 0) ||

        (this.PlatformType == "CHASSIS" && this.platformData.params.released == 1 && reqestParams.platformComponentOptionType == "powerSupplies" &&
          reqestParams.componentSupportFlagList?.length == 0) ||
        (this.PlatformType == "CHASSIS" && this.platformData.params.released == 1 && reqestParams.platformComponentOptionType == ComponentConstant.IOMS &&
          reqestParams.componentSupportFlagList?.length == 0)
      ) {

        this.toaster.show('error', "Mandatory Components cannot be Empty, Platform Release State is True.", '', 5000);
      }
      else {

        this.blockUI.start('Loading...');
        const url = UrlConstant.MANAGE_PLATFORM_COMP_OPTIONS;
        this.subscriptions.add(
          this.apiService.postMethod(url, reqestParams).subscribe((data: any) => {

            this.blockUI.stop();
            this.formChanged = false;

            if (data.modifiedCount > 0) {
              window.scroll(0, 0);

              this.toaster.show('success', errorCodes.PLATFORM_COMPONENTS_UPDATED, '', 5000);

              if (reqestParams.platformComponentOptionType == ComponentConstant.CPUS) {
                this.platformData.params["cpus"] = reqestParams.componentSupportFlagList;
                this.supportedCPUs = reqestParams.componentSupportFlagList;
              }
              else if (reqestParams.platformComponentOptionType == ComponentConstant.DIMMS) {
                this.platformData.params["dimms"] = reqestParams.componentSupportFlagList;
                this.supportedDimms = reqestParams.componentSupportFlagList;
              }
              else if (reqestParams.platformComponentOptionType == "powerSupplies") {
                this.platformData.params["powerSupplies"] = reqestParams.componentSupportFlagList;
                this.supportedPowerSupply = reqestParams.componentSupportFlagList;
              }
              else if (reqestParams.platformComponentOptionType == ComponentConstant.IOES) {
                this.platformData.params["ioes"] = reqestParams.componentSupportFlagList;
                this.supportedIOCards = reqestParams.componentSupportFlagList;
              }
              else if (reqestParams.platformComponentOptionType == ComponentConstant.STORAGES) {
                this.platformData.params["storages"] = reqestParams.componentSupportFlagList;
                this.supportedStorages = reqestParams.componentSupportFlagList;
              }
              else if (reqestParams.platformComponentOptionType == ComponentConstant.IOMS) {//ioms
                this.platformData.params["ioms"] = reqestParams.componentSupportFlagList;
                this.supportedIOMS = reqestParams.componentSupportFlagList;
              }
              else if (reqestParams.platformComponentOptionType == "bladeServers") {//ioms
                this.platformData.params["bladeServers"] = reqestParams.componentSupportFlagList;
                this.supportedBlades = reqestParams.componentSupportFlagList;
              }
              else if (reqestParams.platformComponentOptionType == "catridgeServers") {//ioms
                this.platformData.params["catridgeServers"] = reqestParams.componentSupportFlagList;
                this.supportedNodes = reqestParams.componentSupportFlagList;
              }
              else if (reqestParams.platformComponentOptionType == ComponentConstant.EXPANSIONS) {//ioms
                this.platformData.params["expansions"] = reqestParams.componentSupportFlagList;
                this.supportedExpansioncards = reqestParams.componentSupportFlagList;
              }
              //child
              else if (reqestParams.platformComponentOptionType == "childPlatforms") {//ioms
                this.platformData.params["childPlatforms"] = reqestParams.componentSupportFlagList;
                this.supportedBlades = reqestParams.componentSupportFlagList;
                this.supportedNodes = reqestParams.componentSupportFlagList;
              }
              else {

                if (reqestParams.dynamicComponent) {
                  this.supportedAdditionalComponents.forEach(e => {

                    if (e.name == reqestParams.platformComponentOptionType) {

                      e.value = reqestParams.additionalComponents[0]?.value
                    }


                  })
                }

              }
              this.formChanged = false;

            }

            if (action == 'closeModal') {
              this.dialogRef.close({
                data: {
                  action: PlatformConstant.ACTION_REFRESH
                }
              });
            }
          }, error => {
            let errorMsg = error?.error ? error.error?.error?.errorString : errorCodes.GENERIC_ERROR_MESSAGE;
            this.toaster.show('error', errorMsg, error ? error?.message : '');
            this.blockUI.stop();
          })
        );

      }
    }
  }
  checkedHandler(e, r) {



    let checked = e.target.checked;
    r.supportCheck = checked;
    this.formChanged = true;
  }

  unsub: Subscription = new Subscription();

  checkAll(e, row, type?: string) {


    let checked = e.target.checked;

    if (type == 'accordion') {
      row.forEach(element => {
        element.supportCheck = checked;
      });
    }
    else {
      if (this.searchText && this.searchText != '') {
        this.unsub = this.adminService.getFilteredComp().subscribe((data) => {

          data.forEach(element => {
            element.supportCheck = checked;
          });
          if (this.unsub) {
            setTimeout(() => {
              this.unsub.unsubscribe();
            }, 100);
          }
        });
      }
      else {
        row.forEach(element => {
          element.supportCheck = checked;
        });
      }

    }
    this.formChanged = true;




  }
  resetArrays() {
    this.cpulist = [];
    this.dimmList = [];
    this.PSList = [];
    this.IOCardsList = [];
    this.storagesList = [];

    this.iomsList = [];
    this.bladeList = [];
    this.nodeList = [];
    this.expansionList = [];

    this.disableSave = false;
    this.checkboxSelectAll = false;
    this.selectedFanPolicy = null;

    this.formChanged = false;
    this.searchText = '';

  }
  componentSupportFlagList(e: any) {
    let ids = [];
    if (e == ComponentConstant.CPUS) {

      this.cpulist.forEach(element => {
        if (element.data) {
          element.data.forEach(row => {
            if (row.supportCheck == true) {
              ids.push(row.sequence);
            }
          });
        }
      });
    }
    else if (e == ComponentConstant.DIMMS) {

      this.dimmList.forEach(element => {
        if (element.data) {
          element.data.forEach(row => {
            if (row.supportCheck == true) {
              ids.push(row.sequence);
            }
          });
        }
      });
    }
    else if (e == ComponentConstant.POWERSUPPLY) {

      if (this.PSList) {
        this.PSList.forEach(row => {
          if (row.supportCheck == true) {
            ids.push(row.sequence);
          }
        });
      }
    }
    else if (e == ComponentConstant.IOES) {

      if (this.IOCardsList.ioes) {
        this.IOCardsList.ioes.forEach(row => {
          if (row.supportCheck == true) {
            ids.push(row.sequence);
          }
        });
      }
    }
    else if (e == ComponentConstant.STORAGES) {

      if (this.storagesList) {
        this.storagesList.forEach(row => {
          if (row.supportCheck == true) {
            ids.push(row.sequence);
          }
        });
      }
    }
    else if (e == ComponentConstant.IOMS) {

      if (this.iomsList.ioms) {
        this.iomsList.ioms.forEach(row => {
          if (row.supportCheck == true) {
            ids.push(row.sequence);
          }
        });
      }
    }
    else if (e == PlatformConstant.BLADE) {

      if (this.bladeList) {
        this.bladeList.forEach(row => {
          if (row.supportCheck == true) {
            ids.push(row.sequence);
          }
        });
      }
    }
    else if (e == PlatformConstant.NODE) {

      if (this.nodeList) {
        this.nodeList.forEach(row => {
          if (row.supportCheck == true) {
            ids.push(row.sequence);
          }
        });
      }
    }
    else if (e == ComponentConstant.EXPANSIONS) {

      if (this.expansionList.expansions) {
        this.expansionList.expansions.forEach(row => {
          if (row.supportCheck == true) {
            ids.push(row.sequence);
          }
        });
      }
    }
    else if (e == ComponentConstant.FAN_POLICY) {
      ids = [this.selectedFanPolicy];
    }
    else {

      ids = [];
    }

    return ids;
  }

  showCheckALL(type: any) {
    if (type == ComponentConstant.CPUS) {
      this.cpulist.forEach(element => {
        let checked = element.data.filter(row => { return row?.supportCheck == true });
        element.checkAll = checked?.length == element?.data?.length ? true : false;
      });
    }
    else if (type == ComponentConstant.DIMMS) {
      this.dimmList.forEach(element => {

        let checked = element.data.filter(row => { return row?.supportCheck == true });
        element.checkAll = checked?.length == element?.data?.length ? true : false;
      });
    }
    else if (type == ComponentConstant.POWERSUPPLY) {
      let checked = this.PSList.filter(row => { return row?.supportCheck == true });
      this.checkboxSelectAll = checked?.length == this.PSList?.length ? true : false;
    }
    else if (type == ComponentConstant.IOES) {
      let checked = this.IOCardsList?.ioes?.filter(row => { return row?.supportCheck == true });
      this.checkboxSelectAll = checked?.length == this.IOCardsList?.ioes?.length ? true : false;
    }
    else if (type == ComponentConstant.STORAGES) {
      let checked = this.storagesList.filter(row => { return row?.supportCheck == true });
      this.checkboxSelectAll = checked?.length == this.storagesList?.length ? true : false;
    }
    else if (type == ComponentConstant.IOMS) {
      let checked = this.iomsList?.ioms?.filter(row => { return row?.supportCheck == true });
      this.checkboxSelectAll = checked?.length == this.iomsList?.ioms?.length ? true : false;
    }
    else if (type == PlatformConstant.BLADE) {
      let checked = this.bladeList.filter(row => { return row?.supportCheck == true });
      this.checkboxSelectAll = checked?.length == this.bladeList?.length ? true : false;
    }
    else if (type == PlatformConstant.NODE) {
      let checked = this.nodeList.filter(row => { return row?.supportCheck == true });
      this.checkboxSelectAll = checked?.length == this.nodeList?.length ? true : false;
    }
    else if (type == ComponentConstant.EXPANSIONS) {
      let checked = this.expansionList.expansions.filter(row => { return row?.supportCheck == true });
      this.checkboxSelectAll = checked?.length == this.expansionList.expansions?.length ? true : false;
    }
    else {
      let checked = this.additionalComponentsList.filter(row => { return row?.supportCheck == true });
      this.checkboxSelectAll = checked?.length == this.additionalComponentsList?.length ? true : false;
    }
  }
  config = {
    displayKey: "viewValue",
    search: false,
    height: '200px',
    placeholder: 'Select Option',
    moreText: 'more',
    noResultsFound: 'No results found!',
    searchPlaceholder: 'Search',
    searchOnKey: 'viewValue',
    clearOnSelection: false,
    inputDirection: 'ltr'
  }

  // checkedHandlerFan(e,r){
  //   
  //   if(e.target.checked)
  //     this.selectedFanPolicy = r;
  //   else
  //   this.selectedFanPolicy = null
  // }

  handleChange(e: any) {
    this.formChanged = true;
  }

  onSearchStringChange(e: any) {
    if (e?.length == 0) {
      this.showCheckALL(this.selectedType.value);
      this.selectedSeriesId = [];
    }
    else {
      this.checkboxSelectAll = false;
      if (this.selectedType.value == 'cpus') {
        for (let i = 0; i < this.cpulist.length; i++) {
          let ind = this.selectedSeriesId.indexOf(i);
          if (!(ind >= 0)) {
            this.selectedSeriesId.push(i);
          }
        }
      } else if (this.selectedType.value == 'dimms') {
        for (let i = 0; i < this.dimmList.length; i++) {
          let ind = this.selectedSeriesId.indexOf(i);
          if (!(ind >= 0)) {
            this.selectedSeriesId.push(i);
          }
        }
      }

    }


  }
  manageComponentScrollUp(): void {
    setTimeout(() => this.manageComponent.nativeElement.scrollIntoView({ behavior: 'smooth', block: "end" }));
  }
}
