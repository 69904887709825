<div class="container project-results-container">
    <div class="row project-results-row">
        <div class="col-md-12 result-div row">
            <div class="col-10">
                <h5> {{'PROJECT_POWER_SUMMARY' | translate}}</h5>
            </div>
            <div class="col-1"></div>
            <div class="col-1">
                <a style="vertical-align: middle;margin-left: 15px;  color: gray" data-icon="icon-info-outline"
                    [attr.data-balloon]="info" data-balloon-pos="up-right" data-balloon-length="large">
                    <span class="icon-info-outline icon-size-18"></span>
                </a>
            </div>

        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <ul class="accordion" style="margin-top: 10px;">
                <li [ngClass]="selectedClassId == 1 ? 'active' : ''">
                    <a class="accordion__title" (click)="onClassSelect(1)">
                        <span class="accordion__toggle icon-chevron-down icon-size-20 icon-margin"
                            style="margin-top: -7px;"></span>
                        <h5>{{'COMPUTE' | translate}}</h5>
                    </a>
                    <div class="accordion__content">

                        <!-- adding a dynamic list -->
                        <ul class="accordion" style="margin-top: 5px"
                            *ngFor="let seriesName of seriesNamesSet; let k = index">
                            <li [ngClass]="selectedSeriesId == 0 && selectedSeriesNameIdx == k ? 'active' : ''"
                                *ngIf="filterList(projectData.chassisList, seriesName).length > 0">
                                <a class="accordion__title accor-title" (click)="onSeriesSelect(0, k)"
                                    style="margin-left: 2px;">
                                    <span
                                        [ngClass]="selectedSeriesId==0 && selectedSeriesNameIdx == k ? 'accordion__toggle icon-chevron-down  icon-margin' : 'accordion__toggle icon-chevron-up icon-margin'"
                                        style="margin-top: -7px;"></span>
                                    <h5>{{ seriesName | translate}}</h5>
                                </a>
                                <div *ngIf="selectedSeriesId==0 && selectedSeriesNameIdx == k"
                                    class="accordion__content accordion_contennt">
                                    <ul class="accordion" style="margin-top: 10px;">
                                        <li
                                            *ngFor="let chassis of filterList(projectData.chassisList, seriesName); let b = index">
                                            <div class="form-group form-group--inline">
                                                <div class="col-md-9" style="padding-left:2px">
                                                    <a class="accordion__title accordian-server-name"
                                                        (click)="onServerSelect('B',b,k,chassis)" id="chassis{{b}}">
                                                        <span class="toggle-icon" style="margin-right: 0px;"
                                                            [ngClass]="selectedServerId.includes('B'+b+k) ? 'accordion__toggle icon-chevron-down' : 'accordion__toggle icon-chevron-up'"></span>

                                                        <span *ngIf="doesServerHaveRedundancyError(chassis)"
                                                            class="icon-warning redundancy-warning"></span>

                                                        <div
                                                            *ngIf="chassis.serverId ==editServerElementName; then editTheServer  else showTheServer">
                                                        </div>
                                                        <ng-template #showTheServer><span
                                                                style="margin-left:1px;white-space: normal;"
                                                                [ngClass]="doesServerHaveRedundancyError(chassis) ? 'redundancy-warning-error' : ''">{{chassis?.userProvidedName}}</span>
                                                            <span style="margin-left: 5px;
                                                    font-size: x-small; color: grey;
                                                    margin-top: -5px;">({{chassis?.displayName}})</span>
                                                        </ng-template>
                                                        <ng-template #editTheServer>
                                                            <div class="form-group__text col-md-6">
                                                                <input id="input-server{{b}}" type="text"
                                                                    style="width: 600px;"
                                                                    [(ngModel)]="chassis.userProvidedName"
                                                                    (blur)="blurServer($event, chassis.id ,'bseries',chassis?.serverId,'')"
                                                                    (change)="changeServerName($event,chassis.id,'bseries', chassis?.serverId)">

                                                            </div>
                                                        </ng-template>
                                                    </a>
                                                </div>
                                                <div class="form-group__text col-md-3"
                                                    style="padding-top: 4px; padding-right:0; padding-left: 0; margin-left:-5px;"
                                                    [attr.disabled]="(isServerEdited && !isCurrentServer(chassis)) ? true : null"
                                                    *ngIf="chassis.serverId !==editServerElementName">
                                                    <input id="input-quantity{{b}}" type="number"
                                                        style="width: 30px; padding: 2px 2px;"
                                                        [(ngModel)]="chassis.config.quantity" min="1" autocomplete="off"
                                                        [ngClass]="chassis.config?.flag ? 'select-danger': 'select-normal'"
                                                        (change)="changeServerQuantity($event,chassis.id,'bseries', chassis?.serverId)">

                                                    <a *ngIf="chassis.serverId !==editServerElementName"><span
                                                            class="icon-edit icon-size-16"
                                                            style="margin: 5px;color:#00bceb"
                                                            (click)="editServer(chassis.id, 'bseries',chassis?.serverId)"></span></a>
                                                    <a *ngIf="chassis.serverId !==editServerElementName"> <span
                                                            class="icon-delete icon-size-16 icon-delete"
                                                            style="margin: 5px;color:#e2231a;"
                                                            (click)="deleteChasssisOpenModal(chassis.id, 'bseries', chassis?.serverId)"></span></a>
                                                </div>
                                            </div>
                                            <div class="accordion__content"
                                                [ngClass]="selectedServerId.includes('B'+b+k) ? 'li-active' : 'li-inactive'">
                                                <a class="add-blade"
                                                    *ngIf="chassis.level == 'Parent' && chassis.hasChildren == true"
                                                    [ngClass]="chassis.level == 'Parent' && chassis.hasChildren == true && !isBladeQtyLimitReached && getTotalSlotsConsumed(chassis.blades) < chassis.maxBladeSlots && !isServerEdited ? 'anchor-active' : 'anchor-inactive'"
                                                    (click)="addChassis(chassis.pid,chassis.config.powerSupplyId,chassis.config.powerSupplyCount, chassis.id,chassis?.config?.inputVoltageId,'blade', chassis.serverId, chassis.maxBladeSlots - getTotalSlotsConsumed(chassis.blades))">
                                                    <span class="icon-add icon-size-12"
                                                        style="margin-right: 3px;margin-left:13px;"></span>{{'ADD_ADDITIONAL_CHASSIS_CONFIG'
                                                    | translate}}
                                                </a>
                                                <div class="power-config-div row">
                                                    <p class="power-config-header col-xs-12 col-md-12">{{'CONFIGURATION'
                                                        |
                                                        translate}}</p>
                                                </div>
                                                <div class="power-supply-container">
                                                    <div class="configure-container col-md-12 base-margin-bottom">
                                                        <div class="col-xs-9 col-md-9 estimate-label">{{'INPUT_VOLTAGE'
                                                            |translate}}
                                                        </div>
                                                        <div class="col-xs-3 col-md-3 estimate-value input-voltage">
                                                            {{chassis?.config?.voltageName}}</div>
                                                    </div>
                                                    <div class="configure-container col-md-12 base-margin-bottom">
                                                        <div class="col-xs-10 col-md-10 estimate-label">
                                                            {{'REDUNDANCY_MODE'
                                                            |translate}}
                                                        </div>
                                                        <div class="col-xs-2 col-md-2 estimate-value redundancy-mode">
                                                            {{displayFormattedRMode(chassis?.config?.redundancyMode)}}
                                                        </div>
                                                    </div>
                                                    <div class="configure-container col-md-12">
                                                        <div class="col-xs-10 col-md-10 estimate-label">{{'POWER_SUPPLY'
                                                            |translate}}
                                                        </div>
                                                        <div class="col-xs-2 col-md-2 estimate-value">
                                                            {{chassis?.config?.powerSupplyCount}}</div>
                                                    </div>
                                                    <div>
                                                        <p class="power-supply-value"><span
                                                                class="icon-margin">▪</span>{{chassis?.config?.powerSupplyTypeName}}
                                                        </p>
                                                    </div>
                                                    <div class="configure-container col-md-12">
                                                        <div class="col-xs-10 col-md-10 estimate-label">{{'IO_MODULE'
                                                            |translate}}</div>
                                                        <div class="col-xs-2 col-md-2 estimate-value">
                                                            {{chassis?.config?.iomCount}}
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <p class="power-supply-value"><span
                                                                class="icon-margin">▪</span>{{chassis?.config?.ioModuleTypeName}}
                                                        </p>
                                                    </div>
                                                    <!-- additional dynamic components -->
                                                    <div>
                                                        <div
                                                            *ngFor="let additionalComp of getAdditionalComponents(chassis)">
                                                            <div class="configure-container col-md-12"
                                                                *ngIf="additionalComp.length > 0">
                                                                <div class="col-xs-10 col-md-10">
                                                                    {{additionalComp[0].label | translate}}</div>
                                                                <div class="col-xs-2 col-md-2">{{
                                                                    getTotalQuantity(additionalComp) }} </div>
                                                            </div>
                                                            <div class="dependent" *ngFor="let comp of additionalComp">
                                                                <div class="col-xs-10 col-md-10 dependent-label"><span
                                                                        class="icon-margin">▪</span>{{comp?.configuration
                                                                    |translate}}
                                                                </div>
                                                                <div class="col-xs-2 col-md-2 dependent-value">
                                                                    {{comp?.quantity}}</div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div class="server-estimate-div">
                                                    <div class="power-estimate-div row">
                                                        <p class="power-estimate-header col-xs-12 col-md-12">
                                                            {{'POWER_ESTIMATE' |
                                                            translate}}</p>
                                                    </div>
                                                    <div class="row estimate-row">
                                                        <div class="col-xs-7 col-md-7 estimate-label">
                                                            {{'MAX_INPUT_POWER'
                                                            |translate}}</div>
                                                        <div class="col-md-3 col-xs-3 estimate-value">
                                                            {{chassis?.power?.maxInputPower
                                                            |number:'1.2-2'}}</div>
                                                        <div class="col-xs-2 col-md-2 estimate-value">
                                                            {{chassis?.power?.imperial?.powerUnit}}</div>
                                                    </div>
                                                    <div class="row estimate-row">
                                                        <div class="col-xs-7 col-md-7 estimate-label">{{'INPUT_POWER'
                                                            |translate}}
                                                        </div>
                                                        <div class="col-md-3 col-xs-3 estimate-value">
                                                            {{chassis?.power?.inputPower
                                                            |number:'1.2-2'}}</div>
                                                        <div class="col-xs-2 col-md-2 estimate-value">
                                                            {{chassis?.power?.imperial?.powerUnit}}</div>
                                                    </div>
                                                    <div class="row estimate-row">
                                                        <div class="col-xs-7 col-md-7 estimate-label">
                                                            {{'IDLE_INPUT_POWER'
                                                            |translate}}</div>
                                                        <div class="col-md-3 col-xs-3 estimate-value">
                                                            {{chassis?.power?.idleInputPower |number:'1.2-2'}}</div>
                                                        <div class="col-xs-2 col-md-2 estimate-value">
                                                            {{chassis?.power?.imperial?.powerUnit}}</div>
                                                    </div>
                                                    <div class="row estimate-row">
                                                        <div class="col-xs-7 col-md-7 estimate-label">
                                                            {{'SYSTEM_WORKLOAD'
                                                            |translate}}</div>
                                                        <div class="col-md-3 col-xs-3 estimate-value">
                                                            {{chassis?.power?.systemWorkloadPercent
                                                            |number:'1.2-2'}}</div>
                                                        <div class="col-xs-2 col-md-2 estimate-value">
                                                            {{chassis?.power?.imperial?.systemWorkload}}</div>
                                                    </div>
                                                    <div class="row estimate-row">
                                                        <div class="col-xs-7 col-md-7 estimate-label">{{'INPUT_CURRENT'
                                                            |translate}}
                                                        </div>
                                                        <div class="col-md-3 col-xs-3 estimate-value">
                                                            {{chassis?.power?.inputCurrentLoad |number:'1.2-2'}}</div>
                                                        <div class="col-xs-2 col-md-2 estimate-value">
                                                            {{chassis?.power?.imperial?.currentUnit}}</div>
                                                    </div>
                                                    <div class="row estimate-row"
                                                        *ngIf="chassis?.power?.legacyPlatform">
                                                        <div class="col-xs-7 col-md-7 estimate-label">{{'AIR_FLOW'
                                                            |translate}}
                                                        </div>
                                                        <div class="col-md-3 col-xs-3 estimate-value"
                                                            *ngIf="(globalUnit == 'Imperial')">
                                                            {{chassis?.power?.inputAirflow?.airflowImperial
                                                            |number:'1.2-2'}} </div>
                                                        <div class="col-md-3 col-xs-3 estimate-value"
                                                            *ngIf="(globalUnit == 'Metric')">
                                                            {{chassis?.power?.inputAirflow?.airflowMetric
                                                            |number:'1.2-2'}} </div>
                                                        <div class="col-xs-2 col-md-2 estimate-value"
                                                            *ngIf="(globalUnit == 'Imperial')">
                                                            {{chassis?.power?.imperial?.airflowUnit}}</div>
                                                        <div class="col-xs-2 col-md-2 estimate-value"
                                                            *ngIf="(globalUnit == 'Metric')">
                                                            {{chassis?.power?.metric?.airflowUnit}}</div>
                                                    </div>
                                                    <div class="row estimate-row"
                                                        *ngIf="chassis?.power?.legacyPlatform == false">
                                                        <div class="col-md-7 estimate-label">{{'AIR_FLOW_MIN'
                                                            |translate}}</div>
                                                        <div class="col-md-3 estimate-value"
                                                            *ngIf="(globalUnit == 'Imperial')">
                                                            {{chassis?.power?.airFlowRange?.minAirflowImperial | number
                                                            :
                                                            '1.2-2'}}</div>
                                                        <div class="col-md-3 estimate-value"
                                                            *ngIf="(globalUnit == 'Metric')">
                                                            {{chassis?.power?.airFlowRange?.minAirflowMetric | number :
                                                            '1.2-2'}}</div>
                                                        <div class="col-xs-2 col-md-2 estimate-value"
                                                            *ngIf="(globalUnit == 'Imperial')">
                                                            {{chassis?.power?.imperial?.airflowUnit}}</div>
                                                        <div class="col-xs-2 col-md-2 estimate-value"
                                                            *ngIf="(globalUnit == 'Metric')">
                                                            {{chassis?.power?.metric?.airflowUnit}}</div>
                                                    </div>
                                                    <div class="row estimate-row"
                                                        *ngIf="chassis?.power?.legacyPlatform == false">
                                                        <div class="col-md-7 estimate-label">{{'AIR_FLOW_MAX'
                                                            |translate}}</div>
                                                        <div class="col-md-3 estimate-value"
                                                            *ngIf="(globalUnit == 'Imperial')">
                                                            {{chassis?.power?.airFlowRange?.maxAirflowImperial | number
                                                            :
                                                            '1.2-2'}}</div>
                                                        <div class="col-md-3 estimate-value"
                                                            *ngIf="(globalUnit == 'Metric')">
                                                            {{chassis?.power?.airFlowRange?.maxAirflowMetric | number :
                                                            '1.2-2'}}</div>
                                                        <div class="col-xs-2 col-md-2 estimate-value"
                                                            *ngIf="(globalUnit == 'Imperial')">
                                                            {{chassis?.power?.imperial?.airflowUnit}}</div>
                                                        <div class="col-xs-2 col-md-2 estimate-value"
                                                            *ngIf="(globalUnit == 'Metric')">
                                                            {{chassis?.power?.metric?.airflowUnit}}</div>
                                                    </div>
                                                    <div class="row estimate-row"
                                                        *ngIf="chassis?.power?.legacyPlatform == false">
                                                        <div class="col-md-7 estimate-label">{{'ACOUSTICS_MIN'
                                                            |translate}}</div>
                                                        <div class="col-md-3 estimate-value">
                                                            {{chassis?.power?.acousticsRange?.minAcoustics | number :
                                                            '1.2-2'}}</div>
                                                        <div class="col-xs-1 col-md-2 estimate-value">
                                                            {{chassis?.power?.imperial?.acousticsUnit}}</div>
                                                    </div>
                                                    <div class="row estimate-row"
                                                        *ngIf="chassis?.power?.legacyPlatform == false">
                                                        <div class="col-md-7 estimate-label">{{'ACOUSTICS_MAX'
                                                            |translate}}</div>
                                                        <div class="col-md-3 estimate-value">
                                                            {{chassis?.power?.acousticsRange?.maxAcoustics | number :
                                                            '1.2-2'}}</div>
                                                        <div class="col-xs-1 col-md-2 estimate-value">
                                                            {{chassis?.power?.imperial?.acousticsUnit}}</div>
                                                    </div>
                                                    <div class="row estimate-row">
                                                        <div class="col-xs-7 col-md-7 estimate-label">{{'COOLING'
                                                            |translate}}</div>
                                                        <div class="col-md-3 col-xs-3 estimate-value"
                                                            *ngIf="(globalUnit == 'Imperial')">
                                                            {{chassis?.power?.inputCooling?.imperialCooling
                                                            |number:'1.2-2'}}</div>
                                                        <div class="col-md-3 col-xs-3 estimate-value"
                                                            *ngIf="(globalUnit == 'Metric')">
                                                            {{chassis?.power?.inputCooling?.imperialCooling
                                                            |number:'1.2-2'}}</div>
                                                        <div class="col-xs-2 col-md-2 estimate-value"
                                                            *ngIf="(globalUnit == 'Imperial')">
                                                            {{chassis?.power?.imperial?.coolingUnit}}</div>
                                                        <div class="col-xs-2 col-md-2 estimate-value"
                                                            *ngIf="(globalUnit == 'Metric')">
                                                            {{chassis?.power?.imperial?.coolingUnit}}</div>
                                                    </div>
                                                    <div class="row estimate-row">
                                                        <div class="col-xs-7 col-md-7 estimate-label">{{'WEIGHT'
                                                            |translate}}</div>
                                                        <div class="col-md-3 col-xs-3 estimate-value"
                                                            *ngIf="(globalUnit == 'Imperial')">
                                                            {{chassis?.power?.weightImperial |number:'1.2-2'}}</div>
                                                        <div class="col-md-3 col-xs-3 estimate-value"
                                                            *ngIf="(globalUnit == 'Metric')">
                                                            {{chassis?.power?.weightMetric |number:'1.2-2'}}</div>
                                                        <div class="col-xs-2 col-md-2 estimate-value"
                                                            *ngIf="(globalUnit == 'Imperial')">
                                                            {{chassis?.power?.imperial?.weightUnit}}</div>
                                                        <div class="col-xs-2 col-md-2 estimate-value"
                                                            *ngIf="(globalUnit == 'Metric')">
                                                            {{chassis?.power?.metric?.weightUnit}}</div>
                                                        <!-- <ng-template #elseBlock>{{server?.metric?.weightMetric}}</ng-template> -->
                                                    </div>
                                                </div>
                                            </div>

                                            <ul class="accordion" style="margin-left:20px;">
                                                <li *ngFor="let blade of chassis.blades; let k = index">
                                                    <div class="form-group form-group--inline">
                                                        <div class="col-md-9">
                                                            <a class="accordion__title accor-title accordian-server-name"
                                                                (click)="onBladeSelect(k, b)" id="blade{{k}}">

                                                                <span class="accordion__toggle icon-margin"
                                                                    [ngClass]="selectedBladeId == (k+','+b) ? 'accordion__toggle icon-chevron-down' : 'accordion__toggle icon-chevron-up'"
                                                                    style="margin-right:2px"></span>
                                                                <div
                                                                    *ngIf="blade.serverId ==editChildServerName; then editChildServer  else showTheChildServer">
                                                                </div>
                                                                <ng-template #showTheChildServer>
                                                                    <span>{{blade?.userProvidedName}}</span><span style="margin-left: 10px;
                                                                    font-size: x-small; color: grey;
                                                                    margin-top: -2px;">({{blade?.displayName}})</span>
                                                                </ng-template>
                                                                <ng-template #editChildServer>
                                                                    <div class="form-group__text col-md-6">
                                                                        <input id="input-server" type="text"
                                                                            style="width: 600px;"
                                                                            [(ngModel)]="blade.userProvidedName"
                                                                            (blur)="blurServer($event, blade.id,'blade', blade.serverId, chassis.id)"
                                                                            (change)="changeBladeName($event, blade.serverId, blade.id, chassis.id )">
                                                                    </div>
                                                                </ng-template>
                                                            </a>
                                                        </div>
                                                        <div class="form-group__text col-md-3"
                                                            style="padding-top: 4px; padding-right:0; padding-left: 0; margin-left:-5px;"
                                                            [attr.disabled]="(isServerEdited && !isCurrentServer(blade)) ? true : null"
                                                            *ngIf="blade.serverId !==editChildServerName">
                                                            <input id="input-quantity{{k}}" type="number"
                                                                style="width: 30px; padding: 2px 2px;"
                                                                [ngModel]="blade.config.quantity" min="1"
                                                                [ngClass]="getTotalSlotsConsumed(chassis.blades) <= chassis.maxBladeSlots  && !blade.config.flag && blade.config.quantity >=0 ?  'select-normal' : 'select-danger'"
                                                                (change)="changeBladeQuantity($event,blade.serverId, chassis.id,blade.type)">
                                                            <!-- <span>{{lodash.sumBy(chassis.blades, blade=>Number(blade.config.quantity))}}</span> -->
                                                            <a *ngIf="blade.serverId !==editChildServerName"><span
                                                                    class="icon-edit icon-size-16"
                                                                    style="margin: 5px;color:#00bceb"
                                                                    (click)="editBladeServer(blade.serverId, blade.id, blade.type, chassis.id, chassis?.config?.inputVoltageId,chassis.config.powerSupplyId,chassis.config.powerSupplyCount )"></span></a>
                                                            <a *ngIf="blade.serverId !==editChildServerName"> <span
                                                                    class="icon-delete icon-size-16 icon-delete"
                                                                    style="margin: 5px;color:#e2231a;"
                                                                    (click)="deleteServerOpenModal(blade.serverId, blade.type, chassis.id, chassis)"></span></a>
                                                        </div>
                                                    </div>
                                                    <div class="accordion__content"
                                                        [ngClass]="selectedBladeId == (k+','+b) ? 'li-active' : 'li-inactive'">
                                                        <div class="power-config-div row">
                                                            <p class="power-config-header col-xs-12 col-md-12">
                                                                {{'CONFIGURATION' |
                                                                translate}}</p>
                                                        </div>
                                                        <div class="power-supply-container">

                                                            <div class="configure-container col-md-12">
                                                                <div class="col-xs-10 col-md-10 estimate-label">
                                                                    {{'PROCESSOR'
                                                                    |translate}}
                                                                </div>
                                                                <div class="col-xs-2 col-md-2 estimate-value">
                                                                    {{blade?.config?.processorCount}}</div>
                                                            </div>
                                                            <div>
                                                                <p class="power-supply-value"><span
                                                                        class="icon-margin">▪</span>{{blade?.config?.processorTypeName}}
                                                                </p>
                                                            </div>
                                                            <div class="configure-container col-md-12">
                                                                <div class="col-xs-10 col-md-10 estimate-label">
                                                                    {{'MEMORY'
                                                                    |translate}}
                                                                </div>
                                                                <div class="col-xs-2 col-md-2 estimate-value">
                                                                    {{blade?.config?.memoryQuantity}}</div>
                                                            </div>
                                                            <div *ngIf="blade.type == 'BLADE'">
                                                                <div *ngFor="let memory of blade?.config?.memory"
                                                                    class="dependent">
                                                                    <div class="col-xs-10 col-md-10 dependent-label">
                                                                        <span
                                                                            class="icon-margin">▪</span>{{memory?.memoryTypeName
                                                                        |translate}}
                                                                    </div>
                                                                    <div class="col-xs-2 col-md-2 dependent-value">
                                                                        {{memory?.memoryCount}}</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="configure-container col-md-12"
                                                            *ngIf="blade?.config?.dedicatedStorageQuantity && blade?.config?.dedicatedStorageQuantity > 0">
                                                            <div class="col-xs-10 col-md-10 estimate-label">
                                                                {{'STORAGE_CONTROLLER'
                                                                |translate}}
                                                            </div>
                                                            <div class="col-xs-2 col-md-2 estimate-value">
                                                                {{blade?.config?.dedicatedStorageQuantity}}</div>
                                                        </div>
                                                        <div *ngFor="let ds of blade?.config?.dedicatedStorage"
                                                            class="dependent">
                                                            <div class="col-xs-10 col-md-10 dependent-label"><span
                                                                    class="icon-margin">▪</span>{{ds?.mezzanineControllerTypeName
                                                                |translate}}
                                                            </div>
                                                            <div class="col-xs-2 col-md-2 dependent-value">
                                                                {{ds?.mezzanineControllerCount}}</div>
                                                        </div>

                                                        <div class="configure-container col-md-12"
                                                            *ngIf="blade?.config?.adapterQuantity && blade?.config?.adapterQuantity > 0">
                                                            <div class="col-xs-10 col-md-10 estimate-label">{{'ADAPTER'
                                                                |translate}}
                                                            </div>
                                                            <div class="col-xs-2 col-md-2 estimate-value">
                                                                {{blade?.config?.adapterQuantity}}</div>
                                                        </div>
                                                        <div>
                                                            <div *ngFor="let adapter of blade?.config?.adaptors"
                                                                class="dependent">
                                                                <div class="col-xs-10 col-md-10 dependent-label"><span
                                                                        class="icon-margin">▪</span>{{adapter?.adaptorTypeName
                                                                    |translate}}
                                                                </div>
                                                                <div class="col-xs-2 col-md-2 dependent-value">
                                                                    {{adapter?.adaptorCount}}</div>
                                                            </div>
                                                        </div>
                                                        <div class="configure-container col-md-12"
                                                            *ngIf="blade?.config?.storageQuantity && blade?.config?.storageQuantity > 0">
                                                            <div class="col-xs-10 col-md-10 estimate-label">
                                                                {{'STORAGE' |translate}}
                                                            </div>
                                                            <div class="col-xs-2 col-md-2 estimate-value">
                                                                {{blade?.config?.storageQuantity}}</div>
                                                        </div>
                                                        <div>
                                                            <div *ngFor="let storage of blade?.config?.storage"
                                                                class="dependent">
                                                                <div class="col-xs-10 col-md-10 dependent-label"><span
                                                                        class="icon-margin">▪</span>{{storage?.storageTypeName
                                                                    |translate}}
                                                                </div>
                                                                <div class="col-xs-2 col-md-2 dependent-value">
                                                                    {{storage?.storageCount}}</div>
                                                            </div>
                                                        </div>
                                                        <!-- additional dynamic components -->
                                                        <div>
                                                            <div
                                                                *ngFor="let additionalComp of getAdditionalComponents(blade)">
                                                                <div class="configure-container col-md-12"
                                                                    *ngIf="additionalComp.length > 0">
                                                                    <div class="col-xs-10 col-md-10">
                                                                        {{additionalComp[0].label | translate }}</div>
                                                                    <div class="col-xs-2 col-md-2">{{
                                                                        getTotalQuantity(additionalComp) }} </div>
                                                                </div>
                                                                <div class="dependent"
                                                                    *ngFor="let comp of additionalComp">
                                                                    <div class="col-xs-10 col-md-10 dependent-label">
                                                                        <span
                                                                            class="icon-margin">▪</span>{{comp?.configuration
                                                                        |translate}}
                                                                    </div>
                                                                    <div class="col-xs-2 col-md-2 dependent-value">
                                                                        {{comp?.quantity}}</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </li>

                                    </ul>
                                </div>
                            </li>
                            <li [ngClass]="selectedSeriesId == 2 && selectedSeriesNameIdx == k ? 'active' : ''"
                                *ngIf="filterList(projectData.rackServers, seriesName).length > 0">
                                <a class="accordion__title accor-title" (click)="onSeriesSelect(2, k)"
                                    style="margin-left: 2px;">
                                    <span
                                        [ngClass]="selectedSeriesId==2 && selectedSeriesNameIdx == k ? 'accordion__toggle icon-chevron-down  icon-margin' : 'accordion__toggle icon-chevron-up icon-margin'"
                                        style="margin-top: -7px;"></span>
                                    <h5>{{ seriesName | translate}}</h5>
                                </a>
                                <div *ngIf="selectedSeriesId==2 && selectedSeriesNameIdx == k"
                                    class="accordion__content">
                                    <ul class="accordion" style="margin-top: 10px;">
                                        <li
                                            *ngFor="let rack of filterList(projectData.rackServers, seriesName); let c = index">
                                            <div class="form-group form-group--inline">
                                                <div class="col-md-9" style="padding-left:2px">
                                                    <a class="accordion__title accordian-server-name"
                                                        (click)="onServerSelect('C',c,k,rack)" id="rack{{c}}">

                                                        <span class="toggle-icon" style="margin-right: 0px;"
                                                            [ngClass]="selectedServerId.includes('C'+c+k) ? 'accordion__toggle icon-chevron-down' : 'accordion__toggle icon-chevron-up'"></span>
                                                        <span *ngIf="doesServerHaveRedundancyError(rack)"
                                                            class="icon-warning redundancy-warning"></span>
                                                        <div
                                                            *ngIf="rack.serverId ==editServerElementName; then editTheServer  else showTheServer">
                                                        </div>
                                                        <ng-template #showTheServer><span
                                                                style="margin-left:1px;white-space: normal;"
                                                                [ngClass]="doesServerHaveRedundancyError(rack) ? 'redundancy-warning-error' : ''">{{rack?.userProvidedName}}</span>
                                                            <span style="margin-left: 10px;
                                                        font-size: x-small; color: grey;
                                                        margin-top: -5px;">({{rack?.displayName}})</span>
                                                        </ng-template>
                                                        <ng-template #editTheServer>
                                                            <div class="form-group__text col-md-6">
                                                                <input id="input-server{{c}}" type="text"
                                                                    style="width: 600px;"
                                                                    [(ngModel)]="rack.userProvidedName"
                                                                    (blur)="blurServer($event, rack.id, 'cseries',rack.serverId, '')"
                                                                    (change)="changeServerName($event,rack.id,'cseries', rack?.serverId)">

                                                            </div>
                                                        </ng-template>
                                                    </a>
                                                </div>
                                                <div class="form-group__text col-md-3"
                                                    style="padding-top: 4px; padding-right:0; padding-left: 0; margin-left:-5px;"
                                                    [attr.disabled]="(isServerEdited && !isCurrentServer(rack)) ? true : null"
                                                    *ngIf="rack.serverId !==editServerElementName">
                                                    <input id="input-quantity{{c}}" type="number"
                                                        style="width: 30px; padding: 2px 2px;"
                                                        [(ngModel)]="rack.config.quantity" min="1" autocomplete="off"
                                                        [ngClass]="rack.config?.flag ? 'select-danger': 'select-normal'"
                                                        (change)="changeServerQuantity($event,rack.id ,'cseries', rack.serverId)">

                                                    <a *ngIf="rack.serverId !==editServerElementName"><span
                                                            class="icon-edit icon-size-16"
                                                            style="margin: 5px;color:#00bceb"
                                                            (click)="editServer(rack.id, 'cseries', rack.serverId)"></span></a>
                                                    <a *ngIf="rack.serverId !==editServerElementName"> <span
                                                            class="icon-delete icon-size-16 icon-delete"
                                                            style="margin: 5px;color:#e2231a;"
                                                            (click)="deleteChasssisOpenModal(rack.id, 'cseries', rack.serverId)"></span></a>
                                                </div>
                                            </div>
                                            <div class="accordion__content"
                                                [ngClass]="selectedServerId.includes('C'+c+k) ? 'li-active' : 'li-inactive'">
                                                <div class="power-config-div row">
                                                    <p class="power-config-header col-xs-12 col-md-12">{{'CONFIGURATION'
                                                        |
                                                        translate}}</p>
                                                </div>
                                                <div class="power-supply-container">
                                                    <div class="configure-container col-md-12 base-margin-bottom">
                                                        <div class="col-xs-9 col-md-9 estimate-label">{{'INPUT_VOLTAGE'
                                                            |translate}}
                                                        </div>
                                                        <div class="col-xs-3 col-md-3 estimate-value input-voltage">
                                                            {{rack?.config?.voltageName}}</div>
                                                    </div>
                                                    <div class="configure-container col-md-12 base-margin-bottom">
                                                        <div class="col-xs-10 col-md-10 estimate-label">
                                                            {{'REDUNDANCY_MODE'
                                                            |translate}}
                                                        </div>
                                                        <div class="col-xs-2 col-md-2 estimate-value redundancy-mode">
                                                            {{displayFormattedRMode(rack?.config?.redundancyMode)}}
                                                        </div>
                                                    </div>
                                                    <div class="configure-container col-md-12">
                                                        <div class="col-xs-10 col-md-10 estimate-label">{{'PROCESSOR'
                                                            |translate}}
                                                        </div>
                                                        <div class="col-xs-2 col-md-2 estimate-value">
                                                            {{rack?.config?.processorCount}}</div>
                                                    </div>
                                                    <div>
                                                        <p class="power-supply-value"><span
                                                                class="icon-margin">▪</span>{{rack?.config?.processorTypeName}}
                                                        </p>
                                                    </div>
                                                    <div class="configure-container col-md-12">
                                                        <div class="col-xs-10 col-md-10 estimate-label">{{'MEMORY'
                                                            |translate}}
                                                        </div>
                                                        <div class="col-xs-2 col-md-2 estimate-value">
                                                            {{rack?.config?.memoryQuantity}}</div>
                                                    </div>
                                                    <div>
                                                        <div *ngFor="let memory of rack?.config?.memory"
                                                            class="dependent">
                                                            <div class="col-xs-10 col-md-10 dependent-label"><span
                                                                    class="icon-margin">▪</span>{{memory?.memoryTypeName
                                                                |translate}}
                                                            </div>
                                                            <div class="col-xs-2 col-md-2 dependent-value">
                                                                {{memory?.memoryCount}}</div>
                                                        </div>
                                                    </div>
                                                    <div class="configure-container col-md-12">
                                                        <div class="col-xs-10 col-md-10 estimate-label">{{'POWER_SUPPLY'
                                                            |translate}}
                                                        </div>
                                                        <div class="col-xs-2 col-md-2 estimate-value">
                                                            {{rack?.config?.powerSupplyCount}}</div>
                                                    </div>
                                                    <div>
                                                        <p class="power-supply-value"><span
                                                                class="icon-margin">▪</span>{{rack?.config?.powerSupplyTypeName}}
                                                        </p>
                                                    </div>


                                                    <div class="configure-container col-md-12"
                                                        *ngIf="rack?.config?.dedicatedStorageQuantity && rack?.config?.dedicatedStorageQuantity > 0">
                                                        <div class="col-xs-10 col-md-10 estimate-label">
                                                            {{'STORAGE_CONTROLLER'
                                                            |translate}}
                                                        </div>
                                                        <div class="col-xs-2 col-md-2 estimate-value">
                                                            {{rack?.config?.dedicatedStorageQuantity}}</div>
                                                    </div>
                                                    <div *ngFor="let ds of rack?.config?.dedicatedStorage"
                                                        class="dependent">
                                                        <div class="col-xs-10 col-md-10 dependent-label"><span
                                                                class="icon-margin">▪</span>{{ds?.mezzanineControllerTypeName
                                                            |translate}}
                                                        </div>
                                                        <div class="col-xs-2 col-md-2 dependent-value">
                                                            {{ds?.mezzanineControllerCount}}</div>
                                                    </div>



                                                    <div class="configure-container col-md-12"
                                                        *ngIf="rack?.config?.adapterQuantity && rack?.config?.adapterQuantity > 0">
                                                        <div class="col-xs-10 col-md-10 estimate-label">{{'ADAPTER'
                                                            |translate}}
                                                        </div>
                                                        <div class="col-xs-2 col-md-2 estimate-value">
                                                            {{rack?.config?.adapterQuantity}}</div>
                                                    </div>




                                                    <div>
                                                        <div *ngFor="let adapter of rack?.config?.adaptors"
                                                            class="dependent">
                                                            <div class="col-xs-10 col-md-10 dependent-label"><span
                                                                    class="icon-margin">▪</span>{{adapter?.adaptorTypeName
                                                                |translate}}
                                                            </div>
                                                            <div class="col-xs-2 col-md-2 dependent-value">
                                                                {{adapter?.adaptorCount}}</div>
                                                        </div>
                                                    </div>
                                                    <div class="configure-container col-md-12"
                                                        *ngIf="rack?.config?.storageQuantity && rack?.config?.storageQuantity > 0">
                                                        <div class="col-xs-10 col-md-10 estimate-label">{{'STORAGE'
                                                            |translate}}
                                                        </div>
                                                        <div class="col-xs-2 col-md-2 estimate-value">
                                                            {{rack?.config?.storageQuantity}}</div>
                                                    </div>
                                                    <div>
                                                        <div *ngFor="let storage of rack?.config?.storage"
                                                            class="dependent">
                                                            <div class="col-xs-10 col-md-10 dependent-label"><span
                                                                    class="icon-margin">▪</span>{{storage?.storageTypeName
                                                                |translate}}
                                                            </div>
                                                            <div class="col-xs-2 col-md-2 dependent-value">
                                                                {{storage?.storageCount}}</div>
                                                        </div>
                                                    </div>

                                                    <!-- additional dynamic components -->
                                                    <div>
                                                        <div
                                                            *ngFor="let additionalComp of getAdditionalComponents(rack)">
                                                            <div class="configure-container col-md-12"
                                                                *ngIf="additionalComp.length > 0">
                                                                <div class="col-xs-10 col-md-10">
                                                                    {{additionalComp[0].label | translate }}</div>
                                                                <div class="col-xs-2 col-md-2">{{
                                                                    getTotalQuantity(additionalComp) }} </div>
                                                            </div>
                                                            <div class="dependent" *ngFor="let comp of additionalComp">
                                                                <div class="col-xs-10 col-md-10 dependent-label"><span
                                                                        class="icon-margin">▪</span>{{comp?.configuration
                                                                    |translate}}
                                                                </div>
                                                                <div class="col-xs-2 col-md-2 dependent-value">
                                                                    {{comp?.quantity}}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="server-estimate-div">
                                                    <div class="power-estimate-div row">
                                                        <p class="power-estimate-header col-xs-12 col-md-12">
                                                            {{'POWER_ESTIMATE' |
                                                            translate}}</p>
                                                    </div>
                                                    <div class="row estimate-row">
                                                        <div class="col-xs-7 col-md-7 estimate-label">
                                                            {{'MAX_INPUT_POWER'
                                                            |translate}}</div>
                                                        <div class="col-md-3 col-xs-3 estimate-value">
                                                            {{rack?.power?.maxInputPower|number:'1.2-2'}}</div>
                                                        <div class="col-xs-2 col-md-2 estimate-value">W</div>
                                                    </div>
                                                    <div class="row estimate-row">
                                                        <div class="col-xs-7 col-md-7 estimate-label">{{'INPUT_POWER'
                                                            |translate}}
                                                        </div>
                                                        <div class="col-md-3 col-xs-3 estimate-value">
                                                            {{rack?.power?.inputPower |number:'1.2-2'}}</div>
                                                        <div class="col-xs-2 col-md-2 estimate-value">
                                                            {{rack?.power?.imperial?.powerUnit}}</div>
                                                    </div>
                                                    <div class="row estimate-row">
                                                        <div class="col-xs-7 col-md-7 estimate-label">
                                                            {{'IDLE_INPUT_POWER'
                                                            |translate}}</div>
                                                        <div class="col-md-3 col-xs-3 estimate-value">
                                                            {{rack?.power?.idleInputPower |number:'1.2-2'}}</div>
                                                        <div class="col-xs-2 col-md-2 estimate-value">
                                                            {{rack?.power?.imperial?.powerUnit}}</div>
                                                    </div>
                                                    <div class="row estimate-row">
                                                        <div class="col-xs-7 col-md-7 estimate-label">
                                                            {{'SYSTEM_WORKLOAD'
                                                            |translate}}</div>
                                                        <div class="col-md-3 col-xs-3 estimate-value">
                                                            {{rack?.power?.systemWorkloadPercent
                                                            |number:'1.2-2'}}</div>
                                                        <div class="col-xs-2 col-md-2 estimate-value">
                                                            {{rack?.power?.imperial?.systemWorkload}}</div>
                                                    </div>
                                                    <div class="row estimate-row">
                                                        <div class="col-xs-7 col-md-7 estimate-label">{{'INPUT_CURRENT'
                                                            |translate}}
                                                        </div>
                                                        <div class="col-md-3 col-xs-3 estimate-value">
                                                            {{rack?.power?.inputCurrentLoad |number:'1.2-2'}}</div>
                                                        <div class="col-xs-2 col-md-2 estimate-value">
                                                            {{rack?.power?.imperial?.currentUnit}}</div>
                                                    </div>
                                                    <div class="row estimate-row" *ngIf="rack?.power?.legacyPlatform">
                                                        <div class="col-xs-7 col-md-7 estimate-label">{{'AIR_FLOW'
                                                            |translate}}
                                                        </div>
                                                        <div class="col-md-3 col-xs-3 estimate-value"
                                                            *ngIf="(globalUnit == 'Imperial')">
                                                            {{rack?.power?.inputAirflow?.airflowImperial
                                                            |number:'1.2-2'}} </div>
                                                        <div class="col-md-3 col-xs-3 estimate-value"
                                                            *ngIf="(globalUnit == 'Metric')">
                                                            {{rack?.power?.inputAirflow?.airflowMetric |number:'1.2-2'}}
                                                        </div>
                                                        <div class="col-xs-2 col-md-2 estimate-value"
                                                            *ngIf="(globalUnit == 'Imperial')">
                                                            {{rack?.power?.imperial?.airflowUnit}}</div>
                                                        <div class="col-xs-2 col-md-2 estimate-value"
                                                            *ngIf="(globalUnit == 'Metric')">
                                                            {{rack?.power?.metric?.airflowUnit}}</div>
                                                    </div>
                                                    <div class="row estimate-row"
                                                        *ngIf="rack?.power?.legacyPlatform == false">
                                                        <div class="col-md-7 estimate-label">{{'AIR_FLOW_MIN'
                                                            |translate}}</div>
                                                        <div class="col-md-3 estimate-value"
                                                            *ngIf="(globalUnit == 'Imperial')">
                                                            {{rack?.power?.airFlowRange?.minAirflowImperial | number :
                                                            '1.2-2'}}</div>
                                                        <div class="col-md-3 estimate-value"
                                                            *ngIf="(globalUnit == 'Metric')">
                                                            {{rack?.power?.airFlowRange?.minAirflowMetric | number :
                                                            '1.2-2'}}</div>
                                                        <div class="col-xs-2 col-md-2 estimate-value"
                                                            *ngIf="(globalUnit == 'Imperial')">
                                                            {{rack?.power?.imperial?.airflowUnit}}</div>
                                                        <div class="col-xs-2 col-md-2 estimate-value"
                                                            *ngIf="(globalUnit == 'Metric')">
                                                            {{rack?.power?.metric?.airflowUnit}}</div>
                                                    </div>
                                                    <div class="row estimate-row"
                                                        *ngIf="rack?.power?.legacyPlatform == false">
                                                        <div class="col-md-7 estimate-label">{{'AIR_FLOW_MAX'
                                                            |translate}}</div>
                                                        <div class="col-md-3 estimate-value"
                                                            *ngIf="(globalUnit == 'Imperial')">
                                                            {{rack?.power?.airFlowRange?.maxAirflowImperial | number :
                                                            '1.2-2'}}</div>
                                                        <div class="col-md-3 estimate-value"
                                                            *ngIf="(globalUnit == 'Metric')">
                                                            {{rack?.power?.airFlowRange?.maxAirflowMetric | number :
                                                            '1.2-2'}}</div>
                                                        <div class="col-xs-2 col-md-2 estimate-value"
                                                            *ngIf="(globalUnit == 'Imperial')">
                                                            {{rack?.power?.imperial?.airflowUnit}}</div>
                                                        <div class="col-xs-2 col-md-2 estimate-value"
                                                            *ngIf="(globalUnit == 'Metric')">
                                                            {{rack?.power?.metric?.airflowUnit}}</div>
                                                    </div>
                                                    <div class="row estimate-row"
                                                        *ngIf="rack?.power?.legacyPlatform == false">
                                                        <div class="col-md-7 estimate-label">{{'ACOUSTICS_MIN'
                                                            |translate}}</div>
                                                        <div class="col-md-3 estimate-value">
                                                            {{rack?.power?.acousticsRange?.minAcoustics | number :
                                                            '1.2-2'}}</div>
                                                        <div class="col-xs-1 col-md-2 estimate-value">
                                                            {{rack?.power?.imperial?.acousticsUnit}}</div>
                                                    </div>
                                                    <div class="row estimate-row"
                                                        *ngIf="rack?.power?.legacyPlatform == false">
                                                        <div class="col-md-7 estimate-label">{{'ACOUSTICS_MAX'
                                                            |translate}}</div>
                                                        <div class="col-md-3 estimate-value">
                                                            {{rack?.power?.acousticsRange?.maxAcoustics | number :
                                                            '1.2-2'}}</div>
                                                        <div class="col-xs-1 col-md-2 estimate-value">
                                                            {{rack?.power?.imperial?.acousticsUnit}}</div>
                                                    </div>
                                                    <div class="row estimate-row">
                                                        <div class="col-xs-7 col-md-7 estimate-label">{{'COOLING'
                                                            |translate}}</div>
                                                        <div class="col-md-3 col-xs-3 estimate-value"
                                                            *ngIf="(globalUnit == 'Imperial')">
                                                            {{rack?.power?.inputCooling?.imperialCooling
                                                            |number:'1.2-2'}}</div>
                                                        <div class="col-md-3 col-xs-3 estimate-value"
                                                            *ngIf="(globalUnit == 'Metric')">
                                                            {{rack?.power?.inputCooling?.imperialCooling
                                                            |number:'1.2-2'}}</div>
                                                        <div class="col-xs-2 col-md-2 estimate-value"
                                                            *ngIf="(globalUnit == 'Imperial')">
                                                            {{rack?.power?.imperial?.coolingUnit}}</div>
                                                        <div class="col-xs-2 col-md-2 estimate-value"
                                                            *ngIf="(globalUnit == 'Metric')">
                                                            {{rack?.power?.imperial?.coolingUnit}}</div>
                                                    </div>
                                                    <div class="row estimate-row">
                                                        <div class="col-xs-7 col-md-7 estimate-label">{{'WEIGHT'
                                                            |translate}}</div>
                                                        <div class="col-md-3 col-xs-3 estimate-value"
                                                            *ngIf="(globalUnit == 'Imperial')">
                                                            {{rack?.power?.weightImperial |number:'1.2-2'}}</div>
                                                        <div class="col-md-3 col-xs-3 estimate-value"
                                                            *ngIf="(globalUnit == 'Metric')">
                                                            {{rack?.power?.weightMetric |number:'1.2-2'}}</div>
                                                        <div class="col-xs-2 col-md-2 estimate-value"
                                                            *ngIf="(globalUnit == 'Imperial')">
                                                            {{rack?.power?.imperial?.weightUnit}}</div>
                                                        <div class="col-xs-2 col-md-2 estimate-value"
                                                            *ngIf="(globalUnit == 'Metric')">
                                                            {{rack?.power?.metric?.weightUnit}}</div>
                                                    </div>
                                                </div>
                                            </div>

                                        </li>
                                    </ul>
                                </div>
                            </li>
                            <li [ngClass]="selectedSeriesId == 3 && selectedSeriesNameIdx == k ? 'active' : ''"
                                *ngIf="filterList(projectData.mseriesList, seriesName).length > 0">
                                <a class="accordion__title accor-title" (click)="onSeriesSelect(3, k)"
                                    style="margin-left: 2px;">
                                    <span
                                        [ngClass]="selectedSeriesId==3 && selectedSeriesNameIdx == k ? 'accordion__toggle icon-chevron-down  icon-margin' : 'accordion__toggle icon-chevron-up icon-margin'"
                                        style="margin-top: -7px;"></span>
                                    <h5>{{ seriesName | translate}}</h5>
                                </a>
                                <div *ngIf="selectedSeriesId==3 && selectedSeriesNameIdx == k"
                                    class="accordion__content">
                                    <ul class="accordion" style="margin-top: 10px;">
                                        <li
                                            *ngFor="let modular of filterList(projectData.mseriesList, seriesName); let m = index">
                                            <div class="form-group form-group--inline"
                                                style="justify-content: space-between;">
                                                <div class="col-md-9" style="padding-left:2px">
                                                    <a class="accordion__title accordian-server-name"
                                                        (click)="onServerSelect('M',m,k,modular)" id="modular{{m}}">

                                                        <span class="toggle-icon" style="margin-right: 0px;"
                                                            [ngClass]="selectedServerId.includes('M'+m+k) ? 'accordion__toggle icon-chevron-down' : 'accordion__toggle icon-chevron-up'"></span>
                                                        <span *ngIf="doesServerHaveRedundancyError(modular)"
                                                            class="icon-warning redundancy-warning"></span>
                                                        <div
                                                            *ngIf="modular.serverId ==editServerElementName; then editTheServer  else showTheServer">
                                                        </div>
                                                        <ng-template #showTheServer><span
                                                                style="margin-left:1px;white-space: normal;"
                                                                [ngClass]="doesServerHaveRedundancyError(modular) ? 'redundancy-warning-error' : ''">{{modular?.userProvidedName}}</span>
                                                            <span style="margin-left: 10px;
                                                        font-size: x-small; color: grey;
                                                        margin-top: -5px;">({{modular?.displayName}})</span>
                                                        </ng-template>
                                                        <ng-template #editTheServer>
                                                            <div class="form-group__text col-md-6">
                                                                <input id="input-server{{m}}" type="text"
                                                                    style="width: 600px;"
                                                                    [(ngModel)]="modular.userProvidedName"
                                                                    (blur)="blurServer($event, modular.id,'mseries',modular?.serverId, '')"
                                                                    (change)="changeServerName($event,modular.id,'mseries', modular?.serverId)">

                                                            </div>
                                                        </ng-template>
                                                    </a>
                                                </div>
                                                <div class="form-group__text col-md-3"
                                                    style="padding-top: 4px; padding-right:0; padding-left: 0; margin-left:-5px;"
                                                    [attr.disabled]="(isServerEdited && !isCurrentServer(modular)) ? true : null"
                                                    *ngIf="modular.serverId !==editServerElementName">
                                                    <input id="input-quantity{{m}}" type="number"
                                                        style="width: 30px; padding: 2px 2px;"
                                                        [(ngModel)]="modular.config.quantity" min="1" autocomplete="off"
                                                        [ngClass]="modular.config?.flag ? 'select-danger': 'select-normal'"
                                                        (change)="changeServerQuantity($event,modular.id,'mseries', modular?.serverId)">

                                                    <a *ngIf="modular.serverId !==editServerElementName"><span
                                                            class="icon-edit icon-size-16"
                                                            style="margin: 5px;color:#00bceb"
                                                            (click)="editServer(modular.id, 'mseries', modular.serverId)"></span></a>
                                                    <a *ngIf="modular.serverId !==editServerElementName"> <span
                                                            class="icon-delete icon-size-16 icon-delete"
                                                            style="margin: 5px;color:#e2231a;"
                                                            (click)="deleteChasssisOpenModal(modular.id, 'mseries', modular.serverId)"></span></a>
                                                </div>
                                            </div>
                                            <div class="accordion__content"
                                                [ngClass]="selectedServerId.includes('M'+m+k) ? 'li-active' : 'li-inactive'">
                                                <a class="add-blade"
                                                    *ngIf="modular.level == 'Parent' && modular.hasChildren == true"
                                                    [ngClass]="modular.level == 'Parent' && modular.hasChildren == true && !isNodeQtyLimitReached  && getTotalSlotsConsumed(modular.nodes) < modular.maxCartridgeSlots && !isServerEdited ?  'anchor-active' : 'anchor-inactive'"
                                                    (click)="addChassis(modular.pid,modular.config.powerSupplyId,modular.config.powerSupplyCount, modular.id ,modular?.config?.inputVoltageId, 'node', modular.serverId, modular.maxCartridgeSlots - getTotalSlotsConsumed(modular.nodes))">
                                                    <span class="icon-add icon-size-12"
                                                        style="margin-right: 3px;margin-left:13px"></span>{{'ADD_ADDITIONAL_CHASSIS_CONFIG'
                                                    | translate}}
                                                </a>
                                                <div class="power-config-div row">
                                                    <p class="power-config-header col-xs-12 col-md-12">{{'CONFIGURATION'
                                                        |
                                                        translate}}</p>
                                                </div>
                                                <div class="power-supply-container">
                                                    <div class="configure-container col-md-12 base-margin-bottom">
                                                        <div class="col-xs-9 col-md-9 estimate-label">{{'INPUT_VOLTAGE'
                                                            |translate}}
                                                        </div>
                                                        <div class="col-xs-3 col-md-3 estimate-value input-voltage">
                                                            {{modular?.config?.voltageName}}</div>
                                                    </div>
                                                    <div class="configure-container col-md-12 base-margin-bottom">
                                                        <div class="col-xs-10 col-md-10 estimate-label">
                                                            {{'REDUNDANCY_MODE'
                                                            |translate}}
                                                        </div>
                                                        <div class="col-xs-2 col-md-2 estimate-value redundancy-mode">
                                                            {{displayFormattedRMode(modular?.config?.redundancyMode)}}
                                                        </div>
                                                    </div>
                                                    <div class="configure-container col-md-12">
                                                        <div class="col-xs-10 col-md-10 estimate-label">{{'POWER_SUPPLY'
                                                            |translate}}
                                                        </div>
                                                        <div class="col-xs-2 col-md-2 estimate-value">
                                                            {{modular?.config?.powerSupplyCount}}</div>
                                                    </div>
                                                    <div>
                                                        <p class="power-supply-value"><span
                                                                class="icon-margin">▪</span>{{modular?.config?.powerSupplyTypeName}}
                                                        </p>
                                                    </div>


                                                    <div class="configure-container col-md-12"
                                                        *ngIf="modular?.config?.dedicatedStorageQuantity && modular?.config?.dedicatedStorageQuantity > 0">
                                                        <div class="col-xs-10 col-md-10 estimate-label">
                                                            {{'STORAGE_CONTROLLER'
                                                            |translate}}
                                                        </div>
                                                        <div class="col-xs-2 col-md-2 estimate-value">
                                                            {{modular?.config?.dedicatedStorageQuantity}}</div>
                                                    </div>
                                                    <div *ngFor="let ds of modular?.config?.dedicatedStorage"
                                                        class="dependent">
                                                        <div class="col-xs-10 col-md-10 dependent-label"><span
                                                                class="icon-margin">▪</span>{{ds?.mezzanineControllerTypeName
                                                            |translate}}
                                                        </div>
                                                        <div class="col-xs-2 col-md-2 dependent-value">
                                                            {{ds?.mezzanineControllerCount}}</div>
                                                    </div>
                                                    <div class="configure-container col-md-12"
                                                        *ngIf="modular?.config?.adapterQuantity && modular?.config?.adapterQuantity > 0">
                                                        <div class="col-xs-10 col-md-10 estimate-label">{{'ADAPTER'
                                                            |translate}}
                                                        </div>
                                                        <div class="col-xs-2 col-md-2 estimate-value">
                                                            {{modular?.config?.adapterQuantity}}</div>
                                                    </div>
                                                    <div>
                                                        <div *ngFor="let adapter of modular?.config?.adaptors"
                                                            class="dependent">
                                                            <div class="col-xs-10 col-md-10 dependent-label"><span
                                                                    class="icon-margin">▪</span>{{adapter?.adaptorTypeName
                                                                |translate}}
                                                            </div>
                                                            <div class="col-xs-2 col-md-2 dependent-value">
                                                                {{adapter?.adaptorCount}}</div>
                                                        </div>
                                                    </div>
                                                    <div class="configure-container col-md-12"
                                                        *ngIf="modular?.config?.storageQuantity && modular?.config?.storageQuantity > 0">
                                                        <div class="col-xs-10 col-md-10 estimate-label">{{'STORAGE'
                                                            |translate}}
                                                        </div>
                                                        <div class="col-xs-2 col-md-2 estimate-value">
                                                            {{modular?.config?.storageQuantity}}</div>
                                                    </div>
                                                    <div>
                                                        <div *ngFor="let storage of modular?.config?.storage"
                                                            class="dependent">
                                                            <div class="col-xs-10 col-md-10 dependent-label"><span
                                                                    class="icon-margin">▪</span>{{storage?.storageTypeName
                                                                |translate}}
                                                            </div>
                                                            <div class="col-xs-2 col-md-2 dependent-value">
                                                                {{storage?.storageCount}}</div>
                                                        </div>
                                                    </div>

                                                    <!-- additional dynamic components -->
                                                    <div>
                                                        <div
                                                            *ngFor="let additionalComp of getAdditionalComponents(modular)">
                                                            <div class="configure-container col-md-12"
                                                                *ngIf="additionalComp.length > 0">
                                                                <div class="col-xs-10 col-md-10">
                                                                    {{additionalComp[0].label | translate }}</div>
                                                                <div class="col-xs-2 col-md-2">{{
                                                                    getTotalQuantity(additionalComp) }} </div>
                                                            </div>
                                                            <div class="dependent" *ngFor="let comp of additionalComp">
                                                                <div class="col-xs-10 col-md-10 dependent-label"><span
                                                                        class="icon-margin">▪</span>{{comp?.configuration
                                                                    |translate}}
                                                                </div>
                                                                <div class="col-xs-2 col-md-2 dependent-value">
                                                                    {{comp?.quantity}}</div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div class="server-estimate-div">
                                                    <div class="power-estimate-div row">
                                                        <p class="power-estimate-header col-xs-12 col-md-12">
                                                            {{'POWER_ESTIMATE' |
                                                            translate}}</p>
                                                    </div>
                                                    <div class="row estimate-row">
                                                        <div class="col-xs-7 col-md-7 estimate-label">
                                                            {{'MAX_INPUT_POWER'
                                                            |translate}}</div>
                                                        <div class="col-md-3 col-xs-3 estimate-value">
                                                            {{modular?.power?.maxInputPower |number:'1.2-2'}}</div>
                                                        <div class="col-xs-2 col-md-2 estimate-value">
                                                            {{modular?.power?.imperial?.powerUnit}}</div>
                                                    </div>
                                                    <div class="row estimate-row">
                                                        <div class="col-xs-7 col-md-7 estimate-label">{{'INPUT_POWER'
                                                            |translate}}
                                                        </div>
                                                        <div class="col-md-3 col-xs-3 estimate-value">
                                                            {{modular?.power?.inputPower |number:'1.2-2'}}</div>
                                                        <div class="col-xs-2 col-md-2 estimate-value">
                                                            {{modular?.power?.imperial?.powerUnit}}</div>
                                                    </div>
                                                    <div class="row estimate-row">
                                                        <div class="col-xs-7 col-md-7 estimate-label">
                                                            {{'IDLE_INPUT_POWER'
                                                            |translate}}</div>
                                                        <div class="col-md-3 col-xs-3 estimate-value">
                                                            {{modular?.power?.idleInputPower |number:'1.2-2'}}</div>
                                                        <div class="col-xs-2 col-md-2 estimate-value">
                                                            {{modular?.power?.imperial?.powerUnit}}</div>
                                                    </div>
                                                    <div class="row estimate-row">
                                                        <div class="col-xs-7 col-md-7 estimate-label">
                                                            {{'SYSTEM_WORKLOAD'
                                                            |translate}}</div>
                                                        <div class="col-md-3 col-xs-3 estimate-value">
                                                            {{modular?.power?.systemWorkloadPercent
                                                            |number:'1.2-2'}}</div>
                                                        <div class="col-xs-2 col-md-2 estimate-value">
                                                            {{modular?.power?.imperial?.systemWorkload}}</div>
                                                    </div>
                                                    <div class="row estimate-row">
                                                        <div class="col-xs-7 col-md-7 estimate-label">{{'INPUT_CURRENT'
                                                            |translate}}
                                                        </div>
                                                        <div class="col-md-3 col-xs-3 estimate-value">
                                                            {{modular?.power?.inputCurrentLoad |number:'1.2-2'}}</div>
                                                        <div class="col-xs-2 col-md-2 estimate-value">
                                                            {{modular?.power?.imperial?.currentUnit}}</div>
                                                    </div>
                                                    <div class="row estimate-row"
                                                        *ngIf="modular?.power?.legacyPlatform">
                                                        <div class="col-xs-7 col-md-7 estimate-label">{{'AIR_FLOW'
                                                            |translate}}
                                                        </div>
                                                        <div class="col-md-3 col-xs-3 estimate-value"
                                                            *ngIf="(globalUnit == 'Imperial')">
                                                            {{modular?.power?.inputAirflow?.airflowImperial
                                                            |number:'1.2-2'}} </div>
                                                        <div class="col-md-3 col-xs-3 estimate-value"
                                                            *ngIf="(globalUnit == 'Metric')">
                                                            {{modular?.power?.inputAirflow?.airflowMetric
                                                            |number:'1.2-2'}} </div>
                                                        <div class="col-xs-2 col-md-2 estimate-value"
                                                            *ngIf="(globalUnit == 'Imperial')">
                                                            {{modular?.power?.imperial?.airflowUnit}}</div>
                                                        <div class="col-xs-2 col-md-2 estimate-value"
                                                            *ngIf="(globalUnit == 'Metric')">
                                                            {{modular?.power?.metric?.airflowUnit}}</div>
                                                    </div>
                                                    <div class="row estimate-row"
                                                        *ngIf="modular?.power?.legacyPlatform == false">
                                                        <div class="col-md-7 estimate-label">{{'AIR_FLOW_MIN'
                                                            |translate}}</div>
                                                        <div class="col-md-3 estimate-value"
                                                            *ngIf="(globalUnit == 'Imperial')">
                                                            {{modular?.power?.airFlowRange?.minAirflowImperial | number
                                                            :
                                                            '1.2-2'}}</div>
                                                        <div class="col-md-3 estimate-value"
                                                            *ngIf="(globalUnit == 'Metric')">
                                                            {{modular?.power?.airFlowRange?.minAirflowMetric | number :
                                                            '1.2-2'}}</div>
                                                        <div class="col-xs-2 col-md-2 estimate-value"
                                                            *ngIf="(globalUnit == 'Imperial')">
                                                            {{modular?.power?.imperial?.airflowUnit}}</div>
                                                        <div class="col-xs-2 col-md-2 estimate-value"
                                                            *ngIf="(globalUnit == 'Metric')">
                                                            {{modular?.power?.metric?.airflowUnit}}</div>
                                                    </div>
                                                    <div class="row estimate-row"
                                                        *ngIf="modular?.power?.legacyPlatform == false">
                                                        <div class="col-md-7 estimate-label">{{'AIR_FLOW_MAX'
                                                            |translate}}</div>
                                                        <div class="col-md-3 estimate-value"
                                                            *ngIf="(globalUnit == 'Imperial')">
                                                            {{modular?.power?.airFlowRange?.maxAirflowImperial | number
                                                            :
                                                            '1.2-2'}}</div>
                                                        <div class="col-md-3 estimate-value"
                                                            *ngIf="(globalUnit == 'Metric')">
                                                            {{modular?.power?.airFlowRange?.maxAirflowMetric | number :
                                                            '1.2-2'}}</div>
                                                        <div class="col-xs-2 col-md-2 estimate-value"
                                                            *ngIf="(globalUnit == 'Imperial')">
                                                            {{modular?.power?.imperial?.airflowUnit}}</div>
                                                        <div class="col-xs-2 col-md-2 estimate-value"
                                                            *ngIf="(globalUnit == 'Metric')">
                                                            {{modular?.power?.metric?.airflowUnit}}</div>
                                                    </div>
                                                    <div class="row estimate-row"
                                                        *ngIf="modular?.power?.legacyPlatform == false">
                                                        <div class="col-md-7 estimate-label">{{'ACOUSTICS_MIN'
                                                            |translate}}</div>
                                                        <div class="col-md-3 estimate-value">
                                                            {{modular?.power?.acousticsRange?.minAcoustics | number :
                                                            '1.2-2'}}</div>
                                                        <div class="col-xs-1 col-md-2 estimate-value">
                                                            {{modular?.power?.imperial?.acousticsUnit}}</div>
                                                    </div>
                                                    <div class="row estimate-row"
                                                        *ngIf="modular?.power?.legacyPlatform == false">
                                                        <div class="col-md-7 estimate-label">{{'ACOUSTICS_MAX'
                                                            |translate}}</div>
                                                        <div class="col-md-3 estimate-value">
                                                            {{modular?.power?.acousticsRange?.maxAcoustics | number :
                                                            '1.2-2'}}</div>
                                                        <div class="col-xs-1 col-md-2 estimate-value">
                                                            {{modular?.power?.imperial?.acousticsUnit}}</div>
                                                    </div>
                                                    <div class="row estimate-row">
                                                        <div class="col-xs-7 col-md-7 estimate-label">{{'COOLING'
                                                            |translate}}</div>
                                                        <div class="col-md-3 col-xs-3 estimate-value"
                                                            *ngIf="(globalUnit == 'Imperial')">
                                                            {{modular?.power?.inputCooling?.imperialCooling
                                                            |number:'1.2-2'}}</div>
                                                        <div class="col-md-3 col-xs-3 estimate-value"
                                                            *ngIf="(globalUnit == 'Metric')">
                                                            {{modular?.power?.inputCooling?.imperialCooling
                                                            |number:'1.2-2'}}</div>
                                                        <div class="col-xs-2 col-md-2 estimate-value"
                                                            *ngIf="(globalUnit == 'Imperial')">
                                                            {{modular?.power?.imperial?.coolingUnit}}</div>
                                                        <div class="col-xs-2 col-md-2 estimate-value"
                                                            *ngIf="(globalUnit == 'Metric')">
                                                            {{modular?.power?.imperial?.coolingUnit}}</div>
                                                    </div>
                                                    <div class="row estimate-row">
                                                        <div class="col-xs-7 col-md-7 estimate-label">{{'WEIGHT'
                                                            |translate}}</div>
                                                        <div class="col-md-3 col-xs-3 estimate-value"
                                                            *ngIf="(globalUnit == 'Imperial')">
                                                            {{modular?.power?.weightImperial |number:'1.2-2'}}</div>
                                                        <div class="col-md-3 col-xs-3 estimate-value"
                                                            *ngIf="(globalUnit == 'Metric')">
                                                            {{modular?.power?.weightMetric |number:'1.2-2'}}</div>
                                                        <div class="col-xs-2 col-md-2 estimate-value"
                                                            *ngIf="(globalUnit == 'Imperial')">
                                                            {{modular?.power?.imperial?.weightUnit}}</div>
                                                        <div class="col-xs-2 col-md-2 estimate-value"
                                                            *ngIf="(globalUnit == 'Metric')">
                                                            {{modular?.power?.metric?.weightUnit}}</div>
                                                    </div>
                                                </div>
                                            </div>

                                            <ul class="accordion" style="margin-left:20px;">
                                                <li *ngFor="let node of modular.nodes; let k = index">
                                                    <div class="form-group form-group--inline">
                                                        <div class="col-md-9">
                                                            <a class="accordion__title accor-title accordian-server-name"
                                                                (click)="onNodeSelect(k, m)" id="node{{k}}">

                                                                <span class="accordion__toggle icon-margin"
                                                                    [ngClass]="selectedNodeId == (k+','+m) ? 'accordion__toggle icon-chevron-down' : 'accordion__toggle icon-chevron-up'"
                                                                    style="margin-top: -7px; margin-right: 2px"></span>
                                                                <div
                                                                    *ngIf="node.serverId ==editChildNodeName; then editTheChildNode  else showTheChildNode">
                                                                </div>
                                                                <ng-template #showTheChildNode>
                                                                    <span>{{node?.userProvidedName}}</span><span
                                                                        style="margin-left: 10px;
                                                                        font-size: x-small; color: grey;
                                                                        margin-top: -2px;">({{node?.displayName}})</span>
                                                                </ng-template>
                                                                <ng-template #editTheChildNode>
                                                                    <div class="form-group__text col-md-6">
                                                                        <input id="input-server{{k}}" type="text"
                                                                            style="width: 600px;"
                                                                            [(ngModel)]="node.userProvidedName"
                                                                            (blur)="blurServer($event, node.id,'node',node.serverId, modular.id)"
                                                                            (change)="changeNodeName($event, node.serverId, node.id, modular.id )">
                                                                    </div>
                                                                </ng-template>
                                                            </a>
                                                        </div>
                                                        <div class="form-group__text col-md-3"
                                                            style="padding-top: 4px; padding-right:0; padding-left: 0; margin-left:-5px;"
                                                            [attr.disabled]="(isServerEdited && !isCurrentServer(node)) ? true : null"
                                                            *ngIf="node.serverId !==editChildNodeName">
                                                            <input id="input-quantity{{k}}" type="number"
                                                                style="width: 30px; padding: 2px 2px;"
                                                                [ngModel]="node.config.quantity" min="1"
                                                                [ngClass]="getTotalSlotsConsumed(modular.nodes) <= modular.maxCartridgeSlots && !node.config.flag && node.config.quantity >= 0 ?  'select-normal' : 'select-danger'"
                                                                (change)="changeNodeQuantity($event,node.serverId, modular.id,node.type)">

                                                            <a><span class="icon-edit icon-size-16"
                                                                    style="margin: 5px;color:#00bceb"
                                                                    (click)="editNodeServer(node.serverId, node.id, node.type, modular.id, modular?.config?.inputVoltageId,modular.config.powerSupplyId,modular.config.powerSupplyCount )"></span></a>
                                                            <a> <span class="icon-delete icon-size-16 icon-delete"
                                                                    style="margin: 5px;color:#e2231a;"
                                                                    (click)="deleteServerOpenModal(node.serverId, node.type, modular.id, modular)"></span></a>
                                                        </div>
                                                    </div>
                                                    <div class="accordion__content"
                                                        [ngClass]="selectedNodeId == (k+','+m) ? 'li-active' : 'li-inactive'">
                                                        <div class="power-config-div row">
                                                            <p class="power-config-header col-xs-12 col-md-12">
                                                                {{'CONFIGURATION' |
                                                                translate}}</p>
                                                        </div>
                                                        <div class="power-supply-container">

                                                            <div class="configure-container col-md-12"
                                                                *ngIf="node?.config?.processorCount > 0">
                                                                <div class="col-xs-10 col-md-10 estimate-label">
                                                                    {{'PROCESSOR'
                                                                    |translate}}
                                                                </div>
                                                                <div class="col-xs-2 col-md-2 estimate-value">
                                                                    {{node?.config?.processorCount}}</div>
                                                            </div>
                                                            <div *ngIf="node?.config?.processorCount > 0">
                                                                <p class="power-supply-value"><span
                                                                        class="icon-margin">▪</span>{{node?.config?.processorTypeName}}
                                                                </p>
                                                            </div>
                                                            <div class="configure-container col-md-12"
                                                                *ngIf="node?.config?.memoryQuantity > 0">
                                                                <div class="col-xs-10 col-md-10 estimate-label">
                                                                    {{'MEMORY'
                                                                    |translate}}
                                                                </div>
                                                                <div class="col-xs-2 col-md-2 estimate-value">
                                                                    {{node?.config?.memoryQuantity}}</div>
                                                            </div>
                                                            <div *ngIf="node.type == 'CARTRIDGE'">
                                                                <div *ngFor="let memory of node?.config?.memory"
                                                                    class="dependent">
                                                                    <div class="col-xs-10 col-md-10 dependent-label">
                                                                        <span
                                                                            class="icon-margin">▪</span>{{memory?.memoryTypeName
                                                                        |translate}}
                                                                    </div>
                                                                    <div class="col-xs-2 col-md-2 dependent-value">
                                                                        {{memory?.memoryCount}}</div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                        <div class="configure-container col-md-12"
                                                            *ngIf="node?.config?.dedicatedStorageQuantity && node?.config?.dedicatedStorageQuantity > 0">
                                                            <div class="col-xs-10 col-md-10 estimate-label">
                                                                {{'STORAGE_CONTROLLER'
                                                                |translate}}
                                                            </div>
                                                            <div class="col-xs-2 col-md-2 estimate-value">
                                                                {{node?.config?.dedicatedStorageQuantity}}</div>
                                                        </div>
                                                        <div *ngFor="let ds of node?.config?.dedicatedStorage"
                                                            class="dependent">
                                                            <div class="col-xs-10 col-md-10 dependent-label"><span
                                                                    class="icon-margin">▪</span>{{ds?.mezzanineControllerTypeName
                                                                |translate}}
                                                            </div>
                                                            <div class="col-xs-2 col-md-2 dependent-value">
                                                                {{ds?.mezzanineControllerCount}}</div>
                                                        </div>

                                                        <div class="configure-container col-md-12"
                                                            *ngIf="node?.config?.adapterQuantity && node?.config?.adapterQuantity > 0">
                                                            <div class="col-xs-10 col-md-10 estimate-label">{{'ADAPTER'
                                                                |translate}}
                                                            </div>
                                                            <div class="col-xs-2 col-md-2 estimate-value">
                                                                {{node?.config?.adapterQuantity}}</div>
                                                        </div>
                                                        <div>
                                                            <div *ngFor="let adapter of node?.config?.adaptors"
                                                                class="dependent">
                                                                <div class="col-xs-10 col-md-10 dependent-label"><span
                                                                        class="icon-margin">▪</span>{{adapter?.adaptorTypeName
                                                                    |translate}}
                                                                </div>
                                                                <div class="col-xs-2 col-md-2 dependent-value">
                                                                    {{adapter?.adaptorCount}}</div>
                                                            </div>
                                                        </div>
                                                        <div class="configure-container col-md-12"
                                                            *ngIf="node?.config?.storageQuantity && node?.config?.storageQuantity > 0">
                                                            <div class="col-xs-10 col-md-10 estimate-label">{{'STORAGE'
                                                                |translate}}
                                                            </div>
                                                            <div class="col-xs-2 col-md-2 estimate-value">
                                                                {{node?.config?.storageQuantity}}</div>
                                                        </div>
                                                        <div>

                                                            <div *ngFor="let storage of node?.config?.storage"
                                                                class="dependent">
                                                                <div class="col-xs-10 col-md-10 dependent-label"><span
                                                                        class="icon-margin">▪</span>{{storage?.storageTypeName
                                                                    |translate}}
                                                                </div>
                                                                <div class="col-xs-2 col-md-2 dependent-value">
                                                                    {{storage?.storageCount}}</div>
                                                            </div>
                                                        </div>
                                                        <!-- additional dynamic components -->
                                                        <div>
                                                            <div
                                                                *ngFor="let additionalComp of getAdditionalComponents(node)">
                                                                <div class="configure-container col-md-12"
                                                                    *ngIf="additionalComp.length > 0">
                                                                    <div class="col-xs-10 col-md-10">
                                                                        {{additionalComp[0].label | translate }}</div>
                                                                    <div class="col-xs-2 col-md-2">{{
                                                                        getTotalQuantity(additionalComp) }} </div>
                                                                </div>
                                                                <div class="dependent"
                                                                    *ngFor="let comp of additionalComp">
                                                                    <div class="col-xs-10 col-md-10 dependent-label">
                                                                        <span
                                                                            class="icon-margin">▪</span>{{comp?.configuration
                                                                        |translate}}
                                                                    </div>
                                                                    <div class="col-xs-2 col-md-2 dependent-value">
                                                                        {{comp?.quantity}}</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </li>

                                    </ul>
                                </div>
                            </li>


                            <!-- additional platforms -->
                            <li *ngFor="let i of enumerate(5); let idx=index;">
                                <div
                                    *ngIf="doesAdditionalPlatformExist(idx+1) && getAdditionalPlatformPrimaryClass(idx+1)=='Compute'">
                            <li [ngClass]="selectedSeriesId == idx*10 && selectedSeriesNameIdx == k ? 'active' : ''"
                                *ngIf="filterList(getAdditionalPlatform(idx+1), seriesName).length > 0">
                                <a class="accordion__title accor-title" (click)="onSeriesSelect(idx*10, k)"
                                    style="margin-left: 2px;">
                                    <span
                                        [ngClass]="selectedSeriesId==idx*10 && selectedSeriesNameIdx == k ? 'accordion__toggle icon-chevron-down  icon-margin' : 'accordion__toggle icon-chevron-up icon-margin'"
                                        style="margin-top: -7px;"></span>
                                    <h5>{{ seriesName | translate}}</h5>
                                </a>
                                <div *ngIf="selectedSeriesId==idx*10 && selectedSeriesNameIdx == k"
                                    class="accordion__content">
                                    <ul class="accordion" style="margin-top: 10px;">
                                        <li
                                            *ngFor="let rack of filterList(getAdditionalPlatform(idx+1), seriesName); let c = index">
                                            <div class="form-group form-group--inline">
                                                <div class="col-md-9" style="padding-left:2px">
                                                    <a class="accordion__title accordian-server-name"
                                                        (click)="onServerSelect('AP',c,k,rack)" id="rack{{c}}">

                                                        <span class="toggle-icon" style="margin-right: 0px;"
                                                            [ngClass]="selectedServerId.includes('AP'+c+k) ? 'accordion__toggle icon-chevron-down' : 'accordion__toggle icon-chevron-up'"></span>
                                                        <span *ngIf="doesServerHaveRedundancyError(rack)"
                                                            class="icon-warning redundancy-warning"></span>
                                                        <div
                                                            *ngIf="rack.serverId ==editServerElementName; then editTheServer  else showTheServer">
                                                        </div>
                                                        <ng-template #showTheServer><span
                                                                style="margin-left:1px;white-space: normal;"
                                                                [ngClass]="doesServerHaveRedundancyError(rack) ? 'redundancy-warning-error' : ''">{{rack?.userProvidedName}}</span>
                                                            <span style="margin-left: 10px;
                                                            font-size: x-small; color: grey;
                                                            margin-top: -5px;">({{rack?.displayName}})</span>
                                                        </ng-template>
                                                        <ng-template #editTheServer>
                                                            <div class="form-group__text col-md-6">
                                                                <input id="input-server{{c}}" type="text"
                                                                    style="width: 600px;"
                                                                    [(ngModel)]="rack.userProvidedName"
                                                                    (blur)="blurServer($event, rack.id, rack.serverType,rack.serverId, '')"
                                                                    (change)="changeServerName($event,rack.id,rack.serverType, rack?.serverId)">

                                                            </div>
                                                        </ng-template>
                                                    </a>
                                                </div>
                                                <div class="form-group__text col-md-3"
                                                    style="padding-top: 4px; padding-right:0; padding-left: 0; margin-left:-5px;"
                                                    [attr.disabled]="(isServerEdited && !isCurrentServer(rack)) ? true : null"
                                                    *ngIf="rack.serverId !==editServerElementName">
                                                    <input id="input-quantity{{c}}" type="number"
                                                        style="width: 30px; padding: 2px 2px;"
                                                        [(ngModel)]="rack.config.quantity" min="1" autocomplete="off"
                                                        [ngClass]="rack.config?.flag ? 'select-danger': 'select-normal'"
                                                        (change)="changeServerQuantity($event,rack.id ,rack.serverType, rack.serverId)">

                                                    <a *ngIf="rack.serverId !==editServerElementName"><span
                                                            class="icon-edit icon-size-16"
                                                            style="margin: 5px;color:#00bceb"
                                                            (click)="editServer(rack.id, rack.serverType, rack.serverId)"></span></a>
                                                    <a *ngIf="rack.serverId !==editServerElementName"> <span
                                                            class="icon-delete icon-size-16 icon-delete"
                                                            style="margin: 5px;color:#e2231a;"
                                                            (click)="deleteChasssisOpenModal(rack.id, rack.serverType, rack.serverId)"></span></a>
                                                </div>
                                            </div>
                                            <div class="accordion__content"
                                                [ngClass]="selectedServerId.includes('AP'+c+k) ? 'li-active' : 'li-inactive'">
                                                <div class="power-config-div row">
                                                    <p class="power-config-header col-xs-12 col-md-12">{{'CONFIGURATION'
                                                        |
                                                        translate}}</p>
                                                </div>
                                                <div class="power-supply-container">
                                                    <div class="configure-container col-md-12 base-margin-bottom">
                                                        <div class="col-xs-9 col-md-9 estimate-label">{{'INPUT_VOLTAGE'
                                                            |translate}}
                                                        </div>
                                                        <div class="col-xs-3 col-md-3 estimate-value input-voltage">
                                                            {{rack?.config?.voltageName}}</div>
                                                    </div>
                                                    <div class="configure-container col-md-12 base-margin-bottom">
                                                        <div class="col-xs-10 col-md-10 estimate-label">
                                                            {{'REDUNDANCY_MODE'
                                                            |translate}}
                                                        </div>
                                                        <div class="col-xs-2 col-md-2 estimate-value redundancy-mode">
                                                            {{displayFormattedRMode(rack?.config?.redundancyMode)}}
                                                        </div>
                                                    </div>
                                                    <div class="configure-container col-md-12">
                                                        <div class="col-xs-10 col-md-10 estimate-label">{{'PROCESSOR'
                                                            |translate}}
                                                        </div>
                                                        <div class="col-xs-2 col-md-2 estimate-value">
                                                            {{rack?.config?.processorCount}}</div>
                                                    </div>
                                                    <div>
                                                        <p class="power-supply-value"><span
                                                                class="icon-margin">▪</span>{{rack?.config?.processorTypeName}}
                                                        </p>
                                                    </div>
                                                    <div class="configure-container col-md-12">
                                                        <div class="col-xs-10 col-md-10 estimate-label">{{'MEMORY'
                                                            |translate}}
                                                        </div>
                                                        <div class="col-xs-2 col-md-2 estimate-value">
                                                            {{rack?.config?.memoryQuantity}}</div>
                                                    </div>
                                                    <div>
                                                        <div *ngFor="let memory of rack?.config?.memory"
                                                            class="dependent">
                                                            <div class="col-xs-10 col-md-10 dependent-label"><span
                                                                    class="icon-margin">▪</span>{{memory?.memoryTypeName
                                                                |translate}}
                                                            </div>
                                                            <div class="col-xs-2 col-md-2 dependent-value">
                                                                {{memory?.memoryCount}}</div>
                                                        </div>
                                                    </div>
                                                    <div class="configure-container col-md-12">
                                                        <div class="col-xs-10 col-md-10 estimate-label">{{'POWER_SUPPLY'
                                                            |translate}}
                                                        </div>
                                                        <div class="col-xs-2 col-md-2 estimate-value">
                                                            {{rack?.config?.powerSupplyCount}}</div>
                                                    </div>
                                                    <div>
                                                        <p class="power-supply-value"><span
                                                                class="icon-margin">▪</span>{{rack?.config?.powerSupplyTypeName}}
                                                        </p>
                                                    </div>

                                                    <div class="configure-container col-md-12"
                                                        *ngIf="rack?.config?.dedicatedStorageQuantity && rack?.config?.dedicatedStorageQuantity > 0">
                                                        <div class="col-xs-10 col-md-10 estimate-label">
                                                            {{'STORAGE_CONTROLLER'
                                                            |translate}}
                                                        </div>
                                                        <div class="col-xs-2 col-md-2 estimate-value">
                                                            {{rack?.config?.dedicatedStorageQuantity}}</div>
                                                    </div>
                                                    <div *ngFor="let ds of rack?.config?.dedicatedStorage"
                                                        class="dependent">
                                                        <div class="col-xs-10 col-md-10 dependent-label"><span
                                                                class="icon-margin">▪</span>{{ds?.mezzanineControllerTypeName
                                                            |translate}}
                                                        </div>
                                                        <div class="col-xs-2 col-md-2 dependent-value">
                                                            {{ds?.mezzanineControllerCount}}</div>
                                                    </div>

                                                    <div class="configure-container col-md-12"
                                                        *ngIf="rack?.config?.adapterQuantity && rack?.config?.adapterQuantity > 0">
                                                        <div class="col-xs-10 col-md-10 estimate-label">{{'ADAPTER'
                                                            |translate}}
                                                        </div>
                                                        <div class="col-xs-2 col-md-2 estimate-value">
                                                            {{rack?.config?.adapterQuantity}}</div>
                                                    </div>
                                                    <div>
                                                        <div *ngFor="let adapter of rack?.config?.adaptors"
                                                            class="dependent">
                                                            <div class="col-xs-10 col-md-10 dependent-label"><span
                                                                    class="icon-margin">▪</span>{{adapter?.adaptorTypeName
                                                                |translate}}
                                                            </div>
                                                            <div class="col-xs-2 col-md-2 dependent-value">
                                                                {{adapter?.adaptorCount}}</div>
                                                        </div>
                                                    </div>
                                                    <div class="configure-container col-md-12"
                                                        *ngIf="rack?.config?.storageQuantity && rack?.config?.storageQuantity > 0">
                                                        <div class="col-xs-10 col-md-10 estimate-label">{{'STORAGE'
                                                            |translate}}
                                                        </div>
                                                        <div class="col-xs-2 col-md-2 estimate-value">
                                                            {{rack?.config?.storageQuantity}}</div>
                                                    </div>
                                                    <div>
                                                        <div *ngFor="let storage of rack?.config?.storage"
                                                            class="dependent">
                                                            <div class="col-xs-10 col-md-10 dependent-label"><span
                                                                    class="icon-margin">▪</span>{{storage?.storageTypeName
                                                                |translate}}
                                                            </div>
                                                            <div class="col-xs-2 col-md-2 dependent-value">
                                                                {{storage?.storageCount}}</div>
                                                        </div>
                                                    </div>

                                                    <!-- additional dynamic components -->
                                                    <div>
                                                        <div
                                                            *ngFor="let additionalComp of getAdditionalComponents(rack)">
                                                            <div class="configure-container col-md-12"
                                                                *ngIf="additionalComp.length > 0">
                                                                <div class="col-xs-10 col-md-10">
                                                                    {{additionalComp[0].label | translate }}</div>
                                                                <div class="col-xs-2 col-md-2">{{
                                                                    getTotalQuantity(additionalComp) }} </div>
                                                            </div>
                                                            <div class="dependent" *ngFor="let comp of additionalComp">
                                                                <div class="col-xs-10 col-md-10 dependent-label"><span
                                                                        class="icon-margin">▪</span>{{comp?.configuration
                                                                    |translate}}
                                                                </div>
                                                                <div class="col-xs-2 col-md-2 dependent-value">
                                                                    {{comp?.quantity}}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="server-estimate-div">
                                                    <div class="power-estimate-div row">
                                                        <p class="power-estimate-header col-xs-12 col-md-12">
                                                            {{'POWER_ESTIMATE' |
                                                            translate}}</p>
                                                    </div>
                                                    <div class="row estimate-row">
                                                        <div class="col-xs-7 col-md-7 estimate-label">
                                                            {{'MAX_INPUT_POWER'
                                                            |translate}}</div>
                                                        <div class="col-md-3 col-xs-3 estimate-value">
                                                            {{rack?.power?.maxInputPower|number:'1.2-2'}}</div>
                                                        <div class="col-xs-2 col-md-2 estimate-value">W</div>
                                                    </div>
                                                    <div class="row estimate-row">
                                                        <div class="col-xs-7 col-md-7 estimate-label">{{'INPUT_POWER'
                                                            |translate}}
                                                        </div>
                                                        <div class="col-md-3 col-xs-3 estimate-value">
                                                            {{rack?.power?.inputPower |number:'1.2-2'}}</div>
                                                        <div class="col-xs-2 col-md-2 estimate-value">
                                                            {{rack?.power?.imperial?.powerUnit}}</div>
                                                    </div>
                                                    <div class="row estimate-row">
                                                        <div class="col-xs-7 col-md-7 estimate-label">
                                                            {{'IDLE_INPUT_POWER'
                                                            |translate}}</div>
                                                        <div class="col-md-3 col-xs-3 estimate-value">
                                                            {{rack?.power?.idleInputPower |number:'1.2-2'}}</div>
                                                        <div class="col-xs-2 col-md-2 estimate-value">
                                                            {{rack?.power?.imperial?.powerUnit}}</div>
                                                    </div>
                                                    <div class="row estimate-row">
                                                        <div class="col-xs-7 col-md-7 estimate-label">
                                                            {{'SYSTEM_WORKLOAD'
                                                            |translate}}</div>
                                                        <div class="col-md-3 col-xs-3 estimate-value">
                                                            {{rack?.power?.systemWorkloadPercent
                                                            |number:'1.2-2'}}</div>
                                                        <div class="col-xs-2 col-md-2 estimate-value">
                                                            {{rack?.power?.imperial?.systemWorkload}}</div>
                                                    </div>
                                                    <div class="row estimate-row">
                                                        <div class="col-xs-7 col-md-7 estimate-label">{{'INPUT_CURRENT'
                                                            |translate}}
                                                        </div>
                                                        <div class="col-md-3 col-xs-3 estimate-value">
                                                            {{rack?.power?.inputCurrentLoad |number:'1.2-2'}}</div>
                                                        <div class="col-xs-2 col-md-2 estimate-value">
                                                            {{rack?.power?.imperial?.currentUnit}}</div>
                                                    </div>
                                                    <div class="row estimate-row" *ngIf="rack?.power?.legacyPlatform">
                                                        <div class="col-xs-7 col-md-7 estimate-label">{{'AIR_FLOW'
                                                            |translate}}
                                                        </div>
                                                        <div class="col-md-3 col-xs-3 estimate-value"
                                                            *ngIf="(globalUnit == 'Imperial')">
                                                            {{rack?.power?.inputAirflow?.airflowImperial
                                                            |number:'1.2-2'}} </div>
                                                        <div class="col-md-3 col-xs-3 estimate-value"
                                                            *ngIf="(globalUnit == 'Metric')">
                                                            {{rack?.power?.inputAirflow?.airflowMetric |number:'1.2-2'}}
                                                        </div>
                                                        <div class="col-xs-2 col-md-2 estimate-value"
                                                            *ngIf="(globalUnit == 'Imperial')">
                                                            {{rack?.power?.imperial?.airflowUnit}}</div>
                                                        <div class="col-xs-2 col-md-2 estimate-value"
                                                            *ngIf="(globalUnit == 'Metric')">
                                                            {{rack?.power?.metric?.airflowUnit}}</div>
                                                    </div>
                                                    <div class="row estimate-row"
                                                        *ngIf="rack?.power?.legacyPlatform == false">
                                                        <div class="col-md-7 estimate-label">{{'AIR_FLOW_MIN'
                                                            |translate}}</div>
                                                        <div class="col-md-3 estimate-value"
                                                            *ngIf="(globalUnit == 'Imperial')">
                                                            {{rack?.power?.airFlowRange?.minAirflowImperial | number :
                                                            '1.2-2'}}</div>
                                                        <div class="col-md-3 estimate-value"
                                                            *ngIf="(globalUnit == 'Metric')">
                                                            {{rack?.power?.airFlowRange?.minAirflowMetric | number :
                                                            '1.2-2'}}</div>
                                                        <div class="col-xs-2 col-md-2 estimate-value"
                                                            *ngIf="(globalUnit == 'Imperial')">
                                                            {{rack?.power?.imperial?.airflowUnit}}</div>
                                                        <div class="col-xs-2 col-md-2 estimate-value"
                                                            *ngIf="(globalUnit == 'Metric')">
                                                            {{rack?.power?.metric?.airflowUnit}}</div>
                                                    </div>
                                                    <div class="row estimate-row"
                                                        *ngIf="rack?.power?.legacyPlatform == false">
                                                        <div class="col-md-7 estimate-label">{{'AIR_FLOW_MAX'
                                                            |translate}}</div>
                                                        <div class="col-md-3 estimate-value"
                                                            *ngIf="(globalUnit == 'Imperial')">
                                                            {{rack?.power?.airFlowRange?.maxAirflowImperial | number :
                                                            '1.2-2'}}</div>
                                                        <div class="col-md-3 estimate-value"
                                                            *ngIf="(globalUnit == 'Metric')">
                                                            {{rack?.power?.airFlowRange?.maxAirflowMetric | number :
                                                            '1.2-2'}}</div>
                                                        <div class="col-xs-2 col-md-2 estimate-value"
                                                            *ngIf="(globalUnit == 'Imperial')">
                                                            {{rack?.power?.imperial?.airflowUnit}}</div>
                                                        <div class="col-xs-2 col-md-2 estimate-value"
                                                            *ngIf="(globalUnit == 'Metric')">
                                                            {{rack?.power?.metric?.airflowUnit}}</div>
                                                    </div>
                                                    <div class="row estimate-row"
                                                        *ngIf="rack?.power?.legacyPlatform == false">
                                                        <div class="col-md-7 estimate-label">{{'ACOUSTICS_MIN'
                                                            |translate}}</div>
                                                        <div class="col-md-3 estimate-value">
                                                            {{rack?.power?.acousticsRange?.minAcoustics | number :
                                                            '1.2-2'}}</div>
                                                        <div class="col-xs-1 col-md-2 estimate-value">
                                                            {{rack?.power?.imperial?.acousticsUnit}}</div>
                                                    </div>
                                                    <div class="row estimate-row"
                                                        *ngIf="rack?.power?.legacyPlatform == false">
                                                        <div class="col-md-7 estimate-label">{{'ACOUSTICS_MAX'
                                                            |translate}}</div>
                                                        <div class="col-md-3 estimate-value">
                                                            {{rack?.power?.acousticsRange?.maxAcoustics | number :
                                                            '1.2-2'}}</div>
                                                        <div class="col-xs-1 col-md-2 estimate-value">
                                                            {{rack?.power?.imperial?.acousticsUnit}}</div>
                                                    </div>
                                                    <div class="row estimate-row">
                                                        <div class="col-xs-7 col-md-7 estimate-label">{{'COOLING'
                                                            |translate}}</div>
                                                        <div class="col-md-3 col-xs-3 estimate-value"
                                                            *ngIf="(globalUnit == 'Imperial')">
                                                            {{rack?.power?.inputCooling?.imperialCooling
                                                            |number:'1.2-2'}}</div>
                                                        <div class="col-md-3 col-xs-3 estimate-value"
                                                            *ngIf="(globalUnit == 'Metric')">
                                                            {{rack?.power?.inputCooling?.imperialCooling
                                                            |number:'1.2-2'}}</div>
                                                        <div class="col-xs-2 col-md-2 estimate-value"
                                                            *ngIf="(globalUnit == 'Imperial')">
                                                            {{rack?.power?.imperial?.coolingUnit}}</div>
                                                        <div class="col-xs-2 col-md-2 estimate-value"
                                                            *ngIf="(globalUnit == 'Metric')">
                                                            {{rack?.power?.imperial?.coolingUnit}}</div>
                                                    </div>
                                                    <div class="row estimate-row">
                                                        <div class="col-xs-7 col-md-7 estimate-label">{{'WEIGHT'
                                                            |translate}}</div>
                                                        <div class="col-md-3 col-xs-3 estimate-value"
                                                            *ngIf="(globalUnit == 'Imperial')">
                                                            {{rack?.power?.weightImperial |number:'1.2-2'}}</div>
                                                        <div class="col-md-3 col-xs-3 estimate-value"
                                                            *ngIf="(globalUnit == 'Metric')">
                                                            {{rack?.power?.weightMetric |number:'1.2-2'}}</div>
                                                        <div class="col-xs-2 col-md-2 estimate-value"
                                                            *ngIf="(globalUnit == 'Imperial')">
                                                            {{rack?.power?.imperial?.weightUnit}}</div>
                                                        <div class="col-xs-2 col-md-2 estimate-value"
                                                            *ngIf="(globalUnit == 'Metric')">
                                                            {{rack?.power?.metric?.weightUnit}}</div>
                                                    </div>
                                                </div>
                                            </div>

                                        </li>
                                    </ul>
                                </div>
                            </li>
                    </div>
                </li>
            </ul>
            <!-- end dynamic list -->
            <div *ngIf="projectData.chassisList.length == 0 &&
                        projectData.mseriesList.length == 0 && projectData.rackServers.length == 0 && (!additionalPlatformsAdded() || !additionalPlatformsAddedByClass('Compute'))"
                class="no-data-div">
                <p>{{'NO_DATA' | translate}}</p>
            </div>
        </div>
        </li>
        <li [ngClass]="selectedClassId == 2 ? 'active' : ''" style="display:none">
            <a class="accordion__title" (click)="onClassSelect(2)">
                <span class="accordion__toggle icon-chevron-down icon-size-20 icon-margin"
                    style="margin-top: -7px;"></span>
                <h5>{{'STORAGE' | translate}}</h5>
            </a>
            <div class="accordion__content">
                <div *ngIf="projectData.fabrics.length == 0" class="no-data-div">
                    <p>{{'NO_DATA' | translate}}</p>
                </div>
            </div>
        </li>
        <li [ngClass]="selectedClassId == 3 ? 'active' : ''">
            <a class="accordion__title" (click)="onClassSelect(3)">
                <span class="accordion__toggle icon-chevron-down icon-size-20 icon-margin"
                    style="margin-top: -7px;"></span>
                <h5> {{'NETWORK' | translate}}</h5>
            </a>
            <div class="accordion__content">
                <ul class="accordion" style="margin-top: 10px;"
                    *ngFor="let seriesName of seriesNamesSet; let k = index">
                    <li [ngClass]="selectedSeriesId == 1 ? 'active' : ''"
                        *ngIf="filterList(projectData?.fabrics, seriesName).length > 0">
                        <a class="accordion__title accor-title" (click)="onSeriesSelect(1, k)"
                            style="margin-left: 2px;">
                            <span
                                [ngClass]="selectedSeriesId==1 ? 'accordion__toggle icon-chevron-down  icon-margin' : 'accordion__toggle icon-chevron-up icon-margin'"
                                style="margin-top: -7px;"></span>
                            <h5>{{ seriesName | translate}}</h5>
                        </a>
                        <div *ngIf="selectedSeriesId==1 && selectedSeriesNameIdx == k"
                            class="accordion__content accordion_contennt">
                            <ul class="accordion" style="margin-top: 10px;">
                                <li *ngFor="let fabricI of filterList(projectData.fabrics, seriesName); let j = index">
                                    <div class="form-group form-group--inline">
                                        <div class="col-md-9" style="padding-left:2px">
                                            <a class="accordion__title accordian-server-name"
                                                (click)="onServerSelect('J',j,k,fabricI)" id="fabricI{{j}}">

                                                <span class="toggle-icon" style="margin-right: 0px;"
                                                    [ngClass]="selectedServerId.includes('J'+j+k) ? 'accordion__toggle icon-chevron-down' : 'accordion__toggle icon-chevron-up'"></span>
                                                <span *ngIf="doesServerHaveRedundancyError(fabricI)"
                                                    class="icon-warning redundancy-warning"></span>
                                                <div
                                                    *ngIf="fabricI.serverId ==editServerElementName; then editTheServer  else showTheServer">
                                                </div>
                                                <ng-template #showTheServer><span
                                                        style="margin-left:1px;white-space: normal;"
                                                        [ngClass]="doesServerHaveRedundancyError(fabricI) ? 'redundancy-warning-error' : ''">{{fabricI?.userProvidedName}}</span>
                                                    <span style="margin-left: 10px;
                                            font-size: x-small; color: grey;
                                            margin-top: -5px;">({{fabricI?.displayName}})</span>
                                                </ng-template>
                                                <ng-template #editTheServer>
                                                    <div class="form-group__text col-md-6">
                                                        <input id="input-server{{j}}" type="text" style="width: 600px;"
                                                            [(ngModel)]="fabricI.userProvidedName"
                                                            (blur)="blurServer($event, fabricI.id, fabricI.type,fabricI?.serverId, '')"
                                                            (change)="changeServerName($event,fabricI.id, fabricI.type, fabricI?.serverId)">

                                                    </div>
                                                </ng-template>
                                            </a>
                                        </div>
                                        <div class="form-group__text col-md-3"
                                            style="padding-top: 4px; padding-right:0; padding-left: 0; margin-left:-5px;"
                                            [attr.disabled]="(isServerEdited && !isCurrentServer(fabricI)) ? true : null"
                                            *ngIf="fabricI.serverId !==editServerElementName">
                                            <input id="input-quantity{{j}}" type="number"
                                                style="width: 30px; padding: 2px 2px;"
                                                [(ngModel)]="fabricI.config.quantity" min="1" autocomplete="off"
                                                [ngClass]="fabricI.config?.flag ? 'select-danger': 'select-normal'"
                                                (change)="changeServerQuantity($event,fabricI.id, fabricI.type, fabricI?.serverId)">

                                            <a *ngIf="fabricI.serverId !==editServerElementName"><span
                                                    class="icon-edit icon-size-16" style="margin: 5px;color:#00bceb"
                                                    (click)="editServer(fabricI.id,fabricI.type,fabricI?.serverId)"></span></a>
                                            <a *ngIf="fabricI.serverId !==editServerElementName"> <span
                                                    class="icon-delete icon-size-16 icon-delete"
                                                    style="margin: 5px;color:#e2231a;"
                                                    (click)="deleteChasssisOpenModal(fabricI.id,fabricI.type , fabricI?.serverId)"></span></a>
                                        </div>
                                    </div>
                                    <div class="accordion__content"
                                        [ngClass]="selectedServerId.includes('J'+j+k) ? 'li-active' : 'li-inactive'">
                                        <div class="power-config-div row">
                                            <p class="power-config-header col-xs-12 col-md-12">{{'CONFIGURATION'
                                                |
                                                translate}}</p>
                                        </div>
                                        <div class="power-supply-container">
                                            <div class="configure-container col-md-12 base-margin-bottom">
                                                <div class="col-xs-9 col-md-9 estimate-label">{{'INPUT_VOLTAGE'
                                                    |translate}}
                                                </div>
                                                <div class="col-xs-3 col-md-3 estimate-value input-voltage">
                                                    {{fabricI?.config?.voltageName}}</div>
                                            </div>
                                            <div class="configure-container col-md-12 base-margin-bottom">
                                                <div class="col-xs-10 col-md-10 estimate-label">{{'REDUNDANCY_MODE'
                                                    |translate}}
                                                </div>
                                                <div class="col-xs-2 col-md-2 estimate-value redundancy-mode">
                                                    {{displayFormattedRMode(fabricI?.config?.redundancyMode)}}</div>
                                            </div>
                                            <div class="configure-container col-md-12">
                                                <div class="col-xs-10 col-md-10 estimate-label">{{'POWER_SUPPLY'
                                                    |translate}}
                                                </div>
                                                <div class="col-xs-2 col-md-2 estimate-value">
                                                    {{fabricI?.config?.powerSupplyCount}}</div>
                                            </div>
                                            <div>
                                                <p class="power-supply-value"><span
                                                        class="icon-margin">▪</span>{{fabricI?.config?.powerSupplyTypeName}}
                                                </p>
                                            </div>
                                            <div class="configure-container col-md-12"
                                                *ngIf="fabricI?.config?.expansionQuantity > 0">
                                                <div class="col-xs-10 col-md-10 estimate-label">
                                                    {{'EXPANSION_MODULES'
                                                    |translate}}
                                                </div>
                                                <div class="col-xs-2 col-md-2 estimate-value">
                                                    {{fabricI?.config?.expansionQuantity}}</div>
                                            </div>
                                            <div>
                                                <div *ngFor="let expansion of fabricI?.config?.expansionCards"
                                                    class="dependent">
                                                    <div class="col-xs-10 col-md-10 dependent-label"><span
                                                            class="icon-margin">▪</span>{{expansion?.extenderTypeName
                                                        |translate}}
                                                    </div>
                                                    <div class="col-xs-2 col-md-4 dependent-value">
                                                        {{expansion?.quantity}}</div>
                                                </div>
                                            </div>

                                            <div class="configure-container col-md-12"
                                                *ngIf="fabricI?.config?.activePorts > 0">
                                                <div class="col-xs-10 col-md-10 estimate-label">
                                                    {{'PORTS_CONFIGURED'
                                                    |translate}}
                                                </div>
                                                <div class="col-xs-2 col-md-2 estimate-value">
                                                    {{fabricI?.config?.activePorts}}</div>
                                            </div>

                                            <!-- additional dynamic components -->
                                            <div>
                                                <div *ngFor="let additionalComp of getAdditionalComponents(fabricI)">
                                                    <div class="configure-container col-md-12"
                                                        *ngIf="additionalComp.length > 0">
                                                        <div class="col-xs-10 col-md-10">
                                                            {{additionalComp[0].label | translate }}</div>
                                                        <div class="col-xs-2 col-md-2">{{
                                                            getTotalQuantity(additionalComp) }} </div>
                                                    </div>
                                                    <div class="dependent" *ngFor="let comp of additionalComp">
                                                        <div class="col-xs-10 col-md-10 dependent-label"><span
                                                                class="icon-margin">▪</span>{{comp?.configuration
                                                            |translate}}
                                                        </div>
                                                        <div class="col-xs-2 col-md-2 dependent-value">
                                                            {{comp?.quantity}}</div>
                                                    </div>
                                                </div>
                                            </div>


                                        </div>
                                        <div class="server-estimate-div">
                                            <div class="power-estimate-div row">
                                                <p class="power-estimate-header col-xs-12 col-md-12">
                                                    {{'POWER_ESTIMATE' |
                                                    translate}}</p>
                                            </div>
                                            <div class="row estimate-row">
                                                <div class="col-xs-7 col-md-7 estimate-label">
                                                    {{'MAX_INPUT_POWER'
                                                    |translate}}</div>
                                                <div class="col-md-3 col-xs-3 estimate-value">
                                                    {{fabricI?.power?.maxInputPower |number:'1.2-2'}}</div>
                                                <div class="col-xs-2 col-md-2 estimate-value">
                                                    {{fabricI?.power?.imperial?.powerUnit}}</div>
                                            </div>
                                            <div class="row estimate-row">
                                                <div class="col-xs-7 col-md-7 estimate-label">{{'INPUT_POWER'
                                                    |translate}}
                                                </div>
                                                <div class="col-md-3 col-xs-3 estimate-value">
                                                    {{fabricI?.power?.inputPower |number:'1.2-2'}}</div>
                                                <div class="col-xs-2 col-md-2 estimate-value">
                                                    {{fabricI?.power?.imperial?.powerUnit}}</div>
                                            </div>
                                            <div class="row estimate-row">
                                                <div class="col-xs-7 col-md-7 estimate-label">
                                                    {{'IDLE_INPUT_POWER'
                                                    |translate}}</div>
                                                <div class="col-md-3 col-xs-3 estimate-value">
                                                    {{fabricI?.power?.idleInputPower |number:'1.2-2'}}</div>
                                                <div class="col-xs-2 col-md-2 estimate-value">
                                                    {{fabricI?.power?.imperial?.powerUnit}}</div>
                                            </div>
                                            <div class="row estimate-row">
                                                <div class="col-xs-7 col-md-7 estimate-label">
                                                    {{'SYSTEM_WORKLOAD'
                                                    |translate}}</div>
                                                <div class="col-md-3 col-xs-3 estimate-value">
                                                    {{fabricI?.power?.systemWorkloadPercent
                                                    |number:'1.2-2'}}</div>
                                                <div class="col-xs-2 col-md-2 estimate-value">
                                                    {{fabricI?.power?.imperial?.systemWorkload}}</div>
                                            </div>
                                            <div class="row estimate-row">
                                                <div class="col-xs-7 col-md-7 estimate-label">{{'INPUT_CURRENT'
                                                    |translate}}
                                                </div>
                                                <div class="col-md-3 col-xs-3 estimate-value">
                                                    {{fabricI?.power?.inputCurrentLoad |number:'1.2-2'}}</div>
                                                <div class="col-xs-2 col-md-2 estimate-value">
                                                    {{fabricI?.power?.imperial?.currentUnit}}</div>
                                            </div>
                                            <div class="row estimate-row" *ngIf="fabricI?.power?.legacyPlatform">
                                                <div class="col-xs-7 col-md-7 estimate-label">{{'AIR_FLOW'
                                                    |translate}}
                                                </div>
                                                <div class="col-md-3 col-xs-3 estimate-value"
                                                    *ngIf="(globalUnit == 'Imperial')">
                                                    {{fabricI?.power?.inputAirflow?.airflowImperial
                                                    |number:'1.2-2'}} </div>
                                                <div class="col-md-3 col-xs-3 estimate-value"
                                                    *ngIf="(globalUnit == 'Metric')">
                                                    {{fabricI?.power?.inputAirflow?.airflowMetric
                                                    |number:'1.2-2'}} </div>
                                                <div class="col-xs-2 col-md-2 estimate-value"
                                                    *ngIf="(globalUnit == 'Imperial')">
                                                    {{fabricI?.power?.imperial?.airflowUnit}}</div>
                                                <div class="col-xs-2 col-md-2 estimate-value"
                                                    *ngIf="(globalUnit == 'Metric')">
                                                    {{fabricI?.power?.metric?.airflowUnit}}</div>
                                            </div>
                                            <div class="row estimate-row"
                                                *ngIf="fabricI?.power?.legacyPlatform == false">
                                                <div class="col-md-7 estimate-label">{{'AIR_FLOW_MIN'
                                                    |translate}}</div>
                                                <div class="col-md-3 estimate-value" *ngIf="(globalUnit == 'Imperial')">
                                                    {{fabricI?.power?.airFlowRange?.minAirflowImperial | number
                                                    :
                                                    '1.2-2'}}</div>
                                                <div class="col-md-3 estimate-value" *ngIf="(globalUnit == 'Metric')">
                                                    {{fabricI?.power?.airFlowRange?.minAirflowMetric | number :
                                                    '1.2-2'}}</div>
                                                <div class="col-xs-2 col-md-2 estimate-value"
                                                    *ngIf="(globalUnit == 'Imperial')">
                                                    {{fabricI?.power?.imperial?.airflowUnit}}</div>
                                                <div class="col-xs-2 col-md-2 estimate-value"
                                                    *ngIf="(globalUnit == 'Metric')">
                                                    {{fabricI?.power?.metric?.airflowUnit}}</div>
                                            </div>
                                            <div class="row estimate-row"
                                                *ngIf="fabricI?.power?.legacyPlatform == false">
                                                <div class="col-md-7 estimate-label">{{'AIR_FLOW_MAX'
                                                    |translate}}</div>
                                                <div class="col-md-3 estimate-value" *ngIf="(globalUnit == 'Imperial')">
                                                    {{fabricI?.power?.airFlowRange?.maxAirflowImperial | number
                                                    :
                                                    '1.2-2'}}</div>
                                                <div class="col-md-3 estimate-value" *ngIf="(globalUnit == 'Metric')">
                                                    {{fabricI?.power?.airFlowRange?.maxAirflowMetric | number :
                                                    '1.2-2'}}</div>
                                                <div class="col-xs-2 col-md-2 estimate-value"
                                                    *ngIf="(globalUnit == 'Imperial')">
                                                    {{fabricI?.power?.imperial?.airflowUnit}}</div>
                                                <div class="col-xs-2 col-md-2 estimate-value"
                                                    *ngIf="(globalUnit == 'Metric')">
                                                    {{fabricI?.power?.metric?.airflowUnit}}</div>
                                            </div>
                                            <div class="row estimate-row"
                                                *ngIf="fabricI?.power?.legacyPlatform == false">
                                                <div class="col-md-7 estimate-label">{{'ACOUSTICS_MIN'
                                                    |translate}}</div>
                                                <div class="col-md-3 estimate-value">
                                                    {{fabricI?.power?.acousticsRange?.minAcoustics | number :
                                                    '1.2-2'}}</div>
                                                <div class="col-xs-1 col-md-2 estimate-value">
                                                    {{fabricI?.power?.imperial?.acousticsUnit}}</div>
                                            </div>
                                            <div class="row estimate-row"
                                                *ngIf="fabricI?.power?.legacyPlatform == false">
                                                <div class="col-md-7 estimate-label">{{'ACOUSTICS_MAX'
                                                    |translate}}</div>
                                                <div class="col-md-3 estimate-value">
                                                    {{fabricI?.power?.acousticsRange?.maxAcoustics | number :
                                                    '1.2-2'}}</div>
                                                <div class="col-xs-1 col-md-2 estimate-value">
                                                    {{fabricI?.power?.imperial?.acousticsUnit}}</div>
                                            </div>
                                            <div class="row estimate-row">
                                                <div class="col-xs-7 col-md-7 estimate-label">{{'COOLING'
                                                    |translate}}</div>
                                                <div class="col-md-3 col-xs-3 estimate-value"
                                                    *ngIf="(globalUnit == 'Imperial')">
                                                    {{fabricI?.power?.inputCooling?.imperialCooling
                                                    |number:'1.2-2'}}</div>
                                                <div class="col-md-3 col-xs-3 estimate-value"
                                                    *ngIf="(globalUnit == 'Metric')">
                                                    {{fabricI?.power?.inputCooling?.imperialCooling
                                                    |number:'1.2-2'}}</div>
                                                <div class="col-xs-2 col-md-2 estimate-value"
                                                    *ngIf="(globalUnit == 'Imperial')">
                                                    {{fabricI?.power?.imperial?.coolingUnit}}</div>
                                                <div class="col-xs-2 col-md-2 estimate-value"
                                                    *ngIf="(globalUnit == 'Metric')">
                                                    {{fabricI?.power?.imperial?.coolingUnit}}</div>
                                            </div>
                                            <div class="row estimate-row">
                                                <div class="col-xs-7 col-md-7 estimate-label">{{'WEIGHT'
                                                    |translate}}</div>
                                                <div class="col-md-3 col-xs-3 estimate-value"
                                                    *ngIf="(globalUnit == 'Imperial')">
                                                    {{fabricI?.power?.weightImperial |number:'1.2-2'}}</div>
                                                <div class="col-md-3 col-xs-3 estimate-value"
                                                    *ngIf="(globalUnit == 'Metric')">
                                                    {{fabricI?.power?.weightMetric |number:'1.2-2'}}</div>
                                                <div class="col-xs-2 col-md-2 estimate-value"
                                                    *ngIf="(globalUnit == 'Imperial')">
                                                    {{fabricI?.power?.imperial?.weightUnit}}</div>
                                                <div class="col-xs-2 col-md-2 estimate-value"
                                                    *ngIf="(globalUnit == 'Metric')">
                                                    {{fabricI?.power?.metric?.weightUnit}}</div>

                                            </div>
                                        </div>
                                    </div>

                                </li>

                            </ul>


                        </div>
                    </li>

                    <!-- additional platforms -->
                    <li *ngFor="let i of enumerate(5); let idx=index;">
                        <div
                            *ngIf="doesAdditionalPlatformExist(idx+1) && getAdditionalPlatformPrimaryClass(idx+1)=='Networking'">
                    <li [ngClass]="selectedSeriesId == idx*10 && selectedSeriesNameIdx == k ? 'active' : ''"
                        *ngIf="filterList(getAdditionalPlatform(idx+1), seriesName).length > 0">
                        <a class="accordion__title accor-title" (click)="onSeriesSelect(idx*10, k)"
                            style="margin-left: 2px;">
                            <span
                                [ngClass]="selectedSeriesId==idx*10 && selectedSeriesNameIdx == k ? 'accordion__toggle icon-chevron-down  icon-margin' : 'accordion__toggle icon-chevron-up icon-margin'"
                                style="margin-top: -7px;"></span>
                            <h5>{{ seriesName | translate}}</h5>
                        </a>
                        <div *ngIf="selectedSeriesId==idx*10 && selectedSeriesNameIdx == k" class="accordion__content">
                            <ul class="accordion" style="margin-top: 10px;">
                                <li
                                    *ngFor="let rack of filterList(getAdditionalPlatform(idx+1), seriesName); let c = index">
                                    <div class="form-group form-group--inline">
                                        <div class="col-md-9" style="padding-left:2px">
                                            <a class="accordion__title accordian-server-name"
                                                (click)="onServerSelect('AP',c,k,rack)" id="rack{{c}}">

                                                <span class="toggle-icon" style="margin-right: 0px;"
                                                    [ngClass]="selectedServerId.includes('AP'+c+k) ? 'accordion__toggle icon-chevron-down' : 'accordion__toggle icon-chevron-up'"></span>
                                                <span *ngIf="doesServerHaveRedundancyError(rack)"
                                                    class="icon-warning redundancy-warning"></span>
                                                <div
                                                    *ngIf="rack.serverId ==editServerElementName; then editTheServer  else showTheServer">
                                                </div>
                                                <ng-template #showTheServer><span
                                                        style="margin-left:1px;white-space: normal;"
                                                        [ngClass]="doesServerHaveRedundancyError(rack) ? 'redundancy-warning-error' : ''">{{rack?.userProvidedName}}</span>
                                                    <span style="margin-left: 10px;
                                                            font-size: x-small; color: grey;
                                                            margin-top: -5px;">({{rack?.displayName}})</span>
                                                </ng-template>
                                                <ng-template #editTheServer>
                                                    <div class="form-group__text col-md-6">
                                                        <input id="input-server{{c}}" type="text" style="width: 600px;"
                                                            [(ngModel)]="rack.userProvidedName"
                                                            (blur)="blurServer($event, rack.id, rack.serverType,rack.serverId, '')"
                                                            (change)="changeServerName($event,rack.id,rack.serverType, rack?.serverId)">

                                                    </div>
                                                </ng-template>
                                            </a>
                                        </div>
                                        <div class="form-group__text col-md-3"
                                            style="padding-top: 4px; padding-right:0; padding-left: 0; margin-left:-5px;"
                                            [attr.disabled]="(isServerEdited && !isCurrentServer(rack)) ? true : null"
                                            *ngIf="rack.serverId !==editServerElementName">
                                            <input id="input-quantity{{c}}" type="number"
                                                style="width: 30px; padding: 2px 2px;"
                                                [(ngModel)]="rack.config.quantity" min="1" autocomplete="off"
                                                [ngClass]="rack.config?.flag ? 'select-danger': 'select-normal'"
                                                (change)="changeServerQuantity($event,rack.id ,rack.serverType, rack.serverId)">

                                            <a *ngIf="rack.serverId !==editServerElementName"><span
                                                    class="icon-edit icon-size-16" style="margin: 5px;color:#00bceb"
                                                    (click)="editServer(rack.id, rack.serverType, rack.serverId)"></span></a>
                                            <a *ngIf="rack.serverId !==editServerElementName"> <span
                                                    class="icon-delete icon-size-16 icon-delete"
                                                    style="margin: 5px;color:#e2231a;"
                                                    (click)="deleteChasssisOpenModal(rack.id, rack.serverType, rack.serverId)"></span></a>
                                        </div>
                                    </div>
                                    <div class="accordion__content"
                                        [ngClass]="selectedServerId.includes('AP'+c+k) ? 'li-active' : 'li-inactive'">
                                        <div class="power-config-div row">
                                            <p class="power-config-header col-xs-12 col-md-12">{{'CONFIGURATION'
                                                |
                                                translate}}</p>
                                        </div>
                                        <div class="power-supply-container">
                                            <div class="configure-container col-md-12 base-margin-bottom">
                                                <div class="col-xs-9 col-md-9 estimate-label">{{'INPUT_VOLTAGE'
                                                    |translate}}
                                                </div>
                                                <div class="col-xs-3 col-md-3 estimate-value input-voltage">
                                                    {{rack?.config?.voltageName}}</div>
                                            </div>
                                            <div class="configure-container col-md-12 base-margin-bottom">
                                                <div class="col-xs-10 col-md-10 estimate-label">{{'REDUNDANCY_MODE'
                                                    |translate}}
                                                </div>
                                                <div class="col-xs-2 col-md-2 estimate-value redundancy-mode">
                                                    {{displayFormattedRMode(rack?.config?.redundancyMode)}}</div>
                                            </div>
                                            <div class="configure-container col-md-12">
                                                <div class="col-xs-10 col-md-10 estimate-label">{{'PROCESSOR'
                                                    |translate}}
                                                </div>
                                                <div class="col-xs-2 col-md-2 estimate-value">
                                                    {{rack?.config?.processorCount}}</div>
                                            </div>
                                            <div>
                                                <p class="power-supply-value"><span
                                                        class="icon-margin">▪</span>{{rack?.config?.processorTypeName}}
                                                </p>
                                            </div>
                                            <div class="configure-container col-md-12">
                                                <div class="col-xs-10 col-md-10 estimate-label">{{'MEMORY'
                                                    |translate}}
                                                </div>
                                                <div class="col-xs-2 col-md-2 estimate-value">
                                                    {{rack?.config?.memoryQuantity}}</div>
                                            </div>
                                            <div>
                                                <div *ngFor="let memory of rack?.config?.memory" class="dependent">
                                                    <div class="col-xs-10 col-md-10 dependent-label"><span
                                                            class="icon-margin">▪</span>{{memory?.memoryTypeName
                                                        |translate}}
                                                    </div>
                                                    <div class="col-xs-2 col-md-2 dependent-value">
                                                        {{memory?.memoryCount}}</div>
                                                </div>
                                            </div>
                                            <div class="configure-container col-md-12">
                                                <div class="col-xs-10 col-md-10 estimate-label">{{'POWER_SUPPLY'
                                                    |translate}}
                                                </div>
                                                <div class="col-xs-2 col-md-2 estimate-value">
                                                    {{rack?.config?.powerSupplyCount}}</div>
                                            </div>
                                            <div>
                                                <p class="power-supply-value"><span
                                                        class="icon-margin">▪</span>{{rack?.config?.powerSupplyTypeName}}
                                                </p>
                                            </div>

                                            <div class="configure-container col-md-12"
                                                *ngIf="rack?.config?.dedicatedStorageQuantity && rack?.config?.dedicatedStorageQuantity > 0">
                                                <div class="col-xs-10 col-md-10 estimate-label">
                                                    {{'STORAGE_CONTROLLER'
                                                    |translate}}
                                                </div>
                                                <div class="col-xs-2 col-md-2 estimate-value">
                                                    {{rack?.config?.dedicatedStorageQuantity}}</div>
                                            </div>
                                            <div *ngFor="let ds of rack?.config?.dedicatedStorage" class="dependent">
                                                <div class="col-xs-10 col-md-10 dependent-label"><span
                                                        class="icon-margin">▪</span>{{ds?.mezzanineControllerTypeName
                                                    |translate}}
                                                </div>
                                                <div class="col-xs-2 col-md-2 dependent-value">
                                                    {{ds?.mezzanineControllerCount}}</div>
                                            </div>

                                            <div class="configure-container col-md-12"
                                                *ngIf="rack?.config?.adapterQuantity && rack?.config?.adapterQuantity > 0">
                                                <div class="col-xs-10 col-md-10 estimate-label">{{'ADAPTER'
                                                    |translate}}
                                                </div>
                                                <div class="col-xs-2 col-md-2 estimate-value">
                                                    {{rack?.config?.adapterQuantity}}</div>
                                            </div>
                                            <div>
                                                <div *ngFor="let adapter of rack?.config?.adaptors" class="dependent">
                                                    <div class="col-xs-10 col-md-10 dependent-label"><span
                                                            class="icon-margin">▪</span>{{adapter?.adaptorTypeName
                                                        |translate}}
                                                    </div>
                                                    <div class="col-xs-2 col-md-2 dependent-value">
                                                        {{adapter?.adaptorCount}}</div>
                                                </div>
                                            </div>
                                            <div class="configure-container col-md-12"
                                                *ngIf="rack?.config?.storageQuantity && rack?.config?.storageQuantity > 0">
                                                <div class="col-xs-10 col-md-10 estimate-label">{{'STORAGE'
                                                    |translate}}
                                                </div>
                                                <div class="col-xs-2 col-md-2 estimate-value">
                                                    {{rack?.config?.storageQuantity}}</div>
                                            </div>
                                            <div>
                                                <div *ngFor="let storage of rack?.config?.storage" class="dependent">
                                                    <div class="col-xs-10 col-md-10 dependent-label"><span
                                                            class="icon-margin">▪</span>{{storage?.storageTypeName
                                                        |translate}}
                                                    </div>
                                                    <div class="col-xs-2 col-md-2 dependent-value">
                                                        {{storage?.storageCount}}</div>
                                                </div>
                                            </div>

                                            <!-- additional dynamic components -->
                                            <div>
                                                <div *ngFor="let additionalComp of getAdditionalComponents(rack)">
                                                    <div class="configure-container col-md-12"
                                                        *ngIf="additionalComp.length > 0">
                                                        <div class="col-xs-10 col-md-10">
                                                            {{additionalComp[0].label | translate }}</div>
                                                        <div class="col-xs-2 col-md-2">{{
                                                            getTotalQuantity(additionalComp) }} </div>
                                                    </div>
                                                    <div class="dependent" *ngFor="let comp of additionalComp">
                                                        <div class="col-xs-10 col-md-10 dependent-label"><span
                                                                class="icon-margin">▪</span>{{comp?.configuration
                                                            |translate}}
                                                        </div>
                                                        <div class="col-xs-2 col-md-2 dependent-value">
                                                            {{comp?.quantity}}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="server-estimate-div">
                                            <div class="power-estimate-div row">
                                                <p class="power-estimate-header col-xs-12 col-md-12">
                                                    {{'POWER_ESTIMATE' |
                                                    translate}}</p>
                                            </div>
                                            <div class="row estimate-row">
                                                <div class="col-xs-7 col-md-7 estimate-label">
                                                    {{'MAX_INPUT_POWER'
                                                    |translate}}</div>
                                                <div class="col-md-3 col-xs-3 estimate-value">
                                                    {{rack?.power?.maxInputPower|number:'1.2-2'}}</div>
                                                <div class="col-xs-2 col-md-2 estimate-value">W</div>
                                            </div>
                                            <div class="row estimate-row">
                                                <div class="col-xs-7 col-md-7 estimate-label">{{'INPUT_POWER'
                                                    |translate}}
                                                </div>
                                                <div class="col-md-3 col-xs-3 estimate-value">
                                                    {{rack?.power?.inputPower |number:'1.2-2'}}</div>
                                                <div class="col-xs-2 col-md-2 estimate-value">
                                                    {{rack?.power?.imperial?.powerUnit}}</div>
                                            </div>
                                            <div class="row estimate-row">
                                                <div class="col-xs-7 col-md-7 estimate-label">
                                                    {{'IDLE_INPUT_POWER'
                                                    |translate}}</div>
                                                <div class="col-md-3 col-xs-3 estimate-value">
                                                    {{rack?.power?.idleInputPower |number:'1.2-2'}}</div>
                                                <div class="col-xs-2 col-md-2 estimate-value">
                                                    {{rack?.power?.imperial?.powerUnit}}</div>
                                            </div>
                                            <div class="row estimate-row">
                                                <div class="col-xs-7 col-md-7 estimate-label">
                                                    {{'SYSTEM_WORKLOAD'
                                                    |translate}}</div>
                                                <div class="col-md-3 col-xs-3 estimate-value">
                                                    {{rack?.power?.systemWorkloadPercent
                                                    |number:'1.2-2'}}</div>
                                                <div class="col-xs-2 col-md-2 estimate-value">
                                                    {{rack?.power?.imperial?.systemWorkload}}</div>
                                            </div>
                                            <div class="row estimate-row">
                                                <div class="col-xs-7 col-md-7 estimate-label">{{'INPUT_CURRENT'
                                                    |translate}}
                                                </div>
                                                <div class="col-md-3 col-xs-3 estimate-value">
                                                    {{rack?.power?.inputCurrentLoad |number:'1.2-2'}}</div>
                                                <div class="col-xs-2 col-md-2 estimate-value">
                                                    {{rack?.power?.imperial?.currentUnit}}</div>
                                            </div>
                                            <div class="row estimate-row" *ngIf="rack?.power?.legacyPlatform">
                                                <div class="col-xs-7 col-md-7 estimate-label">{{'AIR_FLOW'
                                                    |translate}}
                                                </div>
                                                <div class="col-md-3 col-xs-3 estimate-value"
                                                    *ngIf="(globalUnit == 'Imperial')">
                                                    {{rack?.power?.inputAirflow?.airflowImperial
                                                    |number:'1.2-2'}} </div>
                                                <div class="col-md-3 col-xs-3 estimate-value"
                                                    *ngIf="(globalUnit == 'Metric')">
                                                    {{rack?.power?.inputAirflow?.airflowMetric |number:'1.2-2'}}
                                                </div>
                                                <div class="col-xs-2 col-md-2 estimate-value"
                                                    *ngIf="(globalUnit == 'Imperial')">
                                                    {{rack?.power?.imperial?.airflowUnit}}</div>
                                                <div class="col-xs-2 col-md-2 estimate-value"
                                                    *ngIf="(globalUnit == 'Metric')">
                                                    {{rack?.power?.metric?.airflowUnit}}</div>
                                            </div>
                                            <div class="row estimate-row" *ngIf="rack?.power?.legacyPlatform == false">
                                                <div class="col-md-7 estimate-label">{{'AIR_FLOW_MIN'
                                                    |translate}}</div>
                                                <div class="col-md-3 estimate-value" *ngIf="(globalUnit == 'Imperial')">
                                                    {{rack?.power?.airFlowRange?.minAirflowImperial | number :
                                                    '1.2-2'}}</div>
                                                <div class="col-md-3 estimate-value" *ngIf="(globalUnit == 'Metric')">
                                                    {{rack?.power?.airFlowRange?.minAirflowMetric | number :
                                                    '1.2-2'}}</div>
                                                <div class="col-xs-2 col-md-2 estimate-value"
                                                    *ngIf="(globalUnit == 'Imperial')">
                                                    {{rack?.power?.imperial?.airflowUnit}}</div>
                                                <div class="col-xs-2 col-md-2 estimate-value"
                                                    *ngIf="(globalUnit == 'Metric')">
                                                    {{rack?.power?.metric?.airflowUnit}}</div>
                                            </div>
                                            <div class="row estimate-row" *ngIf="rack?.power?.legacyPlatform == false">
                                                <div class="col-md-7 estimate-label">{{'AIR_FLOW_MAX'
                                                    |translate}}</div>
                                                <div class="col-md-3 estimate-value" *ngIf="(globalUnit == 'Imperial')">
                                                    {{rack?.power?.airFlowRange?.maxAirflowImperial | number :
                                                    '1.2-2'}}</div>
                                                <div class="col-md-3 estimate-value" *ngIf="(globalUnit == 'Metric')">
                                                    {{rack?.power?.airFlowRange?.maxAirflowMetric | number :
                                                    '1.2-2'}}</div>
                                                <div class="col-xs-2 col-md-2 estimate-value"
                                                    *ngIf="(globalUnit == 'Imperial')">
                                                    {{rack?.power?.imperial?.airflowUnit}}</div>
                                                <div class="col-xs-2 col-md-2 estimate-value"
                                                    *ngIf="(globalUnit == 'Metric')">
                                                    {{rack?.power?.metric?.airflowUnit}}</div>
                                            </div>
                                            <div class="row estimate-row" *ngIf="rack?.power?.legacyPlatform == false">
                                                <div class="col-md-7 estimate-label">{{'ACOUSTICS_MIN'
                                                    |translate}}</div>
                                                <div class="col-md-3 estimate-value">
                                                    {{rack?.power?.acousticsRange?.minAcoustics | number :
                                                    '1.2-2'}}</div>
                                                <div class="col-xs-1 col-md-2 estimate-value">
                                                    {{rack?.power?.imperial?.acousticsUnit}}</div>
                                            </div>
                                            <div class="row estimate-row" *ngIf="rack?.power?.legacyPlatform == false">
                                                <div class="col-md-7 estimate-label">{{'ACOUSTICS_MAX'
                                                    |translate}}</div>
                                                <div class="col-md-3 estimate-value">
                                                    {{rack?.power?.acousticsRange?.maxAcoustics | number :
                                                    '1.2-2'}}</div>
                                                <div class="col-xs-1 col-md-2 estimate-value">
                                                    {{rack?.power?.imperial?.acousticsUnit}}</div>
                                            </div>
                                            <div class="row estimate-row">
                                                <div class="col-xs-7 col-md-7 estimate-label">{{'COOLING'
                                                    |translate}}</div>
                                                <div class="col-md-3 col-xs-3 estimate-value"
                                                    *ngIf="(globalUnit == 'Imperial')">
                                                    {{rack?.power?.inputCooling?.imperialCooling
                                                    |number:'1.2-2'}}</div>
                                                <div class="col-md-3 col-xs-3 estimate-value"
                                                    *ngIf="(globalUnit == 'Metric')">
                                                    {{rack?.power?.inputCooling?.imperialCooling
                                                    |number:'1.2-2'}}</div>
                                                <div class="col-xs-2 col-md-2 estimate-value"
                                                    *ngIf="(globalUnit == 'Imperial')">
                                                    {{rack?.power?.imperial?.coolingUnit}}</div>
                                                <div class="col-xs-2 col-md-2 estimate-value"
                                                    *ngIf="(globalUnit == 'Metric')">
                                                    {{rack?.power?.imperial?.coolingUnit}}</div>
                                            </div>
                                            <div class="row estimate-row">
                                                <div class="col-xs-7 col-md-7 estimate-label">{{'WEIGHT'
                                                    |translate}}</div>
                                                <div class="col-md-3 col-xs-3 estimate-value"
                                                    *ngIf="(globalUnit == 'Imperial')">
                                                    {{rack?.power?.weightImperial |number:'1.2-2'}}</div>
                                                <div class="col-md-3 col-xs-3 estimate-value"
                                                    *ngIf="(globalUnit == 'Metric')">
                                                    {{rack?.power?.weightMetric |number:'1.2-2'}}</div>
                                                <div class="col-xs-2 col-md-2 estimate-value"
                                                    *ngIf="(globalUnit == 'Imperial')">
                                                    {{rack?.power?.imperial?.weightUnit}}</div>
                                                <div class="col-xs-2 col-md-2 estimate-value"
                                                    *ngIf="(globalUnit == 'Metric')">
                                                    {{rack?.power?.metric?.weightUnit}}</div>
                                            </div>
                                        </div>
                                    </div>

                                </li>
                            </ul>
                        </div>
                    </li>
            </div>
        </li>

        </ul>
        <div *ngIf="projectData.fabrics.length == 0 && (!additionalPlatformsAdded() || !additionalPlatformsAddedByClass('Networking'))"
            class="no-data-div">
            <p>{{'NO_DATA' | translate}}</p>
        </div>
    </div>


    </li>
    </ul>

</div>
<div *ngIf="projectData.fabrics.length == 0 && projectData.chassisList.length == 0 &&
 projectData.mseriesList.length == 0 && projectData.rackServers.length == 0 && !additionalPlatformsAdded()"
    class="no-data-div">
    <p>{{'NO_RESULT_DATA' | translate}}</p>
</div>
</div>
</div>