import { Component, OnInit, Inject, HostListener, ChangeDetectorRef } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormControl, UntypedFormBuilder, UntypedFormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';
import { UrlConstant } from 'src/constants/url.constants';
import { ToasterService } from "src/app/shared/components/toast/toast.service";
import { PlatformConstant } from 'src/constants/platform.constants';
import { errorCodes } from "src/constants/errorCodes";
import { ComponentConstant } from 'src/constants/components.constants';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { AppConstant } from 'src/constants/app.constants';

@Component({
  selector: 'app-new-measurement',
  templateUrl: './new-measurement.component.html',
  styleUrls: ['./new-measurement.component.css']
})
export class NewMeasurementComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<NewMeasurementComponent>,
    @Inject(MAT_DIALOG_DATA) public params: any,
    private apiService: ApiService,
    private toaster: ToasterService,
    private formBuilder: UntypedFormBuilder,
    private cdr: ChangeDetectorRef) { }

  @BlockUI() blockUI: NgBlockUI;
  subscriptions: Subscription = new Subscription();
  errorMsg: any;
  enableStates = PlatformConstant.ENABLED_DISABLED;
  cStates = PlatformConstant.C_STATES;
  memory = PlatformConstant.MEMORY_MODE;

  formControl_common = {
    FWRevision: new UntypedFormControl('', [Validators.required, Validators.pattern(AppConstant.VALID_INPUT_REGEX)]),
    pState: new UntypedFormControl('', [Validators.required]),
    turbo: new UntypedFormControl('', [Validators.required]),
    cStates: new UntypedFormControl('', []),
    memory: new UntypedFormControl('', [Validators.required]),
    ht: new UntypedFormControl('', [Validators.required]),
    noDisks: new UntypedFormControl('', [Validators.required, Validators.pattern('\\d+'), Validators.min(1)]),
    disk: new UntypedFormControl('', [Validators.required]),
    idlePower: new UntypedFormControl('', [Validators.required, Validators.min(0.000000001), Validators.pattern(AppConstant.VALID_INPUT_REGEX)]),
    maxPower: new UntypedFormControl('', [Validators.required, Validators.min(0.000000001), Validators.pattern(AppConstant.VALID_INPUT_REGEX)]),
    notes: new UntypedFormControl('', []),
  }

  formcontrol_rack = {
    raid: new UntypedFormControl('', []),
    noPsus: new UntypedFormControl('', [Validators.required, Validators.pattern('\\d+'), Validators.min(1)]),
    voltage: new UntypedFormControl('', [Validators.required]),
    pSupply: new UntypedFormControl('', [Validators.required]),
  }
  platform_type: any = null;
  measurementForm: any;
  ngOnInit(): void {

    this.platform_type = this.params.params.platformData.type;
    if (this.platform_type == PlatformConstant.RACK) {
      this.formControl_common = Object.assign(this.formControl_common, this.formcontrol_rack);

      this.measurementForm = new UntypedFormGroup(this.formControl_common);
      //field apis
      // this.getVoltageList();
      this.getPowerSupply();
      this.getIOES();
      this.getStorages();
    }
    else {
      this.measurementForm = new UntypedFormGroup(this.formControl_common);
      this.getStorages();
    }

    // this.getVoltageList();
    // this.getPowerSupply();
    // this.getIOES();
    // this.getStorages();
  }
  voltageList: any;
  voltageListAll: any;

  getVoltageList() {

    let voltages = ComponentConstant.VOLTAGES;
    const url = UrlConstant.MANAGECOMPONENTS + '/?component=' + voltages;
    this.subscriptions.add(
      this.apiService.getMethod(url).subscribe((data: any) => {
        this.voltageListAll = data.componenetList;
        let volts = [];
        data.componenetList.forEach(element => {
          if (this.addedcurves.includes(element.sequence)) {
            volts.push(element);
          }
        });
        this.voltageList = volts;

      }));
  }
  psList: any = [];
  psListAll: any = [];
  addedcurves: any = [];
  getPowerSupply() {

    const url = UrlConstant.GET_COMP_DIS_NAMES + '/' + ComponentConstant.POWERSUPPLY;
    let params = {
      "type": ComponentConstant.POWERSUPPLY,
      "sequenceIds": this.params.params.platformData.powerSupplies
    }
    this.subscriptions.add(
      this.apiService.postMethod(url, params).subscribe((data: any) => {
        // this.psList = data;
        let row = [];
        this.addedcurves = [];
        data.forEach(element => {
          if (element.powerSupplyInputs?.length > 0) {
            let curves = element.powerSupplyInputs?.filter(value => { return (value.deleteFlag == false || value.deleteFlag == null) && value.inputVoltage !== null })
            let psCurves = [];


            curves?.forEach(element2 => {
              psCurves.push(element2.inputVoltage);
              if (element2.inputVoltage !== null && !this.addedcurves.includes(element2.inputVoltage)) {
                this.addedcurves.push(element2.inputVoltage)
              }
            });

            if (curves.length > 0) {
              let ps = {
                id: element.id,
                sequence: element.sequence,
                displayName: element.powerSupply.displayName,
                voltages: psCurves
              }
              row.push(ps);
            }
          }


        });
        // this.psList = row;
        this.psListAll = JSON.parse(JSON.stringify(row));


        //voltage api
        this.getVoltageList();



      }));
  }
  ioesList: any = [];
  getIOES() {

    let ps = ComponentConstant.POWERSUPPLY;
    const url = UrlConstant.GET_COMP_DIS_NAMES;
    let params = {
      "type": ComponentConstant.IOES,
      "sequenceIds": this.params.params.platformData.ioes
    }
    this.subscriptions.add(
      this.apiService.postMethod(url, params).subscribe((data: any) => {
        this.ioesList = data;
      }));
  }
  storagesList: any = [];
  getStorages() {

    let ps = ComponentConstant.POWERSUPPLY;
    const url = UrlConstant.GET_COMP_DIS_NAMES;
    let params = {
      "type": ComponentConstant.STORAGES,
      "sequenceIds": this.params.params.platformData.storages
    }
    this.subscriptions.add(
      this.apiService.postMethod(url, params).subscribe((data: any) => {
        this.storagesList = data;
      }));
  }

  refreshData: boolean = false;
  submit(action: any) {

    this.blockUI.start('Loading...');
    let params = {
      "serverConfigSeqNo": this.params.params.selectedRow.sequence,
      "cpuSeqNo": this.params.params.selectedCpu.sequence,
      "dimmSeqNo": this.params.params.selectedDimm.sequence,

      "fwRevision": this.measurementForm.value.FWRevision,
      "pStates": this.measurementForm.value.pState.value,
      "turbo": this.measurementForm.value.turbo.value,
      "cStates": this.measurementForm.value.cStates.value,
      "memoryMode": this.measurementForm.value.memory.value,
      "ht": this.measurementForm.value.ht.value,//
      "numDisks": this.measurementForm.value.noDisks,
      "storageSeqNo": this.measurementForm.value.disk.sequence,
      "idleWatts": this.measurementForm.value.idlePower,
      "maxWatts": this.measurementForm.value.maxPower,
      "notes": this.measurementForm.value.notes,

      "released": 1,
      "type": this.params.params.platformData.type,
      "role": "Admin",
      "timestamp": new Date(),
      "allowDuplicates": true,
      "additionalFields": []
    }
    //additional fields for Rack
    if (this.platform_type == PlatformConstant.RACK) {
      let params_rack = {
        "ioSeqNo": this.measurementForm.value.raid.sequence,
        "numPsus": this.measurementForm.value.noPsus,
        "inputVoltageSeq": this.measurementForm.value.voltage.sequence,
        "psusSeqNo": this.measurementForm.value.pSupply.sequence, //check
      }
      //merge
      params = Object.assign(params, params_rack);

    }


    // this.blockUI.start('Loading...');
    const url = UrlConstant.ADD_NEW_MEASUREMENT;
    this.subscriptions.add(
      this.apiService.postMethod(url, params).subscribe((data: any) => {
        this.refreshData = true;
        this.blockUI.stop();
        if (data.modifiedCount > 0) {
          this.toaster.show('success', errorCodes.UPDATE_RECORD, '', 5000);

          if (action == 'closeModal') {
            this.dialogRef.close({
              data: {
                action: PlatformConstant.ACTION_REFRESH
              }
            });
          }
          else {
            this.resetForm();
          }

        }

      }, error => {
        this.blockUI.stop();
        let errorMsg = error?.error ? error.error?.error?.errorString : "Oops something went wrong!!";
        this.toaster.show('error', errorMsg, error ? error?.message : '', 5000);
      }));
  }
  resetForm() {
    this.measurementForm.reset();
  }
  closeModal() {
    this.dialogRef.close({
      data: {
        action: this.refreshData ? PlatformConstant.ACTION_REFRESH : PlatformConstant.ACTION_CANCEL
      }
    });
  }

  showWattErr: boolean = false;
  IdleMaxWattsErr: boolean = false;
  IdleWattsErr: boolean = false;
  validateWatts(e: any) {

    this.showWattErr = false;
    this.IdleMaxWattsErr = false;
    this.IdleWattsErr = false;

    if (this.measurementForm.value.idlePower && this.measurementForm.value.maxPower
      && !isNaN(this.measurementForm.value.idlePower) && !isNaN(this.measurementForm.value.maxPower)) {
      this.showWattErr = Number(this.measurementForm.value.maxPower) >= Number(this.measurementForm.value.idlePower)
        ? false : true;



      this.cdr.detectChanges();

    }
    if (this.measurementForm.value.idlePower && (isNaN(this.measurementForm.value.idlePower) || parseFloat(this.measurementForm.value.idlePower) < 0)) {

      //this.lblErrMessage = "Idle watts should be a Positive integer";
      this.IdleWattsErr = true;
      this.cdr.detectChanges();
    }
    if (this.measurementForm.value.maxPower && (isNaN(this.measurementForm.value.maxPower) || parseFloat(this.measurementForm.value.maxPower) < 0)) {

      //this.lblErrMessage = "Idle watts should be a Positive integer";
      this.IdleMaxWattsErr = true;
      this.cdr.detectChanges();
    }
  }
  selectionChanged(event: any, type: any) {

    if (type == 'powerSupply') {
      // this.measurementForm.controls['voltage'].reset();
      // let volts = [];
      // 
      // this.voltageListAll.forEach(element => {
      //   if(event?.value?.voltages.includes(element.sequence)){
      //     volts.push(element);
      //   }
      // });
      // 
      // this.voltageList = volts;
    }
    else if (type == 'voltage') {

      // this.measurementForm.controls['pSupply'].reset();
      let ps = [];
      this.psListAll.forEach(element => {
        if (element.voltages?.includes(event?.value?.sequence)) {
          ps.push(element);
        }
      });


      this.measurementForm.controls['pSupply'].reset();
      this.psList = ps;


    }
  }

}
