import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ICellRendererAngularComp } from "ag-grid-angular";
import { Subscription } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';
import { UrlConstant } from 'src/constants/url.constants';
import { PlatformConstant } from 'src/constants/platform.constants';
import { ToasterService } from "src/app/shared/components/toast/toast.service";
import { errorCodes } from "src/constants/errorCodes";
import { UpdateNameService } from 'src/app/services/update-name.service';

@Component({
  selector: 'app-checkbox-render-manage-platforms',
  templateUrl: './checkbox-render-manage-platforms.component.html',
  styleUrls: ['./checkbox-render-manage-platforms.component.css']
})
export class CheckboxRenderManagePlatformsComponent implements OnInit, ICellRendererAngularComp {

  constructor(private apiService: ApiService, private toaster: ToasterService,
    private cdr: ChangeDetectorRef,
    private updateNameService: UpdateNameService,
  ) { }

  ngOnInit(): void { }
  subscriptions: Subscription = new Subscription();

  params: any;
  agInit(params: any): void {
    this.params = params;
  }
  refresh(params?: any): boolean {
    return true;
  }

  isFanPolicyReq: boolean = false;
  configList: any[] = [];
  checkedHandler(event) {
    let checked = event.target.checked;
    let colId = this.params.column?.colId;
    /** 
     * if cpu/dimms config not added 
     * for a platform [rack/blades/nodes/additional] - show error message 
     * [Change] - Not required
    */
    let invalidConfig = false;
    
    if (checked) {
      if (this.params.data.configs) {
        if (this.params.data.configs.length > 0) { invalidConfig = false; }
        else invalidConfig = true;
      } else invalidConfig = false;
    }
    /* 
      if (invalidConfig) {
        this.toaster.show('error', errorCodes.CONFIG_NOT_ADDED, errorCodes.CONFIG_NOT_ADDED_INFO, 5000);
        this.cdr.detectChanges();
        this.params.api.redrawRows();
        return;
      }
    */
    if (checked) {
      this.params.data.released = 1;
    } else {
      this.params.data.released = 0;
    }
    this.params.node.setDataValue(colId, this.params.data.released);

    this.updateNameService.getplatformPanelData().subscribe((data) => {
      if (data.length > 0) {
        let platform = data.filter(f => { return f["platformType"] == this.params?.data?.type })
        this.isFanPolicyReq = platform[0]?.isFanPolicyReq;
      }
    });
    /** discont should be 0/1 */
    let discont = this.params?.data?.discont;
    if (discont && ["Yes", "No"].map(a => a.toString().toLocaleLowerCase()).includes(discont.toString().toLocaleLowerCase())) {
      this.params.data.discont = discont == "Yes" ? "1" : "0";
    }
    if (this.params?.data?.type == PlatformConstant.RACK) {
      console.log(this.params?.data, 'data params');
      let params = {
        "role": "Admin",
        "type": this.params?.data?.type,//type
        "id": this.params?.data?.id,//id
        "released": this.params?.data?.released,
        "imageId": this.params?.data?.imageId,
        "vendor": this.params?.data?.vendor,
        "name": this.params?.data?.name,
        "gen": this.params?.data?.gen,
        "basePid": this.params?.data?.basePid,
        "weight": this.params?.data?.weight,
        "discont": this.params?.data?.discont,
        "url": "",//image url
        "pciSlots": this.params?.data?.pciSlots,
        "maxDisks": this.params?.data?.maxDisks,
        "nMin": this.params?.data?.nMin,
        "implicitCap": this.params?.data?.implicitCap,
        "mtbf": this.params?.data?.mtbf,
        "dimmSlots": this.params?.data?.dimmSlots,
        "cpuSockets": this.params?.data?.cpuSockets,
        "maxBootPower": this.params?.data?.maxBootPower,
        "maxPsus": this.params?.data?.maxPsus,
        "height": this.params?.data?.height,
        "mezzSlots": this.params?.data?.mezzSlots,
        "idlePower": this.params?.data?.idlePower,
        "maxPower": this.params?.data?.maxPower,
        "conversionFactor": this.params?.data?.conversionFactor,
        "series": this.params?.data?.series,
        "primaryClass": this.params?.data?.primaryClass,

        "legacyPlatform": this.params?.data?.legacyPlatform,
        "isNewCreated": this.params?.data?.isNewCreated,
        "isNewConverted": this.params?.data?.isNewConverted,

        "allowDuplicates": true,
        "additionalFields": this.params?.data?.additionalFields?.length > 0 ? this.params?.data?.additionalFields : []
      }
      this.changeRelease(params);
    }
    if (this.params?.data?.type == PlatformConstant.BLADE) {
      let params = {
        "role": "Admin",
        "type": this.params?.data?.type,//type
        "id": this.params?.data?.id,//id
        "vendor": this.params?.data?.vendor,
        "name": this.params?.data?.name,
        "gen": this.params?.data?.gen,
        "basePid": this.params?.data?.basePid,
        "weight": this.params?.data?.weight,
        "discont": this.params?.data?.discont,
        "url": "",//image url
        "pciSlots": this.params?.data?.pciSlots,
        "maxDisks": this.params?.data?.maxDisks,
        "released": this.params?.data?.released,//released
        "mtbf": this.params?.data?.mtbf,
        "numChassisSlots": this.params?.data?.numChassisSlots,
        "dimmSlots": this.params?.data?.dimmSlots,
        "cpuSockets": this.params?.data?.cpuSockets,
        "maxBootPower": this.params?.data?.maxBootPower,
        "mezzSlots": this.params?.data?.mezzSlots,
        "imageId": this.params?.data?.imageId,
        "idlePower": this.params?.data?.idlePower,
        "maxPower": this.params?.data?.maxPower,
        "conversionFactor": this.params?.data?.conversionFactor,
        "series": this.params?.data?.series,
        "primaryClass": this.params?.data?.primaryClass,
        "allowDuplicates": true,
        "legacyPlatform": this.params?.data?.legacyPlatform,
        "isNewCreated": this.params?.data?.isNewCreated,
        "isNewConverted": this.params?.data?.isNewConverted,
        "additionalFields": this.params?.data?.additionalFields?.length > 0 ? this.params?.data?.additionalFields : []
      }
      this.changeRelease(params);
    }
    if (this.params?.data?.type == PlatformConstant.BLADE_CHASSIS) {
      let params = {
        "role": "Admin",
        "type": this.params?.data?.type,//type
        "id": this.params?.data?.id,//id        
        "vendor": this.params?.data?.vendor,
        "name": this.params?.data?.name,
        "gen": this.params?.data?.gen,
        "basePid": this.params?.data?.numPsuSlots,
        "numPsuSlots": this.params?.data?.numPsuSlots,
        "numIomSlots": this.params?.data?.numIomSlots,
        "numBladeSlots": this.params?.data?.numBladeSlots,
        "weight": this.params?.data?.weight,
        "idleBasePower": this.params?.data?.idleBasePower,
        "maxBasePower": this.params?.data?.maxBasePower,
        "discont": this.params?.data?.discont,
        "url": "",
        "pciSlots": 0,
        "maxDisks": 0,
        "nMin": this.params?.data?.nMin,
        "implicitCap": this.params?.data?.implicitCap,
        "released": this.params?.data?.released,
        "mtbf": this.params?.data?.mtbf,
        "allowDuplicates": true,
        "conversionFactor": this.params?.data?.conversionFactor,
        "series": this.params?.data?.series,
        "primaryClass": this.params?.data?.primaryClass,
        "legacyPlatform": this.params?.data?.legacyPlatform,
        "isNewCreated": this.params?.data?.isNewCreated,
        "isNewConverted": this.params?.data?.isNewConverted,
        "additionalFields": this.params?.data?.additionalFields?.length > 0 ? this.params?.data?.additionalFields : [],
        "imageId": this.params?.data?.imageId,
      }
      this.changeRelease(params);

    }
    if (this.params?.data?.type == PlatformConstant.NODE) {
      let params = {
        "role": "Admin",
        "type": this.params?.data?.type,//type
        "id": this.params?.data?.id,//id   
        "vendor": this.params?.data?.vendor,
        "name": this.params?.data?.name,
        "gen": this.params?.data?.gen,
        "basePid": this.params?.data?.basePid,
        "weight": this.params?.data?.weight,
        "discont": this.params?.data?.discont,
        "url": "",//image url
        "released": this.params?.data?.released,
        "mtbf": this.params?.data?.mtbf,
        "numChassisSlots": this.params?.data?.numChassisSlots,
        "dimmSlots": this.params?.data?.dimmSlots,
        "cpuSockets": this.params?.data?.cpuSockets,
        "maxBootPower": this.params?.data?.maxBootPower,
        "mezzSlots": this.params?.data?.mezzSlots,
        "idlePower": this.params?.data?.idlePower,
        "maxPower": this.params?.data?.maxPower,
        "computeNode": this.params?.data?.computeNode,
        "allowDuplicates": true,
        "conversionFactor": this.params?.data?.conversionFactor,
        "series": this.params?.data?.series,
        "primaryClass": this.params?.data?.primaryClass,
        "legacyPlatform": this.params?.data?.legacyPlatform,
        "isNewCreated": this.params?.data?.isNewCreated,
        "isNewConverted": this.params?.data?.isNewConverted,
        "additionalFields": this.params?.data?.additionalFields?.length > 0 ? this.params?.data?.additionalFields : [],
        "imageId": this.params?.data?.imageId,
      }
      this.changeRelease(params);

    }
    if (this.params?.data?.type == PlatformConstant.MODULAR_CHASSIS) {
      let params = {
        "role": "Admin",
        "type": this.params?.data?.type,//type
        "id": this.params?.data?.id,//id   
        "vendor": this.params?.data?.vendor,
        "name": this.params?.data?.name,
        "gen": this.params?.data?.gen,
        "basePid": this.params?.data?.basePid,
        "numPsuSlots": this.params?.data?.numPsuSlots,
        "numBladeSlots": this.params?.data?.numBladeSlots,
        "weight": this.params?.data?.weight,
        "idleBasePower": this.params?.data?.idleBasePower,
        "maxBasePower": this.params?.data?.maxBasePower,
        "discont": this.params?.data?.discont,
        "url": "",
        "pciSlots": this.params?.data?.pciSlots,
        "maxDisks": this.params?.data?.maxDisks,
        "imageId": this.params?.data?.imageId,
        "nMin": this.params?.data?.nMin,
        "implicitCap": this.params?.data?.implicitCap,
        "mtbf": this.params?.data?.mtbf,
        "allowDuplicates": true,
        "conversionFactor": this.params?.data?.conversionFactor,

        "additionalFields": this.params?.data?.additionalFields?.length > 0 ? this.params?.data?.additionalFields : [],
        "released": this.params?.data?.released,
        "series": this.params?.data?.series,
        "primaryClass": this.params?.data?.primaryClass,
        "legacyPlatform": this.params?.data?.legacyPlatform,
        "isNewCreated": this.params?.data?.isNewCreated,
        "isNewConverted": this.params?.data?.isNewConverted,
      }
      this.changeRelease(params);

    }
    if (this.params?.data?.type == PlatformConstant.FABRIC_INTERCONNECTS) {
      let params = {
        "role": "Admin",
        "type": this.params?.data?.type,//type
        "id": this.params?.data?.id,//id  
        "vendor": this.params?.data?.vendor,
        "name": this.params?.data?.name,
        "gen": this.params?.data?.gen,
        "basePid": this.params?.data?.basePid,
        "imageId": this.params?.data?.imageId,
        "weight": this.params?.data?.weight,
        "discont": this.params?.data?.discont,
        "url": "",
        "released": this.params?.data?.released,

        "mtbf": this.params?.data?.mtbf,
        "maxPsus": this.params?.data?.maxPsus,
        "height": this.params?.data?.height,
        "switchType": "FI",
        "maxExpSlots": this.params?.data?.maxExpSlots,
        "maxPorts": this.params?.data?.maxPorts,
        "baseIdle": this.params?.data?.baseIdle,
        "baseMax": this.params?.data?.baseMax,
        "idlePerPort": this.params?.data?.idlePerPort,
        "maxPerPort": this.params?.data?.maxPerPort,
        "allowDuplicates": true,
        "additionalFields": this.params?.data?.additionalFields?.length > 0 ? this.params?.data?.additionalFields : [],
        "conversionFactor": this.params?.data?.conversionFactor,
        "series": this.params?.data?.series,
        "primaryClass": this.params?.data?.primaryClass,
        "legacyPlatform": this.params?.data?.legacyPlatform,
        "isNewCreated": this.params?.data?.isNewCreated,
        "isNewConverted": this.params?.data?.isNewConverted,
      }
      this.changeRelease(params);

    }
    if (this.params?.data?.type == PlatformConstant.FABRIC_EXTENDERS) {
      let params = {
        "role": "Admin",
        "type": this.params?.data?.type,//type
        "id": this.params?.data?.id,//id  
        "vendor": this.params?.data?.vendor,
        "name": this.params?.data?.name,
        "gen": this.params?.data?.gen,
        "basePid": this.params?.data?.basePid,
        "imageId": this.params?.data?.imageId,
        "weight": this.params?.data?.weight,
        "discont": this.params?.data?.discont,
        "url": "",
        "released": this.params?.data?.released,

        "mtbf": this.params?.data?.mtbf,
        "maxPsus": this.params?.data?.maxPsus,
        "height": this.params?.data?.height,
        "switchType": "NEXUS",
        "maxExpSlots": this.params?.data?.maxExpSlots,
        "maxPorts": this.params?.data?.maxPorts,
        "baseIdle": this.params?.data?.baseIdle,
        "baseMax": this.params?.data?.baseMax,
        "idlePerPort": this.params?.data?.idlePerPort,
        "maxPerPort": this.params?.data?.maxPerPort,
        "additionalFields": this.params?.data?.additionalFields?.length > 0 ? this.params?.data?.additionalFields : [],
        "conversionFactor": this.params?.data?.conversionFactor,
        "series": this.params?.data?.series,
        "primaryClass": this.params?.data?.primaryClass,
        "legacyPlatform": this.params?.data?.legacyPlatform,
        "isNewCreated": this.params?.data?.isNewCreated,
        "isNewConverted": this.params?.data?.isNewConverted,
      }
      this.changeRelease(params);

    }
    //additional platforms
    if (this.params.additionalPlatform) {
      let params = {
        "role": "Admin",
        "type": this.params?.data?.type,//type
        "id": this.params?.data?.id,//id  
        "allowDuplicates": true,
        "additionalFields": this.params?.data?.additionalFields?.length > 0 ? this.params?.data?.additionalFields : [],
        "released": this.params?.data?.released,
        "discont": this.params?.data?.discont,
        "imageId": this.params?.data?.imageId,
        "displayName": this.params?.data?.displayName,
        "internalName": this.params?.data?.internalName,
        "name": this.params?.data?.displayName ? this.params?.data?.displayName : this.params?.data?.name,
        "basePid": this.params?.data?.basePid,
        "idlePower": this.params?.data?.idlePower,
        "maxPower": this.params?.data?.maxPower,
        "sharedResource": this.params?.data?.sharedResource,
        "processorSupport": this.params?.data?.processorSupport,
        "node": this.params?.data?.node,
        "conversionFactor": this.params?.data?.conversionFactor,
        "series": this.params?.data?.series,
        "primaryClass": this.params?.data?.primaryClass,
        "legacyPlatform": this.params?.data?.legacyPlatform,
        "isNewCreated": this.params?.data?.isNewCreated,
        "isNewConverted": this.params?.data?.isNewConverted,
        "dynamicPlatform": true,
        "vendor": this.params?.data?.vendor,
        dimmSlots: this.params?.data?.dimmSlots,
        cpuSockets: this.params?.data?.cpuSockets,
        pciSlots: this.params?.data?.pciSlots,
        maxDisks: this.params?.data?.maxDisks,
        maxPsuSlots: this.params?.data?.maxPsuSlots,
        maxPsus: this.params?.data?.maxPsuSlots,
        nMin: this.params?.data?.nMin,
        implicitCap: this.params?.data?.implicitCap,
        height: this.params?.data?.height,
        weight: this.params?.data?.weight,
        maxBootPower: this.params?.data?.maxBootPower,
        mtbf: this.params?.data?.mtbf,
        gen: this.params?.data?.gen,
        mezzSlots: this.params?.data?.mezzSlots,
        numChassisSlots: this.params?.data?.numChassisSlots,
        numBladeSlots: this.params?.data?.numBladeSlots,
        "idleWatts": this.params?.data?.idlePower,
        "maxWatts": this.params?.data?.maxPower,
      }
      this.changeRelease(params);

    }

  }

  changeRelease(params) {
    if (
      params.released == "0" ||
      (
        (
          ((this.params?.data?.isNewConverted == true) && (this.params?.data?.fanPolicyPid !== null &&
            this.params?.data?.fanPolicyPid !== "")
          )
          ||
          (
            (this.isFanPolicyReq == false) ||
            (this.isFanPolicyReq == true && this.params?.data?.isNewConverted != true && this.params?.data?.isNewCreated != true)
          )
          ||
          (
            ((this.isFanPolicyReq == true && this.params?.data?.isNewCreated == true) && (this.params?.data?.fanPolicyPid !== null &&
              this.params?.data?.fanPolicyPid !== "")
            )
          )
        )
        &&
        (
          ((params.type == "RACK" || params.type.includes('AdditionalPlatform')) &&
            this.params?.data?.cpus !== null && this.params?.data?.dimms !== null
            && this.params?.data?.dimms?.length > 0 && this.params?.data?.cpus.length > 0
            && this.params?.data?.powerSupplies !== null && this.params?.data?.powerSupplies.length > 0
          )
          || ((params.type == "BLADE") &&
            this.params?.data?.cpus !== null && this.params?.data?.dimms !== null
            && this.params?.data?.dimms?.length > 0 && this.params?.data?.cpus.length > 0
          )
          || ((params.type == "CARTRIDGE") && this.params?.data?.computeNode == 1 &&
            this.params?.data?.cpus !== null && this.params?.data?.dimms !== null
            && this.params?.data?.dimms?.length > 0 && this.params?.data?.cpus.length > 0
          )
          || (params.type == "CARTRIDGE" && this.params?.data?.computeNode == 0)

          || ((params.type == "MSERIES" || params.type == "FI" || params.type == "NEXUS") &&
            this.params?.data?.powerSupplies !== null && this.params?.data?.powerSupplies.length > 0
          )
          || ((params.type == "CHASSIS") &&
            this.params?.data?.powerSupplies !== null && this.params?.data?.powerSupplies.length > 0
            && this.params?.data?.ioms !== null && this.params?.data?.ioms.length > 0)
        )
      )
    ) {

      //check for measurement
      if (this.params?.data?.legacyPlatform !== false && (params.type == "RACK" || params.type == "BLADE" ||
        (params.type == "CARTRIDGE" && this.params?.data?.computeNode == 1))
      ) {


        const url_measure = UrlConstant.GET_MEAUREMENT_BY_CONFIGS;
        const params_measure =
        {
          "configs": this.params?.data?.configs ? this.params?.data?.configs : []
        }
        this.subscriptions.add(
          this.apiService.postMethod(url_measure, params_measure).subscribe((data: any) => {

            if (data?.length > 0 || params.released == "0") {
              const url = UrlConstant.MANAGE_PLATFORM;
              let params2 = [];
              params2.push(params);
              this.subscriptions.add(
                this.apiService.postMethod(url, params2).subscribe((data: any) => {
                  this.toaster.show('success', "Release State Changed Successfully!", '', 5000);

                })
              );
            }
            else {
              this.params.value = false;
              let colId = this.params?.column?.colId;
              this.params.data.released = 0;
              this.params.node.setDataValue(colId, this.params.data.released);
              this.toaster.show('error', "Operation Failed!", errorCodes.MEASUREMENTS_NOT_ADDED, 5000);
              this.cdr.detectChanges();
              this.params.api.redrawRows();
            }

          })
        );


      }
      else {
        const url = UrlConstant.MANAGE_PLATFORM;
        let params2 = [];
        params2.push(params);
        this.subscriptions.add(
          this.apiService.postMethod(url, params2).subscribe((data: any) => {
            this.toaster.show('success', "Release State Changed Successfully!", '', 5000);
          })
        );
      }

    }
    else {
      this.params.value = false;
      let colId = this.params?.column?.colId;
      this.params.data.released = 0;
      this.params.node.setDataValue(colId, this.params.data.released);
      let fields = [];
      if (params.type == "RACK" || params.type.includes('AdditionalPlatform')) {
        if (this.params?.data?.dimms?.length == 0 || this.params?.data?.dimms == null)
          fields.push("Memory")
        if (this.params?.data?.cpus?.length == 0 || this.params?.data?.cpus == null)
          fields.push("Processor")
        if (this.params?.data?.powerSupplies == null || this.params?.data?.powerSupplies?.length == 0)
          fields.push("Power Supply")
        // if((this.isFanPolicyReq == true || this.params?.data?.isNewConverted == true ) && this.params?.data?.fanPolicyPid == null ||
        // this.params?.data?.fanPolicyPid == "" ){
        //   fields.push("Fan Policy");
        // }
      }
      else if (params.type == "BLADE" || params.type == "CARTRIDGE") {
        if (this.params?.data?.dimms?.length == 0 || this.params?.data?.dimms == null)
          fields.push("Memory")
        if (this.params?.data?.cpus?.length == 0 || this.params?.data?.cpus == null)
          fields.push("Processor")
        // if((this.isFanPolicyReq == true || this.params?.data?.isNewConverted == true ) && this.params?.data?.fanPolicyPid == null ||
        // this.params?.data?.fanPolicyPid == "" ){
        //   fields.push("Fan Policy");
        // }
      }
      else if (params.type == "MSERIES" || params.type == "FI" || params.type == "NEXUS") {
        if (this.params?.data?.powerSupplies == null || this.params?.data?.powerSupplies?.length == 0)
          fields.push("Power Supply");
        // if((this.isFanPolicyReq == true || this.params?.data?.isNewConverted == true ) && this.params?.data?.fanPolicyPid == null ||
        // this.params?.data?.fanPolicyPid == "" ){
        //   fields.push("Fan Policy");
        // }
      }
      else if (params.type == "CHASSIS") {
        if (this.params?.data?.powerSupplies == null || this.params?.data?.powerSupplies?.length == 0)
          fields.push("Power Supply");

        if (this.params?.data?.ioms == null || this.params?.data?.ioms?.length == 0)
          fields.push("IO Module");
        // if((this.isFanPolicyReq == true || this.params?.data?.isNewConverted == true ) && this.params?.data?.fanPolicyPid == null ||
        // this.params?.data?.fanPolicyPid == "" ){
        //   fields.push("Fan Policy");
        // }
      }
      // else{
      //    if((this.isFanPolicyReq == true || this.params?.data?.isNewConverted == true ) && this.params?.data?.fanPolicyPid == null ||
      //   this.params?.data?.fanPolicyPid == "" ){
      //     fields.push("Fan Policy");
      //   }

      // }  
      // 

      if (!(
        ((this.params?.data?.isNewConverted == true) && (this.params?.data?.fanPolicyPid !== null &&
          this.params?.data?.fanPolicyPid !== "")
        )
        ||
        (
          (this.isFanPolicyReq == false) ||
          (this.isFanPolicyReq == true && this.params?.data?.isNewConverted != true && this.params?.data?.isNewCreated != true)
        )
        ||
        (
          ((this.isFanPolicyReq == true && this.params?.data?.isNewCreated == true) && (this.params?.data?.fanPolicyPid !== null &&
            this.params?.data?.fanPolicyPid !== "")
          )
        ))
      ) {
        fields.push("Fan Policy");

      }
      // 


      let manFields = "";
      fields.forEach(f => {
        manFields = manFields + f + ", "
      })
      manFields = manFields.replace(/,\s*$/, "");
      this.toaster.show('error', "Operation Failed!", 'Mandatory Components are not assigned : ' + manFields, 5000);


      this.cdr.detectChanges();
      this.params.api.redrawRows();
    }

  }
}
