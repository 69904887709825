<div class="admin">

    <ul id="hleft" class="tabs">
        <li id="hleft-1" class="tab" routerLink="admin-tab" routerLinkActive="active">
            <a tabindex="0">Admin</a>
        </li>
        <li id="hleft-2" class="tab"  [routerLink]="['/admin/projectList']" routerLinkActive="active">
            <a tabindex="0">Estimate Power</a>
        </li>
        <li id="hleft-3" class="tab" routerLink="document" routerLinkActive="active">
            <a tabindex="0">Document</a>            
        </li>
        <li id="hleft-4" class="tab" routerLink="configs" routerLinkActive="active">
            <a tabindex="0">DB Query Support</a>
        </li>
        <li id="hleft-4" class="tab" style="float: right; vertical-align: middle;margin-top:10px;margin-left: 0px;margin-right: 10px;    width: 400px;
        ">
            <div class="row" style="display: flex; align-items:center;">
                <div class="col-2">
                    <span class="icon-search" style="margin-right: 3px;"></span>
                    <span class="tab" style="font-weight: 700; color: rgb(71, 71, 71)">Platform</span>
                </div>
                <div class="col-1"></div>
                <div class="col-6" style="padding-left:0px; margin-left:-5px;">
                    <div class="form-group">
                    <div class="form-group__text">
                    <input 
                    [(ngModel)]="searchQuery"
                    style="width : 100%; height: 100%;flex:auto" 
                    type="text"
                    title="Enter min 3 characters to search"
                    >
                </div></div></div>
                <div class="col-3">
                    <button class="btn btn--primary btn--small" [disabled]="searchQuery.trim().length < 3" (click)="searchForItem()">Go</button>
                </div>
            </div>
        </li> 
    </ul>
    <div id="hleft-content" class="tab-content">
        <div id="hleft-1-content" class="tab-pane active">
            <router-outlet></router-outlet>
         </div>

    </div>
    
</div>
