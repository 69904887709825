import { Component, OnInit } from '@angular/core';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { GridOptions } from "ag-grid-community";
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { CheckboxRenderComponent } from 'src/app/shared/components/checkbox-render/checkbox-render.component';
import { DeleteRenderComponent } from 'src/app/shared/components/delete-render/delete-render.component';
import { EditRenderComponent } from 'src/app/shared/components/edit-render/edit-render.component';
import { MatDialog } from '@angular/material/dialog';
import { CreateFanpolicyComponent } from '../create-components/create-fanpolicy/create-fanpolicy.component';
import { ComponentConstant } from 'src/constants/components.constants';
import { UrlConstant } from 'src/constants/url.constants';
import { ApiService } from 'src/app/services/api.service';
import { ToasterService } from 'src/app/shared/components/toast/toast.service';
import { errorCodes } from 'src/constants/errorCodes';
import { CngContentConfigType, CngModalService, CngModalSize } from '@cisco/cui-ng';
import { ConfirmationBoxComponent } from 'src/app/shared/components/confirmation-box/confirmation-box.component';
import { TranslateService } from '@ngx-translate/core';
import { ShowFieldsComponent } from 'src/app/shared/components/show-fields/show-fields.component';
import { UpdateNameService } from 'src/app/services/update-name.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-manage-fan-policy',
  templateUrl: './manage-fan-policy.component.html',
  styleUrls: ['./manage-fan-policy.component.css']
})
export class ManageFanPolicyComponent implements OnInit {
  @BlockUI() blockUI: NgBlockUI;
  subscriptions: Subscription = new Subscription();
  errorMsg = '';
  gridApi: any;
  gridColumnApi: any;
  componentIdx: number;
  componentName: string;
  componentDisplayName: string = "";
  // columnDefs;
  // frameworkComponents;
  // defaultColDef;
  rowData;
  gridOptions: GridOptions = {};
  flagBackButton = false;
  paginationPageSize = 10;
  dataforMultipleDelete: { pid: any; policies: any[]; }[] = [];

  constructor(public dialog: MatDialog,
    private apiService: ApiService,
    private toaster: ToasterService,
    private modalService: CngModalService,
    private translate: TranslateService,
    private updateNameService: UpdateNameService,
    private router: Router
    ) { 
      this.updateNameService.getCurrentName().subscribe((data) => {
        if (data.length > 0) {
          this.componentName = data.filter(compName => compName.internalName.trim() == 'Manage Fan Policies')[0].currentName;
          let compNameSplit = this.componentName.trim().replace("Manage", "").replace("manage", ""); 
          this.componentDisplayName = compNameSplit;
        }
      });
    }

  ngOnInit(): void {
    this.getFanPolicy();

  }
 
  columnDefs = [

    { headerName: 'PID', field: 'pid', pinned: 'left', width: 150, 
    comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
      if (valueA.toUpperCase() == valueB.toUpperCase()){ 
        return 0;
      }
      else{
        return (valueA.toUpperCase() > valueB.toUpperCase()) ? 1 : -1;
      }
    }},
    // 'cell-span' },
    { headerName: 'Policy', field: 'policy', pinned: 'left', width: 110, },
    { headerName: 'PWM at 20', field: 'a20pwm', width: 100 },
    { headerName: 'Power at 20', field: 'a20power', },
    { headerName: 'Airflow at 20', field: 'a20airflow', },
    { headerName: 'Sound at 20', field: 'a20sound', },

    { headerName: 'PWM at 21', field: 'a21pwm', },
    { headerName: 'Power at 21', field: 'a21power', },
    { headerName: 'Airflow at 21', field: 'a21airflow', },
    { headerName: 'Sound at 21', field: 'a21power', },

    { headerName: 'PWM at 22', field: 'a22pwm', },
    { headerName: 'Power at 22', field: 'a22power', },
    { headerName: 'Airflow at 22', field: 'a22airflow', },
    { headerName: 'Sound at 22', field: 'a22sound', },

    { headerName: 'PWM at 23', field: 'a23pwm', },
    { headerName: 'Power at 23', field: 'a23power', },
    { headerName: 'Airflow at 23', field: 'a23airflow', },
    { headerName: 'Sound at 23', field: 'a23sound', },

    { headerName: 'PWM at 24', field: 'a24pwm', },
    { headerName: 'Power at 24', field: 'a24power', },
    { headerName: 'Airflow at 24', field: 'a24airflow', },
    { headerName: 'Sound at 24', field: 'a24sound', },

    { headerName: 'PWM at 25', field: 'a25pwm', },
    { headerName: 'Power at 25', field: 'a25power', },
    { headerName: 'Airflow at 25', field: 'a25airflow', },
    { headerName: 'Sound at 25', field: 'a25sound', },

    { headerName: 'PWM at 26', field: 'a26pwm', },
    { headerName: 'Power at 26', field: 'a26power', },
    { headerName: 'Airflow at 26', field: 'a26airflow', },
    { headerName: 'Sound at 26', field: 'a26sound', },

    { headerName: 'PWM at 27', field: 'a27pwm', },
    { headerName: 'Power at 27', field: 'a27power', },
    { headerName: 'Airflow at 27', field: 'a27airflow', },
    { headerName: 'Sound at 27', field: 'a27sound', },

    { headerName: 'PWM at 28', field: 'a28pwm', },
    { headerName: 'Power at 28', field: 'a28power', },
    { headerName: 'Airflow at 28', field: 'a28airflow', },
    { headerName: 'Sound at 28', field: 'a28sound', },

    { headerName: 'PWM at 29', field: 'a29pwm', },
    { headerName: 'Power at 29', field: 'a29power', },
    { headerName: 'Airflow at 29', field: 'a29airflow', },
    { headerName: 'Sound at 29', field: 'a29sound', },

    { headerName: 'PWM at 30', field: 'a30pwm', },
    { headerName: 'Power at 30', field: 'a30power', },
    { headerName: 'Airflow at 30', field: 'a30airflow', },
    { headerName: 'Sound at 30', field: 'a30sound', },

    { headerName: 'PWM at 31', field: 'a31pwm', },
    { headerName: 'Power at 31', field: 'a31power', },
    { headerName: 'Airflow at 31', field: 'a31airflow', },
    { headerName: 'Sound at 31', field: 'a31sound', },

    { headerName: 'PWM at 32', field: 'a32pwm', },
    { headerName: 'Power at 32', field: 'a32power', },
    { headerName: 'Airflow at 32', field: 'a32airflow', },
    { headerName: 'Sound at 32', field: 'a32sound', },

    { headerName: 'PWM at 33', field: 'a33pwm', },
    { headerName: 'Power at 33', field: 'a33power', },
    { headerName: 'Airflow at 33', field: 'a33airflow', },
    { headerName: 'Sound at 33', field: 'a33sound', },

    { headerName: 'PWM at 34', field: 'a34pwm', },
    { headerName: 'Power at 34', field: 'a34power', },
    { headerName: 'Airflow at 34', field: 'a34airflow', },
    { headerName: 'Sound at 34', field: 'a34sound', },

    { headerName: 'PWM at 35', field: 'a35pwm', },
    { headerName: 'Power at 35', field: 'a35power', },
    { headerName: 'Airflow at 35', field: 'a35airflow', },
    { headerName: 'Sound at 35', field: 'a35sound', },

    { headerName: 'PWM at 36', field: 'a36pwm', },
    { headerName: 'Power at 36', field: 'a36power', },
    { headerName: 'Airflow at 36', field: 'a36airflow', },
    { headerName: 'Sound at 36', field: 'a36sound', },

    { headerName: 'PWM at 37', field: 'a37pwm', },
    { headerName: 'Power at 37', field: 'a37power', },
    { headerName: 'Airflow at 37', field: 'a37airflow', },
    { headerName: 'Sound at 37', field: 'a37sound', },

    { headerName: 'PWM at 38', field: 'a38pwm', },
    { headerName: 'Power at 38', field: 'a38power', },
    { headerName: 'Airflow at 38', field: 'a38airflow', },
    { headerName: 'Sound at 38', field: 'a38sound', },

    { headerName: 'PWM at 39', field: 'a39pwm', },
    { headerName: 'Power at 39', field: 'a39power', },
    { headerName: 'Airflow at 39', field: 'a39airflow', },
    { headerName: 'Sound at 39', field: 'a39sound', },

    { headerName: 'PWM at 40', field: 'a40pwm', },
    { headerName: 'Power at 40', field: 'a40power', },
    { headerName: 'Airflow at 40', field: 'a40airflow', },
    { headerName: 'Sound at 40', field: 'a40sound', },

    { headerName: 'PWM at 41', field: 'a41pwm', },
    { headerName: 'Power at 41', field: 'a41power', },
    { headerName: 'Airflow at 41', field: 'a41airflow', },
    { headerName: 'Sound at 41', field: 'a41sound', },

    { headerName: 'PWM at 42', field: 'a42pwm', },
    { headerName: 'Power at 42', field: 'a42power', },
    { headerName: 'Airflow at 42', field: 'a42airflow', },
    { headerName: 'Sound at 42', field: 'a42sound', },
    { headerName: 'Updated By', field: 'editor', },
    {
      headerName: 'Last Updated', field: 'lastUpdated',
      cellRenderer: (data) => {
        return data.value ? moment(data.value).format('MMM DD, YYYY') : moment(data.data.created).format('MMM DD, YYYY');
      },
      valueGetter: function(data) {

        return data.data?.lastUpdated ? moment(data.data?.lastUpdated).format('MMM DD, YYYY') : moment(data.data?.created).format('MMM DD, YYYY');

  },
      comparator:function dateComparator(date1, date2) {
        var date1Number = date1 && new Date(date1).getTime();
        var date2Number = date2 && new Date(date2).getTime();

        
        
      
        if (date1Number == null && date2Number == null) {
          return 0;
        }
      
        if (date1Number == null) {
          return -1;
        } else if (date2Number == null) {
          return 1;
        }
      
        return date1Number - date2Number;
      }
    },
    // {
    //   headerName: "Associated Platforms",
    //   minWidth: 100,
    //   maxWidth: 110,
    //   sortable: false,
    //   filter: false,
    //   cellRenderer: "buttonEditRenderer",
    //   cellRendererParams: {
    //     label: "associatedPlatforms",
    //     dynamicComponent : false
    //   },

    // },
    {
      headerName: 'Delete Selected',
      field: 'deleteSelected',
      cellRenderer: "checkboxRenderer",
      editable: false,
      sortable: false,
      minWidth: 80,
      resizable: false,
      cellRendererParams: {
        onClick: this.onDeleteSelected.bind(this),
        label: "deleteSelected",
        dynamicComponent : false
      },
    },
    {
      headerName: "Associated Platforms",
      minWidth: 100,
      maxWidth: 110,
      sortable: false,
      filter: false,
      cellRenderer: "buttonEditRenderer",
      cellRendererParams: {
        label: "associatedPlatforms",
        dynamicComponent : false
      },

    },
    {
      headerName: "",
      minWidth: 57,
      maxWidth: 57,
      sortable: false,
      filter: false,
      cellRenderer: "buttonEditRenderer",
      cellRendererParams: {
        onClick: this.onEditButtonClicked.bind(this),
        label: "editFanPolicy",
        dynamicComponent : false
      },

    },
    {
      headerName: "",
      minWidth: 57,
      maxWidth: 57,
      sortable: false,
      filter: false,
      cellRenderer: "buttonEditRenderer",
      cellRendererParams: {
        onClick: this.onDeleteButtonClick.bind(this),
        label: "DeletePIDDetails",
        dynamicComponent : false
      },

    },
    { headerName: 'sequence', field: 'sequence', hide: true },
    { headerName: 'deleteFlag', field: 'deleteFlag', hide: true },
  ];
  defaultColDef = {
    editable: false,
    sortable: true,
    //flex: 1,
    minWidth: 90,
    filter: true,
    resizable: true,
    headerComponentParams: {
      template:
        '<div class="ag-cell-label-container" role="presentation">' +
        '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
        '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
        '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
        '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
        '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
        '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
        '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
        '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
        '  </div>' +
        '</div>',
    },
    filterParams: { newRowsAction: 'keep'},
  };
  frameworkComponents = {
    btnCellRenderer: DeleteRenderComponent,
    buttonEditRenderer: EditRenderComponent,
    checkboxRenderer: CheckboxRenderComponent
  };
  getFanPolicy() {
    this.blockUI.start('Loading...');
    const url = UrlConstant.MANAGE_FAN_POLICY;
    this.subscriptions.add(this.apiService.getMethod(url).subscribe((result: any) => {
      this.rowData = result;
      this.blockUI.stop();
      //  this.dialog.close();
      //this.showLoader = false;
    }, error => {
      this.errorMsg = error?.error ? error.error?.error?.errorString : errorCodes.GENERIC_ERROR_MESSAGE;
      this.toaster.show('error', this.errorMsg, error ? error?.message : '');
      this.blockUI.stop();//this.showLoader = false;    
    }));
  }

  onDeleteButtonClick(params: any) {
    this.gridOptions.api?.updateRowData({ remove: [params.data.pid && params.data.policy] });
  }
  onEditButtonClicked(params: any) {
    this.openDialog(params);
    //this.getManageIoDevicesList();    
  }
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    var allColumnIds = [];
    this.gridColumnApi.getAllColumns().forEach(function (column) {
      allColumnIds.push(column.colId);
    });
    this.gridColumnApi.autoSizeColumns(allColumnIds, false);

  }
  headerHeightSetter(event: any) {
    var padding = 20;
    var height = this.headerHeightGetter() + padding;
    this.gridApi.setHeaderHeight(height);
    this.gridApi.resetRowHeights();
  }

  headerHeightGetter() {
    var columnHeaderTexts = document.querySelectorAll('.ag-header-cell-text');

    var columnHeaderTextsArray = [];

    columnHeaderTexts.forEach(node => columnHeaderTextsArray.push(node));

    var clientHeights = columnHeaderTextsArray.map(
      headerText => headerText.clientHeight
    );
    var tallestHeaderTextHeight = Math.max(...clientHeights);
    return tallestHeaderTextHeight;
  }
  resetGrid() {
    this.gridApi.setFilterModel(null);
    //this.flagBackButton = false;
  }

  openDialog(params: any): void {
    const dialogRef = this.dialog.open(CreateFanpolicyComponent, {
      data: {
        params: params,
        // InternalName : this.internalName,
        type: ComponentConstant.FAN_POLICY
      },
      disableClose: true,
    }
    );

    dialogRef.afterClosed().subscribe(result => {
      try {
        if (result !== 'cancel') {
          this.getFanPolicy();
         setTimeout(() => this.gridApi.paginationGoToLastPage(), 100);
        }
      } catch (error) {
        
      }
    });
  }
  onDeleteSelected(params: any, checked: boolean) {
    const PID = params?.data.pid;
    const policy = params.data.policy;
    
    if (this.dataforMultipleDelete.length == 0 && checked) {
      this.dataforMultipleDelete = [{
        "pid": PID,
        "policies": [policy]
      }];
    } else {
      if (this.dataforMultipleDelete.filter(e => e.pid === PID).length > 0 && checked) {
        let selectedPid = this.dataforMultipleDelete.filter(e => e.pid === PID);
        selectedPid[0]['policies'].push(policy);
      } 
      if (this.dataforMultipleDelete.filter(e => e.pid === PID).length == 0 && checked) {
        const data = {
          "pid": PID,
          "policies": [policy]
        };
        this.dataforMultipleDelete.push(data);
      }
      if(!checked){
        for(let i=0;i< this.dataforMultipleDelete.length;i++){
          let pid = this.dataforMultipleDelete[i].pid;
          let pol = this.dataforMultipleDelete[i].policies;
          if(pid ==PID && pol.includes(policy)){
            for(let j=0;j<pol.length;j++){
              if(pol[j]==policy) pol.splice(j,1);
            }
          }
          if(pol.length == 0){
            this.dataforMultipleDelete.splice(i,1);
          }
        }
      }
    }
  }

  public async openModal2($event) {
    
    const result = await this.modalService
      .open({
        content: {
          type: CngContentConfigType.COMPONENT,
          content: ConfirmationBoxComponent,
          componentData: {
            someText: this.translate.instant(
              "ARE_YOU_SURE_TO_DELETE_SELECTED_COMPONENT"
            ),
            key: "delete",
            event: $event,
          },
        },
        size: CngModalSize.SMALL,
      })
      .onDismiss.toPromise();

    if (result == 'ok') {
      this.multipleDelete();
    }
  }
  multipleDelete() {
    const url = UrlConstant.DELETE_FAN_POLICY;
    this.blockUI.start('Loading...');
    if (this.dataforMultipleDelete.length > 0) {
      this.subscriptions.add(this.apiService.postMethod(url, this.dataforMultipleDelete).subscribe((data: any) => {
this.getFanPolicy();
        
        if (data?.modifiedCount == 0 && data?.error?.errorString != "") {
          this.toaster.show('error', data?.error?.errorString, '', 5000);
        } else {
          this.toaster.show('success', errorCodes.DELETE_RECORD, '', 5000);
          // this.params.api.updateRowData({remove: [this.params.node.data]});
        }
        this.dataforMultipleDelete = [];
        this.blockUI.stop();
      }, error => {
        this.errorMsg = error?.error ? error.error?.error?.errorString : errorCodes.GENERIC_ERROR_MESSAGE;
        this.toaster.show('error', this.errorMsg, error ? error?.message : '');
        this.blockUI.stop();
      }));
    }
  }

  openShowFields(){
    
      //show/hide column
      
  
      
      var dd = this.gridColumnApi.getAllDisplayedColumns();
      dd.forEach(element => {
        
      });
      //this.gridColumnApi.setColumnsVisible(["basePid", "cpuSockets"], false);
      // const group = this.gridColumnApi.columnController.columnDefs;
      // this.gridOptions.columnDefs.forEach(child => this.gridColumnApi.setColumnVisible(child.field, false));
      
      let allcols = this.gridColumnApi.getAllColumns();
      const dialogRef = this.dialog.open(ShowFieldsComponent, {      
        data: {
               fields : allcols,
              } ,
        disableClose: false,
        // panelClass: 'width-dialog-100',           
      });   
  
      dialogRef.afterClosed().subscribe(result => {
         
        // let show = result.data.fields.filter(v => { return v.visible == true });
        // let hide = result.data.fields.filter(v => { return v.visible == false });
        let show = [];
        let hide = [];
        result.data.fields.forEach(element => {
          if(element.visible == true){
            show.push(element.colId)
          }
          else{
            hide.push(element.colId)
          }
        });
        
        
        //save to localstorage
      const setFields = {show:show,hide:hide}
      localStorage.setItem(ComponentConstant.FAN_POLICY+"GridFields", JSON.stringify(setFields));

        this.gridColumnApi.setColumnsVisible(show, true);
        this.gridColumnApi.setColumnsVisible(hide, false);
        this.gridColumnApi.moveColumns(setFields.show, 0);

        this.headerHeightSetter(null);
      });
    }
  ngOnDestroy() {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }
}



