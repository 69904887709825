import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-help-docs',
  templateUrl: './help-docs.component.html',
  styleUrls: ['./help-docs.component.css']
})
export class HelpDocsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
