export class 
UrlConstant {
    public static LICENSETEXT = 'license';
    public static COMPUTE = 'chassis/Compute';
    public static NETWORKING = 'chassis/Networking';
    public static SERVER_DETAILS = 'configAdd'
    public static BLADE_LIST = 'blades';
    public static NODE_LIST = 'nodes';
    public static MANAGECOMPONENTS = 'admin/manageComponentData';
    public static ADDITIONALCOMPONENTS = 'admin/insertAdditionalComponentData';
    public static DELETE = '/delete';
    public static UPDATE = '/update';
    public static POWER_SUPPLY = '/powerSupply';
    public static VOLTAGE_CURVE ='/updateVoltageCurve';
    public static MANAGE_PLATFORM ='admin/managePlatforms';
    public static GET_DOCUMENT = 'document/getDocumentList';
    public static UPLOAD_DOCUMENT = 'document/uploadDocuments';
    public static DELETE_DOCUMENT = 'document/delete';
    public static DOWNLOAD_DOCUMENT = 'document/download';

    public static ADD_CONFIG_DETAIL = 'admin/addConfigDetail';
    public static ADD_CONFIG = '/addConfig';
 
    public static CHASSISPOWER = 'chassis/power';
    public static RACKPOWER = 'rackmount/power';
    public static MODULARPOWER = 'mseries/power';
    public static CARTRIDGE = 'cartridge/power';
    public static BLADE = 'blade/power';
    public static FABRIC_EXTENDER = 'fabric/extender/power';
    public static FABRIC_INTERCONNECT = 'fabric/interconnect/power';
    public static MANAGE_COMP_OPTIONS= 'admin/manageComponentOptions'
    public static MANAGE_COMP_DATA = 'admin/manageComponentData/'
    public static MANAGE_PLATFORM_COMP_OPTIONS = 'admin/managePlatforms/manageComponentOptions';
    public static MANAGEMENTS_CPU = 'admin/addManagementsCpu';
    public static GET_DIMS_INCOM = 'admin/getDimsIncom';
    public static CPU_DIMM_INCOMP = 'admin/managePlatforms/cpuDimmIncompatible';
    public static MANAGEMENTS_DIMMS = 'admin/addManagementsDims';
    public static MANAGEMENTS_DETAILS = 'admin/addManagementsDetails';
    public static GET_MEASURE_DATA = 'admin/getMeasureData';
    public static GET_COMP_DIS_NAMES = 'admin/componentsDisplayNames';
    public static ADD_NEW_MEASUREMENT = 'admin/managePlatforms/newMeasurement';
    public static GET_ALL_BLADED_NODES='admin/getAllBladesOrNodes';
    public static UPLOAD_IMAGES = 'admin/managePlatforms/uploadImage';
    public static PROJECT_POWER = 'project/power';
    public static GET_PID = 'admin/getpid?match=';
    public static MEASUREMENT_CLONE = 'admin/managePlatforms/measurements/batchClone';
    public static MANAGE_FAN_POLICY = 'admin/getfanpolicies';
    public static DELETE_FAN_POLICY = 'admin/deleteFanPolicy';
    public static SAVE_FAN_POLICY = 'admin/saveFanPolicy';
    public static MANAGE_PLATFORMS_PANEL_DATA= 'admin/managePlatformsLPanelData';
    public static GET_ALL_SERIES= 'admin/getseries';
    public static GET_FAN_TABLE = 'admin/getFanTable';
    public static SAVE_FAN_TABLE = 'admin/assignFanPolicy'
    public static GET_ASSOCIATED_PLATFORMS = 'admin/platformsAssociatedToComps'
    public static REMOVE_COMPONENT_OPTIONS = 'admin/removeComponentOptions'
    public static GET_PLATFORM_DIMMS_INCOMPAT = 'admin/getPlatformDimsIncom';
    public static GET_MEAUREMENT_BY_CONFIGS = 'admin/getPlatformMeasurements';

    public static SERVER_DETAILS_ADMIN = 'admin/configAdd';
    public static COMPUTE_ADMIN = 'admin/chassis/Compute';
    public static NETWORKING_ADMIN = 'admin/chassis/Networking';
    public static BLADE_LIST_ADMIN = 'admin/blades';
    public static NODE_LIST_ADMIN = 'admin/nodes';
    public static DYNAMIC_COMPONENT_ATTRIBUTES = 'admin/dynamicComponentAttributes';




}