import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { Routes, RouterModule } from "@angular/router";
import { ProjectDetailsComponent } from "./project-details.component";
import { AuthorizationGuard } from "../../services/authorization.guard";
import { ProjectConfigurationComponent } from "./project-configuration/project-configuration.component";
import { ServerDetailsComponent } from "./server-details/server-details.component";
const projectRoutes: Routes = [
  {
    path: "",
    component: ProjectDetailsComponent,
    children: [
      //   { path: ':id/detail', component: ProductDetailComponent },
      {
        path: "",
        component: ProjectConfigurationComponent,
        canActivate: [AuthorizationGuard],
      },
      {
        path: "serverDetails",
        component: ServerDetailsComponent,
        canActivate: [AuthorizationGuard],
      },
    ],
  },
];

@NgModule({
  imports: [CommonModule, RouterModule.forChild(projectRoutes)],
  declarations: [],
  exports: [RouterModule],
})
export class ProjectRoutingModule { }
