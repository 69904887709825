<div class="edit-header">
  <div class="flex flex-between" style="max-width: 920px;">
    <h4>Edit Header</h4>
    <div>
      <button (click)="reset()" class="btn btn--small btn--ghost"
        style="height: 24px;margin: 5px 20px 0 0;">Reset</button>
      <button (click)="addHeader()" class="btn btn--small btn--primary"
        style="height: 24px;margin: 5px 20px 0 0;">Add</button>
    </div>
  </div>
  <div>
    <label style="color: red;"> {{lblErrMsg}}</label>
  </div>
  <div style="padding: 0 10px;">
    <div *ngIf="!showLoader">

      <div style="display: flex;">

        <div>
          <div *ngFor="let header of headList; let i = index" style="display: flex;margin-top: 10px;">
            <h5 style="padding: 20px 10px 0 0;">Link {{i + 1}} - </h5>
            <div class="form-group" style="width: 200px;margin: 0 10px 0 0;">
              <div class="form-group__text">
                <input [ngClass]="header.title.trim() == '' || header.title ==null ? 'text-danger': 'text-normal'"
                  [(ngModel)]="header.title" [disabled]="!header.enableEdit" type="text"
                  (ngModelChange)="isformValid()">
                <label style="width: min-content;float: left;opacity: 1;">Title</label>
              </div>
            </div>
            <div class="form-group" style="width: 200px;margin: 0 10px 0 0;">
              <div class="form-group__text">
                <input [ngClass]="header.cssClass.trim() == '' || header.cssClass ==null ? 'text-danger': 'text-normal'"
                  [(ngModel)]="header.cssClass" type="text" [disabled]="!header.enableEdit"
                  (ngModelChange)="isformValid()">
                <label style="width: min-content;float: left;display: flex;opacity: 1;">Icon
                  <a style="margin-left: 2px;" href="http://cisco-ui.cisco.com/section-primitives.html#primitives-icons"
                    target="_blank"><span class="icon-jump-out icon-size-12"></span> </a>
                </label>
              </div>
            </div>
            <div class="form-group" style="width: 400px;margin: 0 10px 0 0;">
              <div class="form-group__text">
                <input [ngClass]="!header.isUrlvalid ? 'text-danger': 'text-normal'" [(ngModel)]="header.url"
                  type="text" [disabled]="!header.enableEdit"
                  (ngModelChange)="validateURL($event,header);isformValid();">
                <label style="width: min-content;float: left;opacity: 1;">Url</label>
              </div>
              <mat-error *ngIf="!header.isUrlvalid" style="color:red">Invalid URL</mat-error>

            </div>
            <div class="form-group" style="display: flex;">
              <div class="flex-center" style="margin-left: 10px;margin-top: 14px;">
                <a class="anchor-edit" id="edit" title="Edit" (click)="enableEdit(header)">
                  <span class="icon-edit icon-size-20 icon-edit icon-color"></span>
                </a>
              </div>
              <div class="flex-center" style="margin-left: 10px;margin-top: 14px;">
                <a class="anchor-delete" id="edit" title="Edit" (click)="deleteHeader(header,i)">
                  <span class="icon-delete icon-size-20 icon-delete icon-color"></span>
                </a>
              </div>
            </div>
          </div>
        </div>

      </div>



      <button [disabled]="!isSaveEnabled" (click)="updateHeadList('update')" class="btn btn--primary"
        style="margin: 20px 0 0 20px;">Save</button>



    </div>
  </div>
</div>