import { HttpClient, HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { environment } from "src/environments/environment";
import { catchError } from "rxjs/operators";
import { AppConstant } from "src/constants/app.constants";

@Injectable({
  providedIn: "root",
})
export class ApiService {
  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Basic " + btoa("ucs_user:ucs_password"),
      "PI-User": JSON.parse(localStorage.getItem('userData'))?.mail && JSON.parse(localStorage.getItem('userData'))?.mail.split("@")[0] ? JSON.parse(localStorage.getItem('userData'))?.mail.split("@")[0] : "Admin"
    }),
  };
  constructor(private http: HttpClient) { }

  /**
   * GET api call.
   *
   * @param url
   */
  getMethod(url: string): Observable<any> {
    return this.http
      .get(environment.apiUrl + url, this.httpOptions)
      .pipe(catchError(this.handleError));
  }
  getMethodActuatorHealth(url: string): Observable<any> {

    return this.http
      .get(environment.env + url, this.httpOptions)
      .pipe(catchError(this.handleError));
  }
  postMethod(url: string, data: any): Observable<any> {

    return this.http
      .post(environment.apiUrl + url, data, this.httpOptions)
      .pipe(catchError(this.handleError));
  }
  postMethodAdmin(url: string, data: any): Observable<any> {
    return this.http
      .post(environment.env + url, data, this.httpOptions)
      .pipe(catchError(this.handleError));
  }
  //for pis search
  postMethodForAuth(url: string): Observable<any> {

    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
      }),
    };
    let data = {}
    return this.http
      .post(url, data, httpOptions)
      .pipe(catchError(this.handleError));
  }

  getPIDSuggestions(url: string, token: string) {
    let httpOptions = {
      headers: new HttpHeaders({
        Authorization: "Basic " + btoa("ucs_user:ucs_password"),
        token: token
      }),
    };
    return this.http
      .get(environment.apiUrl + url, httpOptions)
      .pipe(catchError(this.handleError));
  }

  putMethod(url: string, data: any): Observable<any> {
    return this.http
      .put(environment.apiUrl + url, data, this.httpOptions)
      .pipe(catchError(this.handleError));
  }

  deleteMethod(url: string, data: any): Observable<any> {
    return this.http
      .delete(environment.apiUrl + url, this.httpOptions)
      .pipe(catchError(this.handleError));

  }

  postMethodOnlyAuth(url: string, data: any): Observable<any> {

    let httpOptions = {
      headers: new HttpHeaders({
        Authorization: "Basic " + btoa("ucs_user:ucs_password"),
      }),
    };
    return this.http
      .post(environment.apiUrl + url, data, httpOptions)
      .pipe(catchError(this.handleError));
  }

  getFile(url: string): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Accept': 'text/html, application/xhtml+xml, */*',
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: "Basic " + btoa("ucs_user:ucs_password"),
        'responseType': 'blob' as 'json'
      }),

    };

    return this.http.get(environment.apiUrl + url, httpOptions);
  }
  /**
   * Check the error message.
   *
   * @param error
   * @returns {ErrorObservable}
   */
  handleError(error: HttpErrorResponse | any) {
    return throwError(error);
  }

  postPowerMethod(url: string, data: any): Observable<any> {
    return this.http
      .post(environment.powerUrl + url, data, this.httpOptions)
      .pipe(catchError(this.handleError));
  }

}
