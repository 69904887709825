import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { GridOptions } from "ag-grid-community";
import * as moment from "moment";
import { BlockUI, NgBlockUI } from "ng-block-ui";
import { Subscription } from "rxjs";
import { AdminService } from "src/app/services/admin.service";
import { ApiService } from "src/app/services/api.service";
import { UpdateNameService } from "src/app/services/update-name.service";
import { CheckboxRenderComponent } from "src/app/shared/components/checkbox-render/checkbox-render.component";
import { DeleteRenderComponent } from "src/app/shared/components/delete-render/delete-render.component";
import { EditRenderComponent } from "src/app/shared/components/edit-render/edit-render.component";
import { ShowFieldsComponent } from "src/app/shared/components/show-fields/show-fields.component";
import { ToasterService } from "src/app/shared/components/toast/toast.service";
import { PlatformConstant } from "src/constants/platform.constants";
import { UrlConstant } from "src/constants/url.constants";
import { CreateNewAdditionalComponent } from "../create-new-additional/create-new-additional.component";

export interface DialogData {
  displayName: string;
  internalName: string;
  componentDisplayName: string;
}
@Component({
  selector: "app-manage-new-two",
  templateUrl: "./manage-new-two.component.html",
  styleUrls: ["./manage-new-two.component.css"],
})
export class ManageNewTwoComponent implements OnInit {
  displayName: string;
  internalName: string;
  gridApi: any;
  gridColumnApi: any;
  componentIdx: number;
  componentName: string;
  componentDisplayName: string = "";
  componentType: string;
  currentComponent: any;
  dynamicComponentAttributes: any[] = [];
  subscriptions: Subscription = new Subscription();
  @BlockUI() blockUI: NgBlockUI;
  showLoader = true;
  errorMsg = "";
  columnDefs;
  frameworkComponents;
  defaultColDef;
  rowData;
  gridOptions: GridOptions = {};
  deleteConfirm;
  flagBackButton = false;
  isSetGridFields: boolean = false;

  constructor(
    private apiService: ApiService,
    private adminService: AdminService,
    public dialog: MatDialog,
    private updateNameService: UpdateNameService,
    private toaster: ToasterService,
  ) {
    this.columnDefs = [
      {
        headerName: "Released State",
        field: "released",
        cellRenderer: "checkboxRenderer",
      },
      { headerName: "Updated By", field: "editor" },
      {
        headerName: "Last Updated",
        field: "lastUpdated",
        cellRenderer: (data) => {
          return data.value
            ? moment(data.value).format("MMM DD, YYYY")
            : moment(data.data.created).format("MMM DD, YYYY");
        },
        valueGetter: function (data) {
          return data.data?.lastUpdated
            ? moment(data.data?.lastUpdated).format("MMM DD, YYYY")
            : moment(data.data?.created).format("MMM DD, YYYY");
        },
        comparator: function dateComparator(date1, date2) {
          var date1Number = date1 && new Date(date1).getTime();
          var date2Number = date2 && new Date(date2).getTime();

          if (date1Number == null && date2Number == null) {
            return 0;
          }
          if (date1Number == null) {
            return -1;
          } else if (date2Number == null) {
            return 1;
          }
          return date1Number - date2Number;
        },
      },
      {
        headerName: "Associated Platforms",
        minWidth: 100,
        maxWidth: 110,
        sortable: false,
        filter: false,
        cellRenderer: "buttonEditRenderer",
        cellRendererParams: {
          label: "associatedPlatforms",
          dynamicComponent: true,
        },
      },
      {
        headerName: "",
        minWidth: 50,
        maxWidth: 60,
        sortable: false,
        filter: false,
        cellRenderer: "buttonEditRenderer",
        cellRendererParams: {
          onClick: this.onEditButtonClicked.bind(this),
          label: "Edit",
          dynamicComponent: true,
          dynamicComponentAttributes: this.dynamicComponentAttributes,
        },
      },
      {
        headerName: "",
        minWidth: 50,
        maxWidth: 60,
        sortable: false,
        filter: false,
        cellRenderer: "buttonEditRenderer",
        cellRendererParams: {
          onClick: this.onDeleteButtonClick.bind(this),
          label: "Delete",
          dynamicComponent: true,
        },
      },
    ];
    this.updateNameService.getCurrentData().subscribe((data) => {
      if (data.length > 0) {
        this.componentName = data.filter(
          (comp) => comp.position == 10
        )[0].displayName;
        let compNameSplit = this.componentName
          .trim()
          .replace("Manage", "")
          .replace("manage", "");
        this.componentDisplayName = compNameSplit;
        this.currentComponent = data.filter((comp) => comp.position == 10)[0];
        if (this.currentComponent) this.setGridFields();
      }
    });

    this.updateNameService.getCurrentType().subscribe((data) => {
      if (data.length > 0) {
        this.componentType = data[9].componentType;
        this.getManageDevicesList();
      }
    });

    this.defaultColDef = {
      editable: false,
      sortable: true,
      flex: 1,
      minWidth: 100,
      filter: true,
      resizable: true,
      headerComponentParams: {
        template:
          '<div class="ag-cell-label-container" role="presentation">' +
          '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
          '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
          '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
          '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
          '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
          '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
          '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
          '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
          "  </div>" +
          "</div>",
      },
      filterParams: { newRowsAction: 'keep'},
    };
    this.frameworkComponents = {
      btnCellRenderer: DeleteRenderComponent,
      buttonEditRenderer: EditRenderComponent,
      checkboxRenderer: CheckboxRenderComponent,
    };
  }
  paginationPageSize = 10;
  value: String;
  name: String;

  ngOnInit(): void {
    this.adminService.getData().subscribe((data) => {
      if (data) {
        this.flagBackButton = true;
        this.getDuplicateRecords(data);
      }
    });
  }

  getDuplicateRecords(data: any) {
    const filter = {
      displayName: {
        filterType: "text",
        type: "equals",
        filter: data.displayName,
      },
      pid: {
        filterType: "text",
        type: "equals",
        filter: data.pid,
      },
    };
    this.gridApi.setFilterModel(filter);
  }

  getManageDevicesList() {
    this.blockUI.start("Loading...");
    const url =
      UrlConstant.MANAGECOMPONENTS + "/?component=" + this.componentType;
    this.subscriptions.add(
      this.apiService.getMethod(url).subscribe((data: any) => {
        this.rowData = data.componenetList;
        this.adminService.setGridData(data.componenetList);
        //to add addtional fields in grid
        if (data.addlabels) {
          let index = this.columnDefs.length - 2;
          data.addlabels.forEach((element) => {
            let headerIndex = this.columnDefs.findIndex(
              (f) => f.headerName == element
            );
            if (headerIndex == -1)
              this.columnDefs.splice(index, 0, {
                headerName: element,
                colId: element,
                hide: true,
                field: element,
                cellRenderer: (data) => {
                  let value = null;
                  data.data.additionalFields?.forEach((element1) => {
                    if (element1.name == data.colDef.headerName)
                      value = element1.value;
                  });
                  return value;
                },
                valueGetter: function (data) {
                  let value = null;
                  data.data.additionalFields?.forEach((element1) => {
                    if (element1.name == data.colDef.headerName)
                      value = element1.value;
                  });
                  return value;
                },
              });
          });
        }
        this.gridApi.setColumnDefs(this.columnDefs);
        let setFields = JSON.parse(
          localStorage.getItem(this.componentType + "GridFields")
        );
        if (setFields?.show && setFields?.hide) {
          this.gridColumnApi.setColumnsVisible(setFields.show, true);
          this.gridColumnApi.setColumnsVisible(setFields.hide, false);
          this.gridColumnApi.moveColumns(setFields.show, 0);
        }
        this.blockUI.stop();
      }, (error) => {
        console.error(error);
        this.errorMsg = error?.error ? error.error?.error?.errorString : "Oops something went wrong!!";
        this.toaster.show('error', this.errorMsg, error ? error?.message : '');
        this.blockUI.stop(); 
      })
    );
  }

  onDeleteButtonClick(params: any) {
    this.gridOptions.api?.updateRowData({ remove: [params.node.data.Id] });
  }
  onEditButtonClicked(params: any) {
    this.getManageDevicesList();
  }
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    if (this.currentComponent) this.setGridFields();
  }
  setGridFields() {
    if (!this.isSetGridFields && this.gridApi) {
      let dynamicComponentAttributes =
        this.currentComponent.dynamicComponentAttributes;
      this.adminService.setDynamicComponentAttributes(
        dynamicComponentAttributes
      );
      if (dynamicComponentAttributes && dynamicComponentAttributes.length > 0) {
        this.dynamicComponentAttributes = dynamicComponentAttributes;
        /**
         * the grid column api contains the pre-defined columns
         * releasedState, updatedBy, lastUpdated, associatedPlatforms, edit, delete
         * add the dynamic component attributes as additional columns
         */
        let currentColDefs = this.columnDefs;
        for (let attribute of dynamicComponentAttributes) {
          let fieldValue = attribute.key;
          if (attribute.key == "idlePower") fieldValue = "idleWatts";
          if (attribute.key == "maxPower") fieldValue = "maxWatts";
          if (attribute.key == "memoryFamilyName") fieldValue = "dimmFamName";
          if (attribute.key == "processorFamilyName") fieldValue = "cpuFamName";
          if (attribute.key == "noOfMemories") fieldValue = "numDimms";
          if (attribute.key == "deviceType") fieldValue = "storageType";
          if (attribute.key == "storageZone") fieldValue = "storageZoneType";
          if (attribute.key == "internalName") continue;
          let field = {
            headerName: attribute.name,
            field: fieldValue,
          };
          if (field.field == "ioType") {
            let raid = {
              headerName: "RAID",
              field: "raid",
            };
            currentColDefs.unshift(raid);
          }
          currentColDefs.unshift(field);
        }
        currentColDefs = currentColDefs.sort(
          (a, b) => this.getSortedOrder(a.field) - this.getSortedOrder(b.field)
        );
        this.gridApi.setColumnDefs(currentColDefs);
        this.gridColumnApi.moveColumns(
          currentColDefs.map((c) => c.field),
          0
        );
        this.isSetGridFields = true;
      }
    }
  }
  getSortedOrder(field?: string) {
    let fields = [
      "displayName",
      "internalName",
      "size",
      "pid",
      "cpuFamName",
      "dimmFamName",
      "mtbf",
      "mpn",
      "tdp",
      "ioType",
      "raid",
      "storageType",
      "storageZoneType",
      "capacity",
      "idleWatts",
      "maxWatts",
      "numDimms",
    ];
    return fields.indexOf(field) == -1
      ? Number.MAX_SAFE_INTEGER
      : fields.indexOf(field);
  }
  openDialog(): void {
    const dialogRef = this.dialog.open(CreateNewAdditionalComponent, {
      data: {
        componentDisplayName: this.componentDisplayName,
        dynamicComponentAttributes:
          this.currentComponent ? this.currentComponent.dynamicComponentAttributes : [],
        type: this.componentType,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      try {
        if (result.data.action !== PlatformConstant.ACTION_CANCEL) {
          this.getManageDevicesList();
         setTimeout(() => this.gridApi.paginationGoToLastPage(), 100);
        }
      } catch (error) {
        console.error("Catch error :" + error);
      }
    });
  }

  headerHeightSetter(event: any) {
    var padding = 20;
    var height = this.headerHeightGetter() + padding;
    this.gridApi.setHeaderHeight(height);
    this.gridApi.resetRowHeights();
  }

  headerHeightGetter() {
    var columnHeaderTexts = document.querySelectorAll(".ag-header-cell-text");

    var columnHeaderTextsArray = [];

    columnHeaderTexts.forEach((node) => columnHeaderTextsArray.push(node));

    var clientHeights = columnHeaderTextsArray.map(
      (headerText) => headerText.clientHeight
    );
    var tallestHeaderTextHeight = Math.max(...clientHeights);
    return tallestHeaderTextHeight;
  }
  resetGrid() {
    this.gridApi.setFilterModel(null);
    this.flagBackButton = false;
  }

  openShowFields() {
    let allcols = this.gridColumnApi.getAllColumns();
    const dialogRef = this.dialog.open(ShowFieldsComponent, {
      data: {
        fields: allcols,
      },
      disableClose: false,
    });

    dialogRef.afterClosed().subscribe((result) => {
      let show = [];
      let hide = [];
      result.data.fields.forEach((element) => {
        if (element.visible == true) {
          show.push(element.colId);
        } else {
          hide.push(element.colId);
        }
      });

      const setFields = { show: show, hide: hide };
      localStorage.setItem(
        this.componentType + "GridFields",
        JSON.stringify(setFields)
      );

      this.gridColumnApi.setColumnsVisible(show, true);
      this.gridColumnApi.setColumnsVisible(hide, false);
      this.gridColumnApi.moveColumns(setFields.show, 0);

      this.headerHeightSetter(null);
    });
  }

  ngOnDestroy() {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }
}
