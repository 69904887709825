<block-ui>
<div class="edit-license">
    <div class="flex flex-between" style="padding: 0 10px;">
        <h4>Dimms Family <a *ngIf="flagBackButton" (click)="resetGrid()" style="font-size: medium; font-weight: 600; padding-left: 10px;">Back</a></h4>
        <button mat-raised-button (click)="openDialog()"  class="btn btn--small " style="height: 24px;margin: 5px 20px 0 0;">New Dimms Family</button>       
  
    </div>
    <div style="padding: 0 10px;">
        <ag-grid-angular
        style="width: 100%; height: 520px;"
        class="ag-theme-alpine"
        [rowData]="rowData"
        [columnDefs]="columnDefs"
        [pagination]="true"
        [paginationPageSize]="paginationPageSize"         
        [defaultColDef]="defaultColDef"
        (gridReady)="onGridReady($event)"
        [frameworkComponents]="frameworkComponents">
    </ag-grid-angular>
    </div>
</div>
</block-ui>
