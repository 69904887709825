<div class="mat-dialog-content" style="min-height: 60vh;">
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle class="flex flex-between"
        style="margin-left: -10px;margin-bottom: 0px;">
        <button mat-button (click)="closeModal()" class="btn btn--link focus">Back</button>
        <div style="    display: flex;
    flex-direction: row-reverse;">
            <div class="flex-center margin_auto_t_b">
                <a class="anchor-close" id="close" title="Close" (click)="closeModal()">
                    <span class="icon-close icon-size-24 icon-close"></span>
                </a>
            </div>
        </div>
    </div>

    <form [formGroup]="measurementForm" autocomplete="off">
        <div class="container-fluid">
            <h4>New Measurement</h4>
            <div class="section">
                <div class="col-md-12 col-lg-12">
                    <div class="row padding_row">
                        <div class="col-md-2">
                            <div class="form-group base-margin-bottom">
                                <div class="form-group__text">
                                    <input type="text" formControlName="FWRevision"
                                        [ngClass]="measurementForm.get('FWRevision').errors ? 'text-danger': 'text-normal'">
                                    <label>FW Revision</label>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-2">
                            <div class="form-group base-margin-bottom">
                                <div class="form-group__text cust_color">

                                    <label for="input-type-email">P State</label>

                                    <div class="form-group">
                                        <ngx-select-dropdown [multiple]="false"
                                            [ngClass]="measurementForm.get('pState').hasError('required') ? 'ngx-select-danger ngx-dropdown-button': 'ngx-select-normal ngx-dropdown-button'"
                                            formControlName="pState" [config]="{search:false,displayKey:'viewValue'}"
                                            [options]="enableStates">
                                        </ngx-select-dropdown>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div class="col-md-2">
                            <div class="form-group base-margin-bottom">
                                <div class="form-group__text cust_color">

                                    <label for="input-type-email">Turbo</label>

                                    <div class="form-group">
                                        <ngx-select-dropdown [multiple]="false"
                                            [ngClass]="measurementForm.get('turbo').hasError('required') ? 'ngx-select-danger ngx-dropdown-button': 'ngx-select-normal ngx-dropdown-button'"
                                            formControlName="turbo" [config]="{search:false,displayKey:'viewValue'}"
                                            [options]="enableStates">
                                        </ngx-select-dropdown>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div class="col-md-2">
                            <div class="form-group base-margin-bottom">
                                <div class="form-group__text">

                                    <label for="input-type-email">C state</label>

                                    <div class="form-group">
                                        <ngx-select-dropdown [multiple]="false"
                                            [ngClass]="measurementForm.get('cStates').hasError('required') ? 'ngx-select-danger ngx-dropdown-button': 'ngx-select-normal ngx-dropdown-button'"
                                            formControlName="cStates" [config]="{search:false,displayKey:'viewValue'}"
                                            [options]="cStates">
                                        </ngx-select-dropdown>
                                    </div>


                                </div>
                            </div>
                        </div>
                        <div class="col-md-2">
                            <div class="form-group base-margin-bottom">
                                <div class="form-group__text cust_color">
                                    <label for="input-type-email">Memory</label>

                                    <div class="form-group">
                                        <ngx-select-dropdown [multiple]="false"
                                            [ngClass]="measurementForm.get('memory').hasError('required') ? 'ngx-select-danger ngx-dropdown-button': 'ngx-select-normal ngx-dropdown-button'"
                                            formControlName="memory" [config]="{search:false,displayKey:'viewValue'}"
                                            [options]="memory">
                                        </ngx-select-dropdown>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-2">
                            <div class="form-group base-margin-bottom">
                                <div class="form-group__text">

                                    <label for="input-type-email">HT</label>

                                    <div class="form-group">
                                        <ngx-select-dropdown [multiple]="false"
                                            [ngClass]="measurementForm.get('ht').hasError('required') ? 'ngx-select-danger ngx-dropdown-button': 'ngx-select-normal ngx-dropdown-button'"
                                            formControlName="ht" [config]="{search:false,displayKey:'viewValue'}"
                                            [options]="enableStates">
                                        </ngx-select-dropdown>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row padding_row">
                        <div class="col-md-4">
                            <div class="form-group base-margin-bottom">
                                <div class="form-group__text">
                                    <input formControlName="noDisks"
                                        [ngClass]="measurementForm.get('noDisks').hasError('required') || measurementForm.get('noDisks').hasError('min') || measurementForm.get('noDisks').hasError('pattern') ? 'text-danger': 'text-normal'">
                                    <label>No Disks</label>
                                </div>
                                <span
                                    *ngIf="measurementForm.get('noDisks').hasError('pattern') || measurementForm.get('noDisks').hasError('min')"
                                    style=color:red>Only Positive Integers are allowed</span>

                            </div>

                        </div>
                        <div class="col-md-4">
                            <div class="form-group base-margin-bottom">
                                <div class="form-group__text">

                                    <label for="input-type-email">Disk</label>

                                    <div class="form-group">
                                        <ngx-select-dropdown [multiple]="false"
                                            [ngClass]="measurementForm.get('disk').hasError('required') ? 'ngx-select-danger ngx-dropdown-button': 'ngx-select-normal ngx-dropdown-button'"
                                            formControlName="disk"
                                            [config]="{search:true,displayKey:'displayName',height: '200px'}"
                                            [options]="storagesList">
                                        </ngx-select-dropdown>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group base-margin-bottom" *ngIf="platform_type == 'RACK'">
                                <div class="form-group__text">

                                    <label for="input-type-email">RAID</label>

                                    <div class="form-group">
                                        <ngx-select-dropdown [multiple]="false"
                                            [ngClass]="measurementForm.get('raid').hasError('required') ? 'ngx-select-danger ngx-dropdown-button': 'ngx-select-normal ngx-dropdown-button'"
                                            formControlName="raid"
                                            [config]="{search:true,displayKey:'displayName',height: '200px'}"
                                            [options]="ioesList">
                                        </ngx-select-dropdown>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row padding_row" *ngIf="platform_type == 'RACK'">
                        <div class="col-md-4">
                            <div class="form-group base-margin-bottom">
                                <div class="form-group__text">
                                    <input formControlName="noPsus"
                                        [ngClass]="measurementForm.get('noPsus').hasError('required') || measurementForm.get('noPsus').hasError('min') || measurementForm.get('noPsus').hasError('pattern') ? 'text-danger': 'text-normal'">
                                    <label>No PSUs</label>
                                </div>
                                <span
                                    *ngIf="measurementForm.get('noPsus').hasError('pattern') || measurementForm.get('noPsus').hasError('min') "
                                    style=color:red>Only Positive Integers are allowed</span>

                            </div>

                        </div>
                        <div class="col-md-4">
                            <div class="form-group base-margin-bottom">
                                <div class="form-group__text">

                                    <label for="input-type-email">Voltage</label>

                                    <div class="form-group">
                                        <ngx-select-dropdown [multiple]="false"
                                            [ngClass]="measurementForm.get('voltage').hasError('required') ? 'ngx-select-danger ngx-dropdown-button': 'ngx-select-normal ngx-dropdown-button'"
                                            formControlName="voltage" (change)="selectionChanged($event,'voltage')"
                                            [config]="{search:true,displayKey:'name',height: '180px'}"
                                            [options]="voltageList">
                                        </ngx-select-dropdown>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group base-margin-bottom">
                                <div class="form-group__text">
                                    <label for="input-type-email">Power Supply</label>
                                    <div class="form-group">
                                        <ngx-select-dropdown [multiple]="false"
                                            [ngClass]="measurementForm.get('pSupply').hasError('required') ? 'ngx-select-danger ngx-dropdown-button': 'ngx-select-normal ngx-dropdown-button'"
                                            formControlName="pSupply" (change)="selectionChanged($event,'powerSupply')"
                                            [config]="{displayKey:'displayName',height: '180px',search:true}"
                                            [options]="psList">
                                        </ngx-select-dropdown>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row padding_row">
                        <div class="col-md-4">
                            <div class="form-group base-margin-bottom">
                                <div class="form-group__text">
                                    <input type="text" formControlName="idlePower"
                                        (ngModelChange)="validateWatts($event)"
                                        [ngClass]="IdleWattsErr || showWattErr || measurementForm.get('idlePower').hasError('required') || measurementForm.get('idlePower').hasError('min') || measurementForm.get('idlePower').hasError('pattern') ? 'text-danger': 'text-normal'">

                                    <label>Idle Power (W)</label>
                                </div>
                                <mat-error *ngIf="showWattErr" style=color:red>Idle Power should be Less than Max Power
                                </mat-error>
                                <mat-error *ngIf="IdleWattsErr" style=color:red>Only Positive Integers are allowed
                                </mat-error>

                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group base-margin-bottom">
                                <div class="form-group__text">
                                    <input type="text" formControlName="maxPower"
                                        (ngModelChange)="validateWatts($event)"
                                        [ngClass]="IdleMaxWattsErr || showWattErr || measurementForm.get('maxPower').hasError('required') || measurementForm.get('maxPower').hasError('min') || measurementForm.get('maxPower').hasError('pattern') ? 'text-danger': 'text-normal'">

                                    <label>Max Power (W)</label>
                                </div>
                                <mat-error *ngIf="showWattErr" style=color:red>Max Power should be Greater than Idle
                                    Power</mat-error>
                                <mat-error *ngIf="IdleMaxWattsErr" style=color:red>Only Positive Integers are allowed
                                </mat-error>

                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group base-margin-bottom">
                                <div class="form-group__text">
                                    <textarea id="textarea-state-default" [ngClass]="'text-normal'" rows="5"
                                        formControlName="notes"></textarea>
                                    <label for="textarea-state-default">Notes</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </form>

</div>
<div>
    <button mat-button type="submit" class="btn btn--small " style="height: 24px;margin: 5px 20px 0 0;"
        (click)="submit('closeModal')"
        [disabled]="!measurementForm.valid || showWattErr || IdleWattsErr || IdleMaxWattsErr">Save & Close</button>
    <button mat-button class="btn btn--small"
        [disabled]="!measurementForm.valid || showWattErr || IdleWattsErr || IdleMaxWattsErr" (click)="submit('save')"
        style="height: 24px;margin: 5px 20px 0 0;">Save</button>
</div>