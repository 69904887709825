import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface DialogData {
  header: string;
}

@Component({
  selector: 'app-document-upload',
  templateUrl: './document-upload.component.html',
  styleUrls: ['./document-upload.component.css']
})

export class DocumentUploadComponent implements OnInit {
  
  header: string = '';
  isFileChosen: boolean = false;
  isHeaderSelected: boolean = false;
  fileName: string = '';
  uploadedFile: any;
  fileEvent: any;
  headers: any[] = [
    {id:1, viewValue: 'How to Videos'},
    {id:2, viewValue: 'Release Notes'},
    {id:3, viewValue: 'Upload Documents'}
  ];

  constructor(
    public dialogRef: MatDialogRef<DocumentUploadComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) { }

  ngOnInit(): void {
    
  }
  onUploadDocument() {
    
    this.dialogRef.close({
      "fileEvent": this.fileEvent,
      "title": this.header
    });
  }
  onCancel() {
    
    this.dialogRef.close('CANCEL');
  }
  onChooseFile($event) {
    this.isFileChosen = true;
    this.fileName = $event.target.files[0].name;
    this.fileEvent = $event;
    
  }
  onSelectHeader($event) {
    
    let selected = $event.value.viewValue;
    this.header = selected == undefined || selected == null ? '' : selected ;
    
    this.isFileChosen = false;
    this.fileName = '';
  }


}
