import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProjectService {
  // this flag is set to true when we open a chassis/server for edit. also for templates
  public editServerFlag = false;
  public payload = null;
  private globalUnitSource$ = new BehaviorSubject('');
  currentUnit = this.globalUnitSource$.asObservable();

  private country$ = new BehaviorSubject('');
  getCountry() {
    return this.country$.asObservable();
  }
  private emissionFactor$ = new BehaviorSubject('');
  getEmissionFactor() {
    return this.emissionFactor$.asObservable();
  }
  private powerCost$ = new BehaviorSubject('');
  getPowerCost() {
    return this.powerCost$.asObservable();
  }
  private projectData$ = new BehaviorSubject(null);
  getProjectData() {
    return this.projectData$.asObservable();
  }

  private chassisPid$ = new BehaviorSubject([]);
  getChassisPid() {
    return this.chassisPid$.asObservable();
  }

  private calculatedPower$ = new BehaviorSubject([]);
  getCalculatedPower() {
    return this.calculatedPower$.asObservable();
  }

  private serverData$ = new BehaviorSubject(null);
  getCurrentServer() {
    return this.serverData$.asObservable();
  }

  private templateFlag$ = new BehaviorSubject(false);
  getTemplateFlag() {
    return this.templateFlag$.asObservable();
  }
  private projectError$ = new BehaviorSubject(false);
  getProjectError() {
    return this.projectError$.asObservable();
  }
  private seriesData$ = new BehaviorSubject({});
  getSeriesData() {
    return this.seriesData$.asObservable();
  }
  private resetSeriesId$ = new BehaviorSubject(-1);
  getResetSeriesId() {
    return this.resetSeriesId$.asObservable();
  }
  private projectMethod$ = new BehaviorSubject('Manual');
  getProjectMethod() {
    return this.projectMethod$.asObservable();
  }
  setProjectMethod(method: string) {
    this.projectMethod$.next(method);
  }
  constructor() { }

  changeUnit(unit: any) {
    this.globalUnitSource$.next(unit)
  }

  setProjectData(currentData: any) {
    this.projectData$.next(currentData)
  }
  setCurrentServer(currentServer: any) {
    this.serverData$.next(currentServer)
  }
  setTemplateFlag(flag: any) {
    this.templateFlag$.next(flag)
  }

  setCurrentChassis(currentPid: any) {
    this.chassisPid$.next(currentPid)
  }

  setCalculatedPower(calculation: any) {
    this.calculatedPower$.next(calculation)
  }

  setPowerCost(powerCost: any) {
    this.powerCost$.next(powerCost)
  }
  setEmissionFactor(emissionFactor: any) {
    this.emissionFactor$.next(emissionFactor);
  }
  setCountry(country: any) {
    this.country$.next(country);
  }
  getServerFlag() {
    return this.editServerFlag;
  }
  setServerFlag(val: boolean) {
    this.editServerFlag = val;
  }
  getReqPayload() {
    return this.payload;
  }
  setReqPayload(val: any) {
    this.payload = val;
  }
  setProjectError(err: boolean) {
    this.projectError$.next(err);
  }
  setSeriesData(data: any) {
    this.seriesData$.next(data);
  }
  setResetSeriesId(val: any) {
    this.resetSeriesId$.next(val);
  }
  /** check whether a server is currently being edited */
  private isServerEdited$ = new BehaviorSubject(false);
  getServerEditStatus() {
    return this.isServerEdited$.asObservable();
  }
  setServerEditStatus(editStatus: boolean) {
    this.isServerEdited$.next(editStatus);
  }
  /** check current project name */
  private currentProjectName$ = new BehaviorSubject(null);
  getCurrentProjectName() {
    return this.currentProjectName$.asObservable();
  }
  setCurrentProjectName(projectName: string) {
    this.currentProjectName$.next(projectName);
  }
  /** check for additions/updations in project */
  private isAddedOrEdited$ = new BehaviorSubject(false);
  getIsAddedOrEdited() {
    return this.isAddedOrEdited$.asObservable();
  }
  setIsAddedOrEdited(isAddedOrEdited: boolean) {
    this.isAddedOrEdited$.next(isAddedOrEdited);
  }
  /** update class data */
  private secondaryClasses$ = new BehaviorSubject(null);
  getSecondaryClasses() {
    return this.secondaryClasses$.asObservable();
  }
  setSecondaryClasses(secondaryClasses) {
    this.secondaryClasses$.next(secondaryClasses);
  }
  calculateSystemWorkloadForChassis(server): number {
    let totalChildSystemWorkload = 0;
    if (server.type == 'CHASSIS') {
      for (let blade of server.blades) {
        if (blade.config && blade.power)
          totalChildSystemWorkload += blade.config.quantity * blade.power.maxInputPower * blade.power.systemWorkloadPercent;
      }
    } else if (server.type == 'MSERIES') {
      for (let node of server.nodes) {
        if (node.config && node.power)
          totalChildSystemWorkload += node.config.quantity * node.power.maxInputPower * node.power.systemWorkloadPercent;
      }
    } else {
      return server.power ? server.power.systemWorkloadPercent : server.config.systemWorkloadPercent;
    }
    let finalSystemWorkload = totalChildSystemWorkload / server.power.maxInputPower;
    return finalSystemWorkload;
  }
}
