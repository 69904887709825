export class AppConstant {
    public static KEY_SEC = 'secret key 123'; 
    public static AIR_FLOW_FACTOR = 17936.085;
    public static CUBIC_MSEC_CUBIC_MFT = 2118.88;
    public static COOLING_FACTOR_WATTS_BTU_PHR = 3.41214163;
    public static WEIGHT_FACTOR_POUNDS_TO_KG = 0.453592; 
    public static TEMPLATE = 'template';
    public static DC_DC_CONVERTION_FACTOR = 1.1;
    public static LOGGEDIN_USER = '';
    public static VALID_INPUT_REGEX = /^(\s+\S+\s*)*(?!\s).*$/;
}