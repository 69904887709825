import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminTabComponent } from './components/admin/admin-tab/admin-tab.component';
import { EditHeaderComponent } from './components/admin/admin-tab/edit-header/edit-header.component';
import { EditLicenseComponent } from './components/admin/admin-tab/edit-license/edit-license.component';
import { ManageIoDevicesComponent } from './components/admin/admin-tab/manage-io-devices/manage-io-devices.component';
import { ManageStorageDevicesComponent } from './components/admin/admin-tab/manage-storage-devices/manage-storage-devices.component';
import { ManagePowerSuppliesComponent } from './components/admin/admin-tab/manage-power-supplies/manage-power-supplies.component';
import { ManageProcessorFamilyComponent } from './components/admin/admin-tab/manage-processor-family/manage-processor-family.component';
import { ManageProcessorsComponent } from './components/admin/admin-tab/manage-processors/manage-processors.component';
import { ManageDimmsFamilyComponent } from './components/admin/admin-tab/manage-dimms-family/manage-dimms-family.component';
import { ManageDimmsComponent } from './components/admin/admin-tab/manage-dimms/manage-dimms.component';
import { ManageExpansionCardsComponent } from './components/admin/admin-tab/manage-expansion-cards/manage-expansion-cards.component';
import { ManageIoModulesComponent } from './components/admin/admin-tab/manage-io-modules/manage-io-modules.component';
import { AdminComponent } from './components/admin/admin.component';
import { HelpComponent } from './components/license/help/help.component';
import { LicenseComponent } from './components/license/license.component';
import { FinalResultsComponent } from './components/project-details/final-results/final-results.component';
//import { ProjectDetailsComponent } from "./components/project-details/project-details.component";
import { ProjectListComponent } from './components/project-list/project-list.component';
import { AuthorizationGuard } from './services/authorization.guard';
import { ManageRackServersComponent } from './components/admin/admin-tab/manage-platforms/manage-rack-servers/manage-rack-servers.component';
import { ManageBladeChassisComponent } from './components/admin/admin-tab/manage-platforms/manage-blade-chassis/manage-blade-chassis.component';
import { ManageBladeServersComponent } from './components/admin/admin-tab/manage-platforms/manage-blade-servers/manage-blade-servers.component';
import { ManageFabricExtendersComponent } from './components/admin/admin-tab/manage-platforms/manage-fabric-extenders/manage-fabric-extenders.component';
import { ManageFabricInterconnectsComponent } from './components/admin/admin-tab/manage-platforms/manage-fabric-interconnects/manage-fabric-interconnects.component';
import { ManageModularChassisComponent } from './components/admin/admin-tab/manage-platforms/manage-modular-chassis/manage-modular-chassis.component';
import { ManageNodesComponent } from './components/admin/admin-tab/manage-platforms/manage-nodes/manage-nodes.component';
import { DocumentComponent } from './components/admin/admin-tab/document/document.component';
import { ConfigsQcReportsComponent } from "./components/admin/admin-tab/configs-qc-reports/configs-qc-reports.component";
import { ManageFanPolicyComponent } from './components/admin/admin-tab/manage-fan-policy/manage-fan-policy.component';
import { ManageNewOneComponent } from './components/admin/admin-tab/manage-new-one/manage-new-one.component';
import { MaintenanceScreenComponent } from "./maintenance-screen/maintenance-screen.component";
import { ApplicationStatusGuard } from "./application-status.guard";
import { ManageAdditionalPlatform1Component } from './components/admin/admin-tab/manage-additional-platforms/manage-additional-platform1/manage-additional-platform1.component';
import { ManageAdditionalPlatform2Component } from './components/admin/admin-tab/manage-additional-platforms/manage-additional-platform2/manage-additional-platform2.component';
import { ManageAdditionalPlatform3Component } from './components/admin/admin-tab/manage-additional-platforms/manage-additional-platform3/manage-additional-platform3.component';
import { ManageAdditionalPlatform4Component } from './components/admin/admin-tab/manage-additional-platforms/manage-additional-platform4/manage-additional-platform4.component';
import { ManageAdditionalPlatform5Component } from './components/admin/admin-tab/manage-additional-platforms/manage-additional-platform5/manage-additional-platform5.component';

import { ManageNewTwoComponent } from './components/admin/admin-tab/manage-new-two/manage-new-two.component';
import { ManageNewThreeComponent } from './components/admin/admin-tab/manage-new-three/manage-new-three.component';
import { ManageNewFourComponent } from './components/admin/admin-tab/manage-new-four/manage-new-four.component';
import { ManageNewFiveComponent } from './components/admin/admin-tab/manage-new-five/manage-new-five.component';
import { HowToVideosComponent } from './components/license/help/how-to-videos/how-to-videos.component';
import { ReleaseNotesComponent } from './components/license/help/release-notes/release-notes.component';
import { HelpDocsComponent } from './components/license/help/help-docs/help-docs.component';
import { EditErrorMessagesComponent } from './components/admin/admin-tab/edit-error-messages/edit-error-messages.component';
import { EditSeriesComponent } from './components/admin/admin-tab/edit-series/edit-series.component';
import { ManageNewSixComponent } from './components/admin/admin-tab/manage-new-six/manage-new-six.component';
import { GlobalSearchResultsComponent } from './components/admin/admin-tab/global-search-results/global-search-results.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';

export const routes: Routes = [
  { path: '', redirectTo: '/license', pathMatch: 'full' },
  {
    path: 'license', component: LicenseComponent,
    canActivate: [ApplicationStatusGuard,]
  },
  { path: 'maintenance', component: MaintenanceScreenComponent },
  {
    path: 'help', component: HelpComponent, canActivate: [ApplicationStatusGuard],
    children: [
      { path: '', component: HelpDocsComponent },
      { path: 'howToVideos', component: HowToVideosComponent },
      { path: 'releaseNotes', component: ReleaseNotesComponent }
    ]
  },
  {
    path: 'projectList',
    component: ProjectListComponent,
    canActivate: [AuthorizationGuard, ApplicationStatusGuard],

  },
  {
    path: 'admin/projectList',
    component: ProjectListComponent,
    canActivate: [ApplicationStatusGuard],

  },
  {
    path: 'ucs-resizer/projectList',
    component: ProjectListComponent,
    canActivate: [AuthorizationGuard, ApplicationStatusGuard],
  },
  {
    path: 'admin/ucs-resizer/projectList',
    component: ProjectListComponent,
    canActivate: [ApplicationStatusGuard],
  },
  {
    path: 'projectDetails',
    canActivate: [ApplicationStatusGuard],
    loadChildren: () =>
      import('./components/project-details/project-details.module').then(
        (mod) => mod.ProjectDetailsModule
      ),
  },
  {
    path: 'admin/projectDetails',
    canActivate: [ApplicationStatusGuard],
    loadChildren: () =>
      import('./components/project-details/project-details.module').then(
        (mod) => mod.ProjectDetailsModule
      ),
  },
  {
    path: 'ucs-resizer/projectDetails',
    canActivate: [ApplicationStatusGuard],
    loadChildren: () =>
      import('./components/project-details/project-details.module').then(
        (mod) => mod.ProjectDetailsModule
      ),
  },
  {
    path: 'admin/ucs-resizer/projectDetails',
    canActivate: [ApplicationStatusGuard],
    loadChildren: () =>
      import('./components/project-details/project-details.module').then(
        (mod) => mod.ProjectDetailsModule
      ),
  },
  {
    path: 'ucs-resizer/projectDetails/:userName/:projectName',
    canActivate: [ApplicationStatusGuard],
    loadChildren: () =>
      import('./components/project-details/project-details.module').then(
        (mod) => mod.ProjectDetailsModule
      ),
  },
  {
    path: 'admin/ucs-resizer/projectDetails/:userName/:projectName',
    canActivate: [ApplicationStatusGuard],
    loadChildren: () =>
      import('./components/project-details/project-details.module').then(
        (mod) => mod.ProjectDetailsModule
      ),
  },
  {
    path: 'finalResults',
    component: FinalResultsComponent,
    canActivate: [AuthorizationGuard, ApplicationStatusGuard],
  },
  {
    path: 'admin/finalResults',
    component: FinalResultsComponent,
    canActivate: [ApplicationStatusGuard],
  },
  {
    path: 'ucs-resizer/finalResults',
    component: FinalResultsComponent,
    canActivate: [AuthorizationGuard, ApplicationStatusGuard],
  },
  {
    path: 'admin/ucs-resizer/finalResults',
    component: FinalResultsComponent,
    canActivate: [ApplicationStatusGuard],
  },
  {
    path: 'admin',
    component: AdminComponent,
    canActivate: [ApplicationStatusGuard],

    children: [
      { path: '', redirectTo: 'admin-tab', pathMatch: 'full' },
      {
        path: 'admin-tab',
        component: AdminTabComponent,
        children: [
          { path: '', redirectTo: 'license', pathMatch: 'full' },
          { path: 'license', component: EditLicenseComponent },
          { path: 'header', component: EditHeaderComponent },
          { path: 'errorMessages', component: EditErrorMessagesComponent },
          { path: 'series', component: EditSeriesComponent },
          { path: 'manageIoDevices', component: ManageIoDevicesComponent },
          { path: 'manageStorageDevices', component: ManageStorageDevicesComponent },
          { path: 'managePowerSupplies', component: ManagePowerSuppliesComponent },
          { path: 'manageProcessorFamily', component: ManageProcessorFamilyComponent },
          { path: 'manageProcessors', component: ManageProcessorsComponent },
          { path: 'manageDimmsFamily', component: ManageDimmsFamilyComponent },
          { path: 'manageMemories', component: ManageDimmsComponent },
          { path: 'manageExpansionCards', component: ManageExpansionCardsComponent },
          { path: 'manageIoModules', component: ManageIoModulesComponent },

          { path: 'manageRackServers', component: ManageRackServersComponent },
          { path: 'manageBladeServers', component: ManageBladeServersComponent },
          { path: 'manageBladeChassis', component: ManageBladeChassisComponent },
          { path: 'manageNodes', component: ManageNodesComponent },
          { path: 'manageModularChassis', component: ManageModularChassisComponent },
          { path: 'manageFabricInterConnect', component: ManageFabricInterconnectsComponent },
          { path: 'manageFabricExtender', component: ManageFabricExtendersComponent },
          { path: 'manageAdditionalPlatform1', component: ManageAdditionalPlatform1Component },
          { path: 'manageAdditionalPlatform2', component: ManageAdditionalPlatform2Component },
          { path: 'manageAdditionalPlatform3', component: ManageAdditionalPlatform3Component },
          { path: 'manageAdditionalPlatform4', component: ManageAdditionalPlatform4Component },
          { path: 'manageAdditionalPlatform5', component: ManageAdditionalPlatform5Component },

          { path: 'manageFabricInterConnects', component: ManageFabricInterconnectsComponent },
          { path: 'manageFabricExtenders', component: ManageFabricExtendersComponent },
          { path: 'manageFanPolicies', component: ManageFanPolicyComponent },
          { path: 'additionalComponent1', component: ManageNewOneComponent },
          { path: 'additionalComponent2', component: ManageNewTwoComponent },
          { path: 'additionalComponent3', component: ManageNewThreeComponent },
          { path: 'additionalComponent4', component: ManageNewFourComponent },
          { path: 'additionalComponent5', component: ManageNewFiveComponent },
          { path: 'additionalComponent6', component: ManageNewSixComponent },

          { path: 'searchResults', component: GlobalSearchResultsComponent }

        ],
      },
      { path: 'estimate-power', component: AdminTabComponent, canActivate: [ApplicationStatusGuard] },
      { path: 'document', component: DocumentComponent, canActivate: [ApplicationStatusGuard] },
      { path: 'configs', component: ConfigsQcReportsComponent, canActivate: [ApplicationStatusGuard] },
      { path: 'qc-report', component: AdminTabComponent, canActivate: [ApplicationStatusGuard] },
    ],
  },
  {
    path: '**',
    component: PageNotFoundComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
