<block-ui>
    <div class="edit-license" >
        <div class="flex flex-between" style="padding: 0 10px;">
            <h4>{{ componentDisplayName }} <a *ngIf="flagBackButton" (click)="resetGrid()" style="font-size: medium; font-weight: 600; padding-left: 10px;">Back</a>
                <button class="btn btn--ghost" (click)="openShowFields()" style="padding: 0 10px;font-size: small;margin-left: 5px;"> 
                <span class="icon-list-menu" style="margin-right: 5px;"></span>Show Fields</button>
            </h4> 
            <div>
            <button mat-raised-button (click)="openModal2($event)"  class="btn btn--small " style="height: 24px;margin: 5px 20px 0 0;" [disabled]="dataforMultipleDelete.length ==0">Delete Selected</button>                                     
                <button mat-raised-button (click)="openDialog('')"  class="btn btn--small " style="height: 24px;margin: 5px 20px 0 0;">New {{ componentDisplayName }} </button>                      
            </div>
        </div>
        
        <div class="flex flex-between" style="padding: 0 10px;">
            <ag-grid-angular
            style="width: 100%; height: 540px;"
            class="ag-theme-alpine"
            [gridOptions]="gridOptions"
            (gridReady)="onGridReady($event)"
            [rowData]="rowData"
            [columnDefs]="columnDefs"
            [pagination]="true"
            [paginationPageSize]="paginationPageSize"         
            [defaultColDef]="defaultColDef"
            (gridReady)="onGridReady($event)"
            (firstDataRendered)="headerHeightSetter($event)"
            (columnResized)="headerHeightSetter($event)"
            [enableCellTextSelection]="true"
            [frameworkComponents]="frameworkComponents">
        </ag-grid-angular>
        </div>
    </div>
    </block-ui>
    