<mat-card-title>
    <div style="margin-bottom: 8px;">
        <h4 style="float: left;font-size: large;font-weight: 500;"> Create Additional Platform </h4>
        <div style="display:flex;
      flex-direction: row-reverse;">
            <div class="flex-center margin_auto_t_b">
                <a class="anchor-close" id="close" title="Close" (click)="onCancel()">
                    <span class="icon-close icon-size-24 icon-close"></span>
                </a>
            </div>
        </div>
    </div>
</mat-card-title>
<form [formGroup]="newPlatformForm" autocomplete="off">

    <div mat-dialog-content style="width: 50vw;height: 310px;">
        <div class="section">
            <div class="col-md-12 col-lg-12">
                <div class="row section">
                    <div class="col-md-3 col-lg-3 row" style="align-items: center;">
                        Display Name
                    </div>
                    <div class="col-md-6 col-lg-6">
                        <div class="form-group">
                            <div class="form-group__text">
                                <input matInput formControlName="platformName"
                                    (ngModelChange)="checkforDuplicateNames()"
                                    [ngClass]="newPlatformForm.get('platformName').errors || duplicatName? 'text-danger': 'text-normal'">
                            </div>
                            <span *ngIf="duplicatName" style=color:red>Duplicate Type</span>
                        </div>
                    </div>
                </div>
                <div class="row section">
                    <div class="col-md-3 col-lg-3 row" style="align-items: center;">
                        Platform Type
                    </div>
                    <div class="col-md-6 col-lg-6">
                        <div class="form-group form-group--inline">
                            <label class="radio">
                                <input type="radio" value="Compute" name="primaryClass" formControlName="primaryClass">
                                <span class="radio__input"></span>
                                <span class="radio__label">Compute</span>
                            </label>
                        </div>
                        <div class="form-group form-group--inline" disabled>
                            <label class="radio">
                                <input type="radio" value="Storage" name="primaryClass" formControlName="primaryClass">
                                <span class="radio__input"></span>
                                <span class="radio__label">Storage</span>
                            </label>
                        </div>
                        <div class="form-group form-group--inline">
                            <label class="radio">
                                <input type="radio" value="Networking" name="primaryClass" formControlName="primaryClass">
                                <span class="radio__input"></span>
                                <span class="radio__label">Network</span>
                            </label>
                        </div>
                    </div>
                </div>

                <div class="row section">
                    <div class="col-md-3 col-lg-3 row" style="align-items: center;">
                        New Series
                    </div>
                    <div class="col-md-6 col-lg-6">
                        <div class="form-group">
                            <div class="form-group__text">
                                <input matInput formControlName="newSeries"
                                    [ngClass]="newPlatformForm.get('newSeries').errors || !checkSeriesUnique()
                                    ? 'text-danger': 'text-normal'">
                                 <mat-error *ngIf="!checkSeriesUnique()" style=color:red>
                                    Platform name already exists!
                                 </mat-error>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row section">
                    <div class="col-md-3 col-lg-3 row" style="align-items: center;">
                        Fan Policy </div>
                    <div class="col-md-6 col-lg-6">
                        <div class="form-group form-group--inline">
                            <label class="radio">
                                <input type="radio" value="true" name="isFanPolicyReq" formControlName="isFanPolicyReq">
                                <span class="radio__input"></span>
                                <span class="radio__label">Yes</span>
                            </label>
                        </div>
                        <div class="form-group form-group--inline">
                            <label class="radio">
                                <input type="radio" value="false" name="isFanPolicyReq"
                                    formControlName="isFanPolicyReq">
                                <span class="radio__input"></span>
                                <span class="radio__label">No</span>
                            </label>
                        </div>
                    </div>
                </div>

                <div class="row section">
                    <div class="col-md-3 col-lg-3 row" style="align-items: center;">
                        Select Attribute
                    </div>
                    <div class="col-md-6 col-lg-6">
                        <div class="form-group form-group--inline">
                            <angular2-multiselect [data]="dropdownList" [ngModelOptions]="{standalone: true}"
                                [(ngModel)]="selectedItems" [settings]="dropdownSettings"
                                (onSelect)="onAttributeSelect($event)" (onDeSelect)="OnAttributeDeSelect($event)"
                                [selectedItems]="selectedItems" (onSelectAll)="onSelectAll($event)"
                                (onDeSelectAll)="onDeSelectAll($event)">
                                <c-item>
                                    <ng-template let-item="item">
                                        <label [ngClass]="staticList.includes(item.key)?'default-select':''" [attr.disabled]="staticList.includes(item.key)?true:null" style="color: #333;min-width: 150px;">{{item.name}}</label>
                                    </ng-template>
                                </c-item>
                            </angular2-multiselect>

                        </div>

                    </div>
                </div>
                <!-- isChild selector : tentative -->
                <!-- <div class="row section">
                    <div class="col-md-3 col-lg-3 row" style="align-items: center;">
                        Is Child </div>
                    <div class="col-md-6 col-lg-6">
                        <div class="form-group form-group--inline">
                            <label class="radio">
                                <input type="radio" value="true" name="isChild" formControlName="isChild">
                                <span class="radio__input"></span>
                                <span class="radio__label">Yes</span>
                            </label>
                        </div>
                        <div class="form-group form-group--inline">
                            <label class="radio">
                                <input type="radio" value="false" name="isChild"
                                    formControlName="isChild">
                                <span class="radio__input"></span>
                                <span class="radio__label">No</span>
                            </label>
                        </div>
                    </div>
                </div>  -->
            </div>
        </div>
    </div>
    <div mat-dialog-actions>
        <button mat-button type="submit" class="btn btn--small " style="height: 24px;margin: 5px 20px 0 0;"
            [disabled]="!this.newPlatformForm.valid || duplicatName || duplicatType"
            (click)="submitPlatform();">Save</button>

        <button mat-button type="button" class="btn btn--small " style="height: 24px;margin: 5px 20px 0 0;"
            (click)="onCancel()">Cancel</button>
    </div>
</form>