import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import {
	CngContentConfigType,
	CngModalService,
	CNG_DATA,
} from '@cisco/cui-ng';
import { Subscription } from 'rxjs';
import { SharedService } from '../../shared.service';
@Component({
  selector: 'app-add-manage-comp',
  templateUrl: './add-manage-comp.component.html',
  styleUrls: ['./add-manage-comp.component.css']
})
export class AddManageCompComponent implements OnInit {
  message:string;
  subscriptions: Subscription = new Subscription(); 
  confirmationMessage: string = "OK"
  newCompName: string = '';
  isAddEnabled: boolean = false;

  constructor(@Inject(CNG_DATA) public data: any,
  private modalService: CngModalService,
  private sharedService: SharedService) { }
 
  isModalShown = false;
  ngOnInit(): void {   
  }

  public closeModal () {
		this.subscriptions.add(this.modalService.close('closed'));
	}

	public openNewModal () {
	this.modalService.open({
			content: {
				type: CngContentConfigType.COMPONENT,
				content: AddManageCompComponent,
			},
		});
	}
  
  onAddComponent() { 
    
    if(this.data.key !== 'delete'){
      this.data.newCompName = this.newCompName;
      this.subscriptions.add(this.sharedService.sendMessage(this.data));
    }   
    
    this.subscriptions.add(this.modalService.close('ok'));
  }  
  
  onChangeNewCompName($event) {
    let newName = $event.target.value;
    
    if (newName.trim().length === 0) {
      this.isAddEnabled = false;
    } 
  }
  onKeyPress($event) {
    if (this.newCompName.trim().length == 0) this.isAddEnabled = false;
    else this.isAddEnabled = true;
  }

  ngOnDestroy(): void {
    if(this.subscriptions) {
      this.subscriptions.unsubscribe();
     }
  }
}
