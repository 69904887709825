import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToasterService } from './shared/components/toast/toast.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ProjectService } from './services/project.service';
import { Subscription } from 'rxjs';
import { DataServiceService } from "./data-service.service";
import { ApiService } from "./services/api.service";
import { NavigationEnd, NavigationStart, Router } from "@angular/router";
import { ApplicationStatusGuard } from "./application-status.guard";
import { filter } from 'rxjs/operators';
import { SharedService } from './shared/shared.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  toastFlag = false;
  @BlockUI() blockUI: NgBlockUI;
  subscription: Subscription = new Subscription();
  res: any;
  loading: boolean;
  hasRouteChanged: boolean = false;
  constructor(
    translate: TranslateService,
    private dataService: DataServiceService,
    private toast: ToasterService,
    private apiService: ApiService,
    private router: Router,
    private projectService: ProjectService,
    private appGuuard: ApplicationStatusGuard,
    private sharedService: SharedService,
  ) {
    let supportedLangs = ['en', 'fr', 'ko', 'ja', 'de', 'zh-CN'];
    translate.addLangs(supportedLangs)
    const currentLanguage = translate.getBrowserLang();
    //default language is used to specify fallback translations in case there are missing translations for current language.
    translate.setDefaultLang('en');
    // set browser language as current language
    if (supportedLangs.includes(currentLanguage)) {
      translate.use(currentLanguage);
    } else {
      translate.use('en');
    }
    //  this.checkForApplicattionHeath();
  }
  checkForApplicattionHeath() {
    alert('checking for application status');
    console.log('checking for application status');
    this.blockUI.start('loading');
    const url = 'test';

    this.apiService.getMethod(url).subscribe((response: Response) => {                           //next() callback

      this.res = response;
      // this.checkDbStatus();

      this.dataService.setAPPlicationStatus(true);
      this.blockUI.stop();
    },
      (error) => {
        console.error('Request failed with error ' + error);
        this.dataService.setAPPlicationStatus(false);
        this.blockUI.stop();
        this.loading = true;
        alert('loading true');
        this.router.navigate(['maintenance']);

      },
      () => {                                   //complete() callback
        console.error('Request completed');
        this.blockUI.stop();//This is actually not needed
      });
  }

  checkDbStatus() {
    const url = '/actuator/health';
    this.apiService.getMethodActuatorHealth(url).subscribe((response: Response) => {                           //next() callback

      this.res = response;

      this.dataService.setAPPlicationStatus(true);
      this.blockUI.stop();
      this.loading = true;

    },
      (error) => {                              //error() callback
        console.error('Request failed with error');
        this.dataService.setAPPlicationStatus(false);
        this.blockUI.stop();
        this.loading = true;
        this.router.navigate(['/maintenance']);
      },
      () => {                                   //complete() callback
        console.error('Request completed')      //This is actually not needed
      });
  }

  ngOnInit() {



    this.toast.subject.subscribe((result: any) => {
      if (result) {
        this.toastFlag = true;
        // this.toast.subject.next(null);
      }
      // setTimeout(() => {
      //   this.toastFlag = false;
      // },5000)
    })

  }
}
