<div>
  <label style="color:red;">{{lblMandatoryMsg}}</label>
  <label style="color:green;">{{lblSuccessMsg}}</label>
</div>
<mat-card-title  mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
  <div style="margin-bottom: 8px;"><h4 style="float: left;font-size: large;font-weight: 400;">  {{cardLabel}}  </h4> 
    <div style="display:flex;
    flex-direction: row-reverse;">
    <div class="flex-center margin_auto_t_b" >
      <a class="anchor-close" id="close" title="Close" (click)="onCancel()">
      <span class="icon-close icon-size-24 icon-close"></span>
      </a>
  </div>   

    <button mat-button class="btn btn--small " style="height: 24px;margin: 0px 20px 0 0;"
    (click)="openAdditionalAtt_Modal()">Additional Fields</button><span class="margin_auto_t_b" 
     style="font-size: 65%;margin-top: -2px;margin-right:5px;" 
    *ngIf="additionalFields.length>0">{{additionalFields.length}} Field(s) Added</span></div>
  </div>
 </mat-card-title>
<form  [formGroup]="validations" autocomplete="off">
  <div mat-dialog-content> 
      <mat-form-field>
        
        <div class="panel panel--compressed" style="max-width:800px; min-width:500px">
          <table class="table table-bordered">
            <tbody>
              <tr>
                  <td class="mandatory"> <mat-label>Display Name</mat-label>
                    <mat-error  *ngIf="validations.get('displayName').errors" style="color:red"></mat-error></td>
                  <td>
                    <div class="form-group" > 
                      <div class="form-group__text">
                        <input [ngClass]="validations.get('displayName').errors  ? 'text-danger': 'text-normal'"  matInput [(ngModel)]="expCardData.displayName" formControlName="displayName"  required  >           
                      </div>
                    </div>    
                  </td> 
              </tr>
              <tr>
                <td></td> <td></td>
              </tr>
               <!-- pid search -->
            <tr>
              <td class="mandatory"  style="vertical-align: top;padding-top: 15px;
              ">
                 <mat-label >PID</mat-label>
              </td>
              <td>
                <app-search-box *ngIf="showPID" [type]="'component'" [pid]="selectedPid" (pidSelectionEvent)="selectedPID($event)" ></app-search-box>
                </td>
              <td></td>
                          
            </tr>

              <tr>
                <td></td> <td></td>
              </tr> 
              <tr>
                <td class="mandatory"> <mat-label>Idle Power(W)</mat-label><mat-error  *ngIf="validations.get('idlePower').hasError('required')" style="color:red"></mat-error></td>
                <td>
                  <div class="form-group" > 
                    <div class="form-group__text">
                  <input matInput (ngModelChange)="validateWatts($event)"
                                  [ngClass]="validations.get('idlePower').hasError('pattern') ||
                                             validations.get('idlePower').hasError('required') || 
                                             validations.get('idlePower').hasError('min') ||
                                             showWattErr || 
                                             IdleWattsErr ? 'text-danger': 'text-normal'"  [(ngModel)]="expCardData.idlePower" formControlName="idlePower"  required >
                </div> 
                  </div>
                </td> 
              </tr> 
              <tr>
                <td></td>
                <td>
                  <mat-error *ngIf ="(validations.get('idlePower').hasError('pattern') || validations.get('idlePower').hasError('min')) && !IdleWattsErr" style = "color:red " >Only Positive Integers are allowed</mat-error>
                  <mat-error *ngIf ="showWattErr" style = color:red;>Idle Power should be less than or equal to Max Power </mat-error>
                  <mat-error *ngIf ="IdleWattsErr" style = color:red>Only Positive Integers are allowed </mat-error>

                </td>
              </tr>
              <tr>              
                <td class="mandatory"> <mat-label>Max Power(W)</mat-label><mat-error  *ngIf="validations.get('maxPower').hasError('required')" style="color:red"></mat-error></td>
                <td>
                  <div class="form-group" > 
                    <div class="form-group__text">
                  <input matInput (ngModelChange)="validateWatts($event)" 
                                  [ngClass]="validations.get('maxPower').hasError('pattern') ||
                                             validations.get('maxPower').hasError('required') || 
                                             validations.get('maxPower').hasError('min') ||
                                             showWattErr || 
                                             IdleMaxWattsErr ? 'text-danger': 'text-normal'"  [(ngModel)]="expCardData.maxPower" formControlName="maxPower"  required >  
                  
                    </div></div>
                </td>
                
              </tr>
              <tr>
                <td></td>
                <td>
                  <mat-error *ngIf ="(validations.get('maxPower').hasError('pattern') || validations.get('maxPower').hasError('min')) && !IdleMaxWattsErr" style = color:red>Only Positive Integers are allowed</mat-error>
                  <mat-error *ngIf ="showWattErr" style = color:red>Max Power should be greater than or equal to Idle Power</mat-error>
                  <mat-error *ngIf ="IdleMaxWattsErr" style = color:red>Only Positive Integers are allowed</mat-error>
                </td>
              </tr>
    
              <tr>
                <td class="mandatory"> <mat-label>MTBF Hours</mat-label></td>
                <td>
                  <div class="form-group" > 
                    <div class="form-group__text">
                  <input matInput (ngModelChange)="validateWatts($event)"  
                                  [ngClass]="validations.get('mtbf').hasError('pattern') ||
                                             mtbfErr ? 'text-danger': 'text-normal'" [(ngModel)]="expCardData.mtbf" formControlName="mtbf">
                    </div></div>
                </td> 
              </tr>      
              <tr>
                <td></td>
                <td>
                  <mat-error *ngIf="mtbfErr" style=color:red>Only Non-Negative Integers are allowed  </mat-error>
                </td>
              </tr>        
            </tbody>
          </table>
        </div>
      </mat-form-field>
    </div>
    <div mat-dialog-actions>
      <table class="table table-bordered">
        <tbody>
          <tr>
            <td>

              <mat-card-actions> 
                <button mat-button  (click)="onSave()"   class="btn btn--small " 
                style="height: 24px;margin: 5px 20px 0 0;"  
                [disabled]="!validations.valid || showWattErr || IdleWattsErr || IdleMaxWattsErr ||mtbfErr || !selectedPid">Save & Close</button>
              </mat-card-actions>
             <button mat-button (click)="onNoClick()" cdkFocusInitial  class="btn btn--small " 
              style="height: 24px;margin: 5px 20px 0 0;"
              [disabled]="!validations.valid || showWattErr || IdleWattsErr || IdleMaxWattsErr ||mtbfErr || !selectedPid">Save</button>
            </td>
          </tr>
        </tbody>
      </table>       
    </div>
</form> 
  