
<div class="container project-details-container" *ngIf="isProjectFetched">
    <block-ui>
    <div class="row">
        <div class="col-md-12 div-project-details">
            <app-project-details-summary></app-project-details-summary>
        </div>
    </div>
    <div class="row project-details-row">
    <div class="col-md-8 col-md-pull-4 col-sm-12 col-sm-pull-4">
       
        <router-outlet></router-outlet>
    </div>
    <div class="col-md-4 col-md-push-8  col-sm-12 col-sm-push-8">
        <app-project-results></app-project-results>
    </div>
</div>
    </block-ui>
</div>

<div class="container project-details-container" *ngIf="!isProjectFetched">
    <div class="loading-dots loading-dots--dark" style="margin-top:5rem" aria-label="Loading, please wait...">
        <span></span>
        <span></span>
        <span></span>
      </div>
</div>