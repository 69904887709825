<div mat-dialog-title style="margin: 0 0 0px;" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
<div class="flex flex-between" style="padding: 0 10px;padding: 0px 10px;">
    <button mat-button (click)="onBackClick()" style="margin: 0px -25px;" class="btn btn--link focus" >Back</button>
    <div class="flex-center margin_auto_t_b" >
        <a class="anchor-close" id="close" title="Close" (click)="onBackClick()">
        <span class="icon-close icon-size-24 icon-close"></span>
        </a>
      </div>
</div>
<div class="flex flex-between" style="padding: 0 10px;">
    
    <h4>Power Supply Curve Input</h4>        
    <button mat-raised-button (click)="openDialog()"  class="btn btn--small " style="height: 24px;margin: 5px 20px 0 0;" *ngIf="form=='curvePointdata'">New Curve Point</button>         
</div>
</div>
<div class="flex flex-between" style="padding: 0 10px;">
    <table>
        <tr>
            <td><label for="input-type-name">Power Supply Name  :  {{powerSupplyName}}</label> </td>
        </tr>
        <tr>
            <td><label for="input-type-name">PID  :  {{powerSupplyPid}}</label>  </td>
        </tr>
        <tr>
            <td> <label for="input-type-name">Capacity  :  {{powerSupplyCapacity}}</label>  </td>
        </tr>
    </table>
</div>    
<div class="edit-license" style="width:1000px" *ngIf="form=='curvePointdata'">      
    <div style="padding: 0 10px;">
        <ag-grid-angular
        id = "curvepoint"
        style="width: 100%; height: 450px;"
        class="ag-theme-alpine"
        [rowData]="rowData"
        [columnDefs]="columnDefs"
        [pagination]="true"
        [paginationPageSize]="paginationPageSize"         
        [defaultColDef]="defaultColDef"
        [frameworkComponents]="frameworkComponents" 
        [suppressRowTransform]="true"
        [isExternalFilterPresent]="isExternalFilterPresent"
        [doesExternalFilterPass]="doesExternalFilterPass"
        >
    </ag-grid-angular>
    
    </div>
    
</div>
<br>    
<div class="edit-license" style="width:100%" *ngIf="form=='newCurvePoint'">
    <form  [formGroup]="validations" autocomplete="off">
        <div mat-dialog-content> 
            <mat-form-field>              
              <div class="panel panel--compressed panel--raised" style="width:100%;">
                <table class="table table-bordered">
                  <tbody>
                    <tr>
                      <td class="mandatory" style="vertical-align: top;padding-top: 15px;">                        
                        <mat-label>Voltage</mat-label>
                        <mat-error  *ngIf="validations.get('voltage').hasError('required')" style="color:red">*</mat-error>
                      </td>
                      <td>
                        <div class="col-md-12">
                            <!-- <div class="form-group" > 
                                <div class="form-group__text select div-mode"> 
                          <mat-form-field appearance="fill">
                            <select matNativeControl [(ngModel)]="selectedVoltage" (change)="selectVoltage($event)"
                             formControlName="voltage" required style="width: 100%;" class="select-small mode"
                             [ngClass]=" validations.get('voltage').hasError('required') || selectedVoltage == 'select'? 'select-danger': 'text-normal' ">
                             <option  value="select">
                                Select Voltage                            
                              </option> 
                              <ng-container  *ngFor="let voltage of voltages">
                             <option [value]="voltage.value" [id]="voltage.viewValue">  
                                {{voltage.viewValue}}                            
                              </option>
                            </ng-container>
                            </select>
                          </mat-form-field></div></div>[value]="voltage.value"  -->
                          <div class="form-group storage"> 
                            <!-- <ngx-select-dropdown 
                                    [multiple]="false" [ngClass]=" validations.get('voltage').hasError('required') || selectedVoltage == 'select' ? 'ngx-select-danger ngx-dropdown-button': 'ngx-select-normal ngx-dropdown-button'" 
                                    [(ngModel)]="selectedVoltage" formControlName="voltage"
                                    [config]="config" [options]="voltages" (change)="selectVoltage($event)" >
                            </ngx-select-dropdown> -->

                            <angular2-multiselect [ngClass]=" validations.get('voltage').hasError('required') ? 'ng2-select-danger selected-list': 'ng2-select-normal selected-list'"
                                [data]="voltages" 
                                formControlName="voltage"
                                [(ngModel)]="selectedVoltage" 
                                [settings]="dropdownSettings" 
                                (onSelect)="selectVoltage($event)" 
                                (onDeSelect)="OnItemDeSelect($event)">
                                <c-item>
                                <ng-template let-item="item">
                                    <label style="color: #333;min-width: 150px;">{{item.viewValue}}</label>
                                </ng-template>
                                </c-item>
                            </angular2-multiselect>
                          </div> 
                        </div>
                         </td>                                      
                    </tr>
                    <tr>
                      <td colspan="6"> <mat-label>PSU Efficiency Curve</mat-label></td>
                      <td></td>
                    </tr>
                    <tr>
                      <td class="mandatory">
                         <mat-label>0% load</mat-label>  
                      </td>
                      <td>   
                         <div class="col-md-7">
                         
                            <div class="form-group">
                                
                                   <!-- [ngClass] =" (validations.get('a0PercentEff').hasError('min') || validations.get('a0PercentEff').hasError('max')) ? 'danger' : 'normal' " -->
                                   <div class="form-group__text">
                                    
                                    <input matInput [ngClass]="validations.get('a0PercentEff').hasError('pattern') || validations.get('a0PercentEff').hasError('required') || validations.get('a0PercentEff').hasError('min') || validations.get('a0PercentEff').hasError('max') ? 'text-danger': 'text-normal'" [(ngModel)]="powerSupplyData.a0PercentEff"  placeholder="0%" formControlName="a0PercentEff" required style="width: 75%;" >
                                    <mat-error *ngIf ="validations.get('a0PercentEff').hasError('min')" style = color:red>0.1 to 1.0 values only</mat-error>
                                    <mat-error *ngIf ="validations.get('a0PercentEff').hasError('max')" style = color:red>0.1 to 1.0 values only</mat-error>
                                </div>                                
                            </div>
                        </div>                       
                      </td>              
                      <td class="mandatory">
                        <mat-label>5% load</mat-label>  
                     </td>
                     <td>   
                        <div class="col-md-7">
                           <div class="form-group ">
                               <div class="form-group__text">
                                   <input matInput [ngClass]="validations.get('a5PercentEff').hasError('pattern') || validations.get('a5PercentEff').hasError('required') || validations.get('a5PercentEff').hasError('min') || validations.get('a5PercentEff').hasError('max') ? 'text-danger': 'text-normal'" [(ngModel)]="powerSupplyData.a5PercentEff" placeholder="5%" formControlName="a5PercentEff" required style="width: 75%;">
                                    <mat-error *ngIf ="validations.get('a5PercentEff').hasError('min')" style = color:red>0.1 to 1.0 values only</mat-error>
                                    <mat-error *ngIf ="validations.get('a5PercentEff').hasError('max')" style = color:red>0.1 to 1.0 values only</mat-error>
                               </div>                                
                           </div>
                       </div>                       
                     </td> 
                     <td class="mandatory">
                        <mat-label>10% load</mat-label>  
                     </td>
                     <td>   <!-- form-group--error"-->
                        <div class="col-md-7">
                           <div class="form-group" > 
                               <div class="form-group__text">
                                   <input matInput [ngClass]="validations.get('a10PercentEff').hasError('pattern') || validations.get('a10PercentEff').hasError('required') || validations.get('a10PercentEff').hasError('min') || validations.get('a10PercentEff').hasError('max') ? 'text-danger': 'text-normal'" [(ngModel)]="powerSupplyData.a10PercentEff" placeholder="10%" formControlName="a10PercentEff" required style="width: 75%;">
                                   <mat-error *ngIf ="validations.get('a10PercentEff').hasError('min')" style = color:red>0.1 to 1.0 values only</mat-error>
                                   <mat-error *ngIf ="validations.get('a10PercentEff').hasError('max')" style = color:red>0.1 to 1.0 values only</mat-error>
                               </div>                                
                           </div>
                       </div>                       
                     </td>   
                    </tr>
                    <tr>
                      <td class="mandatory">
                         <mat-label>15% load</mat-label>  
                      </td>
                      <td>   
                         <div class="col-md-7">
                            <div class="form-group ">
                                <div class="form-group__text">
                                    <input matInput [ngClass]="validations.get('a15PercentEff').hasError('pattern') || validations.get('a15PercentEff').hasError('required') || validations.get('a15PercentEff').hasError('min') || validations.get('a15PercentEff').hasError('max') ? 'text-danger': 'text-normal'" [(ngModel)]="powerSupplyData.a15PercentEff" placeholder="15%" formControlName="a15PercentEff" required style="width: 75%;">
                                    <mat-error *ngIf ="validations.get('a15PercentEff').hasError('min') || validations.get('a15PercentEff').hasError('max')" style = color:red>0.1 to 1.0 values only</mat-error>
                                </div>                                
                            </div>
                        </div>                       
                      </td>              
                      <td class="mandatory">
                        <mat-label>20% load</mat-label>  
                     </td>
                     <td>   
                        <div class="col-md-7">
                           <div class="form-group ">
                               <div class="form-group__text">
                                   <input matInput [ngClass]="validations.get('a20PercentEff').hasError('pattern') || validations.get('a20PercentEff').hasError('required') || validations.get('a20PercentEff').hasError('min') || validations.get('a20PercentEff').hasError('max') ? 'text-danger': 'text-normal'" [(ngModel)]="powerSupplyData.a20PercentEff" placeholder="20%" formControlName="a20PercentEff" required style="width: 75%;">
                                   <mat-error *ngIf ="validations.get('a20PercentEff').hasError('min')" style = color:red>0.1 to 1.0 values only</mat-error>
                                   <mat-error *ngIf ="validations.get('a20PercentEff').hasError('max')" style = color:red>0.1 to 1.0 values only</mat-error>
                               </div>                                
                           </div>
                       </div>                       
                     </td> 
                     <td class="mandatory">
                        <mat-label>25% load</mat-label>  
                     </td>
                     <td>   
                        <div class="col-md-7">
                           <div class="form-group ">
                               <div class="form-group__text">
                                   <input matInput [ngClass]="validations.get('a25PercentEff').hasError('pattern') || validations.get('a25PercentEff').hasError('required') || validations.get('a25PercentEff').hasError('min') || validations.get('a25PercentEff').hasError('max') ? 'text-danger': 'text-normal'" [(ngModel)]="powerSupplyData.a25PercentEff" placeholder="25%" formControlName="a25PercentEff" required style="width: 75%;">
                                   <mat-error *ngIf ="validations.get('a25PercentEff').hasError('min')" style = color:red>0.1 to 1.0 values only</mat-error>
                                   <mat-error *ngIf ="validations.get('a25PercentEff').hasError('max')" style = color:red>0.1 to 1.0 values only</mat-error>
                               </div>                                
                           </div>
                       </div>                       
                     </td>   
                    </tr> 
                    <tr>
                      <td class="mandatory">
                         <mat-label>30% load</mat-label>  
                      </td>
                      <td>   
                         <div class="col-md-7">
                            <div class="form-group ">
                                <div class="form-group__text">
                                    <input matInput [ngClass]="validations.get('a30PercentEff').hasError('pattern') || validations.get('a30PercentEff').hasError('required') || validations.get('a30PercentEff').hasError('min') || validations.get('a30PercentEff').hasError('max') ? 'text-danger': 'text-normal'" [(ngModel)]="powerSupplyData.a30PercentEff" placeholder="30%" formControlName="a30PercentEff" required style="width: 75%;">
                                    <mat-error *ngIf ="validations.get('a30PercentEff').hasError('min')" style = color:red>0.1 to 1.0 values only</mat-error>
                                    <mat-error *ngIf ="validations.get('a30PercentEff').hasError('max')" style = color:red>0.1 to 1.0 values only</mat-error>
                                </div>                                
                            </div>
                        </div>                       
                      </td>              
                      <td class="mandatory">
                        <mat-label>35% load</mat-label>  
                     </td>
                     <td>   
                        <div class="col-md-7">
                           <div class="form-group ">
                               <div class="form-group__text">
                                   <input matInput [ngClass]="validations.get('a35PercentEff').hasError('pattern') || validations.get('a35PercentEff').hasError('required') || validations.get('a35PercentEff').hasError('min') || validations.get('a35PercentEff').hasError('max') ? 'text-danger': 'text-normal'" [(ngModel)]="powerSupplyData.a35PercentEff" placeholder="35%" formControlName="a35PercentEff" required style="width: 75%;">
                                   <mat-error *ngIf ="validations.get('a35PercentEff').hasError('min')" style = color:red>0.1 to 1.0 values only</mat-error>
                                   <mat-error *ngIf ="validations.get('a35PercentEff').hasError('max')" style = color:red>0.1 to 1.0 values only</mat-error>
                               </div>                                
                           </div>
                       </div>                       
                     </td> 
                     <td class="mandatory">
                        <mat-label>40% load</mat-label>  
                     </td>
                     <td>   
                        <div class="col-md-7">
                           <div class="form-group ">
                               <div class="form-group__text">
                                   <input matInput [ngClass]="validations.get('a40PercentEff').hasError('pattern') || validations.get('a40PercentEff').hasError('required') || validations.get('a40PercentEff').hasError('min') || validations.get('a15PercentEff').hasError('max') ? 'text-danger': 'text-normal'"  [(ngModel)]="powerSupplyData.a40PercentEff" placeholder="40%" formControlName="a40PercentEff" required style="width: 75%;">
                                   <mat-error *ngIf ="validations.get('a40PercentEff').hasError('min')" style = color:red>0.1 to 1.0 values only</mat-error>
                                   <mat-error *ngIf ="validations.get('a40PercentEff').hasError('max')" style = color:red>0.1 to 1.0 values only</mat-error>
                               </div>                                
                           </div>
                       </div>                       
                     </td>   
                    </tr>
                    <tr>
                      <td class="mandatory">
                         <mat-label>45% load</mat-label>  
                      </td>
                      <td>   
                         <div class="col-md-7">
                            <div class="form-group ">
                                <div class="form-group__text">
                                    <input matInput [ngClass]="validations.get('a45PercentEff').hasError('pattern') || validations.get('a45PercentEff').hasError('required') || validations.get('a45PercentEff').hasError('min') || validations.get('a45PercentEff').hasError('max') ? 'text-danger': 'text-normal'" [(ngModel)]="powerSupplyData.a45PercentEff" placeholder="45%" formControlName="a45PercentEff" required style="width: 75%;">
                                    <mat-error *ngIf ="validations.get('a45PercentEff').hasError('min')" style = color:red>0.1 to 1.0 values only</mat-error>
                                    <mat-error *ngIf ="validations.get('a45PercentEff').hasError('max')" style = color:red>0.1 to 1.0 values only</mat-error>
                                </div>                                
                            </div>
                        </div>                       
                      </td>              
                      <td class="mandatory">
                        <mat-label>50% load</mat-label>  
                     </td>
                     <td>   
                        <div class="col-md-7">
                           <div class="form-group r">
                               <div class="form-group__text">
                                   <input matInput [ngClass]="validations.get('a50PercentEff').hasError('pattern') || validations.get('a50PercentEff').hasError('required') || validations.get('a50PercentEff').hasError('min') || validations.get('a50PercentEff').hasError('max') ? 'text-danger': 'text-normal'" [(ngModel)]="powerSupplyData.a50PercentEff" placeholder="50%" formControlName="a50PercentEff" required style="width: 75%;">
                                   <mat-error *ngIf ="validations.get('a50PercentEff').hasError('min')" style = color:red>0.1 to 1.0 values only</mat-error>
                                   <mat-error *ngIf ="validations.get('a50PercentEff').hasError('max')" style = color:red>0.1 to 1.0 values only</mat-error>
                               </div>                                
                           </div>
                       </div>                       
                     </td> 
                     <td class="mandatory">
                        <mat-label>55% load</mat-label>  
                     </td>
                     <td>   
                        <div class="col-md-7">
                           <div class="form-group ">
                               <div class="form-group__text">
                                   <input matInput  [ngClass]="validations.get('a55PercentEff').hasError('pattern') || validations.get('a55PercentEff').hasError('required') || validations.get('a55PercentEff').hasError('min') || validations.get('a55PercentEff').hasError('max') ? 'text-danger': 'text-normal'" [(ngModel)]="powerSupplyData.a55PercentEff" placeholder="55%" formControlName="a55PercentEff" required style="width: 75%;">
                                   <mat-error *ngIf ="validations.get('a55PercentEff').hasError('min')" style = color:red>0.1 to 1.0 values only</mat-error>
                                   <mat-error *ngIf ="validations.get('a55PercentEff').hasError('max')" style = color:red>0.1 to 1.0 values only</mat-error>
                               </div>                                
                           </div>
                       </div>                       
                     </td>   
                    </tr> 
                    <tr>
                      <td class="mandatory">
                         <mat-label>60% load</mat-label>  
                      </td>
                      <td>   
                         <div class="col-md-7">
                            <div class="form-group ">
                                <div class="form-group__text">
                                    <input matInput [ngClass]="validations.get('a60PercentEff').hasError('pattern') || validations.get('a60PercentEff').hasError('required') || validations.get('a60PercentEff').hasError('min') || validations.get('a60PercentEff').hasError('max') ? 'text-danger': 'text-normal'" [(ngModel)]="powerSupplyData.a60PercentEff" placeholder="60%" formControlName="a60PercentEff" required style="width: 75%;">
                                    <mat-error *ngIf ="validations.get('a60PercentEff').hasError('min')" style = color:red>0.1 to 1.0 values only</mat-error>
                                    <mat-error *ngIf ="validations.get('a60PercentEff').hasError('max')" style = color:red>0.1 to 1.0 values only</mat-error>
                                </div>                                
                            </div>
                        </div>                       
                      </td>              
                      <td class="mandatory">
                        <mat-label>65% load</mat-label>  
                     </td>
                     <td>   
                        <div class="col-md-7">
                           <div class="form-group ">
                               <div class="form-group__text">
                                   <input matInput [ngClass]="validations.get('a65PercentEff').hasError('pattern') || validations.get('a65PercentEff').hasError('required') || validations.get('a65PercentEff').hasError('min') || validations.get('a65PercentEff').hasError('max') ? 'text-danger': 'text-normal'" [(ngModel)]="powerSupplyData.a65PercentEff" placeholder="65%" formControlName="a65PercentEff" required style="width: 75%;">
                                   <mat-error *ngIf ="validations.get('a65PercentEff').hasError('min')" style = color:red>0.1 to 1.0 values only</mat-error>
                                   <mat-error *ngIf ="validations.get('a65PercentEff').hasError('max')" style = color:red>0.1 to 1.0 values only</mat-error>
                               </div>                                
                           </div>
                       </div>                       
                     </td> 
                     <td class="mandatory">
                        <mat-label>70% load</mat-label>  
                     </td>
                     <td>   
                        <div class="col-md-7">
                           <div class="form-group ">
                               <div class="form-group__text">
                                   <input matInput [ngClass]="validations.get('a70PercentEff').hasError('pattern') || validations.get('a70PercentEff').hasError('required') || validations.get('a70PercentEff').hasError('min') || validations.get('a70PercentEff').hasError('max') ? 'text-danger': 'text-normal'" [(ngModel)]="powerSupplyData.a70PercentEff" placeholder="70%" formControlName="a70PercentEff" required style="width: 75%;">
                                   <mat-error *ngIf ="validations.get('a70PercentEff').hasError('min')" style = color:red>0.1 to 1.0 values only</mat-error>
                                   <mat-error *ngIf ="validations.get('a70PercentEff').hasError('max')" style = color:red>0.1 to 1.0 values only</mat-error>
                               </div>                                
                           </div>
                       </div>                       
                     </td>   
                    </tr> 
                    <tr>
                      <td class="mandatory">
                         <mat-label>75% load</mat-label>  
                      </td>
                      <td>   
                         <div class="col-md-7">
                            <div class="form-group ">
                                <div class="form-group__text">
                                    <input matInput [ngClass]="validations.get('a75PercentEff').hasError('pattern') || validations.get('a75PercentEff').hasError('required') || validations.get('a75PercentEff').hasError('min') || validations.get('a75PercentEff').hasError('max') ? 'text-danger': 'text-normal'" [(ngModel)]="powerSupplyData.a75PercentEff" placeholder="75%" formControlName="a75PercentEff" required style="width: 75%;">
                                    <mat-error *ngIf ="validations.get('a75PercentEff').hasError('min')" style = color:red>0.1 to 1.0 values only</mat-error>
                                    <mat-error *ngIf ="validations.get('a75PercentEff').hasError('max')" style = color:red>0.1 to 1.0 values only</mat-error>
                                </div>                                
                            </div>
                        </div>                       
                      </td>              
                      <td class="mandatory">
                        <mat-label>80% load</mat-label>  
                     </td>
                     <td>   
                        <div class="col-md-7">
                           <div class="form-group ">
                               <div class="form-group__text">
                                   <input matInput [ngClass]="validations.get('a80PercentEff').hasError('pattern') || validations.get('a80PercentEff').hasError('required') || validations.get('a80PercentEff').hasError('min') || validations.get('a80PercentEff').hasError('max') ? 'text-danger': 'text-normal'" [(ngModel)]="powerSupplyData.a80PercentEff" placeholder="80%" formControlName="a80PercentEff" required style="width: 75%;">
                                   <mat-error *ngIf ="validations.get('a80PercentEff').hasError('min')" style = color:red>0.1 to 1.0 values only</mat-error>
                                   <mat-error *ngIf ="validations.get('a80PercentEff').hasError('max')" style = color:red>0.1 to 1.0 values only</mat-error>
                               </div>                                
                           </div>
                       </div>                       
                     </td> 
                     <td class="mandatory">
                        <mat-label>85% load</mat-label>  
                     </td>
                     <td>   
                        <div class="col-md-7">
                           <div class="form-group ">
                               <div class="form-group__text">
                                   <input matInput [ngClass]="validations.get('a85PercentEff').hasError('pattern') || validations.get('a85PercentEff').hasError('required') || validations.get('a85PercentEff').hasError('min') || validations.get('a85PercentEff').hasError('max') ? 'text-danger': 'text-normal'" [(ngModel)]="powerSupplyData.a85PercentEff" placeholder="85%" formControlName="a85PercentEff" required style="width: 75%;">
                                   <mat-error *ngIf ="validations.get('a85PercentEff').hasError('min')" style = color:red>0.1 to 1.0 values only</mat-error>
                                   <mat-error *ngIf ="validations.get('a85PercentEff').hasError('max')" style = color:red>0.1 to 1.0 values only</mat-error>
                               </div>                                
                           </div>
                       </div>                       
                     </td>   
                    </tr> 
                    <tr>
                      <td class="mandatory">
                         <mat-label>90% load</mat-label>  
                      </td>
                      <td>   
                         <div class="col-md-7">
                            <div class="form-group ">
                                <div class="form-group__text">
                                    <input matInput [ngClass]="validations.get('a90PercentEff').hasError('pattern') || validations.get('a90PercentEff').hasError('required') || validations.get('a90PercentEff').hasError('min') || validations.get('a90PercentEff').hasError('max') ? 'text-danger': 'text-normal'" [(ngModel)]="powerSupplyData.a90PercentEff" placeholder="90%" formControlName="a90PercentEff" required style="width: 75%;">
                                    <mat-error *ngIf ="validations.get('a90PercentEff').hasError('min')" style = color:red>0.1 to 1.0 values only</mat-error>
                                    <mat-error *ngIf ="validations.get('a90PercentEff').hasError('max')" style = color:red>0.1 to 1.0 values only</mat-error>
                                </div>                                
                            </div>
                        </div>                       
                      </td>              
                      <td class="mandatory">
                        <mat-label>95% load</mat-label>  
                     </td>
                     <td>   
                        <div class="col-md-7">
                           <div class="form-group ">
                               <div class="form-group__text">
                                   <input matInput [ngClass]="validations.get('a95PercentEff').hasError('pattern') || validations.get('a95PercentEff').hasError('required') || validations.get('a95PercentEff').hasError('min') || validations.get('a95PercentEff').hasError('max') ? 'text-danger': 'text-normal'" [(ngModel)]="powerSupplyData.a95PercentEff" placeholder="95%" formControlName="a95PercentEff" required style="width: 75%;">
                                   <mat-error *ngIf ="validations.get('a95PercentEff').hasError('min')" style = color:red>0.1 to 1.0 values only</mat-error>
                                   <mat-error *ngIf ="validations.get('a95PercentEff').hasError('max')" style = color:red>0.1 to 1.0 values only</mat-error>
                               </div>                                
                           </div>
                       </div>                       
                     </td> 
                     <td class="mandatory">
                        <mat-label>100% load</mat-label>  
                     </td>
                     <td>   
                        <div class="col-md-7">
                           <div class="form-group ">
                               <div class="form-group__text">
                                   <input [ngClass]="validations.get('a100PercentEff').hasError('pattern') || validations.get('a100PercentEff').hasError('required') || validations.get('a100PercentEff').hasError('min') || validations.get('a100PercentEff').hasError('max') ? 'text-danger': 'text-normal'" matInput [(ngModel)]="powerSupplyData.a100PercentEff" placeholder="100%" formControlName="a100PercentEff" required style="width: 75%;">
                                   <mat-error *ngIf ="validations.get('a100PercentEff').hasError('min')" style = color:red>0.1 to 1.0 values only</mat-error>
                                   <mat-error *ngIf ="validations.get('a100PercentEff').hasError('max')" style = color:red>0.1 to 1.0 values only</mat-error>
                               </div>                                
                           </div>
                       </div>                       
                     </td>   
                    </tr>  
                    
                    <tr>
                      <td colspan="6"> <mat-label>PSU Power Factor Curve</mat-label></td>
                      <td></td>
                    </tr>
                    <tr>
                      <td class="mandatory">
                         <mat-label>0% load</mat-label>  
                      </td>
                      <td>   
                         <div class="col-md-7">
                            <div class="form-group ">
                                <div class="form-group__text">
                                    <input matInput [ngClass]="validations.get('a0PercentPf').hasError('pattern') || validations.get('a0PercentPf').hasError('required') || validations.get('a0PercentPf').hasError('min') || validations.get('a0PercentPf').hasError('max') ? 'text-danger': 'text-normal'" [(ngModel)]="powerSupplyData.a0PercentPf" placeholder="0%" formControlName="a0PercentPf" required style="width: 75%;" appL>
                                    <mat-error *ngIf ="validations.get('a0PercentPf').hasError('min')" style = color:red>0.1 to 1.0 values only</mat-error>
                                    <mat-error *ngIf ="validations.get('a0PercentPf').hasError('max')" style = color:red>0.1 to 1.0 values only</mat-error>
                                </div>                                
                            </div>
                        </div>                       
                      </td>              
                      <td class="mandatory">
                        <mat-label>5% load</mat-label>  
                     </td>
                     <td>   
                        <div class="col-md-7">
                           <div class="form-group ">
                               <div class="form-group__text">
                                   <input matInput [ngClass]="validations.get('a5PercentPf').hasError('pattern') || validations.get('a5PercentPf').hasError('required') || validations.get('a5PercentPf').hasError('min') || validations.get('a5PercentPf').hasError('max') ? 'text-danger': 'text-normal'" [(ngModel)]="powerSupplyData.a5PercentPf" placeholder="5%" formControlName="a5PercentPf" required style="width: 75%;">
                                   <mat-error *ngIf ="validations.get('a5PercentPf').hasError('min')" style = color:red>0.1 to 1.0 values only</mat-error>
                                    <mat-error *ngIf ="validations.get('a5PercentPf').hasError('max')" style = color:red>0.1 to 1.0 values only</mat-error>
                               </div>                                
                           </div>
                       </div>                       
                     </td> 
                     <td class="mandatory">
                        <mat-label>10% load</mat-label>  
                     </td>
                     <td>   
                        <div class="col-md-7">
                           <div class="form-group ">
                               <div class="form-group__text">
                                   <input matInput [ngClass]="validations.get('a10PercentPf').hasError('pattern') || validations.get('a10PercentPf').hasError('required') || validations.get('a10PercentPf').hasError('min') || validations.get('a10PercentPf').hasError('max') ? 'text-danger': 'text-normal'" [(ngModel)]="powerSupplyData.a10PercentPf" placeholder="10%" formControlName="a10PercentPf" required style="width: 75%;">
                                   <mat-error *ngIf ="validations.get('a10PercentPf').hasError('min')" style = color:red>0.1 to 1.0 values only</mat-error>
                                    <mat-error *ngIf ="validations.get('a10PercentPf').hasError('max')" style = color:red>0.1 to 1.0 values only</mat-error>
                               </div>                                
                           </div>
                       </div>                       
                     </td>   
                    </tr>
                    <tr>
                      <td class="mandatory">
                         <mat-label>15% load</mat-label>  
                      </td>
                      <td>   
                         <div class="col-md-7">
                            <div class="form-group ">
                                <div class="form-group__text">
                                    <input matInput [ngClass]="validations.get('a15PercentPf').hasError('pattern') || validations.get('a15PercentPf').hasError('required') || validations.get('a15PercentPf').hasError('min') || validations.get('a15PercentPf').hasError('max') ? 'text-danger': 'text-normal'" [(ngModel)]="powerSupplyData.a15PercentPf" placeholder="15%" formControlName="a15PercentPf" required style="width: 75%;">
                                    <mat-error *ngIf ="validations.get('a15PercentPf').hasError('min')" style = color:red>0.1 to 1.0 values only</mat-error>
                                    <mat-error *ngIf ="validations.get('a15PercentPf').hasError('max')" style = color:red>0.1 to 1.0 values only</mat-error>
                                </div>                                
                            </div>
                        </div>                       
                      </td>              
                      <td class="mandatory">
                        <mat-label>20% load</mat-label>  
                     </td>
                     <td>   
                        <div class="col-md-7">
                           <div class="form-group ">
                               <div class="form-group__text">
                                   <input matInput [ngClass]="validations.get('a20PercentPf').hasError('pattern') || validations.get('a20PercentPf').hasError('required') || validations.get('a20PercentPf').hasError('min') || validations.get('a20PercentPf').hasError('max') ? 'text-danger': 'text-normal'" [(ngModel)]="powerSupplyData.a20PercentPf" placeholder="20%" formControlName="a20PercentPf" required style="width: 75%;">
                                   <mat-error *ngIf ="validations.get('a20PercentPf').hasError('min')" style = color:red>0.1 to 1.0 values only</mat-error>
                                    <mat-error *ngIf ="validations.get('a20PercentPf').hasError('max')" style = color:red>0.1 to 1.0 values only</mat-error>
                               </div>                                
                           </div>
                       </div>                       
                     </td> 
                     <td class="mandatory">
                        <mat-label>25% load</mat-label>  
                     </td>
                     <td>   
                        <div class="col-md-7">
                           <div class="form-group ">
                               <div class="form-group__text">
                                   <input matInput [ngClass]="validations.get('a25PercentPf').hasError('pattern') || validations.get('a25PercentPf').hasError('required') || validations.get('a25PercentPf').hasError('min') || validations.get('a25PercentPf').hasError('max') ? 'text-danger': 'text-normal'" [(ngModel)]="powerSupplyData.a25PercentPf" placeholder="25%" formControlName="a25PercentPf" required style="width: 75%;">
                                   <mat-error *ngIf ="validations.get('a25PercentPf').hasError('min')" style = color:red>0.1 to 1.0 values only</mat-error>
                                    <mat-error *ngIf ="validations.get('a25PercentPf').hasError('max')" style = color:red>0.1 to 1.0 values only</mat-error>
                               </div>                                
                           </div>
                       </div>                       
                     </td>   
                    </tr> 
                    <tr>
                      <td class="mandatory">
                         <mat-label>30% load</mat-label>  
                      </td>
                      <td>   
                         <div class="col-md-7">
                            <div class="form-group ">
                                <div class="form-group__text">
                                    <input matInput [ngClass]="validations.get('a30PercentPf').hasError('pattern') || validations.get('a30PercentPf').hasError('required') || validations.get('a30PercentPf').hasError('min') || validations.get('a30PercentPf').hasError('max') ? 'text-danger': 'text-normal'" [(ngModel)]="powerSupplyData.a30PercentPf" placeholder="30%" formControlName="a30PercentPf" required style="width: 75%;">
                                    <mat-error *ngIf ="validations.get('a30PercentPf').hasError('min')" style = color:red>0.1 to 1.0 values only</mat-error>
                                    <mat-error *ngIf ="validations.get('a30PercentPf').hasError('max')" style = color:red>0.1 to 1.0 values only</mat-error>
                                </div>                                
                            </div>
                        </div>                       
                      </td>              
                      <td class="mandatory">
                        <mat-label>35% load</mat-label>  
                     </td>
                     <td>   
                        <div class="col-md-7">
                           <div class="form-group ">
                               <div class="form-group__text">
                                   <input matInput [ngClass]="validations.get('a35PercentPf').hasError('pattern') || validations.get('a35PercentPf').hasError('required') || validations.get('a35PercentPf').hasError('min') || validations.get('a35PercentPf').hasError('max') ? 'text-danger': 'text-normal'" [(ngModel)]="powerSupplyData.a35PercentPf" placeholder="35%" formControlName="a35PercentPf" required style="width: 75%;">
                                   <mat-error *ngIf ="validations.get('a35PercentPf').hasError('min')" style = color:red>0.1 to 1.0 values only</mat-error>
                                    <mat-error *ngIf ="validations.get('a35PercentPf').hasError('max')" style = color:red>0.1 to 1.0 values only</mat-error>
                               </div>                                
                           </div>
                       </div>                       
                     </td> 
                     <td class="mandatory">
                        <mat-label>40% load</mat-label>  
                     </td>
                     <td>   
                        <div class="col-md-7">
                           <div class="form-group ">
                               <div class="form-group__text">
                                   <input matInput [ngClass]="validations.get('a40PercentPf').hasError('pattern') || validations.get('a40PercentPf').hasError('required') || validations.get('a40PercentPf').hasError('min') || validations.get('a40PercentPf').hasError('max') ? 'text-danger': 'text-normal'" [(ngModel)]="powerSupplyData.a40PercentPf" placeholder="40%" formControlName="a40PercentPf" required style="width: 75%;">
                                   <mat-error *ngIf ="validations.get('a40PercentPf').hasError('min')" style = color:red>0.1 to 1.0 values only</mat-error>
                                    <mat-error *ngIf ="validations.get('a40PercentPf').hasError('max')" style = color:red>0.1 to 1.0 values only</mat-error>
                               </div>                                
                           </div>
                       </div>                       
                     </td>   
                    </tr>
                    <tr>
                      <td class="mandatory">
                         <mat-label>45% load</mat-label>  
                      </td>
                      <td>   
                         <div class="col-md-7">
                            <div class="form-group ">
                                <div class="form-group__text">
                                    <input matInput [ngClass]="validations.get('a45PercentPf').hasError('pattern') || validations.get('a45PercentPf').hasError('required') || validations.get('a45PercentPf').hasError('min') || validations.get('a45PercentPf').hasError('max') ? 'text-danger': 'text-normal'" [(ngModel)]="powerSupplyData.a45PercentPf" placeholder="45%" formControlName="a45PercentPf" required style="width: 75%;">
                                    <mat-error *ngIf ="validations.get('a45PercentPf').hasError('min')" style = color:red>0.1 to 1.0 values only</mat-error>
                                    <mat-error *ngIf ="validations.get('a45PercentPf').hasError('max')" style = color:red>0.1 to 1.0 values only</mat-error>
                                </div>                                
                            </div>
                        </div>                       
                      </td>              
                      <td class="mandatory">
                        <mat-label>50% load</mat-label>  
                     </td>
                     <td>   
                        <div class="col-md-7">
                           <div class="form-group ">
                               <div class="form-group__text">
                                   <input matInput [ngClass]="validations.get('a50PercentPf').hasError('pattern') || validations.get('a50PercentPf').hasError('required') || validations.get('a50PercentPf').hasError('min') || validations.get('a50PercentPf').hasError('max') ? 'text-danger': 'text-normal'" [(ngModel)]="powerSupplyData.a50PercentPf" placeholder="50%" formControlName="a50PercentPf" required style="width: 75%;">
                                   <mat-error *ngIf ="validations.get('a50PercentPf').hasError('min')" style = color:red>0.1 to 1.0 values only</mat-error>
                                    <mat-error *ngIf ="validations.get('a50PercentPf').hasError('max')" style = color:red>0.1 to 1.0 values only</mat-error>
                               </div>                                
                           </div>
                       </div>                       
                     </td> 
                     <td class="mandatory">
                        <mat-label>55% load</mat-label>  
                     </td>
                     <td>   
                        <div class="col-md-7">
                           <div class="form-group ">
                               <div class="form-group__text">
                                   <input matInput [ngClass]="validations.get('a55PercentPf').hasError('pattern') || validations.get('a55PercentPf').hasError('required') || validations.get('a55PercentPf').hasError('min') || validations.get('a55PercentPf').hasError('max') ? 'text-danger': 'text-normal'" [(ngModel)]="powerSupplyData.a55PercentPf" placeholder="55%" formControlName="a55PercentPf" required style="width: 75%;">
                                   <mat-error *ngIf ="validations.get('a55PercentPf').hasError('min')" style = color:red>0.1 to 1.0 values only</mat-error>
                                    <mat-error *ngIf ="validations.get('a55PercentPf').hasError('max')" style = color:red>0.1 to 1.0 values only</mat-error>
                               </div>                                
                           </div>
                       </div>                       
                     </td>   
                    </tr> 
                    <tr>
                      <td class="mandatory">
                         <mat-label>60% load</mat-label>  
                      </td>
                      <td>   
                         <div class="col-md-7">
                            <div class="form-group ">
                                <div class="form-group__text">
                                    <input matInput [ngClass]="validations.get('a60PercentPf').hasError('pattern') || validations.get('a60PercentPf').hasError('required') || validations.get('a60PercentPf').hasError('min') || validations.get('a60PercentPf').hasError('max') ? 'text-danger': 'text-normal'" [(ngModel)]="powerSupplyData.a60PercentPf" placeholder="60%" formControlName="a60PercentPf" required style="width: 75%;">
                                    <mat-error *ngIf ="validations.get('a60PercentPf').hasError('min')" style = color:red>0.1 to 1.0 values only</mat-error>
                                    <mat-error *ngIf ="validations.get('a60PercentPf').hasError('max')" style = color:red>0.1 to 1.0 values only</mat-error>
                                </div>                                
                            </div>
                        </div>                       
                      </td>              
                      <td class="mandatory">
                        <mat-label>65% load</mat-label>  
                     </td>
                     <td>   
                        <div class="col-md-7">
                           <div class="form-group ">
                               <div class="form-group__text">
                                   <input matInput [ngClass]="validations.get('a65PercentPf').hasError('pattern') || validations.get('a65PercentPf').hasError('required') || validations.get('a65PercentPf').hasError('min') || validations.get('a65PercentPf').hasError('max') ? 'text-danger': 'text-normal'" [(ngModel)]="powerSupplyData.a65PercentPf" placeholder="65%" formControlName="a65PercentPf" required style="width: 75%;">
                                   <mat-error *ngIf ="validations.get('a65PercentPf').hasError('min')" style = color:red>0.1 to 1.0 values only</mat-error>
                                    <mat-error *ngIf ="validations.get('a65PercentPf').hasError('max')" style = color:red>0.1 to 1.0 values only</mat-error>
                               </div>                                
                           </div>
                       </div>                       
                     </td> 
                     <td class="mandatory">
                        <mat-label>70% load</mat-label>  
                     </td>
                     <td>   
                        <div class="col-md-7">
                           <div class="form-group ">
                               <div class="form-group__text">
                                   <input matInput [ngClass]="validations.get('a70PercentPf').hasError('pattern') || validations.get('a70PercentPf').hasError('required') || validations.get('a70PercentPf').hasError('min') || validations.get('a70PercentPf').hasError('max') ? 'text-danger': 'text-normal'" [(ngModel)]="powerSupplyData.a70PercentPf" placeholder="70%" formControlName="a70PercentPf" required style="width: 75%;">
                                   <mat-error *ngIf ="validations.get('a70PercentPf').hasError('min')" style = color:red>0.1 to 1.0 values only</mat-error>
                                    <mat-error *ngIf ="validations.get('a70PercentPf').hasError('max')" style = color:red>0.1 to 1.0 values only</mat-error>
                               </div>                                
                           </div>
                       </div>                       
                     </td>   
                    </tr> 
                    <tr>
                      <td class="mandatory">
                         <mat-label>75% load</mat-label>  
                      </td>
                      <td>   
                         <div class="col-md-7">
                            <div class="form-group ">
                                <div class="form-group__text">
                                    <input matInput [ngClass]="validations.get('a75PercentPf').hasError('pattern') || validations.get('a75PercentPf').hasError('required') || validations.get('a75PercentPf').hasError('min') || validations.get('a75PercentPf').hasError('max') ? 'text-danger': 'text-normal'" [(ngModel)]="powerSupplyData.a75PercentPf" placeholder="75%" formControlName="a75PercentPf" required style="width: 75%;">
                                    <mat-error *ngIf ="validations.get('a75PercentPf').hasError('min')" style = color:red>0.1 to 1.0 values only</mat-error>
                                    <mat-error *ngIf ="validations.get('a75PercentPf').hasError('max')" style = color:red>0.1 to 1.0 values only</mat-error>
                                </div>                                
                            </div>
                        </div>                       
                      </td>              
                      <td class="mandatory">
                        <mat-label>80% load</mat-label>  
                     </td>
                     <td>   
                        <div class="col-md-7">
                           <div class="form-group ">
                               <div class="form-group__text">
                                   <input matInput [ngClass]="validations.get('a80PercentPf').hasError('pattern') || validations.get('a80PercentPf').hasError('required') || validations.get('a80PercentPf').hasError('min') || validations.get('a80PercentPf').hasError('max') ? 'text-danger': 'text-normal'" [(ngModel)]="powerSupplyData.a80PercentPf" placeholder="80%" formControlName="a80PercentPf" required style="width: 75%;">
                                   <mat-error *ngIf ="validations.get('a80PercentPf').hasError('min')" style = color:red>0.1 to 1.0 values only</mat-error>
                                    <mat-error *ngIf ="validations.get('a80PercentPf').hasError('max')" style = color:red>0.1 to 1.0 values only</mat-error>
                               </div>                                
                           </div>
                       </div>                       
                     </td> 
                     <td class="mandatory">
                        <mat-label>85% load</mat-label>  
                     </td>
                     <td>   
                        <div class="col-md-7">
                           <div class="form-group ">
                               <div class="form-group__text">
                                   <input matInput [ngClass]="validations.get('a85PercentPf').hasError('pattern') || validations.get('a85PercentPf').hasError('required') || validations.get('a85PercentPf').hasError('min') || validations.get('a85PercentPf').hasError('max') ? 'text-danger': 'text-normal'" [(ngModel)]="powerSupplyData.a85PercentPf" placeholder="85%" formControlName="a85PercentPf" required style="width: 75%;">
                                   <mat-error *ngIf ="validations.get('a85PercentPf').hasError('min')" style = color:red>0.1 to 1.0 values only</mat-error>
                                    <mat-error *ngIf ="validations.get('a85PercentPf').hasError('max')" style = color:red>0.1 to 1.0 values only</mat-error>
                               </div>                                
                           </div>
                       </div>                       
                     </td>   
                    </tr> 
                    <tr>
                      <td class="mandatory">
                         <mat-label>90% load</mat-label>  
                      </td>
                      <td>   
                         <div class="col-md-7">
                            <div class="form-group ">
                                <div class="form-group__text">
                                    <input matInput [ngClass]="validations.get('a90PercentPf').hasError('pattern') || validations.get('a90PercentPf').hasError('required') || validations.get('a90PercentPf').hasError('min') || validations.get('a90PercentPf').hasError('max') ? 'text-danger': 'text-normal'" [(ngModel)]="powerSupplyData.a90PercentPf" placeholder="90%" formControlName="a90PercentPf" required style="width: 75%;">
                                    <mat-error *ngIf ="validations.get('a90PercentPf').hasError('min')" style = color:red>0.1 to 1.0 values only</mat-error>
                                    <mat-error *ngIf ="validations.get('a90PercentPf').hasError('max')" style = color:red>0.1 to 1.0 values only</mat-error>
                                </div>                                
                            </div>
                        </div>                       
                      </td>              
                      <td class="mandatory">
                        <mat-label>95% load</mat-label>  
                     </td>
                     <td>   
                        <div class="col-md-7">
                           <div class="form-group">
                               <div class="form-group__text">
                                   <input matInput  [ngClass]="validations.get('a95PercentPf').hasError('pattern') || validations.get('a95PercentPf').hasError('required') || validations.get('a95PercentPf').hasError('min') || validations.get('a95PercentPf').hasError('max') ? 'text-danger': 'text-normal'"[(ngModel)]="powerSupplyData.a95PercentPf" placeholder="95%" formControlName="a95PercentPf" required style="width: 75%;">
                                   <mat-error *ngIf ="validations.get('a95PercentPf').hasError('min')" style = color:red>0.1 to 1.0 values only</mat-error>
                                    <mat-error *ngIf ="validations.get('a95PercentPf').hasError('max')" style = color:red>0.1 to 1.0 values only</mat-error>
                               </div>                                
                           </div>
                       </div>                       
                     </td> 
                     <td class="mandatory">
                        <mat-label>100% load</mat-label>  
                     </td>
                     <td>   
                        <div class="col-md-7">
                           <div class="form-group ">
                               <div class="form-group__text">
                                   <input matInput [ngClass]="validations.get('a100PercentPf').hasError('pattern') || validations.get('a100PercentPf').hasError('required') || validations.get('a100PercentPf').hasError('min') || validations.get('a100PercentPf').hasError('max') ? 'text-danger': 'text-normal'" [(ngModel)]="powerSupplyData.a100PercentPf" placeholder="100%" formControlName="a100PercentPf" required style="width: 75%;">
                                   <mat-error *ngIf ="validations.get('a100PercentPf').hasError('min')" style = color:red>0.1 to 1.0 values only</mat-error>
                                    <mat-error *ngIf ="validations.get('a100PercentPf').hasError('max')" style = color:red>0.1 to 1.0 values only</mat-error>
                               </div>                                
                           </div>
                       </div>                       
                     </td>   
                    </tr> 
                  </tbody>
                </table>
              </div>
            </mat-form-field>
          </div>
          
          <div mat-dialog-actions>
            <table class="table table-bordered">
              <tbody>
                <tr>
                  <td>
                    <mat-card-actions> 
                      <button mat-button  (click)="onSave()"   class="btn btn--small " 
                      style="height: 24px;margin: 5px 20px 0 0;"  [disabled]="!validations.valid || submitInProgress">Submit</button>
                    </mat-card-actions>           
                    
                    <button mat-button (click)="onNoClick()"  class="btn btn--small " style="height: 24px;margin: 5px 20px 0 0;">Cancel</button>
                  </td>
                </tr>
              </tbody>
            </table>   
            
            
          </div>
</form>    </div>
