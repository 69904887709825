import { Component, OnInit, Inject, HostListener, ChangeDetectorRef } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormControl, UntypedFormBuilder, UntypedFormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { Subscription } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ComponentConstant } from 'src/constants/components.constants';
import { ApiService } from 'src/app/services/api.service';
import { UrlConstant } from 'src/constants/url.constants';
import { ToasterService } from "src/app/shared/components/toast/toast.service";
import { errorCodes } from 'src/constants/errorCodes';
import { CngContentConfigType, CngModalService, CngModalSize } from '@cisco/cui-ng';
import { ConfirmationBoxComponent } from 'src/app/shared/components/confirmation-box/confirmation-box.component';
import { TranslateService } from '@ngx-translate/core';
import { AdminService } from 'src/app/services/admin.service';
import { PlatformConstant } from 'src/constants/platform.constants';
import { AdditionalAttributesComponent } from 'src/app/shared/components/additional-attributes/additional-attributes.component';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { AppConstant } from 'src/constants/app.constants';

export interface DialogData {

  displayName: string;
  internalName: string;
  pid: string
  capacity: string;
  mtbf: string;
  type: string;
  id: string;
  update: boolean;
  released: string;
}

@Component({
  selector: 'app-create-powersupply',
  templateUrl: './create-powersupply.component.html',
  styleUrls: ['./create-powersupply.component.css']
})
export class CreatePowersupplyComponent implements OnInit {
  subscriptions: Subscription = new Subscription();

  constructor(public dialogRef: MatDialogRef<CreatePowersupplyComponent>,
    @Inject(MAT_DIALOG_DATA) public powerSupplyData: DialogData,
    private apiService: ApiService,
    private toaster: ToasterService,
    private formBuilder: UntypedFormBuilder,
    private modalService: CngModalService,
    private translate: TranslateService,
    private adminService: AdminService,
    public dialog: MatDialog,
    private cdr: ChangeDetectorRef) {
    this.validations = this.formBuilder.group({
      displayName: new UntypedFormControl('', [
        Validators.required, Validators.pattern(AppConstant.VALID_INPUT_REGEX)]),
      internalName: new UntypedFormControl('', [
        Validators.required, Validators.pattern(AppConstant.VALID_INPUT_REGEX)]),
      capacity: new UntypedFormControl('', [
        Validators.required,
        Validators.pattern('\\d+'),
        Validators.min(1),
        Validators.pattern(AppConstant.VALID_INPUT_REGEX)
      ]),
      mtbf: new UntypedFormControl('', [
      ])
    })
  }
  @BlockUI() blockUI: NgBlockUI;

  validations: UntypedFormGroup;
  lblMandatoryMsg = "";
  lblSuccessMsg = "";
  dialogConfig;
  errorMsg = '';
  update: boolean = false;
  type: string;
  released;

  @HostListener('window:keyup.esc') onKeyUp() {
    let cn = confirm('you want to close ?')
    if (cn) {
      this.dialogRef.close();
    }
  }
  saveCloseFlag: boolean = true;
  onNoClick(): void {
    this.type = this.powerSupplyData.type;
    this.saveCloseFlag = false;
    this.dataToSave();
  }
  refreshData: boolean = false;
  public onCancel = () => {

    this.dialogRef.close({
      data: {
        action: this.refreshData ? PlatformConstant.ACTION_REFRESH : PlatformConstant.ACTION_CANCEL
      }
    });
  }

  showPID = true;
  initialDisplayName = null;
  initialPID = null;
  ngOnInit(): void {
    this.showPID = true;

    this.type = this.powerSupplyData.type;
    if (this.powerSupplyData.update) {
      //for pid search
      this.selectedPid = this.powerSupplyData.pid;
      //addional fields
      this.additionalFields = this.powerSupplyData["additionalFields"] ? this.powerSupplyData["additionalFields"] : [];

      this.initialDisplayName = this.powerSupplyData.displayName;
      this.initialPID = this.powerSupplyData.pid;

    }
    this.dialogRef.disableClose = true;
    this.cdr.detectChanges();

  }
  dataToSave() {
    if (this.validations.valid) {

      let data = {
        "type": this.type,
        "role": "Admin",
        "displayName": this.validations.value.displayName,
        "pid": this.selectedPid,

        "internalName": this.validations.value.internalName,
        "capacity": this.validations.value.capacity,
        "mtbf": this.validations.value.mtbf,
        "released": "",
        "additionalFields": this.setAdditionalFields(),
        "allowDuplicates": false,
      };
      if (!this.powerSupplyData.update) {
        let result = this.createPowerSupplyData(data);
      } else {

        data.released = this.powerSupplyData.released;
        data["id"] = this.powerSupplyData.id;
        /*let powerSupplyInputs = {
          "a0percentEff": 0 ,
        }
        data["powerSupplyInputs"] = powerSupplyInputs; */
        let result = this.updatePowerSupplyData(data);
      }

    } else {

    }

  }
  setAdditionalFields() {
    let array = [];
    if (this.additionalFields?.length > 0) {
      this.additionalFields.forEach(element => {
        let row = {
          "name": element.name,
          "type": element.type,
          "value": element.value
        }
        array.push(row);
      });
    }
    else
      array = [];

    return array;
  }
  resetForm() {
    this.showPID = false;

    this.validations.reset();
    this.saveCloseFlag = true;
    this.additionalFields = [];

    //search pid
    this.selectedPid = null;
    setTimeout(() => {
      this.showPID = true;
    }, 100);
  }
  onSave() {
    this.dataToSave();
  }

  createPowerSupplyData(reqData: any) {
    this.blockUI.start('Loading...');
    let params = [];
    params.push(reqData);
    params = params.concat(this.applyToRecords);
    params = params.concat(this.updatedRecords);
    const url = UrlConstant.MANAGECOMPONENTS;
    this.subscriptions.add(
      this.apiService.postMethod(url, params).subscribe((data: any) => {
        this.blockUI.stop();
        this.refreshData = true;

        this.lblMandatoryMsg = "";
        if (data.successMessage?.includes("Successfully Inserted") || data.successMessage?.includes("Successfully updated")) {
          this.toaster.show('success', errorCodes.NEW_RECORD, '');
          if (this.saveCloseFlag) {
            window.scroll(0, 0);
            this.dialogRef.close({
              data: {
                action: PlatformConstant.ACTION_REFRESH
              }
            });
          } else {
            this.resetForm();
          }

        } else if (data.modifiedCount == 0 && data.error?.errorString == "Duplicate Data Found") {
          reqData["allowDuplicates"] = true;
          this.blockUI.start("Loading...");
          this.subscriptions.add(
            this.apiService.postMethod(url, params).subscribe((data: any) => {
              this.blockUI.stop();
              if (data.successMessage?.includes("Successfully Inserted") || data.successMessage?.includes("Successfully updated")) {
                this.toaster.show('success', errorCodes.NEW_RECORD, '');
                if (this.saveCloseFlag) {
                  window.scroll(0, 0);
                  this.dialogRef.close({
                    data: {
                      action: PlatformConstant.ACTION_REFRESH
                    }
                  });
                  this.openModal(reqData);
                } else {
                  this.resetForm();
                }


              }
            }, (error) => {
              this.handleError(error);
            }));
        }
      }, error => {
        this.handleError(error);
      })
    );
  }

  updatePowerSupplyData(reqData: any) {
    this.blockUI.start('Loading...');
    let params = [];
    params.push(reqData);
    params = params.concat(this.applyToRecords);
    params = params.concat(this.updatedRecords);

    const url = UrlConstant.MANAGECOMPONENTS + UrlConstant.UPDATE;
    this.subscriptions.add(
      this.apiService.postMethod(url, params).subscribe((data: any) => {
        this.blockUI.stop();
        this.refreshData = true;

        this.lblMandatoryMsg = "";

        if (data.successMessage == "Successfully updated") {

          this.toaster.show('success', errorCodes.UPDATE_RECORD, '');
          if (this.saveCloseFlag) {
            window.scroll(0, 0);
            this.dialogRef.close({
              data: {
                action: PlatformConstant.ACTION_REFRESH
              }
            });
          } else {
            this.saveCloseFlag = true;
          }

        } else if (data.modifiedCount == 0 && data.error.errorString == "Duplicate Data Found") {

          reqData["allowDuplicates"] = true;
          this.blockUI.start("Loading...");
          this.subscriptions.add(
            this.apiService.postMethod(url, params).subscribe((data: any) => {

              this.blockUI.stop();
              if (data.successMessage == "Successfully updated") {
                this.toaster.show('success', errorCodes.UPDATE_RECORD, '');
                if (this.saveCloseFlag) {
                  window.scroll(0, 0);
                  this.dialogRef.close({
                    data: {
                      action: PlatformConstant.ACTION_REFRESH
                    }
                  });
                  this.openModal(reqData);
                } else {
                  this.saveCloseFlag = true;
                }
              }
            }, (error) => {
              this.handleError(error);
            }));
        }
      }, error => {
        this.handleError(error);
      })
    );

  }
  handleError(error) {
    console.error(error);
    this.errorMsg = error?.error ? error.error?.error?.errorString : errorCodes.GENERIC_ERROR_MESSAGE;
    this.toaster.show('error', this.errorMsg, error ? error?.message : '');
    this.blockUI.stop();
  }
  ngOnDestroy() {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }
  showWattErr: boolean = false;
  IdleMaxWattsErr: boolean = false;
  IdleWattsErr: boolean = false;
  mtbfErr: boolean = false;
  capacityErr: boolean = false;
  validateWatts(e: any) {
    this.showWattErr = false;
    this.IdleMaxWattsErr = false;
    this.IdleWattsErr = false;
    this.mtbfErr = false;
    this.capacityErr = false;
    if (this.validations.value.idleWatts && this.validations.value.maxWatts
      && !isNaN(this.validations.value.idleWatts) && !isNaN(this.validations.value.maxWatts)) {
      this.showWattErr = Number(this.validations.value.maxWatts) > Number(this.validations.value.idleWatts)
        ? false : true;



      this.cdr.detectChanges();

    }
    if (this.validations.value.idleWatts && (isNaN(this.validations.value.idleWatts) || parseFloat(this.validations.value.idleWatts) < 0)) {

      //this.lblErrMessage = "Idle watts should be a Positive integer";
      this.IdleWattsErr = true;
      this.cdr.detectChanges();
    }
    if (this.validations.value.maxWatts && (isNaN(this.validations.value.maxWatts) || parseFloat(this.validations.value.maxWatts) < 0)) {

      //this.lblErrMessage = "Idle watts should be a Positive integer";
      this.IdleMaxWattsErr = true;
      this.cdr.detectChanges();
    }
    if (this.validations.value.mtbf && (isNaN(this.validations.value.mtbf) || Number(this.validations.value.mtbf) < 0)) {

      //this.lblErrMessage = "Idle watts should be a Positive integer";
      this.mtbfErr = true;
      this.cdr.detectChanges();
    }
    if (this.validations.value.capacity && (isNaN(this.validations.value.capacity))) {

      this.capacityErr = true;
      this.cdr.detectChanges();
    }
  }

  public async openModal(data: any) {
    const result = await this.modalService
      .open({
        content: {
          type: CngContentConfigType.COMPONENT,
          content: ConfirmationBoxComponent,
          componentData: {
            someText: this.translate.instant(
              "DUPLICATE_DATA_FOUND"
            ),
            key: "delete",
          },
        },
        size: CngModalSize.SMALL,
      })
      .onDismiss.toPromise();

    if (result == 'ok')
      this.getDuplicateData(data);
  }
  getDuplicateData(data: any) {
    this.subscriptions.add(this.adminService.setPowerSupplyData(data));
  }
  //addional fields
  additionalFields: any = [];
  applyToRecords: any = [];
  updatedRecords: any = [];
  isAdditionFieldsEdited: boolean = false;
  openAdditionalAtt_Modal() {


    const dialogRef = this.dialog.open(AdditionalAttributesComponent, {
      data: {
        params: this.powerSupplyData,
        additionalFields: this.additionalFields,
        p_type: ComponentConstant.COMPONENT,
        type: ComponentConstant.POWERSUPPLY,
        edited: this.isAdditionFieldsEdited
      },
      disableClose: true,
      panelClass: 'width-dialog-100',
    });

    dialogRef.afterClosed().subscribe(result => {


      if (result.data.action !== PlatformConstant.ACTION_CANCEL) {
        this.additionalFields = result.data.addedFields;
        this.applyToRecords = result.data.additionalRecords;
        this.isAdditionFieldsEdited = result.data.edited;
        //
        this.updatedRecords = [];
        this.additionalFields.forEach(element => {
          if (element.flag == "UpdateField" && element.hasOwnProperty("selectedItems")
            && element.selectedItems?.length > 0) {
            element.selectedItems.forEach(element2 => {

              if (element2.rowId !== this.powerSupplyData.id && element2.flag !== "NewField") {
                let ele = this.updatedRecords.filter(value => { return value.id == element2.rowId });

                if (ele?.length == 0) {

                  this.updatedRecords.push({
                    "type": ComponentConstant.POWERSUPPLY,
                    "role": "Admin",
                    "id": element2.rowId,
                    "additionalFields": [{
                      name: element.name,
                      type: element.type,
                      value: element.value,
                      flag: element.flag,
                      previousName: element.previousName
                    }]
                  })
                }
                //
                else {

                  ele[0].additionalFields.push({
                    name: element.name,
                    type: element.type,
                    value: element.value,
                    flag: element.flag,
                    previousName: element.previousName
                  })
                }
              }
              //
            });

          }
        });

      }

    });
  }
  // end-of-additional-fields

  //pid search
  selectedPid: any = null;
  selectedPID(e: any) {

    this.selectedPid = e;
  }

}
