<div class="edit">
    <div class="flex flex-between base-margin-bottom" style="max-width: 920px;">
        <h4>Edit Error Messages</h4>
    </div>
    <div class="row base-margin-bottom" *ngFor="let error of errorTypes; let i = index;">
        
        <div class="col-3" style="margin-left: 30px;">
            <div class="row base-margin-bottom">
                Error Type
            </div>
            <div class="row">
                Error Message
            </div>
        </div>
        <div class="col-6" style="display: flex;">
            <div class="form-group base-margin-bottom" style="flex:auto;">
                <div class="form-group__text" style="flex:auto;">
                    <textarea 
                    [ngClass]="errorMessages[i].trim() == '' || errorMessages[i]==null ? 'text-danger': (isEdit[i]==true ? 'text-normal' : 'text-disabled')" 
                    [(ngModel)]="errorMessages[i]" 
                    type="text" rows="3" style="flex:auto"
                    [disabled]="isEdit.length == 0 ? true : isEdit[i]==false"
                    (change)="onChangeErrorMessage($event, i)"
                    style="margin-top: 10px;"
                    ></textarea>
                    <label for="textarea-state-hover"> {{ errorTypes[i] | uppercase }} </label>
                </div>
            </div>
        </div>
        <div class="col-1 middle">
            <div (click)="onClickEdit($event, i)"
            class="icon-edit icon-size-20 man-comp-icon" style="margin: 0px 5px;color:#00bceb">
        </div> 
        </div> 
    </div>

</div>