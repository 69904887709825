import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { CNG_DATA, CngModalService } from '@cisco/cui-ng';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-save-template',
  templateUrl: './save-template.component.html',
  styleUrls: ['./save-template.component.css']
})
export class SaveTemplateComponent implements OnInit {
  isModalShown = false;
  subscriptions: Subscription = new Subscription(); 
  templateName ='';

  constructor(@Inject(CNG_DATA) public data: any,
  private modalService: CngModalService) { }

  ngOnInit(): void {
    this.templateName = this.data.key;
  }
  public closeModal () {
		this.subscriptions.add(this.modalService.close('closed'));
  }
  
  onConfimation() {   
    this.subscriptions.add(this.modalService.close([this.templateName,this.data.type,this.data.attributes, this.data.config]));
  }  

  ngOnDestroy(): void {
    if(this.subscriptions) {
      this.subscriptions.unsubscribe();
     }
  }
}
