<mat-card-title>
    <div mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle style="margin-bottom: 8px;">
        <h4 style="float: left;font-size: large;font-weight: 400;"> {{ platformName }} </h4>
        <div style="display:flex;
            flex-direction: row-reverse;">
            <div class="flex-center margin_auto_t_b">
                <a class="anchor-close" id="close" title="Close" (click)="onCancel()">
                    <span class="icon-close icon-size-24 icon-close"></span>
                </a>
            </div>
            <button mat-button class="btn btn--small " style="height: 24px;margin: 0px 20px 0 0;"
                (click)="openAdditionalAtt_Modal()">Additional Fields</button><span class="margin_auto_t_b"
                style="font-size: 65%;margin-top: -2px;margin-right:5px;"
                *ngIf="additionalFields.length>0">{{additionalFields.length}} Field(s) Added</span>
        </div>
    </div>
</mat-card-title>

<form [formGroup]="additionalPlatformForm" autocomplete="off">
    <div class="additionalPlatformForm--form--content">
        <mat-form-field>

            <div class="form-group base-margin-bottom row" style="min-width: 500px;">
                <div class="col-6"
                    *ngFor="let attribute of additionalPlatformForm.get('dynamicAttributes')['controls']; let i = index">
                    <div class="mandatory row">
                        <div class="col-4">
                        <mat-label>{{ attribute.controls.name.value }} </mat-label>
                        </div> 

                        <div class="col-8 form-group">
                            <div *ngIf="['text','integer'].includes(attribute.value.type)"
                                [attr.disabled]="attribute.value.id=='series'?true:null" class="form-group__text">
                                <input matInput value="{{ attribute.value.value }}" [readonly]="attribute.value.id=='series'" [ngClass]="attribute.status=='INVALID' && 
                                    attribute.value.required == true || (attribute.controls.value.hasError('pattern')) ||
                                    (attribute.value.id=='idlePower'|| attribute.value.id=='maxPower') && showWattErr 
                                    ? 'danger' : 'normal'" [(ngModel)]="attribute.value.value"
                                    [ngModelOptions]="{standalone: true}" (input)="validateForm(attribute)">
                                <mat-error
                                    *ngIf="attribute.value.type === 'integer' && (attribute.controls.value.hasError('pattern') || attribute.controls.value.hasError('min'))"
                                    style=color:red>Only Positive Integers are allowed</mat-error>
                                <mat-error
                                    *ngIf="(attribute.value.id=='idlePower'|| attribute.value.id=='maxPower') && showWattErr"
                                    style=color:red>Idle Power should be less than or equal to Max Power
                                </mat-error>
                            </div>
                            <div *ngIf="attribute.value.type==='dropdown'" class="select-io-type">
                                <ngx-select-dropdown [multiple]="false"
                                    [ngClass]="attribute.status == 'INVALID' ? 'ngx-select-danger ngx-dropdown-button': 'ngx-select-normal ngx-dropdown-button'"
                                    [(ngModel)]="attribute.value.value" [ngModelOptions]="{standalone: true}"
                                    (change)="validateForm(attribute)" [config]="{search:false,displayKey:'viewValue'}"
                                    [options]="getOptionsList(attribute.value.id)">
                                </ngx-select-dropdown>

                            </div>
                            <div *ngIf="attribute.value.type==='radio'">
                               
                                    <div class="form-group form-group--inline">
                                        <label class="radio">
                                            <input type="radio" value="1" name="eos" [ngModelOptions]="{standalone: true}" [(ngModel)]="attribute.value.value">
                                            <span class="radio__input"></span>
                                            <span class="radio__label">Yes</span>
                                        </label>
                                    </div>
                                    <div class="form-group form-group--inline">
                                        <label class="radio">
                                            <input type="radio" value="0" name="eos" [ngModelOptions]="{standalone: true}" [(ngModel)]="attribute.value.value" [checked]="true">
                                            <span class="radio__input"></span>
                                            <span class="radio__label">No</span>
                                        </label>
                                    </div>
                                
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </mat-form-field>
    </div>
    <div mat-dialog-actions>
        <table class="table table-bordered">
            <tbody>
                <tr>
                    <td>
                        <mat-card-actions>
                            <button mat-button class="btn btn--small " (click)="submit('closeModal')"
                                style="height: 24px;margin: 5px 20px 0 0;"
                                [disabled]="!additionalPlatformForm.valid">Save & Close</button>
                        </mat-card-actions>
                        <button mat-button class="btn btn--small " (click)="submit('save')"
                            style="height: 24px;margin: 5px 20px 0 0;"
                            [disabled]="!additionalPlatformForm.valid">Save</button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</form>