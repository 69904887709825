import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';

import { ProjectService } from 'src/app/services/project.service';
import { UcsSizerService } from 'src/app/services/ucs-sizer.service';
@Component({
  selector: 'app-project-details',
  templateUrl: './project-details.component.html',
  styleUrls: ['./project-details.component.css']
})
export class ProjectDetailsComponent implements OnInit {
  isProjectFetched: boolean = false;
  @BlockUI() blockUI: NgBlockUI;
  constructor(
    private projectService: ProjectService,
    public router: Router,
    private route: ActivatedRoute,
    private sizerService: UcsSizerService,
  ) {
    if (this.router.url.includes('ucs-resizer')) {
      let userName = this.route.snapshot.paramMap.get('userName');
      let projectName = this.route.snapshot.paramMap.get('projectName');
      if (userName && projectName) {
        this.blockUI.start();
        this.sizerService.fetchProjectByUserIdAndProjectName(userName, projectName);
        this.sizerService.getProjectStatus().subscribe(status => {
          if (status && !this.isProjectFetched) {
            this.isProjectFetched = true;
            this.blockUI.stop();
          }
        })
      } else {
        this.isProjectFetched = true;
        this.fetchFromLocalOrRedirect();
      }
    } else {
      this.isProjectFetched = true;
      this.fetchFromLocalOrRedirect();
    }
  }
  fetchFromLocalOrRedirect() {
    if (!localStorage.getItem("latestProjectId")) {
      if (this.router.url.includes('ucs-resizer')) {
        this.router.navigate(["/ucs-resizer/projectList"]);
      } else this.router.navigate(["/projectList"]);
    } else {
      let latestProjectId = localStorage.getItem("latestProjectId");
      if (!sessionStorage.getItem("myProjectId")) {
        sessionStorage.setItem("myProjectId", latestProjectId);
      } else {
        let projectType = this.router.url.includes('ucs-resizer') ? 'UCS.SizerProjects' : 'UCS.UserProjects';
        if (!JSON.parse(localStorage.getItem(`${projectType}-${sessionStorage.getItem("myProjectId")}`))) {
          if (projectType.includes('Sizer')) {
            this.router.navigate(["/ucs-resizer/projectList"]);
          } else this.router.navigate(["/projectList"]);
        }

      }
    }
  }
  ngOnInit(): void { }
}
