export const server = {
   "serverId": '',
   "displayName": "",
   "projectReferenceId": '',
   "serverType": "--series",
   "type": "",
   "pid": "",
   "index":0,
   "id": 0,
   "quantity": 0,  
   "userProvidedName": '',
   "config": {
      "quantity": 0,     
      "hasValidConfig": true,
      "systemWorkloadPercent": 0,
      "redundancyMode": "N",
      "inputVoltageId": 0,
      "voltageName": "",
      "voltageValue": "",
      "powerSupplyId": 0,
      "powerSupplyTypeName": "0",
      "powerSupplyCount": 0,
      "iom": 0,
      "ioModuleTypeName": "0",   
      "expansionCards": [
      ],
      "expansionQuantity": 0,
      "activePorts": 0,
      "processorId": 0,
      "processorTypeName": "",
      "processorCount": 0,
      "mezzanineControllerCount": 0,
      "mezzanineControllerTypeName": '',
      "adapterQuantity": 0,
      "storageQuantity": 0,
      "memoryQuantity": 0,
      "dedicatedStorageQuantity": 0,
      "memory": [],
      "storage": [],
      "adaptors": [],
      "dedicatedStorage": []
   },
   "power": {
   },
   "blades": [],
   "nodes": [],
   "maxBladeSlots": 0,
   "maxCartridgeSlots": 0,
  
   
}

