import { Component,ChangeDetectorRef, OnInit ,Inject,HostListener } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { UntypedFormControl, UntypedFormBuilder,UntypedFormGroup, Validators,ReactiveFormsModule  } from '@angular/forms';
import { Subscription } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ComponentConstant } from 'src/constants/components.constants';
import { ApiService } from 'src/app/services/api.service';
import { UrlConstant } from 'src/constants/url.constants';
import { ToasterService } from "src/app/shared/components/toast/toast.service";
import { errorCodes } from 'src/constants/errorCodes';
import { CngContentConfigType, CngModalService, CngModalSize } from '@cisco/cui-ng';
import { ConfirmationBoxComponent } from 'src/app/shared/components/confirmation-box/confirmation-box.component';
import { TranslateService } from '@ngx-translate/core';
import { AdminService } from 'src/app/services/admin.service';


export interface DialogData {  
  displayName : string; 
  type : string;
  id: string;
  update: boolean;
  released : string; 
}

@Component({
  selector: 'app-create-processorfamily',
  templateUrl: './create-processorfamily.component.html',
  styleUrls: ['./create-processorfamily.component.css']
})
export class CreateProcessorfamilyComponent implements OnInit {
  subscriptions: Subscription = new Subscription();
  constructor(public dialogRef: MatDialogRef<CreateProcessorfamilyComponent>,  
              @Inject(MAT_DIALOG_DATA) public data: DialogData,
              private apiService: ApiService,
              private toaster: ToasterService, 
              private modalService: CngModalService,
              private translate: TranslateService,
              private adminService: AdminService,
              private formBuilder : UntypedFormBuilder,
              private cdr: ChangeDetectorRef) { 
  this.validations = this.formBuilder.group({
    displayName : new UntypedFormControl('',[Validators.required,]),                  
    })
  }
    validations: UntypedFormGroup; 
    id;
    type;
    released;
    update : boolean = false;
    dialogConfig;
    errorMsg= '';     

    onNoClick(): void {
      this.type = this.data.type;
      if (this.data.update) {
        this.displayName = this.data.displayName;
      }
      this.dialogRef.close('cancel');
    }
    private params: any;
    private displayName : string;


    /*agInit(params: any): void {
      this.params = params;
      // simple implementation - we assume a full name consists of a first and last name only
      this.displayName = this.params.value;      
    } */

  ngOnInit(): void {
    this.type = this.data.type;
    this.displayName = this.data.displayName;
    
    this.update = this.data.update;
    this.cdr.detectChanges();

  }
  
  
  onSave(){    
    if (this.validations.valid) {
      
      //let type = this.type = this.dimmsFamilyData.type;//ComponentConstant.DIMM_FAMILIES;        
      let data = {      
        "type" : this.type,
        "role" : "Admin",
        "text" : this.validations.value.displayName,          
        "released" : "",
        "allowDuplicates" : false            
      };  
      
      if (!this.update){
        
        let result = this.createProcessorFamily(data);          
      } else {
        data.released = this.released;        
        data["id"] = this.data.id;
        let result = this.updateProcessorFamily(data);
      }     
      
    } else {
      
    }
  }

  createProcessorFamily(reqData:any){
    const url = UrlConstant.MANAGECOMPONENTS ;    
    this.subscriptions.add(
      this.apiService.postMethod(url,reqData).subscribe((data: any) => {        
        
        this.data = data;
        if (data.modifiedCount > 0) {
          // var cn = confirm('New ProcessorFamily has been created.');
          // if(cn){
            this.toaster.show('success', errorCodes.NEW_RECORD , '');
            window.scroll(0,0);
             this.dialogRef.close();
          //}
        } else if (data.modifiedCount == 0 && data.error.errorString == "Duplicate Data Found" ) {
          // var cn = confirm("Duplicate data found. Do you want to close ? ");
          // if (cn){
           // this.toaster.show('warning', errorCodes.DUPLICATE_RECORD , '');
           reqData["allowDuplicates"] = true;
           this.openModal(reqData);
           // this.dialogRef.close();
          //}
          this.subscriptions.add(
            this.apiService.postMethod(url,reqData).subscribe((data: any)=>{
              
              if (data.modifiedCount > 0) {
                // var cn = confirm('New IO Device has been created.');
                // if(cn){
                  this.toaster.show('success', errorCodes.NEW_RECORD , '');
                  window.scroll(0,0);
                   this.dialogRef.close();
              //  }
              }
            })) 
        }                       
      }, error =>{  
        this.errorMsg= error?.error ? error.error?.error?.errorString : errorCodes.GENERIC_ERROR_MESSAGE;
        this.toaster.show('error', this.errorMsg , error ? error?.message : '');          
      })
    );
  }

  updateProcessorFamily(reqData:any) {
    const url = UrlConstant.MANAGECOMPONENTS + UrlConstant.UPDATE ;        
    this.subscriptions.add(
      this.apiService.postMethod(url,reqData).subscribe((data: any) => {     
        
        this.data = data;
        if (data.modifiedCount > 0 &&  data.successMessage == "Successfully updated") {
          // var cn = confirm('Details have been updated.');
          // if(cn){
            this.toaster.show('success', errorCodes.UPDATE_RECORD , '');
            window.scroll(0,0);
             this.dialogRef.close();
         // }
        } else if (data.modifiedCount == 0 && data.error.errorString == "Duplicate Data Found" ) {
          // var cn = confirm("Duplicate data found. Do you want to close ? ");
          // if (cn){
            // this.toaster.show('warning', errorCodes.DUPLICATE_RECORD , '');
            // this.dialogRef.close();
         // }
         reqData["allowDuplicates"] = true;
         this.openModal(reqData);         
        this.dialogRef.close();
            this.subscriptions.add(
              this.apiService.postMethod(url,reqData).subscribe((data: any)=>{
                
                if (data.modifiedCount > 0) {
                  // var cn = confirm('Details have been updated.');
                  // if(cn){
                    this.toaster.show('success', errorCodes.UPDATE_RECORD , '');
                    window.scroll(0,0);
                     this.dialogRef.close();
                  //}
                }
              })) 
        }                       
      }, error =>{  
        this.errorMsg= error?.error ? error.error?.error?.errorString : errorCodes.GENERIC_ERROR_MESSAGE;
        this.toaster.show('error', this.errorMsg , error ? error?.message : '');           
      })
    );
    
  }
  ngOnDestroy() {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }


  public onCancel = () => {
    
  } 

  public async openModal(data: any) {
    const result = await this.modalService
      .open({
        content: {
          type: CngContentConfigType.COMPONENT,
          content: ConfirmationBoxComponent,
          componentData: {
            someText: this.translate.instant(
              "DUPLICATE_DATA_FOUND"
            ),
            key: "delete",           
          },
        },
        size: CngModalSize.SMALL,
      })
      .onDismiss.toPromise();

    if (result == 'ok')     
   this.getDuplicateData(data);
  }
  getDuplicateData(data: any) {
    this.subscriptions.add(this.adminService.setProcessorFamilyData(data));
  }
}
