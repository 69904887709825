<mat-card-title>
  <div mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle style="margin-bottom: 8px;">
    <h4 style="float: left;font-size: large;font-weight: 400;"> {{ componentDisplayName }} </h4>
    <div style="display:flex;
      flex-direction: row-reverse;">
      <div class="flex-center margin_auto_t_b">
        <a class="anchor-close" id="close" title="Close" (click)="onCancel()">
          <span class="icon-close icon-size-24 icon-close"></span>
        </a>
      </div>
      <button mat-button class="btn btn--small " style="height: 24px;margin: 0px 20px 0 0;"
        (click)="openAdditionalAtt_Modal()">Additional Fields</button><span class="margin_auto_t_b"
        style="font-size: 65%;margin-top: -2px;margin-right:5px;"
        *ngIf="additionalFields.length>0">{{additionalFields.length}} Field(s) Added</span>
    </div>
  </div>
</mat-card-title>

<form [formGroup]="validations" autocomplete="off">
  <div class="validations--form--content">
    <mat-form-field>

      <div class="form-group base-margin-bottom" style="max-width:800px;min-width: 500px;">
        <table class="table table-bordered">
          <tbody>
            <tr *ngFor="let attribute of validations.get('dynamicAttributes')['controls']; let i = index">
              <td class="mandatory">
                <mat-label>{{ attribute.controls.name.value }} </mat-label>
                <div class="form-group">
                  <div *ngIf="['text','integer'].includes(attribute.value.type)" class="form-group__text">
                    <input matInput 
                    value="{{ attribute.value.value }}" 
                    [ngClass]="attribute.status=='INVALID' && attribute.value.required == true ||
                      (attribute.controls.value.hasError('pattern')) ||
                      (attribute.value.id=='idlePower'|| attribute.value.id=='maxPower') && showWattErr 
                      ? 'danger' : 'normal'"
                    [(ngModel)]="attribute.value.value" [ngModelOptions]="{standalone: true}"
                    (input)="validateForm(attribute)">
                    <mat-error *ngIf="attribute.value.type === 'integer' && (attribute.controls.value.hasError('pattern') || attribute.controls.value.hasError('min'))" style=color:red>Only Positive
                      Integers are allowed</mat-error>
                    <mat-error *ngIf="(attribute.value.id=='idlePower'|| attribute.value.id=='maxPower') && showWattErr"
                      style=color:red>Idle Power should be less than or equal to Max Power </mat-error>
                  </div>
                  <div *ngIf="attribute.value.type==='dropdown'" class="select-io-type">
                    <ngx-select-dropdown [multiple]="false"
                      [ngClass]="attribute.status == 'INVALID' ? 'ngx-select-danger ngx-dropdown-button': 'ngx-select-normal ngx-dropdown-button'"
                      [(ngModel)]="attribute.value.value" (change)="validateForm(attribute)"
                      [ngModelOptions]="{standalone: true}" [config]="{search:false,displayKey:'viewValue'}"
                      [options]="getOptionsList(attribute.value.id)">
                    </ngx-select-dropdown>
                    <div
                      *ngIf="attribute.value.id=='ioType' && (attribute.value.value?.value=='RAID'|| attribute.value.value?.value=='PCI-RAID')">
                      <div style="margin:1rem 0">
                        <mat-checkbox [checked]="validations.controls.raid0.value"
                          (change)="setRaid(0, attribute, $event)" style="padding-right: 10px;"> RAID 0 </mat-checkbox>
                        <mat-checkbox [checked]="validations.controls.raid1.value"
                          (change)="setRaid(1, attribute, $event)" style="padding-right: 10px;"> RAID 1 </mat-checkbox>
                        <mat-checkbox [checked]="validations.controls.raid5.value"
                          (change)="setRaid(5, attribute, $event)" style="padding-right: 10px;"> RAID 5 </mat-checkbox>
                        <mat-checkbox [checked]="validations.controls.raid6.value"
                          (change)="setRaid(6, attribute, $event)" style="padding-right: 10px;"> RAID 6 </mat-checkbox>
                      </div>
                      <div style="margin:1rem 0">
                        <mat-checkbox [checked]="validations.controls.raid10.value"
                          (change)="setRaid(10, attribute, $event)" style="padding-right: 10px;"> RAID 10
                        </mat-checkbox>
                        <mat-checkbox [checked]="validations.controls.raid50.value"
                          (change)="setRaid(50, attribute, $event)" style="padding-right: 10px;"> RAID 50
                        </mat-checkbox>
                        <mat-checkbox [checked]="validations.controls.raid60.value"
                          (change)="setRaid(60, attribute, $event)" style="padding-right: 10px;"> RAID 60
                        </mat-checkbox>
                      </div>
                    </div>
                  </div>
                  <div *ngIf="attribute.value.type==='checkbox'" class="select-checkbox">
                    <!-- if attribute is a checkbox -->
                    <label class="checkbox" style="margin: 0px 5px 0px 5px;">
                      <input class="text-danger" type="checkbox" (click)="checkedHandler($event,'FRONT', attribute)"
                        [checked]="frontChecked" value="FRONT"
                        [ngClass]=" !zoneCkeckboxSelected ? 'text-danger': 'hover'" name="front">
                      <span class="checkbox__input" style="margin-right: 5px;"
                        [ngClass]=" !zoneCkeckboxSelected ? 'text-danger': ''">
                      </span>FRONT
                    </label>
                    <label class="text-danger" class="checkbox" style="margin: 0px 5px 0px 5px;">
                      <input type="checkbox" (click)="checkedHandler($event, 'REAR', attribute)" [checked]="rearChecked"
                        value="REAR" [ngClass]=" !zoneCkeckboxSelected ? 'text-danger': 'hover'" name="rear">
                      <span class="checkbox__input" style="margin-right: 5px;"
                        [ngClass]=" !zoneCkeckboxSelected ? 'text-danger': ''">
                      </span>REAR
                    </label>
                    <label class="checkbox" style="margin: 0px 5px 0px 5px;">
                      <input type="checkbox" (click)="checkedHandler($event, 'INTERNAL', attribute)"
                        [checked]="internalChecked" value="INTERNAL"
                        [ngClass]=" !zoneCkeckboxSelected ? 'text-danger': 'hover'" name="internal">
                      <span class="checkbox__input" [ngClass]=" !zoneCkeckboxSelected ? 'text-danger': ''"
                        style="margin-right: 5px;"></span>INTERNAL
                    </label>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </mat-form-field>
  </div>
  <div mat-dialog-actions>
    <table class="table table-bordered">
      <tbody>
        <tr>
          <td>
            <mat-card-actions>
              <button mat-button (click)="onSave()" class="btn btn--small " style="height: 24px;margin: 5px 20px 0 0;"
                [disabled]="!validations.valid">Save & Close</button>
            </mat-card-actions>
            <button mat-button (click)="onNoClick()" class="btn btn--small " style="height: 24px;margin: 5px 20px 0 0;"
              [disabled]="!validations.valid">Save</button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</form>