


<div class="main-content1" *ngIf="!showLoader">
    <div class="license-first-container">
        <h5 class="license-heading">{{'CISCO_POWER_CALCULATOR_INFORMATION' | translate}}</h5>
        <br />
        <!-- <div *ngFor="let licenseText of licenseTextResponse">
            <pre style="font-family: 'CiscoSans';">{{licenseText}}</pre>
        </div>  -->
        <pre style="font-family: 'CiscoSans';">{{licenseText}}</pre>
        <br>
    </div>
    <div class="license-second-container">
        <div class="form-group form-group--inline license-checkbox">
            <label class="checkbox">
                <input type="checkbox" id="acceptLicense" (change)="true" value="1" #acceptLicense>
                <span class="checkbox__input"></span>
                <span class="checkbox__label">{{'ACCEPT_LICENSE' | translate}}</span>
            </label>
        </div>
        <div class="license-button">
            <button class="btn btn--ghost" (click)="onDeclineLicense()">{{'DECLINE' | translate}}</button>
            <button class="btn" [ngClass]="(acceptLicense.checked) ? 'active': 'disabled'"
                (click)="onAgreeToLicense()">{{'PROCEED' | translate}} </button>
        </div>
    </div>
</div>
