import { Component, OnInit } from '@angular/core';
import { GridOptions } from "ag-grid-community";
import { Subscription } from 'rxjs';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { CreateModularChassisComponent } from "src/app/components/admin/admin-tab/create-platforms/create-modular-chassis/create-modular-chassis.component";
import { CheckboxRenderManagePlatformsComponent } from 'src/app/shared/components/checkbox-render-manage-platforms/checkbox-render-manage-platforms.component';
import { EditRenderManagePlatformsComponent } from 'src/app/shared/components/edit-render-manage-platforms/edit-render-manage-platforms.component';
import { PlatformConstant } from 'src/constants/platform.constants';
import { ApiService } from 'src/app/services/api.service';
import { UrlConstant } from 'src/constants/url.constants';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import * as moment from 'moment';
import { AdminService } from 'src/app/services/admin.service';
import { ShowFieldsComponent } from 'src/app/shared/components/show-fields/show-fields.component';
import { UpdateNameService } from 'src/app/services/update-name.service';
import { ToasterService } from 'src/app/shared/components/toast/toast.service';

@Component({
  selector: 'app-manage-modular-chassis',
  templateUrl: './manage-modular-chassis.component.html',
  styleUrls: ['./manage-modular-chassis.component.css']
})
export class ManageModularChassisComponent implements OnInit {

  subscriptions: Subscription = new Subscription();
  platformName:any = "";

  errorMsg:any = '';
  rowData:any;
  paginationPageSize = 10; 
  @BlockUI() blockUI: NgBlockUI; 

  frameworkComponents = {
    checkboxRenderer : CheckboxRenderManagePlatformsComponent ,
    buttonEditRenderer : EditRenderManagePlatformsComponent             
  };

  constructor(public dialog: MatDialog,
    private apiService: ApiService,
    private adminService: AdminService,
    private updateNameService: UpdateNameService,
    private toaster: ToasterService,
    ) { }

  ngOnInit(): void {
    this.getManageModularServersList();
    
    this.updateNameService.getplatformPanelData().subscribe((data) => {
      
      if(data.length>0){
        let platform = data.filter(f=>{return f["internalName"] == "Modular Chassis"})
        
        let compNameSplit = platform[0].displayName.trim().split(" ");
        let compName = '';
        for (let i = 0; i < compNameSplit.length; i++) {
          if(!(compNameSplit[i]?.toUpperCase().includes("MANAGE")))
            compName += compNameSplit[i] + " ";
        }        
        this.platformName = compName;
        this.adminService.setPlatformName(this.platformName);

      }
    });

    this.subscriptions.add(

      this.adminService.getPlatformData().subscribe(data => {
        if(data) {
          this.flagBackButton = true;
        this.getDuplicateRecords(data);
        }
      })
      )
      window.scroll(0,0);
      this.subscriptions.add(this.adminService.getSeriesAll().subscribe((data) => {
        this.seriesAll = data;
        
        }));
  }
  seriesAll = [];
  flagBackButton = false;
  getDuplicateRecords(data: any) {    
    const filter = {
     name: {
           filterType: 'text',
           type: 'equals',
           filter: data.name
       }, basePid: {
         filterType: 'text',
         type: 'equals',
         filter:  data.basePid
     }    
   }
     this.gridApi.setFilterModel(filter)
   }

  gridOptions : GridOptions = {
    columnDefs: [
      { headerName: 'Name' , field:'name',
      comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
        if (valueA.toUpperCase() == valueB.toUpperCase()){ 
          return 0;
        }
        else{
          return (valueA.toUpperCase() > valueB.toUpperCase()) ? 1 : -1;
        }
      }},
      { headerName: 'Generation' , field:'gen',
      comparator: (a, b, nodeA, nodeB, isInverted) => {
        if (a === b) {
            return 0;
          }
         else if (a == null || a=='') {
             return isInverted ?-1: 1;
         }
         else if (b == null || a=='') {
             return isInverted ? 1: -1;
         }
         else { 
          return (a.toUpperCase() > b.toUpperCase()) ? 1 : -1;
         }
     } 
    },
      { headerName: 'PID' , field:'basePid',
      comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
        if (valueA.toUpperCase() == valueB.toUpperCase()){ 
          return 0;
        }
        else{
          return (valueA.toUpperCase() > valueB.toUpperCase()) ? 1 : -1;
        }
      }},
      { headerName: 'Idle Power(W)' , field:'idleBasePower'},
      { headerName: 'Max Power(W)' , field:'maxBasePower'},
      { headerName: 'EoS' , field:'discont',minWidth: 80,
      maxWidth: 90,
        cellRenderer: (data) => {
          return data.value == "1" ? "Yes" : "No" ;
        }},
      { headerName: 'Weight(lbs)' , field:'weight'},
      { headerName: 'Power Cap' , field:'implicitCap',
      cellRenderer: (data) => {
        return  data.value ? data.value.charAt(0).toUpperCase() + data.value.slice(1) : "";
      }},

      { headerName: 'PSU Slots' , field:'numPsuSlots'},
      { headerName: 'MTBF (Hours)' , field:'mtbf'},
      { headerName: 'Disk Slots' , field:'maxDisks'},

      { headerName: 'IO Slots' , field:'pciSlots'},
      { headerName: 'Catridge Slots' , field:'numBladeSlots'},//to be added.


      { headerName: 'Released State' , field:'released',cellRenderer: "checkboxRenderer",minWidth: 110,
      maxWidth: 120,},
      { headerName: 'Series' , field:'series', hide:true,
      cellRenderer: (data) => {
        
        let series = this.seriesAll.filter(f=>f.series == data.value)
        return series?.length>0 ?series[0].seriesDisplayName : data.value; 
      }},
      { headerName: 'Updated By' , field:'editor'},
      { headerName: 'Last Updated' , field:'lastUpdated',
      cellRenderer: (data) => {
        return data.value ? moment(data.value).format('MMM DD, YYYY') : moment(data.data.created).format('MMM DD, YYYY');
      },
      valueGetter: function(data) {

        return data.data?.lastUpdated ? moment(data.data?.lastUpdated).format('MMM DD, YYYY') : moment(data.data?.created).format('MMM DD, YYYY');

  },
      comparator:function dateComparator(date1, date2) {
        var date1Number = date1 && new Date(date1).getTime();
        var date2Number = date2 && new Date(date2).getTime();

        
        
      
        if (date1Number == null && date2Number == null) {
          return 0;
        }
      
        if (date1Number == null) {
          return -1;
        } else if (date2Number == null) {
          return 1;
        }
      
        return date1Number - date2Number;
      }
    }, 

      { headerName: 'Manage Component Options' , 
        cellRenderer: "buttonEditRenderer",
        sortable: false,
          filter: false,
        cellRendererParams: {
          label: PlatformConstant.MANAGE_COMP_OPTIONS,
          onClick: this.onConfigButtonClick.bind(this),
        }},
      {
        headerName: "",
        minWidth: 60,
        maxWidth: 70,
        sortable: false,
          filter: false,
        cellRenderer: "buttonEditRenderer",
        cellRendererParams: {
          label: PlatformConstant.ACTION_UPDATE,
          onClick: this.onConfigButtonClick.bind(this),
        },
      },
      {
        headerName: "",
        minWidth: 60,
        maxWidth: 70,
        sortable: false,
          filter: false,
        cellRenderer: "buttonEditRenderer",
        cellRendererParams: {
          label: PlatformConstant.ACTION_DELETE,
        },
      },
    ],
    defaultColDef: {
      sortable: true,
      flex: 1,
      minWidth: 125,
      filter: true,
      resizable: true,
      editable: false,
      headerComponentParams: {
        template:
            '<div class="ag-cell-label-container" role="presentation">' +
            '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
            '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
            '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
            '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
            '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
            '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
            '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
            '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
            '  </div>' +
            '</div>',
    },
    filterParams: { newRowsAction: 'keep'},
    }
  }
  openDialog(): void {
    const dialogRef = this.dialog.open(CreateModularChassisComponent, {      
      data: {
        type : PlatformConstant.MODULAR_CHASSIS,
        action : PlatformConstant.ACTION_CREATE
      } ,
      disableClose: true,
      panelClass: 'width-dialog-100',           
    });

    dialogRef.afterClosed().subscribe(result => {
       
       
      if(result.data?.action == PlatformConstant.ACTION_REFRESH)
        this.getManageModularServersList(); 
       setTimeout(() => this.gridApi.paginationGoToLastPage(), 100);   
    });
  }

  // showGrid:boolean = false;
  getManageModularServersList(){
    
    this.blockUI.start('Loading...');
    // this.showGrid = false;
    const type = PlatformConstant.MODULAR_CHASSIS;
    const url = UrlConstant.MANAGE_PLATFORM + '/?platformType=' + type ;
    this.subscriptions.add(
      this.apiService.getMethod(url).subscribe((data: any) => {
        this.adminService.setGridData(data.ucsPlatform);
        this.rowData = data.ucsPlatform.filter(d => d.deleteFlag !== true);
        //to add addtional fields in grid
        if(data.addlabels){
          let index = this.gridOptions.columnDefs.length - 3; 
         data.addlabels.forEach(element => {
          let headerIndex =  this.gridOptions.columnDefs.findIndex(f=>f.headerName == element);
          if(headerIndex == -1)
             this.gridOptions.columnDefs.splice(index,0,{ headerName: element ,colId:element,hide:true,field:element,
               cellRenderer: (data) => {
                 
                 let value = null;
                 data.data.additionalFields?.forEach(element1 => {
                   if(element1.name == data.colDef.headerName)
                     value = element1.value;
                 });
                 return value;
               },
               valueGetter: function(data) {
                let value = null;
                data.data.additionalFields?.forEach(element1 => {
                  if(element1.name == data.colDef.headerName)
                    value = element1.value;
                });
                return value;
          }})
         });
       }
      // this.showGrid = true;
      this.gridApi.setColumnDefs(this.gridOptions.columnDefs);
      //set coumns from localstorage
      let setFields = JSON.parse(localStorage.getItem(PlatformConstant.MODULAR_CHASSIS+"GridFields"))
      if(setFields?.show && setFields?.hide){
        this.gridColumnApi.setColumnsVisible(setFields.show, true);
        this.gridColumnApi.setColumnsVisible(setFields.hide, false);
        this.gridColumnApi.moveColumns(setFields.show, 0);

      }
      this.blockUI.stop();
      }, (error) => {
        console.error(error);
        this.errorMsg = error?.error ? error.error?.error?.errorString : "Oops something went wrong!!";
        this.toaster.show('error', this.errorMsg, error ? error?.message : '');
        this.blockUI.stop(); 
      })
    );
  }
  
  onConfigButtonClick(params:any){
    
    this.getManageModularServersList();
  }

  gridApi: any;
  gridColumnApi:any;
  headerHeightSetter(event: any) {
    var padding = 20;
    var height = this.headerHeightGetter() + padding;
    this.gridApi.setHeaderHeight(height);
    this.gridApi.resetRowHeights();
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi; 
    //params.api.sizeColumnsToFit();
    
    //set coumns from localstorage
    let setFields = JSON.parse(localStorage.getItem(PlatformConstant.MODULAR_CHASSIS+"GridFields"))
    if(setFields?.show && setFields?.hide){
      this.gridColumnApi.setColumnsVisible(setFields.show, true);
      this.gridColumnApi.setColumnsVisible(setFields.hide, false);
      this.gridColumnApi.moveColumns(setFields.show, 0);

    }
  }
  headerHeightGetter() {
    var columnHeaderTexts = document.querySelectorAll('.ag-header-cell-text');
  
    var columnHeaderTextsArray = [];
  
    columnHeaderTexts.forEach(node => columnHeaderTextsArray.push(node));
  
    var clientHeights = columnHeaderTextsArray.map(
      headerText => headerText.clientHeight
    );
    var tallestHeaderTextHeight = Math.max(...clientHeights);
    return tallestHeaderTextHeight;
  }
  resetGrid() {
    this.gridApi.setFilterModel(null);
    this.flagBackButton = false;
  }

  openShowFields(){
    
      //show/hide column
      
  
      
      var dd = this.gridColumnApi.getAllDisplayedColumns();
      dd.forEach(element => {
        
      });
      //this.gridColumnApi.setColumnsVisible(["basePid", "cpuSockets"], false);
      // const group = this.gridColumnApi.columnController.columnDefs;
      // this.gridOptions.columnDefs.forEach(child => this.gridColumnApi.setColumnVisible(child.field, false));
      
      let allcols = this.gridColumnApi.getAllColumns();
      const dialogRef = this.dialog.open(ShowFieldsComponent, {      
        data: {
               fields : allcols,
              } ,
        disableClose: false,
        // panelClass: 'width-dialog-100',           
      });   
  
      dialogRef.afterClosed().subscribe(result => {
         
        // let show = result.data.fields.filter(v => { return v.visible == true });
        // let hide = result.data.fields.filter(v => { return v.visible == false });
        let show = [];
        let hide = [];
        result.data.fields.forEach(element => {
          if(element.visible == true){
            show.push(element.colId)
          }
          else{
            hide.push(element.colId)
          }
        });
        
        
        //save to localstorage
        const setFields = {show:show,hide:hide}
        localStorage.setItem(PlatformConstant.MODULAR_CHASSIS+"GridFields", JSON.stringify(setFields));

        this.gridColumnApi.setColumnsVisible(show, true);
        this.gridColumnApi.setColumnsVisible(hide, false);
        this.gridColumnApi.moveColumns(setFields.show, 0);

        this.headerHeightSetter(null);
      });
    }

  ngOnDestroy() {
    
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }

}
