<form [formGroup]="nodeForm" autocomplete="off" style="overflow: auto;max-height: 90vh;">
    <div class="container-fluid">

        <div style="margin-bottom: 0px;" mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
            <h4 style="float: left;">{{platformName}}</h4>
            <div style="    display: flex;
        flex-direction: row-reverse;">
                <div class="flex-center margin_auto_t_b">
                    <a class="anchor-close" id="close" title="Close" (click)="onCancel()">
                        <span class="icon-close icon-size-24 icon-close"></span>
                    </a>
                </div>
                <button mat-button type="button" class="btn btn--small " style="height: 24px;margin: 5px 20px 0 0;"
                    (click)="openAdditionalAtt_Modal()">Additional Fields</button><span class="margin_auto_t_b"
                    style="font-size: 65%;padding: 5px;" *ngIf="additionalFields?.length>0">{{additionalFields.length}}
                    Field(s) Added</span>
            </div>
        </div>
        <div class="section">
            <div class="col-md-12 col-lg-12">
                <div class="row">
                    <div class="col-md-6 col-lg-6 row">
                        <div class="col-3">
                            Compute Node
                        </div>
                        <div class="col-md-6 col-lg-6">
                            <div class="form-group form-group--inline">
                                <label class="radio">
                                    <input type="radio" value="1" name="computeNode" formControlName="computeNode"
                                        (ngModelChange)="changeCNode($event)">
                                    <span class="radio__input"></span>
                                    <span class="radio__label">Yes</span>
                                </label>
                            </div>
                            <div class="form-group form-group--inline">
                                <label class="radio">
                                    <input type="radio" value="0" name="computeNode" formControlName="computeNode"
                                        (ngModelChange)="changeCNode($event)">
                                    <span class="radio__input"></span>
                                    <span class="radio__label">No</span>
                                </label>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <div class="section">
            <div class="col-md-12 col-lg-12">
                <div class="row">

                    <div class="col-md-6 col-lg-6 row">
                        <div class="col-3">
                            Vendor
                        </div>
                        <div class="col-md-6 col-lg-6">
                            <div class="form-group">
                                <div class="form-group__text">
                                    <input matInput formControlName="vendor"
                                        [ngClass]="nodeForm.get('vendor').errors ? 'text-danger': 'text-normal'">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-6 row">
                        <div class="col-3">
                            Name
                        </div>
                        <div class="col-md-6 col-lg-6">
                            <div class="form-group">
                                <div class="form-group__text">
                                    <input matInput formControlName="name"
                                        [ngClass]="nodeForm.get('name').errors ? 'text-danger': 'text-normal'">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <div class="section">
            <div class="col-md-12 col-lg-12">

                <div class="row">

                    <div class="col-md-6 col-lg-6 row">
                        <div class="col-3">
                            Generation
                        </div>
                        <div class="col-md-6 col-lg-6">
                            <div class="form-group">
                                <div class="form-group__text">
                                    <input matInput formControlName="gen" [ngClass]="'text-normal'">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-6 row">
                        <div class="col-3">
                            PID
                        </div>
                        <div class="col-md-6 col-lg-6">
                            <app-search-box *ngIf="showPID" [type]="'platform'" [pid]="selectedPid"
                                (pidSelectionEvent)="selectedPID($event)"></app-search-box>

                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="section">
            <div class="col-md-12 col-lg-12">
                <div class="row">

                    <div class="col-md-6 col-lg-6 row">
                        <div class="col-3">
                            Max Dimms
                        </div>
                        <div class="col-md-6 col-lg-6">
                            <div class="form-group">
                                <div class="form-group__text">
                                    <input matInput formControlName="dimmSlots" maxlength="10"
                                        [ngClass]="!showPowerfields ? (nodeForm.get('dimmSlots').hasError('required') || 
                                                   nodeForm.get('dimmSlots').hasError('min') 
                                                  ||nodeForm.get('dimmSlots').hasError('pattern')  ? 'text-danger': 'text-normal'):''">
                                </div>
                                <span
                                    *ngIf="nodeForm.get('dimmSlots').hasError('pattern') || nodeForm.get('dimmSlots').hasError('min')"
                                    style=color:red>Only Positive Integers are allowed</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-6 row">
                        <div class="col-3">
                            Max CPUs
                        </div>
                        <div class="col-md-6 col-lg-6">
                            <div class="form-group">
                                <div class="form-group__text">
                                    <input matInput formControlName="cpuSockets" maxlength="10"
                                        [ngClass]="!showPowerfields ? (nodeForm.get('cpuSockets').hasError('required') ||
                                        nodeForm.get('cpuSockets').hasError('pattern') || nodeForm.get('cpuSockets').hasError('min') ? 'text-danger': 'text-normal'):''">
                                </div>
                                <span
                                    *ngIf="nodeForm.get('cpuSockets').hasError('pattern') || nodeForm.get('cpuSockets').hasError('min')"
                                    style=color:red>Only Positive Integers are allowed</span>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <div class="section">
            <div class="col-md-12 col-lg-12">

                <div class="row">

                    <div class="col-md-6 col-lg-6 row">
                        <div class="col-3">
                            Number Of PCI Slots
                        </div>
                        <div class="col-md-6 col-lg-6">
                            <div class="form-group">
                                <div class="form-group__text">
                                    <input matInput formControlName="pciSlots" maxlength="10"
                                        (ngModelChange)="validateSlots($event,'pci')"
                                        [ngClass]="pciSoltsErr || nodeForm.get('pciSlots').hasError('required') || nodeForm.get('pciSlots').hasError('pattern') ? 'text-danger': 'text-normal'">
                                </div>
                                <span *ngIf="nodeForm.get('pciSlots').hasError('pattern')" style=color:red>Only Positive
                                    Integers are allowed</span>
                                <mat-error *ngIf="pciSoltsErr" style=color:red>PCi Slots cannot be set to 0 because IO
                                    Cards are already assigned</mat-error>

                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-6 row">
                        <div class="col-3">
                            Number of Hard Disks
                        </div>
                        <div class="col-md-6 col-lg-6">
                            <div class="form-group">
                                <div class="form-group__text">
                                    <input matInput formControlName="maxDisks" maxlength="10"
                                        (ngModelChange)="validateSlots($event,'hd')"
                                        [ngClass]="hdSoltsErr || nodeForm.get('maxDisks').hasError('required') || nodeForm.get('maxDisks').hasError('pattern') ? 'text-danger': 'text-normal'">
                                </div>
                                <span *ngIf="nodeForm.get('maxDisks').hasError('pattern')" style=color:red>Only Positive
                                    Integers are allowed</span>
                                <mat-error *ngIf="hdSoltsErr" style=color:red>Hard Drives cannot be set to 0 because
                                    Storages are already assigned</mat-error>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="section">
            <div class="col-md-12 col-lg-12">

                <div class="row">

                    <div class="col-md-6 col-lg-6 row">
                        <div class="col-3">
                            Idle Power
                        </div>
                        <div class="col-md-6 col-lg-6">
                            <div class="form-group">
                                <div class="form-group__text">
                                    <input matInput formControlName="idlePower" (ngModelChange)="validateWatts($event)"
                                        [ngClass]="IdleWattsErr || showWattErr || nodeForm.get('idlePower').hasError('required') || nodeForm.get('idlePower').hasError('pattern') ? 'text-danger': 'text-normal'">
                                </div>
                                <span *ngIf="IdleWattsErr || nodeForm.get('idlePower').hasError('pattern')"
                                    style=color:red>Only Positive Integers are allowed </span>
                                <mat-error *ngIf="showWattErr" style=color:red>Idle Power should be less than or equal
                                    to Max Power </mat-error>

                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-6 row">
                        <div class="col-3">
                            Max Power
                        </div>
                        <div class="col-md-6 col-lg-6">
                            <div class="form-group">
                                <div class="form-group__text">
                                    <input matInput formControlName="maxPower" (ngModelChange)="validateWatts($event)"
                                        [ngClass]="IdleMaxWattsErr || showWattErr || nodeForm.get('maxPower').hasError('required') || nodeForm.get('maxPower').hasError('pattern') ? 'text-danger': 'text-normal'">
                                </div>
                                <span *ngIf="IdleMaxWattsErr || nodeForm.get('maxPower').hasError('pattern')"
                                    style=color:red>Only Positive Integers are allowed </span>
                                <mat-error *ngIf="showWattErr" style=color:red>Idle Power should be less than or equal
                                    to Max Power </mat-error>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="section">
            <div class="col-md-12 col-lg-12">

                <div class="row">

                    <div class="col-md-6 col-lg-6 row">
                        <div class="col-3">
                            Chassis Slots
                        </div>
                        <div class="col-md-6 col-lg-6">
                            <div class="form-group">
                                <div class="form-group">
                                    <div class="form-group__text">
                                        <input matInput formControlName="numChassisSlots"
                                            [ngClass]="nodeForm.get('numChassisSlots').hasError('required') ||nodeForm.get('numChassisSlots').hasError('min') || nodeForm.get('numChassisSlots').hasError('pattern') ? 'text-danger': 'text-normal'">
                                    </div>
                                    <span
                                        *ngIf="nodeForm.get('numChassisSlots').hasError('pattern') || nodeForm.get('numChassisSlots').hasError('min')"
                                        style=color:red>Only Positive Integers are allowed</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-6 row">
                        <div class="col-3">
                            Conversion Factor
                        </div>
                        <div class="col-md-6 col-lg-6">
                            <div class="form-group">
                                <div class="form-group__text">
                                    <input matInput formControlName="conversionFactor" maxlength="10"
                                        [ngClass]="nodeForm.get('conversionFactor').hasError('max') || nodeForm.get('conversionFactor').hasError('required') || nodeForm.get('conversionFactor').hasError('pattern') || nodeForm.get('conversionFactor').hasError('min') ? 'text-danger': 'text-normal'">
                                </div>
                                <span
                                    *ngIf="nodeForm.get('conversionFactor').hasError('min') || nodeForm.get('conversionFactor').hasError('pattern') || nodeForm.get('conversionFactor').hasError('max')"
                                    style=color:red>Only Positive Numders are allowed </span>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="section">
            <div class="col-md-12 col-lg-12">

                <div class="row">

                    <div class="col-md-6 col-lg-6 row">
                        <div class="col-3">
                            Weight(lbs)
                        </div>
                        <div class="col-md-6 col-lg-6">
                            <div class="form-group">
                                <div class="form-group">
                                    <div class="form-group__text">
                                        <input matInput formControlName="weight" (ngModelChange)="validateWatts($event)"
                                            [ngClass]="weightErr || nodeForm.get('weight').hasError('required') || nodeForm.get('weight').hasError('pattern') || nodeForm.get('weight').hasError('min') ? 'text-danger': 'text-normal'">
                                    </div>
                                    <span
                                        *ngIf="nodeForm.get('weight').hasError('pattern') || nodeForm.get('weight').hasError('min') "
                                        style=color:red>Only Positive Integers are allowed </span>
                                    <span *ngIf="weightErr" style=color:red>Only Positive Numbers are allowed</span>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-6 row">
                        <div class="col-3">
                            Series
                        </div>
                        <div class="col-md-6 col-lg-6">
                            <div class="form-group select-ngx" [attr.disabled]="true">
                                <ngx-select-dropdown disabled="true" [multiple]="false"
                                    [ngClass]=" nodeForm.get('series').hasError('required') ? 
                                                      'ngx-select-danger ngx-dropdown-button': 'ngx-select-normal ngx-dropdown-button'" formControlName="series"
                                    [config]="config" [options]="seriesList">
                                </ngx-select-dropdown>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="section">
            <div class="col-md-12 col-lg-12">

                <div class="row">

                    <div class="col-md-6 col-lg-6 row">
                        <div class="col-3">
                            Boot Power
                        </div>
                        <div class="col-md-6 col-lg-6">
                            <div class="form-group">
                                <div class="form-group__text">
                                    <input matInput formControlName="maxBootPower"
                                        [ngClass]="nodeForm.get('maxBootPower').hasError('required') || nodeForm.get('maxBootPower').hasError('pattern') ? 'text-danger': 'text-normal'">
                                </div>
                                <span *ngIf="nodeForm.get('maxBootPower').hasError('pattern')" style=color:red>Only
                                    Positive Integers are allowed </span>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-6 row" *ngIf="isEdit && storageData.params?.legacyPlatform !== false
                        && storageData.params?.isNewCreated == null">
                        <div class="col-3">
                            Legacy Platform

                        </div>
                        <div class="col-md-6 col-lg-6">
                            <div class="form-group form-group--inline">
                                <label class="radio">
                                    <input type="radio" (change)="legacyPlatformRadioChange($event)" value="true"
                                        name="legacyPlatform" formControlName="legacyPlatform">
                                    <span class="radio__input"></span>
                                    <span class="radio__label">Yes</span>
                                </label>
                            </div>
                            <div class="form-group form-group--inline">
                                <label class="radio">
                                    <input type="radio" (change)="legacyPlatformRadioChange($event)" value="false"
                                        name="legacyPlatform" formControlName="legacyPlatform">
                                    <span class="radio__input"></span>
                                    <span class="radio__label">No</span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-6 col-lg-6">


                <div class="section" style="margin-top: -15px;">
                    <div class="col-md-12 col-lg-12">

                        <div class="row">

                            <div class="col-3">
                                MTBF Hours
                            </div>
                            <div class="col-md-6 col-lg-6 padding-set-input">
                                <div class="form-group">
                                    <div class="form-group__text">
                                        <input matInput formControlName="mtbf" (ngModelChange)="validateWatts($event)"
                                            [ngClass]="nodeForm.get('mtbf').hasError('pattern') || mtbfErr ? 'text-danger': 'text-normal'">
                                    </div>
                                    <span *ngIf="nodeForm.get('mtbf').hasError('pattern')" style=color:red>Only Positive
                                        Integers are allowed </span>
                                    <span *ngIf="mtbfErr" style=color:red>Only Non-Negative Integers are allowed</span>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div class="section">
                    <div class="col-md-12 col-lg-12">

                        <div class="row">

                            <div class="col-3">
                                Dedicated RAID Slot
                            </div>
                            <div class="col-md-6 col-lg-6 padding-set-input">
                                <div class="form-group">
                                    <div class="form-group__text">
                                        <input matInput formControlName="mezzSlots"
                                            [ngClass]="nodeForm.get('mezzSlots').hasError('required') || nodeForm.get('mezzSlots').hasError('pattern') ? 'text-danger': 'text-normal'">
                                    </div>
                                    <span *ngIf="nodeForm.get('mezzSlots').hasError('pattern')" style=color:red>Only
                                        Positive Integers are allowed</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="section">
                    <div class="col-md-12 col-lg-12">

                        <div class="row">
                            <div class="col-3">
                                End of Sale
                            </div>
                            <div class="col-md-6 col-lg-6">
                                <div class="form-group form-group--inline">
                                    <label class="radio">
                                        <input type="radio" value="1" name="discont" formControlName="discont">
                                        <span class="radio__input"></span>
                                        <span class="radio__label">Yes</span>
                                    </label>
                                </div>
                                <div class="form-group form-group--inline">
                                    <label class="radio">
                                        <input type="radio" value="0" name="discont" formControlName="discont">
                                        <span class="radio__input"></span>
                                        <span class="radio__label">No</span>
                                    </label>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-lg-6" style="
      margin-left: -22px;
  ">

                <div class="row padding_row">
                    <div class="col-md-12 col-lg-12 row">
                        <div class="col-md-3 col-lg-3 ">
                            Image Look up
                        </div>
                        <div class="col-md-6 col-lg-6">
                            <div class="form-group">
                                <input type="file" name="uploadfile" id="importImage" accept="image/*"
                                    style="display:none;" (change)="onImportImage($event)" />
                                <label for="importImage" class="btn btn--secondary import-project-btn">Browse
                                    Image</label>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="row padding_row">
                    <div class="col-md-12 col-lg-12 row" style="height: 170px;">

                        <img [src]="url" style="width: 275px;height: auto;margin: auto;"> <br />

                    </div>
                </div>

            </div>
        </div>

        <button mat-button type="button" (click)="imageUpload('closeModal')"
            [disabled]="pciSoltsErr || hdSoltsErr || IdleWattsErr || IdleMaxWattsErr || submitInProgress || !nodeForm.valid || mtbfErr || showWattErr || !selectedPid"
            class="btn btn--small " style="height: 24px;margin: 5px 20px 0 0;">Save & Close</button>
        <button mat-button type="button"
            [disabled]="pciSoltsErr || hdSoltsErr || IdleWattsErr || IdleMaxWattsErr ||submitInProgress || !nodeForm.valid || mtbfErr || showWattErr || !selectedPid"
            class="btn btn--small " style="height: 24px;margin: 5px 20px 0 0;"
            (click)="imageUpload('save')">Save</button>


    </div>
</form>