<!-- <div class="form-group">
    <div class="form-group__text">
        <input [(ngModel)]="searchValue" (ngModelChange)="onSearchValueChange($event)">
    </div>
</div>
<div class="dropdown__menu">
    <div>value1</div>
    <div>value1</div>
    <div>value1</div>
    <div>value1</div>
</div> -->
<div class="form-group form-group--inline dropdown" style="display: block;">
    <div class="form-group__text">
        <input [ngClass]=" !selectedPid?.trim() ? 'text-danger': 'text-normal'" 
        (blur)="onBlur($event)" (keyup)="onkeyup($event)"
        [(ngModel)]="selectedPid" (ngModelChange)="onSearchValueChange($event)"
        #toggleButton [disabled]="flag">
    </div>
    <div class="autocomplete" *ngIf="searchResultsBox" [ngClass]=" type == 'platform' ? 'autocomplete_platform': ''" style="width:100%;"  #menu>
        <div *ngIf="show || (pids.length == 0 && loading)" id="myInputautocomplete-list" class="autocomplete-items" style="max-height: 150px;
        overflow: scroll;
        border: 1px solid #d4d4d4;">
                                          <ng-container  *ngFor="let pid of pids">

            <div (click)="selectedPID(pid)">
                <a >{{pid.PID}}</a>
            </div>
            </ng-container>
            <div *ngIf="pids.length == 0 && !loading">
                No Results Found.
            </div>
            <div *ngIf="pids.length == 0 && loading">
                Loading..
            </div>
        </div>
      </div>
</div>