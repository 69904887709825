import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { v4 as uuidv4 } from "uuid";
import { HttpClient } from '@angular/common/http';
import { projectTemplate } from 'src/constants/configuration';
import { Router } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { BehaviorSubject } from 'rxjs';
import { ToasterService } from '../shared/components/toast/toast.service';
@Injectable({
  providedIn: 'root'
})
export class UcsSizerService {
  private loggedInUserId: string = JSON.parse(localStorage.getItem('userData'))?.mail && JSON.parse(localStorage.getItem('userData'))?.mail.split("@")[0] ? JSON.parse(localStorage.getItem('userData'))?.mail.split("@")[0] : "Admin";
  private SIZER_URL: string = 'getUserProjects';
  private projectFetched: boolean = false;
  // private SIZER_URL: string = 'http://localhost:3000/projects';
  private projectStatus$ = new BehaviorSubject(false);
  getProjectStatus() {
    return this.projectStatus$.asObservable();
  }
  setProjectStatus(status: boolean) {
    this.projectStatus$.next(status);
  }
  @BlockUI() blockUI: NgBlockUI;
  constructor(
    private apiService: ApiService,
    private http: HttpClient,
    private router: Router,
    private toast: ToasterService,
  ) { }
  clearItems() {
    let arr = [];
    for (let i = 0; i < localStorage.length; i++) {
      if (localStorage.key(i).includes('UCS.SizerProjects-')) {
        arr.push(localStorage.key(i));
      }
    }
    for (var i = 0; i < arr.length; i++) {
      localStorage.removeItem(arr[i]);
    }
  }
  setProjectData(p) {
    p.projectId = uuidv4();
    p.createdDate = new Date().toISOString();
    p.updatedDate = new Date().toISOString();
    p.powerUnitCost = "0.105";
    p.emissionFactor = projectTemplate.emissionFactor;
    p.powerCostMethod = projectTemplate.powerCostMethod;
    p.hasValidServerCounts = true;
    p.measurementUnit = "Imperial";
    p.power = projectTemplate.power;
    let platforms = ['rackServers', 'bladeServers', 'chassisList', 'invictaServers', 'fabricInterConnect', 'fabricExtender', 'mseriesList', 'fabrics'];
    for (let platform of platforms) {
      if (!p[`${platform}`]) {
        p[`${platform}`] = projectTemplate[`${platform}`];
      } else {
        for (let i = 0; i < p[`${platform}`].length; i++) {
          p[`${platform}`][i]['power'] = projectTemplate.power;
          if (!p[`${platform}`][i]['blades']) p[`${platform}`][i]['blades'] = [];
          if (!p[`${platform}`][i]['nodes']) p[`${platform}`][i]['nodes'] = [];
          p[`${platform}`][i]['seriesDisplayName'] = p[`${platform}`][i]['seriesName'];
          p[`${platform}`][i]['userProvidedName'] = p[`${platform}`][i]['displayName'];
        }
      }
    }
    localStorage.setItem(`UCS.SizerProjects-${p.projectId}`, JSON.stringify(p));
    localStorage.setItem("latestProjectId", p.projectId.toString());
    localStorage.setItem("UCS.GlobalMeasurmentUnit", p.measurementUnit);
    sessionStorage.setItem("myProjectId", p.projectId.toString());
  }
  fetchProjects() {
    let apiURL = `${this.SIZER_URL}/${this.loggedInUserId}`;
    // let apiURL = this.SIZER_URL;
    this.apiService.getMethod(apiURL).subscribe(response => {
      // this.http.get(apiURL).subscribe(response => {
      let projects = response as any[];
      this.clearItems();
      setTimeout(() => {
        projects.forEach(prj => {
          this.setProjectData(prj);
        });
        let sizerProjectsList = projects.map(prj => prj.projectId).join(',');
        localStorage.setItem("UCS.SizerProjects", sizerProjectsList);
      }, 100);
    });
  }
  fetchProjectByUserIdAndProjectName(userId: string, projectName: string) {
    if (!this.projectFetched) {
      this.blockUI.start();
      let apiURL = `getUserProject/${userId}/${projectName}`;
      this.apiService.getMethod(apiURL).subscribe(response => {
        let project = response as any;
        this.projectFetched = true;
        this.clearItems();
        setTimeout(() => {
          this.setProjectData(project);
          this.setProjectStatus(true);
          localStorage.setItem("UCS.SizerProjects", project.projectId);
          this.router.navigate(['/ucs-resizer/projectDetails', userId, projectName]);
          this.blockUI.stop();
        }, 50);
      }, (error) => {
        console.error(error);
        this.blockUI.stop();
        this.toast.show("error", "Error Fetching Re-Sizer Projects", "Encountered Error while Fetching Projects", 5000);
        this.router.navigate(["/projectList"]);
      })
    }
  }
}
