export let projectTemplate = {
   projectName: "",
   measurementUnit: "Imperial",
   powerCostMethod: "Manual",
   power: {
      systemWorkloadPercent: 0,
      idleInputPower: 0,
      maxInputPower: 0,
      inputPower: 0,
      inputCurrentIdle: 0,
      inputCurrentMax: 0,
      inputCurrentLoad: 0,
      inputCurrent: 0,
      airflowMetric: 0,
      airflowImperial: 0,
      coolingMetric: 0,
      coolingImperial: 0,
      weightMetric: 0,
      weightImperial: 0,
      imperial: {
         powerUnit: "W",
         systemWorkload: "%",
         currentUnit: "A",
         airflowUnit: "CFM",
         coolingUnit: "BTU/hr",
         weightUnit: "lbs"
      },
      metric: {
         powerUnit: "W",
         systemWorkload: "%",
         currentUnit: "A",
         airflowUnit: "m&sup3;/s",
         coolingUnit: "W",
         weightUnit: "kg"
      },
      redundancyStatus: {
         idleStatus: {
            powerSupplyStatus: "good"
         },
         maxStatus: {
            powerSupplyStatus: "good",
            powerCapped: false,
            powerCappedAt: "max"
         },
         loadStatus: {
            powerSupplyStatus: "good",
            powerCapped: false
         }
      }
   },
   powerUnitCost: "",
   emissionFactor: "399",
   country: "",
   createdDate: "",
   updatedDate: "",
   hasValidServerCounts: true,
   projectId: "",

   rackServers: [],
   bladeServers: [],
   chassisList: [],
   invictaServers: [],
   fabricInterConnect: [],
   fabricExtender: [],
   mseriesList: [],
   fabrics: []
}

export let currentServer = {
   "serverId": "1",
   "displayName": "Cisco 5108 Blade Chassis (AC)  V2",
   "projectReferenceId": "1",
   "serverType": "bseries",
   "type": "CHASSIS",
   "pid": "UCSB-5108-AC2",
   "index": 0,
   "id": 78,
   "quantity": 1,
   "userProvidedName": "Cisco 5108 Blade Chassis (AC)  V2",
   "config": {
      "quantity": 1,
      "hasValidConfig": true,
      "systemWorkloadPercent": 0,
      "redundancyMode": "N",
      "inputVoltageId": 7,
      "voltageName": "220 VAC",
      "voltageValue": "220VAC",
      "powerSupplyId": 35,
      "powerSupplyTypeName": "2500W PSU DV",
      "powerSupplyCount": 4,
      "iom": 789,
      "ioModuleTypeName": "2104",
      "expansionCards": [

      ],
      "expansionQuantity": 0,
      "activePorts": 0,
      "processorId": 0,
      "processorTypeName": "",
      "processorCount": 0,
      "mezzanineControllerCount": 0,
      "mezzanineControllerTypeName": "",
      "adapterQuantity": 0,
      "storageQuantity": 0,
      "memoryQuantity": 0,
      "memory": [

      ],
      "storage": [

      ],
      "adaptors": [

      ],
      "iomCount": 2
   },
   "power": {
      "legacyPlatform": true,
      "index": 1,
      "idleInputPower": 156.17785262242987,
      "maxInputPower": 276.4044475040157,
      "systemWorkloadPercent": 0,
      "inputPower": 156.17785262242987,
      "inputCurrentIdle": 0.9521948253641038,
      "inputCurrentMax": 1.6026285439554724,
      "inputCurrentLoad": 0.9521948253641038,
      "airflowMetric": 0.008707466128892111,
      "airflowImperial": 18.45007583118692,
      "coolingMetric": 156.17785262242987,
      "coolingImperial": 532.9009526169976,
      "weightMetric": 49.89512,
      "weightImperial": 110,
      "idleCooling": {
         "metricCoolingWatts": 156.17785262242987,
         "imperialCooling": 532.9009526169976
      },
      "idleAirflow": {
         "airflowMetric": 0.008707466128892111,
         "airflowImperial": 18.45007583118692,
         "minAirflowMetric": 0,
         "minAirflowImperial": 0,
         "maxAirflowMetric": 0,
         "maxAirflowImperial": 0
      },
      "inputCooling": {
         "metricCoolingWatts": 156.17785262242987,
         "imperialCooling": 532.9009526169976
      },
      "inputAirflow": {
         "airflowMetric": 0.008707466128892111,
         "airflowImperial": 18.45007583118692,
         "minAirflowMetric": 0,
         "minAirflowImperial": 0,
         "maxAirflowMetric": 0,
         "maxAirflowImperial": 0
      },
      "maxCooling": {
         "metricCoolingWatts": 276.4044475040157,
         "imperialCooling": 943.1311220456015
      },
      "maxAirflow": {
         "airflowMetric": 0.015410522837286717,
         "airflowImperial": 32.65304862947008,
         "minAirflowMetric": 0,
         "minAirflowImperial": 0,
         "maxAirflowMetric": 0,
         "maxAirflowImperial": 0
      },
      "redundancyStatus": {
         "idleStatus": {
            "powerSupplyStatus": "good"
         },
         "maxStatus": {
            "powerSupplyStatus": "good"
         },
         "loadStatus": {
            "powerSupplyStatus": "good"
         }
      },
      "imperial": {
         "powerUnit": "W",
         "systemWorkload": "%",
         "currentUnit": "A",
         "airflowUnit": "cfm",
         "coolingUnit": "BTU/hr",
         "weightUnit": "lbs",
         "acousticsUnit": "dBA"
      },
      "metric": {
         "powerUnit": "W",
         "systemWorkload": "%",
         "currentUnit": "A",
         "airflowUnit": "m³/s",
         "coolingUnit": "W",
         "weightUnit": "kgs",
         "acousticsUnit": "dBA"
      },
      "seriesName": "B-Series Blade Servers",
      "level": "Parent",
      "type": "CHASSIS",
      "hasChildren": true
   },
   "blades": [

   ],
   "nodes": [

   ],
   "maxBladeSlots": 8,
   "maxCartridgeSlots": 0,
   "seriesDisplayName": "B-Series Blade Servers",
   "level": "Parent",
   "hasChildren": true
};
export let projectConfig = {
   "projectName": "Ucs-POWER-PROJECT_1",
   "measurementUnit": "Metric",
   "power": {
      "systemWorkloadPercent": 0,
      "idleInputPower": 0,
      "maxInputPower": 0,
      "inputPower": 0,
      "inputCurrentIdle": 0,
      "inputCurrentMax": 0,
      "inputCurrentLoad": 0,
      "inputCurrent": 0,
      "airflowMetric": 0,
      "airflowImperial": 0,
      "coolingMetric": 0,
      "coolingImperial": 0,
      "weightMetric": 0,
      "weightImperial": 0,
      "imperial": {
         "powerUnit": "W",
         "systemWorkload": "%",
         "currentUnit": "A",
         "airflowUnit": "CFM",
         "coolingUnit": "BTU/hr",
         "weightUnit": "lbs"
      },
      "metric": {
         "powerUnit": "W",
         "systemWorkload": "%",
         "currentUnit": "A",
         "airflowUnit": "m&sup3;/s",
         "coolingUnit": "W",
         "weightUnit": "kg"
      },
      "redundancyStatus": {
         "idleStatus": {
            "powerSupplyStatus": "good"
         },
         "maxStatus": {
            "powerSupplyStatus": "good",
            "powerCapped": false,
            "powerCappedAt": "max"
         },
         "loadStatus": {
            "powerSupplyStatus": "good",
            "powerCapped": false
         }
      }
   },
   "powerUnitCost": 42,
   "createdDate": "2022-03-17T11:15:31.052Z",
   "updatedDate": "2022-03-17T11:15:31.052Z",
   "hasValidServerCounts": true,
   "projectId": "1",
   "rackServers": [

   ],
   "bladeServers": [

   ],
   "chassisList": [

   ],
   "invictaServers": [

   ],
   "fabricInterConnect": [

   ],
   "fabricExtender": [

   ],
   "mseriesList": [

   ],
   "fabrics": [

   ]
}