import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { Subscription } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';
import { DialogData } from 'src/app/shared/components/edit-render/edit-render.component';
import { ToasterService } from 'src/app/shared/components/toast/toast.service';
import { errorCodes } from 'src/constants/errorCodes';
import { UrlConstant } from 'src/constants/url.constants';

@Component({
  selector: 'app-create-fanpolicy',
  templateUrl: './create-fanpolicy.component.html',
  styleUrls: ['./create-fanpolicy.component.css']
})
export class CreateFanpolicyComponent implements OnInit {
  lblMandatoryMsg = "";
  lblSuccessMsg = "";
  @BlockUI() blockUI: NgBlockUI;
  subscriptions: Subscription = new Subscription();

  showPID = true;
  selectedPid: any = null;
  dropdownSettings = {
    singleSelection: false,
    text: "Select Policies",
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    enableSearchFilter: false,
    disabled: false,
  };
  //errorFlag: boolean = false;
  // selectedTabId = 1;
  // acousticFlag: boolean = false;
  // balancedFlag: boolean = false;
  // maximumFlag: boolean = false;
  // highpowerFlag: boolean = false;
  // lowpowerFlag: boolean = false;
  // fanfailureFlag: boolean = false;
  policyForm: UntypedFormGroup;
  fanPolicies = [
    { id: 1, itemName: "Acoustic" },
    { id: 2, itemName: "Balanced" },
    { id: 3, itemName: "High-Power" },
    { id: 4, itemName: "Fan-Failure" },
    { id: 5, itemName: "Low-Power" },
    { id: 6, itemName: "Maximum" }
  ];
  selectedPolicy = [];
  errorMsg: any;
  updateFlag: boolean = false;
  isRecordCreated: boolean = false;
  constructor(public dialogRef: MatDialogRef<CreateFanpolicyComponent>,
    private formBuilder: UntypedFormBuilder,
    private apiService: ApiService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private toaster: ToasterService) { }

  ngOnInit(): void {


    this.policyForm = this.formBuilder.group({
      policy: new UntypedFormControl('', [Validators.required,]),

      a20pwm: new UntypedFormControl('', [Validators.pattern("^\\d*\\.?\\d+$"), Validators.required, Validators.min(0.00000000001)]),
      a20power: new UntypedFormControl('', [Validators.pattern("^\\d*\\.?\\d+$"), Validators.required, Validators.min(0.00000000001), Validators.min(0.00000000001)]),
      a20airflow: new UntypedFormControl('', [Validators.pattern("^\\d*\\.?\\d+$"), Validators.required, Validators.min(0.00000000001)]),
      a20sound: new UntypedFormControl('', [Validators.pattern("^\\d*\\.?\\d+$"), Validators.required, Validators.min(0.00000000001)]),

      a21pwm: new UntypedFormControl('', [Validators.pattern("^\\d*\\.?\\d+$"), Validators.required, Validators.min(0.00000000001)]),
      a21power: new UntypedFormControl('', [Validators.pattern("^\\d*\\.?\\d+$"), Validators.required, Validators.min(0.00000000001)]),
      a21airflow: new UntypedFormControl('', [Validators.pattern("^\\d*\\.?\\d+$"), Validators.required, Validators.min(0.00000000001)]),
      a21sound: new UntypedFormControl('', [Validators.pattern("^\\d*\\.?\\d+$"), Validators.required, Validators.min(0.00000000001)]),

      a22pwm: new UntypedFormControl('', [Validators.pattern("^\\d*\\.?\\d+$"), Validators.required, Validators.min(0.00000000001)]),
      a22power: new UntypedFormControl('', [Validators.pattern("^\\d*\\.?\\d+$"), Validators.required, Validators.min(0.00000000001)]),
      a22airflow: new UntypedFormControl('', [Validators.pattern("^\\d*\\.?\\d+$"), Validators.required, Validators.min(0.00000000001)]),
      a22sound: new UntypedFormControl('', [Validators.pattern("^\\d*\\.?\\d+$"), Validators.required, Validators.min(0.00000000001)]),

      a23pwm: new UntypedFormControl('', [Validators.pattern("^\\d*\\.?\\d+$"), Validators.required, Validators.min(0.00000000001)]),
      a23power: new UntypedFormControl('', [Validators.pattern("^\\d*\\.?\\d+$"), Validators.required, Validators.min(0.00000000001)]),
      a23airflow: new UntypedFormControl('', [Validators.pattern("^\\d*\\.?\\d+$"), Validators.required, Validators.min(0.00000000001)]),
      a23sound: new UntypedFormControl('', [Validators.pattern("^\\d*\\.?\\d+$"), Validators.required, Validators.min(0.00000000001)]),

      a24pwm: new UntypedFormControl('', [Validators.pattern("^\\d*\\.?\\d+$"), Validators.required, Validators.min(0.00000000001)]),
      a24power: new UntypedFormControl('', [Validators.pattern("^\\d*\\.?\\d+$"), Validators.required, Validators.min(0.00000000001)]),
      a24airflow: new UntypedFormControl('', [Validators.pattern("^\\d*\\.?\\d+$"), Validators.required, Validators.min(0.00000000001)]),
      a24sound: new UntypedFormControl('', [Validators.pattern("^\\d*\\.?\\d+$"), Validators.required, Validators.min(0.00000000001)]),

      a25pwm: new UntypedFormControl('', [Validators.pattern("^\\d*\\.?\\d+$"), Validators.required, Validators.min(0.00000000001)]),
      a25power: new UntypedFormControl('', [Validators.pattern("^\\d*\\.?\\d+$"), Validators.required, Validators.min(0.00000000001)]),
      a25airflow: new UntypedFormControl('', [Validators.pattern("^\\d*\\.?\\d+$"), Validators.required, Validators.min(0.00000000001)]),
      a25sound: new UntypedFormControl('', [Validators.pattern("^\\d*\\.?\\d+$"), Validators.required, Validators.min(0.00000000001)]),

      a26pwm: new UntypedFormControl('', [Validators.pattern("^\\d*\\.?\\d+$"), Validators.required, Validators.min(0.00000000001)]),
      a26power: new UntypedFormControl('', [Validators.pattern("^\\d*\\.?\\d+$"), Validators.required, Validators.min(0.00000000001)]),
      a26airflow: new UntypedFormControl('', [Validators.pattern("^\\d*\\.?\\d+$"), Validators.required, Validators.min(0.00000000001)]),
      a26sound: new UntypedFormControl('', [Validators.pattern("^\\d*\\.?\\d+$"), Validators.required, Validators.min(0.00000000001)]),

      a27pwm: new UntypedFormControl('', [Validators.pattern("^\\d*\\.?\\d+$"), Validators.required, Validators.min(0.00000000001)]),
      a27power: new UntypedFormControl('', [Validators.pattern("^\\d*\\.?\\d+$"), Validators.required, Validators.min(0.00000000001)]),
      a27airflow: new UntypedFormControl('', [Validators.pattern("^\\d*\\.?\\d+$"), Validators.required, Validators.min(0.00000000001)]),
      a27sound: new UntypedFormControl('', [Validators.pattern("^\\d*\\.?\\d+$"), Validators.required, Validators.min(0.00000000001)]),

      a28pwm: new UntypedFormControl('', [Validators.pattern("^\\d*\\.?\\d+$"), Validators.required, Validators.min(0.00000000001)]),
      a28power: new UntypedFormControl('', [Validators.pattern("^\\d*\\.?\\d+$"), Validators.required, Validators.min(0.00000000001)]),
      a28airflow: new UntypedFormControl('', [Validators.pattern("^\\d*\\.?\\d+$"), Validators.required, Validators.min(0.00000000001)]),
      a28sound: new UntypedFormControl('', [Validators.pattern("^\\d*\\.?\\d+$"), Validators.required, Validators.min(0.00000000001)]),

      a29pwm: new UntypedFormControl('', [Validators.pattern("^\\d*\\.?\\d+$"), Validators.required, Validators.min(0.00000000001)]),
      a29power: new UntypedFormControl('', [Validators.pattern("^\\d*\\.?\\d+$"), Validators.required, Validators.min(0.00000000001)]),
      a29airflow: new UntypedFormControl('', [Validators.pattern("^\\d*\\.?\\d+$"), Validators.required, Validators.min(0.00000000001)]),
      a29sound: new UntypedFormControl('', [Validators.pattern("^\\d*\\.?\\d+$"), Validators.required, Validators.min(0.00000000001)]),

      a30pwm: new UntypedFormControl('', [Validators.pattern("^\\d*\\.?\\d+$"), Validators.required, Validators.min(0.00000000001)]),
      a30power: new UntypedFormControl('', [Validators.pattern("^\\d*\\.?\\d+$"), Validators.required, Validators.min(0.00000000001)]),
      a30airflow: new UntypedFormControl('', [Validators.pattern("^\\d*\\.?\\d+$"), Validators.required, Validators.min(0.00000000001)]),
      a30sound: new UntypedFormControl('', [Validators.pattern("^\\d*\\.?\\d+$"), Validators.required, Validators.min(0.00000000001)]),

      a31pwm: new UntypedFormControl('', [Validators.pattern("^\\d*\\.?\\d+$"), Validators.required, Validators.min(0.00000000001)]),
      a31power: new UntypedFormControl('', [Validators.pattern("^\\d*\\.?\\d+$"), Validators.required, Validators.min(0.00000000001)]),
      a31airflow: new UntypedFormControl('', [Validators.pattern("^\\d*\\.?\\d+$"), Validators.required, Validators.min(0.00000000001)]),
      a31sound: new UntypedFormControl('', [Validators.pattern("^\\d*\\.?\\d+$"), Validators.required, Validators.min(0.00000000001)]),

      a32pwm: new UntypedFormControl('', [Validators.pattern("^\\d*\\.?\\d+$"), Validators.required, Validators.min(0.00000000001)]),
      a32power: new UntypedFormControl('', [Validators.pattern("^\\d*\\.?\\d+$"), Validators.required, Validators.min(0.00000000001)]),
      a32airflow: new UntypedFormControl('', [Validators.pattern("^\\d*\\.?\\d+$"), Validators.required, Validators.min(0.00000000001)]),
      a32sound: new UntypedFormControl('', [Validators.pattern("^\\d*\\.?\\d+$"), Validators.required, Validators.min(0.00000000001)]),

      a33pwm: new UntypedFormControl('', [Validators.pattern("^\\d*\\.?\\d+$"), Validators.required, Validators.min(0.00000000001)]),
      a33power: new UntypedFormControl('', [Validators.pattern("^\\d*\\.?\\d+$"), Validators.required, Validators.min(0.00000000001)]),
      a33airflow: new UntypedFormControl('', [Validators.pattern("^\\d*\\.?\\d+$"), Validators.required, Validators.min(0.00000000001)]),
      a33sound: new UntypedFormControl('', [Validators.pattern("^\\d*\\.?\\d+$"), Validators.required, Validators.min(0.00000000001)]),

      a34pwm: new UntypedFormControl('', [Validators.pattern("^\\d*\\.?\\d+$"), Validators.required, Validators.min(0.00000000001)]),
      a34power: new UntypedFormControl('', [Validators.pattern("^\\d*\\.?\\d+$"), Validators.required, Validators.min(0.00000000001)]),
      a34airflow: new UntypedFormControl('', [Validators.pattern("^\\d*\\.?\\d+$"), Validators.required, Validators.min(0.00000000001)]),
      a34sound: new UntypedFormControl('', [Validators.pattern("^\\d*\\.?\\d+$"), Validators.required, Validators.min(0.00000000001)]),

      a35pwm: new UntypedFormControl('', [Validators.pattern("^\\d*\\.?\\d+$"), Validators.required, Validators.min(0.00000000001)]),
      a35power: new UntypedFormControl('', [Validators.pattern("^\\d*\\.?\\d+$"), Validators.required, Validators.min(0.00000000001)]),
      a35airflow: new UntypedFormControl('', [Validators.pattern("^\\d*\\.?\\d+$"), Validators.required, Validators.min(0.00000000001)]),
      a35sound: new UntypedFormControl('', [Validators.pattern("^\\d*\\.?\\d+$"), Validators.required, Validators.min(0.00000000001)]),

      a36pwm: new UntypedFormControl('', [Validators.pattern("^\\d*\\.?\\d+$"), Validators.required, Validators.min(0.00000000001)]),
      a36power: new UntypedFormControl('', [Validators.pattern("^\\d*\\.?\\d+$"), Validators.required, Validators.min(0.00000000001)]),
      a36airflow: new UntypedFormControl('', [Validators.pattern("^\\d*\\.?\\d+$"), Validators.required, Validators.min(0.00000000001)]),
      a36sound: new UntypedFormControl('', [Validators.pattern("^\\d*\\.?\\d+$"), Validators.required, Validators.min(0.00000000001)]),

      a37pwm: new UntypedFormControl('', [Validators.pattern("^\\d*\\.?\\d+$"), Validators.required, Validators.min(0.00000000001)]),
      a37power: new UntypedFormControl('', [Validators.pattern("^\\d*\\.?\\d+$"), Validators.required, Validators.min(0.00000000001)]),
      a37airflow: new UntypedFormControl('', [Validators.pattern("^\\d*\\.?\\d+$"), Validators.required, Validators.min(0.00000000001)]),
      a37sound: new UntypedFormControl('', [Validators.pattern("^\\d*\\.?\\d+$"), Validators.required, Validators.min(0.00000000001)]),

      a38pwm: new UntypedFormControl('', [Validators.pattern("^\\d*\\.?\\d+$"), Validators.required, Validators.min(0.00000000001)]),
      a38power: new UntypedFormControl('', [Validators.pattern("^\\d*\\.?\\d+$"), Validators.required, Validators.min(0.00000000001)]),
      a38airflow: new UntypedFormControl('', [Validators.pattern("^\\d*\\.?\\d+$"), Validators.required, Validators.min(0.00000000001)]),
      a38sound: new UntypedFormControl('', [Validators.pattern("^\\d*\\.?\\d+$"), Validators.required, Validators.min(0.00000000001)]),

      a39pwm: new UntypedFormControl('', [Validators.pattern("^\\d*\\.?\\d+$"), Validators.required, Validators.min(0.00000000001)]),
      a39power: new UntypedFormControl('', [Validators.pattern("^\\d*\\.?\\d+$"), Validators.required, Validators.min(0.00000000001)]),
      a39airflow: new UntypedFormControl('', [Validators.pattern("^\\d*\\.?\\d+$"), Validators.required, Validators.min(0.00000000001)]),
      a39sound: new UntypedFormControl('', [Validators.pattern("^\\d*\\.?\\d+$"), Validators.required, Validators.min(0.00000000001)]),

      a40pwm: new UntypedFormControl('', [Validators.pattern("^\\d*\\.?\\d+$"), Validators.required, Validators.min(0.00000000001)]),
      a40power: new UntypedFormControl('', [Validators.pattern("^\\d*\\.?\\d+$"), Validators.required, Validators.min(0.00000000001)]),
      a40airflow: new UntypedFormControl('', [Validators.pattern("^\\d*\\.?\\d+$"), Validators.required, Validators.min(0.00000000001)]),
      a40sound: new UntypedFormControl('', [Validators.pattern("^\\d*\\.?\\d+$"), Validators.required, Validators.min(0.00000000001)]),

      a41pwm: new UntypedFormControl('', [Validators.pattern("^\\d*\\.?\\d+$"), Validators.required, Validators.min(0.00000000001)]),
      a41power: new UntypedFormControl('', [Validators.pattern("^\\d*\\.?\\d+$"), Validators.required, Validators.min(0.00000000001)]),
      a41airflow: new UntypedFormControl('', [Validators.pattern("^\\d*\\.?\\d+$"), Validators.required, Validators.min(0.00000000001)]),
      a41sound: new UntypedFormControl('', [Validators.pattern("^\\d*\\.?\\d+$"), Validators.required, Validators.min(0.00000000001)]),

      a42pwm: new UntypedFormControl('', [Validators.pattern("^\\d*\\.?\\d+$"), Validators.required, Validators.min(0.00000000001)]),
      a42power: new UntypedFormControl('', [Validators.pattern("^\\d*\\.?\\d+$"), Validators.required, Validators.min(0.00000000001)]),
      a42airflow: new UntypedFormControl('', [Validators.pattern("^\\d*\\.?\\d+$"), Validators.required, Validators.min(0.00000000001)]),
      a42sound: new UntypedFormControl('', [Validators.pattern("^\\d*\\.?\\d+$"), Validators.required, Validators.min(0.00000000001)]),

    })
    if (this.data.params !== null && this.data.params !== '') {
      this.updateForm(this.data);
    }
  }
  updateForm(data: DialogData) {
    this.updateFlag = true;
    this.selectedPid = data?.params?.data?.pid;
    this.selectedPolicy = this.fanPolicies.filter(x => { return x.itemName == data?.params?.data?.policy });
    this.dropdownSettings['disabled'] = true;
    this.policyForm.get('a20pwm').patchValue(data?.params?.data?.a20pwm);
    this.policyForm.get('a20power').patchValue(data?.params?.data?.a20power);
    this.policyForm.get('a20airflow').patchValue(data?.params?.data?.a20airflow);
    this.policyForm.get('a20sound').patchValue(data?.params?.data?.a20sound);

    this.policyForm.get('a21pwm').patchValue(data?.params?.data?.a21pwm);
    this.policyForm.get('a21power').patchValue(data?.params?.data?.a21power);
    this.policyForm.get('a21airflow').patchValue(data?.params?.data?.a21airflow);
    this.policyForm.get('a21sound').patchValue(data?.params?.data?.a21sound);

    this.policyForm.get('a22pwm').patchValue(data?.params?.data?.a22pwm);
    this.policyForm.get('a22power').patchValue(data?.params?.data?.a22power);
    this.policyForm.get('a22airflow').patchValue(data?.params?.data?.a22airflow);
    this.policyForm.get('a22sound').patchValue(data?.params?.data?.a22sound);

    this.policyForm.get('a23pwm').patchValue(data?.params?.data?.a23pwm);
    this.policyForm.get('a23power').patchValue(data?.params?.data?.a23power);
    this.policyForm.get('a23airflow').patchValue(data?.params?.data?.a23airflow);
    this.policyForm.get('a23sound').patchValue(data?.params?.data?.a23sound);

    this.policyForm.get('a24pwm').patchValue(data?.params?.data?.a24pwm);
    this.policyForm.get('a24power').patchValue(data?.params?.data?.a24power);
    this.policyForm.get('a24airflow').patchValue(data?.params?.data?.a24airflow);
    this.policyForm.get('a24sound').patchValue(data?.params?.data?.a24sound);

    this.policyForm.get('a25pwm').patchValue(data?.params?.data?.a25pwm);
    this.policyForm.get('a25power').patchValue(data?.params?.data?.a25power);
    this.policyForm.get('a25airflow').patchValue(data?.params?.data?.a25airflow);
    this.policyForm.get('a25sound').patchValue(data?.params?.data?.a25sound);

    this.policyForm.get('a26pwm').patchValue(data?.params?.data?.a26pwm);
    this.policyForm.get('a26power').patchValue(data?.params?.data?.a26power);
    this.policyForm.get('a26airflow').patchValue(data?.params?.data?.a26airflow);
    this.policyForm.get('a26sound').patchValue(data?.params?.data?.a26sound);

    this.policyForm.get('a27pwm').patchValue(data?.params?.data?.a27pwm);
    this.policyForm.get('a27power').patchValue(data?.params?.data?.a27power);
    this.policyForm.get('a27airflow').patchValue(data?.params?.data?.a27airflow);
    this.policyForm.get('a27sound').patchValue(data?.params?.data?.a27sound);

    this.policyForm.get('a28pwm').patchValue(data?.params?.data?.a28pwm);
    this.policyForm.get('a28power').patchValue(data?.params?.data?.a28power);
    this.policyForm.get('a28airflow').patchValue(data?.params?.data?.a28airflow);
    this.policyForm.get('a28sound').patchValue(data?.params?.data?.a28sound);

    this.policyForm.get('a29pwm').patchValue(data?.params?.data?.a29pwm);
    this.policyForm.get('a29power').patchValue(data?.params?.data?.a29power);
    this.policyForm.get('a29airflow').patchValue(data?.params?.data?.a29airflow);
    this.policyForm.get('a29sound').patchValue(data?.params?.data?.a29sound);

    this.policyForm.get('a30pwm').patchValue(data?.params?.data?.a30pwm);
    this.policyForm.get('a30power').patchValue(data?.params?.data?.a30power);
    this.policyForm.get('a30airflow').patchValue(data?.params?.data?.a30airflow);
    this.policyForm.get('a30sound').patchValue(data?.params?.data?.a30sound);

    this.policyForm.get('a31pwm').patchValue(data?.params?.data?.a31pwm);
    this.policyForm.get('a31power').patchValue(data?.params?.data?.a31power);
    this.policyForm.get('a31airflow').patchValue(data?.params?.data?.a31airflow);
    this.policyForm.get('a31sound').patchValue(data?.params?.data?.a31sound);

    this.policyForm.get('a32pwm').patchValue(data?.params?.data?.a32pwm);
    this.policyForm.get('a32power').patchValue(data?.params?.data?.a32power);
    this.policyForm.get('a32airflow').patchValue(data?.params?.data?.a32airflow);
    this.policyForm.get('a32sound').patchValue(data?.params?.data?.a32sound);

    this.policyForm.get('a33pwm').patchValue(data?.params?.data?.a20pwm);
    this.policyForm.get('a33power').patchValue(data?.params?.data?.a20power);
    this.policyForm.get('a33airflow').patchValue(data?.params?.data?.a20airflow);
    this.policyForm.get('a33sound').patchValue(data?.params?.data?.a20sound);

    this.policyForm.get('a34pwm').patchValue(data?.params?.data?.a34pwm);
    this.policyForm.get('a34power').patchValue(data?.params?.data?.a34power);
    this.policyForm.get('a34airflow').patchValue(data?.params?.data?.a34airflow);
    this.policyForm.get('a34sound').patchValue(data?.params?.data?.a34sound);

    this.policyForm.get('a35pwm').patchValue(data?.params?.data?.a35pwm);
    this.policyForm.get('a35power').patchValue(data?.params?.data?.a35power);
    this.policyForm.get('a35airflow').patchValue(data?.params?.data?.a35airflow);
    this.policyForm.get('a35sound').patchValue(data?.params?.data?.a35sound);

    this.policyForm.get('a36pwm').patchValue(data?.params?.data?.a36pwm);
    this.policyForm.get('a36power').patchValue(data?.params?.data?.a36power);
    this.policyForm.get('a36airflow').patchValue(data?.params?.data?.a36airflow);
    this.policyForm.get('a36sound').patchValue(data?.params?.data?.a36sound);

    this.policyForm.get('a37pwm').patchValue(data?.params?.data?.a37pwm);
    this.policyForm.get('a37power').patchValue(data?.params?.data?.a37power);
    this.policyForm.get('a37airflow').patchValue(data?.params?.data?.a37airflow);
    this.policyForm.get('a37sound').patchValue(data?.params?.data?.a37sound);

    this.policyForm.get('a38pwm').patchValue(data?.params?.data?.a38pwm);
    this.policyForm.get('a38power').patchValue(data?.params?.data?.a38power);
    this.policyForm.get('a38airflow').patchValue(data?.params?.data?.a38airflow);
    this.policyForm.get('a38sound').patchValue(data?.params?.data?.a38sound);

    this.policyForm.get('a39pwm').patchValue(data?.params?.data?.a39pwm);
    this.policyForm.get('a39power').patchValue(data?.params?.data?.a39power);
    this.policyForm.get('a39airflow').patchValue(data?.params?.data?.a39airflow);
    this.policyForm.get('a39sound').patchValue(data?.params?.data?.a39sound);

    this.policyForm.get('a40pwm').patchValue(data?.params?.data?.a40pwm);
    this.policyForm.get('a40power').patchValue(data?.params?.data?.a40power);
    this.policyForm.get('a40airflow').patchValue(data?.params?.data?.a40airflow);
    this.policyForm.get('a40sound').patchValue(data?.params?.data?.a40sound);

    this.policyForm.get('a41pwm').patchValue(data?.params?.data?.a41pwm);
    this.policyForm.get('a41power').patchValue(data?.params?.data?.a41power);
    this.policyForm.get('a41airflow').patchValue(data?.params?.data?.a41airflow);
    this.policyForm.get('a41sound').patchValue(data?.params?.data?.a41sound);


    this.policyForm.get('a42pwm').patchValue(data?.params?.data?.a42pwm);
    this.policyForm.get('a42power').patchValue(data?.params?.data?.a42power);
    this.policyForm.get('a42airflow').patchValue(data?.params?.data?.a42airflow);
    this.policyForm.get('a42sound').patchValue(data?.params?.data?.a42sound);


  }
  public onCancel = () => {
    this.isRecordCreated ? this.dialogRef.close() : this.dialogRef.close('cancel');
  }

  selectedPID(e: any) {

    this.selectedPid = e;
  }

  onSave() {
    this.dataToSave('saveClose');
  }
  dataToSave(type: string) {
    if (this.policyForm.valid) {
      let object = this.policyForm.value;
      let { policy, ...exceptPolicy } = object;
      let payload;
      if (typeof (policy) !== 'string') {
        payload = policy.map((v) => { return { 'policy': v.itemName, ...exceptPolicy } });
      } else {
        payload = JSON.parse(JSON.stringify(exceptPolicy));
        payload['policy'] = policy
        payload = [payload]
      }
      let reqData = {
        "pid": this.selectedPid,
        "policyOpTempMap": payload
      }
      this.blockUI.start('Loading...');

      const url = UrlConstant.SAVE_FAN_POLICY;
      this.subscriptions.add(this.apiService.postMethod(url, reqData).subscribe((result: any) => {

        this.updateFlag ? this.toaster.show('success', errorCodes.UPDATE_RECORD, '') :
          this.toaster.show('success', errorCodes.NEW_RECORD, '');
        if (type == 'saveClose') {
          this.dialogRef.close();
        }
        this.isRecordCreated = true;
        this.blockUI.stop();
      }, error => {
        this.isRecordCreated = false;
        this.errorMsg = error?.error ? error.error?.error?.errorString : errorCodes.GENERIC_ERROR_MESSAGE;
        this.toaster.show('error', this.errorMsg, error ? error?.message : '');
        this.blockUI.stop();
      }));
    }
  }
  onNoClick(): void {
    this.dataToSave('save');
    if (!this.updateFlag) {
      this.resetForm();
    }
  }
  resetForm() {
    this.showPID = false;
    this.policyForm.reset();
    this.selectedPid = null;
    //search pid
    setTimeout(() => {
      this.showPID = true;
    }, 100);
  }

  onTabClick(index: number) {
    //this.selectedTabId = index;
  }
  onChange(event) {
    this.policyForm.get('policy').patchValue(event.map(x => x.name));
  }
  onItemSelect(item: any) {
  }
  OnItemDeSelect(item: any) {
  }
  onSelectAll(items: any) {
  }
  onDeSelectAll(items: any) {
  }
}
