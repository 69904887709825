<mat-card-title>
    <div mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle style="margin-bottom: 8px;">
        <h4 style="float: left;font-size: large;font-weight: 400;"> {{ data.header }} </h4>
        <div style="display:flex;
      flex-direction: row-reverse;">
            <div class="flex-center margin_auto_t_b">
                <a class="anchor-close" id="close" title="Close" (click)="onCancel()">
                    <span class="icon-close icon-size-24 icon-close"></span>
                </a>
            </div>
        </div>
    </div>
</mat-card-title>

<mat-dialog-content>
    <mat-form-field>
        <div class="form-group base-margin-bottom" style="max-width:800px; min-width:500px; min-height: 150px;">
            <div class="row base-margin-bottom">
                <div class="col-4">
                    Short Description
                </div>
                <div class="col-8" style="display: flex;">
                    <input matInput [(ngModel)]="shortDescription"
                        [ngClass]="shortDescription.trim()=='' ? 'text-danger' : 'text-normal'" style="flex:auto"
                        required>
                </div>
            </div>
            <div class="row base-margin-bottom">
                <div class="col-4">
                    Description
                </div>
                <div class="col-8" style="display: flex;">
                    <textarea [(ngModel)]="description"
                        [ngClass]="description.trim()=='' ? 'text-danger' : 'text-normal'" style="flex:auto" rows="5"
                        required></textarea>
                </div>
            </div>
            <div class="row base-margin-bottom">
                <div class="col-4">
                    Comments
                </div>
                <div class="col-8" style="display: flex;">
                    <input matInput [(ngModel)]="comments" style="flex:auto">
                </div>
            </div>
        </div>
    </mat-form-field>
</mat-dialog-content>


<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close class="btn btn--small" (click)="onRaiseCase()"
        [disabled]="shortDescription.trim()==''||description.trim()==''">Raise Case</button>
    <button mat-button class="btn btn--small btn--danger" cdkFocusInitial (click)="onCancel()">Cancel</button>
</mat-dialog-actions>