<div class="container project-summary-container ">
    <div class="row project-summary-mainRow">
        <div [ngClass]="isUserLoggedIn ? 'col-md-3' : 'col-md-4'">
            <div class="row project-summary-row form-group">

                <div class=" project-name form-group__text">
                    <div  *ngIf="!isEdit"><h5 style="word-break: break-word;">{{projectData?.projectName}}</h5></div>
                    <div><input id="projectName" type="text" style="width: 300px;" maxlength="50"
                    [(ngModel)]="projectData.projectName"  [ngClass]="(projectData.projectName != '') ?  'select-normal' : 'select-danger'"
                    *ngIf="isEdit" (blur)="onBlurName($event)"
                    (change)="changeProjectName($event)"></div>

                   <div style="margin-left: 10px;" ><a ><span (click)="toggleEdit()"
                        class="icon-edit icon-size-20" style="margin: 5px;color:#00bceb"
                        ></span></a></div> 
                </div>
            </div>
            <div class="row">
                <div class="col-md-6 createdOn-div">
                    {{'CREATED_ON' | translate}}: <label>{{createDate}}</label>
                </div>
                <div class="col-md-6 last-updated">
                    {{'LAST_UPDATED' | translate}}: <label>{{updateDate}}</label>
                </div>
            </div>

        </div>
        <div class="col-md-2 power-container-div">

            <div class="col-md-6 power-div">
                <p>{{'POWER' | translate}}</p>
                <label>{{ (power !== null) ? (power | number : '1.2-2') : "" }} W</label>
            </div>
            <div class="col-md-6 current-div">
                <p>{{'CURRENT' | translate}}</p>
                <label>{{ (current !== null) ?  (current | number : '1.2-2') : "" }} A</label>

            </div>
        </div>

        <div class="col-md-1 div-global-unit">
            <div class="row global-unit">
                <div class=" col-md-12 form-group form-group--inline" style="font-size: medium;top:0.1rem">
                    Units
                </div>
                <div class="col-md-12 form-group form-group--inline div-imperial" style="top:-0.3rem">
                    <label class="radio">
                        <input type="radio" [checked]="(globalUnit == 'Imperial') ? true : false" name="radio-inline"
                            (change)="globalUnitChange('Imperial')">
                        <span class="radio__input"></span>
                        <span class="radio__label">{{'IMPERIAL' | translate}}</span>
                    </label>
                </div>
                <div class="col-md-12 form-group form-group--inline div-metric">
                    <label class="radio">
                        <input type="radio" [checked]="(globalUnit == 'Metric') ? true : false" name="radio-inline"
                            (change)="globalUnitChange('Metric')"  >
                        <span class="radio__input"></span>
                        <span class="radio__label">{{'METRIC' | translate}}</span>
                    </label>
                </div>
            </div>

        </div>
        <div class="div-powerCost" [ngClass]="isUserLoggedIn ? 'col-md-4' : 'col-md-3'">
            <div class="row" style="width: 100%;">
                <form [formGroup]="powerCostForm" style="width: 100%;">
                <div class="row div-inputPower" [ngClass]="!isUserLoggedIn ? 'user-not-logged-in' : '' ">
                    <div *ngIf="isUserLoggedIn" class="col-md-12" [ngClass]="isUserLoggedIn ? '' : 'add-top'" style="font-size: medium;padding-left: 0;">Power cost entry method</div>
                    <!-- <div class="col-md-6 logged-in-label" style="height: 6rem;" *ngIf="isUserLoggedIn">
                        <label for="input-state-focus" class="label-powerCost">Region:</label>
                    </div> -->
                    <div class="col-md-3" [ngClass]="isUserLoggedIn? 'add-log-cls': ''" *ngIf="isUserLoggedIn" style="height: 6rem;display: flex;justify-content: flex-start;">
                        <div class="row global-unit" [ngClass]="isUserLoggedIn? 'add-log-cls': ''">
                            <div class="form-group form-group--inline">
                                <label class="radio">
                                    <input type="radio" [checked]="(projectMethod == 'ByRegion') ? true : false" name="radio-inline"
                                        (change)="projectPowerCostMethodChange('ByRegion')">
                                    <span class="radio__input"></span>
                                    <span class="radio__label">By Region</span>
                                </label>
                            </div>
                            <div class="form-group form-group--inline div-metric">
                                <label class="radio">
                                    <input type="radio" [checked]="(projectMethod == 'Manual') ? true : false" name="radio-inline"
                                        (change)="projectPowerCostMethodChange('Manual')"  >
                                    <span class="radio__input"></span>
                                    <span class="radio__label">Manual</span>
                                </label>
                            </div>
                        </div> 
                    </div>
                    <div class="col-md-5"  style="height: 6rem;" *ngIf="isUserLoggedIn && projectMethod == 'ByRegion'">
                        <label for="input-state-focus" class="label-powerCost">Region:</label> 
                        <ng-select style="width: 100%;" appendTo="body" appearance="outline" [searchable]="true" class="select-small " formControlName="country"
                        (change)="changeCountry($event, false, true)"
                        placeholder="Select Region"
                        [(ngModel])]="country"
                        [ngClass]="(powerCostForm.get('country').invalid) ? 'select-danger': 'select-normal'"
                        [items]="countries">
                        <ng-option
                          *ngFor="let element of countries">
                            {{element | translate }}
                        </ng-option>
                      </ng-select>
                    </div>
                    <!-- <div class="col-md-5 power-cost-left" *ngIf="!isUserLoggedIn">User Input {{'POWER_COST_PER_KWHR' | translate}}:</div> -->
                    <div [ngClass]="isUserLoggedIn ? 'col-md-9 set-ht' : 'col-md-12'" class=" row" *ngIf="!isUserLoggedIn || (isUserLoggedIn && projectMethod == 'Manual')">
                        <div class="form-group div-power-cost col-md-6">
                            <div class="form-group__text div-power">
                                <div class="row">
                                <div class="col-md-12"><label  for="input-state-focus" class="label-powerCost">{{'POWER_COST_PER_KWHR' |
                                    translate}}:</label>
                                </div>
                                <!-- <div class="col-md-2">
                                    <a style="vertical-align: middle; color: gray; display: flex; justify-content: flex-end;" data-icon="icon-info-outline"
                                    [attr.data-balloon]="powerCostInfo" data-balloon-pos="down" data-balloon-length="large">
                                    <span class="icon-info-outline icon-size-18"></span>
                                </a>
                                </div> -->
                                </div>
                                <input  id="input-state-focus" type="number"  class="input-cost" min="0"
                                placeholder="Select Region for Power Cost"
                                (change)="changePowerCost($event)" formControlName="powerCost" autocomplete="off"
                                [ngClass]="(powerCostForm.get('powerCost').valid) ?  'select-normal' : 'select-danger'">
                            </div>
                        </div> 
                        <div  *ngIf="!isUserLoggedIn || (isUserLoggedIn && projectMethod == 'Manual')" class="form-group div-emission-cost col-md-6">
                            <div class="form-group__text div-power">
                                <label for="input-state-focus" class="label-emissionFactor">Emission Factor </label>
                                <input id="input-state-focus" type="number"  class="input-cost" min="0"
                                (change)="changeEmissionFactor($event, false)" formControlName="emissionFactor" autocomplete="off"
                                [ngClass]="(powerCostForm.get('emissionFactor').valid) ?  'select-normal' : 'select-danger'">
                            </div>
                        </div> 
                    </div>
              </div>
                </form>
            </div>

        </div>
        <div class="col-md-2 div-details" style="padding-right: 0;">
            <div class="row view-results-row">
                <div class="col-md-12 result-btn">
                    <button class="btn btn--primary" [disabled]="!powerCostForm.valid || projectData.projectName == '' || (power == 0 || current == 0) || projectError" (click)="viewResults()"> {{ 'VIEW_DETAILED_RESULT' | translate }} </button>
                </div>
            </div>

        </div>

    </div>