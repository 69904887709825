<form [formGroup]="rackServerForm" autocomplete="off">
  <div class="mat-dialog-content">

  <div class="container-fluid">
    
      <div style="margin-bottom: 0px;" mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle><h4 style="float: left;">{{platformName}}</h4> 
      <div style="    display: flex;
      flex-direction: row-reverse;">
      <div class="flex-center margin_auto_t_b" >
        <a class="anchor-close" id="close" title="Close" (click)="onCancel()">
        <span class="icon-close icon-size-24 icon-close"></span>
        </a>
    </div>
      <button mat-button type="button" class="btn btn--small " style="height: 24px;margin: 5px 20px 0 0;"
      (click)="openAdditionalAtt_Modal()">Additional Fields</button><span class="margin_auto_t_b" style="font-size: 65%;padding: 5px;"
       *ngIf="additionalFields?.length>0">{{additionalFields.length}} Field(s) Added</span></div>
    </div>
  
    <div class="section">
      <div class="col-md-12 col-lg-12">
        <div class="row padding_row">
          <div class="col-md-6 col-lg-6 row">
            <div class="col-md-3 col-lg-3">
              Vendor
            </div>
            <div class="col-md-6 col-lg-6">
              <div class="form-group">
                <div class="form-group__text">
                  <input matInput formControlName="vendor"
                    [ngClass]="rackServerForm.get('vendor').errors ? 'text-danger': 'text-normal'">
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-lg-6 row">
            <div class="col-md-3 col-lg-3">
              Name
            </div>
            <div class="col-md-6 col-lg-6">
              <div class="form-group">
                <div class="form-group__text">
                  <input matInput formControlName="name"
                    [ngClass]="rackServerForm.get('name').errors ? 'text-danger': 'text-normal'">
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row padding_row">
          <div class="col-md-6 col-lg-6 row">
            <div class="col-md-3 col-lg-3 ">
              Generation
            </div>
            <div class="col-md-6 col-lg-6">
              <div class="form-group">
                <div class="form-group__text">
                  <input matInput formControlName="gen"
                    [ngClass]="'text-normal'">
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-lg-6 row">
            <div class="col-md-3 col-lg-3 ">
              PID
            </div>
            <div class="col-md-6 col-lg-6">

              <app-search-box *ngIf="showPID" [type]="'platform'" [pid]="selectedPid" (pidSelectionEvent)="selectedPID($event)" ></app-search-box>

            </div>
          </div>
        </div>
        <div class="row padding_row">
          <div class="col-md-6 col-lg-6 row">
            <div class="col-md-3 col-lg-3 ">
              Max Dimms
            </div>
            <div class="col-md-6 col-lg-6">
              <div class="form-group">
                <div class="form-group__text">
                  <input matInput formControlName="dimmSlots" maxlength="10"
                    [ngClass]="rackServerForm.get('dimmSlots').hasError('required') || 
                               rackServerForm.get('dimmSlots').hasError('min') 
                              ||rackServerForm.get('dimmSlots').hasError('pattern')  ? 'text-danger': 'text-normal'">
                </div>
                <span *ngIf ="rackServerForm.get('dimmSlots').hasError('pattern') || rackServerForm.get('dimmSlots').hasError('min') " style = color:red>Only Positive Integers are allowed</span>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-lg-6 row">
            <div class="col-md-3 col-lg-3 ">
              Max CPUs
            </div>
            <div class="col-md-6 col-lg-6">
              <div class="form-group">
                <div class="form-group__text">
                  <input matInput formControlName="cpuSockets"   maxlength="10"
                    [ngClass]="rackServerForm.get('cpuSockets').hasError('required') ||
                    rackServerForm.get('cpuSockets').hasError('min')||
                    rackServerForm.get('cpuSockets').hasError('pattern') ? 'text-danger': 'text-normal'">
                </div>
                <span *ngIf ="rackServerForm.get('cpuSockets').hasError('min') || rackServerForm.get('cpuSockets').hasError('pattern')" style = color:red>Only Positive Integers are allowed</span>
              </div>
            </div>
          </div>
        </div>
        <div class="row padding_row">
          <div class="col-md-6 col-lg-6 row">
            <div class="col-md-3 col-lg-3 ">
              Number of PCI Slots
            </div>
            <div class="col-md-6 col-lg-6">
              <div class="form-group">
                <div class="form-group__text">
                  <input matInput formControlName="pciSlots"  maxlength="10" (ngModelChange)="validateSlots($event,'pci')"
                    [ngClass]="pciSoltsErr || rackServerForm.get('pciSlots').hasError('required') || rackServerForm.get('pciSlots').hasError('pattern')  || rackServerForm.get('pciSlots').hasError('min') ? 'text-danger': 'text-normal'">
                </div>
                <span *ngIf ="rackServerForm.get('pciSlots').hasError('pattern') || rackServerForm.get('pciSlots').hasError('min')" style = color:red>Only Positive Integers are allowed</span>
                <mat-error *ngIf ="pciSoltsErr" style = color:red>PCi Slots cannot be set to 0 because IO Cards are already assigned</mat-error>

              </div>
            </div>
          </div>
          <div class="col-md-6 col-lg-6 row">
            <div class="col-md-3 col-lg-3 ">
              Number of Hard Drives
            </div>
            <div class="col-md-6 col-lg-6">
              <div class="form-group">
                <div class="form-group__text">
                  <input matInput formControlName="maxDisks"  maxlength="10" (ngModelChange)="validateSlots($event,'hd')"
                    [ngClass]="hdSoltsErr || rackServerForm.get('maxDisks').hasError('required') || rackServerForm.get('maxDisks').hasError('pattern') || rackServerForm.get('maxDisks').hasError('min') ? 'text-danger': 'text-normal'">
                </div>
                <span *ngIf ="rackServerForm.get('maxDisks').hasError('pattern') || rackServerForm.get('maxDisks').hasError('min')" style = color:red>Only Positive Integers are allowed</span>
                <mat-error *ngIf ="hdSoltsErr" style = color:red>Hard Drives cannot be set to 0 because Storages are already assigned</mat-error>

              </div>
            </div>
          </div>
        </div>
        <!-- idle/max -->
        <div class="row padding_row">
          <div class="col-md-6 col-lg-6 row">
            <div class="col-md-3 col-lg-3 ">
              Idle Power
            </div>
            <div class="col-md-6 col-lg-6">

              <div class="form-group">
                <div class="form-group__text">
                  <input matInput formControlName="idlePower" (ngModelChange)="validateWatts($event)"
                    [ngClass]="IdleWattsErr || showWattErr || rackServerForm.get('idlePower').hasError('required') || rackServerForm.get('idlePower').hasError('pattern') || rackServerForm.get('idlePower').hasError('min')? 'text-danger': 'text-normal'">
                </div>
                <span *ngIf ="IdleWattsErr || rackServerForm.get('idlePower').hasError('pattern') || rackServerForm.get('idlePower').hasError('min')" style = color:red>Only Positive Integers are allowed </span>
                <mat-error *ngIf ="showWattErr" style = color:red>Idle Power should be less than or equal to Max Power </mat-error>

            </div>
            </div>
          </div>
          <div class="col-md-6 col-lg-6 row">
            <div class="col-md-3 col-lg-3 ">
              Max Power
            </div>
            <div class="col-md-6 col-lg-6">

              <div class="form-group">
                <div class="form-group__text">
                  <input matInput formControlName="maxPower" (ngModelChange)="validateWatts($event)"
                    [ngClass]="IdleMaxWattsErr || showWattErr || rackServerForm.get('maxPower').hasError('min') || rackServerForm.get('maxPower').hasError('required') || rackServerForm.get('maxPower').hasError('pattern') ? 'text-danger': 'text-normal'">
                </div>
                <span *ngIf ="IdleMaxWattsErr || rackServerForm.get('maxPower').hasError('min') || rackServerForm.get('maxPower').hasError('pattern')" style = color:red>Only Positive Integers are allowed </span>
                <mat-error *ngIf ="showWattErr" style = color:red>Idle Power should be less than or equal to Max Power </mat-error>

            </div>
            </div>
          </div>
        </div>

        <div class="row padding_row">
          <div class="col-md-6 col-lg-6 row">
            <div class="col-md-3 col-lg-3 ">
              Number of PSU Slots
            </div>
            <div class="col-md-6 col-lg-6 cc">
              <div class="form-group">
                <div class="form-group__text">
                  <input matInput formControlName="maxPsus"  maxlength="10"
                    [ngClass]="rackServerForm.get('maxPsus').hasError('required') || rackServerForm.get('maxPsus').hasError('pattern') || rackServerForm.get('maxPsus').hasError('min') ? 'text-danger': 'text-normal'">
                </div>
                <span *ngIf ="rackServerForm.get('maxPsus').hasError('pattern') || rackServerForm.get('maxPsus').hasError('min')" style = color:red>Only Positive Integers are allowed</span>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-lg-6 row">
            <div class="col-md-3 col-lg-3 margin_auto">
              nMin
            </div>
            <div class="col-md-3 col-lg-3">
              <div class="form-group">
                <div class="form-group__text">
                  <input matInput formControlName="nMin"  maxlength="10"
                    [ngClass]="rackServerForm.get('nMin').hasError('pattern') ? 'text-danger': 'text-normal'">
                </div>
                <span *ngIf ="rackServerForm.get('nMin').hasError('pattern')" style = color:red>Only Positive Integers are allowed</span>
              </div>
            </div>
            <div class="col-md-3 col-lg-3 margin_auto">
              Power Cap Level
            </div>
            <div class="col-md-3 col-lg-3">
              <div class="form-group">

                <ngx-select-dropdown 
                  [multiple]="false" [ngClass]="rackServerForm.get('implicitCap').hasError('required') ? 'ngx-select-danger ngx-dropdown-button': 'ngx-select-normal ngx-dropdown-button'" 
                  formControlName="implicitCap"
                  [config]="{search:false,displayKey:'viewValue'}" [options]="powerCapLevel">
                </ngx-select-dropdown>
              </div>
            </div>
          </div>
        </div>
        <div class="row padding_row">
          <div class="col-md-6 col-lg-6 row">
            <div class="col-md-3 col-lg-3 ">
              Height (Rack Units)
            </div>
            <div class="col-md-6 col-lg-6">
              <div class="form-group" >
                <div class="form-group__text">
                  <input matInput formControlName="height"  maxlength="10"
                    [ngClass]="rackServerForm.get('height').hasError('required') || rackServerForm.get('height').hasError('pattern') || rackServerForm.get('height').hasError('min') ? 'text-danger': 'text-normal'">
                </div>
                <span *ngIf ="rackServerForm.get('height').hasError('pattern') || rackServerForm.get('height').hasError('min')" style = color:red>Only Positive Integers are allowed</span>

              </div>
            </div>
          </div>
          <div class="col-md-6 col-lg-6 row">
            <div class="col-md-3 col-lg-3 ">
              Conversion Factor
            </div>
            <div class="col-md-6 col-lg-6">
              <div class="form-group">
                <div class="form-group__text">
                  <input matInput formControlName="conversionFactor"   maxlength="10"
                    [ngClass]="rackServerForm.get('conversionFactor').hasError('max') || rackServerForm.get('conversionFactor').hasError('required') || rackServerForm.get('conversionFactor').hasError('pattern') || rackServerForm.get('conversionFactor').hasError('min') ? 'text-danger': 'text-normal'">
                </div>
                <span *ngIf ="rackServerForm.get('conversionFactor').hasError('min') || rackServerForm.get('conversionFactor').hasError('pattern') || rackServerForm.get('conversionFactor').hasError('max')" style = color:red>Only Positive Numders are allowed </span>

              </div>
            </div>
          </div>
        </div>
        <div class="row padding_row">
          <div class="col-md-6 col-lg-6 row">
            <div class="col-md-3 col-lg-3 ">
              Weight (lbs)
            </div>
            <div class="col-md-6 col-lg-6">
              <div class="form-group">
                <div class="form-group__text">
                  <input matInput formControlName="weight"  (ngModelChange)="validateWatts($event)"
                    [ngClass]="weightErr || rackServerForm.get('weight').hasError('required') || rackServerForm.get('weight').hasError('pattern') || rackServerForm.get('weight').hasError('min') ? 'text-danger': 'text-normal'">
                </div>
                <span *ngIf ="rackServerForm.get('weight').hasError('pattern') || rackServerForm.get('weight').hasError('min')" style = color:red>Only Positive Integers are allowed </span>
                <span *ngIf ="weightErr" style = color:red>Only Positive Numbers are allowed</span>

              </div>
            </div>
          </div>
          <div class="col-md-6 col-lg-6 row">
            <div class="col-md-3 col-lg-3 ">
              Series
            </div>
            <div class="col-md-6 col-lg-6">
              <div class="form-group select-ngx" [attr.disabled]="true">
                <ngx-select-dropdown 
                  disabled="true"
                  [multiple]="false" 
                  [ngClass]=" rackServerForm.get('series').hasError('required') ? 
                              'ngx-select-danger ngx-dropdown-button': 'ngx-select-normal ngx-dropdown-button'" 
                  formControlName="series"
                  [config]="config" 
                  [options]="seriesList" >
                </ngx-select-dropdown>
              </div>
            </div>
          </div>
        </div>
        <div class="row padding_row">
          <div class="col-md-6 col-lg-6 row">
            <div class="col-md-3 col-lg-3 ">
              Boot Power
            </div>
            <div class="col-md-6 col-lg-6">
              <div class="form-group">
                <div class="form-group__text">
                  <input matInput formControlName="maxBootPower"  maxlength="10"
                    [ngClass]="rackServerForm.get('maxBootPower').hasError('required') || rackServerForm.get('maxBootPower').hasError('pattern') ? 'text-danger': 'text-normal'">
                </div>
                <span *ngIf ="rackServerForm.get('maxBootPower').hasError('pattern')" style = color:red>Only Positive Integers are allowed </span>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-lg-6 row"
              *ngIf="isEdit && storageData.params?.legacyPlatform !== false
                               && storageData.params?.isNewCreated == null">
            <div class="col-md-3 col-lg-3 ">
              Legacy Platform
            </div>
            <div class="col-md-6 col-lg-6">
              <div class="form-group form-group--inline">
                <label class="radio">
                    <input type="radio" value="true" (change)="legacyPlatformRadioChange($event)" name="legacyPlatform" formControlName="legacyPlatform">
                    <span class="radio__input"></span>
                    <span class="radio__label">Yes</span>
                </label>
            </div>
            <div class="form-group form-group--inline">
                <label class="radio">
                    <input type="radio" value="false" (change)="legacyPlatformRadioChange($event)" name="legacyPlatform" formControlName="legacyPlatform">
                    <span class="radio__input"></span>
                    <span class="radio__label">No</span>
                </label>
            </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 col-lg-6">

        
          <div class="row padding_row">

          <div class="col-md-12 col-lg-12 row">
            <div class="col-md-3 col-lg-3 ">
              MTBF Hours
            </div>
            <div class="col-md-6 col-lg-6 padding-left-6-right-12">
              <div class="form-group">
                <div class="form-group__text">
                  <input matInput formControlName="mtbf" (ngModelChange)="validateWatts($event)"
                    [ngClass]=" mtbfErr || rackServerForm.get('mtbf').hasError('pattern') || mtbfErr ? 'text-danger': 'text-normal'">
                </div>
                <span *ngIf ="rackServerForm.get('mtbf').hasError('pattern')" style = color:red>Only Positive Integers are allowed </span>
                 <span *ngIf="mtbfErr" style=color:red>Only Non-Negative Integers are allowed</span> 
                <!--<mat-error *ngIf="mtbfErr" style=color:red>Only Non-Negative Integers are allowed</mat-error>-->
              </div>
            </div>
          </div>
        </div>

        <div class="row padding_row">
          <div class="col-md-12 col-lg-12 row">
            <div class="col-md-3 col-lg-3 ">
              Dedicated RAID Slot
            </div>
            <div class="col-md-6 col-lg-6 padding-left-6-right-12">
              <div class="form-group">
                <div class="form-group__text">
                  <input matInput formControlName="mezzSlots"  maxlength="10"
                    [ngClass]="rackServerForm.get('mezzSlots').hasError('required') || rackServerForm.get('mezzSlots').hasError('pattern') ? 'text-danger': 'text-normal'">
                </div>
                <span *ngIf ="rackServerForm.get('mezzSlots').hasError('pattern')" style = color:red>Only Positive Integers are allowed</span>
              </div>
            </div>
          </div>
          </div>
          <div class="row padding_row">

          <div class="col-md-12 col-lg-12 row">
            <div class="col-md-3 col-lg-3 ">
              End of Sale
            </div>
            <div class="col-md-6 col-lg-6 padding-left-6-right-12">
              <div class="form-group form-group--inline">
                  <label class="radio">
                      <input type="radio" value="1" name="discont" formControlName="discont">
                      <span class="radio__input"></span>
                      <span class="radio__label">Yes</span>
                  </label>
              </div>
              <div class="form-group form-group--inline">
                  <label class="radio">
                      <input type="radio" value="0" name="discont" formControlName="discont">
                      <span class="radio__input"></span>
                      <span class="radio__label">No</span>
                  </label>
              </div>
          </div>
          </div>
        </div>
      </div><!-- new chge -->
      <div class="col-md-6 col-lg-6" style="
      margin-left: -22px;
  ">

        <div class="row padding_row">
          <div class="col-md-12 col-lg-12 row">
            <div class="col-md-3 col-lg-3 ">
              Image Look up
            </div>
            <div class="col-md-6 col-lg-6">
              <div class="form-group">
                <input type="file" name="uploadfile" id="importImage" accept="image/*" style="display:none;"
                (change)="onImportImage($event)" />
                <label for="importImage" class="btn btn--secondary import-project-btn">Browse Image</label>
              </div>
            </div>
          </div>

        </div>
        <div class="row padding_row">
          <div class="col-md-12 col-lg-12 row" style="height: 170px;">
         
            <img [src]="url" style="width: 275px;height: auto;margin: auto;"> <br/>

          </div></div>

        </div>
      </div>

       
      </div>
    </div>
  </div>

</div>
<mat-dialog-actions>
  <button mat-button type="button" (click)="imageUpload('closeModal')" [disabled]="hdSoltsErr || pciSoltsErr || submitInProgress || !rackServerForm.valid || mtbfErr  || showWattErr || IdleWattsErr || IdleMaxWattsErr || !selectedPid" class="btn btn--small "

  style="height: 24px;margin: 5px 20px 0 0;">Save & Close</button>
<button mat-button type="button"  class="btn btn--small " [disabled]="hdSoltsErr || pciSoltsErr|| submitInProgress || !rackServerForm.valid || mtbfErr || showWattErr || IdleWattsErr || IdleMaxWattsErr || !selectedPid"  style="height: 24px;margin: 5px 20px 0 0;"
  (click)="imageUpload('save')">Save</button>
</mat-dialog-actions>  </form>