<!-- <div>
    <label style="color:red;">{{lblMandatoryMsg}}</label>
    <label style="color:green;">{{lblSuccessMsg}}</label>
</div> -->
<mat-card-title>

    <div mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle style="margin-bottom: 8px;">
        <h4 style="float: left;font-size: large;font-weight: 400;">Fan Policy </h4>
        <div style="display:flex;
      flex-direction: row-reverse;">
            <div class="flex-center margin_auto_t_b">
                <a class="anchor-close" id="close" title="Close" (click)="onCancel()">
                    <span class="icon-close icon-size-24 icon-close"></span>
                </a>
            </div>
        </div>
    </div>
</mat-card-title>

<div mat-dialog-content style="width: max-content;">
    <mat-form-field>
        <form [formGroup]="policyForm" autocomplete="off">
            <div class="panel panel--compressed form-group" style="max-width:1000px;min-width: 500px;">
                <table class="table table-bordered">
                    <tbody>
                        <tr>
                            <td class="mandatory col-md-1" style="vertical-align: top;padding-top: 15px;">
                                <mat-label>PID</mat-label>
                            </td>
                            <td class="col-md-6" colspan="3">
                                <app-search-box *ngIf="showPID" [type]="'component'" [pid]="selectedPid"
                                    (pidSelectionEvent)="selectedPID($event)" [flag]="updateFlag"></app-search-box>
                            </td>
                            <td class="col-md-3"></td>
                            <td class="col-md-2"></td>
                        </tr>
                        <tr>
                            <td class="mandatory col-md-12" style="vertical-align: top;padding-top: 15px;">
                                <mat-label>Policy</mat-label>
                            </td>
                            <!-- <td> (Please fill values in all policy tabs to enable the Save button)</td> -->
                            <td>
                                <!-- <div class="form-group dropdown">
                                <div class="form-group__text select "> -->
                                <!-- <select id="select-type" formControlName="fanPolicy" multiple>
                                    <option *ngFor="let element of fanPolicies" [ngValue]="element">
                                      {{element.name}}
                                    </option>
                                  </select>                                  -->
                                <div class="form-group dropdown">
                                    <div class="form-group__text ">
                                        <!-- <ng-select
                                [items]="fanPolicies"
                                [multiple]="true" (change)="onChange($event)"
                                [ngClass]="(policyForm.get('policy').invalid) ? 'select-danger': 'select-normal'"
                                bindLabel="name" bindValue="name"
                                placeholder="Select Policy"
                                formControlName="policy" >
                            </ng-select> -->
                                        <angular2-multiselect [data]="fanPolicies" formControlName="policy" [(ngModel)]="selectedPolicy" 
                                            [settings]="dropdownSettings" (onSelect)="onItemSelect($event)"
                                            (onDeSelect)="OnItemDeSelect($event)" (onSelectAll)="onSelectAll($event)"
                                            (onDeSelectAll)="onDeSelectAll($event)" >
                                            <c-item>
                                                <ng-template let-item="item">
                                                  <label style="color: #333;min-width: 150px;">{{item.itemName}}</label>
                                                </ng-template>
                                              </c-item>
                                        </angular2-multiselect>
                                    </div>
                                </div>
                                <!-- <ngx-select-dropdown 
                          [multiple]="true" 
                          [(ngModel)]="selectedPolicy" formControlName="fanPolicy" 
                          [config]="config" [options]="fanPolicies">
                          </ngx-select-dropdown> -->
                                <!-- </div> -->

                                <!-- </div> -->
                                <!-- </div> -->
                            </td>
                        </tr>
                        <tr>
                            <td colspan="12">
                                <!--  <ul id="hleft" class="tabs">
                                <li id="hleft-1" class="tab" [ngClass]="selectedTabId == 1 ? 'active' : ''">
                                    <a tabindex="0" (click)="onTabClick(1)">Acoustic</a>
                                </li>
                                <li id="hleft-2" class="tab" [ngClass]="selectedTabId == 2 ? 'active' : ''">
                                    <a tabindex="0" (click)="onTabClick(2)">Balanced</a>
                                </li>
                                <li id="hleft-3" class="tab" [ngClass]="selectedTabId == 3 ? 'active' : ''">
                                    <a tabindex="0" (click)="onTabClick(3)">Low-Power</a>
                                </li>
                                <li id="hleft-4" class="tab" [ngClass]="selectedTabId == 4 ? 'active' : ''">
                                    <a tabindex="0" (click)="onTabClick(4)">High-Power</a>
                                </li>
                                <li id="hleft-5" class="tab" [ngClass]="selectedTabId == 5 ? 'active' : ''">
                                    <a tabindex="0" (click)="onTabClick(5)">Maximum</a>
                                </li>
                                <li id="hleft-6" class="tab" [ngClass]="selectedTabId == 6 ? 'active' : ''">
                                    <a tabindex="0" (click)="onTabClick(6)">Fan Failure</a>
                                </li>
                            </ul>
                            <div id="hleft-content" class="tab-content">
                                <div id="hleft-1-content" class="tab-pane "
                                    [ngClass]="selectedTabId == 1 ? 'active' : ''">
                                    <app-fan-policy (formValidEvent)="receiveMessage($event,'acoustic')"></app-fan-policy>
                                </div>
                                <div id="hleft-2-content" class="tab-pane"
                                    [ngClass]="selectedTabId == 2 ? 'active' : ''">
                                    <app-fan-policy (formValidEvent)="receiveMessage($event, 'balanced')"></app-fan-policy>
                                </div>
                                <div id="hleft-3-content" class="tab-pane"
                                    [ngClass]="selectedTabId == 3 ? 'active' : ''">
                                    <app-fan-policy (formValidEvent)="receiveMessage($event, 'lowpower')"></app-fan-policy>
                                </div>
                                <div id="hleft-4-content" class="tab-pane"
                                    [ngClass]="selectedTabId == 4 ? 'active' : ''">
                                    <app-fan-policy (formValidEvent)="receiveMessage($event, 'highpower')"></app-fan-policy>
                                </div>
                                <div id="hleft-5-content" class="tab-pane"
                                    [ngClass]="selectedTabId == 5 ? 'active' : ''">
                                    <app-fan-policy (formValidEvent)="receiveMessage($event,'maximum')"></app-fan-policy>
                                </div>
                                <div id="hleft-6-content" class="tab-pane"
                                    [ngClass]="selectedTabId == 6 ? 'active' : ''">
                                    <app-fan-policy (formValidEvent)="receiveMessage($event,'fanfailure')"></app-fan-policy>
                                </div>
                            </div>-->
                                <!-- <div> <app-fan-policy (formValidEvent)="receiveMessage($event,'fanfailure')"></app-fan-policy></div> -->
                                <div class="responsive-table">
                                    <!-- <form [formGroup]="policyForm" autocomplete="off"> -->
                                    <mat-form-field>
                                        <table class="table table--lined table--loose">
                                            <thead>
                                            <tr>
                                                <td style="text-align: center;">Inlet Temp</td>
                                                <td style="text-align: center;">PWM</td>
                                                <td style="text-align: center;">Power</td>
                                                <td style="text-align: center;">Airflow</td>
                                                <td style="text-align: center;">Sound</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <!-- <td class="mandatory">
                                                    <mat-label>PWM at 20</mat-label>
                                                </td> -->
                                                <td class="mandatory">
                                                    <mat-label>20</mat-label>
                                                </td>
                                                <td>
                                                    <div class="col-md-2">

                                                        <div class="form-group">
                                                            <div class="form-group__text">
                                                                <input matInput
                                                                    [ngClass]="policyForm.get('a20pwm').hasError('pattern') || policyForm.get('a20pwm').hasError('required')  || policyForm.get('a20pwm').hasError('min')   ? 'text-danger': 'text-normal'"
                                                                    formControlName="a20pwm" required
                                                                    style="width: 100px;">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <!-- <td class="mandatory">
                                                    <mat-label>Power at 20</mat-label>
                                                </td> -->
                                                <td>
                                                    <div class="col-md-2">
                                                        <div class="form-group ">
                                                            <div class="form-group__text">
                                                                <input matInput
                                                                    [ngClass]="policyForm.get('a20power').hasError('pattern') || policyForm.get('a20power').hasError('required')  || policyForm.get('a20power').hasError('min')  ? 'text-danger': 'text-normal'"
                                                                    formControlName="a20power" required
                                                                    style="width: 100px;">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <!-- <td class="mandatory">
                                                    <mat-label>Airflow at 20</mat-label>
                                                </td> -->
                                                <td>
                                                    <!-- form-group--error"-->
                                                    <div class="col-md-2">
                                                        <div class="form-group">
                                                            <div class="form-group__text">
                                                                <input matInput
                                                                    [ngClass]="policyForm.get('a20airflow').hasError('pattern') || policyForm.get('a20airflow').hasError('required') || policyForm.get('a20airflow').hasError('min')   ? 'text-danger': 'text-normal'"
                                                                    formControlName="a20airflow" required
                                                                    style="width: 100px;">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <!-- <td class="mandatory">
                                                    <mat-label>Sound at 20</mat-label>
                                                </td> -->
                                                <td>
                                                    <!-- form-group--error"-->
                                                    <div class="col-md-2">
                                                        <div class="form-group">
                                                            <div class="form-group__text">
                                                                <input matInput
                                                                    [ngClass]="policyForm.get('a20sound').hasError('pattern') || policyForm.get('a20sound').hasError('required') || policyForm.get('a20sound').hasError('min')   ? 'text-danger': 'text-normal'"
                                                                    formControlName="a20sound" required
                                                                    style="width: 100px;">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="mandatory">
                                                    <mat-label>21</mat-label>
                                                </td>
                                                <td>
                                                    <div class="col-md-2">

                                                        <div class="form-group">
                                                            <div class="form-group__text">
                                                                <input matInput
                                                                    [ngClass]="policyForm.get('a21pwm').hasError('pattern') || policyForm.get('a21pwm').hasError('required') || policyForm.get('a21pwm').hasError('min')   ? 'text-danger': 'text-normal'"
                                                                    formControlName="a21pwm" required
                                                                    style="width: 100px;">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <!-- <td class="mandatory">
                                                    <mat-label>Power at 21</mat-label>
                                                </td> -->
                                                <td>
                                                    <div class="col-md-2">
                                                        <div class="form-group ">
                                                            <div class="form-group__text">
                                                                <input matInput
                                                                    [ngClass]="policyForm.get('a21power').hasError('pattern') || policyForm.get('a21power').hasError('required') || policyForm.get('a21power').hasError('min')   ? 'text-danger': 'text-normal'"
                                                                    formControlName="a21power" required
                                                                    style="width: 100px;">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <!-- <td class="mandatory">
                                                    <mat-label>Airflow at 21</mat-label>
                                                </td> -->
                                                <td>
                                                    <!-- form-group--error"-->
                                                    <div class="col-md-2">
                                                        <div class="form-group">
                                                            <div class="form-group__text">
                                                                <input matInput
                                                                    [ngClass]="policyForm.get('a21airflow').hasError('pattern') || policyForm.get('a21airflow').hasError('required') || policyForm.get('a21airflow').hasError('min')   ? 'text-danger': 'text-normal'"
                                                                    formControlName="a21airflow" required
                                                                    style="width: 100px;">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <!-- <td class="mandatory">
                                                    <mat-label>Sound at 21</mat-label>
                                                </td> -->
                                                <td>
                                                    <!-- form-group--error"-->
                                                    <div class="col-md-2">
                                                        <div class="form-group">
                                                            <div class="form-group__text">
                                                                <input matInput
                                                                    [ngClass]="policyForm.get('a21sound').hasError('pattern') || policyForm.get('a21sound').hasError('required') || policyForm.get('a21sound').hasError('min')   ? 'text-danger': 'text-normal'"
                                                                    formControlName="a21sound" required
                                                                    style="width: 100px;">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="mandatory">
                                                    <mat-label>22</mat-label>
                                                </td>
                                                <td>
                                                    <div class="col-md-2">

                                                        <div class="form-group">
                                                            <div class="form-group__text">
                                                                <input matInput
                                                                    [ngClass]="policyForm.get('a22pwm').hasError('pattern') || policyForm.get('a22pwm').hasError('required') || policyForm.get('a22pwm').hasError('min')   ? 'text-danger': 'text-normal'"
                                                                    formControlName="a22pwm" required
                                                                    style="width: 100px;">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <!-- <td class="mandatory">
                                                    <mat-label>Power at 22</mat-label>
                                                </td> -->
                                                <td>
                                                    <div class="col-md-2">
                                                        <div class="form-group ">
                                                            <div class="form-group__text">
                                                                <input matInput
                                                                    [ngClass]="policyForm.get('a22power').hasError('pattern') || policyForm.get('a22power').hasError('required') || policyForm.get('a22power').hasError('min')   ? 'text-danger': 'text-normal'"
                                                                    formControlName="a22power" required
                                                                    style="width: 100px;">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <!-- <td class="mandatory">
                                                    <mat-label>Airflow at 22</mat-label>
                                                </td> -->
                                                <td>
                                                    <!-- form-group--error"-->
                                                    <div class="col-md-2">
                                                        <div class="form-group">
                                                            <div class="form-group__text">
                                                                <input matInput
                                                                    [ngClass]="policyForm.get('a22airflow').hasError('pattern') || policyForm.get('a22airflow').hasError('required') || policyForm.get('a22airflow').hasError('min')   ? 'text-danger': 'text-normal'"
                                                                    formControlName="a22airflow" required
                                                                    style="width: 100px;">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <!-- <td class="mandatory">
                                                    <mat-label>Sound at 22</mat-label>
                                                </td> -->
                                                <td>
                                                    <!-- form-group--error"-->
                                                    <div class="col-md-2">
                                                        <div class="form-group">
                                                            <div class="form-group__text">
                                                                <input matInput
                                                                    [ngClass]="policyForm.get('a22sound').hasError('pattern') || policyForm.get('a22sound').hasError('required') || policyForm.get('a22sound').hasError('min')   ? 'text-danger': 'text-normal'"
                                                                    formControlName="a22sound" required
                                                                    style="width: 100px;">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td class="mandatory">
                                                    <mat-label>23</mat-label>
                                                </td>
                                                <td>
                                                    <div class="col-md-2">

                                                        <div class="form-group">
                                                            <div class="form-group__text">
                                                                <input matInput
                                                                    [ngClass]="policyForm.get('a23pwm').hasError('pattern') || policyForm.get('a23pwm').hasError('required') || policyForm.get('a23pwm').hasError('min')   ? 'text-danger': 'text-normal'"
                                                                    formControlName="a23pwm" required
                                                                    style="width: 100px;">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <!-- <td class="mandatory">
                                                    <mat-label>Power at 23</mat-label>
                                                </td> -->
                                                <td>
                                                    <div class="col-md-2">
                                                        <div class="form-group ">
                                                            <div class="form-group__text">
                                                                <input matInput
                                                                    [ngClass]="policyForm.get('a23power').hasError('pattern') || policyForm.get('a23power').hasError('required') || policyForm.get('a23power').hasError('min')   ? 'text-danger': 'text-normal'"
                                                                    formControlName="a23power" required
                                                                    style="width: 100px;">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <!-- <td class="mandatory">
                                                    <mat-label>Airflow at 23</mat-label>
                                                </td> -->
                                                <td>
                                                    <!-- form-group--error"-->
                                                    <div class="col-md-2">
                                                        <div class="form-group">
                                                            <div class="form-group__text">
                                                                <input matInput
                                                                    [ngClass]="policyForm.get('a23airflow').hasError('pattern') || policyForm.get('a23airflow').hasError('required') || policyForm.get('a23airflow').hasError('min')   ? 'text-danger': 'text-normal'"
                                                                    formControlName="a23airflow" required
                                                                    style="width: 100px;">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <!-- <td class="mandatory">
                                                    <mat-label>Sound at 23</mat-label>
                                                </td> -->
                                                <td>
                                                    <!-- form-group--error"-->
                                                    <div class="col-md-2">
                                                        <div class="form-group">
                                                            <div class="form-group__text">
                                                                <input matInput
                                                                    [ngClass]="policyForm.get('a23sound').hasError('pattern') || policyForm.get('a23sound').hasError('required') || policyForm.get('a23sound').hasError('min')   ? 'text-danger': 'text-normal'"
                                                                    formControlName="a23sound" required
                                                                    style="width: 100px;">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="mandatory">
                                                    <mat-label>24</mat-label>
                                                </td>
                                                <td>
                                                    <div class="col-md-2">

                                                        <div class="form-group">
                                                            <div class="form-group__text">
                                                                <input matInput
                                                                    [ngClass]="policyForm.get('a24pwm').hasError('pattern') || policyForm.get('a24pwm').hasError('required') || policyForm.get('a24pwm').hasError('min')   ? 'text-danger': 'text-normal'"
                                                                    formControlName="a24pwm" required
                                                                    style="width: 100px;">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <!-- <td class="mandatory">
                                                    <mat-label>Power at 24</mat-label>
                                                </td> -->
                                                <td>
                                                    <div class="col-md-2">
                                                        <div class="form-group ">
                                                            <div class="form-group__text">
                                                                <input matInput
                                                                    [ngClass]="policyForm.get('a24power').hasError('pattern') || policyForm.get('a24power').hasError('required') || policyForm.get('a24power').hasError('min')   ? 'text-danger': 'text-normal'"
                                                                    formControlName="a24power" required
                                                                    style="width: 100px;">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <!-- <td class="mandatory">
                                                    <mat-label>Airflow at 24</mat-label>
                                                </td> -->
                                                <td>
                                                    <!-- form-group--error"-->
                                                    <div class="col-md-2">
                                                        <div class="form-group">
                                                            <div class="form-group__text">
                                                                <input matInput
                                                                    [ngClass]="policyForm.get('a24airflow').hasError('pattern') || policyForm.get('a24airflow').hasError('required') || policyForm.get('a24airflow').hasError('min')   ? 'text-danger': 'text-normal'"
                                                                    formControlName="a24airflow" required
                                                                    style="width: 100px;">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <!-- <td class="mandatory">
                                                    <mat-label>Sound at 24</mat-label>
                                                </td> -->
                                                <td>
                                                    <!-- form-group--error"-->
                                                    <div class="col-md-2">
                                                        <div class="form-group">
                                                            <div class="form-group__text">
                                                                <input matInput
                                                                    [ngClass]="policyForm.get('a24sound').hasError('pattern') || policyForm.get('a24sound').hasError('required') || policyForm.get('a24sound').hasError('min')   ? 'text-danger': 'text-normal'"
                                                                    formControlName="a24sound" required
                                                                    style="width: 100px;">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="mandatory">
                                                    <mat-label>25</mat-label>
                                                </td>
                                                <td>
                                                    <div class="col-md-2">

                                                        <div class="form-group">
                                                            <div class="form-group__text">
                                                                <input matInput
                                                                    [ngClass]="policyForm.get('a25pwm').hasError('pattern') || policyForm.get('a25pwm').hasError('required') || policyForm.get('a25pwm').hasError('min')   ? 'text-danger': 'text-normal'"
                                                                    formControlName="a25pwm" required
                                                                    style="width: 100px;">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <!-- <td class="mandatory">
                                                    <mat-label>Power at 25</mat-label>
                                                </td> -->
                                                <td>
                                                    <div class="col-md-2">
                                                        <div class="form-group ">
                                                            <div class="form-group__text">
                                                                <input matInput
                                                                    [ngClass]="policyForm.get('a25power').hasError('pattern') || policyForm.get('a25power').hasError('required') || policyForm.get('a25power').hasError('min')   ? 'text-danger': 'text-normal'"
                                                                    formControlName="a25power" required
                                                                    style="width: 100px;">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <!-- <td class="mandatory">
                                                    <mat-label>Airflow at 25</mat-label>
                                                </td> -->
                                                <td>
                                                    <!-- form-group--error"-->
                                                    <div class="col-md-2">
                                                        <div class="form-group">
                                                            <div class="form-group__text">
                                                                <input matInput
                                                                    [ngClass]="policyForm.get('a25airflow').hasError('pattern') || policyForm.get('a25airflow').hasError('required') || policyForm.get('a25airflow').hasError('min')   ? 'text-danger': 'text-normal'"
                                                                    formControlName="a25airflow" required
                                                                    style="width: 100px;">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <!-- <td class="mandatory">
                                                    <mat-label>Sound at 25</mat-label>
                                                </td> -->
                                                <td>
                                                    <!-- form-group--error"-->
                                                    <div class="col-md-2">
                                                        <div class="form-group">
                                                            <div class="form-group__text">
                                                                <input matInput
                                                                    [ngClass]="policyForm.get('a25sound').hasError('pattern') || policyForm.get('a25sound').hasError('required') || policyForm.get('a25sound').hasError('min')   ? 'text-danger': 'text-normal'"
                                                                    formControlName="a25sound" required
                                                                    style="width: 100px;">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="mandatory">
                                                    <mat-label>26</mat-label>
                                                </td>
                                                <td>
                                                    <div class="col-md-2">

                                                        <div class="form-group">
                                                            <div class="form-group__text">
                                                                <input matInput
                                                                    [ngClass]="policyForm.get('a26pwm').hasError('pattern') || policyForm.get('a26pwm').hasError('required') || policyForm.get('a26pwm').hasError('min')   ? 'text-danger': 'text-normal'"
                                                                    formControlName="a26pwm" required
                                                                    style="width: 100px;">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <!-- <td class="mandatory">
                                                    <mat-label>Power at 26</mat-label>
                                                </td> -->
                                                <td>
                                                    <div class="col-md-2">
                                                        <div class="form-group ">
                                                            <div class="form-group__text">
                                                                <input matInput
                                                                    [ngClass]="policyForm.get('a26power').hasError('pattern') || policyForm.get('a26power').hasError('required') || policyForm.get('a26power').hasError('min')   ? 'text-danger': 'text-normal'"
                                                                    formControlName="a26power" required
                                                                    style="width: 100px;">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <!-- <td class="mandatory">
                                                    <mat-label>Airflow at 26</mat-label>
                                                </td> -->
                                                <td>
                                                    <!-- form-group--error"-->
                                                    <div class="col-md-2">
                                                        <div class="form-group">
                                                            <div class="form-group__text">
                                                                <input matInput
                                                                    [ngClass]="policyForm.get('a26airflow').hasError('pattern') || policyForm.get('a26airflow').hasError('required') || policyForm.get('a26airflow').hasError('min')   ? 'text-danger': 'text-normal'"
                                                                    formControlName="a26airflow" required
                                                                    style="width: 100px;">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <!-- <td class="mandatory">
                                                    <mat-label>Sound at 26</mat-label>
                                                </td> -->
                                                <td>
                                                    <!-- form-group--error"-->
                                                    <div class="col-md-2">
                                                        <div class="form-group">
                                                            <div class="form-group__text">
                                                                <input matInput
                                                                    [ngClass]="policyForm.get('a26sound').hasError('pattern') || policyForm.get('a26sound').hasError('required') || policyForm.get('a26sound').hasError('min')   ? 'text-danger': 'text-normal'"
                                                                    formControlName="a26sound" required
                                                                    style="width: 100px;">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="mandatory">
                                                    <mat-label>27</mat-label>
                                                </td>
                                                <td>
                                                    <div class="col-md-2">

                                                        <div class="form-group">
                                                            <div class="form-group__text">
                                                                <input matInput
                                                                    [ngClass]="policyForm.get('a27pwm').hasError('pattern') || policyForm.get('a27pwm').hasError('required') || policyForm.get('a27pwm').hasError('min')   ? 'text-danger': 'text-normal'"
                                                                    formControlName="a27pwm" required
                                                                    style="width: 100px;">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <!-- <td class="mandatory">
                                                    <mat-label>Power at 27</mat-label>
                                                </td> -->
                                                <td>
                                                    <div class="col-md-2">
                                                        <div class="form-group ">
                                                            <div class="form-group__text">
                                                                <input matInput
                                                                    [ngClass]="policyForm.get('a27power').hasError('pattern') || policyForm.get('a27power').hasError('required') || policyForm.get('a27power').hasError('min')   ? 'text-danger': 'text-normal'"
                                                                    formControlName="a27power" required
                                                                    style="width: 100px;">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <!-- <td class="mandatory">
                                                    <mat-label>Airflow at 27</mat-label>
                                                </td> -->
                                                <td>
                                                    <!-- form-group--error"-->
                                                    <div class="col-md-2">
                                                        <div class="form-group">
                                                            <div class="form-group__text">
                                                                <input matInput
                                                                    [ngClass]="policyForm.get('a27airflow').hasError('pattern') || policyForm.get('a27airflow').hasError('required') || policyForm.get('a27airflow').hasError('min')   ? 'text-danger': 'text-normal'"
                                                                    formControlName="a27airflow" required
                                                                    style="width: 100px;">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <!-- <td class="mandatory">
                                                    <mat-label>Sound at 27</mat-label>
                                                </td> -->
                                                <td>
                                                    <!-- form-group--error"-->
                                                    <div class="col-md-2">
                                                        <div class="form-group">
                                                            <div class="form-group__text">
                                                                <input matInput
                                                                    [ngClass]="policyForm.get('a27sound').hasError('pattern') || policyForm.get('a27sound').hasError('required') || policyForm.get('a27sound').hasError('min')   ? 'text-danger': 'text-normal'"
                                                                    formControlName="a27sound" required
                                                                    style="width: 100px;">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="mandatory">
                                                    <mat-label>28</mat-label>
                                                </td>
                                                <td>
                                                    <div class="col-md-2">

                                                        <div class="form-group">
                                                            <div class="form-group__text">
                                                                <input matInput
                                                                    [ngClass]="policyForm.get('a28pwm').hasError('pattern') || policyForm.get('a28pwm').hasError('required') || policyForm.get('a28pwm').hasError('min')   ? 'text-danger': 'text-normal'"
                                                                    formControlName="a28pwm" required
                                                                    style="width: 100px;">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <!-- <td class="mandatory">
                                                    <mat-label>Power at 28</mat-label>
                                                </td> -->
                                                <td>
                                                    <div class="col-md-2">
                                                        <div class="form-group ">
                                                            <div class="form-group__text">
                                                                <input matInput
                                                                    [ngClass]="policyForm.get('a28power').hasError('pattern') || policyForm.get('a28power').hasError('required') || policyForm.get('a28power').hasError('min')   ? 'text-danger': 'text-normal'"
                                                                    formControlName="a28power" required
                                                                    style="width: 100px;">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <!-- <td class="mandatory">
                                                    <mat-label>Airflow at 28</mat-label>
                                                </td> -->
                                                <td>
                                                    <!-- form-group--error"-->
                                                    <div class="col-md-2">
                                                        <div class="form-group">
                                                            <div class="form-group__text">
                                                                <input matInput
                                                                    [ngClass]="policyForm.get('a28airflow').hasError('pattern') || policyForm.get('a28airflow').hasError('required') || policyForm.get('a28airflow').hasError('min')   ? 'text-danger': 'text-normal'"
                                                                    formControlName="a28airflow" required
                                                                    style="width: 100px;">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <!-- <td class="mandatory">
                                                    <mat-label>Sound at 28</mat-label>
                                                </td> -->
                                                <td>
                                                    <!-- form-group--error"-->
                                                    <div class="col-md-2">
                                                        <div class="form-group">
                                                            <div class="form-group__text">
                                                                <input matInput
                                                                    [ngClass]="policyForm.get('a28sound').hasError('pattern') || policyForm.get('a28sound').hasError('required') || policyForm.get('a28sound').hasError('min')   ? 'text-danger': 'text-normal'"
                                                                    formControlName="a28sound" required
                                                                    style="width: 100px;">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="mandatory">
                                                    <mat-label>29</mat-label>
                                                </td>
                                                <td>
                                                    <div class="col-md-2">

                                                        <div class="form-group">
                                                            <div class="form-group__text">
                                                                <input matInput
                                                                    [ngClass]="policyForm.get('a29pwm').hasError('pattern') || policyForm.get('a29pwm').hasError('required') || policyForm.get('a29pwm').hasError('min')   ? 'text-danger': 'text-normal'"
                                                                    formControlName="a29pwm" required
                                                                    style="width: 100px;">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <!-- <td class="mandatory">
                                                    <mat-label>Power at 29</mat-label>
                                                </td> -->
                                                <td>
                                                    <div class="col-md-2">
                                                        <div class="form-group ">
                                                            <div class="form-group__text">
                                                                <input matInput
                                                                    [ngClass]="policyForm.get('a29power').hasError('pattern') || policyForm.get('a29power').hasError('required') || policyForm.get('a29power').hasError('min')   ? 'text-danger': 'text-normal'"
                                                                    formControlName="a29power" required
                                                                    style="width: 100px;">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <!-- <td class="mandatory">
                                                    <mat-label>Airflow at 29</mat-label>
                                                </td> -->
                                                <td>
                                                    <!-- form-group--error"-->
                                                    <div class="col-md-2">
                                                        <div class="form-group">
                                                            <div class="form-group__text">
                                                                <input matInput
                                                                    [ngClass]="policyForm.get('a29airflow').hasError('pattern') || policyForm.get('a29airflow').hasError('required') || policyForm.get('a29airflow').hasError('min')   ? 'text-danger': 'text-normal'"
                                                                    formControlName="a29airflow" required
                                                                    style="width: 100px;">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <!-- <td class="mandatory">
                                                    <mat-label>Sound at 29</mat-label>
                                                </td> -->
                                                <td>
                                                    <!-- form-group--error"-->
                                                    <div class="col-md-2">
                                                        <div class="form-group">
                                                            <div class="form-group__text">
                                                                <input matInput
                                                                    [ngClass]="policyForm.get('a29sound').hasError('pattern') || policyForm.get('a29sound').hasError('required') || policyForm.get('a29sound').hasError('min')   ? 'text-danger': 'text-normal'"
                                                                    formControlName="a29sound" required
                                                                    style="width: 100px;">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="mandatory">
                                                    <mat-label>30</mat-label>
                                                </td>
                                                <td>
                                                    <div class="col-md-2">

                                                        <div class="form-group">
                                                            <div class="form-group__text">
                                                                <input matInput
                                                                    [ngClass]="policyForm.get('a30pwm').hasError('pattern') || policyForm.get('a30pwm').hasError('required') || policyForm.get('a30pwm').hasError('min')   ? 'text-danger': 'text-normal'"
                                                                    formControlName="a30pwm" required
                                                                    style="width: 100px;">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <!-- <td class="mandatory">
                                                    <mat-label>Power at 30</mat-label>
                                                </td> -->
                                                <td>
                                                    <div class="col-md-2">
                                                        <div class="form-group ">
                                                            <div class="form-group__text">
                                                                <input matInput
                                                                    [ngClass]="policyForm.get('a30power').hasError('pattern') || policyForm.get('a30power').hasError('required') || policyForm.get('a30power').hasError('min')   ? 'text-danger': 'text-normal'"
                                                                    formControlName="a30power" required
                                                                    style="width: 100px;">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <!-- <td class="mandatory">
                                                    <mat-label>Airflow at 30</mat-label>
                                                </td> -->
                                                <td>
                                                    <!-- form-group--error"-->
                                                    <div class="col-md-2">
                                                        <div class="form-group">
                                                            <div class="form-group__text">
                                                                <input matInput
                                                                    [ngClass]="policyForm.get('a30airflow').hasError('pattern') || policyForm.get('a30airflow').hasError('required') || policyForm.get('a30airflow').hasError('min')   ? 'text-danger': 'text-normal'"
                                                                    formControlName="a30airflow" required
                                                                    style="width: 100px;">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <!-- <td class="mandatory">
                                                    <mat-label>Sound at 30</mat-label>
                                                </td> -->
                                                <td>
                                                    <!-- form-group--error"-->
                                                    <div class="col-md-2">
                                                        <div class="form-group">
                                                            <div class="form-group__text">
                                                                <input matInput
                                                                    [ngClass]="policyForm.get('a30sound').hasError('pattern') || policyForm.get('a30sound').hasError('required') || policyForm.get('a30sound').hasError('min')   ? 'text-danger': 'text-normal'"
                                                                    formControlName="a30sound" required
                                                                    style="width: 100px;">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="mandatory">
                                                    <mat-label>31</mat-label>
                                                </td>
                                                <td>
                                                    <div class="col-md-2">

                                                        <div class="form-group">
                                                            <div class="form-group__text">
                                                                <input matInput
                                                                    [ngClass]="policyForm.get('a31pwm').hasError('pattern') || policyForm.get('a31pwm').hasError('required') || policyForm.get('a31pwm').hasError('min')   ? 'text-danger': 'text-normal'"
                                                                    formControlName="a31pwm" required
                                                                    style="width: 100px;">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <!-- <td class="mandatory">
                                                    <mat-label>Power at 31</mat-label>
                                                </td> -->
                                                <td>
                                                    <div class="col-md-2">
                                                        <div class="form-group ">
                                                            <div class="form-group__text">
                                                                <input matInput
                                                                    [ngClass]="policyForm.get('a31power').hasError('pattern') || policyForm.get('a31power').hasError('required') || policyForm.get('a31power').hasError('min')   ? 'text-danger': 'text-normal'"
                                                                    formControlName="a31power" required
                                                                    style="width: 100px;">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <!-- <td class="mandatory">
                                                    <mat-label>Airflow at 31</mat-label>
                                                </td> -->
                                                <td>
                                                    <!-- form-group--error"-->
                                                    <div class="col-md-2">
                                                        <div class="form-group">
                                                            <div class="form-group__text">
                                                                <input matInput
                                                                    [ngClass]="policyForm.get('a31airflow').hasError('pattern') || policyForm.get('a31airflow').hasError('required') || policyForm.get('a31airflow').hasError('min')   ? 'text-danger': 'text-normal'"
                                                                    formControlName="a31airflow" required
                                                                    style="width: 100px;">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <!-- <td class="mandatory">
                                                    <mat-label>Sound at 31</mat-label>
                                                </td> -->
                                                <td>
                                                    <!-- form-group--error"-->
                                                    <div class="col-md-2">
                                                        <div class="form-group">
                                                            <div class="form-group__text">
                                                                <input matInput
                                                                    [ngClass]="policyForm.get('a31sound').hasError('pattern') || policyForm.get('a31sound').hasError('required') || policyForm.get('a31sound').hasError('min')   ? 'text-danger': 'text-normal'"
                                                                    formControlName="a31sound" required
                                                                    style="width: 100px;">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="mandatory">
                                                    <mat-label>32</mat-label>
                                                </td>
                                                <td>
                                                    <div class="col-md-2">

                                                        <div class="form-group">
                                                            <div class="form-group__text">
                                                                <input matInput
                                                                    [ngClass]="policyForm.get('a32pwm').hasError('pattern') || policyForm.get('a32pwm').hasError('required') || policyForm.get('a32pwm').hasError('min')   ? 'text-danger': 'text-normal'"
                                                                    formControlName="a32pwm" required
                                                                    style="width: 100px;">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <!-- <td class="mandatory">
                                                    <mat-label>Power at 32</mat-label>
                                                </td> -->
                                                <td>
                                                    <div class="col-md-2">
                                                        <div class="form-group ">
                                                            <div class="form-group__text">
                                                                <input matInput
                                                                    [ngClass]="policyForm.get('a32power').hasError('pattern') || policyForm.get('a32power').hasError('required') || policyForm.get('a32power').hasError('min')   ? 'text-danger': 'text-normal'"
                                                                    formControlName="a32power" required
                                                                    style="width: 100px;">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <!-- <td class="mandatory">
                                                    <mat-label>Airflow at 32</mat-label>
                                                </td> -->
                                                <td>
                                                    <!-- form-group--error"-->
                                                    <div class="col-md-2">
                                                        <div class="form-group">
                                                            <div class="form-group__text">
                                                                <input matInput
                                                                    [ngClass]="policyForm.get('a32airflow').hasError('pattern') || policyForm.get('a32airflow').hasError('required') || policyForm.get('a32airflow').hasError('min')   ? 'text-danger': 'text-normal'"
                                                                    formControlName="a32airflow" required
                                                                    style="width: 100px;">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <!-- <td class="mandatory">
                                                    <mat-label>Sound at 32</mat-label>
                                                </td> -->
                                                <td>
                                                    <!-- form-group--error"-->
                                                    <div class="col-md-2">
                                                        <div class="form-group">
                                                            <div class="form-group__text">
                                                                <input matInput formControlName="a32sound" required
                                                                    style="width: 100px;"
                                                                    [ngClass]="policyForm.get('a32sound').hasError('pattern') || policyForm.get('a32sound').hasError('required') || policyForm.get('a32sound').hasError('min')   ? 'text-danger': 'text-normal'">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="mandatory">
                                                    <mat-label>33</mat-label>
                                                </td>
                                                <td>
                                                    <div class="col-md-2">

                                                        <div class="form-group">
                                                            <div class="form-group__text">
                                                                <input matInput
                                                                    [ngClass]="policyForm.get('a33pwm').hasError('pattern') || policyForm.get('a33pwm').hasError('required') || policyForm.get('a33pwm').hasError('min')   ? 'text-danger': 'text-normal'"
                                                                    formControlName="a33pwm" required
                                                                    style="width: 100px;">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <!-- <td class="mandatory">
                                                    <mat-label>Power at 33</mat-label>
                                                </td> -->
                                                <td>
                                                    <div class="col-md-2">
                                                        <div class="form-group ">
                                                            <div class="form-group__text">
                                                                <input matInput
                                                                    [ngClass]="policyForm.get('a33power').hasError('pattern') || policyForm.get('a33power').hasError('required') || policyForm.get('a33power').hasError('min')   ? 'text-danger': 'text-normal'"
                                                                    formControlName="a33power" required
                                                                    style="width: 100px;">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <!-- <td class="mandatory">
                                                    <mat-label>Airflow at 33</mat-label>
                                                </td> -->
                                                <td>
                                                    <!-- form-group--error"-->
                                                    <div class="col-md-2">
                                                        <div class="form-group">
                                                            <div class="form-group__text">
                                                                <input matInput
                                                                    [ngClass]="policyForm.get('a33airflow').hasError('pattern') || policyForm.get('a33airflow').hasError('required') || policyForm.get('a33airflow').hasError('min')   ? 'text-danger': 'text-normal'"
                                                                    formControlName="a33airflow" required
                                                                    style="width: 100px;">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <!-- <td class="mandatory">
                                                    <mat-label>Sound at 33</mat-label>
                                                </td> -->
                                                <td>
                                                    <!-- form-group--error"-->
                                                    <div class="col-md-2">
                                                        <div class="form-group">
                                                            <div class="form-group__text">
                                                                <input matInput
                                                                    [ngClass]="policyForm.get('a33sound').hasError('pattern') || policyForm.get('a33sound').hasError('required') || policyForm.get('a33sound').hasError('min')   ? 'text-danger': 'text-normal'"
                                                                    formControlName="a33sound" required
                                                                    style="width: 100px;">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="mandatory">
                                                    <mat-label>34</mat-label>
                                                </td>
                                                <td>
                                                    <div class="col-md-2">

                                                        <div class="form-group">
                                                            <div class="form-group__text">
                                                                <input matInput
                                                                    [ngClass]="policyForm.get('a34pwm').hasError('pattern') || policyForm.get('a34pwm').hasError('required') || policyForm.get('a34pwm').hasError('min')   ? 'text-danger': 'text-normal'"
                                                                    formControlName="a34pwm" required
                                                                    style="width: 100px;">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <!-- <td class="mandatory">
                                                    <mat-label>Power at 34</mat-label>
                                                </td> -->
                                                <td>
                                                    <div class="col-md-2">
                                                        <div class="form-group ">
                                                            <div class="form-group__text">
                                                                <input matInput
                                                                    [ngClass]="policyForm.get('a34power').hasError('pattern') || policyForm.get('a34power').hasError('required') || policyForm.get('a34power').hasError('min')   ? 'text-danger': 'text-normal'"
                                                                    formControlName="a34power" required
                                                                    style="width: 100px;">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <!-- <td class="mandatory">
                                                    <mat-label>Airflow at 34</mat-label>
                                                </td> -->
                                                <td>
                                                    <!-- form-group--error"-->
                                                    <div class="col-md-2">
                                                        <div class="form-group">
                                                            <div class="form-group__text">
                                                                <input matInput
                                                                    [ngClass]="policyForm.get('a34airflow').hasError('pattern') || policyForm.get('a34airflow').hasError('required') || policyForm.get('a34airflow').hasError('min')   ? 'text-danger': 'text-normal'"
                                                                    formControlName="a34airflow" required
                                                                    style="width: 100px;">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <!-- <td class="mandatory">
                                                    <mat-label>Sound at 34</mat-label>
                                                </td> -->
                                                <td>
                                                    <!-- form-group--error"-->
                                                    <div class="col-md-2">
                                                        <div class="form-group">
                                                            <div class="form-group__text">
                                                                <input matInput
                                                                    [ngClass]="policyForm.get('a34sound').hasError('pattern') || policyForm.get('a34sound').hasError('required') || policyForm.get('a34sound').hasError('min')   ? 'text-danger': 'text-normal'"
                                                                    formControlName="a34sound" required
                                                                    style="width: 100px;">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="mandatory">
                                                    <mat-label>35</mat-label>
                                                </td>
                                                <td>
                                                    <div class="col-md-2">

                                                        <div class="form-group">
                                                            <div class="form-group__text">
                                                                <input matInput
                                                                    [ngClass]="policyForm.get('a35pwm').hasError('pattern') || policyForm.get('a35pwm').hasError('required') || policyForm.get('a35pwm').hasError('min')   ? 'text-danger': 'text-normal'"
                                                                    formControlName="a35pwm" required
                                                                    style="width: 100px;">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <!-- <td class="mandatory">
                                                    <mat-label>Power at 35</mat-label>
                                                </td> -->
                                                <td>
                                                    <div class="col-md-2">
                                                        <div class="form-group ">
                                                            <div class="form-group__text">
                                                                <input matInput
                                                                    [ngClass]="policyForm.get('a35power').hasError('pattern') || policyForm.get('a35power').hasError('required') || policyForm.get('a35power').hasError('min')   ? 'text-danger': 'text-normal'"
                                                                    formControlName="a35power" required
                                                                    style="width: 100px;">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <!-- <td class="mandatory">
                                                    <mat-label>Airflow at 35</mat-label>
                                                </td> -->
                                                <td>
                                                    <!-- form-group--error"-->
                                                    <div class="col-md-2">
                                                        <div class="form-group">
                                                            <div class="form-group__text">
                                                                <input matInput
                                                                    [ngClass]="policyForm.get('a35airflow').hasError('pattern') || policyForm.get('a35airflow').hasError('required') || policyForm.get('a35airflow').hasError('min')   ? 'text-danger': 'text-normal'"
                                                                    formControlName="a35airflow" required
                                                                    style="width: 100px;">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <!-- <td class="mandatory">
                                                    <mat-label>Sound at 35</mat-label>
                                                </td> -->
                                                <td>
                                                    <!-- form-group--error"-->
                                                    <div class="col-md-2">
                                                        <div class="form-group">
                                                            <div class="form-group__text">
                                                                <input matInput
                                                                    [ngClass]="policyForm.get('a35sound').hasError('pattern') || policyForm.get('a35sound').hasError('required') || policyForm.get('a35sound').hasError('min')   ? 'text-danger': 'text-normal'"
                                                                    formControlName="a35sound" required
                                                                    style="width: 100px;">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="mandatory">
                                                    <mat-label>36</mat-label>
                                                </td>
                                                <td>
                                                    <div class="col-md-2">

                                                        <div class="form-group">
                                                            <div class="form-group__text">
                                                                <input matInput
                                                                    [ngClass]="policyForm.get('a36pwm').hasError('pattern') || policyForm.get('a36pwm').hasError('required') || policyForm.get('a36pwm').hasError('min')   ? 'text-danger': 'text-normal'"
                                                                    formControlName="a36pwm" required
                                                                    style="width: 100px;">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <!-- <td class="mandatory">
                                                    <mat-label>Power at 36</mat-label>
                                                </td> -->
                                                <td>
                                                    <div class="col-md-2">
                                                        <div class="form-group ">
                                                            <div class="form-group__text">
                                                                <input matInput
                                                                    [ngClass]="policyForm.get('a36power').hasError('pattern') || policyForm.get('a36power').hasError('required') || policyForm.get('a36power').hasError('min')   ? 'text-danger': 'text-normal'"
                                                                    formControlName="a36power" required
                                                                    style="width: 100px;">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <!-- <td class="mandatory">
                                                    <mat-label>Airflow at 36</mat-label>
                                                </td> -->
                                                <td>
                                                    <!-- form-group--error"-->
                                                    <div class="col-md-2">
                                                        <div class="form-group">
                                                            <div class="form-group__text">
                                                                <input matInput
                                                                    [ngClass]="policyForm.get('a36airflow').hasError('pattern') || policyForm.get('a36airflow').hasError('required') || policyForm.get('a36airflow').hasError('min')   ? 'text-danger': 'text-normal'"
                                                                    formControlName="a36airflow" required
                                                                    style="width: 100px;">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <!-- <td class="mandatory">
                                                    <mat-label>Sound at 36</mat-label>
                                                </td> -->
                                                <td>
                                                    <!-- form-group--error"-->
                                                    <div class="col-md-2">
                                                        <div class="form-group">
                                                            <div class="form-group__text">
                                                                <input matInput
                                                                    [ngClass]="policyForm.get('a36sound').hasError('pattern') || policyForm.get('a36sound').hasError('required') || policyForm.get('a36sound').hasError('min')   ? 'text-danger': 'text-normal'"
                                                                    formControlName="a36sound" required
                                                                    style="width: 100px;">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="mandatory">
                                                    <mat-label>37</mat-label>
                                                </td>
                                                <td>
                                                    <div class="col-md-2">

                                                        <div class="form-group">
                                                            <div class="form-group__text">
                                                                <input matInput
                                                                    [ngClass]="policyForm.get('a37pwm').hasError('pattern') || policyForm.get('a37pwm').hasError('required') || policyForm.get('a37pwm').hasError('min')   ? 'text-danger': 'text-normal'"
                                                                    formControlName="a37pwm" required
                                                                    style="width: 100px;">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <!-- <td class="mandatory">
                                                    <mat-label>Power at 37</mat-label>
                                                </td> -->
                                                <td>
                                                    <div class="col-md-2">
                                                        <div class="form-group ">
                                                            <div class="form-group__text">
                                                                <input matInput
                                                                    [ngClass]="policyForm.get('a37power').hasError('pattern') || policyForm.get('a37power').hasError('required') || policyForm.get('a37power').hasError('min')   ? 'text-danger': 'text-normal'"
                                                                    formControlName="a37power" required
                                                                    style="width: 100px;">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <!-- <td class="mandatory">
                                                    <mat-label>Airflow at 37</mat-label>
                                                </td> -->
                                                <td>
                                                    <!-- form-group--error"-->
                                                    <div class="col-md-2">
                                                        <div class="form-group">
                                                            <div class="form-group__text">
                                                                <input matInput
                                                                    [ngClass]="policyForm.get('a37airflow').hasError('pattern') || policyForm.get('a37airflow').hasError('required') || policyForm.get('a37airflow').hasError('min')   ? 'text-danger': 'text-normal'"
                                                                    formControlName="a37airflow" required
                                                                    style="width: 100px;">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <!-- <td class="mandatory">
                                                    <mat-label>Sound at 37</mat-label>
                                                </td> -->
                                                <td>
                                                    <!-- form-group--error"-->
                                                    <div class="col-md-2">
                                                        <div class="form-group">
                                                            <div class="form-group__text">
                                                                <input matInput
                                                                    [ngClass]="policyForm.get('a37sound').hasError('pattern') || policyForm.get('a37sound').hasError('required') || policyForm.get('a37sound').hasError('min')   ? 'text-danger': 'text-normal'"
                                                                    formControlName="a37sound" required
                                                                    style="width: 100px;">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="mandatory">
                                                    <mat-label>38</mat-label>
                                                </td>
                                                <td>
                                                    <div class="col-md-2">

                                                        <div class="form-group">
                                                            <div class="form-group__text">
                                                                <input matInput
                                                                    [ngClass]="policyForm.get('a38pwm').hasError('pattern') || policyForm.get('a38pwm').hasError('required') || policyForm.get('a38pwm').hasError('min')   ? 'text-danger': 'text-normal'"
                                                                    formControlName="a38pwm" required
                                                                    style="width: 100px;">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <!-- <td class="mandatory">
                                                    <mat-label>Power at 38</mat-label>
                                                </td> -->
                                                <td>
                                                    <div class="col-md-2">
                                                        <div class="form-group ">
                                                            <div class="form-group__text">
                                                                <input matInput
                                                                    [ngClass]="policyForm.get('a38power').hasError('pattern') || policyForm.get('a38power').hasError('required') || policyForm.get('a38power').hasError('min')   ? 'text-danger': 'text-normal'"
                                                                    formControlName="a38power" required
                                                                    style="width: 100px;">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <!-- <td class="mandatory">
                                                    <mat-label>Airflow at 38</mat-label>
                                                </td> -->
                                                <td>
                                                    <!-- form-group--error"-->
                                                    <div class="col-md-2">
                                                        <div class="form-group">
                                                            <div class="form-group__text">
                                                                <input matInput
                                                                    [ngClass]="policyForm.get('a38airflow').hasError('pattern') || policyForm.get('a38airflow').hasError('required') || policyForm.get('a38airflow').hasError('min')   ? 'text-danger': 'text-normal'"
                                                                    formControlName="a38airflow" required
                                                                    style="width: 100px;">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <!-- <td class="mandatory">
                                                    <mat-label>Sound at 38</mat-label>
                                                </td> -->
                                                <td>
                                                    <!-- form-group--error"-->
                                                    <div class="col-md-2">
                                                        <div class="form-group">
                                                            <div class="form-group__text">
                                                                <input matInput
                                                                    [ngClass]="policyForm.get('a38sound').hasError('pattern') || policyForm.get('a38sound').hasError('required') || policyForm.get('a38sound').hasError('min')   ? 'text-danger': 'text-normal'"
                                                                    formControlName="a38sound" required
                                                                    style="width: 100px;">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="mandatory">
                                                    <mat-label>39</mat-label>
                                                </td>
                                                <td>
                                                    <div class="col-md-2">

                                                        <div class="form-group">
                                                            <div class="form-group__text">
                                                                <input matInput
                                                                    [ngClass]="policyForm.get('a39pwm').hasError('pattern') || policyForm.get('a39pwm').hasError('required') || policyForm.get('a39pwm').hasError('min')   ? 'text-danger': 'text-normal'"
                                                                    formControlName="a39pwm" required
                                                                    style="width: 100px;">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <!-- <td class="mandatory">
                                                    <mat-label>Power at 39</mat-label>
                                                </td> -->
                                                <td>
                                                    <div class="col-md-2">
                                                        <div class="form-group ">
                                                            <div class="form-group__text">
                                                                <input matInput
                                                                    [ngClass]="policyForm.get('a39power').hasError('pattern') || policyForm.get('a39power').hasError('required') || policyForm.get('a39power').hasError('min')   ? 'text-danger': 'text-normal'"
                                                                    formControlName="a39power" required
                                                                    style="width: 100px;">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <!-- <td class="mandatory">
                                                    <mat-label>Airflow at 39</mat-label>
                                                </td> -->
                                                <td>
                                                    <!-- form-group--error"-->
                                                    <div class="col-md-2">
                                                        <div class="form-group">
                                                            <div class="form-group__text">
                                                                <input matInput
                                                                    [ngClass]="policyForm.get('a39airflow').hasError('pattern') || policyForm.get('a39airflow').hasError('required') || policyForm.get('a39airflow').hasError('min')   ? 'text-danger': 'text-normal'"
                                                                    formControlName="a39airflow" required
                                                                    style="width: 100px;">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <!-- <td class="mandatory">
                                                    <mat-label>Sound at 39</mat-label>
                                                </td> -->
                                                <td>
                                                    <!-- form-group--error"-->
                                                    <div class="col-md-2">
                                                        <div class="form-group">
                                                            <div class="form-group__text">
                                                                <input matInput
                                                                    [ngClass]="policyForm.get('a39sound').hasError('pattern') || policyForm.get('a39sound').hasError('required') || policyForm.get('a39sound').hasError('min')   ? 'text-danger': 'text-normal'"
                                                                    formControlName="a39sound" required
                                                                    style="width: 100px;">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="mandatory">
                                                    <mat-label>40</mat-label>
                                                </td>
                                                <td>
                                                    <div class="col-md-2">

                                                        <div class="form-group">
                                                            <div class="form-group__text">
                                                                <input matInput
                                                                    [ngClass]="policyForm.get('a40pwm').hasError('pattern') || policyForm.get('a40pwm').hasError('required') || policyForm.get('a40pwm').hasError('min')   ? 'text-danger': 'text-normal'"
                                                                    formControlName="a40pwm" required
                                                                    style="width: 100px;">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <!-- <td class="mandatory">
                                                    <mat-label>Power at 40</mat-label>
                                                </td> -->
                                                <td>
                                                    <div class="col-md-2">
                                                        <div class="form-group ">
                                                            <div class="form-group__text">
                                                                <input matInput
                                                                    [ngClass]="policyForm.get('a40power').hasError('pattern') || policyForm.get('a40power').hasError('required') || policyForm.get('a40power').hasError('min')   ? 'text-danger': 'text-normal'"
                                                                    formControlName="a40power" required
                                                                    style="width: 100px;">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <!-- <td class="mandatory">
                                                    <mat-label>Airflow at 40</mat-label>
                                                </td> -->
                                                <td>
                                                    <!-- form-group--error"-->
                                                    <div class="col-md-2">
                                                        <div class="form-group">
                                                            <div class="form-group__text">
                                                                <input matInput
                                                                    [ngClass]="policyForm.get('a40airflow').hasError('pattern') || policyForm.get('a40airflow').hasError('required') || policyForm.get('a40airflow').hasError('min')   ? 'text-danger': 'text-normal'"
                                                                    formControlName="a40airflow" required
                                                                    style="width: 100px;">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <!-- <td class="mandatory">
                                                    <mat-label>Sound at 40</mat-label>
                                                </td> -->
                                                <td>
                                                    <!-- form-group--error"-->
                                                    <div class="col-md-2">
                                                        <div class="form-group">
                                                            <div class="form-group__text">
                                                                <input matInput
                                                                    [ngClass]="policyForm.get('a40sound').hasError('pattern') || policyForm.get('a40sound').hasError('required') || policyForm.get('a40sound').hasError('min')   ? 'text-danger': 'text-normal'"
                                                                    formControlName="a40sound" required
                                                                    style="width: 100px;">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="mandatory">
                                                    <mat-label>41</mat-label>
                                                </td>
                                                <td>
                                                    <div class="col-md-2">

                                                        <div class="form-group">
                                                            <div class="form-group__text">
                                                                <input matInput
                                                                    [ngClass]="policyForm.get('a41pwm').hasError('pattern') || policyForm.get('a41pwm').hasError('required') || policyForm.get('a41pwm').hasError('min')   ? 'text-danger': 'text-normal'"
                                                                    formControlName="a41pwm" required
                                                                    style="width: 100px;">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <!-- <td class="mandatory">
                                                    <mat-label>Power at 41</mat-label>
                                                </td> -->
                                                <td>
                                                    <div class="col-md-2">
                                                        <div class="form-group ">
                                                            <div class="form-group__text">
                                                                <input matInput
                                                                    [ngClass]="policyForm.get('a41power').hasError('pattern') || policyForm.get('a41power').hasError('required') || policyForm.get('a41power').hasError('min')   ? 'text-danger': 'text-normal'"
                                                                    formControlName="a41power" required
                                                                    style="width: 100px;">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <!-- <td class="mandatory">
                                                    <mat-label>Airflow at 41</mat-label>
                                                </td> -->
                                                <td>
                                                    <!-- form-group--error"-->
                                                    <div class="col-md-2">
                                                        <div class="form-group">
                                                            <div class="form-group__text">
                                                                <input matInput
                                                                    [ngClass]="policyForm.get('a41airflow').hasError('pattern') || policyForm.get('a41airflow').hasError('required') || policyForm.get('a41airflow').hasError('min')   ? 'text-danger': 'text-normal'"
                                                                    formControlName="a41airflow" required
                                                                    style="width: 100px;">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <!-- <td class="mandatory">
                                                    <mat-label>Sound at 41</mat-label>
                                                </td> -->
                                                <td>
                                                    <!-- form-group--error"-->
                                                    <div class="col-md-2">
                                                        <div class="form-group">
                                                            <div class="form-group__text">
                                                                <input matInput
                                                                    [ngClass]="policyForm.get('a41sound').hasError('pattern') || policyForm.get('a41sound').hasError('required') || policyForm.get('a41sound').hasError('min')   ? 'text-danger': 'text-normal'"
                                                                    formControlName="a41sound" required
                                                                    style="width: 100px;">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="mandatory">
                                                    <mat-label>42</mat-label>
                                                </td>
                                                <td>
                                                    <div class="col-md-2">

                                                        <div class="form-group">
                                                            <div class="form-group__text">
                                                                <input matInput
                                                                    [ngClass]="policyForm.get('a42pwm').hasError('pattern') || policyForm.get('a42pwm').hasError('required') || policyForm.get('a42pwm').hasError('min')   ? 'text-danger': 'text-normal'"
                                                                    formControlName="a42pwm" required
                                                                    style="width: 100px;">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <!-- <td class="mandatory">
                                                    <mat-label>Power at 42</mat-label>
                                                </td> -->
                                                <td>
                                                    <div class="col-md-2">
                                                        <div class="form-group ">
                                                            <div class="form-group__text">
                                                                <input matInput
                                                                    [ngClass]="policyForm.get('a42power').hasError('pattern') || policyForm.get('a42power').hasError('required') || policyForm.get('a42power').hasError('min')   ? 'text-danger': 'text-normal'"
                                                                    formControlName="a42power" required
                                                                    style="width: 100px;">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <!-- <td class="mandatory">
                                                    <mat-label>Airflow at 42</mat-label>
                                                </td> -->
                                                <td>
                                                    <!-- form-group--error"-->
                                                    <div class="col-md-2">
                                                        <div class="form-group">
                                                            <div class="form-group__text">
                                                                <input matInput
                                                                    [ngClass]="policyForm.get('a42airflow').hasError('pattern') || policyForm.get('a42airflow').hasError('required') || policyForm.get('a42airflow').hasError('min')   ? 'text-danger': 'text-normal'"
                                                                    formControlName="a42airflow" required
                                                                    style="width: 100px;">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <!-- <td class="mandatory">
                                                    <mat-label>Sound at 42</mat-label>
                                                </td> -->
                                                <td>
                                                    <!-- form-group--error"-->
                                                    <div class="col-md-2">
                                                        <div class="form-group">
                                                            <div class="form-group__text">
                                                                <input matInput
                                                                    [ngClass]="policyForm.get('a42sound').hasError('pattern') || policyForm.get('a42sound').hasError('required') || policyForm.get('a42sound').hasError('min')   ? 'text-danger': 'text-normal'"
                                                                    formControlName="a42sound" required
                                                                    style="width: 100px;">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                        </table>
                                    </mat-form-field>
                                    <!-- </form> -->
                                </div>
                            </td>
                        </tr>

                    </tbody>
                </table>
            </div>
        </form>
    </mat-form-field>
</div>

<div mat-dialog-actions>
    <table class="table table-bordered">
        <tbody>
            <tr>
                <td>
                    <mat-card-actions>
                        <button mat-button (click)="onSave()" class="btn btn--small "
                            style="height: 24px;margin: 5px 20px 0 0;"
                            [disabled]="!(this.policyForm.valid && selectedPid)">Save &
                            Close</button>
                    </mat-card-actions>
                    <button mat-button (click)="onNoClick()" cdkFocusInitial class="btn btn--small "
                        style="height: 24px;margin: 5px 20px 0 0;"
                        [disabled]="!(this.policyForm.valid && selectedPid)">Save</button>
                </td>
            </tr>
        </tbody>
    </table>
</div>