import { Injectable } from '@angular/core';
import { AuthClientService } from './auth-client.service';

@Injectable({
  providedIn: 'root',
})
export class StartupService {
  constructor(
    private authClientService: AuthClientService,
  ) { }

  load(): Promise<boolean> {
    localStorage.setItem('userData', null);
    this.authClientService.startAuthService();
    return new Promise((resolve, reject) => resolve(true));
  }
}
