<span *ngIf="sequenceId&&voltage" (click)="gerServerDetails(sequenceId, voltage, true)" class="icon-refresh icon-size-18" title="Refresh Data"></span>
<div class="container project-dynamic-container">
  <div>
    <form novalidate *ngIf="dataObject" (ngSubmit)="onSubmit(dynamicForm?.value)" [formGroup]="dynamicForm">
      <div class="row server-image-row">

        <div class="col-xs-6 col-md-8 server-image">
          <div class="row">
            <div class="server-image-div col-md-12">
              <img alt="Cisco UCS  5108 Blade Chassis (AC) V2"
                [src]="dataObject?.image ? getImage(dataObject?.image) : dataObject?.imageURL ? dataObject?.imageURL : defaultImage" class="server-image">
            </div>
          </div>
          <div class="row">
            <div class="row-operating-slider col-md-6" style="margin-top: 30px;"
              *ngIf="dataObject.type=='RACK' || dataObject.type=='BLADE' || dataObject.type=='CARTRIDGE' || dataObject.type=='NEXUS' || dataObject.type=='FI' || dataObject.type.includes('AdditionalPlatform')">
              <mat-slider class="mat-slider cdk-focused" thumbLabel [displayWith]="formatLabel" min="0"
                value="systemWorkLoad" max="100" style="margin-top: 25px;" formControlName="systemWorkLoad"
                (input)="onSystemWorkloadChange($event)">
              </mat-slider>
              <div class="idle-max"><span style="color:#5A7789; font-weight:500">Idle</span>
                <span style="color:#5A7789; font-weight:500">Max</span>
              </div>
              <div class="operating-label">{{'SYSTEM_WORKLOAD_FACTOR' | translate}}</div>
            </div>
            <div class="col-md-6 row-operating-slider" style="margin-top: 30px;"
              *ngIf="dataObject?.legacyPlatform == false && dataObject?.isFanPolicyReq == true">
              <mat-slider class="mat-slider cdk-focused" thumbLabel [displayWith]="formatOTLabel" min="5"
                value="operatingTemp" max="45" style="margin-top: 25px;" formControlName="operatingTemp"
                (change)="checkForChange()">
              </mat-slider>
              <div class="idle-max"><span style="color:#5A7789; font-weight:500">Low</span>
                <span style="color:#5A7789; font-weight:500">High</span>
              </div>
              <div class="operating-label">{{'OPERATING_TEMPERATURE' | translate}}</div>
            </div>
          </div>
          <div class="row" style="margin:30px 0px;"
            *ngIf="dataObject?.legacyPlatform == false && dataObject?.isFanPolicyReq == true">
            <div class="col-md-6" style="display: flex; align-items: center;" *ngIf="dataObject?.fanPolicies?.visible">
              <div class="form-group dropdown grp-voltage">
                <div class="form-group__text select div-mode">
                  <select id="select-type" class="select-small mode" formControlName="fanPolicy"
                    (change)="checkForChange()">
                    <option *ngFor="let element of dataObject?.fanPolicies?.options; let i = index;"
                      [ngValue]="element">
                      {{element.label | translate }}
                    </option>
                  </select>
                  <label for="select-type-basic" class="select-label results-label">{{'FAN_POLICY'
                    |translate}}</label>
                </div>
              </div>
            </div>
            <div class="col-md-6" style="display: flex; align-items: center;">
              <div class="form-group dropdown grp-voltage">
                <div class="form-group__text select div-mode">
                  <select id="select-type" class="select-small mode" formControlName="fanFailure"
                    (change)="checkForChange()">
                    <option value="No">{{'No' | translate}}</option>
                    <option value="Yes">{{'Yes' | translate}}</option>
                  </select>
                  <label for="select-type-basic" class="select-label results-label">{{'FAN_FAILURE'
                    |translate}}</label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xs-6 col-md-4 server-estimate-div"
          *ngIf="((hasPowerCapWarning || hasPowerSupplyStatusWarning) && !dynamicForm.valid || dynamicForm?.valid) && isFormValid && !isFormIncomplete">
          <!-- *ngIf="dynamicForm?.valid" -->
          <div class="power-estimate-div row">
            <h5 class="power-estimate-header col-xs-12 col-md-12">{{'ESTIMATES' | translate}}</h5>

          </div>
          <div></div>
          <div class="row estimate-row">
            <div class="col-xs-7 col-md-7 estimate-label">{{'MAX_INPUT_POWER' |translate}}</div>
            <div class="col-md-3 col-xs-3 estimate-value">{{maxInputPower | number : '1.2-2'}}</div>
            <div class="col-xs-2 col-md-2 estimate-value">{{maxInputPowerUnit}}</div>
          </div>
          <div class="row estimate-row">
            <div class="col-md-7 estimate-label">{{'INPUT_POWER' |translate}}</div>
            <div class="col-md-3 estimate-value">{{inputPower | number : '1.2-2'}}</div>
            <div class="col-xs-1 col-md-2 estimate-value">{{maxInputPowerUnit}}</div>
          </div>
          <div class="row estimate-row">
            <div class="col-md-7 estimate-label">{{'IDLE_INPUT_POWER' |translate}}</div>
            <div class="col-md-3 estimate-value">{{idleInputPower | number : '1.2-2'}}</div>
            <div class="col-xs-1 col-md-2 estimate-value">{{maxInputPowerUnit}}</div>
          </div>
          <div class="row estimate-row">
            <div class="col-md-7 estimate-label">{{'SYSTEM_WORKLOAD' |translate}}</div>
            <div class="col-md-3 estimate-value">{{systemWorkLoad | number :'1.0-0'}}</div>
            <div class="col-xs-1 col-md-2 estimate-value">{{systemWorkloadUnit}}</div>
          </div>
          <div *ngIf="!(dataObject?.legacyPlatform == false && server?.level == 'Child')">
            <div class="row estimate-row">
              <div class="col-md-7 estimate-label">{{'INPUT_CURRENT' |translate}}</div>
              <div class="col-md-3 estimate-value">{{inputCurrent | number : '1.2-2'}}</div>
              <div class="col-xs-1 col-md-2 estimate-value">{{currentUnit}}</div>
            </div>
            <div class="row estimate-row" *ngIf="dataObject?.legacyPlatform !== false">
              <div class="col-md-7 estimate-label">{{'AIR_FLOW' |translate}}</div>
              <div class="col-md-3 estimate-value" *ngIf="(globalUnit == 'Imperial')">{{airFlowImperial | number :
                '1.2-2'}}</div>
              <div class="col-md-3 estimate-value" *ngIf="(globalUnit == 'Metric')">{{airFlowMetric | number :
                '1.2-2'}}</div>
              <div class="col-xs-1 col-md-2 estimate-value">{{airflowUnit}}</div>
            </div>
            <div class="row estimate-row" *ngIf="dataObject?.legacyPlatform == false">
              <div class="col-md-7 estimate-label">{{'AIR_FLOW_MIN' |translate}}</div>
              <div class="col-md-3 estimate-value" *ngIf="(globalUnit == 'Imperial')">{{minAirflowImperial | number :
                '1.2-2'}}</div>
              <div class="col-md-3 estimate-value" *ngIf="(globalUnit == 'Metric')">{{minAirflowMetric | number :
                '1.2-2'}}</div>
              <div class="col-xs-1 col-md-2 estimate-value">{{airflowUnit}}</div>
            </div>
            <div class="row estimate-row" *ngIf="dataObject?.legacyPlatform == false">
              <div class="col-md-7 estimate-label">{{'AIR_FLOW_MAX' |translate}}</div>
              <div class="col-md-3 estimate-value" *ngIf="(globalUnit == 'Imperial')">{{maxAirflowImperial | number :
                '1.2-2'}}</div>
              <div class="col-md-3 estimate-value" *ngIf="(globalUnit == 'Metric')">{{maxAirflowMetric | number :
                '1.2-2'}}</div>
              <div class="col-xs-1 col-md-2 estimate-value">{{airflowUnit}}</div>
            </div>
            <div class="row estimate-row" *ngIf="dataObject?.legacyPlatform == false">
              <div class="col-md-7 estimate-label">{{'ACOUSTICS_MIN' |translate}}</div>
              <div class="col-md-3 estimate-value">{{minAcoustics | number :
                '1.2-2'}}</div>
              <div class="col-xs-1 col-md-2 estimate-value">{{acousticsUnit}}</div>
            </div>
            <div class="row estimate-row" *ngIf="dataObject?.legacyPlatform == false">
              <div class="col-md-7 estimate-label">{{'ACOUSTICS_MAX' |translate}}</div>
              <div class="col-md-3 estimate-value">{{maxAcoustics | number :
                '1.2-2'}}</div>
              <div class="col-xs-1 col-md-2 estimate-value">{{acousticsUnit}}</div>
            </div>
            <div class="row estimate-row">
              <div class="col-md-7 estimate-label">{{'COOLING' |translate}}</div>
              <div class="col-md-3 estimate-value" *ngIf="(globalUnit == 'Imperial')">{{coolingImperial | number :
                '1.2-2'}}</div>
              <div class="col-md-3 estimate-value" *ngIf="(globalUnit == 'Metric')">{{coolingImperial | number :
                '1.2-2'}}</div>
              <div class="col-xs-1 col-md-2 estimate-value">{{coolingUnit}}</div>
            </div>
          </div>
          <div class="row estimate-row">
            <div class="col-md-7 estimate-label">{{'WEIGHT' |translate}}</div>
            <ng-container *ngIf="globalUnit == 'Imperial' && !isNaN(serverWeightImperial) || globalUnit == 'Metric' && !isNaN(serverWeightMetric); else showNaN">
            <div class="col-md-3 estimate-value" *ngIf="(globalUnit == 'Imperial')">{{serverWeightImperial | number
              : '1.2-2'}}</div>
            <div class="col-md-3 estimate-value" *ngIf="(globalUnit == 'Metric')">{{serverWeightMetric | number :
              '1.2-2'}}</div>
            </ng-container>
            <ng-template #showNaN> <div class="col-md-3 estimate-value"> NA </div> </ng-template>

            <div class="col-xs-1 col-md-2 estimate-value">{{weightUnit}}</div>
          </div>

          <div class="row estimate-row">
            <button class="btn  btn--primary  btn--justified"
              [disabled]="!(hasPowerCapWarning || hasPowerSupplyStatusWarning) && !dynamicForm?.valid || !isFormValid"
              (click)="addToProject($event, server.serverId, server.type)">{{addToProjectLabel | translate }}</button>
          </div>
        </div>

        <div class="col-xs-6 col-md-4 server-estimate-div div-estimate-background"
          *ngIf="!(hasPowerCapWarning || hasPowerSupplyStatusWarning) && !dynamicForm.valid  || !isFormValid || isFormIncomplete">

          <span class="icon-info-outline icon-size-24"></span>
          <span>{{ 'Please Complete the Configuration to calculate the power estimates' | translate }} </span>
        </div>
      </div>
      <div class="row" style="margin:10px;" *ngIf="dataObject?.legacyPlatform == false">

      </div>
      <div class="row div-chassis-name">
        <h5>{{ 'SELECT_CONFIGURATION' | translate}}</h5>
      </div>
      <div class="row div-voltage">
        <div class="col-6 form-group dropdown grp-voltage">
          <div class="form-group__text div-mode row fit-width" *ngIf="dataObject?.redundancy?.visible">
            <div class="col-12">
              <label style="margin-right: 0.5rem; margin-bottom: 0.5rem; display: inline-block;">{{'REDUNDANCY_MODE' |translate}}</label>
            </div>
            <div class="col-12">
              <ng-select appearance="outline" [searchable]="false" class="select-small " formControlName="redundancyMode"
              (change)="changeRMode($event, dataObject?.type)"
              [ngClass]="(dynamicForm.get('redundancyMode').invalid) ? 'select-danger': 'select-normal'"
              [items]="dataObject?.redundancy?.options">
              <ng-option
                *ngFor="let element of dataObject?.redundancy?.options | orderBy:{property: 'value', descending: false}">
                  {{element.label | translate }}
              </ng-option>
            </ng-select>
            </div>
          </div>
          <div class="form-group__text div-mode row fit-width" *ngIf="dataObject?.voltage?.visible">
            <div class="col-12">
              <label style="margin-right: 0.5rem;margin-bottom: 0.5rem;display: inline-block;">{{'INPUT_VOLTAGE' |translate}}</label>
            </div>
            <div class="col-12">
              <ng-select appearance="outline" [searchable]="false" class="select-small "
              [ngClass]="(dynamicForm.get('voltage').invalid) ? 'select-danger': 'select-normal'"
              formControlName="voltage" (change)="changeVoltage($event)" [items]="dataObject?.voltage?.options">
              <ng-option
                *ngFor="let element of dataObject?.voltage?.options | orderBy:{property: 'value', descending: false}">
                  {{element.label | translate }}
              </ng-option>
            </ng-select>
            </div>
          </div>

        </div>
        <div class="col-6 config-actions">
          <div class="row config-end">
          <button class="btn btn--primary reset" (click)="openModal('reset')">{{'RESET'
            |translate}}</button>
          <button class="btn btn--primary cancel" (click)="openModal('cancel')">{{'CANCEL' |translate}}</button>
          <div *ngIf="(dataObject?.type !== 'CHASSIS') && (dataObject?.type !== 'MSERIES')"
            style="margin-right: -1rem;">
            <button class="btn btn--primary" style="margin-left:1rem"
              [disabled]="!(hasPowerCapWarning || hasPowerSupplyStatusWarning) && !dynamicForm.valid  || !isFormValid || isFormIncomplete"
              (click)="openSaveTemplateModal(dataObject?.type, dataObject?.secondaryClasses, dynamicForm.value)">{{'SAVE_TEMPLATE'
              | translate}}</button>
          </div>
          </div>
        </div>
      </div>
      <div class="row row-form-property">
        <div class="col-md-2 div-header item-title">
          <span>{{'ITEM' |translate}}</span>
        </div>
        <div class="col-md-2 div-header qty-title" *ngIf="dataObject?.type !== 'CHASSIS'">
          <span>{{'QUANTITY' |translate}}</span>
        </div>
        <div class="col-md-8 div-header config-title">
          <span>{{'CONFIGURATION' |translate}}</span>
        </div>
      </div>
      <div class="row" *ngFor="let prop of dataObject?.others">

        <div class="col-2 row-label" *ngIf="prop?.visible">
          {{prop?.displayName}}
        </div>
        <div class="col-10" [formArrayName]="prop?.group" *ngIf="prop?.controlType == 'controlArray'">
          <!-------------------- Dependent dropdowns ------------------------->
          <div class="dynamic-div">
            <!-- <div *ngFor="let control of prop?.controls"> -->
            <div *ngFor="let control of dynamicForm.get(prop?.group)['controls'];  let i = index" [formGroupName]="i">
              <!-- <div class="col-md-3"> {{prop?.label}}</div> -->
              <div class="form-group dropdown row others-row">

                <div class="col-md-3 div-mode" *ngIf="prop?.controls[0]?.visible">
                  <div class="form-group__text ">
                    <ng-select appearance="outline" [searchable]="false" class="select-small "
                      [ngClass]="(control?.invalid) ? 'select-danger': 'select-normal'" formControlName="Quantity"
                      (change)="onControlChange($event, prop?.group,'Quantity', control)"
                      [dropdownPosition]="(i == (dynamicForm.get(prop?.group)['controls'].length-1) || (i==dynamicForm.get(prop?.group)['controls'].length-2)) ? 'top' : 'bottom'"
                      [items]="optionsObject[prop?.group + 'Quantity']">
                      <ng-option
                        *ngFor="let element of optionsObject[prop?.group + 'Quantity'] | orderBy:{property: 'value', descending: false}">
                          {{element.label | translate }}
                      </ng-option>
                    </ng-select>
                  </div>
                </div>
                <div class="col-md-9 div-mode" *ngIf="prop?.controls[1]?.visible"
                  [ngClass]="(prop?.expandable) ? 'div-padding': ''">
                  <div class="row" style="width: inherit;">
                    <div class="col-11" style="width: inherit;">
                      <div class="form-group__text parent-width">
                        <ng-select appearance="outline" [searchable]="true" class="auto-grow select-big parent-width"
                          [ngClass]="(control?.invalid) ? 'select-danger': 'select-normal'"
                          formControlName="Configuration"
                          (change)="onControlChange($event, prop?.group, 'Configuration', control)"
                          [dropdownPosition]="(i == (dynamicForm.get(prop?.group)['controls'].length-1) ) ? 'top' : 'bottom'"
                          [items]="optionsObject[prop?.group + 'Configuration']">
                          <ng-option
                            *ngFor="let element of optionsObject[prop?.group + 'Configuration'] | orderBy:{property: 'value', descending: false}">
                              [ngValue]="element">
                              {{element.label | translate }}
                          </ng-option>
                        </ng-select>
                      </div>
                    </div>
                    <div class="col-1 add-remove">
                      <a (click)="addProcesoorFormGroup(prop?.group,i)" *ngIf="i ==0 && prop?.expandable"><span
                          class="icon-add icon-size-14 icon-expandable"></span></a>
                      <a (click)="removeOrClearProcessor(i, prop?.group)" *ngIf="i >0"><span
                          class="icon-close icon-size-14 icon-expandable"></span></a>
                    </div>
                  </div>

                </div>

              </div>
            </div>
          </div>

        </div>
        <!------------------------------------ Independent dropdowns ---------------------------------->
        <div class="col-10" *ngIf="prop?.controlType == 'controlIndependent'">
          <div class="dynamic-indep-div" [formArrayName]="prop?.group" *ngIf="prop?.visible">

            <!-- <div *ngFor="let control of prop?.controls"> -->
            <div *ngFor="let control of dynamicForm.get(prop?.group)['controls'];  let i = index" [formGroupName]="i">
              <!-- <div class="col-md-3"> {{prop?.label}}</div> -->
              <div class="form-group dropdown row others-row">

                <div class="col-md-3 div-mode" *ngIf="prop?.controls[0]?.visible">
                  <div class="form-group__text ">
                    <ng-select appearance="outline" [searchable]="true" class="select-small "
                      [ngClass]="(control?.invalid) ? 'select-danger': 'select-normal'"
                      [dropdownPosition]="(i == (dynamicForm.get(prop?.group)['controls'].length-1) || (i==dynamicForm.get(prop?.group)['controls'].length-2)) ? 'top' : 'bottom'"
                      formControlName="Quantity" (change)="checkIndependentDropdownsForChange(control)"
                      [items]="prop?.controls[0]?.options">
                      <ng-option
                        *ngFor="let element of prop?.controls[0]?.options | orderBy:{property: 'value', descending: false}">
                          {{element.label | translate }}
                      </ng-option>
                    </ng-select>
                  </div>
                </div>
                <div class="col-md-9 div-mode" *ngIf="prop?.controls[1]?.visible"
                  [ngClass]="(prop?.expandable) ? 'div-padding': ''">
                  <div class="row" style="width: inherit;">
                    <div class="col-11" style="width: inherit;">
                      <div class="form-group__text parent-width">
                        <ng-select appearance="outline" [searchable]="true" class="auto-grow select-big parent-width"
                          [ngClass]="(control?.invalid) ? 'select-danger': 'select-normal'"
                          [dropdownPosition]="(i == (dynamicForm.get(prop?.group)['controls'].length-1) || (i==dynamicForm.get(prop?.group)['controls'].length-2)) ? 'top' : 'bottom'"
                          formControlName="Configuration" (change)="checkIndependentDropdownsForChange(control)"
                          [items]="prop?.controls[1]?.options">
                          <ng-option
                            *ngFor="let element of prop?.controls[1]?.options | orderBy:{property: 'value', descending: false}">
                              {{element.label | translate }}
                          </ng-option>
                        </ng-select>
                      </div>
                    </div>
                    <div class="col-1 add-remove">
                      <a (click)="addProcesoorFormGroup(prop?.group,i)" *ngIf="i ==0 && prop?.expandable"><span
                          class="icon-add icon-size-14 icon-expandable"></span></a>
                      <a (click)="removeOrClearProcessor(i, prop?.group)" *ngIf="i >0"><span
                          class="icon-close icon-size-14 icon-expandable"></span></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>


          </div>
        </div>

      </div>

    </form>
    <div style="height: 100px;"></div>
  </div>
</div>