import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { GridOptions } from "ag-grid-community";
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ApiService } from 'src/app/services/api.service';
import { UrlConstant } from 'src/constants/url.constants';
import { ComponentConstant } from 'src/constants/components.constants';
import { EditRenderComponent} from 'src/app/shared/components/edit-render/edit-render.component';
import { CreateDimmsfamilyComponent} from 'src/app/components/admin/admin-tab/create-components/create-dimmsfamily/create-dimmsfamily.component';
import { ToasterService } from 'src/app/shared/components/toast/toast.service';
import { errorCodes } from 'src/constants/errorCodes';
import { AdminService } from 'src/app/services/admin.service';
import { Router } from '@angular/router';
export interface DialogData {
  displayName : string;
  internalName : string;  
  type : string;
}

@Component({
  selector: 'app-manage-dimms-family',
  templateUrl: './manage-dimms-family.component.html',
  styleUrls: ['./manage-dimms-family.component.css']
})
export class ManageDimmsFamilyComponent implements OnInit {
  @BlockUI() blockUI: NgBlockUI; 
  subscriptions: Subscription = new Subscription();
  errorMsg = '';
  columnDefs;
  frameworkComponents;
  defaultColDef;
  rowData: [];
  gridOptions: GridOptions = {};
  gridApi: any;
  gridColumnApi: any;
  flagBackButton = false;
  componentIdx: number;

  constructor(
    private apiService: ApiService,
    private httpClient: HttpClient,
    private toaster: ToasterService,
    private adminService: AdminService,
    public dialog: MatDialog,
    private router: Router){ 

      // this.componentIdx = this.router.getCurrentNavigation().extras.state.idx;
      this.getManageDimmsFamilyList();      
      this.columnDefs = [
        { headerName: 'Name' , field:'text'},
        { headerName: 'Rank', field: 'rank'},
        { headerName: 'Speed (MHz)', field:'speed'},
        { headerName: 'Silicon Size', field:'siliconSize'},
        { headerName: 'Updated By', field:'editor'},
        { headerName: 'Last Updated' , field:'lastUpdated' ,filter : 'agDateColumnFilter',
         cellRenderer: (data) => {
         // return data.value ? moment(data.value).format('MMM DD, YYYY') : null;
         return data.value ? moment(data.value).format('MMM DD, YYYY') : moment(data.data.created).format('MMM DD, YYYY');
        }},
        {
          headerName: "",
          minWidth: 40,
          maxWidth: 50,
          sortable: false,
          filter: false,
          cellRenderer: "buttonEditRenderer",
          cellRendererParams: {
            onClick: this.onEditButtonClick.bind(this),
            label: "Edit",
          },
        
        },
        {
          headerName: "",
          minWidth: 40,
          maxWidth: 50,
          sortable: false,
          filter: false,
          cellRenderer: "buttonEditRenderer",
          cellRendererParams: {
            onClick: this.onDeleteButtonClick.bind(this),
            label: "Delete",
          },
         
        },
        
        { headerName: 'RAID', field:'raid',hide:true},
        { headerName: 'id', field:'id',hide:true},
        { headerName: 'sequence', field:'sequence',hide:true},
        { headerName: 'type', field:'type',hide:true},
        { headerName: 'name', field:'name',hide:true},
        { headerName: 'index' , field:'index',hide:true},
        { headerName: 'voltage', field:'voltage',hide:true},
        { headerName: 'voltageType', field:'voltageType',hide:true},
        { headerName: 'ts', field:'ts',hide:true},
        { headerName: 'created', field:'created',hide:true},
        { headerName: 'error' , field:'error',hide:true},
        { headerName: 'successMessage' , field:'successMessage',hide:true}
    ];
      this.defaultColDef = {
      editable: false,
      sortable: true,
      flex: 1,
      minWidth: 100,
      filter: true,
      resizable: true,
    };
    this.frameworkComponents = {
      buttonEditRenderer : EditRenderComponent,            
    };
  }
  paginationPageSize = 10; 
  
  ngOnInit(): void { 
    this.adminService.getDimmFamilyData().subscribe(data => {
      if(data) {
     this.flagBackButton = true;
      this.getDuplicateRecords(data);
      }
    }) ;   
   }

  getDuplicateRecords(data: any) {    
   const filter = {
    text: {
          filterType: 'text',
          type: 'equals',
          filter: data.text
      },     
  }
    this.gridApi.setFilterModel(filter)
  }
  getManageDimmsFamilyList(){
    let dimmFamilies = ComponentConstant.DIMM_FAMILIES;
    const url = UrlConstant.MANAGECOMPONENTS + '/?component=' + dimmFamilies ;
    this.blockUI.start('Loading...');
    this.subscriptions.add(
      this.apiService.getMethod(url).subscribe((data: any) => {
        this.rowData = data;
        this.blockUI.stop();
      }, error =>{  
        this.errorMsg= error?.error ? error.error?.error?.errorString : errorCodes.GENERIC_ERROR_MESSAGE;
        this.toaster.show('error', this.errorMsg , error ? error?.message : '');         
      }));
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(CreateDimmsfamilyComponent, {      
      data: {
               type : ComponentConstant.DIMM_FAMILIES
            }            
    });   

    dialogRef.afterClosed().subscribe(result => {     
      if (result !== 'cancel') {
        this.getManageDimmsFamilyList();
      }             
    });
  }

  /*createDimmsFamily(data:any){
    const url = UrlConstant.MANAGECOMPONENTS ;    
    this.subscriptions.add(
      this.apiService.postMethod(url,data).subscribe((data: any) => {
        this.showLoader = false; 
        for (let [key, value] of Object.entries(data)) {
          
          if (key == "modifiedCount" && value > 0){
            
            this.getManageDimmsFamilyList();
            break;
          }
        }       
        
      })
    );
  }*/
  
  onEditButtonClick(params: any) {
    
    this.getManageDimmsFamilyList();     
  }

  onDeleteButtonClick(params: any) {
    
    this.gridOptions.api?.updateRowData({ remove: [params.node.data.Id] });     
  }

  onGridReady(params) {    
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  } 
  resetGrid() {
    this.gridApi.setFilterModel(null);
    this.flagBackButton = false;
  }
  ngOnDestroy() {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }
  
}

