import { Component, OnInit, Inject, HostListener, ChangeDetectorRef } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormControl, UntypedFormBuilder, UntypedFormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ComponentConstant } from 'src/constants/components.constants';
import { ApiService } from 'src/app/services/api.service';
import { UrlConstant } from 'src/constants/url.constants';
import { ToasterService } from "src/app/shared/components/toast/toast.service";
import { errorCodes } from 'src/constants/errorCodes';
import { CngContentConfigType, CngModalService, CngModalSize } from '@cisco/cui-ng';
import { ConfirmationBoxComponent } from 'src/app/shared/components/confirmation-box/confirmation-box.component';
import { TranslateService } from '@ngx-translate/core';
import { AdminService } from 'src/app/services/admin.service';
import { PlatformConstant } from 'src/constants/platform.constants';
import { AdditionalAttributesComponent } from 'src/app/shared/components/additional-attributes/additional-attributes.component';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { AppConstant } from 'src/constants/app.constants';

export interface DialogData {
  displayName: string;
  internalName: string;
  pid: string;
  ioType: string;
  idleWatts: string;
  maxWatts: string;
  mtbf: string;
  selectedIoType: string;
  type: string;
  id: string;
  update: boolean;
  released: string;
  raid: any;
  raid1: any;
  raid5: any;
  raid6: any;
  raid10: any;
  raid50: any;
  raid60: any;
}
interface IoType {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-create-newcomponent',
  templateUrl: './create-newcomponent.component.html',
  styleUrls: ['./create-newcomponent.component.css']
})
export class CreateNewcomponentComponent implements OnInit {
  subscriptions: Subscription = new Subscription();
  RAID0 = true;
  public ioDeviceForm: UntypedFormGroup;
  constructor(public dialogRef: MatDialogRef<CreateNewcomponentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private apiService: ApiService,
    private toaster: ToasterService,
    private formBuilder: UntypedFormBuilder,
    private modalService: CngModalService,
    private translate: TranslateService,
    private adminService: AdminService,
    public dialog: MatDialog,
    private cdr: ChangeDetectorRef) {
    this.validations = this.formBuilder.group({
      displayName: new UntypedFormControl('', [
        Validators.required, Validators.pattern(AppConstant.VALID_INPUT_REGEX)]),
      internalName: new UntypedFormControl('', [
        Validators.required,  Validators.pattern(AppConstant.VALID_INPUT_REGEX)]),
      ioType: new UntypedFormControl('', [Validators.required]),
      idleWatts: new UntypedFormControl('', [
        Validators.pattern(AppConstant.VALID_INPUT_REGEX),
        Validators.required, Validators.min(0.1)]),
      maxWatts: new UntypedFormControl('', [
        Validators.pattern(AppConstant.VALID_INPUT_REGEX),
        Validators.required, Validators.min(0.1),
      ]),
      mtbf: new UntypedFormControl('', []),
      raid: new UntypedFormControl(),
      raid1: new UntypedFormControl(),
      raid5: new UntypedFormControl(),
      raid6: new UntypedFormControl(),
      raid10: new UntypedFormControl(),
      raid50: new UntypedFormControl(),
      raid60: new UntypedFormControl()
    })

  }

  ioTypes: IoType[] = [
    { value: 'MEZ', viewValue: 'MEZ' },
    { value: 'PCI', viewValue: 'PCI' },
    { value: 'RAID', viewValue: 'RAID' },
    { value: 'PCI-RAID', viewValue: 'PCI-RAID' }
  ];

  @BlockUI() blockUI: NgBlockUI;


  setCheckBox(completed: boolean) {

    this.RAID0 = completed;
  }
  setCheckBox1(completed1: boolean) {

  }
  setCheckBox5(completed: boolean) {

    this.RAID0 = completed;
  }
  setCheckBox6(completed1: boolean) {

  }
  setCheckBox10(completed: boolean) {

    this.RAID0 = completed;
  }
  setCheckBox50(completed1: boolean) {

  }
  setCheckBox60(completed: boolean) {

    this.RAID0 = completed;
  }


  validations: UntypedFormGroup;
  selectedIoType: any;
  dialogConfig;
  errorMsg = '';
  showLoader: boolean = false;
  type;
  released;
  update: boolean = false;
  isDisabled: boolean = true;

  saveCloseFlag: boolean = true;
  onNoClick(): void {
    this.type = this.data.type;
    this.update = this.data.update;

    this.saveCloseFlag = false;
    this.dataToSave();
    //this.dialogRef.close('cancel');
  }

  selectIOType(event: any) {

    // 
    // if ((event.target as HTMLSelectElement).value == 'PCI'
    //   || (event.target as HTMLSelectElement).value == "MEZ") {
    if (event.value.value == 'PCI' || event.value.value == 'MEZ') {
      this.isDisabled = true;
      this.validations.controls['raid'].patchValue(false);
      this.validations.controls['raid1'].patchValue(false);
      this.validations.controls['raid5'].patchValue(false);
      this.validations.controls['raid6'].patchValue(false);
      this.validations.controls['raid10'].patchValue(false);
      this.validations.controls['raid50'].patchValue(false);
      this.validations.controls['raid60'].patchValue(false);
    }
    else {
      this.isDisabled = false;
    }
  }
  showPID = true;
  ngOnInit(): void {
    this.showPID = true;


    if (this.data.update) {

      //for pid search
      this.selectedPid = this.data.pid;

      //addional fields
      this.additionalFields = this.data["additionalFields"] ? this.data["additionalFields"] : [];


      if (this.data.ioType) {
        // this.selectedIoType = this.data.ioType;
        this.selectedIoType = this.ioTypes.find(row => this.data.ioType == row.value);


        if (this.data.ioType == 'PCI' || this.data.ioType == 'MEZ') {
          this.isDisabled = true;
        }
        else {
          this.isDisabled = false;
        }
      }
      if ((this.data.ioType != 'PCI' && this.data.ioType != 'MEZ')
        || this.data.raid != null || this.data.raid != "" || this.data.raid != undefined) {

        var splitted = this.data.raid.split(",");
        for (let i = 0; i < splitted.length; i++) {
          switch (splitted[i]) {
            case "00": {
              this.validations.controls['raid'].patchValue(true);
              break;
            }
            case "01": {
              this.validations.controls['raid1'].patchValue(true);
              break;
            }
            case "05": {
              this.validations.controls['raid5'].patchValue(true);
              break;
            }
            case "06": {
              this.validations.controls['raid6'].patchValue(true);
              break;
            }
            case "10": {
              this.validations.controls['raid10'].patchValue(true);
              break;
            }
            case "50": {
              this.validations.controls['raid50'].patchValue(true);
              break;
            }
            case "60": {
              this.validations.controls['raid60'].patchValue(true);
              break;
            }

          }
        }
      } else {
        this.isDisabled = true;

      }



    }
  }

  dataToSave() {
    if (this.validations.valid) {
      let raid = "";
      if (this.validations.value.raid) {
        raid = "00";
      }
      if (this.validations.value.raid1) {
        if (raid != "") {
          raid = raid + "," + "01";
        } else {
          raid = "01";
        }

      }
      if (this.validations.value.raid5) {
        if (raid != "") {
          raid = raid + "," + "05";
        } else {
          raid = "05";
        }
      }
      if (this.validations.value.raid6) {
        if (raid != "") {
          raid = raid + "," + "06";
        } else {
          raid = "06";
        }
      }
      if (this.validations.value.raid10) {
        if (raid != "") {
          raid = raid + "," + "10";
        } else {
          raid = "10";
        }
      }
      if (this.validations.value.raid50) {
        if (raid != "") {
          raid = raid + "," + "50";
        } else {
          raid = "50";
        }
      }
      if (this.validations.value.raid60) {
        if (raid != "") {
          raid = raid + "," + "60";
        } else {
          raid = "60";
        }
      }
      let ioTypeToSave = this.validations.get('ioType').value.value;
      if (ioTypeToSave == 'MEZ' || ioTypeToSave == 'PCI') {
        raid = "";
      }
      let data = {
        "type": this.data.type,
        "role": "Admin",
        "displayName": this.validations.value.displayName,
        "internalName": this.validations.value.internalName,
        "ioType": ioTypeToSave,
        "idleWatts": this.validations.value.idleWatts,
        "maxWatts": this.validations.value.maxWatts,
        // "pid": this.validations.value.pid,
        "pid": this.selectedPid,

        "mtbf": this.validations.value.mtbf,
        "raid": raid,//this.validations.value.raid,
        "released": "",
        "additionalFields": this.setAdditionalFields(),
        "allowDuplicates": false
      };
      if (!this.data.update) {
        let result = this.createIODevice(data);
      } else {
        data.released = this.data.released;
        data["id"] = this.data.id;
        let result = this.updateIODevice(data);
      }

    } else {

    }

  }
  setAdditionalFields() {
    let array = [];
    if (this.additionalFields?.length > 0) {
      this.additionalFields.forEach(element => {
        let row = {
          "name": element.name,
          "type": element.type,
          "value": element.value
        }
        array.push(row);
      });
    }
    else
      array = [];

    return array;
  }

  onSave() {
    this.dataToSave();
  }

  createIODevice(reqData: any) {
    const url = UrlConstant.MANAGECOMPONENTS;
    this.blockUI.start('Loading...');
    let params = [];
    params.push(reqData);
    params = params.concat(this.applyToRecords);
    params = params.concat(this.updatedRecords);



    this.subscriptions.add(
      this.apiService.postMethod(url, params).subscribe((data: any) => {

        this.refreshData = true;
        if (data.modifiedCount > 0) {
          this.toaster.show('success', errorCodes.NEW_RECORD, '');
          if (this.saveCloseFlag) {
            window.scroll(0, 0);
            this.dialogRef.close({
              data: {
                action: PlatformConstant.ACTION_REFRESH
              }
            });
          } else {
            this.resetForm();
          }

        } else if (data.modifiedCount == 0 && data.error.errorString == "Duplicate Data Found") {
          reqData["allowDuplicates"] = true;
          this.blockUI.start("Loading...");
          this.subscriptions.add(
            this.apiService.postMethod(url, params).subscribe((data: any) => {
              this.blockUI.stop();
              if (data.modifiedCount > 0) {
                this.toaster.show('success', errorCodes.NEW_RECORD, '');
                if (this.saveCloseFlag) {
                  window.scroll(0, 0);
                  this.dialogRef.close({
                    data: {
                      action: PlatformConstant.ACTION_REFRESH
                    }
                  });
                  this.openModal(reqData);
                } else {
                  this.resetForm();
                }

              }
            }, (error) => {
              this.handleError(error);
            }))
        }
        this.blockUI.stop();

      }, error => {
        this.handleError(error);
      })
    );
  }

  updateIODevice(reqData: any) {

    this.blockUI.start('Loading...');
    let params = [];
    params.push(reqData);
    params = params.concat(this.applyToRecords);
    params = params.concat(this.updatedRecords);

    const url = UrlConstant.MANAGECOMPONENTS + UrlConstant.UPDATE;
    this.subscriptions.add(
      this.apiService.postMethod(url, params).subscribe((data: any) => {
        this.blockUI.stop();
        this.refreshData = true;

        if (data.modifiedCount > 0 && data.successMessage == "Successfully updated") {
          this.toaster.show('success', errorCodes.UPDATE_RECORD, '');
          if (this.saveCloseFlag) {
            window.scroll(0, 0);
            this.dialogRef.close({
              data: {
                action: PlatformConstant.ACTION_REFRESH
              }
            });
          } else {
            this.saveCloseFlag = true;
          }

        } else if (data.modifiedCount == 0 && data.error.errorString == "Duplicate Data Found") {

          reqData["allowDuplicates"] = true;
          this.blockUI.start("Loading...");
          this.subscriptions.add(
            this.apiService.postMethod(url, params).subscribe((data: any) => {

              this.blockUI.stop();
              if (data.modifiedCount > 0) {
                this.toaster.show('success', errorCodes.UPDATE_RECORD, '');
                if (this.saveCloseFlag) {
                  window.scroll(0, 0);
                  this.dialogRef.close({
                    data: {
                      action: PlatformConstant.ACTION_REFRESH
                    }
                  });
                  this.openModal(reqData);
                } else {
                  //this.resetForm();
                  this.saveCloseFlag = true;
                }
              }
            }, (error) => {
              this.handleError(error);
            }))
        }
      }, error => {
        this.handleError(error);
      })
    );

  }
  handleError(error) {
    this.errorMsg = error?.error ? error.error?.error?.errorString : errorCodes.GENERIC_ERROR_MESSAGE;
    this.toaster.show('error', this.errorMsg, error ? error?.message : '');
    this.blockUI.stop();
  }
  @HostListener('window:keyup.esc') onKeyUp() {
    let cn = confirm('you want to close ?')
    if (cn) {
      this.dialogRef.close();
    }
  }
  refreshData: boolean = false;
  public onCancel = () => {

    this.dialogRef.close({
      data: {
        action: this.refreshData ? PlatformConstant.ACTION_REFRESH : PlatformConstant.ACTION_CANCEL
      }
    });
  }

  showWattErr: boolean = false;
  IdleMaxWattsErr: boolean = false;
  IdleWattsErr: boolean = false;
  mtbfErr: boolean = false;
  validateWatts(e: any) {
    this.showWattErr = false;
    this.IdleMaxWattsErr = false;
    this.IdleWattsErr = false;
    this.mtbfErr = false;
    if (this.validations.value.idleWatts && this.validations.value.maxWatts
      && !isNaN(this.validations.value.idleWatts) && !isNaN(this.validations.value.maxWatts)) {
      this.showWattErr = Number(this.validations.value.maxWatts) >= Number(this.validations.value.idleWatts)
        ? false : true;


      this.cdr.detectChanges();

    }

    if (this.validations.value.idleWatts && (isNaN(this.validations.value.idleWatts) || parseFloat(this.validations.value.idleWatts) <= 0)) {
      this.IdleWattsErr = true;
      this.cdr.detectChanges();
    }
    if (this.validations.value.maxWatts && (isNaN(this.validations.value.maxWatts) || parseFloat(this.validations.value.maxWatts) <= 0)) {
      this.IdleMaxWattsErr = true;
      this.cdr.detectChanges();
    }
    if (this.validations.value.mtbf && (isNaN(this.validations.value.mtbf) || Number(this.validations.value.mtbf) < 0)) {
      this.mtbfErr = true;
      this.cdr.detectChanges();
    }
  }

  public async openModal(data: any) {
    const result = await this.modalService
      .open({
        content: {
          type: CngContentConfigType.COMPONENT,
          content: ConfirmationBoxComponent,
          componentData: {
            someText: this.translate.instant(
              "DUPLICATE_DATA_FOUND"
            ),
            key: "delete",
          },
        },
        size: CngModalSize.SMALL,
      })
      .onDismiss.toPromise();

    if (result == 'ok') {
      this.getDuplicateData(data);
    }
  }
  getDuplicateData(data: any) {
    this.subscriptions.add(this.adminService.setData(data));
  }

  resetForm() {
    this.showPID = false;

    this.validations.reset();
    this.saveCloseFlag = true;
    this.isDisabled = true;
    this.additionalFields = [];
    //search pid
    this.selectedPid = null;
    setTimeout(() => {
      this.showPID = true;
    }, 100);
  }

  //addional fields
  additionalFields: any = [];
  applyToRecords: any = [];
  updatedRecords: any = [];
  isAdditionFieldsEdited: boolean = false;

  openAdditionalAtt_Modal() {


    const dialogRef = this.dialog.open(AdditionalAttributesComponent, {
      data: {
        params: this.data,
        additionalFields: this.additionalFields,
        p_type: ComponentConstant.COMPONENT,
        type: ComponentConstant.IOES,
        edited: this.isAdditionFieldsEdited
      },
      disableClose: true,
      panelClass: 'width-dialog-100',
    });

    dialogRef.afterClosed().subscribe(result => {


      if (result.data.action !== PlatformConstant.ACTION_CANCEL) {
        this.additionalFields = result.data.addedFields;
        this.applyToRecords = result.data.additionalRecords;
        this.isAdditionFieldsEdited = result.data.edited;
        //
        this.updatedRecords = [];
        this.additionalFields.forEach(element => {
          if (element.flag == "UpdateField" && element.hasOwnProperty("selectedItems")
            && element.selectedItems?.length > 0) {
            element.selectedItems.forEach(element2 => {

              if (element2.rowId !== this.data.id && element2.flag !== "NewField") {
                let ele = this.updatedRecords.filter(value => { return value.id == element2.rowId });

                if (ele?.length == 0) {

                  this.updatedRecords.push({
                    "type": ComponentConstant.IOES,
                    "role": "Admin",
                    "id": element2.rowId,
                    "additionalFields": [{
                      name: element.name,
                      type: element.type,
                      value: element.value,
                      flag: element.flag,
                      previousName: element.previousName
                    }]
                  })
                }
                //
                else {

                  ele[0].additionalFields.push({
                    name: element.name,
                    type: element.type,
                    value: element.value,
                    flag: element.flag,
                    previousName: element.previousName
                  })
                }
              }
              //
            });

          }
        });
      }

    });
  }
  selectedPid: any = null;
  selectedPID(e: any) {

    this.selectedPid = e;
  }
}


