<block-ui>
<div class="edit-license">
    <div class="flex flex-between" style="padding: 0 10px;">
        <h4>{{ componentDisplayName }} <a *ngIf="flagBackButton" (click)="resetGrid()" style="font-size: medium; font-weight: 600; padding-left: 10px;">Back</a>
            <button class="btn btn--ghost" (click)="openShowFields()" style="    padding: 0 10px;    font-size: small;
            margin-left: 5px;
            "> <span class="icon-list-menu" style="    margin-right: 5px;
                "></span>Show Fields</button>
        </h4>
        <button mat-raised-button (click)="openDialog()"  class="btn btn--small " style="height: 24px;margin: 5px 20px 0 0;">New {{ componentDisplayName }}</button>       
    </div>
    <div style="padding: 0 10px;">
        <ag-grid-angular
        style="width: 100%; height: 540px;"
        class="ag-theme-alpine"
        [rowData]="rowData"
        [columnDefs]="columnDefs"
        [pagination]="true"
        [paginationPageSize]="paginationPageSize"         
        [defaultColDef]="defaultColDef"
        (firstDataRendered)="$event.api.sizeColumnsToFit();"
        (gridReady)="onGridReady($event)"
        (firstDataRendered)="headerHeightSetter($event)"
        (columnResized)="headerHeightSetter($event)"
        [frameworkComponents]="frameworkComponents"
        [enableCellTextSelection]="true">
    </ag-grid-angular>
    </div>
</div>
</block-ui>
