import {
  Component,
  OnInit,
  Inject,
  ChangeDetectorRef,
} from "@angular/core";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import {
  UntypedFormBuilder,
} from "@angular/forms";
import { Subscription } from "rxjs";
import { PlatformConstant } from "src/constants/platform.constants";
import { ComponentConstant } from "src/constants/components.constants";

import { ApiService } from "src/app/services/api.service";
import { AdminService } from "src/app/services/admin.service";
import { ToasterService } from "src/app/shared/components/toast/toast.service";
@Component({
  selector: "app-additional-attributes",
  templateUrl: "./additional-attributes.component.html",
  styleUrls: ["./additional-attributes.component.css"],
})
export class AdditionalAttributesComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<AdditionalAttributesComponent>,
    @Inject(MAT_DIALOG_DATA) public storageData: any,
    private formBuilder: UntypedFormBuilder,
    private cdr: ChangeDetectorRef,
    public dialog: MatDialog,
    private apiService: ApiService,
    private adminService: AdminService,
    private toaster: ToasterService
  ) {}
  subscriptions: Subscription = new Subscription();

  additional_fields: any = [];
  existingfields: any = [];
  additionalFields_bk: any = [];
  gridData: any = [];
  ngOnInit(): void {
    
    if (
      this.storageData.params.action === PlatformConstant.ACTION_CREATE &&
      this.storageData.additionalFields?.length == 0
    ) {
      this.insert_row();
    } else {
      this.additional_fields = JSON.parse(
        JSON.stringify(this.storageData.additionalFields)
      );
      this.additional_fields.forEach((element) => {
        
        if (!element.hasOwnProperty("selectedItems")) {
          element["selectedItems"] = [];
        }
        if (!element.hasOwnProperty("isSaved")) {
          element["isSaved"] = true;
        }
        if (!element.hasOwnProperty("flag")) {
          element["flag"] = null;
        }
        if (!element.hasOwnProperty("previousName")) {
          element["previousName"] = null;
        }
        if (!element.hasOwnProperty("deletedItems")) {
          element["deletedItems"] = [];
        }

        //set previous value
        if (element.previousName == null && element.isSaved) {
          element.previousName = element.name;
        }
      });

      if (this.additional_fields?.length == 0) {
        this.insert_row();
      }
    }
    this.validate();
    this.existingfields = this.storageData.fields
      ? this.storageData.fields
      : [];
    
    

    //to Set dropdown Values
    this.subscriptions.add(
      this.adminService.getGridData().subscribe((data: any) => {
        
        //remove current from list
        if (this.storageData.p_type == PlatformConstant.PLATFORM) {
          data = data.filter((v) => {
            return v.id !== this.storageData?.params?.params?.id;
          });
        } else {
          data = data.filter((v) => {
            return v.id !== this.storageData?.params?.id;
          });
        }
        

        this.gridData = data;
        let arr = [];
        if (
          this.storageData.p_type == PlatformConstant.PLATFORM &&
          this.storageData.additionalPlatform !== true
        ) {
          data.forEach((element, index) => {
            let d = {
              id: index + 1,
              itemName: element.name + " " + element.basePid,
              sequence: element.sequence,
              rowId: element.id,
              flag: null,
            };
            arr.push(d);
          });
        } else if (
          this.storageData.p_type == PlatformConstant.PLATFORM &&
          this.storageData.additionalPlatform == true
        ) {
          data.forEach((element, index) => {
            let d = {
              id: index + 1,
              itemName: element.name
                ? element.name
                : element.displayName
                ? element.displayName
                : "" + " " + element.basePid
                ? element.basePid
                : "",
              sequence: element.sequence,
              rowId: element.id,
              flag: null,
            };
            arr.push(d);
          });
        } else if (
          this.storageData.p_type == ComponentConstant.COMPONENT &&
          this.storageData.type !== ComponentConstant.POWERSUPPLY
        ) {
          data.forEach((element, index) => {
            let d = {
              id: index + 1,
              itemName: element.displayName + " " + element.pid,
              sequence: element.sequence,
              rowId: element.id,
              flag: null,
            };
            arr.push(d);
          });
        } else if (
          this.storageData.p_type == ComponentConstant.COMPONENT &&
          this.storageData.type == ComponentConstant.POWERSUPPLY
        ) {
          data.forEach((element, index) => {
            let d = {
              id: index + 1,
              itemName:
                element.powerSupply.displayName + " " + element.powerSupply.pid,
              sequence: element.sequence,
              rowId: element.id,
              flag: null,
            };
            arr.push(d);
          });
        }
        this.dropdownList = arr;
        //add check for dropdown values.
        if (
          !this.storageData.edited &&
          this.storageData.type !== ComponentConstant.POWERSUPPLY
        ) {
          this.additional_fields.forEach((element) => {
            let values = data.filter((f) => {
              if (f.additionalFields) {
                let field = f.additionalFields.filter((v) => {
                  return v.name == element.name && v.type == element.type;
                });
                if (field.length > 0) return true;
                else return false;
              } else {
                return false;
              }
            });
            
            let selected = [];
            values.forEach((row) => {
              let filtered = this.dropdownList.filter((r) => {
                return r.rowId == row.id;
              });
              selected = selected.concat(filtered);
            });
            element.selectedItems = selected;
          });
        } //
        if (
          !this.storageData.edited &&
          this.storageData.type == ComponentConstant.POWERSUPPLY
        ) {
          
          this.additional_fields.forEach((element) => {
            let values = data.filter((f) => {
              if (f.powerSupply.additionalFields) {
                let field = f.powerSupply.additionalFields.filter((v) => {
                  return v.name == element.name && v.type == element.type;
                });
                if (field.length > 0) return true;
                else return false;
              } else {
                return false;
              }
            });
            
            let selected = [];
            values.forEach((row) => {
              let filtered = this.dropdownList.filter((r) => {
                return r.rowId == row.id;
              });
              selected = selected.concat(filtered);
            });
            element.selectedItems = selected;
          });
        }

        this.additionalFields_bk = JSON.parse(
          JSON.stringify(this.additional_fields)
        );
        let height = 200;
        //to support height
        if (this.dropdownList?.length < 6) {
          height = 40 * this.dropdownList.length;
          if (this.dropdownList?.length >= 2) {
            height = height - 15;
          }
        }

        this.dropdownSettings = {
          singleSelection: false,
          text: "Select Options",
          selectAllText: "Select All",
          unSelectAllText: "UnSelect All",
          enableSearchFilter: true,
          classes: "myclass custom-class",
          badgeShowLimit: 1,
          maxHeight: height > 0 ? height : 60,
          enableCheckAll: false,
          enableFilterSelectAll: false,
        };

      })
    );
  }

  type = [
    {
      value: "text",
      viewValue: "Text",
    },
    {
      value: "number",
      viewValue: "Number",
    },
    {
      value: "boolean",
      viewValue: "Boolean",
    },
  ];

  booleanValues = [
    { value: "true", viewValue: "True" },
    { value: "false", viewValue: "False" },
  ];

  close() {
    
    this.dialogRef.close({
      data: {
        action: PlatformConstant.ACTION_CANCEL,
      },
    });
  }
  typeChanged(field) {
    
    field.value = null;
    this.validate();
  }
  insert_row() {
    let sample_field = {
      name: null,
      type: "text",
      value: null,
      selectedItems: [],
      deletedItems: [],
      isSaved: false,
      flag: "NewField", //for new record
      previousName: null,
    };
    this.additional_fields.push(sample_field);
    this.validate();
  }
  delete_row(deleteIndex: any) {
    
    this.additional_fields.splice(deleteIndex, 1);
    this.validate();
  }

  onclikOK() {
    var cc = [];
    

    this.additional_fields.forEach((element) => {
      if (
        element.hasOwnProperty("selectedItems") &&
        element.selectedItems?.length > 0
      ) {
        element.selectedItems.forEach((element2) => {
          if (
            element2.rowId !== this.storageData?.params?.id &&
            element2.flag !== null
          ) {
            let ele = cc.filter((value) => {
              return value.id == element2.rowId;
            });
            if (ele?.length == 0) {
              
              cc.push({
                type: this.storageData.type,
                role: "Admin",
                id: element2.rowId,
                additionalFields: [
                  {
                    name: element.name,
                    type: element.type,
                    value: element.value,
                    flag: element2.flag,
                    previousName: element.previousName,
                  },
                ],
              });
            } else {
              
              ele[0].additionalFields.push({
                name: element.name,
                type: element.type,
                value: element.value,
                flag: element2.flag,
                previousName: element.previousName,
              });
            }
          }
        });
        // let ele = cc.filter(value =>{return value.id == element.rowId });
      }
      if (
        element.hasOwnProperty("deletedItems") &&
        element.deletedItems?.length > 0
      ) {
        element.deletedItems.forEach((element2) => {
          if (
            element2.rowId !== this.storageData?.params?.id &&
            element2.flag !== null
          ) {
            let ele = cc.filter((value) => {
              return value.id == element2.rowId;
            });
            if (ele?.length == 0) {
              
              cc.push({
                type: this.storageData.type,
                role: "Admin",
                id: element2.rowId,
                additionalFields: [
                  {
                    name: element.name,
                    type: element.type,
                    value: element.value,
                    flag: element2.flag,
                    previousName: element.previousName,
                  },
                ],
              });
            } else {
              
              ele[0].additionalFields.push({
                name: element.name,
                type: element.type,
                value: element.value,
                flag: element2.flag,
                previousName: element.previousName,
              });
            }
          }
        });
      }
    });

    
    this.additional_fields.forEach((element1) => {
      this.additionalFields_bk.forEach((element2) => {
        if (
          element1.previousName == element2.previousName &&
          element2.previousName !== null &&
          element1.previousName !== null
        ) {
          if (
            element1.name !== element2.name ||
            element1.type !== element2.type ||
            element1.value !== element2.value
          ) {
            element1.flag = "UpdateField";
          }
        }
      });
    });

    

    this.dialogRef.close({
      data: {
        action: PlatformConstant.ACTION_UPDATE,
        addedFields: JSON.parse(JSON.stringify(this.additional_fields)),
        additionalRecords: cc,
        edited: true,
      },
    });
  }

  validForm: boolean = false;
  validate() {
    
    this.duplicateFieldCheck();
    let valid = true;
    this.additional_fields.forEach((element) => {

      if (
        element.type !== "number" &&
        (element.name == null ||
          element.value == null ||
          element.name?.trim() == "" ||
          element.value?.trim() == "")
      ) {
        valid = false;
      } else if (
        element.type == "number" &&
        (element.name?.trim() == "" ||
          element.name?.trim() == null ||
          isNaN(element.value) ||
          element.value <= 0 ||
          element.value == null)
      ) {
        valid = false;
      }
    });
    if (valid) {
      this.validForm = true;
    } else {
      this.validForm = false;
    }
    
  }

  duplicateNames = [];
  duplicate = false;

  existingNames = [];
  IsExists = false;

  duplicateFieldCheck() {
    
    this.duplicate = false;
    this.duplicateNames = [];

    this.existingNames = [];
    this.IsExists = false;

    this.additional_fields.forEach((element) => {
      if (element.name !== null && element.name !== "") {
        let filtered = this.additional_fields.filter(
          (ele) =>
            ele.name?.toUpperCase().replace(/\s+/g, "") ==
            element.name.toUpperCase().replace(/\s+/g, "")
        );

        let exiting = this.existingfields.filter(
          (ele) =>
            ele?.toUpperCase().replace(/\s+/g, "") ==
            element.name.toUpperCase().replace(/\s+/g, "")
        );

        if (filtered.length > 1) {
          this.duplicate = true;
          this.duplicateNames.push(element.name);
        }
        
        if (exiting.length > 0) {
          this.existingNames.push(element.name);
          this.IsExists = true;
        }
      }

      
    });

    
  }

  //DDL

  dropdownList = [];
  // selectedItems = [];
  dropdownSettings = {};
  onItemSelect(item: any, field) {

    let selected = this.gridData.filter((f) => {
      return f.id == item.rowId;
    });
    
    let add_field = [];
    if (this.storageData.type == ComponentConstant.POWERSUPPLY) {
      add_field = selected[0].powerSupply.additionalFields?.filter((f) => {
        return f.name == field.name;
      });
    } else {
      add_field = selected[0].additionalFields?.filter((f) => {
        return f.name == field.name;
      });
    }

    

    let isAdded = true;
    let filterbk = this.additionalFields_bk.filter((f) => {
      return f.name == field.name;
    });
    
    if (filterbk.length > 0) {
      let indexbk = filterbk[0].selectedItems.findIndex(
        (x) => x.rowId === item.rowId
      );
      
      isAdded = indexbk >= 0 ? false : true;
    }

    let del_index = field.deletedItems.findIndex((x) => x.rowId === item.rowId);

    if (add_field && add_field.length > 0 && isAdded && del_index < 0) {
      let displayName = this.storageData.type == ComponentConstant.POWERSUPPLY ? selected[0][`${ComponentConstant.POWERSUPPLY}`].displayName: selected[0].displayName;
      if (!displayName) {
        displayName = `${selected[0].name} ${selected[0].basePid}`;
      }
      this.toaster.show(
        "error",
        "Field already exists in " + displayName,
        "",
        2000
      );
      let index = field.selectedItems.findIndex((x) => x.rowId === item.rowId);
      field.selectedItems.splice(index, 1);
    } else {

      if (isAdded && del_index < 0) {
        item.flag = "NewField";
      }
    }
    if (del_index >= 0) field.deletedItems.splice(del_index, 1);
  }

  OnItemDeSelect(item: any, field) {
    
    
    

    let filter_bk = this.additionalFields_bk.filter((f) => {
      return f.name == field.name;
    });

    
    if (filter_bk.length > 0) {
      let indexbk = filter_bk[0].selectedItems.findIndex(
        (x) => x.rowId === item.rowId
      );
      
      if (
        indexbk >= 0 &&
        filter_bk[0]?.selectedItems[indexbk]?.flag !== "NewField"
      ) {
        item.flag = "DeleteField";
        field.deletedItems.push(item);
      }
    }

    
  }

  onSelectAll(items: any, field) {
    
    
  }
  onDeSelectAll(items: any, field) {
    
    
  }
}
