import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { DataServiceService } from "../data-service.service";
import { ApiService } from "../services/api.service";
import { BlockUI, NgBlockUI } from 'ng-block-ui';
@Component({
  selector: 'app-maintenance-screen',
  templateUrl: './maintenance-screen.component.html',
  styleUrls: ['./maintenance-screen.component.css']
})
export class MaintenanceScreenComponent implements OnInit, OnDestroy {
  message: any;
  applicationMessage: any;
  dbStatusMessage: any
  @BlockUI() blockUI: NgBlockUI;
  subscription: any;
  loading: boolean;
  constructor(private router: Router, private data: DataServiceService, private apiService: ApiService) {
  }

  ngOnInit(): void {
    this.blockUI.start();
    this.checkForStatus();
  }

  checkForStatus() {
    this.subscription = this.data.currrentStatus.subscribe((response: Response) => {

      if (response != null) {
        this.loading = true;
        this.message = response;
        this.blockUI.stop();
      }
      if (response == null) {
        const url = 'test'
        this.apiService.getMethod(url).subscribe((response: Response) => {                           //next() callback

          this.applicationMessage = response;
          // this.checkDbStatus();
          this.blockUI.stop();

        },
          (error) => {
            this.blockUI.stop();
            this.loading = true;
            console.error('Request failed with error ' + error);
          },
          () => {                                   //complete() callback
            console.error('Request completed');

          });

      }
    });

  }

  checkDbStatus() {
    const url = '/actuator/health';
    this.apiService.getMethodActuatorHealth(url).subscribe((response: Response) => {                           //next() callback

      this.dbStatusMessage = response;
      this.blockUI.stop();
      alert('Application is up now we are redirecting to home screen');
      this.router.navigate(['license']);

    },
      (error) => {                              //error() callback
        console.error('Request failed with error');
        this.loading = true;
        this.blockUI.stop();
      },
      () => {                                   //complete() callback
        console.error('Request completed')      //This is actually not needed
      });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }


}
