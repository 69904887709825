import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { AddMeasurementComponent} from 'src/app/components/admin/admin-tab/manage-platform-configs/add-measurement/add-measurement.component'
import { ManageComponentComponent} from 'src/app/components/admin/admin-tab/manage-platform-configs/manage-component/manage-component.component'
import { AddConfigurationComponent} from 'src/app/components/admin/admin-tab/manage-platform-configs/add-configuration/add-configuration.component'
import { CpuMemoryCompatibilityComponent} from 'src/app/components/admin/admin-tab/manage-platform-configs/cpu-memory-compatibility/cpu-memory-compatibility.component'
import { CngContentConfigType,CngModalService,CngModalSize } from "@cisco/cui-ng";
import { ConfirmationBoxComponent } from "../confirmation-box/confirmation-box.component";
import { TranslateService } from "@ngx-translate/core";
import { Subscription } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';
import { UrlConstant } from 'src/constants/url.constants';
import { PlatformConstant } from 'src/constants/platform.constants';
import { CreateRackServersComponent } from "src/app/components/admin/admin-tab/create-platforms/create-rack-servers/create-rack-servers.component";
import { CreateBladeServersComponent } from "src/app/components/admin/admin-tab/create-platforms/create-blade-servers/create-blade-servers.component";
import { CreateBladeChassisComponent } from "src/app/components/admin/admin-tab/create-platforms/create-blade-chassis/create-blade-chassis.component";
import { CreateModularChassisComponent } from "src/app/components/admin/admin-tab/create-platforms/create-modular-chassis/create-modular-chassis.component";
import { CreateNodesComponent } from "src/app/components/admin/admin-tab/create-platforms/create-nodes/create-nodes.component";
import { CreateFabricInterconnectsComponent } from "src/app/components/admin/admin-tab/create-platforms/create-fabric-interconnects/create-fabric-interconnects.component";
import { CreateFabricExtendersComponent } from "src/app/components/admin/admin-tab/create-platforms/create-fabric-extenders/create-fabric-extenders.component";
import { ToasterService } from "src/app/shared/components/toast/toast.service";
import { errorCodes } from "src/constants/errorCodes";
import { CreateAdditionalPlatform1Component } from "src/app/components/admin/admin-tab/create-platforms/create-additional-platforms/create-additional-platform1/create-additional-platform1.component";
import { UpdateNameService } from 'src/app/services/update-name.service';
import { AdminService } from 'src/app/services/admin.service';


@Component({
  selector: 'app-edit-render-global-search',
  templateUrl: './edit-render-global-search.component.html',
  styleUrls: ['./edit-render-global-search.component.css']
})
export class EditRenderGlobalSearchComponent implements OnInit {

  constructor(
    public dialog: MatDialog,
    private modalService: CngModalService,
    private translate: TranslateService,  
    private apiService: ApiService,
    private toaster: ToasterService, 
    private updateNameService: UpdateNameService,
    private adminService: AdminService,

    ) { }

  ngOnInit(): void {
  }

  label:any;
  subscriptions: Subscription = new Subscription();
  params:any;
  agInit(params: any): void {
    this.params = params;
    this.label = this.params.label || null;    
  } 
  onClick($event:any, action: string) {
  
  this.openEditDialog(this.params.data);
  this.setName(this.params.data?.type);
  this.setGridData(this.params.data?.type);
  }

  openEditDialog(params:any){
    
    let component:any = null;
    let type:any = null;

    switch (params?.type) {
      case PlatformConstant.RACK : {
        
        component = CreateRackServersComponent;
        break;
      }
      case PlatformConstant.BLADE : {
        
        component = CreateBladeServersComponent;
        break;
      }
      case PlatformConstant.BLADE_CHASSIS : {
        
        component = CreateBladeChassisComponent;
        break;
      }
      case PlatformConstant.NODE : {
        
        component = CreateNodesComponent;
        break;
      }
      case PlatformConstant.MODULAR_CHASSIS : {
        
        component = CreateModularChassisComponent;
        break;
      }
      case PlatformConstant.FABRIC_INTERCONNECTS : {
        
        component = CreateFabricInterconnectsComponent;
        break;
      }
      case PlatformConstant.FABRIC_EXTENDERS : {
        
        component = CreateFabricExtendersComponent;
        break;
      }
      default : {
        
        component = CreateAdditionalPlatform1Component;
      }
    }
    if(component){
      const dialogRef = this.dialog.open(component, {      
        data: {
               action : PlatformConstant.ACTION_UPDATE,
               params : params,
              },
        disableClose: true,
        panelClass: 'width-dialog-100',           
      });
      dialogRef.afterClosed().subscribe(result => {
         
         
        if(result.data?.action == PlatformConstant.ACTION_REFRESH){
          this.params.onClick(this.params);   
        }
      });
    }
  }

  setName(type:string){
    
  this.updateNameService.getplatformPanelData().subscribe((data) => {
    
    if(data.length>0){
      let platform = data.filter(f=>{return f["platformType"] == type})
      let compNameSplit = platform[0]?.displayName.trim().split(" ");
      let compName = '';
      for (let i = 0; i < compNameSplit.length; i++) {
        if(!(compNameSplit[i]?.toUpperCase().includes("MANAGE")))
          compName += compNameSplit[i] + " ";
      }       
      
      this.adminService.setPlatformName(compName);

    }
  });
}

//for additional fields dropdown
setGridData(type:string){
  const url = UrlConstant.MANAGE_PLATFORM + '/?platformType=' + type ;
    this.subscriptions.add(
      this.apiService.getMethod(url).subscribe((data: any) => {
        
        this.adminService.setGridData(data.ucsPlatform);
      })
    );
}
  
}
