<div class="container-fluid">
<div class="row">
    <div class="col-10">
    <h4> Basic Mongodb query tutorial </h4>
    <div>
    <ul>
        <li>
            <a href="https://docs.mongodb.com/manual/tutorial/getting-started/" target="_blank">Introduction to MongoDB
            </a>

        </li>
        <li>
            <a href="https://www.tutorialspoint.com/mongodb/index.htm" target="_blank">Mongo DB Simple learning </a>
        </li>

        <li>
       <a href="https://studio3t.com/academy/?utm_source=adwords&utm_medium=ppc&utm_term=mongodb%20tutorial%20point&utm_campaign=GS%20%7C%20Academy%20%7C%20Top%2025&hsa_net=adwords&hsa_ad=415719234888&hsa_src=g&hsa_ver=3&hsa_grp=95114376404&hsa_acc=1756351187&hsa_tgt=kwd-337929376723&hsa_mt=b&hsa_kw=mongodb%20tutorial%20point&hsa_cam=9221150778&gclid=Cj0KCQjwjo2JBhCRARIsAFG667XXpS4yGDTkOXrEV6Bh3-WQ8Cwdc7Wvmv-0KOwlSTNFajyifyXWjxQaAn86EALw_wcB" target="_blank">
           Mongo db with studio 3T </a>
        </li>
    </ul>
    </div>

    <br>
    <br>

    <h4> User Guide to install Mongo GUI the database. </h4>

    <h6> <a href="https://robomongo.org/?utm_source=Guru99&utm_medium=listing&utm_campaign=leadgen" target="_blank">Install Mongodb GUI ROBO 3T/STUDIO 3T </a> </h6>
    <br>

    <h6> Steps to connect to the DB </h6>
    <ul>
        <li> Open Mongo GUI</li>
        <li> Click connect on top left button </li>
        <li> Pop up will be opened</li>
        <li> Click Create </li>
        <li> Under server textbox type - sjc5-wwdl-ipw1</li>
        <li> Under name textbox type - ucs-dev</li>
        <li> Click on Authentication Tab  - and select Mode as - Basic(Username/Password)</li>
        <li> Provide user name and password as -
            <a href="mailto:ucspc@cisco.com?" title="contact"> Please reach out to support team </a>
        </li>
        <li> Test connection </li>
        <li> Save and connect </li>
    </ul>
    </div>
    <div class="col-2">
        <div class="row">
            <div class="col-12 div-raise-case">
                <button class="btn btn--primary" (click)="openDialog()">Raise Case</button>
            </div>
        </div>
    </div>
</div>

</div>
