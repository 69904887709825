import { Component, OnInit } from '@angular/core';
import { ToasterService } from 'src/app/shared/components/toast/toast.service';
import { ApiService } from 'src/app/services/api.service';
import { errorCodes } from 'src/constants/errorCodes';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { Subscription } from 'rxjs';
import { AdminService } from 'src/app/services/admin.service';
import { UrlConstant } from 'src/constants/url.constants';

@Component({
  selector: 'app-edit-series',
  templateUrl: './edit-series.component.html',
  styleUrls: ['./edit-series.component.css']
})
export class EditSeriesComponent implements OnInit {
  seriesNames: string[] = [];
  seriesDisplayNames: string[] = [];
  seriesDisplayNamesRef: string[] = [];
  isEdit: boolean[] = [];
  @BlockUI() blockUI: NgBlockUI;
  subscription: Subscription = new Subscription();
  
  constructor(
    private toast: ToasterService,
    private apiService: ApiService,
    private adminService: AdminService, 
    private toaster: ToasterService,
    ) { }

  ngOnInit(): void {
    this.blockUI.start('Loading...')
    this.subscription.add(this.apiService.getMethod('admin/getseries').subscribe((data) => {
        for (let i = 0; i < data.length; i++) {
          this.seriesNames.push(data[i].series.toString());
          this.seriesDisplayNames.push(data[i].seriesDisplayName.toString());
        }
        this.seriesDisplayNamesRef = [...this.seriesDisplayNames];
        this.isEdit = new Array<boolean>(this.seriesNames.length).fill(false); 
        this.blockUI.stop();
      }, (error) => {
        console.error(error);
        let errorMsg = error?.error ? error.error?.error?.errorString : "Oops something went wrong!!";
        this.toaster.show('error', errorMsg, error ? error?.message : '');
        this.blockUI.stop(); 
      })
    );
    

  }
  onClickEdit($event, idx: number) {
    this.isEdit[idx] = !this.isEdit[idx];
    for (let i = 0; i < this.seriesDisplayNames.length; i++) {
      if (i!==idx) {
        this.isEdit[i] = false;
      }
    } 
  }
  onChangeSeriesDisplayName($event, idx: number) {
    if ($event.target.value.trim() === '') {
      this.toast.show('error', errorCodes.SERIESNAME_EMPTY, '', 5000); 
      return ;
    }
    let payload = {
      "series": this.seriesNames[idx],
      "seriesDisplayName": this.seriesDisplayNames[idx]
    }
    this.blockUI.start('Loading...');
    
    this.subscription.add(this.apiService.postMethod('admin/updateseries', payload).subscribe((response) => {
        this.toast.show('success', errorCodes.SERIESNAME_UPDATED, '', 5000);
        this.blockUI.stop();
        this.getAllSeries();
      }, (error) => {
        console.error(error);
        let errorMsg = error?.error ? error.error?.error?.errorString : "Oops something went wrong!!";
        this.toaster.show('error', errorMsg, error ? error?.message : '');
        this.blockUI.stop(); 
      })
    );
    this.isEdit[idx]=false;

  }
  getAllSeries(){
    const url_series = UrlConstant.GET_ALL_SERIES;
    this.subscription.add(
      this.apiService.getMethod(url_series).subscribe((data) => {
        this.adminService.setSeriesAll(data);
      }, (error) => {
        console.error(error);
        let errorMsg = error?.error ? error.error?.error?.errorString : "Oops something went wrong!!";
        this.toaster.show('error', errorMsg, error ? error?.message : '');
        this.blockUI.stop(); 
      }))
  }
  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    this.blockUI.stop();
  }
}
