import { ChangeDetectorRef, Component, Inject, OnInit } from "@angular/core";
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import {
  CngContentConfigType,
  CngModalService,
  CngModalSize,
} from "@cisco/cui-ng";
import { TranslateService } from "@ngx-translate/core";
import { BlockUI, NgBlockUI } from "ng-block-ui";
import { Subscription } from "rxjs";
import { AdminService } from "src/app/services/admin.service";
import { ApiService } from "src/app/services/api.service";
import { AdditionalAttributesComponent } from "src/app/shared/components/additional-attributes/additional-attributes.component";
import { ConfirmationBoxComponent } from "src/app/shared/components/confirmation-box/confirmation-box.component";
import { ToasterService } from "src/app/shared/components/toast/toast.service";
import { AppConstant } from "src/constants/app.constants";
import { errorCodes } from "src/constants/errorCodes";
import { PlatformConstant } from "src/constants/platform.constants";
import { UrlConstant } from "src/constants/url.constants";

interface ViewType {
  value: string;
  viewValue: string;
}
@Component({
  selector: "app-create-additional-platform1",
  templateUrl: "./create-additional-platform1.component.html",
  styleUrls: ["./create-additional-platform1.component.css"],
})
export class CreateAdditionalPlatform1Component implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<CreateAdditionalPlatform1Component>,
    @Inject(MAT_DIALOG_DATA) public storageData: any,
    private apiService: ApiService,
    private toaster: ToasterService,
    private formBuilder: UntypedFormBuilder,
    private modalService: CngModalService,
    private translate: TranslateService,
    private adminService: AdminService,
    private cdr: ChangeDetectorRef,
    public dialog: MatDialog
  ) {}

  @BlockUI() blockUI: NgBlockUI;
  subscriptions: Subscription = new Subscription();
  errorMsg: any;
  refreshData: boolean = false;
  submitInProgress: boolean = false;
  platformName: string = null;
  showPID: boolean = true;
  url: any;

  booleanFields = [
    { value: "yes", viewValue: "Yes" },
    { value: "no", viewValue: "No" },
  ];

  implicitCap: ViewType[] = [
    { value: "None", viewValue: "None" },
    { value: "Max", viewValue: "Max" },
    { value: "Redundant", viewValue: "Redundant" },
  ];

  /** dynamic platform */
  dynamicPlatformAttributes: any[] = [];
  dynamicPlatformData: any;
  isDynamicFormCreated: boolean = false;
  idlePower: number = null;
  maxPower: number = null;

  additionalPlatformForm = new UntypedFormGroup({
    dynamicAttributes: this.formBuilder.array([]),
  });

  get dynamicAttributes() {
    return this.additionalPlatformForm.controls[
      "dynamicAttributes"
    ] as UntypedFormArray;
  }

  ngOnInit(): void {
    if (
      this.storageData.all_data &&
      this.storageData.all_data.dynamicPlatformAttributes &&
      this.storageData.all_data.dynamicPlatformAttributes.length > 0
    ) {
      this.dynamicPlatformAttributes =
        this.storageData.all_data.dynamicPlatformAttributes;
      this.dynamicPlatformData = this.storageData.dynamicPlatformData;

      if (!this.isDynamicFormCreated) {
        this.isDynamicFormCreated = true;
        this.buildDynamicForm();
      }
    }
    this.adminService.getDynamicPlatformAttributes().subscribe((attributes) => {
      this.dynamicPlatformAttributes = attributes;
      if (!this.isDynamicFormCreated) {
        this.isDynamicFormCreated = true;
        this.buildDynamicForm();
      }
    });
    this.adminService.getPlatformName().subscribe((data) => {
      this.platformName = data;
    });
    this.additionalFields = this.storageData.params?.additionalFields
      ? this.storageData.params.additionalFields
      : [];
  }
  getProperties(attribute) {
    return {
      id: attribute.key,
      name: attribute.name,
      required: attribute.required,
      type: attribute.type,
      visible: attribute.visible,
    };
  }
  getOptionsList(key: string) {
    if (key == "implicitCap") {
      return this.implicitCap;
    }
    return [];
  }
  buildDynamicForm() {
    if (this.dynamicPlatformAttributes) {
      this.dynamicPlatformAttributes = this.dynamicPlatformAttributes.sort(
        (a, b) => this.getSortedOrder(a.key) - this.getSortedOrder(b.key)
      );
      for (let attribute of this.dynamicPlatformAttributes) {
        switch (attribute.key) {
          // optional text
          case "gen":
            const textFormOpt = this.formBuilder.group({
              ...this.getProperties(attribute),
              value: [null],
            });
            this.dynamicAttributes.push(textFormOpt);
            break;
          // required text
          case "vendor":
          case "name":
          case "basePid":
            const textFormReq = this.formBuilder.group({
              ...this.getProperties(attribute),
              value: [
                null,
                Validators.compose([
                  Validators.required,
                  Validators.pattern(AppConstant.VALID_INPUT_REGEX),
                ]),
              ],
            });
            this.dynamicAttributes.push(textFormReq);
            break;

          // required numbers - integers
          case "dimmSlots":
          case "height":
          case "weight":
          case "cpuSockets":
          case "maxPsus":
            const integerForm = this.formBuilder.group({
              ...this.getProperties(attribute),
              value: [
                null,
                Validators.compose([
                  Validators.required,
                  Validators.pattern("^[0-9]*$"),
                  Validators.min(1),
                ]),
              ],
            });
            this.dynamicAttributes.push(integerForm);
            break;

          // required decimals
          case "idlePower":
          case "maxPower":
            const decimalForm = this.formBuilder.group({
              ...this.getProperties(attribute),
              value: [
                null,
                Validators.compose([
                  Validators.required,
                  Validators.pattern("^[0-9]+(.[0-9]{0,2})?$"),
                  Validators.min(0.1),
                ]),
              ],
            });
            this.dynamicAttributes.push(decimalForm);
            break;

          // optional decimal
          case "conversionFactor":
            const defaultValue: number = 1.1;
            const decimalFormOpt = this.formBuilder.group({
              ...this.getProperties(attribute),
              value: [
                defaultValue,
                Validators.compose([
                  Validators.pattern("^[0-9]+(.[0-9]{0,2})?$"),
                  Validators.min(0.1),
                ]),
              ],
            });
            this.dynamicAttributes.push(decimalFormOpt);
            break;

          // optional numbers
          case "pciSlots":
          case "maxDisks":
          case "nMin":
          case "maxBootPower":
          case "mtbf":
          case "mezzSlots":
          case "numChassisSlots":
          case "numBladeSlots":
            const integerFormOpt = this.formBuilder.group({
              ...this.getProperties(attribute),
              value: [
                null,
                Validators.compose([
                  Validators.pattern("^[0-9]*$"),
                  Validators.min(0),
                ]),
              ],
            });
            this.dynamicAttributes.push(integerFormOpt);
            break;

          // dropdown
          case "implicitCap":
            const dropdownForm = this.formBuilder.group({
              ...this.getProperties(attribute),
              value: [null, Validators.required],
            });
            this.dynamicAttributes.push(dropdownForm);
            break;

          // radio
          case "discont":
            const eosForm = this.formBuilder.group({
              ...this.getProperties(attribute),
              value: ["0", Validators.required],
            });
            this.dynamicAttributes.push(eosForm);
            break;
        }
      }
      this.setPlatformSeries();
      if (this.storageData.action == "Edit") {
        this.dynamicPlatformData = this.storageData.params;
        this.setDynamicAttributes();
      }
    }
  }
  /**
   * series -- for the platform
   */
  setPlatformSeries() {
    let platformSeries = this.storageData.params
      ? this.storageData.params.series
      : this.storageData.all_data.series;
    const seriesForm = this.formBuilder.group({
      id: "series",
      name: "Series",
      required: false,
      type: "text",
      visible: true,
      value: platformSeries,
    });
    this.dynamicAttributes.push(seriesForm);
  }
  getSortedOrder(field?: string) {
    let fields = [
      "vendor",
      "name",
      "gen",
      "basePid",
      "dimmSlots",
      "cpuSockets",
      "pciSlots",
      "maxDisks",
      "idlePower",
      "maxPower",
      "maxPsus",
      "nMin",
      "implicitCap",
      "height",
      "conversionFactor",
      "maxBootPower",
      "mtbf",
      "mezzSlots",
      "chassisSlots",
      "bladeSlots",
      "numberOfIOSlots",
      "discont",
    ];
    return fields.indexOf(field) == -1
      ? Number.MAX_SAFE_INTEGER
      : fields.indexOf(field);
  }
  patchFormValue(key, value) {
    const controls =
      this.additionalPlatformForm.get("dynamicAttributes")["controls"];
    for (const control of controls) {
      if (control.value.id == key) {
        control.controls.value.patchValue(value);
        break;
      }
    }
  }
  validateForm(attribute) {
    if (attribute.value.id == "idlePower" || attribute.value.id == "maxPower") {
      let data = {
        idlePower: this.idlePower,
        maxPower: this.maxPower,
      };
      if (attribute.value.id == "idlePower") {
        data.idlePower = attribute.value.value;
        this.idlePower = data.idlePower;
      } else {
        data.maxPower = attribute.value.value;
        this.maxPower = data.maxPower;
      }
      this.validateWatts(data);
    }
    this.patchFormValue(attribute.value.id, attribute.value.value);
  }
  getAttributeValue(key: string) {
    let dynamicAttributes = this.additionalPlatformForm.value.dynamicAttributes;
    let res = null;
    let found = dynamicAttributes.find((d) => d.id == key);
    if (found) {
      res = found.value;
      if (typeof res == "object") {
        res = res ? res.value : null;
      }
    }
    return res;
  }
  setAttributeValue(key: string) {
    let dynamicAttributes = this.additionalPlatformForm.value.dynamicAttributes;
    let found = dynamicAttributes.find((d) => d.id == key);
    let fieldValue = key;

    if (key == "idlePower") {
      this.idlePower = this.dynamicPlatformData[`${fieldValue}`];
    }
    if (key == "maxPower") {
      this.maxPower = this.dynamicPlatformData[`${fieldValue}`];
    }
    if (key == "maxPsus") fieldValue = "maxPsuSlots";

    if (found) {
      let attributeValue = this.dynamicPlatformData[`${fieldValue}`];
      switch (found.type) {
        case "text":
        case "integer":
          this.patchFormValue(found.id, attributeValue);
          break;
        case "dropdown":
          let value = this.getOptionsList(key).find(
            (f) => f.value == attributeValue
          );
          this.patchFormValue(found.id, value);
          break;
        case "radio":
          let attVal = attributeValue == "No" ? "0" : "1";
          this.patchFormValue(found.id, attVal);
          break;
      }
    }
  }
  setDynamicAttributes() {
    this.dynamicPlatformAttributes.forEach((d) =>
      this.setAttributeValue(d.key)
    );
  }

  seriesList = [];

  setAdditionalFields() {
    let array = [];
    if (this.additionalFields?.length > 0) {
      this.additionalFields.forEach((element) => {
        let row = {
          name: element.name,
          type: element.type,
          value: element.value,
        };
        array.push(row);
      });
    } else array = [];
    return array;
  }

  submit(action: any) {
    if (this.additionalPlatformForm.valid) {
      let payload = {
        id:
          this.storageData.action === PlatformConstant.ACTION_CREATE
            ? undefined
            : this.storageData.params.id,
        role: "Admin",
        type:
          this.storageData.action === PlatformConstant.ACTION_CREATE
            ? this.storageData.type
            : this.storageData.params.type,
        released:
          this.storageData.action === PlatformConstant.ACTION_CREATE
            ? 0
            : this.storageData.params.released,
        allowDuplicates: true,
        additionalFields: this.setAdditionalFields(),
        primaryClass:
          this.storageData.action === PlatformConstant.ACTION_CREATE
            ? this.storageData.all_data.primaryClass
            : this.storageData.params.primaryClass,
        additionalComponents:
          this.storageData.action === PlatformConstant.ACTION_CREATE
            ? []
            : this.storageData.params.additionalComponents,
        legacyPlatform: false,
        isNewCreated: true,
        isNewConverted: null,
        dynamicPlatform: true,
        /** add series parameter */
        series:
          this.storageData.action === PlatformConstant.ACTION_CREATE
            ? this.storageData.all_data.series
            : this.storageData.params.series,

        /** dynamic platform attributes */
        vendor: this.getAttributeValue("vendor"),
        name: this.getAttributeValue("name"),
        basePid: this.getAttributeValue("basePid"),
        dimmSlots: this.getAttributeValue("dimmSlots"),
        cpuSockets: this.getAttributeValue("cpuSockets"),
        pciSlots: this.getAttributeValue("pciSlots"),
        maxDisks: this.getAttributeValue("maxDisks"),
        idlePower: this.getAttributeValue("idlePower"),
        maxPower: this.getAttributeValue("maxPower"),
        maxPsuSlots: this.getAttributeValue("maxPsus"),
        maxPsus: this.getAttributeValue("maxPsus"),
        nMin: this.getAttributeValue("nMin"),
        implicitCap: this.getAttributeValue("implicitCap"),
        height: this.getAttributeValue("height"),
        weight: this.getAttributeValue("weight"),
        maxBootPower: this.getAttributeValue("maxBootPower"),
        mtbf: this.getAttributeValue("mtbf"),
        conversionFactor: this.getAttributeValue("conversionFactor"),
        gen: this.getAttributeValue("gen"),
        mezzSlots: this.getAttributeValue("mezzSlots"),
        numChassisSlots: this.getAttributeValue("numChassisSlots"),
        numBladeSlots: this.getAttributeValue("numBladeSlots"),
        discont: this.getAttributeValue("discont"),
        idleWatts: this.getAttributeValue("idlePower"),
        maxWatts: this.getAttributeValue("maxPower"),
        configs: [],
      };

      this.blockUI.start("Loading...");
      this.submitInProgress = true;
      this.refreshData = true;
      const url = UrlConstant.MANAGE_PLATFORM;
      let params2 = [];
      params2.push(payload);
      params2 = params2.concat(this.applyToRecords);
      params2 = params2.concat(this.updatedRecords);

      this.subscriptions.add(
        this.apiService.postMethod(url, params2).subscribe(
          (data: any) => {
            this.blockUI.stop();
            this.submitInProgress = false;
            if (data.modifiedCount > 0) {
              this.storageData.action === PlatformConstant.ACTION_CREATE
                ? this.toaster.show("success", errorCodes.NEW_RECORD, "", 5000)
                : this.toaster.show(
                    "success",
                    errorCodes.UPDATE_RECORD,
                    "",
                    5000
                  );

              if (action == "closeModal") {
                this.dialogRef.close({
                  data: {
                    action: PlatformConstant.ACTION_REFRESH,
                  },
                });
              } else {
                if (
                  this.storageData.action === PlatformConstant.ACTION_CREATE
                ) {
                  // this.resetForm();
                }
              }
            } else if (
              data.modifiedCount == 0 &&
              data.error.errorString == "Duplicate Data Found"
            ) {
              payload["allowDuplicates"] = true;
              this.openModal(payload);

              this.subscriptions.add(
                this.apiService
                  .postMethod(url, params2)
                  .subscribe((data: any) => {
                    if (data.modifiedCount > 0) {
                      this.storageData.action === PlatformConstant.ACTION_CREATE
                        ? this.toaster.show(
                            "success",
                            errorCodes.NEW_RECORD,
                            "",
                            5000
                          )
                        : this.toaster.show(
                            "success",
                            errorCodes.UPDATE_RECORD,
                            "",
                            5000
                          );
                      window.scroll(0, 0);
                      this.dialogRef.close({
                        data: {
                          action: PlatformConstant.ACTION_REFRESH,
                        },
                      });
                    }
                  })
              );
              //
            }
          },
          (error) => {
            this.blockUI.stop();
            this.errorMsg = error?.error
              ? error.error?.error?.errorString
              : "Oops something went wrong!!";
            this.dialogRef.close({
              data: {
                action: PlatformConstant.ACTION_CANCEL,
              },
            });
            this.toaster.show(
              "error",
              this.errorMsg,
              error ? error?.message : "",
              5000
            );
          }
        )
      );
    }
  }

  resetForm() {
    this.showPID = false;

    this.additionalPlatformForm.reset();
    this.additionalFields = [];
    this.url = null;
    this.storageData = {
      type: this.storageData.type,
      action: PlatformConstant.ACTION_CREATE,
    };
    this.selectedPid = null;
    setTimeout(() => {
      this.showPID = true;
    }, 100);
  }

  onCancel() {
    this.dialogRef.close({
      data: {
        action: this.refreshData
          ? PlatformConstant.ACTION_REFRESH
          : PlatformConstant.ACTION_CANCEL,
      },
    });
  }

  selectedPid: any = null;
  selectedPID(e: any) {
    this.selectedPid = e;
  }

  showWattErr: boolean = false;
  IdleMaxWattsErr: boolean = false;
  IdleWattsErr: boolean = false;
  mtbfErr: boolean = false;
  validateWatts(data: any) {
    this.showWattErr = false;
    this.IdleMaxWattsErr = false;
    this.IdleWattsErr = false;
    this.mtbfErr = false;
    let idlePower = data.idlePower;
    let maxPower = data.maxPower;
    if (idlePower && maxPower && !isNaN(idlePower) && !isNaN(maxPower)) {
      this.showWattErr = Number(maxPower) >= Number(idlePower) ? false : true;
      this.cdr.detectChanges();
    }

    if (idlePower && (isNaN(idlePower) || parseFloat(idlePower) < 0)) {
      this.IdleWattsErr = true;
      this.cdr.detectChanges();
    }
    if (maxPower && (isNaN(maxPower) || parseFloat(maxPower) < 0)) {
      this.IdleMaxWattsErr = true;
      this.cdr.detectChanges();
    }
  }

  additionalFields: any = [];
  applyToRecords: any = [];
  updatedRecords: any = [];
  isAdditionFieldsEdited: boolean = false;

  openAdditionalAtt_Modal() {
    const dialogRef = this.dialog.open(AdditionalAttributesComponent, {
      data: {
        params: this.storageData,
        additionalFields: this.additionalFields,
        fields: [],
        p_type: PlatformConstant.PLATFORM,
        type: this.storageData.type,
        edited: this.isAdditionFieldsEdited,
        additionalPlatform: true,
      },
      disableClose: true,
      panelClass: "width-dialog-100",
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result.data.action !== PlatformConstant.ACTION_CANCEL) {
        this.additionalFields = result.data.addedFields;
        this.applyToRecords = result.data.additionalRecords;
        this.isAdditionFieldsEdited = result.data.edited;

        this.updatedRecords = [];
        this.additionalFields.forEach((element) => {
          if (
            element.flag == "UpdateField" &&
            element.hasOwnProperty("selectedItems") &&
            element.selectedItems?.length > 0
          ) {
            element.selectedItems.forEach((element2) => {
              if (
                element2.rowId !== this.storageData.id &&
                element2.flag !== "NewField"
              ) {
                let ele = this.updatedRecords.filter((value) => {
                  return value.id == element2.rowId;
                });

                if (ele?.length == 0) {
                  this.updatedRecords.push({
                    type: this.storageData.type,
                    role: "Admin",
                    id: element2.rowId,
                    additionalFields: [
                      {
                        name: element.name,
                        type: element.type,
                        value: element.value,
                        flag: element.flag,
                        previousName: element.previousName,
                      },
                    ],
                  });
                } else {
                  ele[0].additionalFields.push({
                    name: element.name,
                    type: element.type,
                    value: element.value,
                    flag: element.flag,
                    previousName: element.previousName,
                  });
                }
              }
            });
          }
        });
      }
    });
  }
  //modal for duplicate show confirmation
  public async openModal(data: any) {
    const result = await this.modalService
      .open({
        content: {
          type: CngContentConfigType.COMPONENT,
          content: ConfirmationBoxComponent,
          componentData: {
            someText: this.translate.instant("DUPLICATE_DATA_FOUND"),
            key: "delete",
          },
        },
        size: CngModalSize.SMALL,
      })
      .onDismiss.toPromise();

    if (result == "ok") this.getDuplicateData(data);
  }
  getDuplicateData(data: any) {
    this.subscriptions.add(this.adminService.setPlatformData(data));
  }

  config = {
    displayKey: "viewValue",
    search: true,
    height: "200px",
    placeholder: "Select Option",
    moreText: "more",
    noResultsFound: "No results found!",
    searchPlaceholder: "Search",
    searchOnKey: "viewValue",
    clearOnSelection: false,
    inputDirection: "ltr",
  };
}
